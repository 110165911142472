import { Validators } from "@angular/forms";
import { BigAlModule } from "../modules/bigal.module";
var ValidatorFactory = /** @class */ (function () {
    function ValidatorFactory(bigAl) {
        this.bigAl = bigAl;
    }
    ValidatorFactory.prototype.getValidators = function (rules, formGroup) {
        var validators = [];
        for (var i = 0; i < rules.length; i++) {
            validators.push(this.getValidator(rules[i], formGroup));
        }
        return validators;
    };
    ValidatorFactory.prototype.getValidator = function (rule, formGroup) {
        switch (rule.Type) {
            case "required":
                return this.getRequiredValidator();
            case "dependentRequired":
                return this.getDependentRequiredValidator(this.hasValue, rule.ExpectsValues, rule.ExpectsControls, rule.DependentControls, formGroup);
            case "integer":
                return this.getIntegerValidator(this.hasValue);
            case "positive-number":
                return this.getPositiveNumberValidator(this.hasValue);
            case "length":
                return this.getLengthValidator(this.hasValue, rule.ExpectsValues[0]);
            case "higherThan":
                return this.getHigherThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
            case "higherThanCustom":
                return this.getHigherThanCustomValidator(this.hasValue, rule.ExpectsValues[0], rule.ExpectsControls[0], formGroup);
            case "lowerThan":
                return this.getLowerThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
            case "lowerThanDifference":
                return this.getLowerThanDifferenceValidator(this.hasValue, rule.ExpectsControls, formGroup);
            case "lowerOrEqualThan":
                return this.getLowerOrEqualThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
            case "higherOrEqualThan":
                return this.getHigherOrEqualThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
            case "lowerOrEqualThanDifference":
                return this.getLowerOrEqualThanDifferenceValidator(this.hasValue, rule.ExpectsControls, formGroup);
            case "max":
                return this.getMaxValidator(this.hasValue, rule.ExpectsValues[0]);
            case "min":
                return this.getMinValidator(this.hasValue, rule.ExpectsValues[0]);
            case "phone":
                return this.getPhoneValidator(this.hasValue);
            case "email":
                return this.getEmailValidator(this.hasValue);
            case "password":
                return this.getPasswordValidator(this.hasValue);
            case "optionalpassword":
                return this.getOptionalPasswordValidator(this.hasValue);
            case "confirmpassword":
                return this.getConfirmPasswordValidator(formGroup);
            case "alphanumeric":
                return this.getOnlyAlphanumericValidator();
            case "postcode":
                return this.getPostcodeValidator();
            default: {
                return null;
            }
        }
    };
    ValidatorFactory.prototype.getMinValidator = function (baseValidator, min) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var value = Number.parseInt(input.value);
            return value >= min ? null : { min: true };
        };
    };
    ValidatorFactory.prototype.getIntegerValidator = function (baseValidator) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var pattern = /^\d+$/;
            return pattern.test(input.value) ? null : { 'integer': true };
        };
    };
    ValidatorFactory.prototype.getPositiveNumberValidator = function (baseValidator) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var pattern = /^\+?(0|[1-9]\d*)$/;
            return pattern.test(input.value) ? null : { 'positive-number': true };
        };
    };
    ValidatorFactory.prototype.getPasswordValidation = function (input) {
        function isAlphaNumeric(value) {
            var pattern = /^[a-zA-Z0-9]+$/;
            return input.value !== null && pattern.test(input.value);
        }
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})([^åäö]*$)");
        var notAllowWord = !(input.value.toLowerCase().includes('automotive'));
        return notAllowWord && !isAlphaNumeric(input.value) && regex.test(input.value);
    };
    ValidatorFactory.prototype.getPasswordValidator = function (baseValidator) {
        var currObj = this;
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            return currObj.getPasswordValidation(input) ? null : { password: true };
        };
    };
    ValidatorFactory.prototype.getOptionalPasswordValidator = function (baseValidator) {
        var currObj = this;
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            return currObj.getPasswordValidation(input) ? null : { optionalpassword: true };
        };
    };
    ValidatorFactory.prototype.getConfirmPasswordValidator = function (formGroup) {
        return function (input) {
            var pswControl = formGroup.controls["Password"];
            if (pswControl) {
                return pswControl.value === input.value ? null : { confirmpassword: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getLengthValidator = function (baseValidator, length) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            return input.value.length > length || input.value.length < length ? null : { length: true };
        };
    };
    ValidatorFactory.prototype.getLowerThanValidator = function (baseValidator, expectsControl, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
                var compareValue = formGroup.get(expectsControl).value;
                return parseInt(input.value) < parseInt(compareValue) ? null : { lowerThan: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getLowerOrEqualThanValidator = function (baseValidator, expectsControl, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
                var compareValue = formGroup.get(expectsControl).value;
                return parseInt(input.value) <= parseInt(compareValue) ? null : { lowerOrEqualThan: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getHigherOrEqualThanValidator = function (baseValidator, expectsControl, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
                var compareValue = formGroup.get(expectsControl).value;
                return parseInt(input.value) >= parseInt(compareValue) ? null : { higherOrEqualThan: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getLowerOrEqualThanDifferenceValidator = function (baseValidator, expectsControls, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var difference = 0;
            var minValue = null;
            var valuesToSubtractFrom = [];
            expectsControls.forEach(function (controlName) {
                var formControl = formGroup.get(controlName);
                if (!formControl || formControl.disabled) {
                    return null;
                }
                var value = formControl.value;
                if (minValue === null) {
                    minValue = parseInt(value);
                }
                else {
                    valuesToSubtractFrom.push((value ? parseInt(value) : 0));
                }
            });
            valuesToSubtractFrom.forEach(function (val) {
                difference += val;
            });
            difference -= minValue;
            return parseInt(input.value) <= difference ? null : { lowerOrEqualThanDifference: true };
        };
    };
    ValidatorFactory.prototype.getLowerThanDifferenceValidator = function (baseValidator, expectsControls, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var difference = 0;
            var minValue = null;
            var valuesToSubtractFrom = [];
            expectsControls.forEach(function (controlName) {
                var formControl = formGroup.get(controlName);
                if (!formControl || formControl.disabled) {
                    return null;
                }
                var value = formControl.value;
                if (minValue === null) {
                    minValue = parseInt(value);
                }
                else {
                    valuesToSubtractFrom.push(parseInt(value));
                }
            });
            valuesToSubtractFrom.forEach(function (val) {
                difference += val;
            });
            difference -= minValue;
            return parseInt(input.value) < difference ? null : { lowerOrEqualThanDifference: true };
        };
    };
    ValidatorFactory.prototype.getHigherThanValidator = function (baseValidator, expectsControl, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
                var compareValue = formGroup.get(expectsControl).value;
                return parseInt(input.value) > parseInt(compareValue) ? null : { higherThan: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getHigherThanCustomValidator = function (baseValidator, expectsValue, expectsControl, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            if (formGroup.get(expectsControl) && !isNaN(expectsValue)) {
                var additionalControlValue = formGroup.get(expectsControl).value;
                var sum = parseInt(input.value) + parseInt(additionalControlValue);
                return sum <= expectsValue ? null : { higherThanCustom: true };
            }
            return null;
        };
    };
    ValidatorFactory.prototype.getMaxValidator = function (baseValidator, max) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var value = Number.parseInt(input.value);
            return value <= max ? null : { max: true };
        };
    };
    ValidatorFactory.prototype.getMinLengthValidator = function (baseValidator, min) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            return input.value.length >= min ? null : { minLength: true };
        };
    };
    ValidatorFactory.prototype.getEmailValidator = function (baseValidator) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(input.value) ? null : { email: true };
        };
    };
    ValidatorFactory.prototype.getPhoneValidator = function (baseValidator) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
            return pattern.test(input.value) ? null : { phone: true };
        };
    };
    ValidatorFactory.prototype.getRequiredValidator = function () {
        return function (input) {
            return !(input.value === null || input.value === undefined || input.value === "" || !/\S/.test(input.value)) ? null : { required: true };
        };
    };
    ValidatorFactory.prototype.getOnlyAlphanumericValidator = function () {
        return function (input) {
            var pattern = /^\w+$/;
            if (input.value === null || input.value === undefined || input.value === "") {
                return null;
            }
            return (input.value !== null && pattern.test(input.value)) ? null : { alphanumeric: true };
        };
    };
    ValidatorFactory.prototype.getPostcodeValidator = function () {
        return function (input) {
            var pattern = /^[0-9]+$/;
            if (input.value === null || input.value === undefined || input.value === "") {
                return null;
            }
            // it is fine to have spaces
            var trimmedValue = input.value.replace(" ", "");
            return pattern.test(trimmedValue) ? null : { postcode: true };
        };
    };
    ValidatorFactory.prototype.getDependentRequiredValidator = function (baseValidator, expectsValues, expectsControls, dependentControls, formGroup) {
        return function (input) {
            if (!baseValidator(input)) {
                return null;
            }
            var setRequired = false;
            for (var i = 0; i < expectsControls.length; i++) {
                if (formGroup.get(expectsControls[i]).value === expectsValues[i].toString()) {
                    setRequired = true;
                }
                if (setRequired) {
                    var validator = Validators.required;
                    for (var i_1 = 0; i_1 < dependentControls.length; i_1++) {
                        var control = formGroup.get(dependentControls[i_1]);
                        control.setValidators(validator);
                        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                    }
                }
                else {
                    for (var i_2 = 0; i_2 < dependentControls.length; i_2++) {
                        var control = formGroup.get(dependentControls[i_2]);
                        control.setValidators(null);
                        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                    }
                }
            }
            return null;
        };
    };
    ValidatorFactory.prototype.hasValue = function (input) {
        return input.value && input.value.toString().trim() !== "";
    };
    return ValidatorFactory;
}());
export { ValidatorFactory };
