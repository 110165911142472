import { RuleBook } from './../models/rule-book.model';
import { DriverJournalDailyLogDelete } from '../models/driverjournal/driverJournalDailyLogDelete.model';
import { CongestionTaxMonthlyLog } from './../models/congestionTaxMonthlyLog.model';
import { CongestionTaxDailyLog } from './../models/congestionTaxDailyLog.model';
import { DriverJournalMonthlyLog } from '../models/driverjournal/driverJournalMonthlyLog.model';
import { DriverJournalDailyRules } from '../models/driverjournal/driverJournalDailyRules.model';
import { FeatureComponentConfiguration } from './../models/component-config/feature-component-configuration.model';
import { Workshop } from './../models/workshop.model';
import { DriverProfile } from './../models/driverProfile.model';
import { Contract } from './../models/contract.model';
import { ApiService } from './../services/api.service';
import { BehaviorSubject, Subscription, Subject } from "rxjs";
import { AppSettings } from '../models/app-settings.model';
import { NgModule } from '@angular/core';
import { CustomTranslateService } from '../translation/customTranslateService';
import { Feed } from '../models/feed.model';
import { FeedItem } from '../models/feed-item.model';
import { Contacts } from '../models/contacts.model';
import { UserLogin } from '../models/user-login.model';
import { DriverJournalMonthly } from '../models/driverjournal/driverJournalMonthly.model';
import { Vehicle } from '../models/vehicle.model';
import { DriverJournalDaily } from '../models/driverjournal/driverJournalDaily.model';
import { ActivatedRoute } from '@angular/router';
import { MarketingConsent } from '../models/marketingConsent.model';
import { Insurance } from '../models/insurance.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BusinessPartner } from '../models/business-partner.model';
import { GetSubjectsAndCategoriesResponse } from '../models/getSubjectsAndCategoriesResponse.model';
import { Driver } from '../models/driver.model';
import { WorkshopSearch } from '../models/workshop-search.model';
import { Mileage } from '../models/mileage.model';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { FuelCompany } from '../models/fuelCompany.model';
import { FuelCardOrder } from '../models/fuelcard-order.model';
import { InsuranceClaimFinish } from '../models/insurance-claim-finish.model';
import { FuelingSummary } from '../models/fueling-summary.model';
import { LanguageTranslation } from '../models/languageTranslationResponse.model';
import { Translation } from '../models/translation.model';
import { CardOrder } from '../models/cardorder.model';
import { CardColour } from '../models/cardorder.model';
import { CertificateOrder } from '../models/certificateorder.model';
import { OrderDocuments } from '../models/orderdocuments.model';
import { AppUser } from '../models/appuser.model';
import { ResetPasswordRequest } from '../models/resetpasswordrequest.model';
import { SetPasswordRequest } from '../models/setpasswordrequest.model';
import { ContactUsEmail } from '../models/contactusemail.model';
import { SpinnerService } from '../services/spinner.service';
import { EditLogin, EditLoginForm } from '../models/editlogin.model';
import { Appointment } from '../models/appointment.model';
import { AppointmentTimeslots } from '../models/appointment-timeslots.model';
import { AppointmentPlace } from '../models/appointment-place.model';
import { TireCenter } from '../models/tireCenter.model';
import { DatePipe } from '@angular/common';
import { FleetUserService } from '../services/fleet-user.service';
import { FleetUser } from '../models/fleet-user.model';
import { WorkshopBooking } from '../models/workshop-booking.model';
import { FleetUserAllReportsViewModel } from '../models/fleetUserAllReportsViewModel.model';
import { ComponentConfiguration } from '../models/component-config/component-configuration.model';
import { ChangeDriver } from '../models/change-driver.model';
import { FleetUserTakeOverInfo } from '../models/fleetUserTakeOverInfo.model';
import { FleetDepartmentChange } from '../models/fleetDepartmentChange.model';
import { UserActionSuccess } from '../models/userActionSuccess.model';
import { IfrsReportOrder, IfrsEventReportOrder } from '../models/ifrsReportOrder.model';
import { BusinessDocuments } from '../models/business-documents.model';
import { FileUploadService } from '../services/files/file-upload.service';
import { DriverJournalMonthlyRules } from '../models/driverjournal/driverJournalMonthlyRules.model';
import { ResetPasswordByIdRequest } from '../models/resetpasswordbyidrequest.model';
import { User } from '../models/user.model';
import { UserModificationRequest } from '../models/usermodificationrequest.model';
import { FleetOverviewContract } from '../models/fleet-overview-contract.model';
import { DriverJournalDailyLog } from '../models/driverjournal/driverJournalDailyLog.model';
import { FleetReportService } from '../services/fleet-report.service';
import { BusinessTripService } from '../services/driverjournal/business-trip.service';
import { UserSettings } from '../models/user-settings.model';
import { CardResponse } from '../models/cardresponse.model';
import { Card } from '../models/card.model';
import { CardsAndTagsService } from '../services/cards-and-tags.service';
import { NavigationService } from '../services/navigation.service';
import { TableDataUpdatesService } from '../services/tables/table-data-updates.service';
import { FleetOverview } from '../models/fleet-overview';
import { DrivingSettings } from '../models/driving-settings.model';
import { TrackingInformationRequest } from '../models/driverjournal/tracking-information';
import { AldPosition } from '../models/geo-tracking-device-motion/ald-position.model';
import { ITireBranch, ITireBranchTimeslot, ITireBooking, IBookingServiceType, IBooking, IBusinessPartnerIdAndReference, IValidateToken, IDefaultSettings, IGTMTags, IScript, ICodeblock, IDriverSettings, ITireBranchServiceType } from '../sharedtypes/interfaces/sharedtypes.interface';
import { Constants } from '../helpers/constants';
import { ResourceInjectorService } from '../services/resourceInjector.service';
import { CongestionTaxStore, ContractStore, DriverStore, AppUserStore, DriverJournalStore, DocumentStore, InsuranceStore, ParkingStore } from '../stores/DataStores';
import { FirebaseService } from '../services/firebase.service';
import { MonitoringService } from '../services/logging.service';
import { IContractVersion } from '../sharedtypes/interfaces/contract/contractversion.interface';
import { JournalUserQueryTypes } from '../models/enums/journal-user-query-types.enum';
import { IJournalUser } from '../sharedtypes/interfaces/driverJournal/journalUser.interface';
import { FuelRules } from '../models/enums/fuelrules.enum';
import { ApiUrl } from '../helpers/apiUrls';
import { ChangePasswordForm } from '../models/changepassword.model';
import { TireBranchServiceTypeOption } from '../sharedtypes/enums/sharedtypes.enum';


@NgModule({
    imports: [],
    declarations: [],
    providers: [DatePipe]
})
export class BigAlModule {

    private appDidInit = new BehaviorSubject<boolean>(null);
    appDidInit$ = this.appDidInit.asObservable();

    private dataStream = new BehaviorSubject<string>(null);
    private nextCallStream = new BehaviorSubject<string>(null);
    dataStreamFailed = new Subject<{ event: string, error: HttpErrorResponse }>();

    private nextCallSubscription: Subscription;
    private uploadedFiles: string[] = [];
    public testTokenRefresh = false;

    private callsQueue: string[] = [];
    public defaultSettings: IDefaultSettings = null;

    private listeningOnFirebaseNotification: boolean = false;

    // Used for getting and setting the user's refresh token in the local/session storage.
    private RefreshTokenKey = "refreshKey";
    private UserRoleKey = "userRoleKey";

    public currentTraceId: string;

    public isUnderMaintenance = false;
    public isInDesktopScreen = true;
    public isUserLoggedIn = false;
    public appSettings: AppSettings = null;
    public localLanguageCountryCode: string;
    public currentRuleBook: RuleBook = null;
    public currentMarketingConsent: MarketingConsent = null;

    public currentBusinessPartnerIdAndReference: IBusinessPartnerIdAndReference[] = [];
    public currentQueriedBusinessPartnerId: string = "";
    public currentVehicleContracts: Array<Contract> = null;
    public currentOtherContracts: Array<Contract> = null;
    public currentVehicleContract: Contract = null;
    public currentOtherContract: Contract = null;
    public currentDriver: Driver = null;
    public currentBusinessPartner: BusinessPartner = null;
    public currentVehicle: Vehicle = null;
    public currentVehicles: Array<Vehicle> = null;
    public currentFuelCards: CardResponse = null;
    public currentDriverCards: CardResponse = null;
    public currentTollTags: CardResponse = null;
    public currentChargingTags: CardResponse = null;
    public currentFuelCompanies: Array<FuelCompany> = null;
    public currentFuelingSummary: FuelingSummary = null;
    public currentFeed: Feed = null;
    public currentTireCenter: TireCenter = null;
    public currentTireBranch: ITireBranch = null;
    public currentWorkshops: Array<Workshop> = null;
    public currentWorkshopSearch: WorkshopSearch = new WorkshopSearch();
    public currentAvailableTimes: AppointmentTimeslots[] = null;
    public currentTireBranchTimeslots: Array<ITireBranchTimeslot> = null;
    public currentTireBookings: Array<ITireBooking> = null;

    public currentDrivingSettings: DrivingSettings = null;
    public currentDriverJournalMonthly: DriverJournalMonthly = null;
    public currentDriverJournalDaily: DriverJournalDaily = null;
    public currentFuelRules: FuelRules = null;
    public currentContacts: Contacts = null;
    public currentInsurance: Insurance = null;
    public currentContactUsSubjects: GetSubjectsAndCategoriesResponse = null;

    public currentPasswordResetCode = null;
    public currentPasswordSetCode = null;

    public currentBusinessDocuments: BusinessDocuments = null;
    public currentAvailableReports: FleetUserAllReportsViewModel = null;
    public currentFleetOldReports: FleetUserAllReportsViewModel = null;
    public currentChangeDriverFuelCompanies: Array<FuelCompany> = null;

    public userPosition: AldPosition;
    public defaultPosition: AldPosition;

    public currentFleetUser: FleetUser;
    public currentAccessibleFleetUsers: User[] = null;
    public currentBusinessUsers: User[] = null;
    public currentFleetUserContracts: FleetOverviewContract[] = null;
    public currentFleetUserFleetOverview: FleetOverview = null;
    public currentFleetUserTakeOverInfo: FleetUserTakeOverInfo = null;
    public currentSearchedUsers: User[] = null;
    public currentSearchedFleetUsers: User[] = null;
    public currentJournalUsers: IJournalUser[] = null;
    public currentFleetUserBusinessPartners: Array<BusinessPartner> = null;
    public currentJournalUser: IJournalUser = null;
    public currentJournalUserQueryAndType: { query: string, type: JournalUserQueryTypes } = null;
    public currentJournalUserContracts: Array<IContractVersion> = null;
    public currentJournalUserContract: IContractVersion = null;
    public currentJournalUserContractsTimeSpan: { from: Date, to: Date } = null;


    public previousModal: string;
    public showAdminButtons = false;
    public resetPasswordResult: boolean;

    // helper vars
    private resetPasswordPath = "/new-password";
    private currentBusinessPartnerSearchQuery: string = null;

    private currentBookingFormSelectedDate: Date;
    private currentBookingFormSelectedServiceType: IBookingServiceType = null;

    public currentDriverJournalMonthlyRules: DriverJournalMonthlyRules = null;
    private currentDriverJournalMonthlyEditLog: DriverJournalMonthlyLog = null;
    public currentDriverJournalDailyRules: DriverJournalDailyRules = null;

    public getDataStream() {
        return this.dataStream.asObservable();
    }

    constructor(
        private firebaseService: FirebaseService,
        private appUserStore: AppUserStore,
        private contractStore: ContractStore,
        private congestionTaxStore: CongestionTaxStore,
        private driverJournalStore: DriverJournalStore,
        private documentStore: DocumentStore,
        private insuranceStore: InsuranceStore,
        private parkingStore: ParkingStore,
        private driverStore: DriverStore,
        private DatePipe: DatePipe,
        private apiService: ApiService,
        private translateService: CustomTranslateService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private spinnerService: SpinnerService,
        private fleetUserService: FleetUserService,
        private fileUploadService: FileUploadService,
        private reportService: FleetReportService,
        private businessTripService: BusinessTripService,
        public tableDataService: TableDataUpdatesService,
        private resourceInjector: ResourceInjectorService,
        private monitoringService: MonitoringService,
    ) {
        this.calls();
    }

    public async init() {

        let path = "";
        if (window.location.href.includes("index.html")) {
            path = window.location.href.replace("index.html", "assets/default-settings.json");
        } else {
            path = "assets/default-settings.json";
        }
        await this.apiService.getFileAsync<IDefaultSettings>(path)
            .then((data: IDefaultSettings) => {
                this.defaultSettings = data;
            })
            .catch((err: HttpErrorResponse) => {
            });

        await this.whoAmI();

        if (this.appSettings) {
            await this.getTranslations();
            await this.getRules();
            this.postRefreshToken();

            this.dataStream.next("init");
        }

        this.injectGTMTag();
        this.initMatomo();
        this.initFleetUserData();
        this.subscribeToFileService();

        // Initialize ApplicationInsights
        this.monitoringService.init(
            this.defaultSettings.InstrumentationKey,
            this.defaultSettings.WhoAmI[location.host]
        );

        this.appDidInit.next(true);
    }

    private injectGTMTag() {

        try {

            let whoAmILocation = this.defaultSettings.WhoAmI[location.host];
            if (whoAmILocation) {
                // We are on the web
                const key = this.getGTMWebKey(whoAmILocation);
                if (key) {
                    this.defaultSettings.GTMTag = key;
                }
            }

            if (this.defaultSettings.GTMTag) {

                // Add GTM key to window
                window["GTMIdentity"] = this.defaultSettings.GTMTag;

                // Create script to inject
                const script: IScript = {
                    src: "assets/scripts/gtm.js",
                    identifier: "gtm"
                };

                // Inject into DOM
                this.resourceInjector.injectScript(script);
            } else {
                console.warn("Failed to find GTM key: ", whoAmILocation, this.defaultSettings.GTMTag);
            }
        } catch (err) {
        }
    }
    private initMatomo() {
        //Based on CompanyId and Country code the tracking Id is assigned for tracking
        //Based on CompanyId and Country code the tracking Id is assigned for tracking from defaultSettings
        if (this.appSettings.CountryCode.toLowerCase() === "dk" && this.appSettings.CompanyId === 1) {
            window["trackId"] = this.defaultSettings.MyAld2DkTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "dk" && this.appSettings.CompanyId === 2) {
            window["trackId"] = this.defaultSettings.MyNf2DkTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "se" && this.appSettings.CompanyId === 1) {
            window["trackId"] = this.defaultSettings.MyAld2SeTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "se" && this.appSettings.CompanyId === 2) {
            window["trackId"] = this.defaultSettings.MyNf2SeTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        const script: IScript = {
            src: "assets/scripts/matomo.js",
            identifier: "matomo"

        };

        this.resourceInjector.injectScript(script);



    }

    private getGTMWebKey(whoAmILocation: string): string {

        let key: string = "";

        try {

            let application = "";
            let domainPrefix = "";
            let environment = "";

            if (whoAmILocation) {
                // What application is running
                application = whoAmILocation.includes("myald2") ? "ald" : "nffleet";
                domainPrefix = application === "ald" ? "my" : "my-nf";

                // Resolve Environment
                if (location.host.includes("localhost")) {
                    if (whoAmILocation.includes("myald2dk")) { // myald2dk | myald2dklocal
                        whoAmILocation = "myald2dk";
                    } else if (whoAmILocation.includes("myald2se")) { // myald2se | myald2selocal
                        whoAmILocation = "myald2se";
                    } else if (whoAmILocation.includes("myald2no")) { // myald2no | myald2nolocal
                        whoAmILocation = "myald2no";
                    } else {
                        console.warn("Failed to find GTM key for unknown whoAmI: ", whoAmILocation);
                    }

                    key = this.defaultSettings.GTMWeb[application]["uat"][whoAmILocation];

                } else {
                    if (location.host.includes(`${domainPrefix}.uat.ald`) ||
                        location.host.includes(`${domainPrefix}.test.ald`)) {
                        environment = "uat";
                    } else if (
                        !location.host.includes(`${domainPrefix}.dev.ald`) &&
                        !location.host.includes(`${domainPrefix}.test.ald`) &&
                        !location.host.includes(`${domainPrefix}.uat.ald`)) {
                        environment = "prod";
                    }

                    key = this.defaultSettings.GTMWeb[application][environment][whoAmILocation];
                }
            }
        } catch (err) {
            console.warn('Failed to find GTM key: ', err);
        }

        return key;
    }

    private initFleetUserData() {
        let isInTakeOverMode = this.getStorageValueForIsInTakeOverMode();
        this.fleetUserService.setIsInTakeOverMode(isInTakeOverMode);

        this.fleetUserService.isInTakeOverMode.subscribe(isInTakeOverMode => {
            localStorage.setItem(Constants.LocalStorage_IsInTakeOverModeKey, isInTakeOverMode.toString());
        });
    }

    public getStorageValueForIsInTakeOverMode(): boolean {
        return localStorage.getItem(Constants.LocalStorage_IsInTakeOverModeKey) === "true";
    }

    private setTraceId(appSettings: AppSettings): AppSettings {
        if (!this.currentTraceId) {
            this.currentTraceId = this.newGuid();
        }
        appSettings.TraceId = this.currentTraceId;
        return appSettings;
    }

    private newGuid() {
        let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        guid = guid.toLowerCase().replace(/-/g, "");
        return guid;
    }

    public async whoAmI() {

        if (this.appSettings) {
            return;
        }

        let whoAmILocation = "";
        try {
            whoAmILocation = this.defaultSettings.WhoAmI[location.host];
            if (whoAmILocation === undefined) {
                whoAmILocation = this.defaultSettings.WhoAmI["app"];
            }

        } catch (err) {

            whoAmILocation = this.defaultSettings.WhoAmI["app"];
        }
        await this.apiService.getAsync<AppSettings>("whoami2/" + whoAmILocation, this.defaultSettings)
            .then((data: AppSettings) => {
                localStorage.setItem('countryCode', data.CountryCode);
                this.appSettings = this.setTraceId(data);
                this.appSettings.FeatureComponentConfiguration = data.FeatureComponentConfiguration;
                this.appSettings.LanguageCode = (localStorage.getItem('language')) ? localStorage.getItem('language') : this.translateService.getCountryLanguage(this.appSettings.CountryCode);
                this.localLanguageCountryCode = this.appSettings.CountryCode;
                this.appSettings.IsApp = this.defaultSettings.IsApp;
                if (localStorage.getItem("language")) {
                    this.changeLanguage(localStorage.getItem("language"));
                }

                this.setDefaultLocation(data.CountryCode);
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    private setDefaultLocation(country) {
        this.defaultPosition = new AldPosition();
        switch (country) {
            case 'dk':
                this.defaultPosition.Latitude = 55.661265;
                this.defaultPosition.Longitude = 12.280537;
                break;
            case 'se':
                this.defaultPosition.Latitude = 59.3289;
                this.defaultPosition.Longitude = 18.0649;
                break;
            case 'no':
                this.defaultPosition.Latitude = 59.911491;
                this.defaultPosition.Longitude = 10.757933;
                break;
            default:
                this.defaultPosition.Latitude = null;
                this.defaultPosition.Longitude = null;
                break;
        }
    }

    public async getFeatureComponentConfiguration() {
        await this.apiService.getAsync<FeatureComponentConfiguration>("featurecomponentconfigurations", this.defaultSettings)
            .then((data: FeatureComponentConfiguration) => {
                this.appSettings.FeatureComponentConfiguration = data;
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public async getTranslations() {
        await this.apiService.getAsync<Array<LanguageTranslation>>("languages", this.defaultSettings)
            .then((data: Array<LanguageTranslation>) => {
                this.translateService.loadCompanyTranslations(data, this.appSettings.CompanyId);
                this.translateService.setLanguage(this.appSettings.CountryCode);
                if (localStorage.getItem("language")) {
                    this.translateService.changeLanguageByLanguageCode(localStorage.getItem("language"));
                }
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public async getRules() {
        // get rules
        await this.apiService.getAsync<RuleBook>("rules", this.defaultSettings)
            .then((data: RuleBook) => {
                this.currentRuleBook = data;
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public getBusinessDocuments() {
        if (!this.currentBusinessDocuments) {
            this.getBusinessPartner();
            this.addCall("currentBusinessDocuments");
        }
    }

    private subscribeToFileService() {
        this.fileUploadService.getFilePathObservable().subscribe(file => {
            if (file) {
                this.uploadedFiles.push(file.FileToken);
            }
        });
    }

    public getAppointmentPlace(modelName: string) {
        let appointmentPlace;
        if (modelName === "TireCenter") {
            this.getTireCenter();
            let bookings: Appointment[] = [];
            if (this.currentTireCenter) {
                for (let index = 0; index < this.currentTireCenter.Bookings.length; index++) {
                    let appointment = this.currentTireCenter.Bookings[index];
                    bookings.push(new Appointment(appointment.AppointmentId, appointment.Appointment, appointment.MobilePhoneNumber, appointment.Description));
                }
                appointmentPlace = new AppointmentPlace("TireCenter", this.currentTireCenter.Name, this.currentTireCenter.Street, this.currentTireCenter.PostalCodeAndCity, this.currentTireCenter.Telephone, bookings);
            }
            else {
                appointmentPlace = new AppointmentPlace("TireCenter", '', '', '', '', []);
            }
            return appointmentPlace;
        }
        else {
            return new AppointmentPlace(null, null, null, null, null);
        }
    }

    public getAvailableTimes(modelName: string) {
        if (modelName === "TireCenter") {
            this.getTireCenter();
            this.addCall("currentTireCenterAvailableTimes");
        }
    }

    public getTireBookings() {
        this.getTireCenterBranch();
        this.addCall(Constants.Event_CurrentTireBookings);
        this.spinnerService.hide();
    }

    public getTireCenterAvailableTimeslots() {
        this.getTireCenterBranch();
        this.addCall(Constants.Event_CurrentTireBranchTimes);
        this.spinnerService.hide();
    }

    public postBooking(booking: IBooking) {
        this.spinnerService.show();
        return this.apiService.postAsync("tire/booking/" + this.currentTireBranch.CenterType, booking, this.appSettings)
            .then(
                () => {
                    this.currentTireBookings = null;
                    this.getTireBookings();
                    this.spinnerService.hide();
                    return true;
                }
            )
            .catch(
                (err) => {
                    this.spinnerService.hide();
                    throw err;
                });
    }

    public putBooking(id: string, booking: IBooking) {
        this.spinnerService.show();
        return this.apiService.putAsync("tire/booking/" + this.currentTireBranch.CenterType + "/" + id, booking, this.appSettings)
            .then(
                () => {
                    this.spinnerService.hide();
                    this.currentTireBookings = null;
                    this.getTireBookings();
                    return true;
                }
            )
            .catch(
                (err) => {
                    this.spinnerService.hide();
                    throw err;
                });
    }

    public deleteBooking(id: string) {
        this.spinnerService.show();
        return this.apiService.deleteAsync("tire/booking/" + this.currentTireBranch.CenterType + "/" + this.currentVehicleContract.Id + "/" + id, null, this.appSettings)
            .then(
                () => {
                    this.currentTireBookings = null;
                    this.spinnerService.hide();
                    this.getTireBookings();
                    return true;
                }
            )
            .catch(
                (err) => {
                    this.spinnerService.hide();
                    throw err;
                });
    }

    public postAppointment(modelName: string, appointment: Appointment) {
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.getTireCenter();
            this.apiService.postAsync("TireCenter/appointments/" + this.currentVehicleContract.Id, appointment, this.appSettings)
                .then(() => {
                    this.currentTireCenter = null;
                    this.getTireCenter();
                    return true;
                });
        }
    }

    public updateAppointment(modelName: string, appointment: Appointment) {
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.getTireCenter();
            this.apiService.putAsync("TireCenter/appointments/" + this.currentVehicleContract.Id + "/" + appointment.AppointmentId, appointment, this.appSettings)
                .then(() => {
                    this.currentTireCenter = null;
                    this.getTireCenter();
                    return true;
                })
                .catch((err) => { });
        }
    }

    public deleteAppointment(modelName: string, appointmentId: string) {
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.apiService.deleteAsync("TireCenter/appointments/" + this.currentVehicleContract.Id + "/" + appointmentId, {}, this.appSettings)
                .then(() => {
                    this.currentTireCenter = null;
                    this.getTireCenter();
                    return true;
                })
                .catch((err) => { });
        }
    }

    public async setUserLocation() {

        this.userPosition = new AldPosition();

        this.userPosition.Latitude = this.defaultPosition.Latitude;
        this.userPosition.Longitude = this.defaultPosition.Longitude;
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                this.userPosition.Latitude = position.coords.latitude;
                this.userPosition.Longitude = position.coords.longitude;
            });
        }

    }

    public getAvailableLanguageCodes(): string[] {
        return this.translateService.getLanguageCodes();
    }

  public changeLanguage(languageCode: string) {
    localStorage.setItem("language", languageCode);
    this.appSettings.LanguageCode = languageCode;
    this.translateService.changeLanguageByLanguageCode(this.appSettings.LanguageCode);
    this.postChangeLanguage();
  }

    public async takeOverDriver(contractId: string) {
        this.spinnerService.show();
        await this.apiService.getAsync<any>("FleetUser/TakeOverDriver/" + contractId, this.appSettings)
            .then((data: FleetUserTakeOverInfo) => {
                this.currentFleetUserTakeOverInfo = data;
                this.currentDriver = this.currentFleetUserTakeOverInfo.Driver;
                this.fleetUserService.setIsInTakeOverMode(true);
                this.navigationService.forward("cars", null);
                this.spinnerService.hide();
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public async revertTakeOverDriver() {
        this.spinnerService.show();
        await this.apiService.getAsync<any>("FleetUser/RevertTakeOverDriver", this.appSettings)
            .then((data: any) => {
                this.clearData(false);
                this.resetStores();
                this.fleetUserService.setIsInTakeOverMode(false);
                this.navigationService.forward("fleet/overview", null, { queryParams: { reload: true } });
                this.spinnerService.hide();
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public resetStores() {
        this.contractStore.reset();
        this.driverStore.reset();
        this.congestionTaxStore.reset();
        this.driverJournalStore.reset();
        this.parkingStore.reset();
        this.insuranceStore.reset();
        this.documentStore.reset();
    }

    public getContracts() {
        if (this.currentVehicleContracts === null) {
            this.addCall(Constants.Event_CurrentContracts);
        }
    }

    public clearContractDependent() {
        this.currentFuelCards = null;
        this.currentDriverJournalMonthly = null;
        this.currentDriverJournalDaily = null;
        this.currentFuelingSummary = null;
        this.currentInsurance = null;
        this.congestionTaxStore.reset();
    }

    public changeContractAndVehicle() {
        const contractIndex = this.currentVehicleContracts.findIndex(c => c.Id === this.currentVehicleContract.Id);
        if (contractIndex < this.currentVehicleContracts.length - 1) {
            this.currentVehicleContract = this.currentVehicleContracts[contractIndex + 1];
        } else {
            this.currentVehicleContract = this.currentVehicleContracts[0];
        }

        this.clearContractDependent();
        this.addCall(Constants.Event_CurrentVehicle);
    }

    public getVehicle() {
        if (this.currentVehicle === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentVehicle);
        }
    }

    public getVehicles() {
        if (this.currentVehicles === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentVehicles);
        }
    }

    public getBusinessPartner() {
        if (this.currentBusinessPartner === null) {
            this.addCall(Constants.Event_CurrentBusinessPartner);
        }
    }

    public getDriver() {
        if (this.currentDriver === null) {
            this.addCall(Constants.Event_CurrentDriver);
        }
    }

    public async getDriverProfile(driverId: string) {
        await this.apiService.getAsync<any>("Drivers/" + driverId, this.appSettings)
            .then((data: Driver) => {
                this.currentDriver = data;
                this.dataStream.next(Constants.Event_CurrentDriver);
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public getMarketConsent() {
        if (this.currentMarketingConsent === null) {
            this.addCall("currentMarketingConsent");
        }
    }

    public getTireCenter() {
        if (this.currentTireCenter === null) {
            this.getContracts();
            this.getDriver();
            this.addCall(Constants.Event_CurrentTireCenter);
        }
    }

    public getTireCenterBranch() {
        if (this.currentTireBranch === null) {
            this.getContracts();
            this.getDriver();
            this.addCall(Constants.Event_CurrentTireBranch);
            this.spinnerService.hide();
        }
    }

    public getWorkshops() {
        this.getContracts();
        this.addCall(Constants.Event_CurrentWorkshops);
    }

    public getFuelCards() {
        if (this.currentFuelCards === null) {
            this.getContracts();
            this.addCall("currentFuelCards");
        }
    }

    public getDriverCards() {
        if (this.currentDriverCards === null) {
            this.getContracts();
            this.addCall("currentDriverCards");
        }
    }

    public getChargingTags() {
        if (this.currentChargingTags === null) {
            this.getContracts();
            this.addCall("currentChargingTags");
        }
    }

    public getTollTags() {
        if (this.currentTollTags === null) {
            this.getContracts();
            this.addCall("currentTollTags");
        }
    }

    public getFuelCompanies() {
        if (this.currentFuelCompanies === null) {
            this.getContracts();
            this.addCall("currentFuelCompanies");
        }
    }

    public getFuelingSummary() {
        if (!this.currentFuelingSummary) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentFuelSummary);
        }
    }

    public getFleetUser() {
        if (!this.currentFleetUser) {
            this.addCall(Constants.Event_CurrentFleetUser);
        }
    }

    public getFleetUserById(id: string) {
        this.currentFleetUser = null;
        this.apiService.getAsync("FleetUser/" + id, this.appSettings)
            .then((data: FleetUser) => {
                this.currentFleetUser = data;
            })
            .then(() => {
                this.next();
                this.dataStream.next(Constants.Event_CurrentFleetUser);
            })
            .catch((err: HttpErrorResponse) => {
                this.next();
            });
    }

    public getFleetUserByUsername(username: string) {
        this.currentFleetUser = null;
        this.apiService.getAsync("FleetUser/name/" + username, this.appSettings)
            .then((data: FleetUser) => {
                this.currentFleetUser = data;
            })
            .then(() => {
                this.next();
                this.dataStream.next(Constants.Event_CurrentFleetUser);
            })
            .catch((err: HttpErrorResponse) => {
                this.next();
            });
    }

    public getAccessibleFleetUsers() {
        if (!this.currentAccessibleFleetUsers) {
            this.addCall("currentAccessibleFleetUsers");
        }
    }

    public getBusinessUsers() {
        if (!this.currentBusinessUsers) {
            this.addCall(Constants.Event_BusinessUsers);
        }
    }


    public getUsersByQuery(queryString: string, endpoint: string): Promise<boolean> {
        this.spinnerService.show();
        let apiPromise: Promise<boolean> = this.apiService.getAsync(endpoint + queryString, this.appSettings)
            .then((data: User[]) => {
                this.currentSearchedUsers = data;
                this.dataStream.next("currentUsers");
                this.spinnerService.hide();
                return true;
            }).catch(() => {
                this.currentSearchedUsers = [];
                this.dataStream.next("currentUsers");
                this.spinnerService.hide();
                return false;
            });
        return apiPromise;
    }





    public getFleetUsersForBusinessPartner(businessPartnerId: string): Promise<boolean> {

        this.spinnerService.show();
        this.currentBusinessPartnerSearchQuery = businessPartnerId;
        this.currentSearchedFleetUsers = null;

        const apiPromise: Promise<boolean> = this.apiService.getAsync("users/businessPartner/fleetmanagers/" + businessPartnerId, this.appSettings)
            .then((data: User[]) => {

                this.currentSearchedFleetUsers = data;

                this.dataStream.next(Constants.Event_CurrentFleetUsers);

                this.spinnerService.hide();

                return true;
            }).catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
                this.currentSearchedFleetUsers = [];
                this.dataStream.next(Constants.Event_CurrentFleetUsers);

                return false;
            });
        return apiPromise;
    }

    public getBusinessPartnerIdAndReference(businessPartnerId: string): Promise<boolean> {
        this.spinnerService.show();

        const apiPromise: Promise<boolean> = this.apiService.getAsync("businesspartners/" + businessPartnerId + "/getIdAndReference", this.appSettings)
            .then((data: IBusinessPartnerIdAndReference[]) => {

                this.currentBusinessPartnerIdAndReference = data;

                this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);

                return true;
            })
            .catch(() => {
                this.currentBusinessPartnerIdAndReference = [];

                this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);

                return false;
            });

        return apiPromise;
    }

    public getBusinessPartnerConcern(businessPartnerId: string): Promise<BusinessPartner> {
        this.spinnerService.show();

        return this.apiService.getAsync<BusinessPartner>("businesspartners/" + businessPartnerId + "/concern", this.appSettings)
            .then(
                (value: BusinessPartner) => {
                    this.spinnerService.hide();

                    return value;
                }
            )
            .catch(
                (err) => {
                    this.spinnerService.hide();
                    throw err;
                }
            );
    }

    public getBusinessPartnersById(businessPartnerId: string): Promise<boolean> {

        this.currentFleetUserBusinessPartners = null;
        this.currentQueriedBusinessPartnerId = businessPartnerId;
        this.spinnerService.show();

        const apiPromise: Promise<boolean> = this.apiService.getAsync("businesspartners/" + businessPartnerId + "/status/all", this.appSettings)
            .then((data: BusinessPartner[]) => {

                this.currentFleetUserBusinessPartners = data;
                this.currentAccessibleFleetUsers = [];
                this.currentSearchedFleetUsers = [];
                this.currentBusinessPartnerIdAndReference = [];

                if (this.currentFleetUserBusinessPartners) {
                    this.getFleetUsersForBusinessPartner(businessPartnerId);
                    this.getBusinessPartnerIdAndReference(businessPartnerId);
                }

                // If 'data' is null they queried ID dose not exist and we treat it as an error
                if (!data) {
                    this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: null });
                } else {
                    this.dataStream.next(Constants.Event_CurrentFleetUserBusinessPartners);
                }

                this.spinnerService.hide();

                return true;
            })
            .catch(() => {
                this.currentFleetUserBusinessPartners = null;
                this.currentAccessibleFleetUsers = [];
                this.currentSearchedFleetUsers = [];
                this.currentBusinessPartnerIdAndReference = [];
                this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: null });
                this.spinnerService.hide();

                return false;
            });

        return apiPromise;
    }

    public getFleetUserFleetOverview(force: boolean = false) {
        if (!this.currentFleetUserFleetOverview || force) {
            this.getFleetUser();
            this.addCall(Constants.Event_CurrentFleetOverview);
        }
    }

    public getFleetUserBusinessPartners() {
        if (!this.currentFleetUserBusinessPartners) {
            this.addCall(Constants.Event_CurrentFleetUserBusinessPartners);
        }
    }

    public getFleetUserBusinessPartnersIdAndReference() {
        if (!this.currentBusinessPartnerIdAndReference) {
            this.addCall(Constants.Event_CurrentBusinessPartnerIdAndReference);
        }
    }

    public getContactUsSubjects() {
        if (this.currentContactUsSubjects === null) {
            this.getContracts();
            this.addCall("currentContactUsSubjects");
        }
    }

    public setDriverJournalMonthlyRulesDefault() {
        let currentDate = new Date().getDate();
        let year = new Date().getFullYear();
        let currentMonth = new Date().getMonth();
        if (currentMonth === 0 && currentDate <= 17) { // if beginning of Jan show prev year
            year = year - 1;
        }

        this.currentDriverJournalMonthlyRules = new DriverJournalMonthlyRules(
            year
        );
    }

    public getDrivingSettings() {
        this.addCall("currentDrivingSettings");
    }

    public getFuelRules() {
        this.getContracts();
        this.addCall(Constants.Event_currentFuelRules);
    }

    /**
     * This shall be used by drivers asking for their DJ entries
     */
    public getDriverJournalMonthly() {
        this.getContracts();
        this.addCall(Constants.Event_CurrentDriverJournalMonthly);
    }

    /**
     * This shall be used by business users asking for a drivers DJ entries
     */
    public getDriverJournalMonthlyForDriver() {
        if (!this.currentJournalUserContract) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
        this.addCall(Constants.Event_CurrentDriverJournalMonthlyForDriver);
    }


    public getDriverJournalMonthlyEditDefaultLog(): DriverJournalMonthlyLog {
        return this.currentDriverJournalMonthlyEditLog;
    }
    public setDriverJournalMonthlyLogDefault(month: number, year: number, contractId: string) {
        this.currentDriverJournalMonthlyEditLog = new DriverJournalMonthlyLog(
            contractId,
            null,
            year,
            month,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }
    public resetDriverJournalMonthlyLogDefault() {
        this.currentDriverJournalMonthlyEditLog = null;
    }

    public setDriverJournalDailyRulesDefault() {
        this.currentDriverJournalDailyRules = new DriverJournalDailyRules(
            new Date().getMonth(),
            new Date().getFullYear()
        );
    }

    public getDriverJournalDaily() {
        this.getContracts();
        this.getVehicle();
        this.addCall(Constants.Event_CurrentDriverJournalDaily);
    }

    public getDriverJournalDailyForDriver() {
        this.getJournalUserContracts();
        this.addCall(Constants.Event_CurrentDriverJournalDailyForDriver);
    }

    public getJournalUsersByQuery() {
        if (this.currentJournalUsers === null) {
            this.addCall(Constants.Event_CurrentJournalUsers);
        }
    }

    public getJournalUserContracts() {
        if (this.currentJournalUserContracts === null) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
    }

    public getAvailableReports() {
        if (this.currentAvailableReports === null) {
            this.addCall(Constants.Event_CurrentFleetAvailableReports);
        }
    }

    public getOldReports() {
        if (this.currentFleetOldReports === null) {
            this.addCall(Constants.Event_CurrentFleetOldReports);
        }
    }

    public getChangeDriverFuelCompanies() {
        if (this.currentChangeDriverFuelCompanies === null) {
            this.addCall("currentChangeDriverFuelCompanies");
        }
    }

    public setCurrentUser(user: AppUser) {
        this.isUserLoggedIn = true;
        this.appUserStore.appUser = user;

        // Let ApplicationInsights attach traces to the authenticated user
        this.monitoringService.setAuthenticatedUser(user.Username);

        // Check if PUSH token is set
        /*
        const pushToken = localStorage.getItem("pushToken");
        if (!pushToken) {
          // Get a new token
          this.wireupFirebasePush();
        }*/
        // this.firebaseService.setupFirebasePush(this.appSettings);

        this.setStorageData(this.appUserStore.appUser, true);

        this.dataStream.next(Constants.Event_currentUser);
    }


    public postUserSettings(model) {
        this.appUserStore.saveUserSettings(model);
    }

    public postSettingsForm(model: UserSettings): void {

        this.appUserStore.saveSettings(model, this.appUserStore.appUser.AuthenticationUserId);
    }

    public postUserLogin(userLogin: UserLogin) {

        userLogin.Username = userLogin.Username.trim();
        this.spinnerService.show();

        this.apiService.postAsync<UserLogin, AppUser>("users/login", userLogin, this.appSettings)
            .then((data: AppUser) => {
                //Enabling the password policy       
                if (data.IsPasswordExpiry) {
                    this.spinnerService.hide();
                    return this.navigationService.forward("change-password", null);
                }
                if (this.isUnderMaintenance && !data.Roles.includes(UserRoleType.MaintenanceUser)) {
                    this.navigationService.openModal('app-under-maintenance');
                    this.spinnerService.hide();
                }
                else if (data instanceof HttpErrorResponse) {
                    this.appUserStore.appUser = AppUser.GetInitialUser();
                    this.spinnerService.hide();
                } else {
                    this.setCurrentUser(data);

                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager) && this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver)) {
                        this.navigationService.forward(returnUrl, null);
                    }

                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver) && !this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager) ||
                        this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.SystemAdminDriver)) {
                        this.navigationService.forward("cars", null);
                    }

                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin) && !this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver) ||
                        this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.SystemAdminFleetAdmin)) {
                        this.navigationService.forward("fleet/overview", null);
                    }

                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin)) {
                        this.navigationService.forward("dashboard", null);
                    }

                    this.dataStream.next('userLogin');
                    this.spinnerService.hide();
                }
            }).catch(() => {
                this.appUserStore.appUser = AppUser.GetInitialUser();
                this.spinnerService.hide();
            });
    }

    public postForgotLoginComponent(resetPasswordRequest: ResetPasswordRequest): Promise<boolean> {
        resetPasswordRequest.Query = resetPasswordRequest.Query.trim();
        return this.resetUserPassword(resetPasswordRequest, "Password/Reset2", true);
    }

    public postResetPassword(resetPasswordRequest: ResetPasswordByIdRequest): Promise<boolean> {
        return this.resetUserPassword(resetPasswordRequest, "Password/Reset/" + resetPasswordRequest.Id, false).then((data: boolean) => {
            if (data) {
                this.apiService.addSuccess(new UserActionSuccess("ResetPasswordBusinessUser"));
                return true;
            }
            return false;
        });
    }

    public postTrackingTestData(name: string, trackingData: Array<AldPosition>): Promise<boolean> {
        return this.apiService.postAsync("testdata/dj", { name: name, trackingInformation: trackingData }, this.appSettings);
    }

    public getTrackingTestData(id: string): Promise<TrackingInformationRequest> {
        return this.apiService.getAsync(`testdata/dj/${id}`, this.appSettings);
    }

    private resetUserPassword(resetPasswordModel: any, url: string, redirect: boolean): Promise<boolean> {
        this.spinnerService.show();
        resetPasswordModel.SetPasswordPath = this.resetPasswordPath;
        let apiPromise: Promise<boolean> = this.apiService.postAsync(url, resetPasswordModel, this.appSettings)
            .then(() => {
                this.spinnerService.hide();
                if (redirect === true) {
                    this.navigationService.forward("forgot-login/confirmation", null);
                }
                return true;
            }).catch(() => {
                this.spinnerService.hide();
                return false;
            });
        return apiPromise;
    }

    public postFleetUser(fleetUser: UserModificationRequest) {

        let possibleBusinessPartners = this.currentFleetUserBusinessPartners;
        let businessPartnerIds: string[] = [];

        const concernId: IBusinessPartnerIdAndReference = this.currentBusinessPartnerIdAndReference.find(x => x.Id === this.currentQueriedBusinessPartnerId || x.Reference === this.currentQueriedBusinessPartnerId);
        fleetUser.ConcernId = concernId.Id;

        // If the new user is a fleet admin, add all business partners and do not bother processing what was selected.
        if (fleetUser.Role === UserRoleType.FleetAdmin) {
            businessPartnerIds.push(concernId.Id);
        }
        else {
            Object.keys(fleetUser).forEach(key => {
                // If the business partner was not selected, and the new user is not a fleet manager, skip this one.
                if (fleetUser[key] !== true) {
                    return;
                }
                let curBp = possibleBusinessPartners.find(bp => bp.Id === key);
                if (curBp && fleetUser[key] === true && fleetUser.Role === UserRoleType.FleetManager) {
                    businessPartnerIds.push(key);
                }
            });
        }

        fleetUser.BusinessPartnerIds = businessPartnerIds;

        if (!fleetUser.Username) {
            fleetUser.Username = fleetUser.Email;
        }

        fleetUser.SetPasswordPath = this.resetPasswordPath;

        this.spinnerService.show();
        // if you have an id it is an update
        if (fleetUser.AuthenticationUserId && fleetUser.AuthenticationUserId.length > 1) {
            this.apiService.putAsync("users/update/", fleetUser, this.appSettings)
                .then((data: any) => {
                    if (this.currentBusinessPartnerSearchQuery &&
                        this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser ||
                            r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver)) {
                        this.currentSearchedFleetUsers = null;
                        this.spinnerService.hide();
                        this.getFleetUsersForBusinessPartner(this.currentBusinessPartnerSearchQuery);
                    }
                    else {
                        this.currentAccessibleFleetUsers = null;
                        this.spinnerService.hide();
                        this.getAccessibleFleetUsers();
                    }
                    this.apiService.addSuccess(new UserActionSuccess("UpdateFleetUser"));
                    return true;
                })
                .catch((err: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.apiService.addError(err);
                });
        }
        // if you have no id it is a create
        else {
            this.apiService.postAsync("users/create", fleetUser, this.appSettings)
                .then((data: any) => {
                    if (this.currentBusinessPartnerSearchQuery &&
                        this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser ||
                            r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver)) {
                        this.currentSearchedFleetUsers = null;
                        this.getFleetUsersForBusinessPartner(this.currentBusinessPartnerSearchQuery);
                        this.spinnerService.hide();
                    }
                    else {
                        this.currentAccessibleFleetUsers = null;
                        this.spinnerService.hide();
                        this.getAccessibleFleetUsers();
                    }
                    this.apiService.addSuccess(new UserActionSuccess("CreateFleetUser"));
                    return true;
                })
                .catch((err: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.apiService.addError(err);
                });
        }

    }

    public postBusinessUser(businessUser: UserModificationRequest) {
        businessUser.SetPasswordPath = this.resetPasswordPath;

        this.spinnerService.show();

        //update business user
        if (businessUser.AuthenticationUserId && businessUser.AuthenticationUserId.length > 1) {
            this.apiService.putAsync("users/updatebusinessuser", businessUser, this.appSettings)
                .then((data: any) => {
                    this.currentBusinessUsers = null;
                    this.spinnerService.hide();
                    this.getBusinessUsers();

                    this.apiService.addSuccess(new UserActionSuccess("UpdateBusinessUser"));
                    return true;
                })
                .catch((err: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.apiService.addError(err);
                });
        } else { //create business user
            this.apiService.postAsync("users/createbusinessuser", businessUser, this.appSettings)
                .then((data: any) => {
                    this.currentBusinessUsers = null;
                    this.spinnerService.hide();
                    this.getBusinessUsers();

                    this.apiService.addSuccess(new UserActionSuccess("CreateBusinessUser"));
                    return true;
                })
                .catch((err: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.apiService.addError(err);
                });
        }
    }

    public postDeleteFleetUser(fleetUserId: string) {
        this.apiService.deleteAsync("users/delete/" + fleetUserId, "", this.appSettings)
            .then((data: any) => {
                if (this.currentBusinessPartnerSearchQuery &&
                    this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser ||
                        r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver)) {
                    this.currentSearchedFleetUsers = null;
                    this.getFleetUsersForBusinessPartner(this.currentBusinessPartnerSearchQuery);
                }
                else {
                    this.currentAccessibleFleetUsers = null;
                    this.getAccessibleFleetUsers();
                }
                this.apiService.addSuccess(new UserActionSuccess("DeleteFleetUser"));
                return true;
            }, () => false)
            .catch((err: HttpErrorResponse) => {
                this.apiService.addError(err);
            });
    }

    public deleteBusinessUser(businessUserId: string) {
        this.apiService.deleteAsync("users/delete/" + businessUserId, "", this.appSettings)
            .then((data: any) => {

                this.currentBusinessUsers = null;
                this.getBusinessUsers();
                this.apiService.addSuccess(new UserActionSuccess("DeleteBusinessUser"));
                return true;
            }, () => false)
            .catch((err: HttpErrorResponse) => {
                this.apiService.addError(err);
            });
    }

    public postFleetDriverNumberChange({ CurrentDriverNumber, NewDriverNumber }): void {

        if (!this.currentDriver) {
            console.warn("No driver.");
            return;
        }

        let driver = this.currentDriver;
        driver.EmployeeNo = NewDriverNumber;

        this.apiService.putAsync("drivers/profile/" + driver.DriverId, driver, this.appSettings)
            .then(() => {
                this.getDriver();
                this.doThenWithSuccessAndForward('FleetDriverNumberChange', "cars");
            })
            .catch((err: HttpErrorResponse) => {
            });

    }

    public postNewPasswordComponent(setPasswordRequest: SetPasswordRequest) {
        setPasswordRequest.SetCode = this.currentPasswordSetCode;
        setPasswordRequest.Password = btoa(setPasswordRequest.Password);
        setPasswordRequest.ConfirmPassword = setPasswordRequest.Password;
        this.apiService.putAsync("Password", setPasswordRequest, this.appSettings)
            .then((data: any) => {
                this.executeLogout(false);
                this.dataStream.next("passwordSet");
            }, () => false)
            .catch((err: HttpErrorResponse) => {

            });
    }



    public getPasswordSetCode(resetCode: string) {
        if (!resetCode) {
            return;
        }
        if (this.currentPasswordSetCode === null || (resetCode !== this.currentPasswordResetCode)) {
            this.currentPasswordSetCode = null;
            this.currentPasswordResetCode = resetCode;
            this.addCall("currentPasswordSetCode");
        }
    }

    private mergePrefixValues<T>(model: T): T {
        Object.keys(model).forEach(key => {
            if (model.hasOwnProperty(key + "_prefix")) {
                model[key] = model[key + "_prefix"] + model[key];
            }
        });
        return model;
    }


    public postMileage(mileage: Mileage) {
        let newdate = new Date(mileage.NewDate)
        let timezoneOffset = (newdate.getTimezoneOffset()) * (-1);
        newdate.setMinutes(timezoneOffset);
        mileage.ContractId = this.currentVehicleContract.Id;
        mileage.NewDate = newdate;
        console.log(newdate);
        this.apiService.postAsync(ApiUrl.MileageUpdate, mileage, this.appSettings)
            .then(() => {
                this.currentVehicle = null;
                this.getVehicle();
                this.navigationService.back();
            })
            .catch((err: HttpErrorResponse) => {
                this.currentVehicle = null;
                this.getVehicle();
            });
    }

    public postCustomerErrorReporting(data: { Message: string, Name: string, Phone: string }): void {

        this.spinnerService.show();
        this.apiService.postAsync("feedback", data, this.appSettings)
            .then(() => {
                this.spinnerService.hide();
                this.navigationService.forward("more", null);
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postDriverJournalMonthlyLog(log: DriverJournalMonthlyLog) {
        log.Month = log.Month + 1;
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = this.currentJournalUser.PersonId;
            }
        }

        if (!log.IsExtraCarSelected) {
            log.BusinessKmTotalExtraCar = 0;
            log.TotalKmExtraCar = 0;
        }

        this.spinnerService.show();
        this.apiService.postAsync("DriverJournal/LogMonth", log, this.appSettings)
            .then(() => {
                this.spinnerService.hide();
                this.currentDriverJournalMonthly = null;
                let isBusinessUser = this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser);
                log.Month = log.Month - 1;
                this.businessTripService.resetBusinessTripKmCountForMonth(log);
                if (isBusinessUser) {
                    this.getDriverJournalMonthlyForDriver();
                    this.navigationService.forward("dashboard/driver-journal", null).then(() => this.navigationService.openModal('update-dj-success'));
                }
                else {
                    this.getDriverJournalMonthly();
                    this.navigationService.forward("journal/driver-journal-monthly", null).then(() => this.navigationService.openModal('update-dj-success'));
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public putDriverJournalDailyLog(log: DriverJournalDailyLog): Promise<DriverJournalDailyLog | HttpErrorResponse> {

        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        const promise = new Promise<DriverJournalDailyLog | HttpErrorResponse>((resolve, reject) => {
            // log.Date = log.Date + "T00:00:00Z";
            log.IsCreate = false;

            this.apiService.putAsync("DriverJournal/LogDaily/" + log.Trip.LogDailyId, log, this.appSettings)
                .then((response: DriverJournalDailyLog) => {
                    this.spinnerService.hide();
                    resolve(response);
                })
                .catch((err: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.businessTripService.resetCurrentBusinessTrip();
                    console.log("Failed to save - probarly no connection");
                    reject(err);
                });

        });

        return promise;
    }

    public postDriverJournalDailyLog(log: DriverJournalDailyLog, isFinalSave: boolean = true): Promise<DriverJournalDailyLog | boolean | HttpErrorResponse> {
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        const promise = new Promise<DriverJournalDailyLog | boolean | HttpErrorResponse>((resolve, reject) => {

            if (log.IsCreate) {

                // log.Date = log.Date + "T00:00:00Z";

                // The date has been formatted
                if (log.Date && log.Date.hasOwnProperty("includes")) {
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                } else {
                    log.Date = new Date(log.Date).toLocaleDateString('sv');
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }


                this.apiService.postAsync("DriverJournal/LogDaily", log, this.appSettings)
                    .then((response: DriverJournalDailyLog) => {

                        // Web manual trip
                        if (!this.appSettings.IsApp) {
                            this.spinnerService.hide();
                            this.currentDriverJournalDaily = null;
                            this.getDriverJournalDaily();
                            this.businessTripService.businessTripUpdate(log);
                            this.businessTripService.resetCurrentBusinessTrip();
                            this.navigationService.forward("journal/driver-journal-daily", null);
                        } else {
                            // App manual
                            if (isFinalSave) {
                                this.spinnerService.hide();
                                this.currentDriverJournalDaily = null;
                                this.getDriverJournalDaily();

                                // Work around for iOS
                                if (this.appSettings.IsApp) {
                                    this.navigationService.forward("cars", null);
                                    setTimeout(() => {
                                        this.navigationService.forward("journal/driver-journal-daily", null);
                                    }, 100);
                                } else {

                                    this.navigationService.forward("journal/driver-journal-daily", null);
                                }
                            }
                            // else App auto
                        }

                        resolve(response);
                    })
                    .catch((err: HttpErrorResponse) => {
                        this.spinnerService.hide();
                        this.businessTripService.resetCurrentBusinessTrip();

                        reject(err);
                    });
            }
            else {

                // The date has been formatted
                if (log.Date && log.Date.hasOwnProperty("includes")) {
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                } else {
                    log.Date = new Date(log.Date).toLocaleDateString('sv');
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }

                this.apiService.putAsync("DriverJournal/EditDailyBusinessTrip/" + log.TripId + "/" + log.DataSource, log, this.appSettings)
                    .then(() => {
                        this.spinnerService.hide();
                        this.currentDriverJournalDaily = null;
                        this.getDriverJournalDaily();
                        this.businessTripService.businessTripUpdate(log);
                        this.businessTripService.resetCurrentBusinessTrip();

                        // Work around for iOS
                        if (this.appSettings.IsApp) {
                            this.navigationService.forward("journal/driver-journal-monthly", null);
                            setTimeout(() => {
                                this.navigationService.forward("journal/driver-journal-daily", null);
                            }, 100);
                        } else {
                            this.navigationService.forward("journal/driver-journal-daily", null);
                        }

                        resolve(true);

                    })
                    .catch((err: HttpErrorResponse) => {
                        this.businessTripService.resetCurrentBusinessTrip();
                        this.spinnerService.hide();
                        // this.navigationService.forward("journal/driver-journal-daily", null);

                        reject(err);
                    });
            }

        });

        return promise;
    }

    public postDriverJournalDailyLogPrivate(log: DriverJournalDailyLog): Promise<DriverJournalDailyLog | boolean | HttpErrorResponse> {
        return this.postDriverJournalDailyLog(log);
    }

    public postDriverJournalDailyLogDelete(logDelete: DriverJournalDailyLogDelete) {
        this.spinnerService.show();
        this.apiService.postAsync("DriverJournal/DeleteDailyBusinessTrip", logDelete, this.appSettings)
            .then(() => {
                this.currentDriverJournalDaily = null;
                this.getDriverJournalDaily();
                this.businessTripService.businessTripDelete(logDelete);
                this.businessTripService.resetCurrentBusinessTrip();
                this.spinnerService.hide();
                // Work around for iOS
                if (this.appSettings.IsApp) {
                    this.navigationService.forward("journal/driver-journal-monthly", null);
                    setTimeout(() => {
                        this.navigationService.forward("journal/driver-journal-daily", null);
                    }, 100);
                }

            })
            .catch((err: HttpErrorResponse) => {
                this.businessTripService.resetCurrentBusinessTrip();
                this.spinnerService.hide();
            });
    }

    public postRecordTrip() {
        this.doThenWithSuccessAndForward("RecordTripComponent", 'journal/driver-journal-daily');
        this.apiService.addSuccess(new UserActionSuccess("RecordTripComponent"));
        this.navigationService.forward('journal/driver-journal-daily', null);
    }

    public postCongestionTaxDailyLog(log: CongestionTaxDailyLog) {
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }

        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        // if it is a business user that is updating journals you will not get vehicle id or contract id 
        // from currentContract or currentVehicle so you only update them if it is not a business user
        let isBusinessUser = this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser);
        if (isBusinessUser && !this.currentJournalUserContract) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
        this.spinnerService.show();
        if (log.IsCreate) {
            this.apiService.postAsync("CTax/LogDaily", log, this.appSettings)
                .then(() => {
                    this.congestionTaxStore.reset();
                    if (!isBusinessUser) {
                        this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                        this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                        this.navigationService.forward("journal/congestion-tax-daily", null);
                    }
                    else {
                        this.congestionTaxStore.getMonthlyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                        this.congestionTaxStore.getDailyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                    }
                    this.spinnerService.hide();
                })
                .catch((err: HttpErrorResponse) => {
                    this.congestionTaxStore.reset();
                    if (!isBusinessUser) {
                        this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                        this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                    }
                    else {
                        this.congestionTaxStore.getMonthlyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                        this.congestionTaxStore.getDailyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                    }
                    this.spinnerService.hide();
                });
        }
        else {
            this.apiService.postAsync("CTax/EditDaily", log, this.appSettings)
                .then(() => {
                    this.congestionTaxStore.reset();
                    if (!isBusinessUser) {
                        this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                        this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                    }
                    else {
                        this.congestionTaxStore.getMonthlyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                        this.congestionTaxStore.getDailyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                    }
                    this.spinnerService.hide();
                    if (!isBusinessUser) {
                        this.navigationService.forward("journal/congestion-tax-daily", null);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.congestionTaxStore.reset();
                    if (!isBusinessUser) {
                        this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                        this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                    }
                    else {
                        this.congestionTaxStore.getMonthlyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                        this.congestionTaxStore.getDailyCTaxForDriver(this.currentJournalUser.PersonId, log.ContractId, true);
                    }
                    this.spinnerService.hide();
                });
        }
    }

    public postCongestionTaxMonthlyLog(log: CongestionTaxMonthlyLog) {

        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        // either Drivers or BusinessUsers can get Daily History. A BusinessUser get it for a driver
        // so you shall use the contract id in the currentJournalUser in those cases
        let isBusinessUser = this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser);
        let contract: IContractVersion = null;
        if (isBusinessUser && this.currentJournalUserContract != null) {
            contract = this.currentJournalUserContract;
        }
        if (isBusinessUser && contract === null) {
            return;
        } else {
            let contractId = isBusinessUser ? contract.ContractId.toString() : this.contractStore.getVehicleContract().Id;
            if (log.IsEdit) {
                this.congestionTaxStore.putMonthlyCTax(contractId, log);
            } else {
                this.congestionTaxStore.postMonthlyCTax(contractId, log);
            }
        }
    }

    public postBlockCard(card: Card, cardsAndTagsService: CardsAndTagsService) {
        this.spinnerService.show();
        this.apiService.postAsync("FuelCard2/Block", card, this.appSettings)
            .then(() => {
                cardsAndTagsService.reloadCardOrTagData();
                return true;
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postCardOrder(companyId: string, cardsAndTagsService: CardsAndTagsService) {
        this.spinnerService.show();
        let contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        let fuelCardOrder = new FuelCardOrder([companyId], contractId);
        this.apiService.postAsync("FuelCard/Order", fuelCardOrder, this.appSettings)
            .then(() => {
                cardsAndTagsService.reloadCardOrTagData();
                // this.spinnerService.hide();
                this.apiService.addSuccess(new UserActionSuccess("FuelCardOrderComponent"));
                return true;
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postCardPinCode(card: Card, cardsAndTagsService: CardsAndTagsService) {
        this.spinnerService.show();
        this.apiService.postAsync("FuelCard2/PincodeOrder", card, this.appSettings)
            .then(() => {
                // this.spinnerService.hide();
                this.apiService.addSuccess(new UserActionSuccess("FuelCardPincodeComponent"));
                cardsAndTagsService.reloadCardOrTagData();
                return true;
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postContactUsEmail(contactUs: ContactUsEmail) {
        this.getContracts();
        this.apiService.postAsync("contactus/SendEmail/" + this.currentVehicleContract.Id, contactUs, this.appSettings)
            .then((data) => {
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postInsuranceClaimFinish(values) {
        this.getContracts();
        let array = values["formArray"];
        let accidentDate: Date = (array[1].AccidentDate === "") ? null : array[1].AccidentDate;
        accidentDate = this.ChangeTimezoneToUTC(accidentDate);
        let cpr = (array[0].CprNumber === undefined) ? null : array[0].CprNumber;
        let claim = new InsuranceClaimFinish(
            array[0].RegistrationNumber,
            array[0].Driver,
            array[0].CompanyName,
            array[0].CompanyAddress,
            array[0].Email,
            array[0].Phone,
            array[1].AccidentLocation,
            array[1].AccidentTime,
            array[1].PoliceStationJounalNumber,
            array[1].VehicleDamageDescription,
            array[1].DescriptionOfAccident,
            array[1].GiveAway,
            array[1].LaneShift,
            array[1].Reversing,
            array[1].LeftTurn,
            array[1].RigthTurn,
            array[1].WhoIsToBlame,
            array[2].CounterPartName,
            array[2].CounterPartAddress,
            array[2].CounterPartPhone,
            array[2].CounterPartRegistrationNumber,
            array[2].CounterPartInsuranceCompany,
            array[2].CounterPartCarDamageDescription,
            array[2].CounterPartMultiple,
            array[3].WitnessName,
            array[3].WitnessAddress,
            array[3].WitnessPhone,
            array[3].WitnessLocation,
            array[4].InjuredPersonsNameAndAddress,
            array[4].InjuredPersonsCprNumbers,
            array[4].InjuredPersonsPhoneNumbers,
            array[4].InjuredPersonsInjuryDescription,
            array[5].MiscDamagesDescription,
            this.uploadedFiles,
            cpr,
            accidentDate,
            array[1].AccidentDuringWorkHours,
            array[1].DayLight,
            array[1].DrivesLicence,
            array[1].PoliceReport,
            array[1].DrugTest,
            array[4].DriverInjury,
            array[4].PassengerInjury,
            array[4].CounterPartInjury
        );
        this.apiService.postAsync("InsuranceClaimFinish/" + this.currentVehicleContract.Id, claim, this.appSettings)
            .then(() => {
                this.uploadedFiles = [];
                this.doThenWithSuccessAndNavBack('InsuranceClaimFinish');
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postProfileForm(driverProfile: DriverProfile) {
        driverProfile.IssuerId = this.appUserStore.appUser.AuthenticationUserId;
        driverProfile.DriverId = this.currentDriver.DriverId;
        driverProfile = this.mergePrefixValues(driverProfile);
        // this.apiService.putAsync("drivers/profile/" + driverProfile.DriverId, driverProfile, this.appSettings)
        this.apiService.putAsync("drivers/profile/" + driverProfile.DriverId, driverProfile, this.appSettings)
            .then((reponse) => {

                if (!reponse) {
                    this.apiService.addError(new HttpErrorResponse({ error: "Failed to update user" }));
                } else {

                    this.currentDriver = null;
                    this.driverStore.reset();
                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver) || this.getStorageValueForIsInTakeOverMode()) {
                        this.driverStore.getDriver();
                        this.getDriver();
                    }

                    if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.FleetAdmin)) {
                        this.tableDataService.setData(driverProfile.Email);
                    }
                    this.doThenWithSuccessAndNavBack("EditDriverProfile");
                }
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postFleetUserProfileForm(fleetUser: FleetUser) {
        fleetUser.FleetManagerId = this.currentFleetUser.FleetManagerId;
        this.apiService.putAsync("fleetUser", fleetUser, this.appSettings)
            .then(() => {
                this.currentFleetUser = null;
                if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager)) {
                    this.getFleetUser();
                }
                if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.FleetAdmin)) {
                    this.tableDataService.setData(fleetUser.Email);
                }
                this.doThenWithSuccessAndNavBack("EditFleetUserProfile");
                return true;
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postFleetDepartmentChange(fleetDepartmentChange: FleetDepartmentChange) {
        if (!this.currentDriver) {
            console.warn("No driver.");
            return;
        }
        let driver = this.currentDriver;
        driver.IssuerId = this.appUserStore.appUser.AuthenticationUserId;
        driver.Department = fleetDepartmentChange.NewDepartment;
        this.apiService.putAsync("drivers/profile/" + driver.DriverId, driver, this.appSettings)
            .then(() => {
                this.doThenWithSuccessAndForward('FleetDepartmentChange', "cars");
                this.currentDriver = null;
                this.getDriver();
            })
            .catch((err: HttpErrorResponse) => {
                this.currentDriver = null;
                this.getDriver();
                this.navigationService.forward("cars", null);
            });

    }

    public postOrderRedcard(cardOrder: CardOrder) {
        cardOrder.Colour = CardColour.Red;
        return this.postOrderCard(cardOrder);
    }
    public postOrderGreencard(cardOrder: CardOrder) {
        cardOrder.Colour = CardColour.Green;
        return this.postOrderCard(cardOrder);
    }

    private postOrderCard(cardOrder: CardOrder) {
        let contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderCard/" + contractId, cardOrder, this.appSettings)
            .then(() => {
                if (cardOrder.Colour === CardColour.Green) {
                    this.doThenWithSuccessAndNavBack("OrderGreencardComponent");
                }
                else if (cardOrder.Colour === CardColour.Red) {
                    this.doThenWithSuccessAndNavBack("OrderRedcardComponent");
                }
                return true;
            }).catch((err: HttpErrorResponse) => {
            });
    }

    public postCertificateOrder(certificateOrder: CertificateOrder) {
        let contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderCertificate/" + contractId, certificateOrder, this.appSettings)
            .then(() => {
                this.doThenWithSuccessAndNavBack("OrderCertificateComponent");
                return true;
            }).catch((err: HttpErrorResponse) => {
            });
    }

    public postOrderDocuments(orderDocuments: OrderDocuments) {
        let contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderDocuments/" + contractId, orderDocuments, this.appSettings)
            .then(() => {
                this.doThenWithSuccessAndNavBack("OrderDocumentsComponent");
                return true;
            }).catch((err: HttpErrorResponse) => {
            });
    }

    public postWorkshopBooking(workshopBooking: WorkshopBooking | any) {
        let path = "";
        if (!(workshopBooking instanceof WorkshopBooking)) {
            workshopBooking = this.mapStepperForm<WorkshopBooking>(workshopBooking, WorkshopBooking);
        }
        if (this.currentWorkshopSearch.ServiceLinkOnly) {
            path = "ServiceLinkBooking";
        } else {
            path = "WorkshopBooking";
        }

        workshopBooking.ContractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        workshopBooking.Date = this.ChangeTimezoneToUTC(workshopBooking.Date);

        this.apiService.postAsync(path, workshopBooking, this.appSettings)
            .then(() => {
                this.doThenWithSuccessAndForward('WorkshopBookComponent', "services");
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postContactUsForm(contactUsEmail: ContactUsEmail) {
        let contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("ContactUs/SendEmail/" + contractId, contactUsEmail, this.appSettings)
            .then(() => true)
            .catch((err: HttpErrorResponse) => {
            });
    }
    public postMarketingConsent(consent: MarketingConsent) {
        this.apiService.postAsync("MarketingConsent", consent, this.appSettings)
            .then(() => {
                this.currentMarketingConsent = null;
                this.getMarketConsent();
                return true;
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postTranslations(translations: Translation[]) {
        let translationsToSave = translations;
        this.apiService.postAsync("languages/translations/", translationsToSave, this.appSettings)
            .then((data) => {
                this.translateService.updateCompanyTranslations(translationsToSave, this.appSettings.CompanyId);
                if (this.previousModal !== null) {
                    this.navigationService.openModal(this.previousModal);
                }
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postChangeLanguage() {
        this.apiService.postAsync("languages/change/" + this.appSettings.LanguageCode, null, this.appSettings)
            .then((data) => {
                this.currentFeed = null;
                this.getFeed();
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postEditLoginForm(editLoginForm: EditLoginForm) {
        let editLogin: EditLogin = new EditLogin(editLoginForm.Username, editLoginForm.CurrentPassword, editLoginForm.Password);
        this.apiService.patchAsync("users/login/edit", editLogin, this.appSettings)
            .then((data) => {
                this.appUserStore.appUser.Username = editLogin.Username;
                this.doThenWithSuccessAndNavBack("EditLoginForm");
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public redirectBasedOnRole() {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager) && this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver)) {
            this.navigationService.forward(returnUrl, null);
        }

        if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver) && !this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager) ||
            this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.SystemAdminDriver)) {
            this.navigationService.forward("cars", null);
        }

        if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin) && !this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.Driver) ||
            this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.SystemAdminFleetAdmin)) {
            this.navigationService.forward("fleet/overview", null);
        }

        if (this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin)) {
            this.navigationService.forward("dashboard", null);
        }

        this.dataStream.next('userLogin');
    }

    public postChangePasswordForm(changePwdForm: ChangePasswordForm) {
        let changePwd: ChangePasswordForm = new ChangePasswordForm('', changePwdForm.Password, '');
        this.apiService.patchAsync<UserLogin, AppUser>("users/login/changepassword", changePwd, this.appSettings)
            .then((data: AppUser) => {
                this.doThenWithSuccessAndNavBack("ChangePasswordForm");
                this.setCurrentUser(data);
                this.redirectBasedOnRole();

            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public postFleetIfrsReportComponent(ifrsReportOrder: IfrsReportOrder) {
        ifrsReportOrder.BusinessPartnerIds = [ifrsReportOrder.BusinessPartnerId];
        ifrsReportOrder.CutOffDate = this.ChangeTimezoneToUTC(ifrsReportOrder.CutOffDate);
        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ifrsReport", ifrsReportOrder, this.appSettings)
            .then((data) => {
                this.doThenWithSuccessAndForward("FleetIfrsReportComponent", "fleet/reports");
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postFleetIfrsEventReportComponent(ifrsReportOrder: IfrsEventReportOrder) {

        ifrsReportOrder.BusinessPartnerIds = [ifrsReportOrder.BusinessPartnerId];
        ifrsReportOrder.StartDate = this.ChangeTimezoneToUTC(ifrsReportOrder.StartDate);
        ifrsReportOrder.EndDate = this.ChangeTimezoneToUTC(ifrsReportOrder.EndDate);

        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ifrsReport", ifrsReportOrder, this.appSettings)
            .then((data) => {
                this.doThenWithSuccessAndForward("FleetIfrsEventReportComponent", "fleet/reports");
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postFleetReportOrderComponent(data: any) {
        let formArray: Array<Object> = data.formArray;
        let firstStep = formArray[0];
        let secondStep = formArray[1];

        let businessPartners = this.currentFleetUserBusinessPartners;
        let selectedBusinessPartners: string[] = [];

        if (secondStep["ReportFor"] === true && this.appUserStore.appUser.Roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.SystemAdminFleetAdmin)) {
            selectedBusinessPartners.push(businessPartners.find(bp => bp.ConcernId === "0").Id);
        }
        else {
            businessPartners.forEach(bp => {
                if (secondStep.hasOwnProperty(bp.Id) && secondStep[bp.Id] === true) {
                    selectedBusinessPartners.push(bp.Id);
                }
            });
        }

        let reportOrder = this.reportService.getOrderValue();
        reportOrder.BusinessPartnerIds = selectedBusinessPartners;
        reportOrder.OrderAll = secondStep['OrderAll'];
        reportOrder.FleetAdminReportWithVariableTypes.forEach(report => {
            report.ReportVariableTypes.forEach(variable => {
                let reportVarId = report.Id + "_" + variable.Id;
                if (firstStep.hasOwnProperty(reportVarId)) {
                    if (variable.IsDate) {
                        variable.ContentTypedFromUser = this.ChangeTimezoneToUTC(firstStep[reportVarId]).toDateString();
                    }
                    else {
                        variable.ContentTypedFromUser = firstStep[reportVarId];
                    }
                }
            });
            if (firstStep.hasOwnProperty(report.Id + "_reportFormat")) {
                report.SelectedFileFormat = firstStep[report.Id + "_reportFormat"];
            }
        });

        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/SubmitReportOrder", reportOrder, this.appSettings)
            .then((data) => {
                this.doThenWithSuccessAndForward("FleetReportOrderComponent", "fleet/reports");
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    postComponents(components: ComponentConfiguration): any {
        this.apiService.postAsync("components/" + this.appSettings.Id, components, this.appSettings)
            .then((data) => {
                this.getFeatureComponentConfiguration();
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    postChangeDriver(values): any {
        let changeDriver = this.mapStepperForm<ChangeDriver>(values, ChangeDriver);
        let lastStepForm = values["formArray"][2];

        if (changeDriver.NotesOnFormerUsersFuelCards === "") {
            changeDriver.NotesOnFormerUsersFuelCards = "-";
        }

        Object.keys(lastStepForm).forEach(element => {
            let fuelCompany = this.currentChangeDriverFuelCompanies.find(f =>
                element.indexOf(f.Name) !== -1
                && lastStepForm[element] === true
                && element.indexOf("_fuelProvider") !== -1);
            if (fuelCompany) {
                changeDriver.ListOfFuelCardsToOrder.push(fuelCompany);
            }
        });

        changeDriver.KmPerYear = this.currentVehicleContract.KilometersPerYear.toString();
        changeDriver.ContractId = this.currentVehicleContract.Id;

        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ChangeDriver", changeDriver, this.appSettings)
            .then((data) => {
                this.revertTakeOverDriver().then(() => {
                    this.doThenWithSuccessAndForward('FleetChangeDriver', "fleet/overview");
                });
                return true;
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public postRefreshToken(): void {
        this.addCall("refreshToken");
    }

    // public postReportApiError(reportError: ReportError) {

    // }

    // public postGlassBooking(value) {
    // }


    public downloadGloveboxDocument(id: string) {
        this.spinnerService.show();
        this.apiService.getFile("file/Glovebox/Download/" + id, this.appSettings)
            .then((data) => {
                this.spinnerService.hide();
            })
            .catch((err: HttpErrorResponse) => {
                this.spinnerService.hide();
            });
    }

    public getContacts() {
        if (this.currentContacts === null) {
            this.addCall(Constants.Event_CurrentContacts);
        }
    }

    public getFeed() {
        if (this.currentFeed === null) {
            this.addCall("currentFeed");
        }
    }

    public getInsurance() {
        if (this.currentInsurance === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentInsurance);
        }
    }

    private executeLogout(redirect: boolean = true): Promise<void> {
        this.nextCallSubscription.unsubscribe();
        return this.apiService.postAsync("Users/Logout", null, this.appSettings)
            .then(() => {
                this.isUserLoggedIn = false;
                this.fleetUserService.setIsInTakeOverMode(false);
                this.showAdminButtons = false;
                this.clearData(true);
                this.resetStores();
                this.setStorageData(AppUser.GetInitialUser(), false);
                this.appUserStore.appUser = AppUser.GetInitialUser();
                this.calls();

                localStorage.removeItem(this.RefreshTokenKey);
                localStorage.setItem(this.UserRoleKey, '0');

                /*
                const pushToken = localStorage.getItem("pushToken");
                if (pushToken) {
                  this.apiService.postAsync(`push/unsubscribe`, { Token: pushToken }, this.appSettings);
                  localStorage.removeItem("pushToken");
                }*/
                this.firebaseService.removeFirebasePush(this.appSettings);

                this.dataStream.next("logout");
            })
            .then(() => {
                if (this.appSettings.IsApp) {
                    if (redirect === true) {
                        this.navigationService.forward("login", null);
                    }
                    this.init();

                }
                else {
                    if (redirect === true) {
                        this.navigationService.reload();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
            });
    }

    public logout() {
        this.executeLogout(true);
    }

    public async logoutAndClearSession() {
        this.executeLogout().then(() => {
            this.appSettings = null;
            this.whoAmI();
        });

    }

    public validateToken(): Promise<string> {
        let settings = this.appSettings;
        this.spinnerService.show();

        return this.apiService.getAsync("token/validateToken", settings).then(
            (data: IValidateToken) => {
                if (data.ValidToken) {
                    this.spinnerService.hide();
                    return this.getAuthorizationHeader();
                }
                else {
                    let refreshToken = this.getRefreshKey();
                    return this.refreshToken(refreshToken).then(
                        () => {
                            this.spinnerService.hide();
                            return this.getAuthorizationHeader();
                        }
                    );
                }
            },
            (err) => {
                this.spinnerService.hide();
                throw err;
            }
        );
    }

    public refreshToken(refreshToken) {

        return this.apiService.getAsync("tokens/refresh/" + refreshToken, this.appSettings)
            .then((data: AppUser) => {
                this.setCurrentUser(data);
                this.next();
                this.dataStream.next("refreshToken");
            })
            .catch((err: HttpErrorResponse) => {
                this.logout();
                this.next();
            });
    }

    private calls() {
        this.nextCallSubscription = this.nextCallStream.asObservable().subscribe(next => {
            switch (next) {
                case "refreshToken":
                    let token = this.getRefreshKey();
                    if (token) {
                        let settings = this.appSettings;
                        if (!settings) {
                            this.next();
                        }
                        else {
                            this.refreshToken(token);
                        }
                    }
                    else {
                        this.next();
                    }
                    break;

                case Constants.Event_CurrentContracts:
                    this.apiService.getAsync<Contract[]>("contract", this.appSettings)
                        .then((data: Contract[]) => {
                            // make distinct - issue on miles because sends dupicate contracts
                            const distinctContracts: Contract[] = [];
                            data.forEach(d => {
                                if (!distinctContracts.some(c => c.Id === d.Id)) {
                                    distinctContracts.push(d);
                                }
                            });

                            this.currentVehicleContracts = distinctContracts.filter(c => isNaN(Number(c.VehicleId)) || c.VehicleId !== "0");
                            this.currentOtherContracts = distinctContracts.filter(c => c.VehicleId === "0");
                            this.currentVehicleContract = this.currentVehicleContracts !== null ? this.currentVehicleContracts[0] : null;
                            this.currentOtherContract = this.currentOtherContracts !== null ? this.currentOtherContracts[0] : null;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentContracts);
                        })
                        .catch((err: HttpErrorResponse) => {

                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentContracts, error: err });
                        });
                    break;
                case Constants.Event_CurrentVehicles:
                    this.apiService.getAsync<Array<Vehicle>>(ApiUrl.Vehicle, this.appSettings)
                        .then((data: Array<Vehicle>) => {
                            this.currentVehicles = data;
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.currentVehicles = null;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentVehicles);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicles, error: err });
                        });
                    break;
                case Constants.Event_CurrentVehicle:
                    if (this.currentVehicleContract.VehicleId) {
                        this.apiService.getAsync("vehicle/" + this.currentVehicleContract.Id + "/" + this.currentVehicleContract.VehicleId, this.appSettings)
                            .then((data: Vehicle) => {
                                this.currentVehicle = data;
                            })
                            .then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentVehicle);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.next();
                                this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicle, error: err });
                            });
                    }
                    else if (this.currentVehicleContract.ExternalVehicleId != '0') {
                        this.apiService.getAsync("externalvehicle/" + this.currentVehicleContract.ExternalVehicleId, this.appSettings)
                            .then((data: Vehicle) => {
                                this.currentVehicle = data;
                            })
                            .then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentVehicle);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.next();
                                this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicle, error: err });
                            });
                    }
                    break;

                case Constants.Event_CurrentBusinessPartner:
                    this.apiService.getAsync<BusinessPartner>("businesspartner/current", this.appSettings)
                        .then((data: BusinessPartner) => {
                            this.currentBusinessPartner = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentBusinessPartner);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;

                case Constants.Event_CurrentFleetUserBusinessPartners:
                    this.apiService.getAsync<BusinessPartner[]>("businesspartners/status/active", this.appSettings)
                        .then((data: BusinessPartner[]) => {
                            this.currentFleetUserBusinessPartners = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFleetUserBusinessPartners);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: err });

                        });
                    break;

                case Constants.Event_CurrentBusinessPartnerIdAndReference:
                    this.apiService.getAsync<IBusinessPartnerIdAndReference[]>("businesspartners/" + this.currentBusinessPartner.Id + "/getIdAndReference", this.appSettings)
                        .then((data: IBusinessPartnerIdAndReference[]) => {
                            this.currentBusinessPartnerIdAndReference = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentBusinessPartnerIdAndReference, error: err });
                        });
                    break;

                case Constants.Event_CurrentDriver:
                    this.apiService.getAsync<Driver>("driver", this.appSettings)
                        .then((data: Driver) => {
                            this.currentDriver = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentDriver);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentDriver, error: err });
                        });
                    break;

                case Constants.Event_CurrentContacts:
                    this.apiService.getAsync<Contacts>("ContactInformation", this.appSettings)
                        .then((data: Contacts) => {
                            this.currentContacts = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentContacts);
                        })
                        .catch((err: HttpErrorResponse) => {

                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentContacts, error: err });
                        });
                    break;

                case "currentFeed":
                    this.apiService.getAsync<FeedItem[]>("Feed", this.appSettings)
                        .then((data: FeedItem[]) => {
                            const feed = new Feed();
                            feed.Items = data;
                            this.currentFeed = feed;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentFeed");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;

                case Constants.Event_CurrentTireCenter:
                    this.apiService.getAsync("TireCenter/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: TireCenter) => {
                            debugger;
                            this.currentTireCenter = data;
                        }).then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentTireCenter);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentTireCenter, error: err });
                        });
                    break;
                case Constants.Event_CurrentTireBranch:
                    this.apiService.getAsync("tire/branch/contract/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: ITireBranch[]) => {
                            this.currentTireBranch = data[0];
                        }).then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentTireBranch);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBranch, error: err });
                        });
                    break;
                case Constants.Event_CurrentTireBookings:
                    if (this.currentTireBranch) {
                        this.apiService.getAsync<Array<ITireBooking>>("tire/booking/" + this.currentTireBranch.CenterType + "/" + this.currentVehicleContract.Id, this.appSettings)
                            .then((data: Array<ITireBooking>) => {
                                this.currentTireBookings = data;
                            }).then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentTireBookings);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.next();
                                this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBookings, error: err });
                            });
                    }
                    break;

                case "currentTireCenterAvailableTimes":
                    this.apiService.getAsync<AppointmentTimeslots[]>("TireCenter/appointments/availabletime/" + this.currentVehicleContract.Id + "/" + this.DatePipe.transform(this.currentBookingFormSelectedDate, "yyyy-MM-dd"), this.appSettings)
                        .then((data: AppointmentTimeslots[]) => {
                            if (!(data instanceof HttpErrorResponse))
                                this.currentAvailableTimes = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentAvailableTimes");
                        });
                    break;

                case Constants.Event_CurrentTireBranchTimes:
                    const serviceTypeOriginId = this.getCurrentBookingFormSelectedServiceType().Id;

                    let fromDate = new Date(this.getCurrentBookingFormSelectedDate());
                    let addedDays = 1;
                    if (fromDate.getDay() === 5) {
                        addedDays = 3;
                    }
                    if (fromDate.getDay() === 6) {
                        addedDays = 3;
                    }
                    if (fromDate.getDay() === 0) {
                        addedDays = 2;
                    }
                    let toDate = new Date(fromDate).setDate(fromDate.getDate() + addedDays);

                    let fromFormatted = this.DatePipe.transform(fromDate, "yyyy-MM-dd");
                    let toFormatted = this.DatePipe.transform(toDate, "yyyy-MM-dd");

                    this.apiService.getAsync<Array<ITireBranchTimeslot>>("tire/timeslot/" + this.currentTireBranch.CenterType + "/" + this.currentVehicleContract.Id + "/" + this.currentTireBranch.OriginId + "?serviceTypeOriginId=" + serviceTypeOriginId + "&from=" + fromFormatted + "&to=" + toFormatted, this.appSettings)
                        .then((data: Array<ITireBranchTimeslot>) => {
                            if (!(data instanceof HttpErrorResponse))
                                this.currentTireBranchTimeslots = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentTireBranchTimes);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBranchTimes, error: err });
                        });
                    break;

                case Constants.Event_CurrentWorkshops:
                    // this needs to be here to map the object in the right way for the backend
                    const workshopSearch = this.currentWorkshopSearch;
                    const parameters = this.makeQueryParameters(workshopSearch);

                    if (workshopSearch.PostalCode !== null) {
                        workshopSearch.Latitude = 0;
                        workshopSearch.Longitude = 0;
                    }

                    if (!this.currentVehicleContract) {
                        this.next();
                        break;
                    }
                    if (workshopSearch.Category !== null) {
                        this.apiService.getAsync<Workshop[]>("FindNearest/Partners/" + this.currentVehicleContract.Id + "?" + parameters, this.appSettings)
                            .then((data: Workshop[]) => {
                                this.currentWorkshops = data;
                            })
                            .then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentWorkshops);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.currentWorkshops = [];
                                this.dataStream.next(Constants.Event_CurrentWorkshops);
                                this.apiService.addError(err);
                                this.next();
                            });
                        break;
                    } else {
                        this.apiService.getAsync<Workshop[]>("Workshop/" + this.currentVehicleContract.Id + "?" + parameters, this.appSettings)
                            .then((data: Workshop[]) => {
                                this.currentWorkshops = data;
                            })
                            .then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentWorkshops);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.dataStream.next(Constants.Event_CurrentWorkshops);
                                this.currentWorkshops = [];
                                this.apiService.addError(err);
                                this.next();
                            });
                        break;
                    }
                case Constants.Event_CurrentFleetUser:
                    this.apiService.getAsync("FleetUser/current", this.appSettings)
                        .then((data: FleetUser) => {
                            this.currentFleetUser = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFleetUser);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUser, error: err });
                        });
                    break;
                case "currentAccessibleFleetUsers":
                    this.apiService.getAsync("users/businessPartner/fleetmanagers", this.appSettings)
                        .then((data: User[]) => {
                            this.currentAccessibleFleetUsers = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentAccessibleFleetUsers");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;

                case Constants.Event_BusinessUsers:
                    this.apiService.getAsync("users/businessusers", this.appSettings)
                        .then((data: User[]) => {
                            this.currentBusinessUsers = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_BusinessUsers);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;

                case Constants.Event_CurrentFleetOverview:
                    this.apiService.getAsync("/FleetUser/FleetOverview", this.appSettings)
                        .then((data: FleetOverview) => {
                            this.currentFleetUserFleetOverview = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFleetOverview);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetOverview, error: err });
                        });
                    break;
                case "currentMarketingConsent":
                    this.apiService.getAsync("MarketingConsent/State", this.appSettings)
                        .then((data: MarketingConsent) => {
                            this.currentMarketingConsent = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentMarketingConsent");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;

                case Constants.Event_CurrentInsurance:
                    if (!this.currentVehicleContract) {
                        this.next();
                        break;
                    }

                    this.apiService.getAsync("Insurance/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: Insurance) => {
                            this.currentInsurance = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentInsurance);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentInsurance, error: err });
                        });
                    break;

                case Constants.Event_CurrentFuelCards:
                    if (!this.currentVehicleContract) {
                        this.next();
                        break;
                    }
                    this.apiService.getAsync("FuelCard2/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: CardResponse) => {
                            this.currentFuelCards = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFuelCards);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelCards, error: err });
                        });
                    break;
                case Constants.Event_CurrentDriverCards:
                    this.apiService.getAsync("DriverCard/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: CardResponse) => {
                            this.currentDriverCards = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentDriverCards);
                        })
                        .catch((err: HttpErrorResponse) => {
                            console.warn("Faildet to get DriverCards: ", err);

                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentDriverCards, error: err });
                        });
                    break;
                case "currentTollTags":
                    this.apiService.getAsync("TollTag/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: CardResponse) => {
                            this.currentTollTags = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentTollTags");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case "currentChargingTags":
                    this.apiService.getAsync("ChargingTag/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: CardResponse) => {
                            this.currentChargingTags = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentChargingTags");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case Constants.Event_CurrentFuelCompanies:
                    this.apiService.getAsync("Fueling/FuelProvider/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: Array<FuelCompany>) => {
                            this.currentFuelCompanies = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFuelCompanies);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelCompanies, error: err });
                        });
                    break;
                case Constants.Event_CurrentFuelSummary:
                    this.apiService.getAsync("Fueling/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: FuelingSummary) => {
                            this.currentFuelingSummary = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFuelSummary);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelSummary, error: err });
                        });
                    break;
                case "currentContactUsSubjects":
                    this.apiService.getAsync<GetSubjectsAndCategoriesResponse>("ContactUs/GetSubjectsAndCategories/", this.appSettings)
                        .then((data: GetSubjectsAndCategoriesResponse) => {
                            this.currentContactUsSubjects = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentContactUsSubjects");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case Constants.Event_CurrentFleetAvailableReports:
                    this.apiService.getAsync<FleetUserAllReportsViewModel>("FleetUser/ReportsAllData", this.appSettings)
                        .then((data: FleetUserAllReportsViewModel) => {
                            this.currentAvailableReports = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentFleetAvailableReports);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetAvailableReports, error: err });
                        });
                    break;
                case "currentPasswordSetCode":
                    this.apiService.getAsync("password/validateLink/" + this.currentPasswordResetCode, this.appSettings)
                        .then((data: string) => {
                            this.currentPasswordSetCode = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentPasswordSetCode");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case "currentChangeDriverFuelCompanies":
                    this.apiService.getAsync("FleetUser/ChangeDriverFuelCompanies/" + this.currentVehicleContract.Id, this.appSettings)
                        .then((data: Array<FuelCompany>) => {
                            this.currentChangeDriverFuelCompanies = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentChangeDriverFuelCompanies");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case "currentBusinessDocuments":
                    this.apiService.getAsync("BusinessDocuments/" + this.currentBusinessPartner.Id, this.appSettings)
                        .then((data: BusinessDocuments) => {
                            this.currentBusinessDocuments = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentBusinessDocuments");
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                        });
                    break;
                case "currentDrivingSettings":
                    this.apiService.getAsync("DriverJournal/DrivingSettings/", this.appSettings)
                        .then((data: DrivingSettings) => {
                            this.currentDrivingSettings = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next("currentDrivingSettings");
                        });
                    break;
                case Constants.Event_CurrentJournalUsers:
                    this.spinnerService.show();
                    this.apiService.getAsync("users/SearchAll?query=" + this.currentJournalUserQueryAndType.query + "&parameteridx=" + JournalUserQueryTypes[this.currentJournalUserQueryAndType.type], this.appSettings)
                        .then((data: IJournalUser[]) => {
                            this.currentJournalUsers = data;
                        })
                        .then(() => {
                            this.doThen(Constants.Event_CurrentJournalUsers);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.doCatch(err);
                        });
                    break;
                case Constants.Event_CurrentJournalUserContracts:
                    let from = this.DatePipe.transform(this.ChangeTimezoneToUTC(this.currentJournalUserContractsTimeSpan.from).toUTCString(), "yyyy-MM-dd");
                    let to = this.DatePipe.transform(this.ChangeTimezoneToUTC(this.currentJournalUserContractsTimeSpan.to).toUTCString(), "yyyy-MM-dd");
                    if (this.currentJournalUserContracts === null) {
                        this.apiService.getAsync("contracts/" + this.currentJournalUser.PersonId + "?from=" + from + "&to=" + to, this.appSettings)
                            .then((data: Array<IContractVersion>) => {
                                data.sort((c1, c2) => new Date(c2.StartDate).getTime() - new Date(c1.StartDate).getTime());
                                this.currentJournalUserContracts = data;
                                if (data.length > 0) {
                                    this.currentJournalUserContract = data[0];
                                }
                            })
                            .then(() => {
                                this.doThen(Constants.Event_CurrentJournalUserContracts)
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.doCatch(err);
                            });
                    }
                    break;
                case Constants.Event_currentFuelRules:
                    if (this.currentFuelRules === null) {
                        this.apiService.getAsync("FuelProfiles/fuelrules/" + this.currentVehicleContract.Id, this.appSettings)
                            .then((data: FuelRules) => {
                                this.currentFuelRules = data;
                            })
                            .then(() => {
                                this.doThen(Constants.Event_currentFuelRules)
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.doCatch(err);
                            });
                    }
                    break;
                case Constants.Event_CurrentDriverJournalMonthly:
                    this.spinnerService.show();
                    this.apiService.getAsync("DriverJournal/MonthHistory/" + this.currentVehicleContract.Id + "/" + this.currentDriverJournalMonthlyRules.Year, this.appSettings)
                        .then((data: DriverJournalMonthly) => {
                            if (data.PreviousMonthLoggingAlert.Month) {
                                data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
                            }

                            data.Months.forEach(entry => {
                                entry.Month = entry.Month - 1;
                            });

                            this.currentDriverJournalMonthly = data;
                        })
                        .then(() => {
                            this.doThen(Constants.Event_CurrentDriverJournalMonthly);
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.doCatch(err);
                        });
                    break;
                // this call is for business users to get the DJ for a specific driver
                case Constants.Event_CurrentDriverJournalMonthlyForDriver:
                    this.spinnerService.show();
                    let contractVersion: IContractVersion = this.currentJournalUserContract;
                    if (contractVersion && this.currentJournalUserContracts && this.currentJournalUserContracts.length > 0) {
                        contractVersion = this.currentJournalUserContract;
                    }
                    if (contractVersion === null || this.currentJournalUser === null) {
                        break;
                    }
                    else {
                        let contractId = contractVersion ? contractVersion.ContractId : this.currentVehicleContract.Id;
                        this.apiService.getAsync("DriverJournal/MonthHistory/" + this.currentJournalUser.PersonId + "/" + contractId + "/" + this.currentDriverJournalMonthlyRules.Year, this.appSettings)
                            .then((data: DriverJournalMonthly) => {
                                if (data.PreviousMonthLoggingAlert.Month) {
                                    data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
                                }

                                data.Months.forEach(entry => {
                                    entry.Month = entry.Month - 1;
                                });

                                this.currentDriverJournalMonthly = data;
                            })
                            .then(() => {
                                this.doThen(Constants.Event_CurrentDriverJournalMonthlyForDriver);
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.doCatch(err);
                            });
                    }
                    break;
                case Constants.Event_CurrentDriverJournalDaily:
                    // this.spinnerService.show();

                    if (!this.currentDriverJournalDailyRules) {
                        break;
                    }
                    this.apiService.getAsync("DriverJournal/DailyHistory/" + this.currentVehicleContract.Id + "/" + (this.currentDriverJournalDailyRules.Month + 1) + "/" + this.currentDriverJournalDailyRules.Year, this.appSettings)
                        .then((data: DriverJournalDaily) => {
                            this.currentDriverJournalDaily = data;
                        })
                        .then(() => {
                            this.next();
                            this.dataStream.next(Constants.Event_CurrentDriverJournalDaily);
                            // this.spinnerService.hide();
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.next();
                            // this.spinnerService.hide();
                        });
                    break;

                case Constants.Event_CurrentDriverJournalDailyForDriver:
                    // this.spinnerService.show();
                    if (!this.currentDriverJournalDailyRules) {
                        break;
                    }
                    let contract = null;
                    if (this.currentJournalUserContracts != null && this.currentJournalUserContracts.length > 0) {
                        contract = this.currentJournalUserContracts[0];
                    }
                    if (contract === null) {
                        this.currentDriverJournalMonthly = null;
                    }
                    else {
                        let contractId = contract['ContractId'];
                        this.apiService.getAsync("DriverJournal/DailyHistory/" + this.currentJournalUser.PersonId + "/" + contractId + "/" + (this.currentDriverJournalDailyRules.Month + 1) + "/" + this.currentDriverJournalDailyRules.Year, this.appSettings)
                            .then((data: DriverJournalDaily) => {
                                this.currentDriverJournalDaily = data;
                            })
                            .then(() => {
                                this.next();
                                this.dataStream.next(Constants.Event_CurrentDriverJournalDailyForDriver);
                                // this.spinnerService.hide();
                            })
                            .catch((err: HttpErrorResponse) => {
                                this.next();
                                // this.spinnerService.hide();
                            });
                    }
                    break;
                case null:
                    // If the command is null, then we have reached the end of the call queue.
                    this.spinnerService.hide();
                    break;
                default:
                    this.spinnerService.hide();
                    console.warn("No api call found for event: ", next);
                    break;
            }
        });
    }

    private doCatch(err) {
        console.warn(err);
        this.apiService.addError(err);
        this.spinnerService.hide();
        this.next();
    }

    private doThen(event) {
        this.dataStream.next(event);
        this.next();
        this.spinnerService.hide();
    }

    private doThenWithSuccessAndForward(successName, path) {
        this.apiService.addSuccess(new UserActionSuccess(successName));
        this.spinnerService.hide();
        this.navigationService.forward(path, null);
    }


    private doThenWithSuccessAndNavBack(successName) {
        this.apiService.addSuccess(new UserActionSuccess(successName));
        this.spinnerService.hide();
        this.navigationService.back();
    }

    private addCall(name: string) {
        if (!this.callsQueue.some(c => c === name)) {
            this.callsQueue.push(name);
            if (this.nextCallStream.value == null) {
                this.nextCallStream.next(name);
            }
        }
    }

    public isEventEnqueued(typeOfEvent) {
        return this.callsQueue.some(c => c === typeOfEvent);
    }

    private next() {
        this.callsQueue.shift();
        if (this.callsQueue.length > 0) {
            this.nextCallStream.next(this.callsQueue[0]);
        } else {
            this.nextCallStream.next(null);
        }
    }

    private makeQueryParameters(obj: object): string {
        const objectPairs: Array<string> = [];
        Object.keys(obj).forEach(key => {
            objectPairs.push([key, obj[key]].join("="));
        });

        return objectPairs.join("&");
    }

    private clearData(clearUser: boolean) {
        Object.keys(this).forEach(key => {
            if (key.startsWith("current")) {
                if ((key === Constants.Event_currentUser && clearUser || key !== Constants.Event_currentUser) && key !== "currentRuleBook" && key !== "currentFleetUserFleetOverview" && key !== "currentFleetUserBusinessPartners") {

                    this[key] = null;
                }
            }
        });
    }

    private mapStepperForm<T>(values, type: (new () => T)): T {
        let typeObject = new type();
        return this.mapStepperFormObjectProvided(values, typeObject);
    }

    private mapStepperFormObjectProvided<T>(form, typeObject: T): T {
        let array = form["formArray"];
        array.forEach(element => {
            Object.keys(element).forEach(key => {
                if (typeObject.hasOwnProperty(key)) {
                    typeObject[key] = element[key];
                }
            });
        });

        return typeObject;
    }

    /* All dates passed to the server should be in UTC to avoid timezone problems.
     However, the user is able to select a date/timeslot in the user's local time,
     and therefore dates need to be handled before passing them on to the backend.
     Particularly date pickers, where you do not need the time of day,
     it can be problematic to pass it to the server because in that process
     it is converted to UTC, and thus the date can potentially change.
  
     This function changes the timezone of the date to UTC but it does not change
     the actual time, so 2019-02-27 4:00:00 will still be 2019-02-27 4:00:00 after
     the change.
    */
    private ChangeTimezoneToUTC(date: Date): Date {

        let hour = date.getHours();
        // Convert the timezone offset from minutes to hours and multiply with -1 so we make up the difference.
        let timezoneOffset = (date.getTimezoneOffset() / 60) * (-1);
        date.setHours((hour + timezoneOffset));
        return date;
    }

    public setCurrentBookingFormSelectedDate(date: Date) {
        this.currentBookingFormSelectedDate = this.ChangeTimezoneToUTC(date);
    }

    public getCurrentBookingFormSelectedDate(): Date {
        return this.currentBookingFormSelectedDate;
    }
    public setCurrentBookingFormSelectedServiceType(type: IBookingServiceType) {
        this.currentBookingFormSelectedServiceType = type;
    }
    public getCurrentBookingFormSelectedServiceType(): IBookingServiceType {
        return this.currentBookingFormSelectedServiceType;
    }

    private setStorageData(appUser: AppUser, overrideRoles: boolean): void {
        localStorage.setItem(this.RefreshTokenKey, appUser.RefreshKey);
        if (appUser.Roles && overrideRoles) {
            let roles = appUser.Roles.join();
            localStorage.setItem(this.UserRoleKey, roles);
        }
    }

    public getRefreshKey(): string {
        let refreshToken = localStorage.getItem(this.RefreshTokenKey);
        return refreshToken;
    }

    public getPreviousUserRoles(): UserRoleType[] {
        let rolesString = localStorage.getItem(this.UserRoleKey);
        let rolesArray: string[] = [];
        if (rolesString) {
            rolesArray = rolesString.split(",");
        }
        let userRoles: UserRoleType[] = [];
        rolesArray.forEach(r => {
            userRoles.push(Number.parseInt(r));
        });
        return userRoles;
    }

    public setDataForRequestHeaders(token: string) {
        localStorage.setItem('api_token', token);
    }

    public isInternalRequest(url: string) {
        let resp = false;
        if (this.defaultSettings) {
            resp = url.includes(this.defaultSettings.ApiBaseUrl);
        }
        if (this.appSettings && !resp) {
            resp = url.includes(this.appSettings.ApiBaseUrl);
        }

        return resp;
    }

    public getAuthorizationHeader() {
        if (this.defaultSettings) {
            return localStorage.getItem("api_token");
        }

        return null;
    }

    public getTraceHeader() {
        if (this.appSettings) {
            return this.appSettings.TraceId;
        }

        return null;
    }

    public getCompany(): string {
        if (this.appSettings.CompanyId === 1) {
            return 'ald';
        }
        if (this.appSettings.CompanyId === 2) {
            return 'nf';
        }
    }

    //SD-821:We need to send different service type based on the vehicletype fo Euromaster booking.
    //We are getting insurance group id from miles and below vehicle type and insurnace group mapping is given by Euromaster.
    public getTireCenterServiceTypeByInsuranceGroup(tireBranch: ITireBranch, insuranceGroupId: number): ITireBranchServiceType {
        const insuranceGroupVehicleTypeMapping: { insuranceGroup: string, vehicleType: string }[] = [
            { insuranceGroup: "203153", vehicleType: "PV" },
            { insuranceGroup: "203155", vehicleType: "LLV" },
            { insuranceGroup: "203156", vehicleType: "PV" },
            { insuranceGroup: "203158", vehicleType: "PV" },
            { insuranceGroup: "203159", vehicleType: "LLV" },
            { insuranceGroup: "203160", vehicleType: "PV" },
            { insuranceGroup: "203161", vehicleType: "PV" },
            { insuranceGroup: "203162", vehicleType: "PV" },
            { insuranceGroup: "203163", vehicleType: "PV" },
            { insuranceGroup: "203164", vehicleType: "PV" },
            { insuranceGroup: "203165", vehicleType: "PV" },
            { insuranceGroup: "203166", vehicleType: "LLV" },
            { insuranceGroup: "203167", vehicleType: "LLV" },
            { insuranceGroup: "203168", vehicleType: "LLV" },
            { insuranceGroup: "203169", vehicleType: "LLV" }
        ];

        const group = insuranceGroupVehicleTypeMapping.find(i => i.insuranceGroup === insuranceGroupId.toString());
        let serviceType: ITireBranchServiceType = null;

        if (group != null) {
            //for LLV vehicle the service type will be Säsongsskifte - Lätt lastbil
            if (group.vehicleType.toLowerCase() === "llv") {
                serviceType = tireBranch.ServiceTypes.find(st => st.Option === TireBranchServiceTypeOption.SeasonChange && st.Name.match(/Säsongsskifte\s*-\s*Lätt\s*lastbil\s*\(\d+\s*min\)/) != null);
            }
            //for PV vehicle the service type will be Säsongsskifte
            else if (group.vehicleType.toLowerCase() === "pv") {
                serviceType = tireBranch.ServiceTypes.find(st => st.Option === TireBranchServiceTypeOption.SeasonChange && st.Name.match(/Säsongsskifte\s*\(\d+\s*min\)/) != null);
            }
        }
        return serviceType;
    }
}

