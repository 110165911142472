var UserActionSuccess = /** @class */ (function () {
    /** The componentBaseTranslationTag should be the name of component (e.g. OrderRedcardComponent) which "caused" the successful action */
    function UserActionSuccess(componentBaseTranslationTag) {
        this.componentBaseTranslationTag = componentBaseTranslationTag;
    }
    UserActionSuccess.prototype.GetDescriptionTranslationTag = function () {
        return this.componentBaseTranslationTag + ".successful_api_post_action_description_t";
    };
    UserActionSuccess.prototype.GetTitleTranslationTag = function () {
        return this.componentBaseTranslationTag + ".successful_api_post_action_header_t";
    };
    return UserActionSuccess;
}());
export { UserActionSuccess };
