<app-modal size="small">
  <div modal-header>
    <app-translate translationName="FeatureWarning.title_t">
      <span translate-text>
        {{ 'FeatureWarning.title_t'| translate }}
      </span>
    </app-translate>
  </div>
  <div modal-body>
    <h2 class="primary-text">
      <app-translate translationName="FeatureWarning.subtitle_t">
        <span translate-text color="primary">
          {{ 'FeatureWarning.subtitle_t'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="FeatureWarning.description_t">
        <span translate-text>
          {{ 'FeatureWarning.description_t'| translate }}
        </span>
      </app-translate>
    </p>
    <br />
    <h2 class="primary-text">
      <app-translate translationName="FeatureWarning.subtitle2_t">
        <span translate-text>
          {{ 'FeatureWarning.subtitle2_t'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="FeatureWarning.description2_t">
        <span translate-text>
          {{ 'FeatureWarning.description2_t'| translate }}
        </span>
      </app-translate>
    </p>
    <br />
    <h2 class="primary-text">
      <app-translate translationName="FeatureWarning.subtitle3_t">
        <span translate-text>
          {{ 'FeatureWarning.subtitle3_t'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="FeatureWarning.description3_t">
        <span translate-text>
          {{ 'FeatureWarning.description3_t'| translate }}
        </span>
      </app-translate>
    </p>
  </div>
</app-modal>