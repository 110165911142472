<app-modal *ngIf="isFeature('contacts') && contacts">
  <div modal-header>
    <app-translate translationName="InsuranceContactsComponent.Header_t">
      <span translate-text>{{ 'InsuranceContactsComponent.Header_t' | translate}}</span>
    </app-translate>
  </div>
  <div modal-body>
    <!-- road assistance contact -->
    <div class="element-container accent" *ngIf="isContent('road_assistance_contact')">
      <h1 class="white-text">
        <app-translate translationName="InsuranceContactsComponent.road_assistance_contact_t">
          <span translate-text>{{ 'InsuranceContactsComponent.road_assistance_contact_t' | translate}}</span>
        </app-translate>
      </h1>
      <div class="sub-box">
        <p>
          <app-translate translationName="InsuranceContactsComponent.road_assistance_description_t">
            <span translate-text>{{ 'InsuranceContactsComponent.road_assistance_description_t' | translate}}</span>
          </app-translate>
        </p>

        <a *ngIf="road_assistance_contact" [href]="'tel:'+road_assistance_contact.Phone">
          <button mat-raised-button color="warn">
            <mat-icon class="icon-btn icon-md" svgIcon="phone"></mat-icon>
            {{road_assistance_contact.Phone}}
          </button>
        </a>
      </div>
    </div>

    <!-- useful contact list -->
    <div class="element-container">
      <h1>
        <app-translate translationName="InsuranceContactsComponent.contact_items_headline_t">
          <span translate-text>{{ 'InsuranceContactsComponent.contact_items_headline_t' | translate}}</span>
        </app-translate>&nbsp;
      </h1>
      <div class="sub-box">
        <ul *ngIf="contacts" class="list">
          <div *ngFor="let contact of contacts.ContactInformations">
            <div *ngIf="isContent(contact.Role)" fxLayout="row" fxLayoutAlign="space-between center" class="list-item">
              <p fxFlex="0 1 100%">
                <app-translate translationName="{{'InsuranceContactsComponent.' + contact.Role + '_t'}}">
                  <span translate-text>{{ 'InsuranceContactsComponent.' + contact.Role + '_t' | translate}}</span>
                </app-translate>
              </p>
              <a [href]="'tel:'+contact.Phone">
                <button mat-raised-button color="warn">
                  <mat-icon class="icon-btn icon-md" svgIcon="phone"></mat-icon>
                  {{contact.Phone}}
                </button>
              </a>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</app-modal>