export class ComponentAction {
    public Name: string;
    public Type: ComponentActionType = ComponentActionType.Route;
    public Next: string;
}

export enum ComponentActionType {
    Route = 0,
    RouteModal = 1,
    Link = 2,
    File = 3,
    Phone = 4
}
