/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/translate/translate.component.ngfactory";
import * as i2 from "../../shared/translate/translate.component";
import * as i3 from "../../../translation/customTranslateService";
import * as i4 from "../../../services/navigation.service";
import * as i5 from "../../../services/modals/modal.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/modal/modal.component.ngfactory";
import * as i9 from "../../shared/modal/modal.component";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "./translate-list";
var styles_TranslateListComponent = [];
var RenderType_TranslateListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TranslateListComponent, data: {} });
export { RenderType_TranslateListComponent as RenderType_TranslateListComponent };
function View_TranslateListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " : "])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "app-translate", [], null, null, null, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(3, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.TranslationName, ""); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.TranslationName; _ck(_v, 1, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.context.$implicit.TranslationName)); _ck(_v, 5, 0, currVal_2); }); }
function View_TranslateListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TranslateListComponent_2)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.Translations; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.GroupName; _ck(_v, 2, 0, currVal_0); }); }
export function View_TranslateListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-modal", [], null, null, null, i8.View_ModalComponent_0, i8.RenderType_ModalComponent)), i0.ɵdid(1, 114688, null, 0, i9.ModalComponent, [i4.NavigationService], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "div", [["modal-header", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Translation List "])), (_l()(), i0.ɵeld(4, 0, null, 2, 3, "div", [["modal-body", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "component-configs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TranslateListComponent_1)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, 3, 3, "div", [["modal-footer", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i0.ɵdid(10, 180224, null, 0, i11.MatButton, [i0.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, [" Close "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.translationGroups; _ck(_v, 7, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 10).disabled || null); var currVal_2 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_TranslateListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-translate-list", [], null, null, null, View_TranslateListComponent_0, RenderType_TranslateListComponent)), i0.ɵdid(1, 114688, null, 0, i15.TranslateListComponent, [i4.NavigationService, i3.CustomTranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TranslateListComponentNgFactory = i0.ɵccf("app-translate-list", i15.TranslateListComponent, View_TranslateListComponent_Host_0, {}, {}, []);
export { TranslateListComponentNgFactory as TranslateListComponentNgFactory };
