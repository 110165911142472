import { ITollStation } from "../../sharedtypes/interfaces/sharedtypes.interface";

export class MapSettings {
  constructor(
    public RequiresDirections: boolean,
    public Lat: number = null,
    public Lng: number = null,
    public Directions: MapSettingsDirections = null,
    public CanShowMap: boolean = null,
    public TollStations: ITollStation[] = []
  ) {
  }
}

export class MapSettingsDirections {
  constructor(
    public OriginAddress: string,
    public OriginPosition: { lat: number, lng: number },
    public DestinationAddress: string,
    public DestinationPosition: { lat: number, lng: number }
  ) { }
}
