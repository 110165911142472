/**
 * MyALD are divided into different Contents that can be active or passive depending if the deployed app shall
 * use the Content or not. We therefore need to divide the settings after Contents so when a user shall updates
 * his/hers settings he/she can only see the ones for the active Contents.
 */
var UserSettings = /** @class */ (function () {
    function UserSettings() {
        this.ContentSettings = new Array();
    }
    return UserSettings;
}());
export { UserSettings };
/**
 * The Content the settings belongs to
 */
var ContentSettings = /** @class */ (function () {
    function ContentSettings() {
        this.Settings = new Map();
    }
    return ContentSettings;
}());
export { ContentSettings };
