
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AppSettingsStore } from '../../../stores/DataStores';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-app-maintenance-notice',
  templateUrl: './app-maintenance-notice.component.html',
  styleUrls: ['./app-maintenance-notice.component.scss']
})
export class AppMaintenanceNoticeComponent implements OnInit, OnDestroy {
  constructor(private translateService: CustomTranslateService,
    private settingsStore: AppSettingsStore) { }

  ngOnInit() {
  }
  getMaintenanceMessage() {
    if (this.isTranslationLoaded) {
      const maintenance = JSON.parse(localStorage.getItem('maintenance'));
      return this.translateService.get("AppMaintenanceNoticeComponent.body_t").pipe(
        map((text) => {
          const localeId = this.settingsStore.getLocalId();
          const maintenanceStart = new Date(maintenance[1]).toLocaleDateString(localeId) + " " + new Date(maintenance[1]).toLocaleTimeString(localeId, { hour: '2-digit', minute: '2-digit' });
          const maintenanceEnd = new Date(maintenance[2]).toLocaleDateString(localeId) + " " + new Date(maintenance[2]).toLocaleTimeString(localeId, { hour: '2-digit', minute: '2-digit' });

          const maintenanceText = text.replace("{0}", maintenanceStart).replace("{1}", maintenanceEnd);
          return maintenanceText;
        }));
    }
  }
  public isTranslationLoaded() {
    if ((this.settingsStore.appSettings.CompanyId == this.translateService.aldCompanyId && this.translateService.aldTranslations.length > 0)) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
  }
}