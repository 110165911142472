import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AppUserStore } from '../../../stores/DataStores';
var LeavePageWarningComponent = /** @class */ (function () {
    function LeavePageWarningComponent(userStore, theBen, dialogRef, data) {
        this.userStore = userStore;
        this.theBen = theBen;
        this.dialogRef = dialogRef;
        this.type = data.type;
    }
    LeavePageWarningComponent.prototype.ngOnInit = function () {
    };
    LeavePageWarningComponent.prototype.yes = function () {
        this.dialogRef.close(true);
    };
    LeavePageWarningComponent.prototype.no = function () {
        this.dialogRef.close(false);
    };
    return LeavePageWarningComponent;
}());
export { LeavePageWarningComponent };
