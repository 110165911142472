import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
// Stores
import { AppSettingsStore } from "./app-settings.store";

import { ApiService } from "../../services/api.service";
import { CongestionTaxDaily } from "../../models/congestionTaxDaily.model";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";
import { CongestionTaxMonthlyTotal } from "../../models/congestionTaxMonthlyTotal.model";
import { CongestionTaxDailyLog } from "../../models/congestionTaxDailyLog.model";
import { CongestionTaxDailyEntry } from "../../models/congestionTaxDailyEntry.model";
import { SpinnerService } from "../../services/spinner.service";
import { ApiUrl } from "../../helpers/apiUrls";
import { CongestionTaxMonthly } from "../../models/congestionTaxMonthly.model";
import { NavigationService } from "../../services/navigation.service";
import { CongestionTaxMonthlyLog } from "../../models/congestionTaxMonthlyLog.model";
import { CongestionTaxMonthlyEntry } from "../../models/congestionTaxMonthlyEntry.model";
import { AppUserStore } from "./app-user.store";
import { UserRoleType } from "../../models/enums/user-role-type.enum";

@Injectable({
    providedIn: "root"
})
export class CongestionTaxStore {
    // the year and month of the days displayed on the daily page
    public dailySelectedYear: number;
    public dailySelectedMonth: number;
    // the selected day to edit
    public dailySelectedDay: number;
    public selectedDayEntry: CongestionTaxDailyEntry = null;

    // the year of the months displayed on the monthly page
    public monthlySelectedYear: number;
    public selectedMonthEntry: CongestionTaxMonthlyEntry = null;


    private _congestionTaxDaily: CongestionTaxDaily;
    public congestionTaxDailyEntriesTotal: CongestionTaxMonthlyTotal;

    private _congestionTaxMonthly: CongestionTaxMonthly;
    public congestionTaxMonthlyEntriesTotal: CongestionTaxMonthlyTotal;

    private readonly congestionTaxDaily: BehaviorSubject<IStoreResult<CongestionTaxDaily>> = new BehaviorSubject<IStoreResult<CongestionTaxDaily>>({ model: null, isInitial: true });
    onGetcongestionTaxDaily$ = this.congestionTaxDaily.asObservable();

    private readonly congestionTaxMonthly: BehaviorSubject<IStoreResult<CongestionTaxMonthly>> = new BehaviorSubject<IStoreResult<CongestionTaxMonthly>>({ model: null, isInitial: true });
    onGetcongestionTaxMonthly$ = this.congestionTaxMonthly.asObservable();


    constructor(
        private appSettingStore: AppSettingsStore,
        private userSettingsStore: AppUserStore,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private navigationService: NavigationService,
    ) {
        this.resetSelectedDates();
    }

    public reset() {
        this._congestionTaxDaily = null;
        this._congestionTaxMonthly = null;
        this.congestionTaxMonthlyEntriesTotal = null;
    }

    public resetSelectedDates() {
        this.dailySelectedMonth = new Date().getMonth();
        this.dailySelectedYear = new Date().getFullYear();
        this.dailySelectedDay = 1;
        this.monthlySelectedYear = new Date().getFullYear();
    }

    /**
     * Fetches all daily passages for the given year and selected month and driver. If it is the current year and the previous selected month
     * is after the current month then the month for the dailies will be the current month
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    setDailySelectedYear(contractId: string, year: number, driverId: string = null) {
        let currentYear = new Date().getFullYear();
        if (year === currentYear) {
            let currentMonth = new Date().getMonth();
            if (this.dailySelectedMonth > currentMonth) {
                this.dailySelectedMonth = currentMonth;
            }
        }

        this.dailySelectedYear = year;
        this._congestionTaxDaily = null;
        if (driverId == null) {
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
    }


    /**
     * Changed the selected month to the given month and fetches the passages for that month
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    setDailySelectedMonth(contractId: string, month: number, driverId: string = null) {
        this.dailySelectedMonth = month;
        this._congestionTaxDaily = null;
        if (driverId == null) {
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
    }

    /**
     * Changes the selected year to the given year.
     * Fetches all monthly passages for the given year and driver 
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    setMonthlySelectedYear(contractId: string, year: number, driverId: string = null) {
        this.monthlySelectedYear = year;
        this._congestionTaxMonthly = null;
        if (driverId == null) {
            this.getMonthlyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
        }
    }


    getDailyEntry(): CongestionTaxDailyEntry {
        let entry = this._congestionTaxDaily.CTaxDailyEntrys.find(dayEntry => (dayEntry.Day === this.dailySelectedDay && dayEntry.Month === this.dailySelectedMonth && dayEntry.Year === this.dailySelectedYear));
        return entry;
    }

    getDailyCTaxForDriver(driverId: string, contractId: string, forceReload: boolean = false) {
        if (this._congestionTaxDaily && !forceReload) {
            this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true, isCached: true });
            return;
        }


        this.apiService.getAsync(ApiUrl.CTaxDailyHistory + driverId + "/" + contractId + "/" + (this.dailySelectedMonth + 1) + "/" + this.dailySelectedYear, this.appSettingStore.appSettings)
            .then((data: CongestionTaxDaily) => {
                this.processCtaxDaily(data);
            })
            .catch((err: any) => {
                console.warn("Error: ", err);
                this._congestionTaxDaily = null;
                this.congestionTaxDailyEntriesTotal = null;
                this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: false, error: err });
            });
    }


    getDailyCTax(contractId: string, forceReload: boolean = false) {
        // Entry found
        if (this._congestionTaxDaily && !forceReload) {
            this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true, isCached: true });
            return;
        }



        this.apiService.getAsync(ApiUrl.CTaxDailyHistory + contractId + "/" + (this.dailySelectedMonth + 1) + "/" + this.dailySelectedYear, this.appSettingStore.appSettings)
            .then((data: CongestionTaxDaily) => {
                this.processCtaxDaily(data);
            })
            .catch((err: any) => {
                console.warn("Error: ", err);
                this._congestionTaxDaily = null;
                this.congestionTaxDailyEntriesTotal = null;
                this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: false, error: err });
            });
    }

    private processCtaxDaily(data: CongestionTaxDaily) {
        let total: CongestionTaxMonthlyTotal = new CongestionTaxMonthlyTotal();
        total.NumberOfPasssages = 0;
        total.PrivateAmount = 0;
        total.TotalAmount = 0;
        total.PrivatePart = 0;

        // show only years after 2018
        let years = [];
        data.SelectableYears.forEach(year => {
            if (year >= 2018) {
                years.push(year);
            }
        });
        data.SelectableYears = years;

        data.CTaxDailyEntrys.forEach((entry, i) => {
            if (entry.Month !== 0 && entry.Year !== 0) {
                entry.Month = entry.Month - 1;

                entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
                entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
                entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
                entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
            }
            else {
                data.MonthlyBrigdeFees.splice(i, 1);
            }
        });
        data.MonthlyBrigdeFees.forEach((entry, i) => {
            if (entry.Month !== 0 && entry.Year !== 0) {
                entry.Month = entry.Month - 1;

                entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
                entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
                entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
                entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
            }
            else {
                data.MonthlyBrigdeFees.splice(i, 1);
            }
        });

        // showPreviewMode if there are none dailyEntries that have noOfPassages and totalAmount
        data.ShowPreviewMode = false;
        let entryWithInvoiceLines = data.CTaxDailyEntrys.find(entry =>
            entry.NumberOfPasssages !== null && entry.TotalAmount !== null
        );
        if (data.CTaxDailyEntrys && data.CTaxDailyEntrys.length > 0 && !entryWithInvoiceLines) {
            data.ShowPreviewMode = true;
        }

        this._congestionTaxDaily = data;
        this.congestionTaxDailyEntriesTotal = total;
        this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true });

    }

    public postDailyCTax(contractId: string, log: CongestionTaxDailyLog) {
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }

        this.spinnerService.show();
        if (log.IsCreate) {
            this.apiService.postAsync(ApiUrl.CTaxLogDaily, log, this.appSettingStore.appSettings)
                .then(() => {
                    this.doThen(contractId, log.PersonId);
                })
                .catch((err: HttpErrorResponse) => {
                    this.doCatch(err, contractId, log.PersonId);
                });
        }
        else {
            this.apiService.postAsync(ApiUrl.CTaxEditDaily, log, this.appSettingStore.appSettings)
                .then(() => {
                    this.doThen(contractId, log.PersonId);
                })
                .catch((err: HttpErrorResponse) => {
                    this.doCatch(err, contractId, log.PersonId);
                });
        }
    }

    getMonthlyCTaxForDriver(driverId: string, contractId: string, forceReload: boolean = false) {
        // Entry found
        if (this._congestionTaxMonthly && !forceReload) {
            this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true, isCached: true });
            return;
        }

        this.spinnerService.show();
        this.apiService.getAsync(ApiUrl.CTaxMonth + driverId + "/" + contractId + "/" + this.monthlySelectedYear, this.appSettingStore.appSettings)
            .then((data: CongestionTaxMonthly) => {
                this.processCongestionTaxMonthly(data);
                this.spinnerService.hide();
            })
            .catch((err: any) => {
                console.warn("Error: ", err);
                this._congestionTaxMonthly = null;
                this.congestionTaxMonthlyEntriesTotal = null;
                this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: false, error: err });
                this.spinnerService.hide();
            });
    }

    getMonthlyCTax(contractId: string, forceReload: boolean = false) {
        // Entry found
        if (this._congestionTaxMonthly && !forceReload) {
            this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true, isCached: true });
            return;
        }

        this.apiService.getAsync(ApiUrl.CTaxMonth + contractId + "/" + this.monthlySelectedYear, this.appSettingStore.appSettings)
            .then((data: CongestionTaxMonthly) => {
                this.processCongestionTaxMonthly(data);
            })
            .catch((err: any) => {
                console.warn("Error: ", err);
                this._congestionTaxMonthly = null;
                this.congestionTaxMonthlyEntriesTotal = null;
                this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: false, error: err });
            });
    }

    private processCongestionTaxMonthly(data: CongestionTaxMonthly) {
        let years = [];
        data.SelectableYears.forEach(year => {
            if (year >= 2018) {
                years.push(year);
            }
        });
        data.SelectableYears = years;

        let total: CongestionTaxMonthlyTotal = new CongestionTaxMonthlyTotal();
        total.NumberOfPasssages = 0;
        total.PrivateAmount = 0;
        total.TotalAmount = 0;
        total.PrivatePart = 0;

        data.CTaxMonthEntrys.forEach(entry => {
            entry.Month = entry.Month - 1;

            entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
            entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
            entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
            entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
        });

        this._congestionTaxMonthly = data;
        this.congestionTaxMonthlyEntriesTotal = total;
        this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true });
    }

    public postMonthlyCTax(contractId: string, log: CongestionTaxMonthlyLog) {
        console.warn(log);
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }

        this.spinnerService.show();

        this.apiService.postAsync(ApiUrl.CTaxMonth, log, this.appSettingStore.appSettings)
            .then(() => {
                this.reset();
                this.doThen(contractId, log.PersonId);
            })
            .catch((err: HttpErrorResponse) => {
                this.doCatch(err, contractId, log.PersonId);
            });
    }

    // create a month log
    public putMonthlyCTax(contractId: string, log: CongestionTaxMonthlyLog) {
        let id = log.ContractId;
        log.Month = log.Month + 1;
        log.ContractId = id;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }

        this.spinnerService.show();
        this.apiService.putAsync(ApiUrl.CTaxMonth, log, this.appSettingStore.appSettings)
            .then(() => {
                this.reset();
                this.doThen(contractId, log.PersonId);
            })
            .catch((err: HttpErrorResponse) => {
                this.doCatch(err, contractId, log.PersonId);
            });
    }

    private doThen(contractId: string, driverId) {
        const isDriver = this.userSettingsStore.appUser.Roles.some(x => x == UserRoleType.Driver);
        this._congestionTaxMonthly = null;
        this._congestionTaxDaily = null;
        if (isDriver) {
            this.getMonthlyCTax(contractId, true);
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
        this.spinnerService.hide();
    }


    private doCatch(err: HttpErrorResponse, contractId: string, driverId) {
        const isDriver = this.userSettingsStore.appUser.Roles.some(x => x == UserRoleType.Driver);
        console.warn("Error: ", err);
        this._congestionTaxMonthly = null;
        this._congestionTaxDaily = null;
        if (isDriver) {
            this.getMonthlyCTax(contractId, true);
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
        this.spinnerService.hide();

    }

}
