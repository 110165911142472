import { GloveboxComponent } from './glovebox.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from "@angular/core";
import { SystemAdminGuard } from '../../../guards/systemadmin.guard';

const routes: Routes = [
    {
        path: '',
        component: GloveboxComponent,
        canActivate: [SystemAdminGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class GloveboxRoutingModule { }
