import { OnInit, ViewChild, Input } from '@angular/core';
import { Component, Output, EventEmitter } from '@angular/core';
import { MaskComponent } from '../mask/mask.component';
import { NavigationService } from '../../../services/navigation.service';

// app-modal.component.ts
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

    @Input() size: 'xxs' | 'small' | 'medium';
    @Input() closeButton: boolean;

    @Output() modalClosing = new EventEmitter<boolean>();

    @ViewChild(MaskComponent) public mask: MaskComponent;

    public allowClose = true;
    public mainStyles = "modal";

    constructor(
        private navigationService: NavigationService) {
    }

    ngOnInit() {

        if (this.size === "xxs") {
            this.mainStyles = "modal modal-xxs  on-top";
        }

        if (this.size === "small") {
            this.mainStyles = "modal modal-small on-top";
        }

        if (this.size === "medium") {
            this.mainStyles = "modal modal-medium on-top";
        }

        if (this.closeButton === false) {
            this.allowClose = false;
        }
    }

    closeModal(childNavigates = false) {
        if (this.allowClose) {
            this.modalClosing.emit(true);
            if (!childNavigates) {
                this.navigationService.closeModal();
            }
        }
    }

}
