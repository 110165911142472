var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
//
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { BigAlModule } from './../../../../../modules/bigal.module';
import { AbstractModalFeature } from '../../../modalfeature.abstract';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { AppUserStore } from '../../../../../stores/DataStores';
var DeleteFleetUserComponent = /** @class */ (function (_super) {
    __extends(DeleteFleetUserComponent, _super);
    function DeleteFleetUserComponent(theBen, bigAl, route, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.route = route;
        _this.appUserStore = appUserStore;
        _this.fleetUser = null;
        return _this;
    }
    DeleteFleetUserComponent.prototype.confirmDeleteFleetUser = function () {
        this.close(true);
        this.bigAl.postDeleteFleetUser(this.id);
    };
    DeleteFleetUserComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("currentAccessibleFleetUsers");
        this.watchEvent("currentFleetUsers");
    };
    DeleteFleetUserComponent.prototype.setData = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get("id");
        if (this.bigAl.currentAccessibleFleetUsers) {
            this.fleetUser = this.bigAl.currentAccessibleFleetUsers.find(function (fm) { return fm.UserId.toString() === _this.id; });
        }
        if (this.bigAl.currentSearchedFleetUsers) {
            this.fleetUser = this.bigAl.currentSearchedFleetUsers.find(function (fm) { return fm.UserId.toString() === _this.id; });
        }
    };
    DeleteFleetUserComponent.prototype.getData = function () {
        if (!this.bigAl.currentAccessibleFleetUsers && this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.FleetManager; })) {
            this.bigAl.getAccessibleFleetUsers();
        }
        this.setData();
    };
    DeleteFleetUserComponent.prototype.isDataAvailable = function () {
        if (this.fleetUser && this.id) {
            return true;
        }
        return false;
    };
    return DeleteFleetUserComponent;
}(AbstractModalFeature));
export { DeleteFleetUserComponent };
