<app-modal size="small" *ngIf="isDataAvailable()">
    <div modal-body>
        <h2 class="element-title">
            <app-translate translationName="DeleteFleetUserComponent.header_t">
                <span translate-text>{{ "DeleteFleetUserComponent.header_t" | translate}}</span>
            </app-translate>
        </h2>
        <p>
            <app-translate translationName="DeleteFleetUserComponent.description_p">
                <span translate-text>{{ "DeleteFleetUserComponent.description_p" | translate}}</span>
            </app-translate>{{ fleetUser.Username }}
        </p>
    </div>
    <div modal-footer>
        <button mat-button (click)="action('close')">
            <app-translate translationName="DeleteFleetUserComponent.cancel_b">
                <span translate-text>{{ "DeleteFleetUserComponent.cancel_b" | translate}}</span>
            </app-translate>
        </button>
        <button mat-raised-button color="warn" (click)="confirmDeleteFleetUser()">
            <mat-icon class="icon-btn icon-md" svgIcon="delete"></mat-icon>
            <app-translate translationName="DeleteFleetUserComponent.delete_b">
                <span translate-text>{{ "DeleteFleetUserComponent.delete_b" | translate}}</span>
            </app-translate>
        </button>
    </div>
</app-modal>
