import { TripTypeEnum } from "../enums/TripType.enum";
import { DriverJournalTrip } from "./driverjournal-trip.model";

export class DriverJournalDailyLog {
    constructor(
        public PersonId = 0,
        public TripId = null,
        public DataSource = -1,
        public ContractId = null,
        public RegistrationNumber = null,
        public Date = null,
        public IsExtraCarSelected = false,
        public Start = null,
        public End = null,
        public Purpose = null,
        public Distance = 0,
        public IsCreate = true,
        public CarDescription = null,
        public TripType = TripTypeEnum.BusinessTrip,
        public Trip: DriverJournalTrip = null,
        public VinliGUID = null,
        public BusinessKM = "0",
        public Route = null,
        public PrivatePassages = 0,
        public BusinessPassages = 0
    ) {

    }
}
