import { OnDestroy, ElementRef, AfterViewInit, NgZone, QueryList } from '@angular/core';
import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { MatStepper } from '@angular/material';
import { MapsAPILoader } from '@agm/core/services';
// Common
import { BigAlModule } from '../../../modules/bigal.module';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
// Types
import { MapSettings, MapSettingsDirections } from './../../../models/map/map-settings.model';
import { Form } from '../../../models/form.model';
import { TranslationTagType } from '../../../models/enums/translation-tag-type.enum';
// Services
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { PhoneCountryCodeService } from '../../../services/phone-country-code.service';
import { ToastService } from '../../../services/toast.service';
import { GeoLocationHelper } from '../../../helpers/geolocationHelper';
var ɵ0 = APP_DATE_FORMATS;
var FormComponent = /** @class */ (function () {
    function FormComponent(translatePipe, bigAl, theBen, fb, validatorFactory, fileUploadService, translateService, mapsLoader, ngZone, toaster, phoneCountryCodeService) {
        this.translatePipe = translatePipe;
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.fb = fb;
        this.validatorFactory = validatorFactory;
        this.fileUploadService = fileUploadService;
        this.translateService = translateService;
        this.mapsLoader = mapsLoader;
        this.ngZone = ngZone;
        this.toaster = toaster;
        this.phoneCountryCodeService = phoneCountryCodeService;
        this.allSubscriptions = new Subscription();
        this.isSubmitting = false;
        // data variables
        this.isLinear = true;
        this.hasSteps = false;
        this.close = new EventEmitter();
        this.tollStations = [];
        this.tollStationMarkerIcon = "./assets/ald/images/gmap/tollstation.png";
        this.isEditing = false;
        this.isChecked = true;
        this.fileNames = [];
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // data calls
        this.watchData();
        this.getData();
        //
        if (this.isReadyForInit !== null && this.isReadyForInit !== undefined) {
            this.allSubscriptions.add(this.isReadyForInit.subscribe(function (isReady) {
                if (isReady) {
                    _this.initForm();
                    _this.subscribe();
                }
            }));
        }
        else {
            this.initForm();
            this.subscribe();
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        if (this.allSubscriptions) {
            this.allSubscriptions.unsubscribe();
        }
        if (this.formModel) {
            this.formModel.unsubscribe();
        }
        this.close.emit(true);
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var options = {
            componentRestrictions: { country: this.bigAl.appSettings.CountryCode },
            fields: ["formatted_address", "geometry"]
        };
        if (this.searchLocationInputs) {
            this.searchLocationInputs.forEach(function (elementRef) { return _this.listenToGooglePlaces(elementRef, options); });
        }
    };
    FormComponent.prototype.isContent = function (name) {
        return this.theBen.isContent(this, name);
    };
    FormComponent.prototype.watchData = function () { };
    FormComponent.prototype.getData = function () { };
    FormComponent.prototype.initForm = function () {
        this.formModel = new Form(this.formObject, this.ruleObject, this.translatePipe, this.bigAl, this.theBen, this.fb, this.validatorFactory, this.fileUploadService, this.translateService, this.phoneCountryCodeService);
    };
    FormComponent.prototype.goBack = function (stepper) {
        stepper.previous();
    };
    FormComponent.prototype.trimValue = function (name, index) {
        var control = this.formModel.formArray.controls[index].get(name);
        if (control.value) {
            control.setValue(control.value.trim());
        }
    };
    FormComponent.prototype.goForward = function (stepper, i) {
        this.formModel.goForward(i);
        stepper.next();
    };
    FormComponent.prototype.submit = function (stepperForm) {
        this.onSubmit(stepperForm.getRawValue());
    };
    FormComponent.prototype.onSubmit = function (value) {
        this.formModel.submitTouched = true;
        if (this.formModel.validate()) {
            var action = "post" + this.formObject.Name;
            if (this.formModel.formArray.length > 1) {
                this.bigAl[action](value);
            }
            else if (this.formModel.formArray.length === 1) {
                this.bigAl[action](value["formArray"][0]);
            }
            this.close.emit(true);
        }
    };
    FormComponent.prototype.subscribe = function () {
        var _this = this;
        this.allSubscriptions.add(this.translateService.isEditing.subscribe(function (editing) {
            _this.isEditing = editing;
        }));
        this.allSubscriptions.add(this.fileUploadService.getuploadErrorObservable().subscribe(function (uploadError) {
            _this.fileUploadError = uploadError;
        }));
        this.allSubscriptions.add(this.fileUploadService.getErrorTypeObservable().subscribe(function (errorType) {
            _this.fileErrorType = errorType;
        }));
        this.allSubscriptions.add(this.fileUploadService.getFilePathObservable().subscribe(function (file) {
            if (file) {
                _this.fileNames.push(file.FileName);
            }
        }));
    };
    FormComponent.prototype.getLabelTranslationTagType = function () {
        return TranslationTagType.Label;
    };
    FormComponent.prototype.onCheckboxChange = function (checked) {
        (checked === true) ? checked = false : checked = true;
    };
    FormComponent.prototype.getGeoLocation = function (address) {
        var geocoder = new google.maps.Geocoder;
        return Observable.create(function (observer) {
            geocoder.geocode({
                'address': address
            }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    observer.next(results[0]);
                    observer.complete();
                }
                else {
                    console.log('Error: ', results, ' & Status: ', status);
                    observer.error();
                }
            });
        });
    };
    FormComponent.prototype.listenToGooglePlaces = function (inputElement, options) {
        var _this = this;
        var property = this.formObject.Steps[0].Properties.find(function (prop) { return prop.Name === inputElement.nativeElement['name']; });
        var control = this.formModel.formArray.controls[0].get(property.Name);
        if (control && !control.touched && control.value) {
            this.mapsLoader.load().then(function () {
                _this.getGeoLocation(control.value).subscribe(function (resp) {
                    if (property.GoogleSettings) {
                        property.GoogleSettings.Influence
                            .forEach(function (toInfluence) {
                            var mapProperty = _this.formObject.Steps[0].Properties.find(function (prop) { return prop.Name === toInfluence; });
                            if (mapProperty) {
                                var mapControl = _this.formModel.formArray.controls[0].get(mapProperty.Name);
                                var newValue = _this.googleInputAction(mapControl.value, property, resp);
                                mapControl.setValue(newValue);
                                mapProperty.Value = newValue;
                                if (newValue.CanShowMap) {
                                    mapProperty.ShouldShow = true;
                                    mapProperty['Hidden'] = false;
                                }
                            }
                        });
                    }
                });
            });
        }
        // load Places Autocomplete
        this.mapsLoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(inputElement.nativeElement, options);
            autocomplete.addListener("place_changed", function () {
                _this.ngZone.run(function () {
                    // get the place result
                    var place = autocomplete.getPlace();
                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    control.setValue(place.formatted_address);
                    if (property.GoogleSettings) {
                        property.GoogleSettings.Influence.forEach(function (toInfluence) {
                            var mapProperty = _this.formObject.Steps[0].Properties.find(function (prop) { return prop.Name === toInfluence; });
                            if (mapProperty) {
                                var mapControl = _this.formModel.formArray.controls[0].get(mapProperty.Name);
                                var newValue = _this.googleInputAction(mapControl.value, property, place);
                                mapControl.setValue(newValue);
                                mapProperty.Value = newValue;
                                if (newValue.CanShowMap) {
                                    mapProperty.ShouldShow = true;
                                    mapProperty['Hidden'] = false;
                                    _this.setChangesFromMap(mapProperty);
                                }
                            }
                        });
                    }
                });
            });
        });
    };
    FormComponent.prototype.googleInputAction = function (settings, property, googleResults) {
        var mapSettings = null;
        if (!settings) {
            mapSettings = new MapSettings(true, null, null, new MapSettingsDirections(null, null, null, null), null);
        }
        else {
            mapSettings = settings;
        }
        if (!mapSettings.Lat) {
            mapSettings.Lat = googleResults.geometry.location.lat();
            mapSettings.Lng = googleResults.geometry.location.lng();
        }
        if (mapSettings.RequiresDirections && !mapSettings.Directions) {
            mapSettings.Directions = new MapSettingsDirections(null, null, null, null);
        }
        if (mapSettings.RequiresDirections && property.GoogleSettings.Directions) {
            switch (property.GoogleSettings.Directions) {
                case 'Origin':
                    mapSettings.Directions.OriginAddress = googleResults.formatted_address;
                    mapSettings.Directions.OriginPosition = { lat: googleResults.geometry.location.lat(), lng: googleResults.geometry.location.lng() };
                    break;
                case 'Destination':
                    mapSettings.Directions.DestinationAddress = googleResults.formatted_address;
                    mapSettings.Directions.DestinationPosition = { lat: googleResults.geometry.location.lat(), lng: googleResults.geometry.location.lng() };
                    break;
                default:
                    break;
            }
        }
        if (mapSettings.TollStations) {
            this.tollStations = Array.from(mapSettings.TollStations);
        }
        if (mapSettings.RequiresDirections) {
            if (mapSettings.Directions.OriginPosition && mapSettings.Directions.DestinationPosition) {
                mapSettings.CanShowMap = true;
            }
            else {
                mapSettings.CanShowMap = false;
            }
        }
        else {
            mapSettings.CanShowMap = true;
        }
        return mapSettings;
    };
    FormComponent.prototype.setChangesFromMap = function (mapField) {
        var _this = this;
        if (mapField.OnChange && mapField.OnChange.length > 0) {
            mapField.OnChange.forEach(function (changeAction) {
                changeAction.Actions.forEach(function (action) {
                    switch (action.Type) {
                        case 'show':
                            action.Influence.forEach(function (toInfluence) {
                                var findField = _this.formObject.Steps[0].Properties.find(function (prop) { return prop.Name === toInfluence; });
                                if (findField) {
                                    findField['Hidden'] = false;
                                }
                            });
                            break;
                        case 'calculateDistance':
                            action.Influence.forEach(function (toInfluence) {
                                GeoLocationHelper.getTripDistanceFromEndpoints(mapField.Value.Directions.OriginPosition.lat, mapField.Value.Directions.OriginPosition.lng, mapField.Value.Directions.DestinationPosition.lat, mapField.Value.Directions.DestinationPosition.lng).then(function (distance) {
                                    var distanceControl = _this.formModel.formArray.controls[0].get(toInfluence);
                                    if (distance >= 0) {
                                        distanceControl.setValue(distance);
                                    }
                                    else {
                                        distanceControl.setValue(null);
                                    }
                                });
                            });
                            break;
                        default:
                            break;
                    }
                });
            });
        }
    };
    return FormComponent;
}());
export { FormComponent };
export { ɵ0 };
