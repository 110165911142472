
export namespace ApiUrl {

  // Driver journal
  export const DriverJournalDaily: string = "DriverJournal/DailyHistory";
  export const DriverJournalMonth: string = "DriverJournal/MonthHistory";
  export const TollStations: string = "DriverJournalTrip/TollStations";
  export const HasActiveTrip: string = "DriverJournal/Trips/stopactive";

  // Ctax
  export const CTaxLogDaily: string = "CTax/LogDaily";
  export const CTaxEditDaily: string = "CTax/EditDaily";
  export const CTaxDailyHistory: string = "Ctax/DailyHistory/";
  export const CTaxMonth: string = "Ctax/Month/";

  // Business-admin
  // User-Management
  export const UsersByEmailUsernameOrRegNo: string = "users/search?query=";
  // JournalManagement
  export const JournalUsers: string = "users/searchAll?query=";

  //
  export const ContactInformation: string = "ContactInformation";

  // Glovebox
  export const GetDocuments: string = "file/info";

  //
  export const Contracts: string = "contract";

  // Insurance
  export const InsuranceForContract: string = "Insurance";

  // Business partner
  export const BusinessPartner_Current: string = "businesspartner/current";

  // Driver
  export const Driver: string = "driver";

  export const Drivers: string = "Drivers";

  // Vehicle
  export const Vehicle: string = "vehicle/";
  export const ExternalVehicle: string = "externalvehicle/";
  export const MileageUpdate: string = "MileageUpdate";

  // Feed
  export const Feed: string = "Feed";

  // WhoAmI
  export const WhoAmI: string = "whoami2";

  // Language
  export const LanguageChange: string = "languages/change";

  // JSON files
  export const DefaultSettingsJson: string = "assets/default-settings.json";

  // Parking
  export const activeParkingsUrl: string = 'Parking/GetActiveParking';
  export const inactiveParkingUrl: string = 'Parking/GetParkingHistory';
  export const pendingParkingUrl: string = 'Parking/GetPendingParking';
  export const answerParkingUrl: string = 'Parking/ParkingDecision';
}
