import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class SpinnerService {

  loaderSubject = new Subject<boolean>();
  spinnerState = this.loaderSubject.asObservable();
  isShowing: boolean;

  constructor() { }

  show() {
    this.loaderSubject.next(true);
    this.isShowing = true;
  }

  hide() {
    this.loaderSubject.next(false);
    this.isShowing = false;
  }
}
