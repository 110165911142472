import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { BigAlModule } from './../../../../../modules/bigal.module';
import { AbstractModalFeature } from '../../../modalfeature.abstract';
import { User } from '../../../../../models/user.model';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { AppUserStore } from '../../../../../stores/DataStores';


@Component({
  selector: 'app-delete-business-user',
  templateUrl: './delete-business-user.component.html',
  styleUrls: ['./delete-business-user.component.scss'],
  exportAs: "modal"
})
export class DeleteBusinessUserComponent extends AbstractModalFeature {

  private id: string;

  businessUser: User = null;

  constructor(
    protected theBen: ComponentConfigService,
    protected bigAl: BigAlModule,
    private route: ActivatedRoute,
    private appUserStore: AppUserStore,
  ) {
    super(bigAl, theBen);
  }

  confirmDeleteBusinessUser() {
    this.close(true);
    this.bigAl.deleteBusinessUser(this.id);
  }

  setEventsToWatch() {
    this.watchEvent("currentBusinessUsers");
  }

  protected setData(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.bigAl.currentBusinessUsers) {
      this.businessUser = this.bigAl.currentBusinessUsers.find(fm => fm.UserId.toString() === this.id);
    }

  }
  protected getData(): void {

    this.setData();
  }


  public isDataAvailable(): boolean {
    if (this.businessUser && this.id) {
      return true;
    }

    return false;
  }
}
