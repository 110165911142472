import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ModalService } from '../../../services/modals/modal.service';
import { NavigationService } from '../../../services/navigation.service';
import { CustomTranslateService } from '../../../translation/customTranslateService';

// Models
import { Translation } from '../../../models/translation.model';
import { LanguageTranslation } from '../../../models/languageTranslationResponse.model';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit, OnDestroy {

  @Input() translationName: string;

  updateTranslations = false;

  private subscription: Subscription;
  private translations: Translation[] = [];

  // #region Life cycle

  constructor(
    private translateService: CustomTranslateService,
    private navigationService: NavigationService,
    private modalService: ModalService<Translation>
  ) { }

  ngOnInit() {
    this.subscription = this.translateService.isEditing
      .subscribe((activateUpdateTranslations: boolean) => {
        this.updateTranslations = activateUpdateTranslations;
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // #endregion

  loadTranslation() {

    this.translations = [];
    const langs: string[] = this.translateService.getLanguageCodes();

    langs.forEach(lang => {
      // Always show ALD translation
      const aldTranslation: Translation = this.addTranslation(this.translateService.aldTranslations, lang, this.translationName, this.translateService.aldCompanyId);
      this.translations.push(aldTranslation);

      // Check if we have translations for NFF
      const nffTranslation: Translation = this.addTranslation(this.translateService.nffTranslations, lang, this.translationName, this.translateService.nffCompanyId);
      if (nffTranslation.TranslationValue && nffTranslation.TranslationValue !== aldTranslation.TranslationValue) {
        this.translations.push(nffTranslation);
        this.translateService.setHasNffTranslations(true);
      }
    });

    if (this.translations.length > 0) {
      this.translations.sort((t1, t2) => t1.CompanyId - t2.CompanyId);
      this.modalService.setData(this.translations);
      this.navigationService.openModal("update-translation");
    }
  }

  addTranslation(translationsList: LanguageTranslation[], languageCode: string, translationTag: string, companyId: number): Translation {

    let translation: Translation;
    const translationParts: string[] = translationTag.split('.');
    const language: string = this.translateService.getLanguageText(languageCode);
    const translations: LanguageTranslation[] = translationsList.filter(t => t.LanguageCode === languageCode);

    if (translations.length > 0) {
      const translationJson: string = translations.pop().TranslationJson;
      if (translationJson.hasOwnProperty(translationParts[0]) && translationJson[translationParts[0]].hasOwnProperty(translationParts[1])) {
        const translationValue = translationJson[translationParts[0]][translationParts[1]];
        translation = new Translation(this.translationName, translationValue, languageCode, companyId, language);
      }
      else {
        translation = new Translation(this.translationName, this.translationName, languageCode, companyId, language);
      }
    }

    return translation;
  }

}
