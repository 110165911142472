/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./base.component";
import * as i2 from "../../modules/bigal.module";
import * as i3 from "../../services/navigation.service";
import * as i4 from "../../stores/store-sections/app-user.store";
var styles_BaseComponent = [];
var RenderType_BaseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaseComponent, data: {} });
export { RenderType_BaseComponent as RenderType_BaseComponent };
export function View_BaseComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_BaseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-base-route", [], null, null, null, View_BaseComponent_0, RenderType_BaseComponent)), i0.ɵdid(1, 245760, null, 0, i1.BaseComponent, [i2.BigAlModule, i3.NavigationService, i4.AppUserStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaseComponentNgFactory = i0.ɵccf("app-base-route", i1.BaseComponent, View_BaseComponent_Host_0, {}, {}, []);
export { BaseComponentNgFactory as BaseComponentNgFactory };
