import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ComponentConfigService } from "../componentConfig.service";

@Injectable()
export class DjTabsService {
    data: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private journalOptions = [
        {
            path: 'driver-journal-monthly',
            label: 't_journal_month',
            features: ['driverjournal'],
            contents: ['driver-journal-monthly'],
            priority: 0,
            width: '50'
        },
        {
            path: 'driver-journal-daily',
            label: 't_journal_day',
            features: ['driverjournal'],
            contents: ['driver-journal-daily'],
            priority: 1,
            width: '50'
        },
        {
            path: 'congestion-tax-daily',
            label: 't_ctax_day',
            features: ['ctax'],
            contents: ['congestion-tax-daily'],
            priority: 2,
            width: '100'
        },
        {
            path: 'congestion-tax-monthly',
            label: 't_ctax_month',
            features: ['ctax'],
            contents: ['congestion-tax-monthly'],
            priority: 3,
            width: '100'
        }
    ];
    constructor(private theBen: ComponentConfigService) {
        this.setInitTab();
    }
    getData() {
        return this.data.asObservable();
    }

    setData(data: string) {
        this.data.next(data);
    }


    public shouldShowContent(contents: Array<string>) {
        let resp = true;
        contents.forEach(c => {
            if (!this.theBen.isContent('JournalComponent', c)) {
                resp = false;
            }
        });

        return resp;
    }

    setInitTab() {
        let path = null;
        this.journalOptions.sort(o => o.priority).every(o => {
            if (this.shouldShowContent(o.contents) && this.shouldShowFeatures(o.features)) {
                path = 'journal/' + o.path;
                return false;
            }
            return true;
        });
        if (path === null) {
            this.data.next('journal/');
        } else {
            this.data.next(path);
        }
    }

    public shouldShowFeatures(features: Array<string>) {
        let resp = true;
        features.forEach(f => {
            if (!this.theBen.isFeature(f)) {
                resp = false;
            }
        });

        return resp;
    }
}
