import { Component } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FormBuilder } from '@angular/forms';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { Form } from '../../../models/form.model';
import { TranslatePipe } from '@ngx-translate/core';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AbstractFeature, PrefilledContentEntry } from '../../features/feature.abstract';
import { PhoneCountryCodeService } from '../../../services/phone-country-code.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  providers: [
    TranslatePipe,
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class NewPasswordComponent extends AbstractFeature {
  // private allSubscriptions: Subscription[] = [];

  public formModel: Form;
  public currentPasswordSetCode = null;
  public badLink = false;
  public passwordSetSuccessfully = false;

  constructor(
    private translatePipe: TranslatePipe,
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private fb: FormBuilder,
    private validatorFactory: ValidatorFactory,
    private fileUploadService: FileUploadService,
    private translateService: CustomTranslateService,
    private phoneCountryCodeService: PhoneCountryCodeService
  ) {
    super(bigAl, theBen);
    this.formModel = new Form(
      this.getFormObject(), this.getRuleObject(),
      this.translatePipe, this.bigAl,
      this.theBen, this.fb,
      this.validatorFactory,
      this.fileUploadService,
      this.translateService,
      this.phoneCountryCodeService
    );
  }

  getFormObject() {
    return super.getFormObject("NewPasswordComponent", new Map<string, PrefilledContentEntry[]>());
  }

  getRuleObject() {
    return super.getRuleObject("NewPasswordComponent");
  }

  protected setEventsToWatch(): void {
    this.watchEvent("currentPasswordSetCode");

    this.subscribe(this.bigAl.getDataStream().subscribe(lastEvent => {
      if (lastEvent === "passwordSet") {
        this.passwordSetSuccessfully = true;
      }
    }));

    this.route.queryParams.subscribe(params => {
      let resetCode: string = params['LinkId'];
      if (resetCode) {
        this.bigAl.getPasswordSetCode(resetCode.trim());
      }
      else {
        this.bigAl.getPasswordSetCode(this.bigAl.currentPasswordResetCode);
      }
      if (!resetCode && !this.bigAl.currentPasswordResetCode) {
        this.badLink = true;
      }
    });
  }
  protected setData(): void {
    if (this.bigAl.currentPasswordResetCode) {
      this.currentPasswordSetCode = this.bigAl.currentPasswordSetCode;
    }
  }

  getData() {
    if (this.bigAl.currentPasswordSetCode) {
      this.setData();
    }
  }

  public isDataAvailable(): boolean {
    return true;
  }

  back() {
    this.navigationService.forward("login", null);
  }
}
