var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var ContractStore = /** @class */ (function () {
    // private readonly contract$: BehaviorSubject<IStoreResult<Contract>> = new BehaviorSubject<IStoreResult<Contract>>({ model: null, isInitial: true });
    // onGetContract$ = this.contract$.asObservable();
    function ContractStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.vehicleContracts = null;
        this.otherContracts = null;
        this.vehicleContract = null;
        this.otherContract = null;
        this.contracts$ = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetContracts$ = this.contracts$.asObservable();
    }
    // #region Requests
    ContractStore.prototype.getVehicleContract = function () {
        return this.vehicleContract;
    };
    ContractStore.prototype.getContracts = function (forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this.vehicleContracts && !forceReload) {
            this.contracts$.next({ model: this.responseObject(), isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync("" + ApiUrl.Contracts, this.settingStore.appSettings)
            .then(function (data) {
            // make distinct - issue on miles because sends dupicate contracts
            var distinctContracts = [];
            data.forEach(function (d) {
                if (!distinctContracts.some(function (c) { return c.Id === d.Id; })) {
                    distinctContracts.push(d);
                }
            });
            _this.vehicleContracts = distinctContracts.filter(function (c) { return isNaN(Number(c.VehicleId)) || c.VehicleId !== "0"; });
            _this.otherContracts = distinctContracts.filter(function (c) { return c.VehicleId === "0"; });
            _this.vehicleContract = _this.vehicleContracts !== null ? __assign({}, _this.vehicleContracts[0]) : null;
            _this.otherContract = _this.otherContracts !== null ? __assign({}, _this.otherContracts[0]) : null;
            // this.contract$.next({ model: this.responseObject().vehicleContract, isSuccess: true });
            _this.contracts$.next({ model: _this.responseObject(), isSuccess: true });
        })
            .catch(function (err) {
            _this.vehicleContracts = null;
            _this.otherContracts = null;
            _this.vehicleContract = null;
            _this.otherContract = null;
            _this.contracts$.next({ model: _this.responseObject(), isSuccess: false, error: err });
            // this.contract$.next({ model: this.vehicleContract, isSuccess: false, error: err });
        });
    };
    ContractStore.prototype.reset = function () {
        this.contracts$.next({ model: null, isInitial: true });
        this.vehicleContract = null;
        this.vehicleContracts = null;
        this.otherContract = null;
        this.otherContracts = null;
    };
    ContractStore.prototype.setCurrentContract = function (contract) {
        var exists = this.vehicleContracts.some(function (cntr) { return cntr === contract; });
        if (exists) {
            this.vehicleContract = contract;
            // this.contract$.next({ model: contract, isSuccess: true });
        }
    };
    // #endregion
    // #region Helpers
    ContractStore.prototype.responseObject = function () {
        return {
            vehicleContract: this.vehicleContract,
            vehicleContracts: this.vehicleContracts,
            otherContract: this.otherContract,
            otherContracts: this.otherContracts
        };
    };
    ContractStore.ngInjectableDef = i0.defineInjectable({ factory: function ContractStore_Factory() { return new ContractStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: ContractStore, providedIn: "root" });
    return ContractStore;
}());
export { ContractStore };
