import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    console.log("Production Mode Active");
    enableProdMode();
}
else {
    console.log("Development Mode Active");
}

const bootstrap = () => {
    platformBrowserDynamic().bootstrapModule(AppModule);
};

if (typeof window["cordova"] !== "undefined") {
    document.addEventListener("deviceready", () => {
        bootstrap();
    }, false);
} else {
    bootstrap();
}
