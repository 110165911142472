import { OnInit } from '@angular/core';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { Rule } from '../../../../models/rule-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';
var FieldTranslationComponent = /** @class */ (function () {
    function FieldTranslationComponent() {
    }
    FieldTranslationComponent.prototype.ngOnInit = function () {
    };
    FieldTranslationComponent.prototype.getTranslationTag = function () {
        if (this.prop.Translateable === false) {
            return this.prop.Label;
        }
        // Because description tags were implemented in a different way, and was already being used in multiple places once discovered, it has it's own special case.
        if (this.translationTagType === TranslationTagType.Description) {
            return this.formObject.Name + ".description_" + this.prop.Name + "_t";
        }
        var baseTag = this.formObject.Name + "." + this.prop.Name;
        switch (this.translationTagType) {
            case TranslationTagType.Error:
                return baseTag + "_" + this.rule.Type + "_e";
            case TranslationTagType.Hint:
                return baseTag + "_h";
            case TranslationTagType.Label:
                return baseTag + "_l";
            case TranslationTagType.Placeholder:
                return baseTag + "_p";
        }
    };
    return FieldTranslationComponent;
}());
export { FieldTranslationComponent };
