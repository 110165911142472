import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { BigAlModule } from './../../../../../modules/bigal.module';
import { AbstractModalFeature } from '../../../modalfeature.abstract';
import { User } from '../../../../../models/user.model';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { AppUserStore } from '../../../../../stores/DataStores';

@Component({
    selector: "delete-fleet-user-component",
    templateUrl: "./delete-fleet-user.component.html",
    exportAs: "modal"
})
export class DeleteFleetUserComponent extends AbstractModalFeature {

    private id: string;

    fleetUser: User = null;

    constructor(
        protected theBen: ComponentConfigService,
        protected bigAl: BigAlModule,
        private route: ActivatedRoute,
        private appUserStore: AppUserStore,
    ) {
        super(bigAl, theBen);
    }

    confirmDeleteFleetUser() {
        this.close(true);
        this.bigAl.postDeleteFleetUser(this.id);
    }

    setEventsToWatch() {
        this.watchEvent("currentAccessibleFleetUsers");
        this.watchEvent("currentFleetUsers");
    }

    protected setData(): void {
        this.id = this.route.snapshot.paramMap.get("id");
        if (this.bigAl.currentAccessibleFleetUsers) {
            this.fleetUser = this.bigAl.currentAccessibleFleetUsers.find(fm => fm.UserId.toString() === this.id);
        }

        if (this.bigAl.currentSearchedFleetUsers) {
            this.fleetUser = this.bigAl.currentSearchedFleetUsers.find(fm => fm.UserId.toString() === this.id);
        }

    }
    protected getData(): void {
        if (!this.bigAl.currentAccessibleFleetUsers && this.appUserStore.appUser.Roles.some(r => r === UserRoleType.FleetManager)) {
            this.bigAl.getAccessibleFleetUsers();
        }

        this.setData();
    }


    public isDataAvailable(): boolean {
        if (this.fleetUser && this.id) {
            return true;
        }

        return false;
    }
}
