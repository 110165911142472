var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { AbstractFeature, PrefilledContentEntry } from '../../feature.abstract';
import { UserRoleType } from '../../../../models/enums/user-role-type.enum';
import { ActivatedRoute } from '@angular/router';
import { Constants } from './../../../../helpers/constants';
import { AppUserStore } from '../../../../stores/DataStores';
var FleetUserProfileFormComponent = /** @class */ (function (_super) {
    __extends(FleetUserProfileFormComponent, _super);
    function FleetUserProfileFormComponent(bigAl, theBen, route, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.route = route;
        _this.appUserStore = appUserStore;
        return _this;
    }
    FleetUserProfileFormComponent.prototype.getFormObject = function () {
        var prefilledContent = new Map();
        if (this.currentFleetUser) {
            prefilledContent.set("Name", [new PrefilledContentEntry("Value", this.currentFleetUser.Name)]);
            prefilledContent.set("Username", [new PrefilledContentEntry("Value", this.currentFleetUser.Username)]);
            prefilledContent.set("Email", [new PrefilledContentEntry("Value", this.currentFleetUser.Email)]);
            prefilledContent.set("Telephone", [new PrefilledContentEntry("Value", this.currentFleetUser.Telephone)]);
        }
        return _super.prototype.getFormObject.call(this, "FleetUserProfileForm", prefilledContent);
    };
    FleetUserProfileFormComponent.prototype.getRuleObject = function () {
        return _super.prototype.getRuleObject.call(this, "FleetUserProfileForm");
    };
    FleetUserProfileFormComponent.prototype.setEventsToWatch = function () {
        this.watchEvent(Constants.Event_CurrentFleetUser);
        return;
    };
    FleetUserProfileFormComponent.prototype.isDataAvailable = function () {
        if (this.currentFleetUser) {
            return true;
        }
        return false;
    };
    FleetUserProfileFormComponent.prototype.getData = function () {
        var _this = this;
        if (!this.bigAl.currentFleetUser) {
            if (this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.SystemAdminFleetAdmin; })) {
                this.bigAl.getFleetUser();
            }
            else if (this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser; })) {
                this.subscribe(this.route.params.subscribe(function (params) {
                    if (params['id']) {
                        _this.bigAl.getFleetUserByUsername(params['id']);
                    }
                }));
            }
        }
        else {
            this.setData();
        }
    };
    FleetUserProfileFormComponent.prototype.setData = function () {
        if (this.bigAl.currentFleetUser) {
            this.currentFleetUser = this.bigAl.currentFleetUser;
        }
    };
    return FleetUserProfileFormComponent;
}(AbstractFeature));
export { FleetUserProfileFormComponent };
