
<div class="container-top-space">
  <div class="element-container">
    <h1>
      <app-translate translationName="DigitalServiceCardComponent.header_t">
        <span translate-text>
          {{ 'DigitalServiceCardComponent.header_t' | translate }}
        </span>
      </app-translate>
    </h1>
    <br>

    <div class="card-wrapper" *ngIf="isDataAvailable()">

      <div class="card-container">
        <div class="flip-container _card-element-height">
          <div class="flipper">
            <div class="front _card-element-height">
              <div class="inner">
                <div id="service-card-front" class="service-card-background"></div>
              </div>
            </div>
            <div class="back _card-element-height">
              <div class="inner">
                <div id="service-card-back" class="service-card-background"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
