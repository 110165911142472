import { BigAlModule } from "../modules/bigal.module";
import { TranslatePipe } from "@ngx-translate/core";
import { ComponentConfigService } from "../services/componentConfig.service";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { ValidatorFactory } from "../rulebook/validator-factory";
import { FileUploadService } from "../services/files/file-upload.service";
import { FormObject, FormProperty, FormStep } from "./form-object.model";
import { RuleObject, Rule } from "./rule-object.model";
import { CustomTranslateService } from "../translation/customTranslateService";
import { Subscription } from "rxjs";
import { PhoneCountryCodeService } from '../services/phone-country-code.service';


export class Form {

  public visibility;
  public hide = true;
  public stepNumber = 0;
  public form: FormGroup;
  public stepperForm: FormGroup;
  public formArray: FormArray;
  public options: Map<string, string[][]> = new Map();
  public dependentOptions: Map<string, string[][][]> = new Map();
  public dependentFieldMap: Map<string, string> = new Map();
  public label: string;
  public input: HTMLInputElement;
  public selectedFile = '';
  // nextTouched and submitTouched is used for showing error messages for radio buttons and similar.
  public nextTouched: boolean[] = [];
  public submitTouched = false;
  public showWeekends = true;
  public shouldShow = true;
  private allSubscriptions: Array<Subscription> = [];
  private defaultProperties: Array<FormProperty> = null;

  private customRuleObject = {};

  constructor(
    public formObject: FormObject,
    private ruleObject: RuleObject,
    private translatePipe: TranslatePipe,
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService,
    private fb: FormBuilder,
    private validatorFactory: ValidatorFactory,
    private fileUploadService: FileUploadService,
    private translateService: CustomTranslateService,
    private phoneCountryCodeService: PhoneCountryCodeService) {
    this.initForm();
  }

  initForm() {

    // Enforce special rules that i CBA to implement the right way
    if (this.formObject.Name.includes("DriverJournalDailyLog")) {
      this.customRuleObject["Start"] = { ...this.formObject.Steps[0].Properties.find(x => x.Name === "Start") };
      this.customRuleObject["End"] = { ...this.formObject.Steps[0].Properties.find(x => x.Name === "End") };
    }

    const group = this.fb.group({});
    // construct the form group with validators
    if (this.formObject) {
      if (this.hasTranslation[this.formObject.Name] && this.hasTranslation[this.formObject.Name]["Header_t"]) {
        this.label = this.formObject.Name + ".Header_t";
      }

      // the properties are divided in a steps array with properties per step so first we need to
      // collect all properties in one array
      let properties: FormProperty[] = [];
      this.formArray = this.fb.array([]);

      this.formObject["Steps"].forEach((step: FormStep) => {
        this.nextTouched.push(false);

        let formGroup = this.fb.group({});
        let props: Array<any> = step["Properties"];

        props.forEach(prop => {

          let rules: Rule[] = this.ruleObject["Properties"].find(obj => obj["Name"] === prop.Name)["Rules"];
          prop.Rules = rules;
          prop.ShouldShow = true;
          properties.push(prop);

          // gets the validators to the form control
          let validators = this.validatorFactory.getValidators(rules, formGroup);
          if (prop.Value) {
            let propVal = prop.Value;
            if (prop.Prefix) {
              propVal = this.removePrefixFromValue(propVal, prop);
            }
            formGroup.addControl(prop.Name, this.fb.control(propVal, validators));
          }

          if (prop.Options) {
            let preselectedOption = "";
            for (let i = 0; i < prop.Options.length; i++) {
              let curOption = prop.Options[i];
              if (curOption[2] === true || prop.Value === curOption[0]) {
                preselectedOption = curOption[0];
              }
            }
            formGroup.addControl(prop.Name, this.fb.control(preselectedOption, validators));
          }
          else {
            formGroup.addControl(prop.Name, this.fb.control("", validators));
          }

          if (prop.Prefix) {
            formGroup.addControl(prop.Name + "_prefix", this.fb.control(this.getPrefixValue(prop)));
          }

          // Some fields depend on the value(s) of other fields for validation of their own value, but re-validation is not triggered automatically
          // when the dependent field's value is updated, and therefore we subscribe to each field's valueChange that it should re-validate all invalid and enabled fields
          // when a value change occurs.
          formGroup.get(prop.Name).valueChanges.subscribe((value) => {


            if (this.customRuleObject["Start"] && this.customRuleObject["Start"].Value) {

              if (prop.Name === "Start") {
                if (value.toLowerCase() !== this.customRuleObject["Start"].Value.toLowerCase()) {
                  if (!prop["WarningMessage"]) {
                    this.subscribe(this.translateService.get("RecordTrip.address-changes-affects-toll-stations").subscribe(text => {
                      prop["WarningMessage"] = text;
                      prop["ShowWarningMessage"] = true;
                    }));
                  } else {
                    prop["ShowWarningMessage"] = true;
                  }
                } else {
                  prop["ShowWarningMessage"] = false;
                }
              }

              if (prop.Name === "End" && this.customRuleObject["End"].Value) {
                if (value.toLowerCase() !== this.customRuleObject["End"].Value.toLowerCase()) {
                  if (!prop["WarningMessage"]) {
                    this.subscribe(this.translateService.get("RecordTrip.address-changes-affects-toll-stations").subscribe(text => {
                      prop["WarningMessage"] = text;
                      prop["ShowWarningMessage"] = true;
                    }));
                  } else {
                    prop["ShowWarningMessage"] = true;
                  }
                } else {
                  prop["ShowWarningMessage"] = false;
                }
              }
            }

            props.forEach(p => {
              if (p.Name === prop.Name) {
                return;
              }
              let sibling = formGroup.get(p.Name);
              if (sibling && sibling.invalid && sibling.enabled) {
                sibling.updateValueAndValidity({ onlySelf: true, emitEvent: false });
              }
            });
          });
        });

        this.formArray.push(formGroup);

        this.defaultProperties = properties;
        // subscribe to value changes when actions have to be done on change
        Object.keys(formGroup.controls).forEach((controlName: string) => {
          let matchProperty = properties.find(p => p.Name === controlName);
          if (matchProperty) {
            if (matchProperty.Readonly === true) {
              formGroup.get(matchProperty.Name).disable();
            }
            if (matchProperty.OnChange) {
              properties = this.applyOnChangeInfluences(matchProperty.Value, matchProperty, properties, formGroup);
              formGroup.get(matchProperty.Name).markAsPristine();
              this.subscribe(
                formGroup.get(matchProperty.Name).valueChanges.subscribe((newValue) => {
                  properties = this.applyOnChangeInfluences(newValue, matchProperty, properties, formGroup);
                })
              );
            }
          }
        });
      });

      this.stepperForm = this.fb.group({ formArray: this.formArray });
      properties.forEach(property => {
        // there will be options if it for example is radio buttons or select field
        if (property.DependentOptions && property.DependentOn) {
          this.dependentFieldMap.set(property.DependentOn, property.Name);
          this.dependentOptions.set(property.DependentOn, property.DependentOptions);
          this.options.set(property.Name, []);
        }
        else if (property.Options) {
          this.options.set(property.Name, property.Options);
        }

        let rules: Rule[] = this.ruleObject["Properties"].find(obj => obj["Name"] === property.Name)["Rules"];
        property.Rules = rules;
        // gets the validators to the form control
        let validators = this.validatorFactory.getValidators(rules, group);
        if (property.Value) {
          group.addControl(property.Name, this.fb.control(property.Value, validators));
        }
        else if (property.Options) {
          let preselectedOption = "";
          for (let i = 0; i < property.Options.length; i++) {
            let curOption = property.Options[i];
            if ((curOption[2] && <boolean><any>curOption[2] === true) || property.Value === curOption[0]) {
              preselectedOption = curOption[0];
            }
          }
          group.addControl(property.Name, this.fb.control(preselectedOption, validators));
        }
        else {
          group.addControl(property.Name, this.fb.control("", validators));
        }

        if (property.Control === "DatePicker" && property.Weekends === false) {
          this.showWeekends = false;
        }
      });

    }
    else {
      console.warn("Did not get a formObject in the form component. Did you forgot to add a formObject to the form tag? Or is the form not added to the rulebook?");
    }
    this.form = group;
  }

  public uploadFile(event) {
    this.upload(event, (file: File, input: HTMLInputElement) => this.fileUploadService.defaultFileUpload(file, input, this.bigAl.appSettings));
  }

  public uploadGloveboxFile(event) {
    this.upload(event, (file: File, input: HTMLInputElement) => this.fileUploadService.gloveboxFileUpload(file, input, this.bigAl.appSettings));
  }

  private upload(event, uploadFunction: (file: File, input: HTMLInputElement) => void) {
    this.input = event.target;
    const file: File = event.target.files[0];
    this.selectedFile = file.name;
    uploadFunction(file, this.input);
  }

  hasTranslation(key: string) {
    let result: boolean;
    this.translateService.get(key).subscribe(res => {
      if (res === key) {
        result = false;
      }
      else {
        result = true;
      }
    });

    return result;
  }

  public getTranslationKey(key: string): string {
    let translationKey = [this.formObject.Name] + '.' + [key];
    return translationKey;
  }

  public translationExists(key: string): boolean {
    let translationKey = this.getTranslationKey(key);
    return this.hasTranslation(translationKey);
  }

  /**
* Gets the text translated for the placeholder or an empty string if no text is found
* @param key the property name the text field belongs to
*/
  getPlaceholder(key: string) {
    return this.translatePipe.transform(this.formObject.Name + "." + key + "_p");
  }

  getPlaceholderForProperty(prop: FormProperty) {
    if (!prop.Translateable) {
      return prop.Label;
    }
    return this.getPlaceholder(prop.Name);
  }

  onSelect(event, second) {
    for (let i = 0; i < this.formObject.Steps.length; i++) {
      if (this.formObject.Steps[i]["Properties"].find(obj => obj["Name"] === second)) {
        let field = this.formObject.Steps[i]["Properties"].find(obj => obj["Name"] === second);
        let map = field["OptionsMap"];
        let options: string[][] = map.get(event.value);
        field.Options = options;
      }
    }
  }

  getRules(propName): Rule[] {
    let rules = [];
    this.formObject.Steps.forEach(step => {
      step.Properties.forEach(prop => {
        if (prop.Name === propName) {
          rules = prop.Rules;
          return;
        }
      });
    });
    return rules;
  }

  public getProperty(name: string): FormProperty {
    let property: FormProperty = null;
    this.formObject.Steps.forEach(step => {
      step.Properties.forEach(prop => {
        if (prop.Name === name) {
          property = prop;
        }
      });
    });
    return property;
  }

  selectedDropdownValue(key, chosenValue) {
    let curDependentOptions = this.dependentOptions.get(key);
    if (!curDependentOptions) {
      return;
    }
    let relevantOptions = curDependentOptions[chosenValue.value];
    if (!relevantOptions) {
      console.warn("Did not find the appropriate set of dependent options for value: " + chosenValue);
    }
    let dependentField = this.dependentFieldMap.get(key);
    this.options.set(dependentField, relevantOptions);
  }

  goForward(i: number) {
    this.nextTouched[i] = true;
  }

  validate() {
    // Re-validates all active fields in the form. This is to ensure that when e.g. submitting, that the user is not actually submitting an invalid form.
    this.formArray.controls.forEach(formGroup => {
      let fg: FormGroup = formGroup as FormGroup;
      if (fg && fg.controls) {
        Object.keys(fg.controls).forEach(field => {
          const control = fg.get(field);
          // Checkbox validation causes problems for fields that are only shown if checkbox is checked, however when validating the form, the checkbox has already been validated anyway.
          if (control.value === true) {
            return;
          }
          if (control.enabled) {
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    });

    // Depending on how the re-validation went, return true or false.
    if (this.formArray.invalid) {
      return false;
    }
    return true;
  }



  public dateFilter = (d: Date) => {
    if (!this.showWeekends) {
      const day = d.getDay();
      return day !== 0 && day !== 6;
    }

    return true;
  }

  private applyOnChangeInfluences(newValue: any, property: FormProperty, currentProperties: Array<FormProperty>, formGroup: FormGroup): Array<FormProperty> {

    property.OnChange.forEach(changePrefs => {
      let dependentCheck = true;
      if (changePrefs.DependentOn) {
        let matchingField = currentProperties.find(propertyField => propertyField.Name === changePrefs.DependentOn.Name);
        if (!matchingField || formGroup.get(matchingField.Name).value != changePrefs.DependentOn.Value) {
          dependentCheck = false;
        }
      }
      if ((changePrefs.Value === newValue || changePrefs.Value === '%%any%%') && dependentCheck) {
        changePrefs.Actions.forEach(action => {
          switch (action.Type) {
            case 'detected':

              break;
            case 'hide':
              action.Influence.forEach(field => {
                let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                if (matchingField) {
                  matchingField.Hidden = true;
                  formGroup.get(matchingField.Name).disable();
                }
              });
              break;
            case 'show':
              action.Influence.forEach(field => {
                let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                if (matchingField) {
                  matchingField.Hidden = false;
                  formGroup.get(matchingField.Name).enable();
                }
              });
              break;
            case 'disable':
              action.Influence.forEach(field => {
                let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                if (matchingField) {
                  formGroup.get(matchingField.Name).setValue(null);
                  formGroup.get(matchingField.Name).disable();
                }
              });
              break;
            case 'enable':
              action.Influence.forEach(field => {
                let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                let matchingDefaultField = this.defaultProperties.find(defaultField => defaultField.Name === field);
                if (matchingField) {
                  formGroup.get(matchingField.Name).enable();
                }
              });
              break;
            case 'reset':
              action.Influence.forEach(field => {
                let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                let matchingDefaultField = this.defaultProperties.find(defaultField => defaultField.Name === field);
                if (matchingField && matchingDefaultField) {
                  formGroup.get(matchingField.Name).setValue(matchingDefaultField.Value);
                }
              });
              break;
            case 'takeFieldValue':
              let name1 = action.By.Between[0];
              action.Influence.forEach(field => {
                let newField = currentProperties.find(propertyField => propertyField.Name === name1);
                let oldFiled = currentProperties.find(propertyField => propertyField.Name === field);
                if (newField && oldFiled) {
                  formGroup.get(oldFiled.Name).setValue(formGroup.get(newField.Name).value);
                }
              });
              break;
            case 'changeValues':
              if ((newValue !== null || newValue !== undefined)) {
                action.Influence.forEach(field => {
                  let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                  if (matchingField) {
                    let oldValue = formGroup.get(matchingField.Name).value;
                    let newValue = this.getChangeValueActionResult(action.By, currentProperties, formGroup);

                    if (oldValue !== newValue && !isNaN(parseInt(newValue))) {
                      formGroup.get(matchingField.Name).setValue(newValue);
                    }
                  }
                });
              }
              break;
            case 'changeValuesNumber':
              if ((newValue !== null || newValue !== undefined) && new RegExp('^[0-9]+$').test(newValue)) {
                action.Influence.forEach(field => {
                  let matchingField = currentProperties.find(propertyField => propertyField.Name === field);
                  if (matchingField) {
                    let oldValue = formGroup.get(matchingField.Name).value;
                    let newValue = this.getChangeValueActionResult(action.By, currentProperties, formGroup);

                    if (oldValue !== newValue && !isNaN(parseInt(newValue))) {
                      formGroup.get(matchingField.Name).setValue(newValue);
                    }
                  }
                });
              }
              break;
            case 'calculateDistance':
              // handled in the form component
              break;
            case 'toggleDriverJournalTripPurposeValidation':
              if ((newValue !== null && newValue !== undefined)) {

                action.Influence.forEach(toInfluence => {

                  let findField = this.formObject.Steps[0].Properties.find(prop => prop.Name === toInfluence);
                  let getField = formGroup.get(findField.Name);

                  console.log(" ");

                  if (newValue === 1) {
                    getField.clearValidators();
                    getField.setValidators(Validators.required);
                    getField.updateValueAndValidity();
                  } else if (newValue === 2) {
                    getField.clearValidators();
                    getField.updateValueAndValidity();
                  }
                });
              }
              break;
            default:
              console.warn('COULD NOT FIND ACTION', action.Type);
              break;
          }
        });
      }
    });

    return currentProperties;
  }

  private getChangeValueActionResult(actionBy, currentProperties, formGroup) {
    let newValue = null;
    switch (actionBy.Approach) {
      case 'math':
        if (actionBy.Operation === 'difference') {
          let fieldValues = new Array<string>();
          actionBy.Between.forEach(betweenField => {
            fieldValues.push(formGroup.get(betweenField).value);
          });

          if (fieldValues.length > 1 && !fieldValues.some(v => v === null || v === "")) {
            newValue = fieldValues[0];
            for (let i = 1; i < fieldValues.length; i++) {
              newValue -= parseInt(fieldValues[i]);
            }
          }
          else {
            newValue = null;
          }

          if (newValue < 0) {
            newValue = null;
          }
        }

        if (actionBy.Operation === 'max') {
          let fieldValues = new Array<number>();
          actionBy.Between.forEach(betweenField => {
            let value = formGroup.get(betweenField).value;
            if (value != null && value !== "") {
              fieldValues.push(parseInt(value));
            }
          });

          if (fieldValues.length > 1) {
            newValue = fieldValues.reduce((a, b) => Math.min(a, b));
          }
          else {
            newValue = null;
          }
        }
        break;
      default:
        console.warn('COULD NOT FIND APPROACH BY TYPE');
    }

    return newValue;
  }

  public getPrefixValue(property: FormProperty): string {
    if (property.Prefix) {
      switch (property.Prefix.trim().toLowerCase()) {
        case "phonecountrycode":
          let curVal: string = property.Value;
          // If there is no "+" then it is not a country code anyways.
          if (!curVal.startsWith("+")) {
            return this.phoneCountryCodeService.getDefaultCountryCode(this.bigAl.localLanguageCountryCode);
          }
          // We start with string length 2, because "+1" is a valid country code (US)
          let strLength = 2;
          let countryCodes = this.phoneCountryCodeService.getCountryCodes();
          while (strLength < 5) {
            let substr = curVal.substr(0, strLength);
            if (countryCodes.has(substr)) {
              return substr;
            }
            strLength++;
          }
          break;
      }
    }

    return "";
  }

  private removePrefixFromValue(value: string, property: FormProperty): string {
    switch (property.Prefix.trim().toLowerCase()) {
      case "phonecountrycode":
        // If there is no "+" then it is not a country code anyways.
        if (!value.startsWith("+")) {
          return value;
        }
        // We start with string length 2, because "+1" is a valid country code (US)
        let strLength = 2;
        let countryCodes = this.phoneCountryCodeService.getCountryCodes();
        while (strLength < 5) {
          let substr = value.substr(0, strLength);
          if (countryCodes.has(substr)) {
            return value.substr(strLength);
          }
          strLength++;
        }
        break;
    }
    return value;
  }

  public getPrefixOptions(property: FormProperty): string[] {
    let options: string[] = [];
    switch (property.Prefix.trim().toLowerCase()) {
      case "phonecountrycode":
        this.phoneCountryCodeService.getCountryCodes().forEach(val => options.push(val));
        break;
    }
    return options;
  }

  private subscribe(subscription: Subscription): void {
    this.allSubscriptions.push(subscription);
  }

  public unsubscribe(): void {
    this.allSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
