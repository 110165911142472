import { UserRoleType } from "./enums/user-role-type.enum";
import { UserSettings } from "./user-settings.model";
var AppUser = /** @class */ (function () {
    function AppUser(Username, AuthenticationUserId, Roles, RefreshKey, Settings, UserSettings, IsPasswordExpiry) {
        this.Username = Username;
        this.AuthenticationUserId = AuthenticationUserId;
        this.Roles = Roles;
        this.RefreshKey = RefreshKey;
        this.Settings = Settings;
        this.UserSettings = UserSettings;
        this.IsPasswordExpiry = IsPasswordExpiry;
    }
    AppUser.GetInitialUser = function () {
        return new AppUser("", "", [UserRoleType.Anonymous], "", { ParkingApp: false }, 
        // { DriverJournalAutoNotificationTime: 20, ParkingTrackingConsent: false, DriverJournalTrackingConsent: false, ParkingApp: false },
        new UserSettings(), false);
    };
    return AppUser;
}());
export { AppUser };
