<div *ngIf="!reportError">
  <app-modal size='small' type='error' (modalClosing)="closeModal($event)" *ngIf="currentError" class="api-error-modal">

    <div modal-header>
      <app-translate translationName="{{getErrorTypeTranslationTagTitle()}}">
        <span translate-text>{{getErrorTypeTranslationTagTitle()|translate}}</span>
      </app-translate>
    </div>
    <div modal-body>
      <p>
        <app-translate translationName="{{getErrorTypeTranslationTagBody()}}">
          <span translate-text>{{getErrorTypeTranslationTagBody()|translate}}</span>
        </app-translate>
      </p>
    </div>
    <div modal-footer>
      <!-- <button mat-raised-button *ngIf="allowErrorReporting" color="primary" type="button" (click)="reportErrorForm()">
        <app-translate translationName="ApiError.ReportError_button_t">
          <span translate-text>{{'ApiError.ReportError_button_t'|translate}}</span>
        </app-translate>
      </button> -->
      <!-- <button mat-raised-button mat-button (click)="closeModal(true)">
        <app-translate translationName="ApiError.Close_t">
          <span translate-text>{{'ApiError.Close_t'|translate}}</span>
        </app-translate>
      </button> -->
    </div>
  </app-modal>
</div>
<div *ngIf="reportError">
  <app-modal size='small' type='error' (modalClosing)="closeModal($event)" *ngIf="currentError" class="api-error-modal">
    <div modal-header>
      <app-translate translationName="ApiError.ReportError_Header_t">
        <span translate-text>{{'ApiError.ReportError_Header_t'|translate}}</span>
      </app-translate>
    </div>
    <div modal-subheader>
      <app-translate translationName="ApiError.ReportError_Subheader_t">
        <span translate-text>{{'ApiError.ReportError_Subheader_t'|translate}}</span>
      </app-translate>
    </div>
    <div modal-body>
      <div *ngIf="currentError">
        <app-form [formObject]="getFormObject()" [ruleObject]="getRuleObject()"></app-form>
      </div>
    </div>
  </app-modal>
</div>