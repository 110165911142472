import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, DecimalPipe, registerLocaleData } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import localeDk from '@angular/common/locales/da';
import localeSe from '@angular/common/locales/se';
import localeNo from '@angular/common/locales/nb';

// Third-party
import { Angulartics2Module } from 'angulartics2';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { ModalSliderModule } from './modules/components/modalslider.module';
import { BigAlModule } from "./modules/bigal.module";
import { SharedModule } from './modules/components/shared.module';
import { RoutingModule } from './modules/routing.module';
import { CustomMaterialModule } from './modules/custom-material.module';
import { MenuModule } from './modules/components/menu.module';
import { GenericModule } from './modules/components/generic.module';
import { GloveboxModule } from "./components/features/glovebox/glovebox.module";

// Components
import { BaseComponent } from './components/init/base.component';
import { InitComponent } from "./components/init/init.component";
import { ApiSuccessComponent } from './components/shared/api-success/api-success.component';

// Services
import { ComponentConfigService } from './services/componentConfig.service';
import { ApiService } from "./services/api.service";
import { ScreenService } from './services/screen.service';
import { BrowserService } from './services/browser.service';
import { CustomTranslateService } from './translation/customTranslateService';
import { SpinnerService } from './services/spinner.service';
import { NavigationService } from './services/navigation.service';
import { TableService } from './services/tables/table.service';
import { FleetReportService } from './services/fleet-report.service';
import { FleetUserService } from './services/fleet-user.service';
import { DatePatternService } from './services/datepattern.service';
import { ModalService } from './services/modals/modal.service';
import { BusinessTripService } from './services/driverjournal/business-trip.service';
import { RecordTripService } from './services/driverjournal/recordtrip.service';
import { TableDataUpdatesService } from './services/tables/table-data-updates.service';
import { DeviceMotionService } from './services/driverjournal/device-motion.service';
import { DailyUpdateService } from "./services/driverjournal/daily-update.service";
import { DjTabsService } from "./services/driverjournal/dj-tabs.service";

// Other
import { ValidatorFactory } from './rulebook/validator-factory';
import { ApiInterceptor } from './interceptors/apiInterceptor.interceptor';
import { QueryTypeEmailValidator } from "./rulebook/query-type-email.validator";
import { QueryTypeOnlyDigitsValidator } from "./rulebook/query-type-only-digits.validator";

// Stores
import { AppSettingsStore, DriverStore, BusinessPartnerStore, AppUserStore } from './stores/DataStores';
import { FirebaseService } from "./services/firebase.service";
import { MonitoringService } from "./services/logging.service";
import { AppMaintenanceNoticeComponent } from './components/shared/app-maintenance-notice/app-maintenance-notice.component';


registerLocaleData(localeDk, 'da');
registerLocaleData(localeSe, 'sv');
registerLocaleData(localeNo, 'nb');

export function initializeAppSettings(settingStore: AppSettingsStore) {
  return () => settingStore.init();
}

@NgModule({
  imports: [
    HttpClientModule,
    HttpModule,
    BigAlModule,
    BrowserModule,
    RoutingModule,
    GloveboxModule,
    CustomMaterialModule,
    TranslateModule.forRoot({}),
    GenericModule,
    MenuModule,
    SharedModule,
    ModalSliderModule,
    Angulartics2Module.forRoot(),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyASH6yTiLsA-7DBZIRx2BbV68TwpZEEAuw",
      libraries: ["places"]
    })
  ],
  declarations: [
    InitComponent,
    ApiSuccessComponent,
    BaseComponent,
    AppMaintenanceNoticeComponent,
  ],
  bootstrap: [
    InitComponent
  ],
  providers: [
    Title,
    ApiService,
    ScreenService,
    BrowserService,
    ComponentConfigService,
    CustomTranslateService,
    SpinnerService,
    RecordTripService,
    NavigationService,
    DecimalPipe,
    TableDataUpdatesService,
    DatePatternService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    ModalService,
    FleetUserService,
    FleetReportService,
    BusinessTripService,
    { provide: APP_BASE_HREF, useValue: '/' },
    TableService,
    ValidatorFactory,
    QueryTypeEmailValidator,
    QueryTypeOnlyDigitsValidator,
    DeviceMotionService,
    DailyUpdateService,
    DjTabsService,
    FirebaseService,
    // Data stores
    BusinessPartnerStore,
    DriverStore,
    AppUserStore,
    AppSettingsStore,
    MonitoringService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppSettings,
      deps: [AppSettingsStore],
      multi: true
    }
  ],
  exports: [
  ],
  entryComponents: [
  ]
})
export class AppModule {
  constructor() { }
}
