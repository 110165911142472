import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUserStore, DocumentStore } from '../../../stores/DataStores';
import { IDocument } from '../../../stores/store-sections/document.store';
import { IStoreResult } from '../../../sharedtypes/interfaces/sharedtypes.interface';
import { BigAlModule } from '../../../modules/bigal.module';
import { FleetUserService } from '../../../services/fleet-user.service';
import { GloveboxInvoiceContainer } from '../../../models/glovebox/glovebox-invoice-container.interface';
import { GloveboxInvoice } from '../../../models/glovebox/glovebox-invoice.interface';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LocaleUtil } from '../../../helpers/locale.util';
import { ScreenService } from '../../../services/screen.service';
import { PageEvent, MatPaginator } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-glovebox',
  templateUrl: './glovebox.component.html',
  styleUrls: ['./glovebox.component.scss'],
  providers: [TranslatePipe],
  host: { 'class': 'component-outlet' }
})
export class GloveboxComponent implements OnDestroy, OnInit {
  private rowstoAdd: number = 10;
  private subscription = new Subscription();

  gloveboxInvoiceContainers: GloveboxInvoiceContainer[] = [];
  gloveboxInvoiceContainersOriginal: GloveboxInvoiceContainer[] = [];

  documents: IDocument[] = [];
  isDoneLoading: boolean = false;
  didFail: boolean = false;
  showTakeOverBar = false;
  isApp: boolean;
  @ViewChild('paginator') paginator: MatPaginator;

  offset: number = 0;
  limit: number = 10;

  displayedColumns: string[] = ['Name', 'CreateDate', 'Download'];

  searchForm = new FormGroup({
    searchField: new FormControl([''])
  });


  constructor(
    private documentStore: DocumentStore,
    private bigAl: BigAlModule,
    private fleetUserService: FleetUserService,
    private translateService: TranslateService,
    public screenService: ScreenService,
    private appUserStore: AppUserStore,
  ) { }

  ngOnInit(): void {

    // Setup subscriptions
    this.subscription.add(this.documentStore.onGetDocuments$.subscribe((payload: IStoreResult<IDocument[]>) => {
      if (payload.isSuccess && !payload.isInitial) {
        this.documents = payload.model;
        this.transformDocument();
        this.gloveboxInvoiceContainersOriginal = Object.assign([], this.gloveboxInvoiceContainers);
        this.isDoneLoading = true;
      } else if (!payload.isSuccess && payload.error) {
        this.didFail = true;
        if (payload.error['error']['Message'].toUpperCase().includes('NFO0047')) {
          this.bigAl.logout();
        }
      }
    }));

    this.isApp = this.bigAl.appSettings.IsApp;

    this.subscription.add(this.fleetUserService.isInTakeOverMode.subscribe(isInTakeOverMode => {
      this.showTakeOverBar = isInTakeOverMode;
    }));
    // Fetch data
    this.documentStore.getDocuments(this.appUserStore.appUser.AuthenticationUserId, true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  downloadFile(gloveboxInvoice: GloveboxInvoice): void {

    const token = localStorage.getItem("api_token");
    if (!token) {
      return;
    }

    window.open(`${this.bigAl.appSettings.ApiBaseUrl}file/Download/${gloveboxInvoice.Id}?Authorization=${token}`, `_blank`);
  }

  private transformDocument() {
    this.gloveboxInvoiceContainers = [];
    for (const document of this.documents) {
      let exists = false;

      for (const gloveboxInvoiceContainer of this.gloveboxInvoiceContainers) {
        if (document.SortingVariables.BusinessPartnerId === gloveboxInvoiceContainer.BusinessPartnerId &&
          document.SortingVariables.BusinessPartnerName === gloveboxInvoiceContainer.BusinessPartnerName) {
          exists = true;
          let gloveboxInvoice: GloveboxInvoice = {
            'CreateDate': new Date(document.CreateDate),
            'FileName': document.FileName,
            'Id': document.Id,
            'MediaHeaderType': document.MediaHeaderType
          };
          gloveboxInvoiceContainer.GloveboxInvoices.push(gloveboxInvoice);
        }
      }

      if (!exists) {
        let gloveboxInvoice: GloveboxInvoice = {
          'CreateDate': new Date(document.CreateDate),
          'FileName': document.FileName,
          'Id': document.Id,
          'MediaHeaderType': document.MediaHeaderType
        };

        let gloveboxInvoiceContainer: GloveboxInvoiceContainer = {
          'GloveboxInvoices': [gloveboxInvoice],
          'BusinessPartnerId': document.SortingVariables.BusinessPartnerId,
          'BusinessPartnerName': document.SortingVariables.BusinessPartnerName,
          'invoicesToShow': this.rowstoAdd
        };

        this.gloveboxInvoiceContainers.push(gloveboxInvoiceContainer);
      }
    }

    // Sorting all the gloveboxInvoiceContainers by partner name
    this.gloveboxInvoiceContainers = this.gloveboxInvoiceContainers.sort(this.sortGloveboxInvoiceContainers);

    for (const gloveboxInvoiceContainer of this.gloveboxInvoiceContainers) {
      // Sorting all the gloveboxInvoice by create date
      gloveboxInvoiceContainer.GloveboxInvoices = gloveboxInvoiceContainer.GloveboxInvoices.sort(this.sortGloveboxInvoices);
    }
  }

  showMore(gloveboxInvoiceContainer: GloveboxInvoiceContainer) {
    gloveboxInvoiceContainer.invoicesToShow += this.rowstoAdd;
  }

  sortGloveboxInvoiceContainers(obj1: GloveboxInvoiceContainer, obj2: GloveboxInvoiceContainer): number {
    if (obj1.BusinessPartnerName > obj2.BusinessPartnerName) {
      return 1;
    }
    else if (obj1.BusinessPartnerName < obj2.BusinessPartnerName) {
      return -1;
    }
    return 0;
  }

  sortGloveboxInvoices(obj1: GloveboxInvoice, obj2: GloveboxInvoice): number {
    if (obj1.CreateDate < obj2.CreateDate) {
      return 1;
    }
    else if (obj1.CreateDate > obj2.CreateDate) {
      return -1;
    }
    return 0;
  }

  getLocaleDate(date) {
    return LocaleUtil.getLocaleShortDate(date, this.translateService.currentLang);
  }

  changePage(page: PageEvent) {
    this.offset = this.limit * page.pageIndex;
  }

  search(): void {
    let searchText = (this.searchForm.controls['searchField'].value + '').toLowerCase();
    this.offset = 0;
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.gloveboxInvoiceContainers = this.gloveboxInvoiceContainersOriginal.filter(x => x.BusinessPartnerName.toLowerCase().indexOf(searchText) >= 0 || x.BusinessPartnerId.indexOf(searchText) >= 0);
  }
}
