import { DatePipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var LocalizedDatePipe = /** @class */ (function () {
    function LocalizedDatePipe(translateService) {
        this.translateService = translateService;
    }
    LocalizedDatePipe.prototype.transform = function (value, pattern) {
        if (pattern === void 0) { pattern = 'mediumDate'; }
        var datePipe = new DatePipe(this.translateService.currentLang);
        return datePipe.transform(value, pattern);
    };
    return LocalizedDatePipe;
}());
export { LocalizedDatePipe };
