var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
import { CustomTranslateService } from "../../translation/customTranslateService";
import { IDefaultSettings, IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";
// Helpers
import { Utils } from "../../helpers/utils";
import { ApiUrl } from "../../helpers/apiUrls";
import { Constants } from "../../helpers/constants";
import * as i0 from "@angular/core";
import * as i1 from "../../translation/customTranslateService";
import * as i2 from "../../services/api.service";
var AppSettingsStore = /** @class */ (function () {
    // #endregion
    function AppSettingsStore(translateService, apiService) {
        this.translateService = translateService;
        this.apiService = apiService;
        // #region Observables
        this.languageChanged = new BehaviorSubject({ model: null, isInitial: true });
        this.onLanguageChanged$ = this.languageChanged.asObservable();
        this.isStoreReady = false;
    }
    AppSettingsStore.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getSettings()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.initWhoAmI()];
                    case 2:
                        _a.sent();
                        this.isStoreReady = true;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.isStoreReady = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppSettingsStore.prototype.changeLanguage = function (languageCode) {
        // Update localstorage
        localStorage.setItem(Constants.LocalStorage_Language, languageCode);
        this.appSettings.LanguageCode = languageCode;
        this.translateService.changeLanguageByLanguageCode(this.appSettings.LanguageCode);
        // Save to backend
        this.postChangeLanguage(this.appSettings.LanguageCode);
    };
    AppSettingsStore.prototype.postChangeLanguage = function (language) {
        var _this = this;
        this.apiService.postAsync(ApiUrl.LanguageChange + "/" + language, null, this.appSettings)
            .then(function (data) {
            // this.currentFeed = null;
            // this.getFeed();
            // Emit language change to subscribers
            _this.languageChanged.next({ model: language, isSuccess: true, isCached: true });
        })
            .catch(function (err) {
            _this.languageChanged.next({ model: null, isSuccess: false, error: err });
        });
    };
    AppSettingsStore.prototype.getSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiService.getFileAsync(ApiUrl.DefaultSettingsJson)
                        .then(function (data) {
                        _this.defaultSettings = data;
                    })
                        .catch(function (err) {
                    })];
            });
        });
    };
    AppSettingsStore.prototype.initWhoAmI = function () {
        return __awaiter(this, void 0, void 0, function () {
            var whoAmILocation;
            var _this = this;
            return __generator(this, function (_a) {
                if (this.appSettings) {
                    return [2 /*return*/];
                }
                whoAmILocation = this.defaultSettings.WhoAmI[location.host];
                if (whoAmILocation === undefined) {
                    whoAmILocation = this.defaultSettings.WhoAmI["app"];
                }
                return [2 /*return*/, this.apiService.getAsync(ApiUrl.WhoAmI + "/" + whoAmILocation, this.defaultSettings)
                        .then(function (data) {
                        // Update localstorage
                        localStorage.setItem(Constants.LocalStorage_CountryCode, data.CountryCode);
                        // Update appSettings
                        _this.appSettings = _this.setTraceId(data);
                        _this.appSettings.FeatureComponentConfiguration = data.FeatureComponentConfiguration;
                        _this.appSettings.LanguageCode = (localStorage.getItem(Constants.LocalStorage_Language)) ? localStorage.getItem(Constants.LocalStorage_Language) : _this.translateService.getCountryLanguage(_this.appSettings.CountryCode);
                        // this.localLanguageCountryCode = this.appSettings.CountryCode;
                        _this.appSettings.IsApp = _this.defaultSettings.IsApp;
                        if (localStorage.getItem(Constants.LocalStorage_Language)) {
                            _this.changeLanguage(localStorage.getItem(Constants.LocalStorage_Language));
                        }
                        // this.setDefaultLocation(data.CountryCode);
                    })
                        .catch(function (err) {
                    })];
            });
        });
    };
    AppSettingsStore.prototype.setTraceId = function (appSettings) {
        if (!this.currentTraceId) {
            this.currentTraceId = Utils.NewGUID();
        }
        appSettings.TraceId = this.currentTraceId;
        return appSettings;
    };
    /**
     * Used when you need to set the language local id. It is dependent on country
     * @returns the local id string. Default is "da-DK" for Denmark
     */
    AppSettingsStore.prototype.getLocalId = function () {
        var locale_id = "da-DK";
        switch (this.appSettings.CountryCode) {
            case 'dk': {
                locale_id = 'da-DK';
                break;
            }
            case 'se': {
                locale_id = 'sv-SE';
                break;
            }
            case 'no': {
                locale_id = 'nb-NO';
                break;
            }
        }
        return locale_id;
    };
    AppSettingsStore.ngInjectableDef = i0.defineInjectable({ factory: function AppSettingsStore_Factory() { return new AppSettingsStore(i0.inject(i1.CustomTranslateService), i0.inject(i2.ApiService)); }, token: AppSettingsStore, providedIn: "root" });
    return AppSettingsStore;
}());
export { AppSettingsStore };
