var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
//
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { BigAlModule } from './../../../../../modules/bigal.module';
import { AbstractModalFeature } from '../../../modalfeature.abstract';
import { AppUserStore } from '../../../../../stores/DataStores';
var DeleteBusinessUserComponent = /** @class */ (function (_super) {
    __extends(DeleteBusinessUserComponent, _super);
    function DeleteBusinessUserComponent(theBen, bigAl, route, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.route = route;
        _this.appUserStore = appUserStore;
        _this.businessUser = null;
        return _this;
    }
    DeleteBusinessUserComponent.prototype.confirmDeleteBusinessUser = function () {
        this.close(true);
        this.bigAl.deleteBusinessUser(this.id);
    };
    DeleteBusinessUserComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("currentBusinessUsers");
    };
    DeleteBusinessUserComponent.prototype.setData = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get("id");
        if (this.bigAl.currentBusinessUsers) {
            this.businessUser = this.bigAl.currentBusinessUsers.find(function (fm) { return fm.UserId.toString() === _this.id; });
        }
    };
    DeleteBusinessUserComponent.prototype.getData = function () {
        this.setData();
    };
    DeleteBusinessUserComponent.prototype.isDataAvailable = function () {
        if (this.businessUser && this.id) {
            return true;
        }
        return false;
    };
    return DeleteBusinessUserComponent;
}(AbstractModalFeature));
export { DeleteBusinessUserComponent };
