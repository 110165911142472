import { Component, OnInit } from '@angular/core';

import { BigAlModule } from '../../../modules/bigal.module';
import { NavigationService } from '../../../services/navigation.service';
import { ApiService } from '../../../services/api.service';
import { Translation } from '../../../models/translation.model';
import { TranslationGroup } from '../../../models/translation.group.model';
import { CustomTranslateService } from '../../../../app/translation/customTranslateService';

@Component({
  selector: 'app-translate-list',
  templateUrl: './translate-list.html',
  exportAs: "modal"
})
export class TranslateListComponent implements OnInit {

  private readonly aldCompanyId = 1;
  translationGroups: TranslationGroup[] = [];

  constructor(
    private navigationService: NavigationService,
    private customTranslateService: CustomTranslateService) {
  }

  ngOnInit() {
    var languageCode = this.customTranslateService.getCurrentLang();
    var result = this.customTranslateService.getAllCompanyTranslations(this.aldCompanyId, languageCode);

    for (var propertyName in result) {
      var currentGroup = {
        'GroupName': propertyName,
        'Translations': []
      };

      var currentTranslations = result[propertyName];

      for (var currentTranslationProp in currentTranslations) {
        currentGroup.Translations.push(new Translation(propertyName + '.' + currentTranslationProp, currentTranslations[currentTranslationProp], languageCode, this.aldCompanyId));
      }
      this.translationGroups.push(currentGroup)
    }
  }

  back() {
    this.navigationService.back();
  }
}
