import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { GloveboxComponent } from './glovebox.component';
import { SearchFilterPipe } from '../../../pipes/search-filter.pipe';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SharedModule } from '../../../modules/components/shared.module';
import { CustomMaterialModule } from '../../../modules/custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericModule } from '../../../modules/components/generic.module';
import { GloveboxTableService } from '../../../services/tables/glovebox-table.service';
import { GloveboxRoutingModule } from './glovebox-routing.module';
import { PipesModule } from '../../../modules/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    GenericModule,
    FormsModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    GloveboxRoutingModule,
    PipesModule
  ],
  providers: [
    SearchFilterPipe,
    TranslatePipe,
    GloveboxTableService
  ],
  declarations: [
    GloveboxComponent
  ],
  exports: [
    GloveboxComponent
  ]
})

export class GloveboxModule { }
