var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { BigAlModule } from '../../../modules/bigal.module';
import { AbstractFeature } from '../../features/feature.abstract';
import { AppUserStore } from '../../../stores/DataStores';
import { ChangePasswordForm } from '../../../models/changepassword.model';
var ChangePasswordFormComponent = /** @class */ (function (_super) {
    __extends(ChangePasswordFormComponent, _super);
    function ChangePasswordFormComponent(bigAl, theBen, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.appUserStore = appUserStore;
        _this.closeButton = false;
        return _this;
    }
    ChangePasswordFormComponent.prototype.getFormObject = function () {
        var prefilledEntries = new Map();
        var formObject = _super.prototype.getFormObject.call(this, "ChangePasswordForm", null);
        return formObject;
    };
    ChangePasswordFormComponent.prototype.getRuleObject = function () {
        var ruleObject = _super.prototype.getRuleObject.call(this, "ChangePasswordForm");
        return ruleObject;
    };
    ChangePasswordFormComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("currentUser");
    };
    ChangePasswordFormComponent.prototype.getData = function () {
        this.setCurrentLogin();
    };
    ChangePasswordFormComponent.prototype.setData = function () {
        this.setCurrentLogin();
    };
    ChangePasswordFormComponent.prototype.isDataAvailable = function () {
        return true;
    };
    ChangePasswordFormComponent.prototype.setCurrentLogin = function () {
        this.currentLogin = new ChangePasswordForm("", "", "");
    };
    return ChangePasswordFormComponent;
}(AbstractFeature));
export { ChangePasswordFormComponent };
