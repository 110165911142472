/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i2 from "@angular/material/radio";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/cdk/collections";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../shared/translate/translate.component.ngfactory";
import * as i7 from "../shared/translate/translate.component";
import * as i8 from "../../translation/customTranslateService";
import * as i9 from "../../services/navigation.service";
import * as i10 from "../../services/modals/modal.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "@angular/forms";
import * as i13 from "@angular/common";
import * as i14 from "../shared/form/form.component.ngfactory";
import * as i15 from "@angular/material/core";
import * as i16 from "../../directives/app-date-adapter";
import * as i17 from "../../stores/store-sections/app-settings.store";
import * as i18 from "../shared/form/form.component";
import * as i19 from "../../modules/bigal.module";
import * as i20 from "../../services/componentConfig.service";
import * as i21 from "../../rulebook/validator-factory";
import * as i22 from "../../services/files/file-upload.service";
import * as i23 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i24 from "../../services/toast.service";
import * as i25 from "../../services/phone-country-code.service";
import * as i26 from "../shared/api-error/api-error.component.ngfactory";
import * as i27 from "../shared/api-error/api-error.component";
import * as i28 from "../../services/api.service";
import * as i29 from "./login.component";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "mat-radio-button", [["class", "mat-radio-button"], ["color", "primary"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "id", 0]], [[null, "change"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.bigAl.changeLanguage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatRadioButton_0, i1.RenderType_MatRadioButton)), i0.ɵdid(3, 4440064, [[1, 4]], 0, i2.MatRadioButton, [[2, i2.MatRadioGroup], i0.ElementRef, i0.ChangeDetectorRef, i3.FocusMonitor, i4.UniqueSelectionDispatcher, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"], id: [1, "id"], checked: [2, "checked"], value: [3, "value"] }, { change: "change" }), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "primary"; var currVal_5 = _v.context.index; var currVal_6 = (_co.bigAl.appSettings.LanguageCode === _v.context.$implicit); var currVal_7 = _v.context.$implicit; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).checked; var currVal_1 = i0.ɵnov(_v, 3).disabled; var currVal_2 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_3 = i0.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_8 = _v.context.$implicit.toUpperCase(); _ck(_v, 4, 0, currVal_8); }); }
function View_LoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "h3", [["class", "primary-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "app-translate", [["translationName", "LoginComponent.language_t"]], null, null, null, i6.View_TranslateComponent_0, i6.RenderType_TranslateComponent)), i0.ɵdid(3, 245760, null, 0, i7.TranslateComponent, [i8.CustomTranslateService, i9.NavigationService, i10.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 5, "mat-radio-group", [["class", "mat-radio-group"], ["role", "radiogroup"]], null, null, null, null, null)), i0.ɵprd(5120, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MatRadioGroup]), i0.ɵdid(9, 1064960, null, 1, i2.MatRadioGroup, [i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { _radios: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i0.ɵdid(12, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "LoginComponent.language_t"; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.bigAl.getAvailableLanguageCodes(); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("LoginComponent.language_t")); _ck(_v, 5, 0, currVal_1); }); }
export function View_LoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 22, "div", [["class", "element-container element-container-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "sub-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "app-translate", [["translationName", "LoginComponent.title_t"]], null, null, null, i6.View_TranslateComponent_0, i6.RenderType_TranslateComponent)), i0.ɵdid(4, 245760, null, 0, i7.TranslateComponent, [i8.CustomTranslateService, i9.NavigationService, i10.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), i0.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 4, "app-form", [], null, null, null, i14.View_FormComponent_0, i14.RenderType_FormComponent)), i0.ɵprd(4608, null, i15.DateAdapter, i16.AppDateAdapter, [i17.AppSettingsStore]), i0.ɵprd(131584, null, i11.TranslatePipe, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(11, 4440064, null, 0, i18.FormComponent, [i11.TranslatePipe, i19.BigAlModule, i20.ComponentConfigService, i12.FormBuilder, i21.ValidatorFactory, i22.FileUploadService, i8.CustomTranslateService, i23.MapsAPILoader, i0.NgZone, i24.ToastService, i25.PhoneCountryCodeService], { formObject: [0, "formObject"], ruleObject: [1, "ruleObject"] }, null), i0.ɵprd(256, null, i15.MAT_DATE_FORMATS, i18.ɵ0, []), (_l()(), i0.ɵeld(13, 0, null, null, 5, "a", [["class", "link-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.forgotLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "app-translate", [["translationName", "LoginComponent.forgotLogin_t"]], null, null, null, i6.View_TranslateComponent_0, i6.RenderType_TranslateComponent)), i0.ɵdid(15, 245760, null, 0, i7.TranslateComponent, [i8.CustomTranslateService, i9.NavigationService, i10.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(16, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), i0.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i0.ɵdid(20, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 1, "app-api-error", [], null, null, null, i26.View_ApiErrorComponent_0, i26.RenderType_ApiErrorComponent)), i0.ɵdid(22, 245760, null, 0, i27.ApiErrorComponent, [i28.ApiService, i19.BigAlModule, i20.ComponentConfigService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "LoginComponent.title_t"; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.getFormObject(); var currVal_3 = _co.getRuleObject(); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = "LoginComponent.forgotLogin_t"; _ck(_v, 15, 0, currVal_4); var currVal_6 = !_co.bigAl.isInDesktopScreen; _ck(_v, 20, 0, currVal_6); _ck(_v, 22, 0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("LoginComponent.title_t")); _ck(_v, 6, 0, currVal_1); var currVal_5 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform("LoginComponent.forgotLogin_t")); _ck(_v, 17, 0, currVal_5); }); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login-component", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵdid(1, 245760, null, 0, i29.LoginComponent, [i9.NavigationService, i19.BigAlModule, i20.ComponentConfigService, i17.AppSettingsStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("login-component", i29.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
