import { OnInit, OnDestroy } from '@angular/core';
// Services
import { ModalService } from '../../../services/modals/modal.service';
import { NavigationService } from '../../../services/navigation.service';
import { CustomTranslateService } from '../../../translation/customTranslateService';
// Models
import { Translation } from '../../../models/translation.model';
var TranslateComponent = /** @class */ (function () {
    // #region Life cycle
    function TranslateComponent(translateService, navigationService, modalService) {
        this.translateService = translateService;
        this.navigationService = navigationService;
        this.modalService = modalService;
        this.updateTranslations = false;
        this.translations = [];
    }
    TranslateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.translateService.isEditing
            .subscribe(function (activateUpdateTranslations) {
            _this.updateTranslations = activateUpdateTranslations;
        });
    };
    TranslateComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    // #endregion
    TranslateComponent.prototype.loadTranslation = function () {
        var _this = this;
        this.translations = [];
        var langs = this.translateService.getLanguageCodes();
        langs.forEach(function (lang) {
            // Always show ALD translation
            var aldTranslation = _this.addTranslation(_this.translateService.aldTranslations, lang, _this.translationName, _this.translateService.aldCompanyId);
            _this.translations.push(aldTranslation);
            // Check if we have translations for NFF
            var nffTranslation = _this.addTranslation(_this.translateService.nffTranslations, lang, _this.translationName, _this.translateService.nffCompanyId);
            if (nffTranslation.TranslationValue && nffTranslation.TranslationValue !== aldTranslation.TranslationValue) {
                _this.translations.push(nffTranslation);
                _this.translateService.setHasNffTranslations(true);
            }
        });
        if (this.translations.length > 0) {
            this.translations.sort(function (t1, t2) { return t1.CompanyId - t2.CompanyId; });
            this.modalService.setData(this.translations);
            this.navigationService.openModal("update-translation");
        }
    };
    TranslateComponent.prototype.addTranslation = function (translationsList, languageCode, translationTag, companyId) {
        var translation;
        var translationParts = translationTag.split('.');
        var language = this.translateService.getLanguageText(languageCode);
        var translations = translationsList.filter(function (t) { return t.LanguageCode === languageCode; });
        if (translations.length > 0) {
            var translationJson = translations.pop().TranslationJson;
            if (translationJson.hasOwnProperty(translationParts[0]) && translationJson[translationParts[0]].hasOwnProperty(translationParts[1])) {
                var translationValue = translationJson[translationParts[0]][translationParts[1]];
                translation = new Translation(this.translationName, translationValue, languageCode, companyId, language);
            }
            else {
                translation = new Translation(this.translationName, this.translationName, languageCode, companyId, language);
            }
        }
        return translation;
    };
    return TranslateComponent;
}());
export { TranslateComponent };
