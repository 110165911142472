var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Platform } from "@angular/cdk/platform";
import { NativeDateAdapter } from "@angular/material";
import { AppSettingsStore } from "../stores/DataStores";
var AppDateAdapter = /** @class */ (function (_super) {
    __extends(AppDateAdapter, _super);
    function AppDateAdapter(settingsStore) {
        var _this = _super.call(this, settingsStore.getLocalId(), new Platform()) || this;
        _this.settingsStore = settingsStore;
        return _this;
    }
    AppDateAdapter.prototype.parse = function (value) {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            var str = value.split('/');
            var year = Number(str[2]);
            var month = Number(str[1]) - 1;
            var date = Number(str[0]);
            return new Date(year, month, date);
        }
        var timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    };
    AppDateAdapter.prototype.getFirstDayOfWeek = function () {
        return 1;
    };
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        var formatted;
        if (displayFormat === "input") {
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            switch (this.settingsStore.appSettings.CountryCode) {
                case 'dk': {
                    formatted = this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
                    break;
                }
                case 'se': {
                    formatted = year + '/' + this._to2digit(month) + '/' + this._to2digit(day);
                    break;
                }
                case 'no': {
                    formatted = year + '/' + this._to2digit(month) + '/' + this._to2digit(day);
                    break;
                }
            }
        }
        else {
            formatted = date.toDateString();
        }
        return formatted;
    };
    AppDateAdapter.prototype._to2digit = function (n) {
        return ('00' + n).slice(-2);
    };
    AppDateAdapter.prototype.getDayOfWeekNames = function (style) {
        var i;
        switch (this.settingsStore.appSettings.LanguageCode) {
            case 'da': {
                i = 0;
                break;
            }
            case 'sv': {
                i = 1;
                break;
            }
            case 'nb': {
                i = 2;
                break;
            }
            case 'en': {
                i = 3;
                break;
            }
        }
        return APP_DAY_OF_WEEK_NAMES[i][style];
    };
    AppDateAdapter.prototype.getMonthNames = function (style, customLang) {
        if (customLang === void 0) { customLang = null; }
        if (customLang !== null) {
            return APP_MONTH_NAMES[customLang][style];
        }
        return APP_MONTH_NAMES[this.settingsStore.appSettings.LanguageCode][style];
    };
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: 'input',
        dateA11yLabel: 'input',
        monthYearA11yLabel: 'input',
    }
};
/** The default day of the week names to use if Intl API is not available. */
export var APP_DAY_OF_WEEK_NAMES = [
    {
        'long': ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        'short': ['Søn', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lør'],
        'narrow': ['S', 'M', 'T', 'O', 'T', 'F', 'L']
    },
    {
        'long': ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
        'short': ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
        'narrow': ['S', 'M', 'T', 'O', 'T', 'F', 'L']
    },
    {
        'long': ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        'short': ['Søn', 'Man', 'Tis', 'Ons', 'Tor', 'Fre', 'Lør'],
        'narrow': ['S', 'M', 'T', 'O', 'T', 'F', 'L']
    },
    {
        'long': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'narrow': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    }
];
export var APP_MONTH_NAMES = {
    'en': {
        'long': [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'
        ],
        'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    },
    'da': {
        'long': [
            'Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September',
            'Oktober', 'November', 'December'
        ],
        'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    },
    'sv': {
        'long': [
            'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September',
            'Oktober', 'November', 'December'
        ],
        'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    },
    'nb': {
        'long': [
            'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
            'Oktober', 'November', 'Desember'
        ],
        'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
        'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    }
};
