<div *ngIf="formModel && formModel.form && formModel.stepperForm">
  <!-- STEPPER FORM-------------------------------------------------------------------------------------------------------------------------------------------------------->
  <div>
    <form [formGroup]="formModel.stepperForm" class="{{(formObject.Steps.length > 1) ? '' : 'single_step'}}">
      <mat-horizontal-stepper [@.disabled]="true" formArrayName="formArray" [linear]="isLinear" #stepper>
        <div *ngFor="let step of formObject.Steps;let i = index">
          <mat-step formGroupName="{{i.toString()}}" [stepControl]="formModel.formArray.get([i])">
            <ng-template matStepLabel>

            </ng-template>
            <h2 *ngIf="formModel.translationExists('Step_' + i + '_t')" color="primary">
              <app-translate translationName="{{formObject.Name + '.Step_' + i + '_t'}}">
                <span translate-text>{{formObject.Name + '.Step_' + i + '_t' | translate}}</span>
              </app-translate>
            </h2>
            <br />
            <div *ngFor="let prop of formObject.Steps[i].Properties" class="form-group">
              <div *ngIf="!prop.Hidden">
                <div [ngSwitch]="prop.Control">

                  <div *ngSwitchCase="'Input'">

                    <div *ngIf="prop.EnableIsContentCheck == null || prop.EnableIsContentCheck == undefined ? true : isContent(prop.Name)">
                      <!-- START-->
                      <mat-form-field *ngIf="prop.Prefix" class="field_left_small">
                        <mat-select matInput [formControlName]="prop.Name + '_prefix'">
                          <mat-placeholder style="white-space: normal;">
                            {{ formModel.getPlaceholder(prop.Name + '_prefix') | translate}}</mat-placeholder>
                          <mat-option *ngFor="let option of formModel.getPrefixOptions(prop)" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- END-->

                      <mat-form-field [ngClass]="{'field_right_large': prop.Prefix}">
                        <input matInput type="prop.Type" [formControlName]="prop.Name" [readonly]="prop.Readonly"
                          (blur)="trimValue(prop.Name, i)">
                        <mat-placeholder style="white-space: normal;">
                          {{ formModel.getPlaceholder(prop.Name)| translate}}</mat-placeholder>
                        <!-- <input id="searchInput" matInput type="text" class="form-control" #searchLocationInput [formControl]="locationInput"
    placeholder="{{ 'DKWorkshopSearchOptionsComponent.input_location_l' | translate }}"> -->

                        <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                        </app-field-placeholder-translate>
                        <mat-hint align="end">
                          <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                          </app-field-hint>
                        </mat-hint>
                        <mat-error *ngFor="let rule of prop.Rules">
                          <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                            [formArrayIndex]="i"></app-field-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngSwitchCase="'InputGoogle'">
                    <mat-form-field>

                      <input matInput #searchLocationInput type="prop.Type" [formControlName]="prop.Name" [name]="prop.Name"
                        [readonly]="prop.Readonly" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                      <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                      </app-field-placeholder-translate>

                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>

                      <mat-error *ngFor="let rule of prop.Rules">
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i"></app-field-error>
                      </mat-error>
                      <p *ngIf="prop?.ShowWarningMessage" class="input-warning">
                        {{prop.WarningMessage}}
                      </p>

                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'Password'">
                    <mat-form-field>
                      <input matInput [formControlName]="prop.Name" [type]="formModel.hide ? 'password' : 'text'"
                        placeholder="{{formModel.getPlaceholder(prop.Name)}}">
                      <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                      </app-field-placeholder-translate>
                      <mat-icon matSuffix (click)="formModel.hide = !formModel.hide" [svgIcon]="formModel.hide ? 'visibility' : 'visibility_off'"></mat-icon>
                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>
                      <mat-error *ngFor="let rule of prop.Rules">
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i">
                        </app-field-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'Checkbox'">
                    <div *ngIf="prop.EnableIsContentCheck == null || prop.EnableIsContentCheck == undefined ? true : isContent(prop.Name)">
                      <mat-checkbox color="primary" [formControlName]="prop.Name">
                        <app-field-translation [formObject]="formObject" [prop]="prop" [rule]="rule"
                          [translationTagType]="getLabelTranslationTagType()">
                        </app-field-translation>
                      </mat-checkbox>
                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>
                      <mat-error *ngFor="let rule of prop.Rules">
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i">
                        </app-field-error>
                      </mat-error>
                    </div>
                  </div>

                  <div *ngSwitchCase="'SlideToggle'">
                    <mat-slide-toggle color="primary" [formControlName]="prop.Name">
                      <app-field-translation [formObject]="formObject" [prop]="prop" [rule]="rule" [translationTagType]="getLabelTranslationTagType()">
                      </app-field-translation>
                    </mat-slide-toggle>
                    <mat-hint align="end">
                      <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                      </app-field-hint>
                    </mat-hint>
                    <mat-error *ngFor="let rule of prop.Rules">
                      <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                        [formArrayIndex]="i">
                      </app-field-error>
                    </mat-error>
                  </div>

                  <div *ngSwitchCase="'Text'">
                    <div *ngIf="prop.EnableIsContentCheck == null || prop.EnableIsContentCheck == undefined ? true : isContent(prop.Name)">
                      <label *ngIf="isContent('parking_app') && !isApp">
                        <app-field-translation [formObject]="formObject" [prop]="prop" [rule]="rule"
                          [translationTagType]="getLabelTranslationTagType()">
                        </app-field-translation>
                      </label>
                    </div>
                  </div>

                  <div *ngSwitchCase="'Textarea'">
                    <mat-form-field>
                      <textarea matInput type="prop.Type" [formControlName]="prop.Name" placeholder="{{formModel.getPlaceholder(prop.Name) | translate}}"></textarea>
                      <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                      </app-field-placeholder-translate>
                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>
                      <mat-error *ngFor="let rule of prop.Rules">
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i">
                        </app-field-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'DatePicker'">
                    <mat-form-field>
                      <div (click)=picker.open()>
                        <div *ngIf="!prop.MinDate && !prop.MaxDate && prop.Translateable === false">
                          <input matInput readonly [formControlName]="prop.Name" [matDatepickerFilter]="formModel.dateFilter"
                            [matDatepicker]="picker" placeholder="{{prop.Label}}">
                        </div>
                        <div *ngIf="prop.MinDate && prop.MaxDate && prop.Translateable === false">
                          <input matInput readonly min="{{prop.MinDate |  date:'yyyy-MM-dd'}}" max="{{prop.MaxDate |  date:'yyyy-MM-dd'}}"
                            [formControlName]="prop.Name" [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker"
                            placeholder="{{prop.Label}}">
                        </div>
                        <div *ngIf="!prop.MinDate && prop.MaxDate && prop.Translateable === false">
                          <input matInput readonly max="{{prop.MaxDate |  date:'yyyy-MM-dd'}}" [formControlName]="prop.Name"
                            [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker" [value]="prop.value  | date:'yyyy-MM-dd'"
                            placeholder="{{prop.Label}}">
                        </div>
                        <div *ngIf="prop.MinDate && !prop.MaxDate && prop.Translateable === false">
                          <input matInput readonly min="{{prop.MinDate |  date:'yyyy-MM-dd'}}" [formControlName]="prop.Name"
                            [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker" placeholder="{{prop.Label}}">
                        </div>


                        <div *ngIf="!prop.MinDate && !prop.MaxDate && prop.Translateable !== false">
                          <input matInput readonly [formControlName]="prop.Name" [matDatepickerFilter]="formModel.dateFilter"
                            [matDatepicker]="picker" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                        </div>
                        <div *ngIf="prop.MinDate && prop.MaxDate && prop.Translateable !== false">
                          <input matInput readonly min="{{prop.MinDate |  date:'yyyy-MM-dd'}}" max="{{prop.MaxDate |  date:'yyyy-MM-dd'}}"
                            [formControlName]="prop.Name" [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker"
                            placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                        </div>
                        <div *ngIf="!prop.MinDate && prop.MaxDate && prop.Translateable !== false">
                          <input matInput readonly max="{{prop.MaxDate |  date:'yyyy-MM-dd'}}" [formControlName]="prop.Name"
                            [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker" [value]="prop.value  | date:'yyyy-MM-dd'"
                            placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                        </div>
                        <div *ngIf="prop.MinDate && !prop.MaxDate && prop.Translateable !== false">
                          <input matInput readonly min="{{prop.MinDate |  date:'yyyy-MM-dd'}}" [formControlName]="prop.Name"
                            [matDatepickerFilter]="formModel.dateFilter" [matDatepicker]="picker" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                        </div>
                      </div>
                      <div *ngIf="isEditing">
                        <app-translate translationName="{{formObject.Name + '.' + prop.Name + '_p'}}">
                          <span translate-text>{{ formModel.getPlaceholder(prop.Name)| translate}}</span>
                        </app-translate>
                      </div>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>
                      <mat-error *ngFor="let rule of prop.Rules">
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i">
                        </app-field-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'RadioButton'">
                    <label>
                      <app-field-translation [formObject]="formObject" [prop]="prop" [rule]="rule" [translationTagType]="getLabelTranslationTagType()">
                      </app-field-translation>
                    </label>
                    <mat-radio-group matInput [formControlName]="prop.Name">
                      <div *ngFor=" let option of formModel.options.get(prop.Name);let i=index">
                        <mat-radio-button [value]="option[0]" color="primary" [checked]="((option[2] === true || option[2] === 'true') && (!prop.Value || prop.Value === option[0]))">
                          <app-translate translationName="{{option[1]}}">
                            <span translate-text>{{option[1] | translate}}</span>
                          </app-translate>
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                    <div *ngFor="let rule of prop.Rules">
                      <mat-error *ngIf='(formModel.nextTouched[i] || formModel.submitTouched)'>
                        <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                          [formArrayIndex]="i">
                        </app-field-error>
                      </mat-error>
                    </div>
                  </div>

                  <div *ngSwitchCase="'ButtonToggle'">
                    <mat-button-toggle-group [formControlName]="prop.Name" aria-label="prop.Name">
                      <mat-button-toggle *ngFor="let option of formModel.options.get(prop.Name)" [value]="option[0]"
                        [checked]="((option[2] === true || option[2] === 'true') && (!prop.Value || prop.Value === option[0]))">
                        <app-translate translationName="{{option[1]}}">
                          <span translate-text>{{option[1] | translate}} </span>
                        </app-translate>
                      </mat-button-toggle>
                    </mat-button-toggle-group>

                  </div>

                  <div *ngSwitchCase="'Select'">
                    <mat-form-field>
                      <mat-select matInput [formControlName]="prop.Name" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                        <mat-option *ngFor="let option of formModel.options.get(prop.Name)" [value]="option[0]">
                          <app-translate translationName="{{option[1]}}">
                            <span translate-text>{{option[1] | translate}}</span>
                          </app-translate>
                        </mat-option>
                      </mat-select>
                      <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                      </app-field-placeholder-translate>
                      <mat-hint align="end">
                        <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                        </app-field-hint>
                      </mat-hint>
                      <mat-error *ngIf='(formModel.nextTouched[i] || formModel.submitTouched) && !formModel.formArray.value[i][prop.Name]'>
                        <div *ngFor="let rule of prop.Rules">
                          <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                            [formArrayIndex]="i">
                          </app-field-error>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'DependentSelect'">
                    <div *ngIf="prop.First">
                      <mat-form-field>
                        <mat-select matInput [formControlName]="prop.Name" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}"
                          (selectionChange)="formModel.onSelect($event, prop.Seccond)">
                          <mat-option *ngFor=" let option of formModel.options.get(prop.Name)" [value]="option[0]">
                            <app-translate translationName="{{option[1]}}">
                              <span translate-text>{{option[1] | translate}}</span>
                            </app-translate>
                          </mat-option>
                        </mat-select>
                        <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                        </app-field-placeholder-translate>
                        <mat-hint align="end">
                          <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                          </app-field-hint>
                        </mat-hint>
                        <div *ngFor="let rule of prop.Rules">
                          <mat-error *ngIf='formModel.nextTouched[i] && !formModel.formArray.value[i][prop.Name]'>
                            <app-translate translationName="{{formObject.Name + '.' + prop.Name + '_' + rule.Type + '_e'}}">
                              <span translate-text>
                                {{(formObject.Name + "." + prop.Name + "_" + rule.Type + "_e") | translate}}
                              </span>
                            </app-translate>
                          </mat-error>
                        </div>
                      </mat-form-field>
                    </div>
                    <div *ngIf="!prop.First">
                      <mat-form-field>
                        <mat-select matInput [formControlName]="prop.Name" placeholder="{{ formModel.getPlaceholder(prop.Name)| translate}}">
                          <mat-option *ngFor="let option of prop.Options" [value]="option[0]">
                            <app-translate translationName="{{option[1]}}">
                              <span translate-text>{{option[1] | translate}}</span>
                            </app-translate>
                          </mat-option>
                        </mat-select>
                        <app-field-placeholder-translate [formObject]="formObject" [prop]="prop">
                        </app-field-placeholder-translate>
                        <mat-hint align="end">
                          <app-field-hint [formObject]="formObject" [formModel]="formModel" [prop]="prop">
                          </app-field-hint>
                        </mat-hint>
                        <div *ngFor="let rule of prop.Rules">
                          <app-field-error [formObject]="formObject" [formModel]="formModel" [prop]="prop" [rule]="rule"
                            [formArrayIndex]="i">
                          </app-field-error>
                        </div>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngSwitchCase="'File'">
                    <div class="fileinputs">
                      <form #fileinputs id="fileupload" *ngIf="(prop.Type !== 'glovebox')">
                        <button type=" button" mat-raised-button (click)="fileInput.click()">
                          <mat-icon class="icon-btn" svgIcon="photo" id="fileupload"></mat-icon>
                          <app-translate translationName="FormComponent.Browse_t">
                            <span translate-text>{{'FormComponent.Browse_t' | translate}}</span>
                          </app-translate>
                        </button>
                        {{selectedFile}}
                        <input hidden type="file" #fileInput ng2FileSelect (change)="formModel.uploadFile($event)" />
                      </form>
                      <form #gloveboxFiles id="gloveboxFileUpload" *ngIf="(prop.Type === 'glovebox')">
                        <button type=" button" mat-raised-button (click)="gloveboxFile.click()">
                          <mat-icon class="icon-btn" svgIcon="photo" id="gloveboxFileUpload"></mat-icon>
                          <app-translate translationName="FormComponent.Browse_t">
                            <span translate-text>{{'FormComponent.Browse_t' | translate}}</span>
                          </app-translate>
                        </button>
                        {{selectedFile}}
                        <input hidden type="file" #gloveboxFile ng2FileSelect (change)="formModel.uploadGloveboxFile($event)" />
                      </form>
                      <br>
                      <div *ngIf="fileUploadError">
                        <p class="warning-text">
                          <app-translate translationName="{{'FileUploadService.' + fileErrorType + '_e'}}">
                            <span translate-text>{{'FileUploadService.' + fileErrorType + '_e' | translate}}</span>
                          </app-translate>
                        </p>
                      </div>
                      <h2 *ngIf="fileNames.length > 0">
                        <app-translate translationName="{{ formObject.Name + '.Uploaded_files_t' }}">
                          <span translate-text>{{ formObject.Name + '.Uploaded_files_t' | translate}}</span>
                        </app-translate>
                      </h2>
                      <ul *ngIf="(prop.Type !== 'glovebox')">
                        <li *ngFor="let fileName of fileNames">
                          {{fileName}}
                          <mat-icon class="green-text" svgIcon="check"></mat-icon>
                        </li>
                      </ul>
                      <ul *ngIf="(prop.Type === 'glovebox') && fileNames.length > 0">
                        <li>
                          {{fileNames[fileNames.length - 1]}}
                          <mat-icon class="green-text" svgIcon="check"></mat-icon>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div *ngSwitchCase="'Description'">
                    <div [ngSwitch]="prop.Tag">
                      <div *ngSwitchCase="'h1'">
                        <h1 (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </h1>
                      </div>
                      <div *ngSwitchCase="'h2'">
                        <h2 (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </h2>
                      </div>
                      <div *ngSwitchCase="'h3'">
                        <h3 (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </h3>
                      </div>
                      <div *ngSwitchCase="'h4'">
                        <h4 (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </h4>
                      </div>
                      <div *ngSwitchCase="'h5'">
                        <h5 (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </h5>
                      </div>
                      <div *ngSwitchCase="'p'">
                        <p (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </p>
                      </div>
                      <div *ngSwitchCase="'div'">
                        <div (class)="prop.Class">
                          <app-field-translation *ngIf="prop.HasLabel" [formObject]="formObject" [prop]="prop" [rule]="rule"
                            [translationTagType]="getLabelTranslationTagType()">
                          </app-field-translation>
                          <br *ngIf="prop.EndlineLabel" /> {{ prop.Value }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'MapGoogle'">

                    <!-- Map rendered with polylines -->
                    <agm-map *ngIf="prop.Value && prop.TripPositions && prop.TripPositions.length > 0" #AgmMap
                      [streetViewControl]="false" [fitBounds]="true" [latitude]="prop.TripPositions[0].Positions[0].Latitude"
                      [longitude]="prop.TripPositions[0].Positions[0].Longitude">

                      <!-- Route -->
                      <div *ngFor="let tripPosition of prop.TripPositions">

                        <agm-polyline [strokeColor]="'#2196f3'" *ngIf="!tripPosition?.IsLinking">
                          <ng-container *ngFor="let position of tripPosition.Positions">
                            <agm-marker [latitude]="position.Latitude" [longitude]="position.Longitude" [visible]="false"
                              [agmFitBounds]="true"></agm-marker>
                            <agm-polyline-point [latitude]="position.Latitude" [longitude]="position.Longitude">
                            </agm-polyline-point>
                          </ng-container>
                        </agm-polyline>

                        <agm-direction *ngIf="tripPosition?.IsLinking" [origin]="tripPosition.OriginPosition"
                          [destination]="tripPosition.DestinationPosition" [renderOptions]="{suppressMarkers: true}">
                        </agm-direction>

                      </div>

                      <!-- Toll Stations -->
                      <div *ngFor="let station of tollStations">
                        <agm-marker [latitude]="station.Latitude" [longitude]="station.Longitude" [iconUrl]="tollStationMarkerIcon"></agm-marker>
                      </div>

                    </agm-map>

                    <!-- Map point A -> B -->
                    <agm-map *ngIf="prop.Value && prop.Value.Lat && prop.Value.Lng && prop.TripPositions.length === 0"
                      #AgmMap [streetViewControl]="false" [latitude]="prop.Value.Lat" [longitude]="prop.Value.Lng">

                      <agm-direction *ngIf="prop.Value.Directions && prop.Value.Directions.OriginPosition && prop.Value.Directions.DestinationPosition"
                        [origin]="prop.Value.Directions.OriginPosition" [destination]="prop.Value.Directions.DestinationPosition">
                      </agm-direction>
                    </agm-map>

                  </div>

                </div>
              </div>
            </div>
            <div fxFlex>

              <button *ngIf="i>0" mat-raised-button color="primary" mat-button (click)="goBack(stepper)">
                <app-translate translationName="FormComponent.Back_t">
                  <span translate-text>{{'FormComponent.Back_t'|translate}}</span>
                </app-translate>
              </button>
              <button *ngIf="i<formObject.Steps.length - 1" mat-raised-button color="primary" mat-button (click)="goForward(stepper, i)"
                type="button">
                <app-translate translationName="FormComponent.Next_t">
                  <span translate-text>{{'FormComponent.Next_t'|translate}}</span>
                </app-translate>
              </button>

              <button id="submit" *ngIf="i===formObject.Steps.length - 1" mat-raised-button color="primary" type="submit"
                (click)="submit(formModel.stepperForm)">
                <app-translate translationName="{{formObject.Name + '.Submit_t'}}">
                  <span translate-text>{{formObject.Name + '.Submit_t'| translate}}</span>
                </app-translate>
              </button>
            </div>
          </mat-step>
        </div>
      </mat-horizontal-stepper>
    </form>
  </div>
</div>