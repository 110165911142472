var CardOrder = /** @class */ (function () {
    function CardOrder(VehicleRegistrationNumber, Name, Address, ZipCode, City, Colour, Email) {
        this.VehicleRegistrationNumber = VehicleRegistrationNumber;
        this.Name = Name;
        this.Address = Address;
        this.ZipCode = ZipCode;
        this.City = City;
        this.Colour = Colour;
        this.Email = Email;
    }
    return CardOrder;
}());
export { CardOrder };
export var CardColour;
(function (CardColour) {
    CardColour[CardColour["Green"] = 0] = "Green";
    CardColour[CardColour["Red"] = 1] = "Red";
})(CardColour || (CardColour = {}));
