import { BehaviorSubject } from "rxjs";
import { ComponentConfigService } from "../componentConfig.service";
var DjTabsService = /** @class */ (function () {
    function DjTabsService(theBen) {
        this.theBen = theBen;
        this.data = new BehaviorSubject('');
        this.journalOptions = [
            {
                path: 'driver-journal-monthly',
                label: 't_journal_month',
                features: ['driverjournal'],
                contents: ['driver-journal-monthly'],
                priority: 0,
                width: '50'
            },
            {
                path: 'driver-journal-daily',
                label: 't_journal_day',
                features: ['driverjournal'],
                contents: ['driver-journal-daily'],
                priority: 1,
                width: '50'
            },
            {
                path: 'congestion-tax-daily',
                label: 't_ctax_day',
                features: ['ctax'],
                contents: ['congestion-tax-daily'],
                priority: 2,
                width: '100'
            },
            {
                path: 'congestion-tax-monthly',
                label: 't_ctax_month',
                features: ['ctax'],
                contents: ['congestion-tax-monthly'],
                priority: 3,
                width: '100'
            }
        ];
        this.setInitTab();
    }
    DjTabsService.prototype.getData = function () {
        return this.data.asObservable();
    };
    DjTabsService.prototype.setData = function (data) {
        this.data.next(data);
    };
    DjTabsService.prototype.shouldShowContent = function (contents) {
        var _this = this;
        var resp = true;
        contents.forEach(function (c) {
            if (!_this.theBen.isContent('JournalComponent', c)) {
                resp = false;
            }
        });
        return resp;
    };
    DjTabsService.prototype.setInitTab = function () {
        var _this = this;
        var path = null;
        this.journalOptions.sort(function (o) { return o.priority; }).every(function (o) {
            if (_this.shouldShowContent(o.contents) && _this.shouldShowFeatures(o.features)) {
                path = 'journal/' + o.path;
                return false;
            }
            return true;
        });
        if (path === null) {
            this.data.next('journal/');
        }
        else {
            this.data.next(path);
        }
    };
    DjTabsService.prototype.shouldShowFeatures = function (features) {
        var _this = this;
        var resp = true;
        features.forEach(function (f) {
            if (!_this.theBen.isFeature(f)) {
                resp = false;
            }
        });
        return resp;
    };
    return DjTabsService;
}());
export { DjTabsService };
