import { Title } from '@angular/platform-browser';
import { OnInit, OnDestroy, Component, HostBinding, Renderer2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import {Constants} from '../../helpers/constants'
// Third-party
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

// Common
import { BigAlModule } from '../../modules/bigal.module';
import { TrackingHelper } from '../../helpers/trackingHelper';

// Interfaces
import { IScript } from '../../sharedtypes/interfaces/sharedtypes.interface';

// Services
import { FleetUserService } from '../../services/fleet-user.service';
import { CustomTranslateService } from '../../translation/customTranslateService';
import { ComponentConfigService } from './../../services/componentConfig.service';
import { ScreenService } from '../../services/screen.service';
import { NavigationService } from '../../services/navigation.service';
import { ResourceInjectorService } from '../../services/resourceInjector.service';
import { RouteStateService } from '../../services/routeState.service';
import { UserRoleType } from '../../models/enums/user-role-type.enum';
import { FirebaseService } from '../../services/firebase.service';
import { AppUserStore } from '../../stores/DataStores';
import { ContractStore, ParkingStore } from '../../stores/DataStores';
import { IAdminInfo } from '../../sharedtypes/interfaces/generic/admininfo.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminInfoService } from '../../services/admin-info.service';

declare var dataLayer: Array<any>;

@Component({
  selector: "app-root",
  templateUrl: "./init.component.html"
  // styleUrls: ["./init.component.css"]
})
export class InitComponent implements OnInit, OnDestroy {

  public static scope: any;
  private subscriptions: Subscription = new Subscription();
  private showTakeOverBar = false;
  private isEditing = false;
  private readonly updateTranslationsText = "Update translations";
  private readonly translationsListText = "Translations List";
  private readonly editTranslationsText = "Done editing";

  public test: string = 'ASDF';
  public trackId:string='';
  showFirstComponent = new BehaviorSubject<boolean>(false);
  isProduction = false;
  databaseType: string = null;
  frontendVersion: string = null;
  backendVersion: string = null;
  updateTranslationsButtonText: string = this.updateTranslationsText;
  translationsListButtonText: string = this.translationsListText;

  private trackingService: TrackingHelper;

  private contractId: string;
  public isApplicationVersionchanged: boolean = false;
  public latestVersion: string;
  private timeInterval: Subscription;
  private showReleaseAlertCloseButton: boolean = false;
  constructor(
    private firebaseService: FirebaseService,
    private titleService: Title,
    public bigAl: BigAlModule,
    private theBen: ComponentConfigService,
    private fleetUserService: FleetUserService,
    public translateService: CustomTranslateService,
    public screenService: ScreenService,
    private navigationService: NavigationService,
    private router: Router,
    private resourceInjector: ResourceInjectorService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public overlayContainer: OverlayContainer,
    private routeStateService: RouteStateService,
    private appUserStore: AppUserStore,
    private contractStore: ContractStore,
    private parkingStore: ParkingStore,
    private renderer:Renderer2,
    private adminInfoService: AdminInfoService) {
     angulartics2GoogleTagManager.startTracking();  

    this.trackingService = new TrackingHelper(appUserStore);

    this.setupStoreListeners();
  }

  private setupStoreListeners() {
    this.parkingStore.onPendingParkingStore$.subscribe(response => {
      if (response.isInitial) {
        return;
      }

      if (response.isSuccess) {
        if (response.model != null) {
          this.router.navigate(['/parking/start-parking']);
        }
      }
    });

    this.contractStore.onGetContracts$.subscribe(response => {
      if (response.isInitial) {
        return;
      }

      if (response.isSuccess) {
        this.contractId = response.model.vehicleContract.Id;
        this.checkAndCallPendingParking();
      }
    });
  }

  ngOnInit(): void {

    this.firebaseService.wireupFirebaseNotification();
    InitComponent.scope = this;
    document.addEventListener("deviceready", this.onDeviceReady, false);

    this.watchData();
    this.getData();
    this.routeStateService.loadRouting();
    this.listenToRouteChanges();

    // this.contractStore.getContracts();

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
  }

  private onDeviceReady() {
    document.addEventListener("resume", () => {
      InitComponent.scope.checkAndCallPendingParking();
    }, false);
  }

  canUseAdminFunctions(): boolean {
    return this.appUserStore.appUser.Roles.some(role => {
      if (role === UserRoleType.DevUser_Admin || role === UserRoleType.DevUser_Driver || role === UserRoleType.DevUser_Business) {
        return true;
      }
    });
  }

  checkAndCallPendingParking() {
    if (this.theBen.isContent(this, 'parking_app') && this.bigAl.appSettings && this.bigAl.appSettings.IsApp && this.appUserStore.appUser && this.appUserStore.appUser.Settings && this.appUserStore.appUser.Settings.ParkingApp) {
      this.parkingStore.getPendingParking(this.contractId);
    }
  }

  private listenToRouteChanges(): void {
    this.subscriptions.add(this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.trackingService.trackPage(val.url, val.url);
      }
    }));
  }

  private watchData() {

    this.subscriptions.add(this.bigAl.getDataStream().subscribe(lastEvent => {
      if (lastEvent === "init") {
        this.hideCarSpinner(this.showFirstComponent);
        switch (this.bigAl.appSettings.CompanyId) {
          case 1:
            this.setTitle("My ALD 2.1");
            this.setFavicon('assets/ald/icons/ald/favicon.ico');
            this.onSetTheme('ald-theme');
            break;
          case 2:
            this.setTitle("My NF FLEET 2.1");
            this.setFavicon('assets/ald/icons/nf-fleet/favicon.png');
            this.onSetTheme('nf-theme');
            break;
          default:
            this.setTitle("My ALD 2.1");
            this.onSetTheme('ald-theme');
        }

        if (!this.bigAl.appSettings.ApiBaseUrl.includes('uat') && !this.bigAl.appSettings.ApiBaseUrl.includes('test') && !this.bigAl.appSettings.ApiBaseUrl.includes('localhost') && !this.bigAl.appSettings.ApiBaseUrl.includes('dev')) {
          this.isProduction = true;
        } else {
          if (this.bigAl.appSettings.DatabaseType === 0) {
            this.databaseType = 'Fleet';
          } else if (this.bigAl.appSettings.DatabaseType === 1) {
            this.databaseType = 'Miles';
          }
          this.frontendVersion = this.bigAl.defaultSettings.FrontendVersion;
          this.backendVersion = this.bigAl.defaultSettings.BackendVersion;
        }
        if (!window['device']) {
          this.timeInterval = interval(900000).subscribe((timerCount => { //added 15 mins as time interval
            this.checkVersion();
          }));
        }
      }
    }));

    this.subscriptions.add(this.fleetUserService.isInTakeOverMode.subscribe(isInTakeOverMode => {
      this.showTakeOverBar = isInTakeOverMode;
    }));

    this.subscriptions.add(this.translateService.isEditing.subscribe(isEditing => {
      this.isEditing = isEditing;

      if (this.isEditing) {
        this.updateTranslationsButtonText = this.editTranslationsText;
      } else {
        this.updateTranslationsButtonText = this.updateTranslationsText;
      }
    }));

    this.subscriptions.add(this.bigAl.appDidInit$.subscribe(didLoad => {
      if (didLoad) {
        this.injectResources();
        
      }
    }));
  }
  

  private getData() {
    if (!this.bigAl.appSettings) {
      this.bigAl.init();
    } else {
      this.hideCarSpinner(this.showFirstComponent);
    }
  }

  private injectResources(): void {

    const whoAmI = this.bigAl.appSettings;

    // Inject GioSG script if the identity is Sweden
    if (whoAmI && !whoAmI.IsApp && whoAmI.CountryCode === "se") {
      const script: IScript = {
        src: "assets/scripts/giosg.js",
        identifier: "giosg"
      };

      this.resourceInjector.injectScript(script);
    }
  }

  /** when using setTimeout always pass parameters to function otherwise vars become undefined  */
  hideCarSpinner(showFirstComponent) {
    setTimeout(function () {
      showFirstComponent.next(true);
    }, 1000);
  }

  translationUpdates(): void {
    if (this.router.url.includes('(modal:')) {

      const posModal = this.router.url.indexOf('(modal:');
      const modalString = this.router.url.substring(posModal);
      let routeModalName = modalString.substring(7);
      routeModalName = routeModalName.substring(0, routeModalName.length - 1);

      this.bigAl.previousModal = routeModalName;
    } else {
      this.bigAl.previousModal = null;
    }

    this.translateService.setIsEditing(!this.isEditing);
  }

  action(location: string, id?: string[]) {
    (id) ? this.navigationService.openModal([location, id]) : this.navigationService.openModal(location);
  }

  // tslint:disable-next-line:member-ordering
  @HostBinding('class') componentCssClass;
  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  changeFavicon(img) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.setAttribute('type', 'image/png');
    link.setAttribute('rel', 'shortcut icon');
    link.setAttribute('href', img);
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  setFavicon(url) {
    this.removeFavicon();

    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  removeFavicon() {

    const links = document.getElementsByTagName('link');
    const head = document.getElementsByTagName('head')[0];
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute('rel') === 'icon') {
        head.removeChild(links[i]);
      }
    }
  }

  isContent(name: string) {
    return this.theBen.isContent(this, name);
  }

  private checkVersion() {
    this.adminInfoService.getApplicationVersion()
      .then((adminInfo: IAdminInfo) => {
        const version = localStorage.getItem("version");
        if (version !== adminInfo.version) {
          this.latestVersion = adminInfo.version;
          this.isApplicationVersionchanged = true;
        }
      })
      .catch((err: HttpErrorResponse) => {

      });
  }


  async updateVersionAndReload() {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }

    localStorage.setItem("version", this.latestVersion);
    location.reload();
  }
}

