import { registerLocaleData } from '@angular/common';
import localeDk from '@angular/common/locales/da';
import localeSe from '@angular/common/locales/se';
import localeNo from '@angular/common/locales/nb';
// Stores
import { AppSettingsStore } from './stores/DataStores';
registerLocaleData(localeDk, 'da');
registerLocaleData(localeSe, 'sv');
registerLocaleData(localeNo, 'nb');
export function initializeAppSettings(settingStore) {
    return function () { return settingStore.init(); };
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
