<app-modal size="small" [closeButton]="closeButton">
    <div modal-body>
        <h2 class="element-title">
            <div class="primary-text">
                <h1>
                    <app-translate translationName="ChangePasswordForm.headline_t">
                        <span translate-text>
                            {{ 'ChangePasswordForm.headline_t' | translate }}
                        </span>
                    </app-translate>
                </h1>
            </div>
            <div>
                <app-translate translationName="ChangePasswordForm.Message_t">
                    <span translate-text>{{ 'ChangePasswordForm.Message_t' | translate }}</span>
                </app-translate>
            </div>
            <br>
            <div class="primary-text">
                <app-translate translationName="ChangePasswordForm.RulesHeader_t">
                    <span translate-text>{{ 'ChangePasswordForm.RulesHeader_t' | translate }}</span>
                </app-translate>
            </div>
            <div>
                <ul class="list numbered-list" fxLayout="column">
                    <div>
                        <h3 fxFlex="40px" class="primary-text">1.</h3>
                        <div class="item-text">
                            <p>
                                <app-translate translationName="ChangePasswordForm.Rules1_t">
                                    <span translate-text>{{ 'ChangePasswordForm.Rules1_t' | translate}}</span>
                                </app-translate>
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3 fxFlex="40px" class="primary-text">2.</h3>
                        <div class="item-text">
                            <p>
                                <app-translate translationName="ChangePasswordForm.Rules2_t">
                                    <span translate-text>{{ 'ChangePasswordForm.Rules2_t' | translate}}</span>
                                </app-translate>
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3 fxFlex="40px" class="primary-text">3.</h3>
                        <div>
                            <p>
                                <app-translate translationName="ChangePasswordForm.Rules3_t">
                                    <span translate-text>{{ 'ChangePasswordForm.Rules3_t' | translate}}</span>
                                </app-translate>
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3 fxFlex="40px" class="primary-text">4.</h3>
                        <div class="item-text">
                            <p>
                                <app-translate translationName="ChangePasswordForm.Rules4_t">
                                    <span translate-text>{{ 'ChangePasswordForm.Rules4_t' | translate}}</span>
                                </app-translate>
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3 fxFlex="40px" class="primary-text">5.</h3>
                        <div>
                            <p>
                                <app-translate translationName="ChangePasswordForm.Rules5_t">
                                    <span translate-text>{{ 'ChangePasswordForm.Rules5_t' | translate}}</span>
                                </app-translate>
                            </p>
                        </div>
                    </div>
                </ul>
            </div>
            <div>
                <app-form [formObject]="getFormObject()" [ruleObject]=getRuleObject()></app-form>
            </div>
        </h2>
    </div>
</app-modal>