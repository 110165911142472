<app-fleet-take-over-bar *ngIf="showTakeOverBar"></app-fleet-take-over-bar>
<div class="element-container">
  <h1>
    <app-translate translationName="GloveboxComponent.header_t">
      <span translate-text>{{'GloveboxComponent.header_t' | translate}}</span>
    </app-translate>
  </h1>

  <p *ngIf="isApp === true">
    <app-translate translationName="GloveboxComponent.descriptionOnlyApp">
      <span translate-text>{{'GloveboxComponent.descriptionOnlyApp' | translate}}</span>
    </app-translate>
  </p>

  <div *ngIf="isApp === false">
    <div *ngIf="gloveboxInvoiceContainersOriginal.length > 5">
      <form [formGroup]="searchForm">
        <mat-form-field class="example-full-width">
          <mat-label>
            <app-translate translationName="GloveboxComponent.searchForAnything">
              <span translate-text>{{'GloveboxComponent.searchForAnything' | translate}}</span>
            </app-translate>
          </mat-label>
          <input matInput formControlName="searchField" autocomplete="off" />
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="search()">
          <app-translate translationName="GloveboxComponent.search">
            <span translate-text>{{'GloveboxComponent.search' | translate}}</span>
          </app-translate>
        </button>
      </form>
      <br />
    </div>

    <p>
      <app-translate *ngIf="isDoneLoading && gloveboxInvoiceContainers.length > 0"
        translationName="GloveboxComponent.description">
        <span translate-text>{{'GloveboxComponent.description' | translate}}</span>
      </app-translate>
    </p>

    <div *ngFor="let gloveboxInvoiceContainer of gloveboxInvoiceContainers | slice:offset:offset+limit"
      class="container-spacer">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div>{{gloveboxInvoiceContainer.BusinessPartnerName}} - {{gloveboxInvoiceContainer.BusinessPartnerId}}</div>
        </mat-expansion-panel-header>

        <div layout="row">
          <!-- Mobile design START -->
          <div *ngIf="!screenService.isDesktop">
            <div class="mobile-spacer container-spacer"
              *ngFor="let gloveboxInvoice of gloveboxInvoiceContainer.GloveboxInvoices | slice:0:gloveboxInvoiceContainer.invoicesToShow"
              (click)="downloadFile(gloveboxInvoice)">

              <div class="container-spacer">
                <label>
                  <app-translate translationName="GloveboxComponent.table_header_invoice_name">
                    <span translate-text>{{'GloveboxComponent.table_header_invoice_name' | translate}}</span>
                  </app-translate>
                </label>
                <div class="text cut-text">{{gloveboxInvoice.FileName}}</div>
              </div>

              <div class="container-spacer">
                <label>
                  <app-translate translationName="GloveboxComponent.table_header_create_date">
                    <span translate-text>{{'GloveboxComponent.table_header_create_date' | translate}}</span>
                  </app-translate>
                </label>
                <div class="text">{{getLocaleDate(gloveboxInvoice.CreateDate)}}</div>
              </div>

              <div class="container-spacer">
                <label>
                  <app-translate translationName="GloveboxComponent.table_header_download">
                    <span translate-text>{{'GloveboxComponent.table_header_download' | translate}}</span>
                  </app-translate>
                </label>
                <div class="text">
                  <mat-icon class="icon-btn" title="Download" color="primary" svgIcon="download"></mat-icon>
                </div>
              </div>

            </div>

            <div class="container-spacer"
              *ngIf="gloveboxInvoiceContainer.invoicesToShow < gloveboxInvoiceContainer.GloveboxInvoices.length">
              <button mat-raised-button color="primary" class="primary-text show-more"
                (click)="showMore(gloveboxInvoiceContainer)">
                <app-translate translationName="GloveboxComponent.show_more">
                  <span translate-text>{{'GloveboxComponent.show_more' | translate}}</span>
                </app-translate>
              </button>
            </div>

          </div>
          <!-- Mobile design END -->
          <!-- Desktop design START-->
          <div *ngIf="screenService.isDesktop">
            <table class="mat-elevation-z1">
              <thead>
                <tr>
                  <th>
                    <app-translate translationName="GloveboxComponent.table_header_invoice_name">
                      <span translate-text>{{'GloveboxComponent.table_header_invoice_name' | translate}}</span>
                    </app-translate>
                  </th>
                  <th>
                    <app-translate translationName="GloveboxComponent.table_header_create_date">
                      <span translate-text>{{'GloveboxComponent.table_header_create_date' | translate}}</span>
                    </app-translate>
                  </th>
                  <th>
                    <app-translate translationName="GloveboxComponent.table_header_download">
                      <span translate-text>{{'GloveboxComponent.table_header_download' | translate}}</span>
                    </app-translate>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let gloveboxInvoice of gloveboxInvoiceContainer.GloveboxInvoices | slice:0:gloveboxInvoiceContainer.invoicesToShow"
                  (click)="downloadFile(gloveboxInvoice)">
                  <td>{{gloveboxInvoice.FileName}}</td>
                  <td>{{getLocaleDate(gloveboxInvoice.CreateDate)}}</td>
                  <td>
                    <div class="float-right">
                      <mat-icon class="icon-btn" title="Download" color="primary" svgIcon="download"></mat-icon>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="gloveboxInvoiceContainer.invoicesToShow < gloveboxInvoiceContainer.GloveboxInvoices.length">
                  <td colspan="3" class="primary-text show-more" (click)="showMore(gloveboxInvoiceContainer)">

                    <app-translate translationName="GloveboxComponent.show_more">
                      <span translate-text>{{'GloveboxComponent.show_more' | translate}}</span>
                    </app-translate>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Desktop design END -->
        </div>
      </mat-expansion-panel>
    </div>

    <mat-paginator #paginator *ngIf="gloveboxInvoiceContainers.length > limit"
      [length]="gloveboxInvoiceContainers.length" [pageSize]="limit" (page)="changePage($event)">
    </mat-paginator>

    <p>
      <app-translate *ngIf="isDoneLoading && gloveboxInvoiceContainers.length === 0"
        translationName="GloveboxComponent.noDocuments">
        <span translate-text>{{'GloveboxComponent.noDocuments' | translate}}</span>
      </app-translate>
    </p>

    <app-spinner [isDoneLoading]="isDoneLoading" [didRequestFail]="didFail">
      <div error-message>
        <h3>
          <app-translate translationName="GloveboxComponent.dataUnavailableHeader_t">
            <span translate-text>{{'GloveboxComponent.dataUnavailableHeader_t' | translate}}</span>
          </app-translate>
        </h3>
        <app-translate translationName="GloveboxComponent.dataUnavailableDescription_t">
          <span translate-text>{{'GloveboxComponent.dataUnavailableDescription_t' | translate}}</span>
        </app-translate>
      </div>
    </app-spinner>
  </div>
</div>