import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppUserStore } from '../../stores/DataStores';
import { DriverJournalDailyLog } from '../../models/driverjournal/driverJournalDailyLog.model';
import { TripTypeEnum } from '../../models/enums/TripType.enum';
import { BigAlModule } from '../../modules/bigal.module';
import { ComponentConfigService } from '../componentConfig.service';

@Injectable()
export class DailyUpdateService {

    private dailyLog = new BehaviorSubject<DriverJournalDailyLog>(null);

    constructor(
        protected appUserStore: AppUserStore,
        protected bigAl: BigAlModule,
        protected theBen: ComponentConfigService,
    ) {
        this.resetDailyLog();
    }

    public getDailyLog(): Observable<DriverJournalDailyLog> {
        return this.dailyLog.asObservable();
    }

    public setDailyLog(log: DriverJournalDailyLog) {
        this.dailyLog.next(log);
    }

    public resetDailyLog() {
        let djSettings = this.appUserStore.appUser.UserSettings.ContentSettings.find(p => p.Content === 'driverjournal');
        // add the default settings from user settings
        let purpose = djSettings.Settings["TravelPurpose"];
        let type = (JSON.parse(djSettings.Settings['DefaultTripType'])) ? TripTypeEnum.BusinessTrip : TripTypeEnum.PrivateTrip;
        let regNo = (this.bigAl.currentVehicle && this.bigAl.currentVehicle.RegistrationNumber) ? this.bigAl.currentVehicle.RegistrationNumber : '';
        let id = (this.bigAl.currentVehicleContract && this.bigAl.currentVehicleContract.Id) ? this.bigAl.currentVehicleContract.Id : '';
        let descr = (this.bigAl.currentVehicle && this.bigAl.currentVehicle.ModelDescription) ? this.bigAl.currentVehicle.ModelDescription : '';

        let log = new DriverJournalDailyLog();
        log.ContractId = id;
        log.RegistrationNumber = regNo;
        log.Date = new Date().toLocaleDateString('sv');
        log.Purpose = purpose;
        log.CarDescription = descr;
        log.TripType = type;
        this.dailyLog.next(log);
    }
}
