/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./mask.component";
var styles_MaskComponent = [];
var RenderType_MaskComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MaskComponent, data: { "animation": [{ type: 7, name: "animMask", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "300ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_MaskComponent as RenderType_MaskComponent };
function View_MaskComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "mask"]], [[24, "@animMask", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMask; _ck(_v, 0, 0, currVal_0); }); }
export function View_MaskComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MaskComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMask; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MaskComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-mask", [], null, null, null, View_MaskComponent_0, RenderType_MaskComponent)), i0.ɵdid(1, 114688, null, 0, i2.MaskComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaskComponentNgFactory = i0.ɵccf("app-mask", i2.MaskComponent, View_MaskComponent_Host_0, {}, { onMaskClose: "onMaskClose" }, []);
export { MaskComponentNgFactory as MaskComponentNgFactory };
