import { BigAlModule } from './../../../modules/bigal.module';
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { ScreenService } from './../../../services/screen.service';
import { Component, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { AbstractFeature } from '../../../components/features/feature.abstract';

@Component({
    selector: "menu-business-user-component",
    templateUrl: "./menu-business-user.component.html"
})
export class MenuBusinessUserComponent extends AbstractFeature implements AfterViewChecked {

    private anchorMovedOnInit = false;
    @ViewChild("decorativeAnchor") private decorativeAnchor: ElementRef;

    constructor(
        public screenService: ScreenService,
        private element: ElementRef,
        public theBen: ComponentConfigService,
        public bigAl: BigAlModule
    ) {
        super(bigAl, theBen);
    }

    ngAfterViewChecked() {
        if (
            !this.anchorMovedOnInit &&
            this.element.nativeElement.querySelector(".active a")
        ) {
            this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
            this.anchorMovedOnInit = true;
        }
    }

    protected setEventsToWatch(): void {
    }
    protected setData(): void {
    }
    protected getData(): void {
    }
    public isDataAvailable(): boolean {
        return true;
    }

    // EventHandler
    public onClick($event) {
        this.moveAnchor($event.target);
    }

    private moveAnchor(target) {
        let itemTarget = this.findAncestorItem(target);
        this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
    }

    private findAncestorItem(el) {
        while ((el = el.parentNode) && !el.classList.contains("item")) { }
        return el;
    }

    public lnkLogout() {
        this.bigAl.logout();
    }
}
