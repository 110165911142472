var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { AbstractFeature } from '../../../feature.abstract';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { AppUserStore } from '../../../../../stores/DataStores';
import { Subscription } from 'rxjs';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../../../../helpers/constants';
var FleetTakeOverPopupComponent = /** @class */ (function (_super) {
    __extends(FleetTakeOverPopupComponent, _super);
    // content flags
    // feature flags
    function FleetTakeOverPopupComponent(bigAl, theBen, route, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.route = route;
        _this.appUserStore = appUserStore;
        _this.subscriptions = new Subscription();
        _this.didOneOrMoreEventFail = false;
        // data variables
        _this.done = new EventEmitter();
        _this.contractId = null;
        _this.subscriptions.add(_this.bigAl.dataStreamFailed.subscribe(function (response) {
            if (response.event === Constants.Event_CurrentDriver) {
                _this.didOneOrMoreEventFail = true;
            }
        }));
        return _this;
    }
    FleetTakeOverPopupComponent.prototype.setEventsToWatch = function () {
        this.watchEvent(Constants.Event_CurrentContracts);
        this.watchEvent(Constants.Event_CurrentDriver);
    };
    FleetTakeOverPopupComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData();
    };
    FleetTakeOverPopupComponent.prototype.setData = function () {
        if (this.bigAl.currentVehicleContract) {
            this.contractId = this.bigAl.currentVehicleContract.Id;
        }
        if (this.bigAl.currentDriver) {
            this.driver = this.bigAl.currentDriver;
        }
    };
    FleetTakeOverPopupComponent.prototype.getData = function () {
        var _this = this;
        if (!this.driver) {
            var takeOverInfo = this.bigAl.currentFleetUserTakeOverInfo;
            if (takeOverInfo) {
                this.contractId = takeOverInfo.ContractNumber;
                if (!this.bigAl.currentVehicleContract) {
                    this.bigAl.getContracts();
                }
                this.bigAl.getDriverProfile(takeOverInfo.Driver.DriverId);
            }
            else {
                if (this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.Driver || r === UserRoleType.SystemAdminDriver || r === UserRoleType.DevUser_Driver; })) {
                    this.bigAl.getDriver();
                }
                else if (this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser || r === UserRoleType.DevUser_Business; })) {
                    this.subscriptions.add(this.route.params.subscribe(function (params) {
                        if (params['id']) {
                            _this.bigAl.getDriverProfile(params['id']);
                        }
                    }));
                }
                else if (this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.DevUser_Admin; })) {
                    this.bigAl.getDriverProfile(this.route.snapshot.params["id"]);
                }
            }
        }
    };
    FleetTakeOverPopupComponent.prototype.isDataAvailable = function () {
        if (this.driver) {
            return true;
        }
        return false;
    };
    return FleetTakeOverPopupComponent;
}(AbstractFeature));
export { FleetTakeOverPopupComponent };
