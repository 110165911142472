import { Component } from '@angular/core';
import { AppointmentPlace } from '../../../../models/appointment-place.model';
import { AbstractModalFeature } from '../../../../components/features/modalfeature.abstract';
import { ActivatedRoute } from '@angular/router';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { Appointment } from '../../../../models/appointment.model';

@Component({
  selector: 'app-delete-booking',
  templateUrl: './delete-booking.component.html',
  styleUrls: ['./delete-booking.component.scss']
})
export class DeleteBookingComponent extends AbstractModalFeature {
  public currentAppointmentPlace: AppointmentPlace;
  public appointment: Appointment;

  constructor(
    private route: ActivatedRoute,
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
  }

  deleteAppointment() {
    this.bigAl.deleteAppointment(this.currentAppointmentPlace.ModelName, this.appointment.AppointmentId);
    this.action('modal');
  }


  protected setEventsToWatch(): void {
    this.subscribe(
      this.route.params.subscribe(params => {
        this.currentAppointmentPlace = this.bigAl.getAppointmentPlace(params['modelName']);
        this.appointment = this.currentAppointmentPlace.Bookings.find(appointment => appointment.AppointmentId === params['appointmentId']);
      }
      ));

    return;
  }
  protected setData(): void {

  }
  protected getData(): void {

    this.setData();
  }

  public isDataAvailable(): boolean {
    return true;
  }
}
