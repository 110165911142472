var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit } from '@angular/core';
import { AbstractFeature } from '../../../feature.abstract';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { FleetUserService } from '../../../../../services/fleet-user.service';
var FleetTakeOverBarComponent = /** @class */ (function (_super) {
    __extends(FleetTakeOverBarComponent, _super);
    // feature flags
    function FleetTakeOverBarComponent(bigAl, theBen, fleetUserService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.fleetUserService = fleetUserService;
        _this.isApp = false;
        // content flags
        _this.showChangeDepartment = true;
        _this.isApp = _this.bigAl.appSettings.IsApp;
        return _this;
    }
    FleetTakeOverBarComponent.prototype.ngAfterViewInit = function () {
        var takeoverBar = document.getElementById('fleet_admin_takeover_bar');
        if (takeoverBar) {
            takeoverBar.scrollIntoView();
        }
    };
    FleetTakeOverBarComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.watchEvent('currentDriver');
        this.fleetUserSubscription = this.fleetUserService.isInTakeOverMode.subscribe(function (isInTakeOverMode) {
            _this.isInTakeOverMode = isInTakeOverMode;
        });
        return;
    };
    FleetTakeOverBarComponent.prototype.setData = function () {
        if (this.bigAl.currentDriver) {
            this.driver = this.bigAl.currentDriver;
        }
    };
    FleetTakeOverBarComponent.prototype.getData = function () {
        if (!this.bigAl.currentDriver) {
            this.bigAl.getDriver();
        }
        else {
            this.setData();
        }
    };
    FleetTakeOverBarComponent.prototype.isDataAvailable = function () {
        if (this.driver && this.isInTakeOverMode) {
            return true;
        }
        return false;
    };
    FleetTakeOverBarComponent.prototype.exitTakeOver = function (event) {
        event.stopPropagation();
        this.bigAl.revertTakeOverDriver();
    };
    FleetTakeOverBarComponent.prototype.changeDriver = function () {
        this.theBen.action(this, "change-driver");
    };
    FleetTakeOverBarComponent.prototype.changeDriverNumber = function ($event) {
        $event.stopPropagation();
        this.theBen.action(this, "change-driver-number");
    };
    FleetTakeOverBarComponent.prototype.changeDepartment = function () {
        this.theBen.action(this, "department");
    };
    return FleetTakeOverBarComponent;
}(AbstractFeature));
export { FleetTakeOverBarComponent };
