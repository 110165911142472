import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { UserSettings } from "../../models/user-settings.model";
import { AppUser } from "../../models/appuser.model";
import { ApiService } from "../../services/api.service";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";
import { AppSettingsStore } from "./app-settings.store";
import { NavigationService } from "../../services/navigation.service";
import { Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: "root"
})
/**
 * TODO This is the AppUserStore and it still need to cleanup and implement alot from BigAL but we
 * needed the saveSettings function so this is the first half migrated version. So until we have migrated everything
 * to the store from BigAl we need to be sure to update the currentAppUser when we update the settings
 * from here.
 */
export class AppUserStore {
    public appUser: AppUser;

    private readonly appUser$: Subject<IStoreResult<AppUser>> = new Subject<IStoreResult<AppUser>>();
    onGetAppUser$ = this.appUser$.asObservable();

    constructor(
        private router: Router,
        private navigationService: NavigationService,
        private appSettingStore: AppSettingsStore,
        private apiService: ApiService) {
        this.appUser = AppUser.GetInitialUser();
        this.appUser$.next({ model: this.appUser, isInitial: true });
    }

    // TODO
    // getAppUser(forceReload: boolean = false): void {

    // }

    /**
     * Updates the settings for an AppUser
     * @param model the IDriverSettings object to update
     * @param authenticationUserId the user id
     */
    saveSettings(model: UserSettings, authenticationUserId: string) {
        return this.apiService.putAsync(`users/settings/${authenticationUserId}`, model, this.appSettingStore.appSettings)
            .then((settings: UserSettings) => {
                if (settings instanceof HttpErrorResponse) {
                    this.appUser.UserSettings = null;
                    this.appUser$.next({ model: this.appUser, isSuccess: false, error: "Something went wrong updating settings: " + settings.error });
                }
                else {
                    this.appUser.UserSettings = settings;
                    this.appUser$.next({ model: this.appUser, isSuccess: true });
                    if (this.router.url === '/more/settings') {
                        this.navigationService.back();
                    }
                }
            }).catch(() => {
                this.appUser.UserSettings = null;
                this.appUser$.next({ model: this.appUser, isSuccess: false, error: "Something went wrong updating settings" });
            });
    }

    /**
     * Saves the settings from the UserSettings form
     * @param formModel the keys and values from the form components
     */
    saveUserSettings(formModel: Map<string, string>) {
        let userSettings = this.appUser.UserSettings;
        // find the keys in the formModel that exists in the user settings and change the value in the user settings
        // to the form value
        for (let key of Object.keys(formModel)) {
            if (userSettings.ContentSettings.find(setting => setting.Settings[key] !== undefined)) {
                userSettings.ContentSettings.find(setting => setting.Settings[key] !== undefined).Settings[key] = formModel[key];
            }
        }
        // save the new user settings
        this.saveSettings(userSettings, this.appUser.AuthenticationUserId);
    }
}
