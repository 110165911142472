export enum UserRoleType {
  Anonymous = 0,
  Driver = 1,
  FleetAdmin = 2,
  SystemAdminDriver = 3,
  SystemAdminFleetAdmin = 5,
  FleetManager = 6,
  BusinessUser = 7,
  DevUser_Admin = 8,
  DevUser_Driver = 9,
  DevUser_Business = 10,
  SuperAdmin = 11,
  MaintenanceUser = 12
}
