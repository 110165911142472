var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FormBuilder } from '@angular/forms';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { Form } from '../../../models/form.model';
import { TranslatePipe } from '@ngx-translate/core';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
import { ActivatedRoute } from '@angular/router';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AbstractFeature } from '../../features/feature.abstract';
import { PhoneCountryCodeService } from '../../../services/phone-country-code.service';
import { NavigationService } from '../../../services/navigation.service';
var ɵ0 = APP_DATE_FORMATS;
var NewPasswordComponent = /** @class */ (function (_super) {
    __extends(NewPasswordComponent, _super);
    function NewPasswordComponent(translatePipe, bigAl, theBen, route, navigationService, fb, validatorFactory, fileUploadService, translateService, phoneCountryCodeService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.translatePipe = translatePipe;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.route = route;
        _this.navigationService = navigationService;
        _this.fb = fb;
        _this.validatorFactory = validatorFactory;
        _this.fileUploadService = fileUploadService;
        _this.translateService = translateService;
        _this.phoneCountryCodeService = phoneCountryCodeService;
        _this.currentPasswordSetCode = null;
        _this.badLink = false;
        _this.passwordSetSuccessfully = false;
        _this.formModel = new Form(_this.getFormObject(), _this.getRuleObject(), _this.translatePipe, _this.bigAl, _this.theBen, _this.fb, _this.validatorFactory, _this.fileUploadService, _this.translateService, _this.phoneCountryCodeService);
        return _this;
    }
    NewPasswordComponent.prototype.getFormObject = function () {
        return _super.prototype.getFormObject.call(this, "NewPasswordComponent", new Map());
    };
    NewPasswordComponent.prototype.getRuleObject = function () {
        return _super.prototype.getRuleObject.call(this, "NewPasswordComponent");
    };
    NewPasswordComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.watchEvent("currentPasswordSetCode");
        this.subscribe(this.bigAl.getDataStream().subscribe(function (lastEvent) {
            if (lastEvent === "passwordSet") {
                _this.passwordSetSuccessfully = true;
            }
        }));
        this.route.queryParams.subscribe(function (params) {
            var resetCode = params['LinkId'];
            if (resetCode) {
                _this.bigAl.getPasswordSetCode(resetCode.trim());
            }
            else {
                _this.bigAl.getPasswordSetCode(_this.bigAl.currentPasswordResetCode);
            }
            if (!resetCode && !_this.bigAl.currentPasswordResetCode) {
                _this.badLink = true;
            }
        });
    };
    NewPasswordComponent.prototype.setData = function () {
        if (this.bigAl.currentPasswordResetCode) {
            this.currentPasswordSetCode = this.bigAl.currentPasswordSetCode;
        }
    };
    NewPasswordComponent.prototype.getData = function () {
        if (this.bigAl.currentPasswordSetCode) {
            this.setData();
        }
    };
    NewPasswordComponent.prototype.isDataAvailable = function () {
        return true;
    };
    NewPasswordComponent.prototype.back = function () {
        this.navigationService.forward("login", null);
    };
    return NewPasswordComponent;
}(AbstractFeature));
export { NewPasswordComponent };
export { ɵ0 };
