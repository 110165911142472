import * as i0 from "@angular/core";
var ToastService = /** @class */ (function () {
    function ToastService() {
    }
    ToastService.prototype.show = function (message) {
        if (window['plugins']) {
            var plugin = window['plugins'].toast;
            if (plugin) {
                plugin.showLongCenter(message);
            }
        }
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
