export class CongestionTaxMonthlyTotal {
    public PrivateAmount: number;
    public PrivateAmountText: string;
    public NumberOfPasssages: number;
    public NumberOfPasssagesText: string;
    public TotalAmount: number;
    public TotalAmountText: string;
    public PrivatePart: number;
    public PrivatePartText: string;
    public TotalLabel: string = "Total";
}
