var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Validators } from "@angular/forms";
var Form = /** @class */ (function () {
    function Form(formObject, ruleObject, translatePipe, bigAl, theBen, fb, validatorFactory, fileUploadService, translateService, phoneCountryCodeService) {
        var _this = this;
        this.formObject = formObject;
        this.ruleObject = ruleObject;
        this.translatePipe = translatePipe;
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.fb = fb;
        this.validatorFactory = validatorFactory;
        this.fileUploadService = fileUploadService;
        this.translateService = translateService;
        this.phoneCountryCodeService = phoneCountryCodeService;
        this.hide = true;
        this.stepNumber = 0;
        this.options = new Map();
        this.dependentOptions = new Map();
        this.dependentFieldMap = new Map();
        this.selectedFile = '';
        // nextTouched and submitTouched is used for showing error messages for radio buttons and similar.
        this.nextTouched = [];
        this.submitTouched = false;
        this.showWeekends = true;
        this.shouldShow = true;
        this.allSubscriptions = [];
        this.defaultProperties = null;
        this.customRuleObject = {};
        this.dateFilter = function (d) {
            if (!_this.showWeekends) {
                var day = d.getDay();
                return day !== 0 && day !== 6;
            }
            return true;
        };
        this.initForm();
    }
    Form.prototype.initForm = function () {
        var _this = this;
        // Enforce special rules that i CBA to implement the right way
        if (this.formObject.Name.includes("DriverJournalDailyLog")) {
            this.customRuleObject["Start"] = __assign({}, this.formObject.Steps[0].Properties.find(function (x) { return x.Name === "Start"; }));
            this.customRuleObject["End"] = __assign({}, this.formObject.Steps[0].Properties.find(function (x) { return x.Name === "End"; }));
        }
        var group = this.fb.group({});
        // construct the form group with validators
        if (this.formObject) {
            if (this.hasTranslation[this.formObject.Name] && this.hasTranslation[this.formObject.Name]["Header_t"]) {
                this.label = this.formObject.Name + ".Header_t";
            }
            // the properties are divided in a steps array with properties per step so first we need to
            // collect all properties in one array
            var properties_1 = [];
            this.formArray = this.fb.array([]);
            this.formObject["Steps"].forEach(function (step) {
                _this.nextTouched.push(false);
                var formGroup = _this.fb.group({});
                var props = step["Properties"];
                props.forEach(function (prop) {
                    var rules = _this.ruleObject["Properties"].find(function (obj) { return obj["Name"] === prop.Name; })["Rules"];
                    prop.Rules = rules;
                    prop.ShouldShow = true;
                    properties_1.push(prop);
                    // gets the validators to the form control
                    var validators = _this.validatorFactory.getValidators(rules, formGroup);
                    if (prop.Value) {
                        var propVal = prop.Value;
                        if (prop.Prefix) {
                            propVal = _this.removePrefixFromValue(propVal, prop);
                        }
                        formGroup.addControl(prop.Name, _this.fb.control(propVal, validators));
                    }
                    if (prop.Options) {
                        var preselectedOption = "";
                        for (var i = 0; i < prop.Options.length; i++) {
                            var curOption = prop.Options[i];
                            if (curOption[2] === true || prop.Value === curOption[0]) {
                                preselectedOption = curOption[0];
                            }
                        }
                        formGroup.addControl(prop.Name, _this.fb.control(preselectedOption, validators));
                    }
                    else {
                        formGroup.addControl(prop.Name, _this.fb.control("", validators));
                    }
                    if (prop.Prefix) {
                        formGroup.addControl(prop.Name + "_prefix", _this.fb.control(_this.getPrefixValue(prop)));
                    }
                    // Some fields depend on the value(s) of other fields for validation of their own value, but re-validation is not triggered automatically
                    // when the dependent field's value is updated, and therefore we subscribe to each field's valueChange that it should re-validate all invalid and enabled fields
                    // when a value change occurs.
                    formGroup.get(prop.Name).valueChanges.subscribe(function (value) {
                        if (_this.customRuleObject["Start"] && _this.customRuleObject["Start"].Value) {
                            if (prop.Name === "Start") {
                                if (value.toLowerCase() !== _this.customRuleObject["Start"].Value.toLowerCase()) {
                                    if (!prop["WarningMessage"]) {
                                        _this.subscribe(_this.translateService.get("RecordTrip.address-changes-affects-toll-stations").subscribe(function (text) {
                                            prop["WarningMessage"] = text;
                                            prop["ShowWarningMessage"] = true;
                                        }));
                                    }
                                    else {
                                        prop["ShowWarningMessage"] = true;
                                    }
                                }
                                else {
                                    prop["ShowWarningMessage"] = false;
                                }
                            }
                            if (prop.Name === "End" && _this.customRuleObject["End"].Value) {
                                if (value.toLowerCase() !== _this.customRuleObject["End"].Value.toLowerCase()) {
                                    if (!prop["WarningMessage"]) {
                                        _this.subscribe(_this.translateService.get("RecordTrip.address-changes-affects-toll-stations").subscribe(function (text) {
                                            prop["WarningMessage"] = text;
                                            prop["ShowWarningMessage"] = true;
                                        }));
                                    }
                                    else {
                                        prop["ShowWarningMessage"] = true;
                                    }
                                }
                                else {
                                    prop["ShowWarningMessage"] = false;
                                }
                            }
                        }
                        props.forEach(function (p) {
                            if (p.Name === prop.Name) {
                                return;
                            }
                            var sibling = formGroup.get(p.Name);
                            if (sibling && sibling.invalid && sibling.enabled) {
                                sibling.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                            }
                        });
                    });
                });
                _this.formArray.push(formGroup);
                _this.defaultProperties = properties_1;
                // subscribe to value changes when actions have to be done on change
                Object.keys(formGroup.controls).forEach(function (controlName) {
                    var matchProperty = properties_1.find(function (p) { return p.Name === controlName; });
                    if (matchProperty) {
                        if (matchProperty.Readonly === true) {
                            formGroup.get(matchProperty.Name).disable();
                        }
                        if (matchProperty.OnChange) {
                            properties_1 = _this.applyOnChangeInfluences(matchProperty.Value, matchProperty, properties_1, formGroup);
                            formGroup.get(matchProperty.Name).markAsPristine();
                            _this.subscribe(formGroup.get(matchProperty.Name).valueChanges.subscribe(function (newValue) {
                                properties_1 = _this.applyOnChangeInfluences(newValue, matchProperty, properties_1, formGroup);
                            }));
                        }
                    }
                });
            });
            this.stepperForm = this.fb.group({ formArray: this.formArray });
            properties_1.forEach(function (property) {
                // there will be options if it for example is radio buttons or select field
                if (property.DependentOptions && property.DependentOn) {
                    _this.dependentFieldMap.set(property.DependentOn, property.Name);
                    _this.dependentOptions.set(property.DependentOn, property.DependentOptions);
                    _this.options.set(property.Name, []);
                }
                else if (property.Options) {
                    _this.options.set(property.Name, property.Options);
                }
                var rules = _this.ruleObject["Properties"].find(function (obj) { return obj["Name"] === property.Name; })["Rules"];
                property.Rules = rules;
                // gets the validators to the form control
                var validators = _this.validatorFactory.getValidators(rules, group);
                if (property.Value) {
                    group.addControl(property.Name, _this.fb.control(property.Value, validators));
                }
                else if (property.Options) {
                    var preselectedOption = "";
                    for (var i = 0; i < property.Options.length; i++) {
                        var curOption = property.Options[i];
                        if ((curOption[2] && curOption[2] === true) || property.Value === curOption[0]) {
                            preselectedOption = curOption[0];
                        }
                    }
                    group.addControl(property.Name, _this.fb.control(preselectedOption, validators));
                }
                else {
                    group.addControl(property.Name, _this.fb.control("", validators));
                }
                if (property.Control === "DatePicker" && property.Weekends === false) {
                    _this.showWeekends = false;
                }
            });
        }
        else {
            console.warn("Did not get a formObject in the form component. Did you forgot to add a formObject to the form tag? Or is the form not added to the rulebook?");
        }
        this.form = group;
    };
    Form.prototype.uploadFile = function (event) {
        var _this = this;
        this.upload(event, function (file, input) { return _this.fileUploadService.defaultFileUpload(file, input, _this.bigAl.appSettings); });
    };
    Form.prototype.uploadGloveboxFile = function (event) {
        var _this = this;
        this.upload(event, function (file, input) { return _this.fileUploadService.gloveboxFileUpload(file, input, _this.bigAl.appSettings); });
    };
    Form.prototype.upload = function (event, uploadFunction) {
        this.input = event.target;
        var file = event.target.files[0];
        this.selectedFile = file.name;
        uploadFunction(file, this.input);
    };
    Form.prototype.hasTranslation = function (key) {
        var result;
        this.translateService.get(key).subscribe(function (res) {
            if (res === key) {
                result = false;
            }
            else {
                result = true;
            }
        });
        return result;
    };
    Form.prototype.getTranslationKey = function (key) {
        var translationKey = [this.formObject.Name] + '.' + [key];
        return translationKey;
    };
    Form.prototype.translationExists = function (key) {
        var translationKey = this.getTranslationKey(key);
        return this.hasTranslation(translationKey);
    };
    /**
  * Gets the text translated for the placeholder or an empty string if no text is found
  * @param key the property name the text field belongs to
  */
    Form.prototype.getPlaceholder = function (key) {
        return this.translatePipe.transform(this.formObject.Name + "." + key + "_p");
    };
    Form.prototype.getPlaceholderForProperty = function (prop) {
        if (!prop.Translateable) {
            return prop.Label;
        }
        return this.getPlaceholder(prop.Name);
    };
    Form.prototype.onSelect = function (event, second) {
        for (var i = 0; i < this.formObject.Steps.length; i++) {
            if (this.formObject.Steps[i]["Properties"].find(function (obj) { return obj["Name"] === second; })) {
                var field = this.formObject.Steps[i]["Properties"].find(function (obj) { return obj["Name"] === second; });
                var map = field["OptionsMap"];
                var options = map.get(event.value);
                field.Options = options;
            }
        }
    };
    Form.prototype.getRules = function (propName) {
        var rules = [];
        this.formObject.Steps.forEach(function (step) {
            step.Properties.forEach(function (prop) {
                if (prop.Name === propName) {
                    rules = prop.Rules;
                    return;
                }
            });
        });
        return rules;
    };
    Form.prototype.getProperty = function (name) {
        var property = null;
        this.formObject.Steps.forEach(function (step) {
            step.Properties.forEach(function (prop) {
                if (prop.Name === name) {
                    property = prop;
                }
            });
        });
        return property;
    };
    Form.prototype.selectedDropdownValue = function (key, chosenValue) {
        var curDependentOptions = this.dependentOptions.get(key);
        if (!curDependentOptions) {
            return;
        }
        var relevantOptions = curDependentOptions[chosenValue.value];
        if (!relevantOptions) {
            console.warn("Did not find the appropriate set of dependent options for value: " + chosenValue);
        }
        var dependentField = this.dependentFieldMap.get(key);
        this.options.set(dependentField, relevantOptions);
    };
    Form.prototype.goForward = function (i) {
        this.nextTouched[i] = true;
    };
    Form.prototype.validate = function () {
        // Re-validates all active fields in the form. This is to ensure that when e.g. submitting, that the user is not actually submitting an invalid form.
        this.formArray.controls.forEach(function (formGroup) {
            var fg = formGroup;
            if (fg && fg.controls) {
                Object.keys(fg.controls).forEach(function (field) {
                    var control = fg.get(field);
                    // Checkbox validation causes problems for fields that are only shown if checkbox is checked, however when validating the form, the checkbox has already been validated anyway.
                    if (control.value === true) {
                        return;
                    }
                    if (control.enabled) {
                        control.updateValueAndValidity({ onlySelf: true });
                    }
                });
            }
        });
        // Depending on how the re-validation went, return true or false.
        if (this.formArray.invalid) {
            return false;
        }
        return true;
    };
    Form.prototype.applyOnChangeInfluences = function (newValue, property, currentProperties, formGroup) {
        var _this = this;
        property.OnChange.forEach(function (changePrefs) {
            var dependentCheck = true;
            if (changePrefs.DependentOn) {
                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === changePrefs.DependentOn.Name; });
                if (!matchingField || formGroup.get(matchingField.Name).value != changePrefs.DependentOn.Value) {
                    dependentCheck = false;
                }
            }
            if ((changePrefs.Value === newValue || changePrefs.Value === '%%any%%') && dependentCheck) {
                changePrefs.Actions.forEach(function (action) {
                    switch (action.Type) {
                        case 'detected':
                            break;
                        case 'hide':
                            action.Influence.forEach(function (field) {
                                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                if (matchingField) {
                                    matchingField.Hidden = true;
                                    formGroup.get(matchingField.Name).disable();
                                }
                            });
                            break;
                        case 'show':
                            action.Influence.forEach(function (field) {
                                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                if (matchingField) {
                                    matchingField.Hidden = false;
                                    formGroup.get(matchingField.Name).enable();
                                }
                            });
                            break;
                        case 'disable':
                            action.Influence.forEach(function (field) {
                                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                if (matchingField) {
                                    formGroup.get(matchingField.Name).setValue(null);
                                    formGroup.get(matchingField.Name).disable();
                                }
                            });
                            break;
                        case 'enable':
                            action.Influence.forEach(function (field) {
                                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                var matchingDefaultField = _this.defaultProperties.find(function (defaultField) { return defaultField.Name === field; });
                                if (matchingField) {
                                    formGroup.get(matchingField.Name).enable();
                                }
                            });
                            break;
                        case 'reset':
                            action.Influence.forEach(function (field) {
                                var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                var matchingDefaultField = _this.defaultProperties.find(function (defaultField) { return defaultField.Name === field; });
                                if (matchingField && matchingDefaultField) {
                                    formGroup.get(matchingField.Name).setValue(matchingDefaultField.Value);
                                }
                            });
                            break;
                        case 'takeFieldValue':
                            var name1_1 = action.By.Between[0];
                            action.Influence.forEach(function (field) {
                                var newField = currentProperties.find(function (propertyField) { return propertyField.Name === name1_1; });
                                var oldFiled = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                if (newField && oldFiled) {
                                    formGroup.get(oldFiled.Name).setValue(formGroup.get(newField.Name).value);
                                }
                            });
                            break;
                        case 'changeValues':
                            if ((newValue !== null || newValue !== undefined)) {
                                action.Influence.forEach(function (field) {
                                    var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                    if (matchingField) {
                                        var oldValue = formGroup.get(matchingField.Name).value;
                                        var newValue_1 = _this.getChangeValueActionResult(action.By, currentProperties, formGroup);
                                        if (oldValue !== newValue_1 && !isNaN(parseInt(newValue_1))) {
                                            formGroup.get(matchingField.Name).setValue(newValue_1);
                                        }
                                    }
                                });
                            }
                            break;
                        case 'changeValuesNumber':
                            if ((newValue !== null || newValue !== undefined) && new RegExp('^[0-9]+$').test(newValue)) {
                                action.Influence.forEach(function (field) {
                                    var matchingField = currentProperties.find(function (propertyField) { return propertyField.Name === field; });
                                    if (matchingField) {
                                        var oldValue = formGroup.get(matchingField.Name).value;
                                        var newValue_2 = _this.getChangeValueActionResult(action.By, currentProperties, formGroup);
                                        if (oldValue !== newValue_2 && !isNaN(parseInt(newValue_2))) {
                                            formGroup.get(matchingField.Name).setValue(newValue_2);
                                        }
                                    }
                                });
                            }
                            break;
                        case 'calculateDistance':
                            // handled in the form component
                            break;
                        case 'toggleDriverJournalTripPurposeValidation':
                            if ((newValue !== null && newValue !== undefined)) {
                                action.Influence.forEach(function (toInfluence) {
                                    var findField = _this.formObject.Steps[0].Properties.find(function (prop) { return prop.Name === toInfluence; });
                                    var getField = formGroup.get(findField.Name);
                                    console.log(" ");
                                    if (newValue === 1) {
                                        getField.clearValidators();
                                        getField.setValidators(Validators.required);
                                        getField.updateValueAndValidity();
                                    }
                                    else if (newValue === 2) {
                                        getField.clearValidators();
                                        getField.updateValueAndValidity();
                                    }
                                });
                            }
                            break;
                        default:
                            console.warn('COULD NOT FIND ACTION', action.Type);
                            break;
                    }
                });
            }
        });
        return currentProperties;
    };
    Form.prototype.getChangeValueActionResult = function (actionBy, currentProperties, formGroup) {
        var newValue = null;
        switch (actionBy.Approach) {
            case 'math':
                if (actionBy.Operation === 'difference') {
                    var fieldValues_1 = new Array();
                    actionBy.Between.forEach(function (betweenField) {
                        fieldValues_1.push(formGroup.get(betweenField).value);
                    });
                    if (fieldValues_1.length > 1 && !fieldValues_1.some(function (v) { return v === null || v === ""; })) {
                        newValue = fieldValues_1[0];
                        for (var i = 1; i < fieldValues_1.length; i++) {
                            newValue -= parseInt(fieldValues_1[i]);
                        }
                    }
                    else {
                        newValue = null;
                    }
                    if (newValue < 0) {
                        newValue = null;
                    }
                }
                if (actionBy.Operation === 'max') {
                    var fieldValues_2 = new Array();
                    actionBy.Between.forEach(function (betweenField) {
                        var value = formGroup.get(betweenField).value;
                        if (value != null && value !== "") {
                            fieldValues_2.push(parseInt(value));
                        }
                    });
                    if (fieldValues_2.length > 1) {
                        newValue = fieldValues_2.reduce(function (a, b) { return Math.min(a, b); });
                    }
                    else {
                        newValue = null;
                    }
                }
                break;
            default:
                console.warn('COULD NOT FIND APPROACH BY TYPE');
        }
        return newValue;
    };
    Form.prototype.getPrefixValue = function (property) {
        if (property.Prefix) {
            switch (property.Prefix.trim().toLowerCase()) {
                case "phonecountrycode":
                    var curVal = property.Value;
                    // If there is no "+" then it is not a country code anyways.
                    if (!curVal.startsWith("+")) {
                        return this.phoneCountryCodeService.getDefaultCountryCode(this.bigAl.localLanguageCountryCode);
                    }
                    // We start with string length 2, because "+1" is a valid country code (US)
                    var strLength = 2;
                    var countryCodes = this.phoneCountryCodeService.getCountryCodes();
                    while (strLength < 5) {
                        var substr = curVal.substr(0, strLength);
                        if (countryCodes.has(substr)) {
                            return substr;
                        }
                        strLength++;
                    }
                    break;
            }
        }
        return "";
    };
    Form.prototype.removePrefixFromValue = function (value, property) {
        switch (property.Prefix.trim().toLowerCase()) {
            case "phonecountrycode":
                // If there is no "+" then it is not a country code anyways.
                if (!value.startsWith("+")) {
                    return value;
                }
                // We start with string length 2, because "+1" is a valid country code (US)
                var strLength = 2;
                var countryCodes = this.phoneCountryCodeService.getCountryCodes();
                while (strLength < 5) {
                    var substr = value.substr(0, strLength);
                    if (countryCodes.has(substr)) {
                        return value.substr(strLength);
                    }
                    strLength++;
                }
                break;
        }
        return value;
    };
    Form.prototype.getPrefixOptions = function (property) {
        var options = [];
        switch (property.Prefix.trim().toLowerCase()) {
            case "phonecountrycode":
                this.phoneCountryCodeService.getCountryCodes().forEach(function (val) { return options.push(val); });
                break;
        }
        return options;
    };
    Form.prototype.subscribe = function (subscription) {
        this.allSubscriptions.push(subscription);
    };
    Form.prototype.unsubscribe = function () {
        this.allSubscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    return Form;
}());
export { Form };
