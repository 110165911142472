/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "../mask/mask.component.ngfactory";
import * as i4 from "../mask/mask.component";
import * as i5 from "@angular/common";
import * as i6 from "./modal.component";
import * as i7 from "../../../services/navigation.service";
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-action-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "grey-text icon-xs icon-close-btn mat-icon"], ["role", "img"], ["svgIcon", "cross"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(2, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "cross"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { mask: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-mask", [], null, [[null, "onMaskClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMaskClose" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MaskComponent_0, i3.RenderType_MaskComponent)), i0.ɵdid(2, 114688, [[1, 4]], 0, i4.MaskComponent, [], null, { onMaskClose: "onMaskClose" }), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h1", [], null, null, null, null, null)), i0.ɵncd(null, 0), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), i0.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.allowClose; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainStyles; _ck(_v, 3, 0, currVal_0); }); }
export function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 114688, null, 0, i6.ModalComponent, [i7.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i0.ɵccf("app-modal", i6.ModalComponent, View_ModalComponent_Host_0, { size: "size", closeButton: "closeButton" }, { modalClosing: "modalClosing" }, ["[modal-header]", "[modal-subheader]", "[modal-body]", "[modal-footer]"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
