import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-warning',
  templateUrl: './feature-warning.component.html',
  styleUrls: ['./feature-warning.component.scss']
})
export class FeatureWarningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
