<div class="accordion" *ngIf="isDataAvailable()">
  <input type="checkbox" id="tab1">
  <label for="tab1">
    <div class="tab-headline">
      <mat-icon class="icon-btn" svgIcon="avatar"></mat-icon>{{driver?.FullName}}&nbsp;&nbsp;
    </div>
    <div class="tab-buttons">
      <button mat-raised-button type="button" class="admin_move_button" *ngIf="isContent('changeDriver')"
        color="primary" (click)="changeDriver(); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>

        <app-translate translationName="FleetTakeOverBarComponent.admin_change_driver_b">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_change_driver_b' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button class="admin_move_button" *ngIf="isContent('changeDriverNumber')" color="primary"
        (click)="changeDriverNumber($event); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.admin_change_driver_number_b">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_change_driver_number_b' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button class="admin_move_button" *ngIf="isContent('change_department')" color="primary"
        (click)="changeDepartment(); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.b_admin_change_department">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.b_admin_change_department' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button color="primary" (click)="exitTakeOver($event)">
        <mat-icon class="icon-btn" svgIcon="admin-list"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.admin_fleet_overview_t">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_fleet_overview_t' | translate }}
          </span>
        </app-translate>
      </button>
    </div>
  </label>
  <div class="tab-content">
    <app-fleet-take-over-popup></app-fleet-take-over-popup>
    <div class="tab-buttons-xs">
      <button mat-raised-button type="button" class="admin_move_button" *ngIf="isContent('changeDriver')"
              color="primary" (click)="changeDriver(); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.admin_change_driver_b">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_change_driver_b' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button class="admin_move_button" *ngIf="isContent('changeDriverNumber')" color="primary"
              (click)="changeDriverNumber($event); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.admin_change_driver_number_b">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_change_driver_number_b' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button class="admin_move_button" *ngIf="isContent('change_department')" color="primary"
              (click)="changeDepartment(); $event.stopPropagation();">
        <mat-icon class="icon-btn" svgIcon="admin-directions"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.b_admin_change_department">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.b_admin_change_department' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button color="primary" (click)="exitTakeOver($event)">
        <mat-icon class="icon-btn" svgIcon="admin-list"></mat-icon>
        <app-translate translationName="FleetTakeOverBarComponent.admin_fleet_overview_t">
          <span translate-text>
            {{ 'FleetTakeOverBarComponent.admin_fleet_overview_t' | translate }}
          </span>
        </app-translate>
      </button>
    </div>
  </div>
</div>
<app-api-error></app-api-error>
