var Appointment = /** @class */ (function () {
    function Appointment(AppointmentId, Appointment, MobilePhoneNumber, Description, Email) {
        if (Email === void 0) { Email = null; }
        this.AppointmentId = AppointmentId;
        this.Appointment = Appointment;
        this.MobilePhoneNumber = MobilePhoneNumber;
        this.Description = Description;
        this.Email = Email;
    }
    return Appointment;
}());
export { Appointment };
