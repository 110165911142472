import { OnInit } from '@angular/core';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';
var FieldHintComponent = /** @class */ (function () {
    function FieldHintComponent() {
        this.translationTagType = TranslationTagType.Hint;
    }
    FieldHintComponent.prototype.ngOnInit = function () {
    };
    return FieldHintComponent;
}());
export { FieldHintComponent };
