import { ApiResponse } from './../models/api-response.model';
import { AppUser } from './../models/appuser.model';
import 'rxjs/add/operator/do';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BigAlModule } from '../modules/bigal.module';
import { Inject, forwardRef } from '@angular/core';
import { ApiService } from '../services/api.service';
import { SpinnerService } from '../services/spinner.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap, share, retryWhen } from 'rxjs/operators';

export class ApiInterceptor implements HttpInterceptor {
    private retryRequest = Symbol('reload');
    private alreadyTriedToRefresh;

    constructor(
        @Inject(forwardRef(() => BigAlModule)) private bigAl: BigAlModule,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private httpClient: HttpClient
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // IMPORTANT - DO NOT REMOVE AS THIS HANDLES CATCHING THE 401 ON EVERY DIFFERENT REQUEST
        this.alreadyTriedToRefresh = false;

        let headers = null;
        const isInternalRequest = this.bigAl.isInternalRequest(request.url);

        if (isInternalRequest && this.bigAl.getAuthorizationHeader() && !this.bigAl.getTraceHeader()) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.bigAl.getAuthorizationHeader()
            });
        }

        if (isInternalRequest && this.bigAl.getAuthorizationHeader() && this.bigAl.getTraceHeader()) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.bigAl.getAuthorizationHeader(),
                'TraceId': this.bigAl.getTraceHeader()
            });
        }

        if (!headers) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }

        const interceptReq = request.clone({
            headers: headers
        });

        const request$ = new Observable<HttpRequest<any>>(observer => {
            observer.next(interceptReq);
            observer.complete();
        });

        return request$.pipe(
            switchMap(req => {
                // CLONE REQUEST AFTER TOKEN REFRESHED TO SEND THE NEW AUTHORIZATION HEADER
                if (this.alreadyTriedToRefresh && !req.urlWithParams.includes('tokens/refresh')) {
                    let newHeaders = new HttpHeaders(
                        {
                            "Content-Type": "application/json",
                            "Authorization": this.bigAl.getAuthorizationHeader(),
                            "TraceId": this.bigAl.currentTraceId
                        }
                    );
                    let clonedReq = req.clone({
                        headers: newHeaders
                    });

                    return next.handle(clonedReq);
                }

                // ONLY FOR TESTING TO FAKE A 401
                // if (req.urlWithParams.includes('MileageUpdate') && !this.bigAl.testTokenRefresh) {
                //     this.bigAl.testTokenRefresh = true;
                //     let newHeaders = new HttpHeaders(
                //         {
                //             "Content-Type": "application/json",
                //             "Authorization": "Token saas",
                //             "TraceId": this.bigAl.currentTraceId
                //         }
                //     );
                //     let clonedReq = req.clone({
                //         headers: newHeaders
                //     });
                //     return next.handle(clonedReq);
                // }

                // if (req.urlWithParams.includes('vehicle') && this.bigAl.testTokenRefresh) {
                //     this.bigAl.testTokenRefresh = false;
                // }

                return next.handle(req);
            }),
            catchError(
                (err: Error) => {
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case 401:
                                let token = this.bigAl.getRefreshKey();
                                // CATCH SECOND TRY TO REFRESH TOKEN ON THE SAME REQUEST
                                if (this.alreadyTriedToRefresh || err.url.includes('logout')) {
                                    this.alreadyTriedToRefresh = false;
                                    this.bigAl.logout();
                                    throw err;
                                }
                                else if (token) {
                                    const path = this.bigAl.appSettings.ApiBaseUrl + "tokens/refresh/" + token;
                                    // SEND REQUEST TO REFRESH TOKEN
                                    return this.httpClient.get(path, { headers: headers })
                                        .pipe(
                                            tap((newToken: ApiResponse<AppUser>) => {
                                                if (newToken.Data) {
                                                    this.bigAl.setDataForRequestHeaders(newToken.Token);
                                                    this.bigAl.setCurrentUser(newToken.Data);
                                                    throw this.retryRequest;
                                                }
                                                else {
                                                    this.bigAl.logout();
                                                }
                                            }),
                                            share()
                                        ) as Observable<any>;
                                }
                                else {
                                    throw err;
                                }
                            case 403:
                                if (err.url.includes('tokens/refresh')) {
                                    this.alreadyTriedToRefresh = true;
                                    this.bigAl.logoutAndClearSession();
                                    return new Observable<any>();
                                }
                                else {
                                    this.apiService.collectFailedRequest(request);
                                    this.spinnerService.hide();
                                    if (request.method !== "GET") {
                                        this.apiService.addError(err);
                                    }
                                    throw err;
                                }
                            default:
                                this.apiService.collectFailedRequest(request);
                                this.spinnerService.hide();
                                if (request.method !== "GET") {
                                    this.apiService.addError(err);
                                }
                                throw err;


                        }
                    }
                    else {
                        throw err;
                    }
                }
            ),
            retryWhen(err$ =>
                err$.pipe(
                    tap(err => {
                        if (err === this.retryRequest && !this.alreadyTriedToRefresh) {
                            this.alreadyTriedToRefresh = true;
                            return;
                        }

                        throw err;
                    })
                )
            )
        );
    }

}
