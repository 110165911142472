import { BigAlModule } from './../modules/bigal.module';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
import { AppUserStore } from '../stores/DataStores';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private navigationService: NavigationService,
    private bigAl: BigAlModule,
    private appUserStore: AppUserStore,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // not logged in so redirect to login page with the return url
    if (!this.appUserStore.appUser.Roles.some(ur => ur !== UserRoleType.Anonymous)) {
      let refreshToken = this.bigAl.getRefreshKey();
      if (refreshToken) {
        console.warn("LoginGuard -> postRefreshToken()");

        this.bigAl.postRefreshToken();
      }
      else {
        this.navigationService.forward("login", null, { queryParams: { returnUrl: state.url } });
        return false;
      }
    }
    return true;
  }
}
