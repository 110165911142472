import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, AfterViewInit, OnDestroy {

  showSpinner = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private spinnerService: SpinnerService) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.subscription.add(this.spinnerService.spinnerState
      .subscribe((state: boolean) => {
        this.showSpinner = state;
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
