<app-modal *ngIf="appointment && currentAppointmentPlace" size='small'>
  <div modal-header>
    <app-translate translationName="DeleteBookingComponent.delete_t">
      <span translate-text>
        {{ 'DeleteBookingComponent.delete_t' | translate }}
      </span>
    </app-translate>
  </div>
  <div modal-body>
    <div class="sub-box">
      <h3>
        <app-translate translationName="DeleteBookingComponent.delete_description_t">
          <span translate-text>
            {{ 'DeleteBookingComponent.delete_description_t' | translate }}
          </span>
        </app-translate>
        {{ currentAppointmentPlace.Name }}
      </h3>
      <p>
        <br>
        <span class="font-numbers">
          {{ appointment.Appointment | localizedDate: "EEEE, MMMM d, y 'at' HH:mm" }}
        </span>
      </p>
      <div>
      </div>
    </div>
  </div>
  <div modal-footer>
    <div fxLayoutGap="40px">
      <button mat-raised-button color="warn" type="button" (click)="action('modal')">
        <app-translate translationName="DeleteBookingComponent.cancel_t">
          <span translate-text>
            {{ 'DeleteBookingComponent.cancel_t' | translate }}
          </span>
        </app-translate>
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="deleteAppointment()">
        <app-translate translationName="DeleteBookingComponent.okay_t">
          <span translate-text>
            {{ 'DeleteBookingComponent.okay_t' | translate }}
          </span>
        </app-translate>
      </button>
    </div>
  </div>
</app-modal>