export class WorkshopSearch {
    constructor(
        public PostalCode: string = null,
        public Latitude: number = 0,
        public Longitude: number = 0,
        public WithPickup: boolean = false,
        public InsuranceOnly: boolean = false,
        public Category: string = null, // only for NO and SE
        public ServiceLinkOnly: boolean = false
    ) {

    }
}
