import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { BusinessPartner } from "../../models/business-partner.model";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";


@Injectable({
  providedIn: "root"
})
export class BusinessPartnerStore {

  private businessPartner: BusinessPartner;

  private readonly businessPartner$: BehaviorSubject<IStoreResult<BusinessPartner>> = new BehaviorSubject<IStoreResult<BusinessPartner>>({ model: null, isInitial: true });
  onGetBusinessPartner$ = this.businessPartner$.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  // #region Requests

  getBusinessPartner(forceReload: boolean = false): void {

    // Entry found
    if (this.businessPartner && !forceReload) {
      this.businessPartner$.next({ model: this.businessPartner, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<BusinessPartner>(ApiUrl.BusinessPartner_Current, this.settingStore.appSettings)
      .then((data: BusinessPartner) => {
        this.businessPartner = data;
        this.businessPartner$.next({ model: this.businessPartner, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.businessPartner = null;
        this.businessPartner$.next({ model: this.businessPartner, isSuccess: false, error: err });
      });
  }

  // #endregion
}
