import { BehaviorSubject } from "rxjs";
var BrowserService = /** @class */ (function () {
    //private onGeoEnablingChanged = new BehaviorSubject<boolean>(false);
    //onGeoEnablingChanged$ = this.onGeoEnablingChanged.asObservable();
    function BrowserService() {
        this.onPermissionChanged = new BehaviorSubject("prompt");
        this.onPermissionChanged$ = this.onPermissionChanged.asObservable();
        this.isGeoPermissionStatusEnabled = false;
        this.isGeoEnabled = false;
        this.permissionStatusType = "prompt";
    }
    BrowserService.prototype.permissionStateChanged = function (_this, evt) {
        console.log(">> permissionStateChanged << ", _this);
        switch (_this.state) {
            case "granted":
                this.isGeoPermissionStatusEnabled = true;
                break;
            case "denied":
            case "prompt":
            case "notAvailable":
                this.isGeoPermissionStatusEnabled = false;
                break;
        }
        this.permissionStatusType = _this.state;
        this.onPermissionChanged.next(this.permissionStatusType);
    };
    BrowserService.prototype.isGeoCapable = function () {
        return (navigator && 'geolocation' in navigator);
    };
    BrowserService.prototype.isGeoPermissionCapable = function () {
        return (navigator && 'permissions' in navigator);
    };
    BrowserService.prototype.enableGeoLocationCompatibility = function () {
        var _this_1 = this;
        console.log(">> enableGeoLocationCompatibility <<");
        return new Promise(function (resolve, reject) {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    _this_1.isGeoEnabled = true;
                    resolve(true);
                }, function (error) {
                    _this_1.isGeoEnabled = false;
                    reject(false);
                }, {
                    timeout: Infinity,
                    maximumAge: 0,
                    enableHighAccuracy: true
                });
            }
            else {
                _this_1.isGeoEnabled = false;
                reject(false);
            }
        });
    };
    BrowserService.prototype.enableGeoPermissionCompatibility = function () {
        var _this_1 = this;
        console.log(">> enableGeoPermissionCompatibility <<");
        return new Promise(function (resolve, reject) {
            if (navigator && 'permissions' in navigator) {
                navigator['permissions'].query({ name: 'geolocation' })
                    .then(function (permissionStatus) {
                    if (permissionStatus.state === "granted") {
                        _this_1.isGeoPermissionStatusEnabled = true;
                        _this_1.onPermissionChanged.next(permissionStatus.state);
                    }
                    permissionStatus.onchange = _this_1.permissionStateChanged.bind(_this_1);
                });
                resolve(true);
            }
            else {
                _this_1.isGeoPermissionStatusEnabled = false;
                _this_1.permissionStatusType = "notAvailable";
                resolve(false);
            }
        });
    };
    return BrowserService;
}());
export { BrowserService };
