import { PhoneCountryCodeService } from './../../../services/phone-country-code.service';
import { Component } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FormBuilder } from '@angular/forms';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { Form } from '../../../models/form.model';
import { TranslatePipe } from '@ngx-translate/core';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AbstractFeature, PrefilledContentEntry } from '../../features/feature.abstract';
import { FormObject } from '../../../models/form-object.model';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-forgot-login',
  templateUrl: './forgot-login.component.html',
  styleUrls: ['./forgot-login.component.scss'],
  providers: [
    TranslatePipe,
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class ForgotLoginComponent extends AbstractFeature {

  public formModel: Form;
  public hideExtraForgotPasswordFields = true;
  public formSuccess = false;

  constructor(
    private translatePipe: TranslatePipe,
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private fb: FormBuilder,
    private validatorFactory: ValidatorFactory,
    private fileUploadService: FileUploadService,
    private navigationService: NavigationService,
    private translateService: CustomTranslateService,
    private phoneCountryCodeService: PhoneCountryCodeService
  ) {
    super(bigAl, theBen);
    this.formModel = new Form(
      this.getFormObject(), this.getRuleObject(),
      this.translatePipe, this.bigAl,
      this.theBen, this.fb,
      this.validatorFactory,
      this.fileUploadService,
      this.translateService,
      this.phoneCountryCodeService
    );
  }

  protected setEventsToWatch(): void {
    this.watchEvent("resetPasswordResult");
    return;
  }
  protected setData(): void {
    // if (this.bigAl.currentResetPasswordResult === true) {
    //   this.formSuccess = true;
    // }
    // else if (this.bigAl.currentResetPasswordResult === false) {
    //   this.formSuccess = false;
    // }
    return;
  }
  protected getData(): void {
    return;
  }
  public isDataAvailable(): boolean {
    return true;
  }

  getFormObject(): FormObject {
    return super.getFormObject("ForgotLoginComponent", new Map<string, PrefilledContentEntry[]>());
  }

  getRuleObject() {
    return super.getRuleObject("ForgotLoginComponent");
  }

  back() {
    // this.bigAl.currentResetPasswordResult = undefined;
    this.navigationService.forward("login", null);
  }
}
