import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpErrorResponse } from '@angular/common/http';

// Third-party
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

// Models
import { AppSettings } from '../models/app-settings.model';
import { ApiResponse } from '../models/api-response.model';
import { UserActionSuccess } from '../models/userActionSuccess.model';
import { IDefaultSettings } from '../sharedtypes/interfaces/sharedtypes.interface';

@Injectable()
export class ApiService {

  private cachedRequests: HttpRequest<any>[] = [];
  private errors = new Subject<HttpErrorResponse>();
  private successes = new Subject<UserActionSuccess>();

  constructor(private http: HttpClient) { }

  // Takes the failed request and puts it in the request cache such that it can be retried at a later time if relevant.
  collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  // #region CRUD operations

  async getAsync<T>(path: string, settings: AppSettings | IDefaultSettings) {
    return this.httpRequestAsync<string, T>("get", path, null, settings);
  }

  async postAsync<X, T>(path: string, model: X, settings: AppSettings | IDefaultSettings) {
    return this.httpRequestAsync<X, T>("post", path, model, settings);
  }

  async putAsync<X, T>(path: string, model: X, settings: AppSettings | IDefaultSettings) {
    return this.httpRequestAsync<X, T>("put", path, model, settings);
  }

  async patchAsync<X, T>(path: string, model: X, settings: AppSettings | IDefaultSettings) {
    return this.httpRequestAsync<X, T>("patch", path, model, settings);
  }

  async deleteAsync<X, T>(path: string, model: X, settings: AppSettings | IDefaultSettings) {
    return this.httpRequestAsync<X, T>("delete", path, model, settings);
  }

  async getFile(path: string, settings: AppSettings | IDefaultSettings) {

    const apiUrl = settings.ApiBaseUrl + path;

    const result = await this.http.get(apiUrl, { responseType: 'blob', observe: 'response' }).subscribe((response) => {

      const blob = new Blob([response.body], { type: 'application/octet-stream' });
      const contentDisposition = response.headers.get('content-disposition');
      let filename = "";

      // From the Content-Disposition reponse header, we retrieve the filename
      if (contentDisposition) {
        let split = contentDisposition.split(";");
        if (split.length >= 2) {
          // We need to sanitize the value, so first we split the value from the key, and then we remove all quotes inside the string to avoid issues when saving the file.
          filename = split[1].split("=")[1].replace(new RegExp('\"', 'g'), '');
        }
      }

      saveAs(blob, filename);
    });

    return result;
  }

  async getFileAsync<T>(location: string) {
    return await this.http.get<T>(location)
      .toPromise()
      .then((data: T) => {
        return data;
      });
  }

  // #endregion

  // #region Error handling

  addError(error: HttpErrorResponse) {
    this.errors.next(error);
  }

  removeError() {
    this.errors.next(null);
  }

  getError(): Observable<HttpErrorResponse> {
    return this.errors.asObservable();
  }

  addSuccess(success: UserActionSuccess) {
    this.successes.next(success);
  }

  removeSuccess() {
    this.successes.next(null);
  }

  getSuccess(): Observable<UserActionSuccess> {
    return this.successes.asObservable();
  }

  // #endregion

  // #region Helpers

  private async httpRequestAsync<X, T>(method: string, path: string, model: X, settings: AppSettings | IDefaultSettings) {

    const apiUrl = settings.ApiBaseUrl + path;

    return await this.getHttpMethod(method, apiUrl, model).pipe(
      map(
        (response: ApiResponse<T>) => {
          if (response.Token) {
            localStorage.setItem('api_token', response.Token);
          }
          return response.Data;
        }))
      .toPromise()
      .then((data: T) => {
        return data;
      });
  }

  private getHttpMethod<X, T>(method: string, apiUrl: string, model: X): Observable<ApiResponse<T>> {
    switch (method) {
      case "delete":
        return this.http.delete<ApiResponse<T>>(apiUrl);
      case "post":
        return this.http.post<ApiResponse<T>>(apiUrl, model);
      case "put":
        return this.http.put<ApiResponse<T>>(apiUrl, model);
      case "patch":
        return this.http.patch<ApiResponse<T>>(apiUrl, model);
      case "get":
        return this.http.get<ApiResponse<T>>(apiUrl);
    }
  }

  // #endregion
}
