import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpErrorResponse, HttpEvent } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ParkingContainer } from "../../models/parking-container.model";
import { Parking } from "../../models/parking.model";

@Injectable({
  providedIn: "root"
})
export class ParkingStore {
  private historyParking: ParkingContainer;
  private activeParking: Parking;
  private pendingParking: Parking;

  private readonly historyParking$: Subject<IStoreResult<ParkingContainer>> = new Subject<IStoreResult<ParkingContainer>>();
  onHistoryParkingStore$ = this.historyParking$.asObservable();

  private readonly activeParking$: Subject<IStoreResult<Parking>> = new Subject<IStoreResult<Parking>>();
  onActiveParkingStore$ = this.activeParking$.asObservable();

  private readonly pendingParking$: Subject<IStoreResult<Parking>> = new Subject<IStoreResult<Parking>>();
  onPendingParkingStore$ = this.pendingParking$.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  reset() {
    this.historyParking$.next({ model: null, isInitial: true });
    this.activeParking$.next({ model: null, isInitial: true });
    this.pendingParking$.next({ model: null, isInitial: true });
    this.historyParking = null;
    this.activeParking = null;
    this.pendingParking = null;
  }

  // #region Requests

  public getInactiveParkings(contractId: string, offset: number, limit: number, forceReload: boolean = true): void {
    // Entry found
    if (this.historyParking && !forceReload) {
      this.historyParking$.next({ model: this.historyParking, isSuccess: true, isCached: true });
      return;
    }

    let url = 'Parking/GetParkingHistory/' + contractId + '/' + offset + '/' + limit;
    // Get entry from database
    this.apiService.getAsync<ParkingContainer>(url, this.settingStore.appSettings)
      .then((data: ParkingContainer) => {
        this.historyParking = data;
        this.historyParking$.next({ model: this.historyParking, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.historyParking = null;
        this.historyParking$.next({ model: this.historyParking, isSuccess: false, error: err });
      });
  }

  public getActiveParking(contractId: string, forceReload: boolean = true): void {
    // Entry found
    if (this.activeParking && !forceReload) {
      this.activeParking$.next({ model: this.activeParking, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    let url = 'Parking/GetActiveParking/' + contractId; // + '/' + offset + '/' + limit;
    this.apiService.getAsync<Parking>(url, this.settingStore.appSettings)
      .then((data: Parking) => {
        this.activeParking = data;
        this.activeParking$.next({ model: this.activeParking, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.activeParking = null;
        this.activeParking$.next({ model: this.activeParking, isSuccess: false, error: err });
      });
  }

  public getPendingParking(contractId: string, forceReload: boolean = true): void {

    // Entry found
    if (this.pendingParking && !forceReload) {
      this.pendingParking$.next({ model: this.pendingParking, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    let url = 'Parking/GetPendingParking/' + contractId; // + '/' + offset + '/' + limit;
    this.apiService.getAsync<Parking>(url, this.settingStore.appSettings)
      .then((data: Parking) => {
        this.pendingParking = data;
        this.pendingParking$.next({ model: this.pendingParking, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.pendingParking = null;
        this.pendingParking$.next({ model: this.pendingParking, isSuccess: false, error: err });
      });
  }

  public sendParkingAnswer(requestId: number, accepted: boolean, isPrivate: boolean, businessParkingProjectId: string): Promise<any> {
    let payload = {
      'RequestId': requestId,
      'ParkingResponse': accepted ? 'ACCEPTED' : 'CANCELLED',
      'BusinessParkingProjectId': businessParkingProjectId
    };

    if (isPrivate != null) {
      payload['ParkingType'] = isPrivate ? 'PRIVATE' : 'BUSINESS';
    }
    let url = 'Parking/ParkingDecision';
    return this.apiService.postAsync(url, payload, this.settingStore.appSettings);
  }
  // #endregion
}
