<div class="car-spinner-wrapper" *ngIf="showSpinner">
    <div class="centered-container">
        <div class="circle">
            <div class="car">
                <mat-icon [svgIcon]="carLogo"></mat-icon>
            </div>
        </div>
    </div>
    <div class="title">
        <p class="company-name">{{companyName}}</p>
        <p>Your personal car assistant</p>
    </div>
    <div class="logo">
        <mat-icon [svgIcon]="logo"></mat-icon>
    </div>
</div>