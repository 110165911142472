import { BigAlModule } from "../modules/bigal.module";
import { AppUserStore } from "../stores/DataStores";

declare var dataLayer: Array<any>;

export class TrackingHelper {

  private userId: string;

  constructor(
    private appUserStore: AppUserStore,
  ) { }

  trackPage(route: string, name: string) {
    try {
      if (!this.isUserSet()) {
        return;
      }

      const simplePath = name.substring(name.lastIndexOf('/') + 1);

      dataLayer.push({
        'sessionID': this.userId,
        'screenPath': route, // Route path
        'screenName': simplePath // Last part of route
      });

      dataLayer.push({ 'event': 'appScreenView' });
    } catch (err) {
    }
  }

  trackClick(action: string, label: string) {
    try {
      if (!this.isUserSet()) {
        return;
      }

      dataLayer.push({
        'sessionID': this.userId,
        'appEventCategory': 'LinkClick',
        'appEventAction': action, // Page/Component - Click button text/id
        'appEventLabel': label // If possible log url we are going to
      });

      dataLayer.push({ 'event': 'appEvent' });
    } catch (err) {
    }
  }

  private isUserSet(): boolean {

    // Try to set userId
    if (!this.userId) {
      this.userId = this.appUserStore.appUser.AuthenticationUserId;
    }

    // If AuthenticationUserId is still not set skip tracking
    if (!this.userId) {
      return false;
    }

    return true;
  }

}
