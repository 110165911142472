import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppUser } from './../../models/appuser.model';
import { AbstractFeature } from '../../components/features/feature.abstract';
import { BigAlModule } from '../../modules/bigal.module';
import { ScreenService } from '../../services/screen.service';
import { ComponentConfigService } from '../../services/componentConfig.service';
import { UserRoleType } from '../../models/enums/user-role-type.enum';
import { FleetUserService } from '../../services/fleet-user.service';
import { NavigationService } from '../../services/navigation.service';
import { AppSettingsStore, AppUserStore } from '../../stores/DataStores';
import { Constants } from '../../helpers/constants';
import { IStoreResult } from '../../sharedtypes/interfaces/sharedtypes.interface';

@Component({
  selector: "menu-component",
  templateUrl: "./menu.component.html"
})
export class MenuComponent extends AbstractFeature implements OnInit, OnDestroy {
  public user: AppUser = null;
  public showRoleDriver = false;
  public showRoleFleetUser = false;
  public showRoleBusinessUser = false;
  private isInTakeOverMode = false;
  public menuRole: UserRoleType = UserRoleType.Driver;
  public showRolesPicker = false;
  public currentLang: string = null;

  constructor(
    public screenService: ScreenService,
    public theBen: ComponentConfigService,
    public bigAl: BigAlModule,
    public fleetUserService: FleetUserService,
    private navigationService: NavigationService,
    private settingsStore: AppSettingsStore,
    private appUserStore: AppUserStore,

  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.watchEvent(Constants.Event_currentUser);
    this.watchEvent('logout');

    this.fleetUserService.isInTakeOverMode.subscribe(isInTakeOverMode => {
      this.isInTakeOverMode = isInTakeOverMode;
      this.clearMenuRoles();
      this.pickMenu();
    });

    return;
  }

  protected setData(): void {
    this.registerSubscription();

    this.setLanguage();

    this.user = this.appUserStore.appUser;
    this.showRolesPicker = !this.bigAl.appSettings.IsApp;
    this.pickMenu();
  }

  protected getData(): void {
    this.setData();
  }

  isDataAvailable(): boolean {
    return true;
  }

  pickMenu(): void {
    let roles: UserRoleType[] = [];
    this.bigAl.showAdminButtons = false;

    if (!this.user || this.user.Roles.length < 1) {
      roles = this.bigAl.getPreviousUserRoles();
    } else {
      roles = this.user.Roles;
    }

    if (roles.some(ur => ur === UserRoleType.Anonymous)) {
      this.clearMenuRoles();
    }

    if (roles.some(ur => ur === UserRoleType.Driver) || this.isInTakeOverMode) {
      this.menuRole = UserRoleType.Driver;
      this.showRoleDriver = true;
      if (roles.some(ur => ur === UserRoleType.SystemAdminFleetAdmin)) {
        this.bigAl.showAdminButtons = true;
      }
    } else if (roles.some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager)) {
      this.menuRole = UserRoleType.FleetAdmin;
      this.showRoleFleetUser = true;
    } else if (roles.some(ur => ur === UserRoleType.SystemAdminDriver)) {
      this.menuRole = UserRoleType.Driver;
      this.bigAl.showAdminButtons = true;
    } else if (roles.some(ur => ur === UserRoleType.SystemAdminFleetAdmin)) {
      this.menuRole = UserRoleType.FleetAdmin;
      this.bigAl.showAdminButtons = true;
      this.showRoleFleetUser = true;
    } else if (roles.some(ur => ur === UserRoleType.BusinessUser)) {
      this.menuRole = UserRoleType.BusinessUser;
      this.showRoleBusinessUser = true;
      this.bigAl.showAdminButtons = true;
    } else if (roles.some(ur => ur === UserRoleType.SuperAdmin)) {
      this.menuRole = UserRoleType.SuperAdmin;
      this.showRoleBusinessUser = true;
      this.bigAl.showAdminButtons = true;
    } else if (roles.some(ur => ur === UserRoleType.DevUser_Driver)) {
      this.menuRole = UserRoleType.Driver;
      this.showRoleDriver = true;
    } else if (roles.some(ur => ur === UserRoleType.DevUser_Admin)) {
      this.menuRole = UserRoleType.FleetAdmin;
      this.bigAl.showAdminButtons = true;
      this.showRoleBusinessUser = true;
    } else if (roles.some(ur => ur === UserRoleType.DevUser_Business)) {
      this.menuRole = UserRoleType.BusinessUser;
      this.bigAl.showAdminButtons = true;
      this.showRoleFleetUser = true;
    }
  }

  clearMenuRoles() {
    this.showRoleBusinessUser = false;
    this.showRoleFleetUser = false;
    this.showRoleDriver = false;
  }

  toggleDriverMenu() {
    if (this.showRoleDriver && this.showRoleFleetUser && this.menuRole !== UserRoleType.Driver) {
      this.menuRole = UserRoleType.Driver;
      this.navigationService.forward("cars", null);
    }
  }

  toggleFleetUserMenu() {
    if (this.showRoleDriver && this.showRoleFleetUser && this.menuRole !== UserRoleType.FleetAdmin) {
      this.menuRole = UserRoleType.FleetAdmin;
      this.navigationService.forward("fleet/overview", null);
    }
  }

  showDriverMenu(): boolean {
    return this.menuRole === UserRoleType.Driver || this.menuRole === UserRoleType.DevUser_Driver;
  }

  showFleetUserMenu(): boolean {
    return this.menuRole === UserRoleType.FleetAdmin || this.menuRole === UserRoleType.DevUser_Admin;
  }

  showBusinessUserMenu(): boolean {
    return this.menuRole === UserRoleType.BusinessUser || this.menuRole === UserRoleType.DevUser_Business || this.menuRole === UserRoleType.SuperAdmin;
  }

  private registerSubscription() {
    super.subscribe(this.settingsStore.onLanguageChanged$.subscribe((response: IStoreResult<string>) => {
      if (response && !response.isInitial && response.isSuccess) {
        this.currentLang = response.model;
      } else {
        if (response.error) {
          this.currentLang = null;
        }
      }
    }));
  }

  changeLanguage(languageCode: string) {
    this.currentLang = languageCode;
    this.settingsStore.changeLanguage(languageCode);
  }

  private setLanguage() {
    if (localStorage.getItem(Constants.LocalStorage_Language)) {
      this.currentLang = localStorage.getItem(Constants.LocalStorage_Language);
    }
    else {
      this.currentLang = this.settingsStore.appSettings.LanguageCode;
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
