import { OnDestroy, OnInit } from '@angular/core';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AppSettingsStore } from '../../../stores/DataStores';
import { map } from 'rxjs/operators';
var AppMaintenanceNoticeComponent = /** @class */ (function () {
    function AppMaintenanceNoticeComponent(translateService, settingsStore) {
        this.translateService = translateService;
        this.settingsStore = settingsStore;
    }
    AppMaintenanceNoticeComponent.prototype.ngOnInit = function () {
    };
    AppMaintenanceNoticeComponent.prototype.getMaintenanceMessage = function () {
        var _this = this;
        if (this.isTranslationLoaded) {
            var maintenance_1 = JSON.parse(localStorage.getItem('maintenance'));
            return this.translateService.get("AppMaintenanceNoticeComponent.body_t").pipe(map(function (text) {
                var localeId = _this.settingsStore.getLocalId();
                var maintenanceStart = new Date(maintenance_1[1]).toLocaleDateString(localeId) + " " + new Date(maintenance_1[1]).toLocaleTimeString(localeId, { hour: '2-digit', minute: '2-digit' });
                var maintenanceEnd = new Date(maintenance_1[2]).toLocaleDateString(localeId) + " " + new Date(maintenance_1[2]).toLocaleTimeString(localeId, { hour: '2-digit', minute: '2-digit' });
                var maintenanceText = text.replace("{0}", maintenanceStart).replace("{1}", maintenanceEnd);
                return maintenanceText;
            }));
        }
    };
    AppMaintenanceNoticeComponent.prototype.isTranslationLoaded = function () {
        if ((this.settingsStore.appSettings.CompanyId == this.translateService.aldCompanyId && this.translateService.aldTranslations.length > 0)) {
            return true;
        }
        return false;
    };
    AppMaintenanceNoticeComponent.prototype.ngOnDestroy = function () {
    };
    return AppMaintenanceNoticeComponent;
}());
export { AppMaintenanceNoticeComponent };
