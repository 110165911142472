/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
function View_SpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).value; var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "loading-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_SpinnerComponent_3(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLodingIndicator; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showLoadingMessage && !_co.didRequestFail); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.didRequestFail; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 638976, null, 0, i6.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerComponentNgFactory = i1.ɵccf("app-spinner", i6.SpinnerComponent, View_SpinnerComponent_Host_0, { isDoneLoading: "isDoneLoading", didRequestFail: "didRequestFail", startAgain: "startAgain" }, {}, ["[error-message]"]);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
