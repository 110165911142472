import { Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var ParkingStore = /** @class */ (function () {
    function ParkingStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.historyParking$ = new Subject();
        this.onHistoryParkingStore$ = this.historyParking$.asObservable();
        this.activeParking$ = new Subject();
        this.onActiveParkingStore$ = this.activeParking$.asObservable();
        this.pendingParking$ = new Subject();
        this.onPendingParkingStore$ = this.pendingParking$.asObservable();
    }
    ParkingStore.prototype.reset = function () {
        this.historyParking$.next({ model: null, isInitial: true });
        this.activeParking$.next({ model: null, isInitial: true });
        this.pendingParking$.next({ model: null, isInitial: true });
        this.historyParking = null;
        this.activeParking = null;
        this.pendingParking = null;
    };
    // #region Requests
    ParkingStore.prototype.getInactiveParkings = function (contractId, offset, limit, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = true; }
        // Entry found
        if (this.historyParking && !forceReload) {
            this.historyParking$.next({ model: this.historyParking, isSuccess: true, isCached: true });
            return;
        }
        var url = 'Parking/GetParkingHistory/' + contractId + '/' + offset + '/' + limit;
        // Get entry from database
        this.apiService.getAsync(url, this.settingStore.appSettings)
            .then(function (data) {
            _this.historyParking = data;
            _this.historyParking$.next({ model: _this.historyParking, isSuccess: true });
        })
            .catch(function (err) {
            _this.historyParking = null;
            _this.historyParking$.next({ model: _this.historyParking, isSuccess: false, error: err });
        });
    };
    ParkingStore.prototype.getActiveParking = function (contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = true; }
        // Entry found
        if (this.activeParking && !forceReload) {
            this.activeParking$.next({ model: this.activeParking, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        var url = 'Parking/GetActiveParking/' + contractId; // + '/' + offset + '/' + limit;
        this.apiService.getAsync(url, this.settingStore.appSettings)
            .then(function (data) {
            _this.activeParking = data;
            _this.activeParking$.next({ model: _this.activeParking, isSuccess: true });
        })
            .catch(function (err) {
            _this.activeParking = null;
            _this.activeParking$.next({ model: _this.activeParking, isSuccess: false, error: err });
        });
    };
    ParkingStore.prototype.getPendingParking = function (contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = true; }
        // Entry found
        if (this.pendingParking && !forceReload) {
            this.pendingParking$.next({ model: this.pendingParking, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        var url = 'Parking/GetPendingParking/' + contractId; // + '/' + offset + '/' + limit;
        this.apiService.getAsync(url, this.settingStore.appSettings)
            .then(function (data) {
            _this.pendingParking = data;
            _this.pendingParking$.next({ model: _this.pendingParking, isSuccess: true });
        })
            .catch(function (err) {
            _this.pendingParking = null;
            _this.pendingParking$.next({ model: _this.pendingParking, isSuccess: false, error: err });
        });
    };
    ParkingStore.prototype.sendParkingAnswer = function (requestId, accepted, isPrivate, businessParkingProjectId) {
        var payload = {
            'RequestId': requestId,
            'ParkingResponse': accepted ? 'ACCEPTED' : 'CANCELLED',
            'BusinessParkingProjectId': businessParkingProjectId
        };
        if (isPrivate != null) {
            payload['ParkingType'] = isPrivate ? 'PRIVATE' : 'BUSINESS';
        }
        var url = 'Parking/ParkingDecision';
        return this.apiService.postAsync(url, payload, this.settingStore.appSettings);
    };
    ParkingStore.ngInjectableDef = i0.defineInjectable({ factory: function ParkingStore_Factory() { return new ParkingStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: ParkingStore, providedIn: "root" });
    return ParkingStore;
}());
export { ParkingStore };
