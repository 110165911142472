import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../../services/spinner.service';
var LoadingSpinnerComponent = /** @class */ (function () {
    function LoadingSpinnerComponent(spinnerService) {
        this.spinnerService = spinnerService;
        this.showSpinner = false;
        this.subscription = new Subscription();
    }
    LoadingSpinnerComponent.prototype.ngOnInit = function () { };
    LoadingSpinnerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscription.add(this.spinnerService.spinnerState
            .subscribe(function (state) {
            _this.showSpinner = state;
        }));
    };
    LoadingSpinnerComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return LoadingSpinnerComponent;
}());
export { LoadingSpinnerComponent };
