import 'rxjs/add/operator/do';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BigAlModule } from '../modules/bigal.module';
import { ApiService } from '../services/api.service';
import { SpinnerService } from '../services/spinner.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap, share, retryWhen } from 'rxjs/operators';
var ApiInterceptor = /** @class */ (function () {
    function ApiInterceptor(bigAl, apiService, spinnerService, httpClient) {
        this.bigAl = bigAl;
        this.apiService = apiService;
        this.spinnerService = spinnerService;
        this.httpClient = httpClient;
        this.retryRequest = Symbol('reload');
    }
    ApiInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        // IMPORTANT - DO NOT REMOVE AS THIS HANDLES CATCHING THE 401 ON EVERY DIFFERENT REQUEST
        this.alreadyTriedToRefresh = false;
        var headers = null;
        var isInternalRequest = this.bigAl.isInternalRequest(request.url);
        if (isInternalRequest && this.bigAl.getAuthorizationHeader() && !this.bigAl.getTraceHeader()) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.bigAl.getAuthorizationHeader()
            });
        }
        if (isInternalRequest && this.bigAl.getAuthorizationHeader() && this.bigAl.getTraceHeader()) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.bigAl.getAuthorizationHeader(),
                'TraceId': this.bigAl.getTraceHeader()
            });
        }
        if (!headers) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        var interceptReq = request.clone({
            headers: headers
        });
        var request$ = new Observable(function (observer) {
            observer.next(interceptReq);
            observer.complete();
        });
        return request$.pipe(switchMap(function (req) {
            // CLONE REQUEST AFTER TOKEN REFRESHED TO SEND THE NEW AUTHORIZATION HEADER
            if (_this.alreadyTriedToRefresh && !req.urlWithParams.includes('tokens/refresh')) {
                var newHeaders = new HttpHeaders({
                    "Content-Type": "application/json",
                    "Authorization": _this.bigAl.getAuthorizationHeader(),
                    "TraceId": _this.bigAl.currentTraceId
                });
                var clonedReq = req.clone({
                    headers: newHeaders
                });
                return next.handle(clonedReq);
            }
            // ONLY FOR TESTING TO FAKE A 401
            // if (req.urlWithParams.includes('MileageUpdate') && !this.bigAl.testTokenRefresh) {
            //     this.bigAl.testTokenRefresh = true;
            //     let newHeaders = new HttpHeaders(
            //         {
            //             "Content-Type": "application/json",
            //             "Authorization": "Token saas",
            //             "TraceId": this.bigAl.currentTraceId
            //         }
            //     );
            //     let clonedReq = req.clone({
            //         headers: newHeaders
            //     });
            //     return next.handle(clonedReq);
            // }
            // if (req.urlWithParams.includes('vehicle') && this.bigAl.testTokenRefresh) {
            //     this.bigAl.testTokenRefresh = false;
            // }
            return next.handle(req);
        }), catchError(function (err) {
            if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                    case 401:
                        var token = _this.bigAl.getRefreshKey();
                        // CATCH SECOND TRY TO REFRESH TOKEN ON THE SAME REQUEST
                        if (_this.alreadyTriedToRefresh || err.url.includes('logout')) {
                            _this.alreadyTriedToRefresh = false;
                            _this.bigAl.logout();
                            throw err;
                        }
                        else if (token) {
                            var path = _this.bigAl.appSettings.ApiBaseUrl + "tokens/refresh/" + token;
                            // SEND REQUEST TO REFRESH TOKEN
                            return _this.httpClient.get(path, { headers: headers })
                                .pipe(tap(function (newToken) {
                                if (newToken.Data) {
                                    _this.bigAl.setDataForRequestHeaders(newToken.Token);
                                    _this.bigAl.setCurrentUser(newToken.Data);
                                    throw _this.retryRequest;
                                }
                                else {
                                    _this.bigAl.logout();
                                }
                            }), share());
                        }
                        else {
                            throw err;
                        }
                    case 403:
                        if (err.url.includes('tokens/refresh')) {
                            _this.alreadyTriedToRefresh = true;
                            _this.bigAl.logoutAndClearSession();
                            return new Observable();
                        }
                        else {
                            _this.apiService.collectFailedRequest(request);
                            _this.spinnerService.hide();
                            if (request.method !== "GET") {
                                _this.apiService.addError(err);
                            }
                            throw err;
                        }
                    default:
                        _this.apiService.collectFailedRequest(request);
                        _this.spinnerService.hide();
                        if (request.method !== "GET") {
                            _this.apiService.addError(err);
                        }
                        throw err;
                }
            }
            else {
                throw err;
            }
        }), retryWhen(function (err$) {
            return err$.pipe(tap(function (err) {
                if (err === _this.retryRequest && !_this.alreadyTriedToRefresh) {
                    _this.alreadyTriedToRefresh = true;
                    return;
                }
                throw err;
            }));
        }));
    };
    return ApiInterceptor;
}());
export { ApiInterceptor };
