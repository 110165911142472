import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUserStore, DocumentStore } from '../../../stores/DataStores';
import { BigAlModule } from '../../../modules/bigal.module';
import { FleetUserService } from '../../../services/fleet-user.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleUtil } from '../../../helpers/locale.util';
import { ScreenService } from '../../../services/screen.service';
import { MatPaginator } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
var GloveboxComponent = /** @class */ (function () {
    function GloveboxComponent(documentStore, bigAl, fleetUserService, translateService, screenService, appUserStore) {
        this.documentStore = documentStore;
        this.bigAl = bigAl;
        this.fleetUserService = fleetUserService;
        this.translateService = translateService;
        this.screenService = screenService;
        this.appUserStore = appUserStore;
        this.rowstoAdd = 10;
        this.subscription = new Subscription();
        this.gloveboxInvoiceContainers = [];
        this.gloveboxInvoiceContainersOriginal = [];
        this.documents = [];
        this.isDoneLoading = false;
        this.didFail = false;
        this.showTakeOverBar = false;
        this.offset = 0;
        this.limit = 10;
        this.displayedColumns = ['Name', 'CreateDate', 'Download'];
        this.searchForm = new FormGroup({
            searchField: new FormControl([''])
        });
    }
    GloveboxComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Setup subscriptions
        this.subscription.add(this.documentStore.onGetDocuments$.subscribe(function (payload) {
            if (payload.isSuccess && !payload.isInitial) {
                _this.documents = payload.model;
                _this.transformDocument();
                _this.gloveboxInvoiceContainersOriginal = Object.assign([], _this.gloveboxInvoiceContainers);
                _this.isDoneLoading = true;
            }
            else if (!payload.isSuccess && payload.error) {
                _this.didFail = true;
                if (payload.error['error']['Message'].toUpperCase().includes('NFO0047')) {
                    _this.bigAl.logout();
                }
            }
        }));
        this.isApp = this.bigAl.appSettings.IsApp;
        this.subscription.add(this.fleetUserService.isInTakeOverMode.subscribe(function (isInTakeOverMode) {
            _this.showTakeOverBar = isInTakeOverMode;
        }));
        // Fetch data
        this.documentStore.getDocuments(this.appUserStore.appUser.AuthenticationUserId, true);
    };
    GloveboxComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    GloveboxComponent.prototype.downloadFile = function (gloveboxInvoice) {
        var token = localStorage.getItem("api_token");
        if (!token) {
            return;
        }
        window.open(this.bigAl.appSettings.ApiBaseUrl + "file/Download/" + gloveboxInvoice.Id + "?Authorization=" + token, "_blank");
    };
    GloveboxComponent.prototype.transformDocument = function () {
        this.gloveboxInvoiceContainers = [];
        for (var _i = 0, _a = this.documents; _i < _a.length; _i++) {
            var document_1 = _a[_i];
            var exists = false;
            for (var _b = 0, _c = this.gloveboxInvoiceContainers; _b < _c.length; _b++) {
                var gloveboxInvoiceContainer = _c[_b];
                if (document_1.SortingVariables.BusinessPartnerId === gloveboxInvoiceContainer.BusinessPartnerId &&
                    document_1.SortingVariables.BusinessPartnerName === gloveboxInvoiceContainer.BusinessPartnerName) {
                    exists = true;
                    var gloveboxInvoice = {
                        'CreateDate': new Date(document_1.CreateDate),
                        'FileName': document_1.FileName,
                        'Id': document_1.Id,
                        'MediaHeaderType': document_1.MediaHeaderType
                    };
                    gloveboxInvoiceContainer.GloveboxInvoices.push(gloveboxInvoice);
                }
            }
            if (!exists) {
                var gloveboxInvoice = {
                    'CreateDate': new Date(document_1.CreateDate),
                    'FileName': document_1.FileName,
                    'Id': document_1.Id,
                    'MediaHeaderType': document_1.MediaHeaderType
                };
                var gloveboxInvoiceContainer = {
                    'GloveboxInvoices': [gloveboxInvoice],
                    'BusinessPartnerId': document_1.SortingVariables.BusinessPartnerId,
                    'BusinessPartnerName': document_1.SortingVariables.BusinessPartnerName,
                    'invoicesToShow': this.rowstoAdd
                };
                this.gloveboxInvoiceContainers.push(gloveboxInvoiceContainer);
            }
        }
        // Sorting all the gloveboxInvoiceContainers by partner name
        this.gloveboxInvoiceContainers = this.gloveboxInvoiceContainers.sort(this.sortGloveboxInvoiceContainers);
        for (var _d = 0, _e = this.gloveboxInvoiceContainers; _d < _e.length; _d++) {
            var gloveboxInvoiceContainer = _e[_d];
            // Sorting all the gloveboxInvoice by create date
            gloveboxInvoiceContainer.GloveboxInvoices = gloveboxInvoiceContainer.GloveboxInvoices.sort(this.sortGloveboxInvoices);
        }
    };
    GloveboxComponent.prototype.showMore = function (gloveboxInvoiceContainer) {
        gloveboxInvoiceContainer.invoicesToShow += this.rowstoAdd;
    };
    GloveboxComponent.prototype.sortGloveboxInvoiceContainers = function (obj1, obj2) {
        if (obj1.BusinessPartnerName > obj2.BusinessPartnerName) {
            return 1;
        }
        else if (obj1.BusinessPartnerName < obj2.BusinessPartnerName) {
            return -1;
        }
        return 0;
    };
    GloveboxComponent.prototype.sortGloveboxInvoices = function (obj1, obj2) {
        if (obj1.CreateDate < obj2.CreateDate) {
            return 1;
        }
        else if (obj1.CreateDate > obj2.CreateDate) {
            return -1;
        }
        return 0;
    };
    GloveboxComponent.prototype.getLocaleDate = function (date) {
        return LocaleUtil.getLocaleShortDate(date, this.translateService.currentLang);
    };
    GloveboxComponent.prototype.changePage = function (page) {
        this.offset = this.limit * page.pageIndex;
    };
    GloveboxComponent.prototype.search = function () {
        var searchText = (this.searchForm.controls['searchField'].value + '').toLowerCase();
        this.offset = 0;
        if (this.paginator) {
            this.paginator.firstPage();
        }
        this.gloveboxInvoiceContainers = this.gloveboxInvoiceContainersOriginal.filter(function (x) { return x.BusinessPartnerName.toLowerCase().indexOf(searchText) >= 0 || x.BusinessPartnerId.indexOf(searchText) >= 0; });
    };
    return GloveboxComponent;
}());
export { GloveboxComponent };
