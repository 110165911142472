<span *ngIf="updateTranslations" class="translate-span" (click)="loadTranslation()">
  <ng-container *ngTemplateOutlet="translateContent"></ng-container>
</span>

<span *ngIf="!updateTranslations">
  <ng-container *ngTemplateOutlet="translateContent"></ng-container>
</span>

<ng-template #translateContent>
  <ng-content select="[translate-text]"></ng-content>
</ng-template>