import { UserRoleType } from './../../../models/enums/user-role-type.enum';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { ScreenService } from '../../../services/screen.service';
import { Component, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { AbstractFeature } from '../../features/feature.abstract';
import { AppUserStore } from '../../../stores/DataStores';

@Component({
  selector: "menu-fleet-user-component",
  templateUrl: "./menu-fleet-user.component.html"
})
export class MenuFleetUserComponent extends AbstractFeature implements AfterViewChecked {

  public isFleetAdmin = false;
  private anchorMovedOnInit = false;

  @ViewChild("decorativeAnchor") private decorativeAnchor: ElementRef;

  constructor(
    public screenService: ScreenService,
    private element: ElementRef,
    public theBen: ComponentConfigService,
    public bigAl: BigAlModule,
    private appUserStore: AppUserStore,
  ) {
    super(bigAl, theBen);
  }

  ngAfterViewChecked() {
    if (
      !this.anchorMovedOnInit &&
      this.element.nativeElement.querySelector(".active a")
    ) {
      this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
      this.anchorMovedOnInit = true;
    }
  }

  protected setEventsToWatch(): void {
    this.watchEvent('userLogin');
  }

  protected setData(): void {
    this.isFleetAdmin = this.appUserStore.appUser.Roles.some((role: UserRoleType) => role === UserRoleType.FleetAdmin || role === UserRoleType.SystemAdminFleetAdmin || role === UserRoleType.DevUser_Admin);
  }

  protected getData(): void {
    this.isFleetAdmin = this.appUserStore.appUser.Roles.some((role: UserRoleType) => role === UserRoleType.FleetAdmin || role === UserRoleType.SystemAdminFleetAdmin || role === UserRoleType.DevUser_Admin);
  }

  public isDataAvailable(): boolean {
    return true;
  }

  // EventHandler
  public onClick($event) {
    this.moveAnchor($event.target);
  }

  private moveAnchor(target) {
    let itemTarget = this.findAncestorItem(target);
    this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
  }

  private findAncestorItem(el) {
    while ((el = el.parentNode) && !el.classList.contains("item")) { }
    return el;
  }
}
