var WorkshopBooking = /** @class */ (function () {
    function WorkshopBooking(ContractId, WorkshopId, CurrentMileage, Date, Time, WorkToBeDone, WantsReplacementCar, PickupStatus, Name, Street, PostalCode, City, Phone, Email, Service, DeliverCarAtSupplier, OrderDate, RepairTypeId) {
        if (ContractId === void 0) { ContractId = null; }
        if (WorkshopId === void 0) { WorkshopId = null; }
        if (CurrentMileage === void 0) { CurrentMileage = 0; }
        if (Date === void 0) { Date = null; }
        if (Time === void 0) { Time = null; }
        if (WorkToBeDone === void 0) { WorkToBeDone = null; }
        if (WantsReplacementCar === void 0) { WantsReplacementCar = false; }
        if (PickupStatus === void 0) { PickupStatus = null; }
        if (Name === void 0) { Name = null; }
        if (Street === void 0) { Street = null; }
        if (PostalCode === void 0) { PostalCode = null; }
        if (City === void 0) { City = null; }
        if (Phone === void 0) { Phone = null; }
        if (Email === void 0) { Email = null; }
        if (Service === void 0) { Service = null; }
        if (DeliverCarAtSupplier === void 0) { DeliverCarAtSupplier = null; }
        if (OrderDate === void 0) { OrderDate = null; }
        if (RepairTypeId === void 0) { RepairTypeId = null; }
        this.ContractId = ContractId;
        this.WorkshopId = WorkshopId;
        this.CurrentMileage = CurrentMileage;
        this.Date = Date;
        this.Time = Time;
        this.WorkToBeDone = WorkToBeDone;
        this.WantsReplacementCar = WantsReplacementCar;
        this.PickupStatus = PickupStatus;
        this.Name = Name;
        this.Street = Street;
        this.PostalCode = PostalCode;
        this.City = City;
        this.Phone = Phone;
        this.Email = Email;
        this.Service = Service;
        this.DeliverCarAtSupplier = DeliverCarAtSupplier;
        this.OrderDate = OrderDate;
        this.RepairTypeId = RepairTypeId;
    }
    return WorkshopBooking;
}());
export { WorkshopBooking };
export var PickupStatus;
(function (PickupStatus) {
    PickupStatus[PickupStatus["DriveYourself"] = 0] = "DriveYourself";
    PickupStatus[PickupStatus["CompanyAddress"] = 1] = "CompanyAddress";
    PickupStatus[PickupStatus["Address"] = 2] = "Address";
})(PickupStatus || (PickupStatus = {}));
export var ServiceType;
(function (ServiceType) {
    ServiceType[ServiceType["Undefined"] = 0] = "Undefined";
    ServiceType[ServiceType["OrdinaryService"] = 1] = "OrdinaryService";
    ServiceType[ServiceType["Repair"] = 2] = "Repair";
})(ServiceType || (ServiceType = {}));
/**Model from 2.0. for serviceLinkBookingWithPickup
 * constructor(
    public shouldSendToCompanyAddress: boolean,
    public contractId: string,
    public workshopId: string,
    public currentMileage: string,
    public date: string,
    public street: string,
    public city: string,
    public zipcode: string,
    public name: string,
    public phone: string,
    public email: string,
    public workToBeDone: string,
    public wantsReplacementCar: boolean
  ) { }
 */
