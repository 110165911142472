import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AppUserStore } from '../../../stores/DataStores';

@Component({
  selector: 'app-leave-page-warning',
  templateUrl: './leave-page-warning.component.html',
  styleUrls: ['./leave-page-warning.component.scss']
})
export class LeavePageWarningComponent implements OnInit {
  type: string;
  content: string;

  constructor(
    protected userStore: AppUserStore,
    protected theBen: ComponentConfigService,
    private dialogRef: MatDialogRef<LeavePageWarningComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.type = data.type;
  }

  ngOnInit() {
  }



  yes() {

    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }

}
