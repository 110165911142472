var InsuranceClaimFinish = /** @class */ (function () {
    function InsuranceClaimFinish(RegistrationNumber, //
    Driver, CompanyName, CompanyAddress, Email, Phone, //
    AccidentLocation, AccidentTime, PoliceStationJounalNumber, VehicleDamageDescription, DescriptionOfAccident, GiveAway, LaneShift, Reversing, LeftTurn, RigthTurn, WhoIsToBlame, //
    CounterPartName, CounterPartAddress, CounterPartPhone, CounterPartRegistrationNumber, CounterPartInsuranceCompany, CounterPartCarDamageDescription, CounterPartMultiple, WitnessName, WitnessAddress, WitnessPhone, WitnessLocation, InjuredPersonsNameAndAddress, InjuredPersonsCprNumbers, InjuredPersonsPhoneNumbers, InjuredPersonsInjuryDescription, MiscDamagesDescription, Attachments, CprNumber, AccidentDate, AccidentDuringWorkHours, DayLight, DrivesLicence, PoliceReport, DrugTest, DriverInjury, PassengerInjury, CounterPartInjury) {
        this.RegistrationNumber = RegistrationNumber;
        this.Driver = Driver;
        this.CompanyName = CompanyName;
        this.CompanyAddress = CompanyAddress;
        this.Email = Email;
        this.Phone = Phone;
        this.AccidentLocation = AccidentLocation;
        this.AccidentTime = AccidentTime;
        this.PoliceStationJounalNumber = PoliceStationJounalNumber;
        this.VehicleDamageDescription = VehicleDamageDescription;
        this.DescriptionOfAccident = DescriptionOfAccident;
        this.GiveAway = GiveAway;
        this.LaneShift = LaneShift;
        this.Reversing = Reversing;
        this.LeftTurn = LeftTurn;
        this.RigthTurn = RigthTurn;
        this.WhoIsToBlame = WhoIsToBlame;
        this.CounterPartName = CounterPartName;
        this.CounterPartAddress = CounterPartAddress;
        this.CounterPartPhone = CounterPartPhone;
        this.CounterPartRegistrationNumber = CounterPartRegistrationNumber;
        this.CounterPartInsuranceCompany = CounterPartInsuranceCompany;
        this.CounterPartCarDamageDescription = CounterPartCarDamageDescription;
        this.CounterPartMultiple = CounterPartMultiple;
        this.WitnessName = WitnessName;
        this.WitnessAddress = WitnessAddress;
        this.WitnessPhone = WitnessPhone;
        this.WitnessLocation = WitnessLocation;
        this.InjuredPersonsNameAndAddress = InjuredPersonsNameAndAddress;
        this.InjuredPersonsCprNumbers = InjuredPersonsCprNumbers;
        this.InjuredPersonsPhoneNumbers = InjuredPersonsPhoneNumbers;
        this.InjuredPersonsInjuryDescription = InjuredPersonsInjuryDescription;
        this.MiscDamagesDescription = MiscDamagesDescription;
        this.Attachments = Attachments;
        this.CprNumber = CprNumber;
        this.AccidentDate = AccidentDate;
        this.AccidentDuringWorkHours = AccidentDuringWorkHours;
        this.DayLight = DayLight;
        this.DrivesLicence = DrivesLicence;
        this.PoliceReport = PoliceReport;
        this.DrugTest = DrugTest;
        this.DriverInjury = DriverInjury;
        this.PassengerInjury = PassengerInjury;
        this.CounterPartInjury = CounterPartInjury;
    }
    return InsuranceClaimFinish;
}());
export { InsuranceClaimFinish };
/*
DayLight = null
*/
