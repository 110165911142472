import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MapsAPILoader } from '@agm/core';
import BackgroundGeolocation, { Location, Config } from "cordova-background-geolocation-lt";

// Common

// Types
import { AldPosition } from "../../models/geo-tracking-device-motion/ald-position.model";

// Third-party global variables
declare const google: any;

@Injectable({
  providedIn: "root"
})
export class GeoLocationService {

  private onNewPostion = new BehaviorSubject<Location>(null);
  onNewPostion$ = this.onNewPostion.asObservable();

  protected isTracking: boolean | null;
  protected isRecording: boolean;
  protected isWaitingForAutomaticStop: boolean;

  private geoCoder = null;
  private recordedPositions: AldPosition[] = [];
  private readonly locationConfig: Config;
  private readonly isCordovaEnabled: boolean;

  // Used for ensureing iOS start to track ASAP and not after 1000meters which is the minimum fence distance it sets
  private readonly geoFenceIdentifer: string = "start-position";
  private lastKnownLocation: Location;
  private isGeofenceSet: boolean;

  constructor(
    public mapLoader: MapsAPILoader) {

    this.isCordovaEnabled = typeof window["cordova"] !== "undefined";
    if (this.isCordovaEnabled) {
      this.locationConfig = {
        reset: true,
        debug: false,
        logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
        logMaxDays: 3,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION,
        autoSync: false,
        stopOnTerminate: false,
        startOnBoot: true,
        preventSuspend: true,
        distanceFilter: 5, // meters
        heartbeatInterval: 60, // seconds
        geofenceProximityRadius: 1000, // meters (this is the minimum that can be set)

        // iOS only
        stationaryRadius: 25, // meters
        activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
      };
    }
    this.loadMap();
  }

  loadMap() {
    console.warn('loading geocoder');
    this.mapLoader.load().then(() => this.geoCoder = new google.maps.Geocoder());
    this.isTracking = null;
    this.isRecording = false;
    this.isGeofenceSet = false;
    this.isWaitingForAutomaticStop = false;

  }

  initGEOService(): void {

    if (!this.isCordovaEnabled) {
      console.warn("Did not start GeoLocationService since we are not in an cordova environment");
      return;
    }

    this.clearPositions();

    try {
      BackgroundGeolocation.onLocation((location: Location) => {

        if (location && location.coords) {
          this.lastKnownLocation = location;
        }

        // if (!this.isWaitingForAutomaticStop) {
        this.onNewPostion.next(location);

        if (this.isRecording) {

          const mappedPosition: AldPosition = this.locationToAldPosition(location);
          if (mappedPosition && this.noCoordinateErrors(mappedPosition)) {
            this.recordedPositions.push(mappedPosition);
          }
        }
        // }
      });

      BackgroundGeolocation.ready(this.locationConfig,
        (state) => {
          if (!state.enabled) {
            BackgroundGeolocation.start();
          }
        },
        (error) => {
          this.isTracking = false;
        });
    } catch (err) {
    }
  }

  isServiceTracking(): boolean {
    return this.isTracking;
  }

  isServiceRecording(): boolean {
    return this.isRecording;
  }

  startTracking(): void {
    try {

      if (!this.isTracking) {
        this.startGeolocationTracking();
      }

      this.isRecording = true;
    } catch (err) {
    }
  }

  startAutomaticStop(): void {
    this.isWaitingForAutomaticStop = true;
  }

  WaitingForAutomaticStop(): boolean {
    return this.isWaitingForAutomaticStop;
  }

  stopTracking(): void {
    try {

      this.clearPositions();
      this.stopGeolocationTracking();

      this.isTracking = false;
      this.isRecording = false;
      this.isGeofenceSet = false;
      this.isWaitingForAutomaticStop = false;
    } catch (err) {
    }
  }

  getDistance(): number {

    let distance = 0;
    const coordinates: AldPosition[] = Array.from(this.recordedPositions);

    for (let i = 0; i < coordinates.length - 1; i++) {

      if (i === (coordinates.length - 1)) {
        continue;
      }

      distance += this.distanceCalculation(coordinates[i].Latitude, coordinates[i].Longitude, coordinates[i + 1].Latitude, coordinates[i + 1].Longitude);
    }

    return distance;
  }

  getAddressFromLatLong(latitude: number, longitude: number): Promise<string> {

    if (!this.geoCoder) {
      this.geoCoder = new google.maps.geoCoder();
    }

    const promise = new Promise<string>((resolve, reject) => {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            resolve(results[0].formatted_address);
          }
        }

        resolve("No address");
      });
    });

    return promise;
  }

  getPositions(): AldPosition[] {
    return Array.from(this.recordedPositions);
  }

  mockPosition() {


    this.recordedPositions.push({ Longitude: 55.660790, Latitude: 12.273067 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
    this.recordedPositions.push({ Longitude: 55.660480, Latitude: 12.284773 });
  }

  getLocationOneTime(): Promise<AldPosition> {

    const promise = new Promise<AldPosition>((resolve, reject) => {

      BackgroundGeolocation.onLocation((location: Location) => {

        const mappedPosition: AldPosition = this.locationToAldPosition(location);
        if (mappedPosition) {

          BackgroundGeolocation.stop();
          BackgroundGeolocation.removeAllListeners();

          resolve(mappedPosition);
        }
      });

      BackgroundGeolocation.ready(this.locationConfig,
        (state) => { },
        (error) => {
          reject(null);
        });

    });

    return promise;
  }

  locationToAldPosition(position: Location): AldPosition {

    if (!position || !position.coords) {
      return null;
    }

    const dateToTicks: Date = new Date(position.timestamp);

    return {
      Latitude: position.coords.latitude,
      Longitude: position.coords.longitude,
      Timestamp: dateToTicks.getTime(),
      Accuracy: position.coords.accuracy,
      Speed: position.coords.speed,
      Heading: position.coords.heading
    };
  }

  // #region BackgroundGeolocation Management

  private setGeofence(): void {

    this.isGeofenceSet = true;

    if (!this.lastKnownLocation) {
      return;
    }

    BackgroundGeolocation.addGeofence({
      identifier: this.geoFenceIdentifer,
      longitude: this.lastKnownLocation.coords.longitude,
      latitude: this.lastKnownLocation.coords.latitude,
      notifyOnDwell: true,
      notifyOnEntry: true,
      notifyOnExit: true,
      radius: 50
    });
  }

  private startGeolocationTracking(): void {

    if (!this.isGeofenceSet) {
      this.setGeofence();
    }

    this.isTracking = true;
  }

  private stopGeolocationTracking(): void {
    BackgroundGeolocation.stop();
    BackgroundGeolocation.removeListeners();
    BackgroundGeolocation.removeGeofence(this.geoFenceIdentifer);
  }

  // #endregion

  // #region Helpers

  private clearPositions(): void {
    this.recordedPositions = [];
  }

  private distanceCalculation(lat1: number, lon1: number, lat2: number, lon2: number): number {

    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const R = 6378.137; // Radius of earth in KM
      const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
      const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;

      return d; // km
    }
  }

  private noCoordinateErrors(position: AldPosition): boolean {

    // Note: Ensure we don't get messy coordinates like this one: 1.85320519890898e-314

    const regX: RegExp = /[a-z]|[-]/ig;
    const matchesLatitude: number = position.Latitude.toString().search(regX);
    const matchesLongitude: number = position.Longitude.toString().search(regX);

    return matchesLatitude === -1 && matchesLongitude === -1; // No match found - success!
  }

  // #endregion


}
