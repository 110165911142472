export class CardOrder {
    constructor(
        public VehicleRegistrationNumber: string,
        public Name: string,
        public Address: string,
        public ZipCode: string,
        public City: string,
        public Colour: CardColour,
        public Email: string
    ) { }
}

export enum CardColour {
    Green = 0,
    Red = 1
}
