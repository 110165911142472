import { UserRoleType } from "./enums/user-role-type.enum";
import { UserSettings } from "./user-settings.model";
import { IDriverSettings } from "../sharedtypes/interfaces/sharedtypes.interface";

export class AppUser {
  constructor(
    public Username: string,
    public AuthenticationUserId: string,
    public Roles: Array<UserRoleType>,
    public RefreshKey: string,
    public Settings: IDriverSettings,
    public UserSettings: UserSettings,
    public IsPasswordExpiry: boolean
  ) { }


  public static GetInitialUser() {
    return new AppUser(
      "",
      "",
      [UserRoleType.Anonymous],
      "",
      { ParkingApp: false },
      // { DriverJournalAutoNotificationTime: 20, ParkingTrackingConsent: false, DriverJournalTrackingConsent: false, ParkingApp: false },
      new UserSettings(),
      false
    );
  }
}
