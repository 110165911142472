<menu-record-trip *ngIf="!screenService.isDesktop"></menu-record-trip>
<nav class="user-menu">
    <div class="user-menu-header" *ngIf="screenService.isDesktop">
        <p class="title">
            <app-translate translationName="MenuComponent.page_title">
                <span translate-text>
                    {{'MenuComponent.page_title'| translate}}
                </span>
            </app-translate>
        </p>
        <p class="sub-title">
            <app-translate translationName="MenuComponent.page_subtitle">
                <span translate-text>
                    {{'MenuComponent.page_subtitle'| translate}}
                </span>
            </app-translate>
        </p>
        <br>
        <div *ngIf="isInTakeOverMode">
            <br>
            <p class="label-border">
                <app-translate translationName="MenuComponent.admin_administrator_l">
                    <span translate-text>{{'MenuComponent.admin_administrator_l' | translate}}
                    </span>
                </app-translate>
            </p>
        </div>

    </div>

    <menu-driver-component *ngIf="showDriverMenu()"></menu-driver-component>
    <menu-fleet-user-component *ngIf="showFleetUserMenu()"></menu-fleet-user-component>
    <menu-business-user-component *ngIf="showBusinessUserMenu()"></menu-business-user-component>

    <div class="details-container" *ngIf="screenService.isDesktop">
        <div class="lang-switch">
            <span *ngFor="let languageCode of bigAl.getAvailableLanguageCodes();let i = index">
                <span (click)="changeLanguage(languageCode)"
                    [class.active-lang]="currentLang === languageCode">{{(languageCode.toUpperCase())}}</span>
                <span *ngIf="i < bigAl.getAvailableLanguageCodes().length - 1"> | </span>
            </span>
        </div>
    </div>

    <div class="role-container" *ngIf="showRolesPicker && bigAl.isInDesktopScreen">
        <a [class.active]="showDriverMenu()" fxFlex *ngIf="showRoleDriver" (click)="toggleDriverMenu()">
            <app-translate translationName="MenuComponent.role_driver">
                <span translate-text>
                    {{'MenuComponent.role_driver'| translate}}
                </span>
            </app-translate>
        </a>
        <a [class.active]="showFleetUserMenu()" fxFlex *ngIf="showRoleFleetUser" (click)="toggleFleetUserMenu()">
            <app-translate translationName="MenuComponent.role_fleet_admin">
                <span translate-text>
                    {{'MenuComponent.role_fleet_admin'| translate}}
                </span>
            </app-translate>
        </a>
        <a [class.active]="showBusinessUserMenu()" fxFlex *ngIf="showRoleBusinessUser">
            <app-translate translationName="MenuComponent.role_business_user">
                <span translate-text>
                    {{'MenuComponent.role_business_user'| translate}}
                </span>
            </app-translate>
        </a>
    </div>
</nav>