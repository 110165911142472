var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Title } from '@angular/platform-browser';
import { OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
// Third-party
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// Common
import { BigAlModule } from '../../modules/bigal.module';
import { TrackingHelper } from '../../helpers/trackingHelper';
// Services
import { FleetUserService } from '../../services/fleet-user.service';
import { CustomTranslateService } from '../../translation/customTranslateService';
import { ComponentConfigService } from './../../services/componentConfig.service';
import { ScreenService } from '../../services/screen.service';
import { NavigationService } from '../../services/navigation.service';
import { ResourceInjectorService } from '../../services/resourceInjector.service';
import { RouteStateService } from '../../services/routeState.service';
import { UserRoleType } from '../../models/enums/user-role-type.enum';
import { FirebaseService } from '../../services/firebase.service';
import { AppUserStore } from '../../stores/DataStores';
import { ContractStore, ParkingStore } from '../../stores/DataStores';
import { AdminInfoService } from '../../services/admin-info.service';
var InitComponent = /** @class */ (function () {
    function InitComponent(firebaseService, titleService, bigAl, theBen, fleetUserService, translateService, screenService, navigationService, router, resourceInjector, angulartics2GoogleTagManager, overlayContainer, routeStateService, appUserStore, contractStore, parkingStore, renderer, adminInfoService) {
        this.firebaseService = firebaseService;
        this.titleService = titleService;
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.fleetUserService = fleetUserService;
        this.translateService = translateService;
        this.screenService = screenService;
        this.navigationService = navigationService;
        this.router = router;
        this.resourceInjector = resourceInjector;
        this.angulartics2GoogleTagManager = angulartics2GoogleTagManager;
        this.overlayContainer = overlayContainer;
        this.routeStateService = routeStateService;
        this.appUserStore = appUserStore;
        this.contractStore = contractStore;
        this.parkingStore = parkingStore;
        this.renderer = renderer;
        this.adminInfoService = adminInfoService;
        this.subscriptions = new Subscription();
        this.showTakeOverBar = false;
        this.isEditing = false;
        this.updateTranslationsText = "Update translations";
        this.translationsListText = "Translations List";
        this.editTranslationsText = "Done editing";
        this.test = 'ASDF';
        this.trackId = '';
        this.showFirstComponent = new BehaviorSubject(false);
        this.isProduction = false;
        this.databaseType = null;
        this.frontendVersion = null;
        this.backendVersion = null;
        this.updateTranslationsButtonText = this.updateTranslationsText;
        this.translationsListButtonText = this.translationsListText;
        this.isApplicationVersionchanged = false;
        this.showReleaseAlertCloseButton = false;
        angulartics2GoogleTagManager.startTracking();
        this.trackingService = new TrackingHelper(appUserStore);
        this.setupStoreListeners();
    }
    InitComponent.prototype.setupStoreListeners = function () {
        var _this = this;
        this.parkingStore.onPendingParkingStore$.subscribe(function (response) {
            if (response.isInitial) {
                return;
            }
            if (response.isSuccess) {
                if (response.model != null) {
                    _this.router.navigate(['/parking/start-parking']);
                }
            }
        });
        this.contractStore.onGetContracts$.subscribe(function (response) {
            if (response.isInitial) {
                return;
            }
            if (response.isSuccess) {
                _this.contractId = response.model.vehicleContract.Id;
                _this.checkAndCallPendingParking();
            }
        });
    };
    InitComponent.prototype.ngOnInit = function () {
        this.firebaseService.wireupFirebaseNotification();
        InitComponent.scope = this;
        document.addEventListener("deviceready", this.onDeviceReady, false);
        this.watchData();
        this.getData();
        this.routeStateService.loadRouting();
        this.listenToRouteChanges();
        // this.contractStore.getContracts();
    };
    InitComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        if (this.timeInterval) {
            this.timeInterval.unsubscribe();
        }
    };
    InitComponent.prototype.onDeviceReady = function () {
        document.addEventListener("resume", function () {
            InitComponent.scope.checkAndCallPendingParking();
        }, false);
    };
    InitComponent.prototype.canUseAdminFunctions = function () {
        return this.appUserStore.appUser.Roles.some(function (role) {
            if (role === UserRoleType.DevUser_Admin || role === UserRoleType.DevUser_Driver || role === UserRoleType.DevUser_Business) {
                return true;
            }
        });
    };
    InitComponent.prototype.checkAndCallPendingParking = function () {
        if (this.theBen.isContent(this, 'parking_app') && this.bigAl.appSettings && this.bigAl.appSettings.IsApp && this.appUserStore.appUser && this.appUserStore.appUser.Settings && this.appUserStore.appUser.Settings.ParkingApp) {
            this.parkingStore.getPendingParking(this.contractId);
        }
    };
    InitComponent.prototype.listenToRouteChanges = function () {
        var _this = this;
        this.subscriptions.add(this.router.events.subscribe(function (val) {
            if (val instanceof NavigationStart) {
                _this.trackingService.trackPage(val.url, val.url);
            }
        }));
    };
    InitComponent.prototype.watchData = function () {
        var _this = this;
        this.subscriptions.add(this.bigAl.getDataStream().subscribe(function (lastEvent) {
            if (lastEvent === "init") {
                _this.hideCarSpinner(_this.showFirstComponent);
                switch (_this.bigAl.appSettings.CompanyId) {
                    case 1:
                        _this.setTitle("My ALD 2.1");
                        _this.setFavicon('assets/ald/icons/ald/favicon.ico');
                        _this.onSetTheme('ald-theme');
                        break;
                    case 2:
                        _this.setTitle("My NF FLEET 2.1");
                        _this.setFavicon('assets/ald/icons/nf-fleet/favicon.png');
                        _this.onSetTheme('nf-theme');
                        break;
                    default:
                        _this.setTitle("My ALD 2.1");
                        _this.onSetTheme('ald-theme');
                }
                if (!_this.bigAl.appSettings.ApiBaseUrl.includes('uat') && !_this.bigAl.appSettings.ApiBaseUrl.includes('test') && !_this.bigAl.appSettings.ApiBaseUrl.includes('localhost') && !_this.bigAl.appSettings.ApiBaseUrl.includes('dev')) {
                    _this.isProduction = true;
                }
                else {
                    if (_this.bigAl.appSettings.DatabaseType === 0) {
                        _this.databaseType = 'Fleet';
                    }
                    else if (_this.bigAl.appSettings.DatabaseType === 1) {
                        _this.databaseType = 'Miles';
                    }
                    _this.frontendVersion = _this.bigAl.defaultSettings.FrontendVersion;
                    _this.backendVersion = _this.bigAl.defaultSettings.BackendVersion;
                }
                if (!window['device']) {
                    _this.timeInterval = interval(900000).subscribe((function (timerCount) {
                        _this.checkVersion();
                    }));
                }
            }
        }));
        this.subscriptions.add(this.fleetUserService.isInTakeOverMode.subscribe(function (isInTakeOverMode) {
            _this.showTakeOverBar = isInTakeOverMode;
        }));
        this.subscriptions.add(this.translateService.isEditing.subscribe(function (isEditing) {
            _this.isEditing = isEditing;
            if (_this.isEditing) {
                _this.updateTranslationsButtonText = _this.editTranslationsText;
            }
            else {
                _this.updateTranslationsButtonText = _this.updateTranslationsText;
            }
        }));
        this.subscriptions.add(this.bigAl.appDidInit$.subscribe(function (didLoad) {
            if (didLoad) {
                _this.injectResources();
            }
        }));
    };
    InitComponent.prototype.getData = function () {
        if (!this.bigAl.appSettings) {
            this.bigAl.init();
        }
        else {
            this.hideCarSpinner(this.showFirstComponent);
        }
    };
    InitComponent.prototype.injectResources = function () {
        var whoAmI = this.bigAl.appSettings;
        // Inject GioSG script if the identity is Sweden
        if (whoAmI && !whoAmI.IsApp && whoAmI.CountryCode === "se") {
            var script = {
                src: "assets/scripts/giosg.js",
                identifier: "giosg"
            };
            this.resourceInjector.injectScript(script);
        }
    };
    /** when using setTimeout always pass parameters to function otherwise vars become undefined  */
    InitComponent.prototype.hideCarSpinner = function (showFirstComponent) {
        setTimeout(function () {
            showFirstComponent.next(true);
        }, 1000);
    };
    InitComponent.prototype.translationUpdates = function () {
        if (this.router.url.includes('(modal:')) {
            var posModal = this.router.url.indexOf('(modal:');
            var modalString = this.router.url.substring(posModal);
            var routeModalName = modalString.substring(7);
            routeModalName = routeModalName.substring(0, routeModalName.length - 1);
            this.bigAl.previousModal = routeModalName;
        }
        else {
            this.bigAl.previousModal = null;
        }
        this.translateService.setIsEditing(!this.isEditing);
    };
    InitComponent.prototype.action = function (location, id) {
        (id) ? this.navigationService.openModal([location, id]) : this.navigationService.openModal(location);
    };
    InitComponent.prototype.onSetTheme = function (theme) {
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.componentCssClass = theme;
    };
    InitComponent.prototype.setTitle = function (newTitle) {
        this.titleService.setTitle(newTitle);
    };
    InitComponent.prototype.changeFavicon = function (img) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.setAttribute('type', 'image/png');
        link.setAttribute('rel', 'shortcut icon');
        link.setAttribute('href', img);
        document.getElementsByTagName('head')[0].appendChild(link);
    };
    InitComponent.prototype.setFavicon = function (url) {
        this.removeFavicon();
        var link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = url;
        document.getElementsByTagName('head')[0].appendChild(link);
    };
    InitComponent.prototype.removeFavicon = function () {
        var links = document.getElementsByTagName('link');
        var head = document.getElementsByTagName('head')[0];
        for (var i = 0; i < links.length; i++) {
            if (links[i].getAttribute('rel') === 'icon') {
                head.removeChild(links[i]);
            }
        }
    };
    InitComponent.prototype.isContent = function (name) {
        return this.theBen.isContent(this, name);
    };
    InitComponent.prototype.checkVersion = function () {
        var _this = this;
        this.adminInfoService.getApplicationVersion()
            .then(function (adminInfo) {
            var version = localStorage.getItem("version");
            if (version !== adminInfo.version) {
                _this.latestVersion = adminInfo.version;
                _this.isApplicationVersionchanged = true;
            }
        })
            .catch(function (err) {
        });
    };
    InitComponent.prototype.updateVersionAndReload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var names;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!caches) return [3 /*break*/, 3];
                        return [4 /*yield*/, caches.keys()];
                    case 1:
                        names = _a.sent();
                        return [4 /*yield*/, Promise.all(names.map(function (name) { return caches.delete(name); }))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        localStorage.setItem("version", this.latestVersion);
                        location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    return InitComponent;
}());
export { InitComponent };
