var EditLogin = /** @class */ (function () {
    function EditLogin(Username, CurrentPassword, NewPassword) {
        this.Username = Username;
        this.CurrentPassword = CurrentPassword;
        this.NewPassword = NewPassword;
    }
    return EditLogin;
}());
export { EditLogin };
var EditLoginForm = /** @class */ (function () {
    function EditLoginForm(Username, CurrentPassword, Password, ConfirmPassword) {
        this.Username = Username;
        this.CurrentPassword = CurrentPassword;
        this.Password = Password;
        this.ConfirmPassword = ConfirmPassword;
    }
    return EditLoginForm;
}());
export { EditLoginForm };
