import { OnInit } from '@angular/core';
import { Form } from '../../../../models/form.model';
import { FormProperty, FormObject } from '../../../../models/form-object.model';
import { Rule } from '../../../../models/rule-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';
var FieldErrorComponent = /** @class */ (function () {
    function FieldErrorComponent() {
        this.translationTagType = TranslationTagType.Error;
    }
    FieldErrorComponent.prototype.ngOnInit = function () {
    };
    return FieldErrorComponent;
}());
export { FieldErrorComponent };
