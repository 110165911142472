import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Services
import { ApiService } from "../../services/api.service";
import { CustomTranslateService } from "../../translation/customTranslateService";

// Types
import { AppSettings } from '../../models/app-settings.model';
import { IDefaultSettings, IStoreResult, IDriverSettings } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Helpers
import { Utils } from "../../helpers/utils";
import { ApiUrl } from "../../helpers/apiUrls";
import { Constants } from "../../helpers/constants";

@Injectable({
  providedIn: "root"
})
export class AppSettingsStore {

  appSettings: AppSettings;
  defaultSettings: IDefaultSettings;
  currentTraceId: string;
  isStoreReady: boolean;

  // #region Observables

  private readonly languageChanged: BehaviorSubject<IStoreResult<string>> = new BehaviorSubject<IStoreResult<string>>({ model: null, isInitial: true });
  onLanguageChanged$ = this.languageChanged.asObservable();

  // #endregion

  constructor(
    private translateService: CustomTranslateService,
    private apiService: ApiService) {

    this.isStoreReady = false;
  }

  async init() {
    try {
      await this.getSettings();
      await this.initWhoAmI();

      this.isStoreReady = true;
    } catch (err) {
      this.isStoreReady = false;
    }
  }

  changeLanguage(languageCode: string) {

    // Update localstorage
    localStorage.setItem(Constants.LocalStorage_Language, languageCode);

    this.appSettings.LanguageCode = languageCode;
    this.translateService.changeLanguageByLanguageCode(this.appSettings.LanguageCode);

    // Save to backend
    this.postChangeLanguage(this.appSettings.LanguageCode);
  }


  private postChangeLanguage(language: string) {
    this.apiService.postAsync(`${ApiUrl.LanguageChange}/${language}`, null, this.appSettings)
      .then((data) => {
        // this.currentFeed = null;
        // this.getFeed();

        // Emit language change to subscribers
        this.languageChanged.next({ model: language, isSuccess: true, isCached: true });
      })
      .catch((err: any) => {
        this.languageChanged.next({ model: null, isSuccess: false, error: err });
      });
  }

  private async getSettings() {
    return this.apiService.getFileAsync<IDefaultSettings>(ApiUrl.DefaultSettingsJson)
      .then((data: IDefaultSettings) => {
        this.defaultSettings = data;
      })
      .catch((err: any) => {
      });
  }

  private async initWhoAmI() {

    if (this.appSettings) {
      return;
    }

    let whoAmILocation = this.defaultSettings.WhoAmI[location.host];
    if (whoAmILocation === undefined) {
      whoAmILocation = this.defaultSettings.WhoAmI["app"];
    }

    return this.apiService.getAsync<AppSettings>(`${ApiUrl.WhoAmI}/${whoAmILocation}`, this.defaultSettings)
      .then((data: AppSettings) => {

        // Update localstorage
        localStorage.setItem(Constants.LocalStorage_CountryCode, data.CountryCode);

        // Update appSettings
        this.appSettings = this.setTraceId(data);
        this.appSettings.FeatureComponentConfiguration = data.FeatureComponentConfiguration;
        this.appSettings.LanguageCode = (localStorage.getItem(Constants.LocalStorage_Language)) ? localStorage.getItem(Constants.LocalStorage_Language) : this.translateService.getCountryLanguage(this.appSettings.CountryCode);
        // this.localLanguageCountryCode = this.appSettings.CountryCode;
        this.appSettings.IsApp = this.defaultSettings.IsApp;

        if (localStorage.getItem(Constants.LocalStorage_Language)) {
          this.changeLanguage(localStorage.getItem(Constants.LocalStorage_Language));
        }
        // this.setDefaultLocation(data.CountryCode);
      })
      .catch((err: any) => {
      });
  }

  private setTraceId(appSettings: AppSettings): AppSettings {

    if (!this.currentTraceId) {
      this.currentTraceId = Utils.NewGUID();
    }

    appSettings.TraceId = this.currentTraceId;

    return appSettings;
  }

  /**
   * Used when you need to set the language local id. It is dependent on country
   * @returns the local id string. Default is "da-DK" for Denmark
   */
  public getLocalId(): string {
    let locale_id = "da-DK";
    switch (this.appSettings.CountryCode) {
      case 'dk': {
        locale_id = 'da-DK';
        break;
      }
      case 'se': {
        locale_id = 'sv-SE';
        break;
      }
      case 'no': {
        locale_id = 'nb-NO';
        break;
      }
    }
    return locale_id;
  }

}
