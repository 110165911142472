export class UserActionSuccess {
    /** The componentBaseTranslationTag should be the name of component (e.g. OrderRedcardComponent) which "caused" the successful action */
    constructor(
        public componentBaseTranslationTag: string
    ) { }

    public GetDescriptionTranslationTag() {
        return this.componentBaseTranslationTag + ".successful_api_post_action_description_t";
    }
    public GetTitleTranslationTag() {
        return this.componentBaseTranslationTag + ".successful_api_post_action_header_t";
    }
}
