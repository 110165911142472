import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { DriverJournalDaily } from "../../models/driverjournal/driverJournalDaily.model";
import { DriverJournalMonthly } from "../../models/driverjournal/driverJournalMonthly.model";
import { IStoreResult, ITollStation } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";


@Injectable({
  providedIn: "root"
})
export class DriverJournalStore {

  private _journalDaily: DriverJournalDaily;
  private _journalMonthly: DriverJournalMonthly;
  private _tollStations: ITollStation[];

  private readonly journalDaily: BehaviorSubject<IStoreResult<DriverJournalDaily>> = new BehaviorSubject<IStoreResult<DriverJournalDaily>>({ model: null, isInitial: true });
  onGetJournalDaily$ = this.journalDaily.asObservable();

  private readonly journalMonthy: BehaviorSubject<IStoreResult<DriverJournalMonthly>> = new BehaviorSubject<IStoreResult<DriverJournalMonthly>>({ model: null, isInitial: true });
  onGetJournalMonthy$ = this.journalMonthy.asObservable();

  private readonly tollStations: BehaviorSubject<IStoreResult<ITollStation[]>> = new BehaviorSubject<IStoreResult<ITollStation[]>>({ model: null, isInitial: true });
  onGetTollStations$ = this.tollStations.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  reset() {
    this.journalDaily.next({ model: null, isInitial: true });
    this.journalMonthy.next({ model: null, isInitial: true });
    this.tollStations.next({ model: null, isInitial: true });
    this._journalDaily = null;
    this._journalMonthly = null;
    this._tollStations = null;
  }

  // #region Requests

  getJournalDaily(vehicleContract: number, month: number, year: number, forceReload: boolean = false): void {

    // Entry found
    if (this._journalDaily && !forceReload) {
      this.journalDaily.next({ model: this._journalDaily, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<DriverJournalDaily>(`${ApiUrl.DriverJournalDaily}/${vehicleContract}/${month}/${year}`, this.settingStore.appSettings)
      .then((data: DriverJournalDaily) => {
        this._journalDaily = data;
        this.journalDaily.next({ model: this._journalDaily, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this._journalDaily = null;
        this.journalDaily.next({ model: this._journalDaily, isSuccess: false, error: err });
      });
  }

  getJournalMonthly(vehicleContract: number, year: number, forceReload: boolean = false): void {

    // Entry found
    if (this._journalMonthly && !forceReload) {
      this.journalMonthy.next({ model: this._journalMonthly, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<DriverJournalMonthly>(`${ApiUrl.DriverJournalMonth}/${vehicleContract}/${year}`, this.settingStore.appSettings)
      .then((data: DriverJournalMonthly) => {

        if (data.PreviousMonthLoggingAlert.Month) {
          data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
        }

        data.Months.forEach(entry => {
          entry.Month = entry.Month - 1;
        });

        this._journalMonthly = data;
        this.journalMonthy.next({ model: this._journalMonthly, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this._journalMonthly = null;
        this.journalMonthy.next({ model: this._journalMonthly, isSuccess: false, error: err });
      });
  }

  getTollStations(forceReload: boolean = false): void {

    // Entry found
    if (this._tollStations && !forceReload) {
      this.tollStations.next({ model: this._tollStations, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<ITollStation[]>(`${ApiUrl.TollStations}`, this.settingStore.appSettings)
      .then((data: ITollStation[]) => {
        this._tollStations = data;
        this.tollStations.next({ model: this._tollStations, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this._tollStations = null;
        this.tollStations.next({ model: this._tollStations, isSuccess: false, error: err });
      });
  }

  stopActiveTrip(): Promise<DriverJournalDaily> {
    // Get entry from database
    return this.apiService.getAsync<DriverJournalDaily>(`${ApiUrl.HasActiveTrip}`, this.settingStore.appSettings);
  }

  // #endregion
}
