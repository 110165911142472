import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FleetUserReportOrder } from '../models/fleetUserReportOrder.model';

@Injectable()
export class FleetReportService {
    private reportOrder: BehaviorSubject<FleetUserReportOrder> = new BehaviorSubject<FleetUserReportOrder>(new FleetUserReportOrder("0", [], []));

    constructor() {
    }

    setOrder(order: FleetUserReportOrder) {
        this.reportOrder.next(order);
    }

    getOrder(): Observable<FleetUserReportOrder> {
        return this.reportOrder.asObservable();
    }

    public getOrderValue(): FleetUserReportOrder {
        return this.reportOrder.getValue();
    }
}
