var MapSettings = /** @class */ (function () {
    function MapSettings(RequiresDirections, Lat, Lng, Directions, CanShowMap, TollStations) {
        if (Lat === void 0) { Lat = null; }
        if (Lng === void 0) { Lng = null; }
        if (Directions === void 0) { Directions = null; }
        if (CanShowMap === void 0) { CanShowMap = null; }
        if (TollStations === void 0) { TollStations = []; }
        this.RequiresDirections = RequiresDirections;
        this.Lat = Lat;
        this.Lng = Lng;
        this.Directions = Directions;
        this.CanShowMap = CanShowMap;
        this.TollStations = TollStations;
    }
    return MapSettings;
}());
export { MapSettings };
var MapSettingsDirections = /** @class */ (function () {
    function MapSettingsDirections(OriginAddress, OriginPosition, DestinationAddress, DestinationPosition) {
        this.OriginAddress = OriginAddress;
        this.OriginPosition = OriginPosition;
        this.DestinationAddress = DestinationAddress;
        this.DestinationPosition = DestinationPosition;
    }
    return MapSettingsDirections;
}());
export { MapSettingsDirections };
