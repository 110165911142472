<app-modal>
  <div modal-header>
  Missing translations
  </div>
  <div modal-body>
    <div class="component-configs">
      <div *ngFor="let entry of translations">
        <app-translate translationName="{{entry.Translation.TranslationValue}}">
          <span translate-text>{{ entry.Translation.TranslationValue | translate}}</span>
        </app-translate>
      </div>
    </div>
  </div>
  <div modal-footer>
    <button mat-raised-button color="primary" (click)="back()">
      Close
    </button>
  </div>
</app-modal>
