var FeatureStructure = /** @class */ (function () {
    function FeatureStructure() {
    }
    return FeatureStructure;
}());
export { FeatureStructure };
export var FeatureShowState;
(function (FeatureShowState) {
    FeatureShowState[FeatureShowState["None"] = 0] = "None";
    FeatureShowState[FeatureShowState["StartScreen"] = 1] = "StartScreen";
    FeatureShowState[FeatureShowState["LoggedIn"] = 2] = "LoggedIn";
})(FeatureShowState || (FeatureShowState = {}));
