import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { RouteStateService } from "./routeState.service";
var NavigationService = /** @class */ (function () {
    function NavigationService(location, router, routeStateService) {
        this.location = location;
        this.router = router;
        this.routeStateService = routeStateService;
    }
    NavigationService.prototype.back = function () {
        this.router.navigateByUrl(this.routeStateService.getPreviousUrl());
    };
    NavigationService.prototype.forward = function (route, routeId, extras) {
        if (!route) {
            throw new Error("No route specified!");
        }
        if (!routeId) {
            return this.router.navigate([route], extras);
        }
        else {
            return this.router.navigate([route, routeId], extras);
        }
    };
    NavigationService.prototype.reload = function () {
        location.reload();
    };
    NavigationService.prototype.closeModal = function () {
        if (this.router.url.includes('(modal:')) {
            this.location.back();
        }
    };
    NavigationService.prototype.openModal = function (parameters) {
        this.router.navigate([{ outlets: { modal: parameters } }]);
    };
    NavigationService.prototype.openNewTab = function (route, isApp) {
        if (isApp) {
            location.href = route;
        }
        else {
            window.open(route, '_blank');
        }
    };
    return NavigationService;
}());
export { NavigationService };
