import { Component } from '@angular/core';
import { AbstractModalFeature } from '../../../../components/features/modalfeature.abstract';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ActivatedRoute } from '@angular/router';
import { AppointmentPlace } from '../../../../models/appointment-place.model';
import { Appointment } from '../../../../models/appointment.model';
import { ValidatorFactory } from '../../../../rulebook/validator-factory';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Driver } from '../../../../models/driver.model';
import { AppointmentTimeslots } from '../../../../models/appointment-timeslots.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { Constants } from '../../../../helpers/constants';

@Component({
  selector: 'app-update-booking',
  templateUrl: './update-booking.component.html',
  styleUrls: ['./update-booking.component.scss']
})
export class UpdateBookingComponent extends AbstractModalFeature {

  // data variables

  public appointmentPlace: AppointmentPlace;
  public appointment: Appointment;
  public driver: Driver;
  public domainForm: FormGroup;
  public availableTimes: AppointmentTimeslots[];
  public currentSelectedTimeslot;
  public today = new Date();
  public modelName;
  public currentLanguage;
  public isEditing = false;
  // set content variables


  // set features variables


  constructor(
    private translateService: CustomTranslateService,
    private translate: TranslateService,
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService,
    private route: ActivatedRoute,
    private validatorFactory: ValidatorFactory,
    private formBuilder: FormBuilder,
  ) {
    super(bigAl, theBen);
    this.createForm();
  }

  onDateChange(newDate: Date) {
    this.bigAl.setCurrentBookingFormSelectedDate(newDate);
    this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
  }

  onLaterClick() {
    let laterDate: Date = new Date();
    let lastDate: Date = this.availableTimes[this.availableTimes.length - 1]
      .AvailableBookingDate;
    // add duration of one day to last date
    laterDate.setTime((new Date(lastDate)).getTime() + 24 * 60 * 60 * 1000);

    this.domainForm.controls["date"].setValue(laterDate);
  }

  validateAllFormFields(formGroup: FormGroup) {         // {1}
    Object.keys(formGroup.controls).forEach(field => {  // {2}
      const control = formGroup.get(field);             // {3}
      if (control instanceof FormControl) {             // {4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        // {5}
        this.validateAllFormFields(control);            // {6}
      }
    });
  }

  private createForm() {
    let mobile = (this.bigAl.currentDriver !== null) ? this.bigAl.currentDriver.MobileNumber : "";
    let date = (this.appointment) ? this.appointment.Appointment : new Date();
    this.domainForm = this.formBuilder.group({
      date: [date],
      selectedDateTime: ['', this.validatorFactory.getRequiredValidator()],
      mobilePhoneNumber: [mobile, [this.validatorFactory.getRequiredValidator(), this.validatorFactory.getPhoneValidator(this.validatorFactory.hasValue)]]
    });
  }

  onFormSubmit() {
    this.validateAllFormFields(this.domainForm);
    if (!this.domainForm.valid) {
    }
    else {
      let appointment = new Appointment(this.appointment.AppointmentId, this.domainForm.value["selectedDateTime"], this.domainForm.value["mobilePhoneNumber"], null);
      this.bigAl.updateAppointment(this.appointmentPlace.ModelName, appointment);
      this.close(true);
    }
  }

  protected setEventsToWatch(): void {
    this.subscribe(
      this.route.params.subscribe(params => {
        this.modelName = params['modelName'];
        this.appointmentPlace = this.bigAl.getAppointmentPlace(params['modelName']);
        this.appointment = this.bigAl.currentTireCenter.Bookings.find(booking => booking.AppointmentId.toString() === params['appointmentId']);
        this.domainForm.controls["date"].setValue(this.appointment.Appointment);
      }
      ));

    this.subscribe(
      this.domainForm.controls["date"].valueChanges.subscribe(newDate => {
        this.onDateChange(newDate);
      })
    );
    this.subscribe(
      this.domainForm.controls["selectedDateTime"].valueChanges.subscribe(timeslot => {
        this.currentSelectedTimeslot = timeslot;
      })
    );

    this.subscribe(this.translateService.isEditing.subscribe(editing => {
      this.isEditing = editing;
    }));
    this.watchEvent(Constants.Event_CurrentDriver);
    this.watchEvent("currentAvailableTimes");
    return;
  }
  protected setData(): void {
    this.currentLanguage = this.translate.currentLang;
    if (this.bigAl.currentDriver) {
      this.driver = this.bigAl.currentDriver;
    }
    if (this.bigAl["current" + this.modelName]) {
      this.appointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
    }
    if (this.bigAl.currentAvailableTimes) {
      this.availableTimes = this.bigAl.currentAvailableTimes;
    }
  }
  protected getData(): void {
    if (!this.bigAl.currentDriver) {
      this.bigAl.getDriver();
    }
    else {
      this.driver = this.bigAl.currentDriver;
    }
    if (!this.bigAl["current" + this.modelName]) {
      this.bigAl["get" + this.modelName]();
      this.appointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
    }
    if (!this.bigAl.currentAvailableTimes) {
      if (this.appointment) {
        this.bigAl.setCurrentBookingFormSelectedDate(new Date(this.appointment.Appointment));
        this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
      }
      else {
        this.bigAl.setCurrentBookingFormSelectedDate(new Date());
        this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
      }
    }
    this.setData();
  }

  public isDataAvailable(): boolean {
    return true;
  }
}
