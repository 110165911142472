import { Component } from '@angular/core';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { AbstractModalFeature } from '../../modalfeature.abstract';
import { Contacts } from '../../../../models/contacts.model';
import { ContactInformation } from '../../../../models/contact-information.model';

@Component({
  selector: 'app-insurance-contacts',
  templateUrl: './insurance-contacts.component.html',
  styleUrls: ['./insurance-contacts.component.scss']
})
export class InsuranceContactsComponent extends AbstractModalFeature {

  // data variables
  public contacts: Contacts;
  public road_assistance_contact: ContactInformation = null;

  // content flags

  // feature flags


  constructor(
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.watchEvent("currentContacts");
    return;
  }

  getData() {
    if (!this.bigAl.currentContacts) {
      this.bigAl.getContacts();
    }
    else {
      this.setData();
    }
  }

  setData() {
    this.contacts = this.bigAl.currentContacts;
    if (this.contacts) {
      this.road_assistance_contact = this.contacts.ContactInformations.find(c => c.Role === "road_assistance_contact" || c.Role === "ald_assistance_contact" || c.Role === "ald_veihjelp");
    }
  }

  public isDataAvailable(): boolean {
    if (!this.contacts) {
      return false;
    }
    return true;
  }
}

