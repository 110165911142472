import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

import { BigAlModule } from '../../modules/bigal.module';
import { AbstractFeature } from '../features/feature.abstract';
import { ComponentConfigService } from '../../services/componentConfig.service';

@Component({
  selector: "car-spinner",
  templateUrl: "./car-spinner.component.html"
})
export class CarSpinnerComponent extends AbstractFeature implements OnInit, OnDestroy {

  public showSpinner = false;
  private companyName = "";
  private carLogo = "";
  private logo = "";

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.watchEvent("init");
  }

  protected setData(): void {
    if (this.bigAl.appSettings) {
      this.showSpinner = true;
      switch (this.bigAl.appSettings.CompanyId) {
        case 1:
          this.companyName = "My ALD";
          this.carLogo = "car-ald";
          this.logo = "logo-ald";
          break;
        case 2:
          this.companyName = "My NF Fleet";
          this.carLogo = "car-nf-fleet";
          this.logo = "logo-nf-fleet";
          break;
        default:
          this.companyName = "";
          this.carLogo = "";
          this.logo = "";
      }
    }
  }

  protected getData(): void {
  }

  public isDataAvailable(): boolean {
    return true;
  }
}

