var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AbstractFeature } from '../../features/feature.abstract';
import { ApiService } from '../../../services/api.service';
var ApiSuccessComponent = /** @class */ (function (_super) {
    __extends(ApiSuccessComponent, _super);
    function ApiSuccessComponent(apiService, bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.apiService = apiService;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.currentSuccess = null;
        return _this;
    }
    ApiSuccessComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.apiService.getSuccess()
            .subscribe(function (success) {
            if (!success) {
                _this.currentSuccess = null;
            }
            else {
                _this.currentSuccess = success;
            }
        }));
    };
    ApiSuccessComponent.prototype.setData = function () {
        return;
    };
    ApiSuccessComponent.prototype.getData = function () {
        return;
    };
    ApiSuccessComponent.prototype.isDataAvailable = function () {
        return true;
    };
    ApiSuccessComponent.prototype.closeModal = function (closing) {
        if (closing) {
            this.apiService.removeSuccess();
        }
    };
    return ApiSuccessComponent;
}(AbstractFeature));
export { ApiSuccessComponent };
