var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AbstractFeature } from '../../features/feature.abstract';
import { NavigationService } from '../../../services/navigation.service';
var ForgotLoginConfirmationComponent = /** @class */ (function (_super) {
    __extends(ForgotLoginConfirmationComponent, _super);
    function ForgotLoginConfirmationComponent(bigAl, theBen, navigationService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.navigationService = navigationService;
        return _this;
    }
    ForgotLoginConfirmationComponent.prototype.setEventsToWatch = function () {
        return;
    };
    ForgotLoginConfirmationComponent.prototype.setData = function () {
        return;
    };
    ForgotLoginConfirmationComponent.prototype.getData = function () {
        return;
    };
    ForgotLoginConfirmationComponent.prototype.isDataAvailable = function () {
        return true;
    };
    ForgotLoginConfirmationComponent.prototype.back = function () {
        this.navigationService.forward("login", null);
    };
    return ForgotLoginConfirmationComponent;
}(AbstractFeature));
export { ForgotLoginConfirmationComponent };
