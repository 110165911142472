<div>
  <app-modal size="small" *ngIf="fuelRule">

    <div modal-header>
      <app-translate translationName="DriverjournalMonthlyUpdateSuccessComponent.Header_t">
        <span translate-text>{{'DriverjournalMonthlyUpdateSuccessComponent.Header_t'|translate}}</span>
      </app-translate>
    </div>
    <div modal-body>
      <p>
        <app-translate translationName="'DriverjournalMonthlyUpdateSuccessComponent.fuelrules_' +fuelRule+ '_Paragraph1_t'">
          <span translate-text id="DJUpdateMonthlySuccessP1">{{'DriverjournalMonthlyUpdateSuccessComponent.fuelrules_'
            +
            fuelRule + '_Paragraph1_t' |translate}}</span>
        </app-translate>
      </p>
      <p>
        <app-translate translationName="'DriverjournalMonthlyUpdateSuccessComponent.fuelrules_' +fuelRule+ '_Paragraph2_t'">
          <span translate-text>{{'DriverjournalMonthlyUpdateSuccessComponent.fuelrules_' +
            fuelRule + '_Paragraph2_t' |translate}}</span>
        </app-translate>
      </p>
    </div>
  </app-modal>
</div>