import { Component, OnInit, ViewChild } from '@angular/core';

// Common
import { BigAlModule } from '../../../../modules/bigal.module';
import { AbstractFeature } from '../../../../components/features/feature.abstract';

// Services
import { RecordTripService } from '../../../../services/driverjournal/recordtrip.service';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { DjTabsService } from '../../../../services/driverjournal/dj-tabs.service';
import { NavigationService } from '../../../../services/navigation.service';
import { BrowserService } from '../../../../services/browser.service';
import { ScreenService } from '../../../../services/screen.service';

@Component({
  selector: 'menu-record-trip',
  templateUrl: './menu-record-trip.component.html'
})
export class MenuRecordTripComponent extends AbstractFeature implements OnInit {

  @ViewChild("menuHandle") $menuHandle: HTMLElement;

  public showMenu = false;
  public djTab = '/journal';
  isApp: boolean = false;

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private djTabService: DjTabsService,
    private navigationService: NavigationService,
    public browserService: BrowserService,
    public screenService: ScreenService,
    private recordTripService: RecordTripService
  ) {
    super(bigAl, theBen);

    this.isApp = this.bigAl.appSettings.IsApp;
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  protected setData(): void {
  }

  protected getData(): void {
  }

  setEventsToWatch(): void {

    this.subscribe(this.recordTripService.showRecordingTripMenue().subscribe((state: boolean) => {
      this.showMenu = state;

      if (state) {
        if (this.screenService.isIOS) {
          if (this.$menuHandle && !this.$menuHandle.classList.contains("_ios")) {
            this.$menuHandle.classList.add("_ios");
          }
        }
      }
    }));

    this.subscribe(this.djTabService.getData().subscribe(data => this.djTab = data));
  }

  onDjClick() {
    this.navigationService.forward(this.djTab, null);
  }

  isDataAvailable(): boolean {
    return true;
  }
}
