var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { AbstractModalFeature } from '../../modalfeature.abstract';
var InsuranceContactsComponent = /** @class */ (function (_super) {
    __extends(InsuranceContactsComponent, _super);
    // content flags
    // feature flags
    function InsuranceContactsComponent(bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.road_assistance_contact = null;
        return _this;
    }
    InsuranceContactsComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("currentContacts");
        return;
    };
    InsuranceContactsComponent.prototype.getData = function () {
        if (!this.bigAl.currentContacts) {
            this.bigAl.getContacts();
        }
        else {
            this.setData();
        }
    };
    InsuranceContactsComponent.prototype.setData = function () {
        this.contacts = this.bigAl.currentContacts;
        if (this.contacts) {
            this.road_assistance_contact = this.contacts.ContactInformations.find(function (c) { return c.Role === "road_assistance_contact" || c.Role === "ald_assistance_contact" || c.Role === "ald_veihjelp"; });
        }
    };
    InsuranceContactsComponent.prototype.isDataAvailable = function () {
        if (!this.contacts) {
            return false;
        }
        return true;
    };
    return InsuranceContactsComponent;
}(AbstractModalFeature));
export { InsuranceContactsComponent };
