import { BigAlModule } from '../modules/bigal.module';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class TakeOverGuard implements CanActivate, OnDestroy {
    private subscription: Subscription;

    constructor(
        private navigationService: NavigationService,
        private bigAl: BigAlModule
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if type directly url - this.bigAl.isUserLoggedIn  returns False
        // this.bigAl.currentUser.Roles.some - returns nothig - changed to this.bigAl.getPreviousUserRoles()

        let isInTakeOver = false;
      // If driver
      if (this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.Driver || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.DevUser_Driver)) {
            return true;
        }
      // If admin
      if (this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.SystemAdminFleetAdmin || ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Admin)) {
            isInTakeOver = this.bigAl.getStorageValueForIsInTakeOverMode();
            if (isInTakeOver) {
                return true;
            }
            else {
                this.navigationService.forward('/fleet/overview', null);
                return false;
            }
        } else {
            // not driver, not business user, not admin
            return false;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
