import { BigAlModule } from './../modules/bigal.module';
import { OnDestroy } from '@angular/core';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
var FleetUserGuard = /** @class */ (function () {
    function FleetUserGuard(navigationService, bigAl) {
        this.navigationService = navigationService;
        this.bigAl = bigAl;
    }
    FleetUserGuard.prototype.canActivate = function (route, state) {
        // If the user is logged in, and that user is not a fleet admin, we redirect to login.
        // The logic related to if the user is not logged in, is not handled by this guard, but by the LoginGuard
        // if driver role
        if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.DevUser_Driver; })) {
            this.navigationService.forward("cars", null);
            return false;
        }
        // if business user role
        if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business; })) {
            this.navigationService.forward("dashboard", null);
            return false;
        }
        // if no admin role
        if (!this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.SystemAdminFleetAdmin || ur === UserRoleType.DevUser_Admin; })) {
            this.navigationService.forward("login", null);
            return false;
        }
        return true;
    };
    FleetUserGuard.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return FleetUserGuard;
}());
export { FleetUserGuard };
