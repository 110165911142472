import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export type PermissionStatusType = "granted" | "denied" | "prompt" | "notAvailable";

@Injectable()
export class BrowserService {

    isGeoEnabled: boolean;
    isGeoPermissionStatusEnabled: boolean;

    permissionStatusType: PermissionStatusType;

    private onPermissionChanged = new BehaviorSubject<PermissionStatusType>("prompt");
    onPermissionChanged$ = this.onPermissionChanged.asObservable();

    //private onGeoEnablingChanged = new BehaviorSubject<boolean>(false);
    //onGeoEnablingChanged$ = this.onGeoEnablingChanged.asObservable();

    constructor() {
        this.isGeoPermissionStatusEnabled = false;
        this.isGeoEnabled = false;
        this.permissionStatusType = "prompt";
    }

    private permissionStateChanged(_this: any, evt: Event): void {

        console.log(">> permissionStateChanged << ", _this);

        switch (_this.state) {
            case "granted":
                this.isGeoPermissionStatusEnabled = true;
                break;
            case "denied":
            case "prompt":
            case "notAvailable":
                this.isGeoPermissionStatusEnabled = false;
                break;
        }

        this.permissionStatusType = _this.state;
        this.onPermissionChanged.next(this.permissionStatusType);
    }

    isGeoCapable(): boolean {
        return (navigator && 'geolocation' in navigator);
    }

    isGeoPermissionCapable(): boolean {
        return (navigator && 'permissions' in navigator);
    }

    enableGeoLocationCompatibility(): Promise<boolean> {

        console.log(">> enableGeoLocationCompatibility <<");

        return new Promise((resolve, reject) => {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.isGeoEnabled = true;
                        resolve(true);
                    },
                    error => {
                        this.isGeoEnabled = false;
                        reject(false);
                    },
                    {
                        timeout: Infinity,
                        maximumAge: 0,
                        enableHighAccuracy: true
                    }
                );
            }
            else {
                this.isGeoEnabled = false;
                reject(false);
            }
        });
    }

    enableGeoPermissionCompatibility(): Promise<boolean> {

        console.log(">> enableGeoPermissionCompatibility <<");

        return new Promise<boolean>((resolve, reject) => {

            if (navigator && 'permissions' in navigator) {

                navigator['permissions'].query({ name: 'geolocation' })
                    .then((permissionStatus) => {

                        if (permissionStatus.state === "granted") {
                            this.isGeoPermissionStatusEnabled = true;
                            this.onPermissionChanged.next(permissionStatus.state);
                        }

                        permissionStatus.onchange = this.permissionStateChanged.bind(this);
                    });

                resolve(true);
            } else {
                this.isGeoPermissionStatusEnabled = false;
                this.permissionStatusType = "notAvailable";

                resolve(false);
            }
        });
    }
}
