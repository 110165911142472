import {
  Component,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: "app-mask",
  templateUrl: "./mask.component.html",
  animations: [
    trigger("animMask", [
      state("true", style({ opacity: 1 })),
      state("false", style({ opacity: 0 })),
      transition("* => *", animate("300ms ease-out"))
    ])
  ]
})
export class MaskComponent implements OnInit {

  public showMask: boolean;

  @Output() public onMaskClose = new EventEmitter<boolean>();

  constructor(
  ) { }

  ngOnInit() {
    this.showMask = true;
  }

  toggleMask() {
    this.showMask = !this.showMask;
  }

  onClose() {
    this.onMaskClose.emit(true);
  }
}
