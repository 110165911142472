import { Component, AfterViewInit } from '@angular/core';
import { AbstractFeature } from '../../../feature.abstract';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { Driver } from '../../../../../models/driver.model';
import { FleetUserService } from '../../../../../services/fleet-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fleet-take-over-bar',
  templateUrl: './fleet-take-over-bar.component.html',
  styleUrls: ['./fleet-take-over-bar.component.scss']
})
export class FleetTakeOverBarComponent extends AbstractFeature implements AfterViewInit {

  // data variables
  isInTakeOverMode: boolean;
  driver: Driver;
  fleetUserSubscription: Subscription;
  isApp = false;

  // content flags
  showChangeDepartment = true;
  // feature flags

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private fleetUserService: FleetUserService
  ) {
    super(bigAl, theBen);
    this.isApp = this.bigAl.appSettings.IsApp;
  }

  ngAfterViewInit(): void {
    let takeoverBar = document.getElementById('fleet_admin_takeover_bar');
    if (takeoverBar) {
      takeoverBar.scrollIntoView();
    }
  }

  protected setEventsToWatch(): void {
    this.watchEvent('currentDriver');

    this.fleetUserSubscription = this.fleetUserService.isInTakeOverMode.subscribe(isInTakeOverMode => {
      this.isInTakeOverMode = isInTakeOverMode;
    });

    return;
  }

  protected setData(): void {
    if (this.bigAl.currentDriver) {
      this.driver = this.bigAl.currentDriver;
    }
  }

  protected getData(): void {
    if (!this.bigAl.currentDriver) {
      this.bigAl.getDriver();
    } else {
      this.setData();
    }
  }

  public isDataAvailable(): boolean {
    if (this.driver && this.isInTakeOverMode) {
      return true;
    }
    return false;
  }

  exitTakeOver(event) {
    event.stopPropagation();
    this.bigAl.revertTakeOverDriver();
  }

  public changeDriver(): void {
    this.theBen.action(this, "change-driver");
  }

  public changeDriverNumber($event: any): void {
    $event.stopPropagation();
    this.theBen.action(this, "change-driver-number");
  }

  public changeDepartment(): void {
    this.theBen.action(this, "department");
  }

}
