<app-modal *ngIf="appointmentPlace && appointment">
  <div modal-header>
    <app-translate translationName="{{appointmentPlace.ModelName + '.update_header_t'}}">
      <span translate-text>
        {{ appointmentPlace.ModelName + '.update_header_t' | translate }}
      </span>
    </app-translate>
  </div>
  <div modal-body>
    <div class="sub-box">
      <h2>
        <app-translate translationName="{{appointmentPlace.ModelName + '.update_sub_header_t'}}">
          <span translate-text>
            {{ appointmentPlace.ModelName + '.update_sub_header_t' | translate }}
          </span>
        </app-translate>
      </h2>
      <p class="font-numbers">
        {{ appointmentPlace.Name }}
        <br> {{ appointmentPlace.Street }}
        <br> {{ appointmentPlace.PostalCodeAndCity }}
        <br>
        <a *ngIf="appointmentPlace" [href]="'tel:'+appointmentPlace.Telephone">
          <button class="font-numbers" mat-raised-button color="primary" type="button">
            <mat-icon class="icon-btn icon-md" svgIcon="phone"></mat-icon>
            {{ appointmentPlace.Telephone }}
          </button>
        </a>
      </p>
      <br>
      <div>
        <h2>
          <app-translate translationName="UpdateBookingComponent.current_appointment_t">
            <span translate-text>
              {{ 'UpdateBookingComponent.current_appointment_t' | translate }}
            </span>
          </app-translate>
        </h2>
        <hr />
        <p>
          {{ appointment.Appointment | localizedDate: "EEEE, MMMM d, y 'at' HH:mm" }}
        </p>
      </div>
      <br />
      <form [formGroup]="domainForm">
        <div class="border-full-blue">
          <h2>
            <app-translate translationName="UpdateBookingComponent.dates_from_t">
              <span translate-text>
                {{ 'UpdateBookingComponent.dates_from_t' | translate }}
              </span>
            </app-translate>
          </h2>
          <div class="form-group">
            <mat-form-field>
              <input matInput readonly formControlName="date" [matDatepicker]="picker" min="{{ today |  date:'yyyy-MM-dd'}}" placeholder="{{ 'UpdateBookingComponent.pick_date_p' | translate}}">
              <div *ngIf="isEditing">
                <app-translate translationName="UpdateBookingComponent.pick_date_p">
                  <span translate-text>{{ 'UpdateBookingComponent.pick_date_p' | translate}}</span>
                </app-translate>
              </div>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-hint align="end"></mat-hint>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group">
            <div *ngFor="let timeslot of availableTimes; let j = index">
              <p class="form-group-title grey-text">
                <app-translate translationName="UpdateBookingComponent.times_from_t">
                  <span translate-text>
                    {{ 'UpdateBookingComponent.times_from_t' | translate }}
                  </span>
                </app-translate>
                {{ timeslot.AvailableBookingDate | localizedDate:'fullDate'}}
              </p>
              <div class="chip-radio-buttons">
                <span class="input-group" *ngFor="let time of timeslot.AvailableBookingTimes; let i = index">
                  <input type='radio' [value]='time' [id]='j*100+i' formControlName="selectedDateTime" />
                  <label class="font-numbers" [for]='j*100+i'> {{ time | date:'HH:mm' }}</label>
                </span>
              </div>
            </div>
            <mat-error>
              <div *ngIf="this.domainForm.controls['selectedDateTime'].touched && this.domainForm.controls['selectedDateTime'].hasError('required')">
                <app-translate translationName='UpdateBookingComponent.selectedDateTime_required_e'>
                  <span translate-text>
                    {{'UpdateBookingComponent.selectedDateTime_required_e' | translate}}
                  </span>
                </app-translate>
              </div>
            </mat-error>
          </div>
          <button mat-raised-button color="primary" type="button" (click)="onLaterClick()">
            <app-translate translationName="UpdateBookingComponent.later_times_t">
              <span translate-text>
                {{ 'UpdateBookingComponent.later_times_t' | translate }}
              </span>
            </app-translate>
          </button>
          <br>
        </div>
        <br>
        <h2 class="no-margin no-padding">
          <app-translate translationName="UpdateBookingComponent.appointment_date_t">
            <span translate-text>
              {{ 'UpdateBookingComponent.appointment_date_t' | translate }}
            </span>
          </app-translate>
        </h2>
        <p *ngIf="currentSelectedTimeslot">
          <app-translate translationName='UpdateBookingComponent.t_tire_center_have_choosen'>
            <span translate-text>
              {{'UpdateBookingComponent.t_tire_center_have_choosen' | translate}}
            </span>
          </app-translate>
          {{ currentSelectedTimeslot | localizedDate: "EEEE, d MMMM, y ' - ' HH:mm" }}
        </p>
        <p *ngIf="!currentSelectedTimeslot">
          <app-translate translationName='UpdateBookingComponent.t_tire_center_no_time'>
            <span translate-text>
              {{'UpdateBookingComponent.t_tire_center_no_time' | translate}}
            </span>
          </app-translate>
        </p>
        <br>
        <br> {{appointmentPlace.Name}}
        <app-translate translationName='UpdateBookingComponent.t_tire_center_phone_for_reminder'>
          <span translate-text>
            {{'UpdateBookingComponent.t_tire_center_phone_for_reminder' | translate}}
          </span>
        </app-translate>
        <div class="form-group">
          <mat-form-field>
            <input matInput formControlName="mobilePhoneNumber" placeholder="{{ 'UpdateBookingComponent.phone_for_reminder_p'| translate}}">
            <div *ngIf="isEditing">
              <app-translate translationName="UpdateBookingComponent.phone_for_reminder_p">
                <span translate-text>{{ 'UpdateBookingComponent.phone_for_reminder_p'| translate}}</span>
              </app-translate>
            </div>
            <mat-hint align="end">
              <app-translate translationName='UpdateBookingComponent.phone_for_reminder_h'>
                <span translate-text>{{'UpdateBookingComponent.phone_for_reminder_h' | translate}}</span>
              </app-translate>
            </mat-hint>
            <mat-error>
              <div *ngIf="this.domainForm.controls['mobilePhoneNumber'].hasError('required')">
                <app-translate translationName='UpdateBookingComponent.mobilePhoneNumber_required_e'>
                  <span translate-text>
                    {{'UpdateBookingComponent.mobilePhoneNumber_required_e' | translate}}
                  </span>
                </app-translate>
              </div>
            </mat-error>
            <mat-error>
              <div *ngIf="this.domainForm.controls['mobilePhoneNumber'].hasError('phone')">
                <app-translate translationName='UpdateBookingComponent.mobilePhoneNumber_phone_e'>
                  <span translate-text>
                    {{'UpdateBookingComponent.mobilePhoneNumber_phone_e' | translate}}
                  </span>
                </app-translate>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutGap="40px">
          <button mat-raised-button color="warn" type="button" (click)="action('modal')">
            <app-translate translationName='UpdateBookingComponent.b_cancel'>
              <span translate-text>
                {{'UpdateBookingComponent.b_cancel' | translate}}
              </span>
            </app-translate>
          </button>
          <button mat-raised-button color="primary" type="submit" (click)="onFormSubmit()">
            <app-translate translationName='UpdateBookingComponent.b_book'>
              <span translate-text>
                {{'UpdateBookingComponent.b_book' | translate}}
              </span>
            </app-translate>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div modal-footer>

  </div>
</app-modal>