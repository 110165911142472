import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Interfaces
import { IScript, ICodeblock } from "../sharedtypes/interfaces/sharedtypes.interface";

//
@Injectable({
    providedIn: "root"
})
export class ResourceInjectorService {

    // #region Notifications

    private onResourceInjected = new BehaviorSubject<string>(null);
    onResourceInjected$ = this.onResourceInjected.asObservable();

    private onResourceInjectionFailed = new BehaviorSubject<string>(null);
    onResourceInjectionFailed$ = this.onResourceInjectionFailed.asObservable();

    // #endregion

    constructor() { }

    // #region Injectors

    injectScript(script: IScript, $context?: HTMLElement): boolean;
    injectScript(script: ICodeblock, $context?: HTMLElement): boolean;
    injectScript(script: any, $context?: HTMLElement): boolean {

        // Default to the HEAD tag
        if (!$context) {
            $context = document.querySelector("head");
        }

        // Create tag
        const tag: HTMLScriptElement = document.createElement("script");
        tag.dataset.identifier = script.identifier;

        // Determine the script type
        if (this.isCDNScript(script)) {

            tag.src = script.src;
            tag.type = script.type ? script.type : "text/javascript";

            // Append to the DOM
            return this.append(tag, $context, script.identifier);

        } else if (this.isCodeblock(script)) {

            tag.innerHTML = script.code;
            tag.type = "text/javascript";

            // Append to the DOM
            return this.append(tag, $context, script.identifier);

        } else {
            console.warn("Unsupported code injection: ", JSON.stringify(script));
            this.onResourceInjectionFailed.next(script.identifier);
            return false;
        }
    }

    // Add other resource injectors here
    // ....

    // #endregion

    // #region Guards

    private isCDNScript(script: IScript | string): script is IScript {

        if (typeof script === "string") {
            return false;
        }

        return (<IScript>script).src !== undefined && (<IScript>script).src !== null;
    }

    private isCodeblock(script: ICodeblock | string): script is ICodeblock {

        if (typeof script === "string") {
            return false;
        }

        return (<ICodeblock>script).code !== undefined && (<ICodeblock>script).code !== null;
    }

    // #endregion

    // #region Internal helpers
    private append($tag: HTMLElement, $context: HTMLElement, identifier: string): boolean {

        let didInject;

        try {

            didInject = $context.appendChild($tag);

            // Notify listeners
            if (didInject) {
                this.onResourceInjected.next(identifier);
            }
        }
        catch (err) {
            this.onResourceInjectionFailed.next(identifier);
        }

        return didInject !== undefined
    }
    // #endregion
}
