import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { Router, NavigationExtras } from "@angular/router";
import { RouteStateService } from "./routeState.service";

@Injectable()
export class NavigationService {

  constructor(
    private location: Location,
    private router: Router,
    private routeStateService: RouteStateService
  ) {
  }

  back(): void {
    this.router.navigateByUrl(this.routeStateService.getPreviousUrl());
  }

  forward(route: string, routeId: string, extras?: NavigationExtras) {
    if (!route) {
      throw new Error("No route specified!");
    }

    if (!routeId) {
      return this.router.navigate([route], extras);
    }
    else {
      return this.router.navigate([route, routeId], extras);
    }
  }

  reload() {
    location.reload();
  }

  closeModal(): void {
    if (this.router.url.includes('(modal:')) {
      this.location.back();
    }
  }



  openModal(parameters: (string | string[]) | (string | string[])[]): void {
    this.router.navigate([{ outlets: { modal: parameters } }]);
  }

  openNewTab(route: string, isApp: boolean): void {
    if (isApp) {
      location.href = route;
    } else {
      window.open(route, '_blank');
    }

  }
}
