import { Appointment } from "./appointment.model";

export class AppointmentPlace {

    public City: string;
    public PostalCode: string;
    constructor(
        public ModelName: string,
        public Name: string,
        public Street: string,
        public PostalCodeAndCity: string,
        public Telephone: string,
        public Bookings: Array<Appointment> = []
    ) {
    }
}
