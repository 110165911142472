import { FleetUser } from './../../../../models/fleet-user.model';
import { Component } from '@angular/core';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { AbstractFeature, PrefilledContentEntry } from '../../feature.abstract';
import { UserRoleType } from '../../../../models/enums/user-role-type.enum';
import { ActivatedRoute } from '@angular/router';
import { Constants } from './../../../../helpers/constants';
import { AppUserStore } from '../../../../stores/DataStores';

@Component({
  selector: 'app-fleet-user-profile-form',
  templateUrl: './fleet-user-profile-form.component.html',
  styleUrls: ['./fleet-user-profile-form.component.scss']
})
export class FleetUserProfileFormComponent extends AbstractFeature {

  // data variables
  public currentFleetUser: FleetUser;

  constructor(
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService,
    private route: ActivatedRoute,
    private appUserStore: AppUserStore,
  ) {
    super(bigAl, theBen);
  }

  public getFormObject() {
    let prefilledContent = new Map<string, PrefilledContentEntry[]>();
    if (this.currentFleetUser) {
      prefilledContent.set("Name", [new PrefilledContentEntry("Value", this.currentFleetUser.Name)]);
      prefilledContent.set("Username", [new PrefilledContentEntry("Value", this.currentFleetUser.Username)]);
      prefilledContent.set("Email", [new PrefilledContentEntry("Value", this.currentFleetUser.Email)]);
      prefilledContent.set("Telephone", [new PrefilledContentEntry("Value", this.currentFleetUser.Telephone)]);
    }

    return super.getFormObject("FleetUserProfileForm", prefilledContent);
  }

  public getRuleObject() {
    return super.getRuleObject("FleetUserProfileForm");
  }

  setEventsToWatch() {
    this.watchEvent(Constants.Event_CurrentFleetUser);
    return;
  }

  public isDataAvailable(): boolean {
    if (this.currentFleetUser) {
      return true;
    }
    return false;
  }

  getData() {
    if (!this.bigAl.currentFleetUser) {
      if (this.appUserStore.appUser.Roles.some(r => r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.SystemAdminFleetAdmin)) {
        this.bigAl.getFleetUser();
      }
      else if (this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser)) {
        this.subscribe(this.route.params.subscribe(params => {
          if (params['id']) {
            this.bigAl.getFleetUserByUsername(params['id']);
          }
        }));
      }
    }
    else {
      this.setData();
    }
  }

  setData() {
    if (this.bigAl.currentFleetUser) {
      this.currentFleetUser = this.bigAl.currentFleetUser;
    }
  }
}
