<app-modal>
  <div modal-header>
    Update translation
  </div>
  <div modal-body>
    <div class="translations">
      <form [formGroup]="translationForm">
        <div formArrayName="aldTranslations">
          <div class="list-item" *ngFor="let item of aldTranslations.controls; let translationIndex=index"
            [formGroupName]="translationIndex">
            <input class="translation-language" type="text" formControlName="language" readonly>
            <textarea type="text" formControlName="translation"></textarea>
            <input class="translation-hidden" type="text" formControlName="languageCode" readonly>
            <input class="translation-hidden" type="text" formControlName="companyId" readonly>
          </div>
          <br>
          <!-- <div *ngIf="!changeNffTranslations" (click)="addNffTranslations()">
            <mat-checkbox>Change NF Fleet translations</mat-checkbox>
          </div> -->
        </div>

        <div>
          <mat-checkbox [checked]="hasDifferentNffTranslations" (change)="toggleNffTranslations()">Different NF Fleet
            translations</mat-checkbox>
        </div>
        <br>
        <div>

          <div formArrayName="nffTranslations">
            <div class="list-item" *ngFor="let item of nffTranslations.controls; let translationIndex=index"
              [formGroupName]="translationIndex">
              <input class="translation-language" type="text" formControlName="language" readonly>
              <textarea type="text" formControlName="translation"></textarea>
              <input class="translation-hidden" type="text" formControlName="languageCode" readonly>
              <input class="translation-hidden" type="text" formControlName="companyId" readonly>
            </div>
            <br>
          </div>



          <button mat-raised-button (click)="closeModal()" type="button">
            Cancel
          </button>
          <button [disabled]="translationForm.invalid" mat-raised-button color="primary"
            (click)="saveTranslation(); closeModal()">
            Ok
          </button>
        </div>
      </form>
    </div>
  </div>
  <div modal-footer>

  </div>
</app-modal>