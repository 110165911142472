import { Component, OnInit, OnDestroy } from '@angular/core';
import { BigAlModule } from '../../../../modules/bigal.module';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../services/modals/modal.service';
import { Translation } from '../../../../models/translation.model';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  selector: 'app-update-translation',
  templateUrl: './update-translation.component.html',
  styleUrls: ['./update-translation.component.scss'],
  exportAs: "modal"
})
export class UpdateTranslationComponent implements OnInit, OnDestroy {
  private modalSubscription: Subscription;
  private translateServiceSubscription: Subscription;
  public translationForm: FormGroup;
  private translationName: string;
  public hasDifferentNffTranslations = false;
  private translationsToSave: Translation[] = [];
  public readonly aldCompanyName = "ALD";
  public readonly nffCompanyName = "NF Fleet";

  constructor(
    private bigAl: BigAlModule,
    private modalService: ModalService<Translation>,
    private theBen: ComponentConfigService,
    private translateService: CustomTranslateService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService) {
  }

  ngOnInit() {
    this.translationForm = this.formBuilder.group({
      aldTranslations: this.formBuilder.array([]),
      nffTranslations: this.formBuilder.array([])
    });

    // For getting the translations to display
    this.modalSubscription = this.modalService.getData()
      .subscribe((languageTranslations) => {
        this.translationName = languageTranslations[0].TranslationName;
        this.addTranslations(languageTranslations);
      });

    // To control the NFF translations checkbox
    this.translateServiceSubscription = this.translateService.getHasNffTranslations()
      .subscribe((hasDifferentNffTranslations) => {
        this.hasDifferentNffTranslations = hasDifferentNffTranslations;
      });
  }

  addTranslations(translationList: Translation[]): void {
    translationList.forEach(translation => {
      if (translation.CompanyId === this.translateService.aldCompanyId) {
        let language = this.getLanguageText(this.aldCompanyName + " (generic)", translation.LanguageCode);
        this.addAldTranslation(translation.TranslationValue, translation.LanguageCode, language, translation.CompanyId);
      } else {
        let language = this.getLanguageText(this.nffCompanyName, translation.LanguageCode);
        this.addNffTranslation(translation.TranslationValue, translation.LanguageCode, language, translation.CompanyId);
      }
    });
  }

  addAldTranslation(translationValue: string, languageCode: string, language: string, companyId: number): void {
    this.aldTranslations.push(this.formBuilder.group({ translation: [translationValue, Validators.required], languageCode: languageCode, language: language, companyId: companyId }));
  }

  addNffTranslation(translationValue: string, languageCode: string, language: string, companyId: number): void {
    this.nffTranslations.push(this.formBuilder.group({ translation: [translationValue, Validators.required], languageCode: languageCode, language: language, companyId: companyId }));
  }

  get aldTranslations() {
    return this.translationForm.get('aldTranslations') as FormArray;
  }

  get nffTranslations() {
    return this.translationForm.get('nffTranslations') as FormArray;
  }

  saveTranslation() {
    let aldCompanyId = this.translateService.aldCompanyId;
    let nffCompanyId = this.translateService.nffCompanyId;

    // Add ALD translations
    this.addTranslationsToSave(this.translationForm.value.aldTranslations, aldCompanyId);

    // If 'different nff translations' is checked save NFF translations
    if (this.hasDifferentNffTranslations) {
      this.addTranslationsToSave(this.translationForm.value.nffTranslations, nffCompanyId);
      // Else overwrite NFF translation with the ALD translation as it should be used for both companies
    } else {
      this.addTranslationsToSave(this.translationForm.value.aldTranslations, nffCompanyId);
    }

    this.bigAl.postTranslations(this.translationsToSave);
  }

  addTranslationsToSave(translations: any, companyId: number) {
    translations.forEach(element => {
      let updatedTranslation = new Translation(this.translationName, element.translation, element.languageCode, companyId);
      this.translationsToSave.push(updatedTranslation);
    });
  }

  toggleNffTranslations() {
    // If we have different NFF translations, remove them
    if (this.hasDifferentNffTranslations) {
      this.nffTranslations.controls.splice(0);
      this.nffTranslations.controls.forEach(c => {
        c.clearValidators();
      });

      this.nffTranslations.updateValueAndValidity({ onlySelf: true });
      this.translationForm.updateValueAndValidity({ onlySelf: true });
      this.translateService.setHasNffTranslations(false);

      // else add empty inputs for the user to enter the NFF translations
    } else {
      let languages = this.translateService.getLanguageCodes();

      languages.forEach(lang => {
        let languageText = this.getLanguageText(this.nffCompanyName, lang);
        this.addNffTranslation("", lang, languageText, this.translateService.nffCompanyId);
      });

      this.translateService.setHasNffTranslations(true);
    }
  }

  getLanguageText(companyDisplayName: string, language: string): string {
    return companyDisplayName + " - " + this.translateService.getLanguageText(language) + ":";
  }

  action(location: string, id?: string[]) {
    (id) ? this.theBen.action(this, location, id) : this.theBen.action(this, location);
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }

    this.translateService.setHasNffTranslations(false);
    if (this.translateServiceSubscription) {
      this.translateServiceSubscription.unsubscribe();
    }
  }

  // We have disabled actions in translations mode so use router
  closeModal() {
    this.navigationService.back();
  }

}
