
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

// Common
import { BigAlModule } from "../../modules/bigal.module";

// Services
import { GeoLocationService } from "./geolocation.service.v2";

// Types
import { DriverJournalDailyLog } from "../../models/driverjournal/driverJournalDailyLog.model";
import { TripTypeEnum } from "../../models/enums/TripType.enum";
import { AldPosition } from "../../models/geo-tracking-device-motion/ald-position.model";
import { DriverJournalTrip, TripPart } from "../../models/driverjournal/driverjournal-trip.model";
import { ComponentConfigService } from "../componentConfig.service";
import { Constants } from "../../helpers/constants";

@Injectable()
export class RecordTripService {

  private subscriptions: Subscription = new Subscription();
  private recordingTripMenue = new BehaviorSubject<boolean>(false);
  private regNo;
  private contractId;
  private useTestCoords = false;
  private dailyLog: DriverJournalDailyLog = null;

  constructor(
    protected theBen: ComponentConfigService,
    protected bigAl: BigAlModule,
    private geolocationService: GeoLocationService,
  ) {
    this.registerSubscription();
  }

  private registerSubscription() {
    this.subscriptions.add(this.bigAl.getDataStream().subscribe(event => {
      if (event === Constants.Event_CurrentVehicle || event === Constants.Event_CurrentContracts) {
        this.setData();
      }
    }));
  }

  setData() {
    if (this.bigAl.currentVehicleContract) {
      this.contractId = this.bigAl.currentVehicleContract.Id;
    }

    if (this.bigAl.currentVehicle) {
      this.regNo = this.bigAl.currentVehicle.RegistrationNumber;
    }
  }

  showMenu() {
    this.toggleRecordingTripMenu(true);
  }

  hideMenu() {
    this.toggleRecordingTripMenu(false);
  }

  showRecordingTripMenue(): Observable<boolean> {
    return this.recordingTripMenue.asObservable();
  }

  toggleRecordingTripMenu(value: boolean) {
    this.recordingTripMenue.next(value);
  }

  saveTrip(tripType: TripTypeEnum, purpose: string, isExtraCar: boolean, isFinalSave: boolean): Promise<{ success: boolean, message: string }> {

    const prom = new Promise<{ success: boolean, message: string }>((resolve, reject) => {

      if (!this.dailyLog) {
        this.dailyLog = this.getDefaultDailyLog();
      }

      let positions: AldPosition[] = this.geolocationService.getPositions(); // Get a copy of the recorded positions
      if (positions.length <= 4) {
        reject({ success: false, message: "no-positions-recorded" });
      } else {
        const distance: number = Math.round(this.geolocationService.getDistance());
        const dataSource = (this.dailyLog.TripType as TripTypeEnum === TripTypeEnum.BusinessTrip as TripTypeEnum) ? 1 : 0;
        const tripPart: TripPart = new TripPart(positions);
        const trip: DriverJournalTrip = new DriverJournalTrip(null, null, dataSource, this.useTestCoords, isFinalSave, [tripPart]);

        try {
          this.geolocationService.getAddressFromLatLong(positions[0].Latitude, positions[0].Longitude).then(
            start => {
              this.dailyLog.Start = start;


              this.geolocationService.getAddressFromLatLong(positions[positions.length - 1].Latitude, positions[positions.length - 1].Longitude).then(end => {

                let logId;
                let tripId;

                // Store id if it has been saved previusly
                if (this.dailyLog && this.dailyLog.Trip && this.dailyLog.Trip.LogDailyId) {
                  logId = this.dailyLog.Trip.LogDailyId;
                }

                if (this.dailyLog && this.dailyLog.Trip && this.dailyLog.Trip.TripId) {
                  tripId = this.dailyLog.Trip.TripId;
                }

                this.dailyLog.Trip = trip;

                // Update if it has been set
                if (logId) {
                  this.dailyLog.Trip.LogDailyId = logId;
                }
                if (tripId) {
                  this.dailyLog.Trip.TripId = tripId;
                }

                this.dailyLog.DataSource = trip.DataSource;
                this.dailyLog.End = end;
                this.dailyLog.Distance = distance;
                this.dailyLog.TripType = tripType;
                this.dailyLog.Purpose = purpose;
                this.dailyLog.IsExtraCarSelected = isExtraCar;

                if (this.dailyLog && this.dailyLog.Trip.LogDailyId) {

                  this.bigAl.putDriverJournalDailyLog(this.dailyLog)
                    .then((log: DriverJournalDailyLog) => {
                      resolve({ success: true, message: "" });
                    })
                    .catch((err) => {
                      if (isFinalSave) {
                        reject({ success: false, message: "failed-to-save" });
                      }
                    });
                } else {
                  this.bigAl.postDriverJournalDailyLog(this.dailyLog, isFinalSave)
                    .then((log: DriverJournalDailyLog) => {
                      this.dailyLog.Trip.LogDailyId = log.Trip.LogDailyId;
                      this.dailyLog.Trip.TripId = log.Trip.TripId;

                      resolve({ success: true, message: "" });
                    })
                    .catch((err) => {
                      if (isFinalSave) {
                        reject({ success: false, message: "failed-to-save" });
                      }
                    });
                }
              }).catch(err => {
                if (isFinalSave) {
                  reject({ success: false, message: "critical-error" });
                }
              });
            }).catch(err => {
              if (isFinalSave) {
                reject({ success: false, message: "critical-error" });
              }
            });
        } catch (err) {
          if (isFinalSave) {
            reject({ success: false, message: "critical-error" });
          }
        }
      }

    });

    return prom;
  }

  initialTripSave(tripType: TripTypeEnum, purpose: string, isExtraCar: boolean): Promise<{ success: boolean, message: string }> {

    const prom = new Promise<{ success: boolean, message: string }>((resolve, reject) => {

      if (!this.dailyLog) {
        this.dailyLog = this.getDefaultDailyLog();
      }

      let positions: AldPosition[] = this.geolocationService.getPositions(); // Get a copy of the recorded positions

      const distance: number = Math.round(this.geolocationService.getDistance());
      const dataSource = (this.dailyLog.TripType as TripTypeEnum === TripTypeEnum.BusinessTrip as TripTypeEnum) ? 1 : 0;
      const tripPart: TripPart = new TripPart(positions);
      const trip: DriverJournalTrip = new DriverJournalTrip(null, null, dataSource, this.useTestCoords, false, [tripPart]);

      try {
        this.dailyLog.Trip = trip;
        this.dailyLog.DataSource = trip.DataSource;
        this.dailyLog.End = "";
        this.dailyLog.Start = "";
        this.dailyLog.Distance = distance;
        this.dailyLog.TripType = tripType;
        this.dailyLog.Purpose = purpose;
        this.dailyLog.IsExtraCarSelected = isExtraCar;

        this.bigAl.postDriverJournalDailyLog(this.dailyLog, false)
          .then((log: DriverJournalDailyLog) => {
            this.dailyLog.Trip.LogDailyId = log.Trip.LogDailyId;
            this.dailyLog.Trip.TripId = log.Trip.TripId;

            resolve({ success: true, message: "" });
          })
          .catch((err) => {
          });
      } catch (err) {
        reject({ success: false, message: "critical-error" });
      }
    });

    return prom;
  }

  private getDefaultDailyLog(): DriverJournalDailyLog {

    let model = new DriverJournalDailyLog();
    model.ContractId = this.contractId;
    model.RegistrationNumber = this.regNo;
    model.IsCreate = true;
    model.Date = new Date().toLocaleDateString('sv');

    return model;
  }

  resetTrip() {
    this.dailyLog = this.getDefaultDailyLog();
  }

}
