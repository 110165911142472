import { Subject } from "rxjs";
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.loaderSubject = new Subject();
        this.spinnerState = this.loaderSubject.asObservable();
    }
    SpinnerService.prototype.show = function () {
        this.loaderSubject.next(true);
        this.isShowing = true;
    };
    SpinnerService.prototype.hide = function () {
        this.loaderSubject.next(false);
        this.isShowing = false;
    };
    return SpinnerService;
}());
export { SpinnerService };
