import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AppUserStore } from '../../../stores/DataStores';
import { ComponentConfigService } from '../../../services/componentConfig.service';
var ConsentComponent = /** @class */ (function () {
    function ConsentComponent(userStore, theBen, dialogRef, data) {
        this.userStore = userStore;
        this.theBen = theBen;
        this.dialogRef = dialogRef;
        this.type = data.consentType;
    }
    ConsentComponent.prototype.ngOnInit = function () {
    };
    ConsentComponent.prototype.isContent = function (name) {
        return this.theBen.isContent(this, name);
    };
    ConsentComponent.prototype.action = function (name) {
        this.theBen.action(this, name);
        this.dialogRef.close(false);
    };
    ConsentComponent.prototype.yes = function () {
        var _this = this;
        // the current settings
        var settings = this.userStore.appUser.UserSettings;
        // Update the userSettings with the user selected option
        settings.ContentSettings.find(function (setting) { return setting.Settings[_this.type] !== undefined; }).Settings[this.type] = "true";
        // Save to the backend
        this.userStore.saveSettings(settings, this.userStore.appUser.AuthenticationUserId)
            .then(function () {
            _this.dialogRef.close(true);
        })
            .catch(function () {
            _this.dialogRef.close(false);
        });
    };
    ConsentComponent.prototype.no = function () {
        this.dialogRef.close(false);
    };
    return ConsentComponent;
}());
export { ConsentComponent };
