export class InsuranceClaimFinish {
    constructor(
        public RegistrationNumber: string, //
        public Driver: string,
        public CompanyName: string,
        public CompanyAddress: string,
        public Email: string,
        public Phone: string, //
        public AccidentLocation: string,
        public AccidentTime: string,
        public PoliceStationJounalNumber: string,
        public VehicleDamageDescription: string,
        public DescriptionOfAccident: string,
        public GiveAway: string,
        public LaneShift: string,
        public Reversing: string,
        public LeftTurn: string,
        public RigthTurn: string,
        public WhoIsToBlame: string, //
        public CounterPartName: string,
        public CounterPartAddress: string,
        public CounterPartPhone: string,
        public CounterPartRegistrationNumber: string,
        public CounterPartInsuranceCompany: string,
        public CounterPartCarDamageDescription: string,
        public CounterPartMultiple: string,
        public WitnessName: string,
        public WitnessAddress: string,
        public WitnessPhone: string,
        public WitnessLocation: string,
        public InjuredPersonsNameAndAddress: string,
        public InjuredPersonsCprNumbers: string,
        public InjuredPersonsPhoneNumbers: string,
        public InjuredPersonsInjuryDescription: string,
        public MiscDamagesDescription: string,
        public Attachments: Array<string>,
        public CprNumber: string,
        public AccidentDate?: Date,
        public AccidentDuringWorkHours?: boolean,
        public DayLight?: boolean,
        public DrivesLicence?: boolean,
        public PoliceReport?: boolean,
        public DrugTest?: boolean,
        public DriverInjury?: boolean,
        public PassengerInjury?: boolean,
        public CounterPartInjury?: boolean
    ) {

    }
}


/*
DayLight = null
*/
