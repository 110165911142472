import { Injectable } from "@angular/core";

// Third-party
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

// Models
import { Translation } from "../models/translation.model";
import { LanguageTranslation } from "../models/languageTranslationResponse.model";

@Injectable()
export class CustomTranslateService {

  isEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasNffTranslations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly aldCompanyId = 1;
  readonly nffCompanyId = 2;

  nffTranslations: LanguageTranslation[] = [];
  aldTranslations: LanguageTranslation[] = [];

  constructor(private translateService: TranslateService) {
    this.translateService.defaultLang = "en";
  }

  getIsEditing() {
    return this.isEditing.asObservable();
  }

  setIsEditing(data: boolean) {
    this.isEditing.next(data);
  }

  getHasNffTranslations() {
    return this.hasNffTranslations.asObservable();
  }

  setHasNffTranslations(data: boolean) {
    this.hasNffTranslations.next(data);
  }

  loadCompanyTranslations(data: LanguageTranslation[], companyId: number) {
    this.aldTranslations = [];
    this.nffTranslations = [];

    data.forEach(translation => {
      if (translation.CompanyId === companyId) {
        this.translateService.setTranslation(translation.LanguageCode, JSON.parse(translation.TranslationJson));
      }

      let companyTranslation = this.loadCompanyTranslation(translation);

      if (translation.CompanyId === this.aldCompanyId) {
        this.aldTranslations.push(companyTranslation);
      } else if (translation.CompanyId === this.nffCompanyId) {
        this.nffTranslations.push(companyTranslation);
      }
    });
  }

  private loadCompanyTranslation(translation: LanguageTranslation): LanguageTranslation {
    return new LanguageTranslation(translation.LanguageCode, JSON.parse(translation.TranslationJson), translation.CompanyId);
  }

  updateCompanyTranslations(translationsToSave: Translation[], currentCompany: number) {
    let currentCompanyTranslations = translationsToSave.filter(t => t.CompanyId === currentCompany);
    let translationName = currentCompanyTranslations[0].TranslationName;

    let keyParts = [];
    if (translationName) {
      keyParts = translationName.split(".");
    }

    const propertyName = keyParts.pop();
    let translations = {};

    // Update ALD and NFF translations
    translationsToSave.forEach(t => {
      if (t.CompanyId === this.aldCompanyId) {
        this.setCompanyTranslation(this.aldTranslations, t.LanguageCode, keyParts[0], propertyName, t.TranslationValue);
      }
      else if (t.CompanyId === this.nffCompanyId) {
        this.setCompanyTranslation(this.nffTranslations, t.LanguageCode, keyParts[0], propertyName, t.TranslationValue);
      }
    });

    // Update translation values in translateService
    currentCompanyTranslations.forEach(t => {
      // Set method doesn't support nested translations so we need to use setTranslation with merge = true
      keyParts.reduce(function (o, k) {
        return o[k] = o[k] || {};
      }, translations)[propertyName] = t.TranslationValue;

      this.translateService.setTranslation(t.LanguageCode, translations, true);
    });
  }

  setCompanyTranslation(languageTranslation: LanguageTranslation[], languageCode: string, parentName: string, labelName: string, translationValue: string) {
    let translationIndex = languageTranslation.findIndex(translation => translation.LanguageCode === languageCode);
    if (translationIndex !== -1) {
      languageTranslation[translationIndex.toString()].TranslationJson[parentName.toString()][labelName.toString()] = translationValue;
    }
  }

  setLanguage(country: string) {
    this.translateService.currentLang = this.getCountryLanguage(country);
  }

  getCurrentLang() {
    return this.translateService.currentLang;
  }

  setLanguageByLang(lang: string) {
    this.translateService.currentLang = lang;
  }

  changeLanguageByLanguageCode(lang: string) {
    this.translateService.use(lang);
  }

  setTranslations(country: string, translations: object) {
    this.translateService.setTranslation(this.getCountryLanguage(country), translations, false);
  }

  setTranslation(countryLang: string, translations: object, shouldMerge?: boolean) {
    this.translateService.setTranslation(countryLang, translations, shouldMerge);
  }

  // Gets the translated value of a key(or an array of keys) or the key if the value was not found
  get(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  getLanguageCodes(): string[] {
    return this.translateService.getLangs();
  }

  getCountryLanguage(country: string): string {
    let countryLang: string;

    switch (country.toLowerCase()) {
      case "dk":
        countryLang = "da";
        break;

      case "se":
        countryLang = "sv";
        break;

      case "no":
        countryLang = "nb";
        break;
      default:
        countryLang = "en";
    }

    return countryLang;
  }

  getLanguageText(languageCode: string): string {
    switch (languageCode) {
      case "da":
        return "Danish";
      case "sv":
        return "Swedish";
      case "nb":
        return "Norwegian";
      case "en":
        return "English";
      case "fr":
        return "French";
      default:
        return "Unknown";
    }
  }

  public getAllCompanyTranslations(companyId: number, languageCode: string): any {
    switch (companyId) {
      case this.aldCompanyId:
        return this.aldTranslations.find(x => x.LanguageCode == languageCode).TranslationJson;
      case this.nffCompanyId:
        return this.nffTranslations.find(x => x.LanguageCode == languageCode).TranslationJson;
      default:
        return this.aldTranslations.find(x => x.LanguageCode == languageCode).TranslationJson;
    }
  }
}
