var WorkshopSearch = /** @class */ (function () {
    function WorkshopSearch(PostalCode, Latitude, Longitude, WithPickup, InsuranceOnly, Category, // only for NO and SE
    ServiceLinkOnly) {
        if (PostalCode === void 0) { PostalCode = null; }
        if (Latitude === void 0) { Latitude = 0; }
        if (Longitude === void 0) { Longitude = 0; }
        if (WithPickup === void 0) { WithPickup = false; }
        if (InsuranceOnly === void 0) { InsuranceOnly = false; }
        if (Category === void 0) { Category = null; }
        if (ServiceLinkOnly === void 0) { ServiceLinkOnly = false; }
        this.PostalCode = PostalCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.WithPickup = WithPickup;
        this.InsuranceOnly = InsuranceOnly;
        this.Category = Category;
        this.ServiceLinkOnly = ServiceLinkOnly;
    }
    return WorkshopSearch;
}());
export { WorkshopSearch };
