/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./field-placeholder-translate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../field-translation/field-translation.component.ngfactory";
import * as i3 from "../field-translation/field-translation.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./field-placeholder-translate.component";
import * as i7 from "../../../../translation/customTranslateService";
import * as i8 from "../../../../modules/bigal.module";
import * as i9 from "../../../../services/componentConfig.service";
var styles_FieldPlaceholderTranslateComponent = [i0.styles];
var RenderType_FieldPlaceholderTranslateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FieldPlaceholderTranslateComponent, data: {} });
export { RenderType_FieldPlaceholderTranslateComponent as RenderType_FieldPlaceholderTranslateComponent };
function View_FieldPlaceholderTranslateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-field-translation", [], null, null, null, i2.View_FieldTranslationComponent_0, i2.RenderType_FieldTranslationComponent)), i1.ɵdid(2, 114688, null, 0, i3.FieldTranslationComponent, [], { translationTagType: [0, "translationTagType"], formObject: [1, "formObject"], prop: [2, "prop"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translationTagType; var currVal_1 = _co.formObject; var currVal_2 = _co.prop; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_FieldPlaceholderTranslateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FieldPlaceholderTranslateComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditing; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FieldPlaceholderTranslateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-field-placeholder-translate", [], null, null, null, View_FieldPlaceholderTranslateComponent_0, RenderType_FieldPlaceholderTranslateComponent)), i1.ɵprd(135680, null, i5.TranslatePipe, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(2, 245760, null, 0, i6.FieldPlaceholderTranslateComponent, [i7.CustomTranslateService, i8.BigAlModule, i9.ComponentConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FieldPlaceholderTranslateComponentNgFactory = i1.ɵccf("app-field-placeholder-translate", i6.FieldPlaceholderTranslateComponent, View_FieldPlaceholderTranslateComponent_Host_0, { formObject: "formObject", prop: "prop" }, {}, []);
export { FieldPlaceholderTranslateComponentNgFactory as FieldPlaceholderTranslateComponentNgFactory };
