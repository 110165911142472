import { ValidatorFn, FormGroup, AbstractControl, Validators } from "@angular/forms";
import { Inject, forwardRef } from "@angular/core";

import { BigAlModule } from "../modules/bigal.module";
import { Rule } from "../models/rule-object.model";

export class ValidatorFactory {

  constructor(@Inject(forwardRef(() => BigAlModule)) public bigAl: BigAlModule) { }

  public getValidators(rules: Rule[], formGroup: FormGroup): ValidatorFn[] {
    let validators: ValidatorFn[] = [];
    for (let i = 0; i < rules.length; i++) {
      validators.push(this.getValidator(rules[i], formGroup));
    }
    return validators;
  }

  public getValidator(rule: Rule, formGroup?: FormGroup): ValidatorFn {
    switch (rule.Type) {
      case "required":
        return this.getRequiredValidator();
      case "dependentRequired":
        return this.getDependentRequiredValidator(this.hasValue, rule.ExpectsValues, rule.ExpectsControls, rule.DependentControls, formGroup);
      case "integer":
        return this.getIntegerValidator(this.hasValue);
      case "positive-number":
        return this.getPositiveNumberValidator(this.hasValue);
      case "length":
        return this.getLengthValidator(this.hasValue, rule.ExpectsValues[0]);
      case "higherThan":
        return this.getHigherThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
      case "higherThanCustom":
        return this.getHigherThanCustomValidator(this.hasValue, rule.ExpectsValues[0], rule.ExpectsControls[0], formGroup);
      case "lowerThan":
        return this.getLowerThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
      case "lowerThanDifference":
        return this.getLowerThanDifferenceValidator(this.hasValue, rule.ExpectsControls, formGroup);
      case "lowerOrEqualThan":
        return this.getLowerOrEqualThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
      case "higherOrEqualThan":
        return this.getHigherOrEqualThanValidator(this.hasValue, rule.ExpectsControls[0], formGroup);
      case "lowerOrEqualThanDifference":
        return this.getLowerOrEqualThanDifferenceValidator(this.hasValue, rule.ExpectsControls, formGroup);
      case "max":
        return this.getMaxValidator(this.hasValue, rule.ExpectsValues[0]);
      case "min":
        return this.getMinValidator(this.hasValue, rule.ExpectsValues[0]);
      case "phone":
        return this.getPhoneValidator(this.hasValue);
      case "email":
        return this.getEmailValidator(this.hasValue);
      case "password":
        return this.getPasswordValidator(this.hasValue);
      case "optionalpassword":
        return this.getOptionalPasswordValidator(this.hasValue);
      case "confirmpassword":
        return this.getConfirmPasswordValidator(formGroup);
      case "alphanumeric":
        return this.getOnlyAlphanumericValidator();
      case "postcode":
        return this.getPostcodeValidator();
      default: {
        return null;
      }
    }
  }

  getMinValidator(baseValidator: (AbstractControl) => boolean, min): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let value = Number.parseInt(input.value);
      return value >= min ? null : { min: true };
    };
  }

  getIntegerValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let pattern = /^\d+$/;
      return pattern.test(input.value) ? null : { 'integer': true };
    };
  }

  getPositiveNumberValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let pattern = /^\+?(0|[1-9]\d*)$/;
      return pattern.test(input.value) ? null : { 'positive-number': true };
    };
  }

  getPasswordValidation(input): boolean {
    function isAlphaNumeric(value: string): boolean {
      const pattern = /^[a-zA-Z0-9]+$/;
      return input.value !== null && pattern.test(input.value);
    }
    let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})([^åäö]*$)");
    let notAllowWord = !(input.value.toLowerCase().includes('automotive'));
    return notAllowWord && !isAlphaNumeric(input.value) && regex.test(input.value);
  }

  getPasswordValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    let currObj = this;
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      return currObj.getPasswordValidation(input) ? null : { password: true };
    };
  }

  getOptionalPasswordValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    let currObj = this;
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      return currObj.getPasswordValidation(input) ? null : { optionalpassword: true };
    };
  }

  getConfirmPasswordValidator(formGroup: FormGroup): ValidatorFn {
    return function (input) {
      let pswControl = formGroup.controls["Password"];
      if (pswControl) {
        return pswControl.value === input.value ? null : { confirmpassword: true };
      }
      return null;
    };
  }

  getLengthValidator(baseValidator: (AbstractControl) => boolean, length): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      return input.value.length > length || input.value.length < length ? null : { length: true };
    };
  }

  getLowerThanValidator(baseValidator: (AbstractControl) => boolean, expectsControl: string, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
        let compareValue = formGroup.get(expectsControl).value;
        return parseInt(input.value) < parseInt(compareValue) ? null : { lowerThan: true };
      }

      return null;
    };
  }

  getLowerOrEqualThanValidator(baseValidator: (AbstractControl) => boolean, expectsControl: string, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
        let compareValue = formGroup.get(expectsControl).value;
        return parseInt(input.value) <= parseInt(compareValue) ? null : { lowerOrEqualThan: true };
      }

      return null;
    };
  }

  getHigherOrEqualThanValidator(baseValidator: (AbstractControl) => boolean, expectsControl: string, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
        let compareValue = formGroup.get(expectsControl).value;
        return parseInt(input.value) >= parseInt(compareValue) ? null : { higherOrEqualThan: true };
      }

      return null;
    };
  }

  getLowerOrEqualThanDifferenceValidator(baseValidator: (AbstractControl) => boolean, expectsControls: string[], formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      let difference = 0;
      let minValue: number = null;
      let valuesToSubtractFrom: number[] = [];
      expectsControls.forEach(controlName => {
        let formControl = formGroup.get(controlName);
        if (!formControl || formControl.disabled) {
          return null;
        }

        let value = formControl.value;
        if (minValue === null) {
          minValue = parseInt(value);
        }
        else {
          valuesToSubtractFrom.push((value ? parseInt(value) : 0));
        }
      });

      valuesToSubtractFrom.forEach(val => {
        difference += val;
      });
      difference -= minValue;
      return parseInt(input.value) <= difference ? null : { lowerOrEqualThanDifference: true };

    };
  }

  getLowerThanDifferenceValidator(baseValidator: (AbstractControl) => boolean, expectsControls: Array<string>, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      let difference = 0;
      let minValue: number = null;
      let valuesToSubtractFrom: number[] = [];
      expectsControls.forEach(controlName => {
        let formControl = formGroup.get(controlName);
        if (!formControl || formControl.disabled) {
          return null;
        }

        let value = formControl.value;
        if (minValue === null) {
          minValue = parseInt(value);
        }
        else {
          valuesToSubtractFrom.push(parseInt(value));
        }
      });

      valuesToSubtractFrom.forEach(val => {
        difference += val;
      });
      difference -= minValue;
      return parseInt(input.value) < difference ? null : { lowerOrEqualThanDifference: true };

    };
  }

  getHigherThanValidator(baseValidator: (AbstractControl) => boolean, expectsControl: string, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      if (formGroup.get(expectsControl) && formGroup.get(expectsControl).value) {
        let compareValue = formGroup.get(expectsControl).value;
        return parseInt(input.value) > parseInt(compareValue) ? null : { higherThan: true };
      }

      return null;
    };
  }

  getHigherThanCustomValidator(baseValidator: (AbstractControl) => boolean, expectsValue: number, expectsControl: string, formGroup: FormGroup): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }

      if (formGroup.get(expectsControl) && !isNaN(expectsValue)) {
        let additionalControlValue = formGroup.get(expectsControl).value;
        let sum = parseInt(input.value) + parseInt(additionalControlValue);

        return sum <= expectsValue ? null : { higherThanCustom: true };
      }

      return null;
    };
  }

  getMaxValidator(baseValidator: (AbstractControl) => boolean, max): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let value = Number.parseInt(input.value);
      return value <= max ? null : { max: true };
    };
  }

  getMinLengthValidator(baseValidator: (AbstractControl) => boolean, min): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      return input.value.length >= min ? null : { minLength: true };
    };
  }

  getEmailValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(input.value) ? null : { email: true };
    };
  }
  getPhoneValidator(baseValidator: (AbstractControl) => boolean): ValidatorFn {
    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      return pattern.test(input.value) ? null : { phone: true };
    };
  }

  getRequiredValidator(): ValidatorFn {
    return function (input) {
      return !(input.value === null || input.value === undefined || input.value === "" || !/\S/.test(input.value)) ? null : { required: true };
    };
  }

  getOnlyAlphanumericValidator() {
    return function (input) {
      let pattern = /^\w+$/;
      if (input.value === null || input.value === undefined || input.value === "") {
        return null;
      }

      return (input.value !== null && pattern.test(input.value)) ? null : { alphanumeric: true };
    };
  }

  getPostcodeValidator() {
    return function (input) {
      let pattern = /^[0-9]+$/;
      if (input.value === null || input.value === undefined || input.value === "") {
        return null;
      }

      // it is fine to have spaces
      let trimmedValue = input.value.replace(" ", "");

      return pattern.test(trimmedValue) ? null : { postcode: true };
    };
  }

  getDependentRequiredValidator(baseValidator: (AbstractControl) => boolean, expectsValues: any[], expectsControls: string[], dependentControls: string[], formGroup: FormGroup): ValidatorFn {

    return function (input) {
      if (!baseValidator(input)) {
        return null;
      }
      let setRequired = false;
      for (let i = 0; i < expectsControls.length; i++) {
        if (formGroup.get(expectsControls[i]).value === expectsValues[i].toString()) {
          setRequired = true;
        }
        if (setRequired) {
          let validator = Validators.required;
          for (let i = 0; i < dependentControls.length; i++) {
            let control = formGroup.get(dependentControls[i]);
            control.setValidators(validator);
            control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          }
        }
        else {
          for (let i = 0; i < dependentControls.length; i++) {
            let control = formGroup.get(dependentControls[i]);
            control.setValidators(null);
            control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          }
        }
      }
      return null;
    };
  }

  public hasValue(input: AbstractControl): boolean {
    return input.value && input.value.toString().trim() !== "";
  }

}