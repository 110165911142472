import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BigAlModule } from "../../modules/bigal.module";
import { ComponentConfigService } from "../componentConfig.service";

@Injectable()
export class DeviceMotionService {
    private dataSource: BehaviorSubject<string> = new BehaviorSubject<string>("still");
    private accTreshold;

    constructor(
        protected bigAl: BigAlModule,
        protected theBen: ComponentConfigService
    ) {
        this.bigAl.getDataStream().subscribe(event => {
            if (event === "currentDrivingSettings") {
                this.accTreshold = this.bigAl.currentDrivingSettings.Acceleration;
            }
        });
        if (this.bigAl.currentDrivingSettings === null) {
            this.bigAl.getDrivingSettings();
        }

        window.addEventListener('devicemotion',
            this.motion
            , false);
    }

    public getMoving(): Observable<string> {
        return this.dataSource.asObservable();
    }

    private setMoving(data: string) {
        this.dataSource.next(data);
    }

    motion = (e) => {
        let acc = e.acceleration;
        if (!acc.hasOwnProperty('x')) {
            acc = e.accelerationIncludingGravity;
        }

        if (!acc.x || !this.accTreshold) {
            return;
        }


        if (Math.abs(acc.x) >= this.accTreshold &&
            Math.abs(acc.y) >= this.accTreshold &&
            Math.abs(acc.z) >= this.accTreshold) {
            this.setMoving("moving");

        }
        else {
            if (this.dataSource && this.dataSource.value !== "still") {
                this.setMoving("still");
            }
        }
    }
}
