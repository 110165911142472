import { BigAlModule } from '../modules/bigal.module';
import { OnDestroy } from '@angular/core';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
var TakeOverGuard = /** @class */ (function () {
    function TakeOverGuard(navigationService, bigAl) {
        this.navigationService = navigationService;
        this.bigAl = bigAl;
    }
    TakeOverGuard.prototype.canActivate = function (route, state) {
        // if type directly url - this.bigAl.isUserLoggedIn  returns False
        // this.bigAl.currentUser.Roles.some - returns nothig - changed to this.bigAl.getPreviousUserRoles()
        var isInTakeOver = false;
        // If driver
        if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.DevUser_Driver; })) {
            return true;
        }
        // If admin
        if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.SystemAdminFleetAdmin || ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Admin; })) {
            isInTakeOver = this.bigAl.getStorageValueForIsInTakeOverMode();
            if (isInTakeOver) {
                return true;
            }
            else {
                this.navigationService.forward('/fleet/overview', null);
                return false;
            }
        }
        else {
            // not driver, not business user, not admin
            return false;
        }
    };
    TakeOverGuard.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return TakeOverGuard;
}());
export { TakeOverGuard };
