import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
//
var ResourceInjectorService = /** @class */ (function () {
    // #endregion
    function ResourceInjectorService() {
        // #region Notifications
        this.onResourceInjected = new BehaviorSubject(null);
        this.onResourceInjected$ = this.onResourceInjected.asObservable();
        this.onResourceInjectionFailed = new BehaviorSubject(null);
        this.onResourceInjectionFailed$ = this.onResourceInjectionFailed.asObservable();
    }
    ResourceInjectorService.prototype.injectScript = function (script, $context) {
        // Default to the HEAD tag
        if (!$context) {
            $context = document.querySelector("head");
        }
        // Create tag
        var tag = document.createElement("script");
        tag.dataset.identifier = script.identifier;
        // Determine the script type
        if (this.isCDNScript(script)) {
            tag.src = script.src;
            tag.type = script.type ? script.type : "text/javascript";
            // Append to the DOM
            return this.append(tag, $context, script.identifier);
        }
        else if (this.isCodeblock(script)) {
            tag.innerHTML = script.code;
            tag.type = "text/javascript";
            // Append to the DOM
            return this.append(tag, $context, script.identifier);
        }
        else {
            console.warn("Unsupported code injection: ", JSON.stringify(script));
            this.onResourceInjectionFailed.next(script.identifier);
            return false;
        }
    };
    // Add other resource injectors here
    // ....
    // #endregion
    // #region Guards
    ResourceInjectorService.prototype.isCDNScript = function (script) {
        if (typeof script === "string") {
            return false;
        }
        return script.src !== undefined && script.src !== null;
    };
    ResourceInjectorService.prototype.isCodeblock = function (script) {
        if (typeof script === "string") {
            return false;
        }
        return script.code !== undefined && script.code !== null;
    };
    // #endregion
    // #region Internal helpers
    ResourceInjectorService.prototype.append = function ($tag, $context, identifier) {
        var didInject;
        try {
            didInject = $context.appendChild($tag);
            // Notify listeners
            if (didInject) {
                this.onResourceInjected.next(identifier);
            }
        }
        catch (err) {
            this.onResourceInjectionFailed.next(identifier);
        }
        return didInject !== undefined;
    };
    ResourceInjectorService.ngInjectableDef = i0.defineInjectable({ factory: function ResourceInjectorService_Factory() { return new ResourceInjectorService(); }, token: ResourceInjectorService, providedIn: "root" });
    return ResourceInjectorService;
}());
export { ResourceInjectorService };
