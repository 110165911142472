var DriverJournalTrip = /** @class */ (function () {
    function DriverJournalTrip(TripId, LogDailyId, DataSource, IsTestData, IsCompleted, TripPositions) {
        this.TripId = TripId;
        this.LogDailyId = LogDailyId;
        this.DataSource = DataSource;
        this.IsTestData = IsTestData;
        this.IsCompleted = IsCompleted;
        this.TripPositions = TripPositions;
    }
    return DriverJournalTrip;
}());
export { DriverJournalTrip };
var TripPart = /** @class */ (function () {
    function TripPart(Positions, IsLinking, DestinationPosition, OriginPosition) {
        this.Positions = Positions;
        this.IsLinking = IsLinking;
        this.DestinationPosition = DestinationPosition;
        this.OriginPosition = OriginPosition;
    }
    return TripPart;
}());
export { TripPart };
