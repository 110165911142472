<mat-dialog-content>
  <div class="modal-header">
    <h1 mat-dialog-title>
      <app-translate translationName="LeavePageWarningComponent.{{type}}_Header">
        <span translate-text>{{'LeavePageWarningComponent.' + type + '_Header'|translate}}</span>
      </app-translate>
    </h1>

    <div class="modal-body">
      <app-translate translationName="LeavePageWarningComponent.{{type}}_Body">
        <span translate-text>{{'LeavePageWarningComponent.' + type + '_Body'|translate}}</span>
      </app-translate>

      <p class="mt-2">
        <app-translate translationName="LeavePageWarningComponent.{{type}}_Body2">
          <span translate-text>{{'LeavePageWarningComponent.' + type + '_Body2'|translate}}</span>
        </app-translate>
      </p>

      <p class="mt-2">
        <app-translate translationName="LeavePageWarningComponent.{{type}}_Body3">
          <span translate-text>{{'LeavePageWarningComponent.' + type + '_Body3'|translate}}</span>
        </app-translate>
      </p>

    </div>

    <mat-dialog-actions class="modal-footer">
      <button mat-raised-button color="primary" (click)="yes()">
        <app-translate translationName="LeavePageWarningComponent.{{type}}_Accept">
          <span translate-text>{{'LeavePageWarningComponent.' + type + '_Accept'|translate}}</span>
        </app-translate>
      </button>
      <button mat-raised-button (click)="no()">
        <app-translate translationName="LeavePageWarningComponent.{{type}}_Decline">
          <span translate-text>{{'LeavePageWarningComponent.' + type + '_Decline'|translate}}</span>
        </app-translate>
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>