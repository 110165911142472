import { BigAlModule } from './../modules/bigal.module';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
import { AppUserStore } from '../stores/DataStores';
var LoginGuard = /** @class */ (function () {
    function LoginGuard(navigationService, bigAl, appUserStore) {
        this.navigationService = navigationService;
        this.bigAl = bigAl;
        this.appUserStore = appUserStore;
    }
    LoginGuard.prototype.canActivate = function (route, state) {
        // not logged in so redirect to login page with the return url
        if (!this.appUserStore.appUser.Roles.some(function (ur) { return ur !== UserRoleType.Anonymous; })) {
            var refreshToken = this.bigAl.getRefreshKey();
            if (refreshToken) {
                console.warn("LoginGuard -> postRefreshToken()");
                this.bigAl.postRefreshToken();
            }
            else {
                this.navigationService.forward("login", null, { queryParams: { returnUrl: state.url } });
                return false;
            }
        }
        return true;
    };
    return LoginGuard;
}());
export { LoginGuard };
