import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    console.log("Production Mode Active");
    enableProdMode();
}
else {
    console.log("Development Mode Active");
}
var bootstrap = function () {
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
};
var ɵ0 = bootstrap;
if (typeof window["cordova"] !== "undefined") {
    document.addEventListener("deviceready", function () {
        bootstrap();
    }, false);
}
else {
    bootstrap();
}
export { ɵ0 };
