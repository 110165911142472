import { PipeTransform } from '@angular/core';
var SearchFilterPipe = /** @class */ (function () {
    function SearchFilterPipe() {
    }
    SearchFilterPipe.prototype.transform = function (values, filter) {
        if (!values || !values.length) {
            return [];
        }
        if (!filter) {
            return values;
        }
        // filter = filter.toUpperCase();
        if (filter && Array.isArray(values)) {
            var keys_1 = Object.keys(values[0]);
            return values.filter(function (v) {
                return v && keys_1.some(function (k) { return v[k] != null && v[k].toString().toUpperCase().indexOf(filter.toString().toUpperCase()) >= 0; });
            });
        }
    };
    return SearchFilterPipe;
}());
export { SearchFilterPipe };
