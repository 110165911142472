import { FormatCardnumberPipe } from './../../pipes/format-cardnumber.pipe';
import { PercentageDatePipe } from './../../pipes/percentage-date.pipe';
import { NgModule } from '@angular/core';
import { RelativeDatePipe } from '../../pipes/relative-date.pipe';
import { FormatNumberPipe } from '../../pipes/format-number.pipe';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';

@NgModule({
    declarations: [
        RelativeDatePipe,
        PercentageDatePipe,
        FormatNumberPipe,
        SearchFilterPipe,
        LocalizedDatePipe,
        FormatCardnumberPipe
    ],
    exports: [
        RelativeDatePipe,
        PercentageDatePipe,
        FormatNumberPipe,
        SearchFilterPipe,
        LocalizedDatePipe,
        FormatCardnumberPipe
    ]
})
export class PipesModule { }
