import { Subscription } from "rxjs";
//
import * as formconfig from 'assets/init/formConfig.json';
var AbstractFeature = /** @class */ (function () {
    function AbstractFeature(bigAl, theBen) {
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.allSubscriptions = new Subscription();
        this.watchedEvents = new Map();
        // Cache of retrieved form objects to achieve immutability for the "original" form config retrieved from the formConfig.json
        this.cacheFormObjects = new Map();
    }
    AbstractFeature.prototype.ngOnInit = function () {
        // data calls
        this.setEventsToWatch();
        this.watchData();
        this.getData();
    };
    AbstractFeature.prototype.ngOnDestroy = function () {
        this.allSubscriptions.unsubscribe();
    };
    AbstractFeature.prototype.watchEvent = function (event) {
        this.watchedEvents.set(event, "");
    };
    AbstractFeature.prototype.watchData = function () {
        var _this = this;
        this.subscribe(this.bigAl.getDataStream().subscribe(function (lastEvent) {
            if (_this.watchedEvents.has(lastEvent)) {
                _this.setData(lastEvent);
            }
        }));
    };
    AbstractFeature.prototype.isEventProcessing = function (typeOfEvent) {
        return this.bigAl.isEventEnqueued(typeOfEvent);
    };
    AbstractFeature.prototype.isFeature = function (names) {
        var _this = this;
        var val = true;
        if (typeof names === 'string') {
            val = this.theBen.isFeature(names);
        }
        else {
            names.forEach(function (name) {
                var isAvailable = _this.theBen.isFeature(name);
                if (!isAvailable) {
                    val = false;
                }
            });
        }
        return val;
    };
    AbstractFeature.prototype.isContent = function (name) {
        return this.theBen.isContent(this, name);
    };
    AbstractFeature.prototype.action = function (location, id) {
        (id) ? this.theBen.action(this, location, id) : this.theBen.action(this, location, null);
    };
    AbstractFeature.prototype.actionDocument = function (location) {
        this.theBen.actionDocument(this, location);
    };
    AbstractFeature.prototype.subscribe = function (subscription) {
        this.allSubscriptions.add(subscription);
    };
    AbstractFeature.prototype.unsubscribe = function () {
        this.allSubscriptions.unsubscribe();
    };
    AbstractFeature.prototype.getFormObject = function (name, prefilledContent, useFormCache) {
        if (useFormCache === void 0) { useFormCache = false; }
        // If the form has previously been cached, retrieve it from the cache.
        if (this.cacheFormObjects.has(name)) {
            return this.cacheFormObjects.get(name);
        }
        var objects = formconfig['FormObjects'];
        var jsonFormObject = objects.find(function (obj) { return obj["Name"] === name; });
        if (!jsonFormObject) {
            console.warn("Could not find " + name + " in the formconfig. Forgot to add it or typo?");
        }
        var formObject;
        // If there are no prefilled values, it is a waste of time to iterate over all steps' properties.
        if (!prefilledContent || prefilledContent.size < 1) {
            if (useFormCache === true) {
                formObject = JSON.parse(JSON.stringify(jsonFormObject));
                this.cacheFormObjects.set(name, formObject);
                return formObject;
            }
            return jsonFormObject;
        }
        formObject = JSON.parse(JSON.stringify(jsonFormObject));
        // Iterate over the steps' properties and set their value if a prefilledValue is specified for the property.
        for (var i = 0; i < formObject.Steps.length; i++) {
            var step = formObject.Steps[i];
            var _loop_1 = function (j) {
                var prop = step.Properties[j];
                if (prefilledContent.has(prop.Name)) {
                    var propContent = prefilledContent.get(prop.Name);
                    propContent.forEach(function (entry) {
                        var value = entry.value;
                        // The value 0 is valid enough, but is incorrectly treated as something else than number on Input fields, which is why we append an empty string to the value.
                        if (prop.Control === "Input" && value === 0) {
                            value = value + "";
                        }
                        prop[entry.key] = value;
                    });
                }
            };
            for (var j = 0; j < step.Properties.length; j++) {
                _loop_1(j);
            }
        }
        // We save the formObject to the cache for performance, so retrieving the same formObject multiple times will be faster.
        this.cacheFormObjects.set(name, formObject);
        return formObject;
    };
    AbstractFeature.prototype.getRuleObject = function (name) {
        var ruleObjects = this.bigAl.currentRuleBook.DomainObjects;
        var ruleObject = ruleObjects.find(function (obj) { return obj["Name"] === name; });
        if (!ruleObject) {
            console.warn("Could not find " + name + " in the rulebook. Forgot to add it or typo?");
        }
        return ruleObject;
    };
    AbstractFeature.prototype.clearFormObjectCache = function (name) {
        if (this.cacheFormObjects.has(name)) {
            this.cacheFormObjects.delete(name);
        }
    };
    return AbstractFeature;
}());
export { AbstractFeature };
var PrefilledContentEntry = /** @class */ (function () {
    function PrefilledContentEntry(key, value) {
        this.key = key;
        this.value = value;
    }
    return PrefilledContentEntry;
}());
export { PrefilledContentEntry };
