
export namespace Utils {

  export function NewGUID(): string {

    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

    const newGuid = guid.toLowerCase().replace(/-/g, "");

    return newGuid;
  }

  /**
   * You shall be able to report during the accounting year
   * If you report during the current year you shall be able to report from the first of January no matter if the first period
   * of the year is within the accounting year or not.
   * @param startOfPeriod The start date of the current accounting year (for either ctax or dj)
   * @param endOfPeriod The end date of the current accounting year (for either ctax or dj)
   * @param selectedYear The year selected to report in
   * @param selectedMonth The month selected to report
   * @returns true if the selected month is either in the current accounting year or in the cut period
   */
  export function MonthWithinAccountingPeriod(startOfPeriod: Date, endOfPeriod: Date, selectedYear: number, selectedMonth: number): boolean {
    const currentDate = new Date();
    const lastDayOfTheSelectedMonth = new Date(selectedYear, selectedMonth + 1, 0);
    const firstDayOfTheSelectedMonth = new Date(selectedYear, selectedMonth, 1);
    const withinAccountingYear = lastDayOfTheSelectedMonth >= startOfPeriod && firstDayOfTheSelectedMonth <= endOfPeriod;
    const withinCutPeriod = selectedYear === currentDate.getFullYear() && selectedMonth <= startOfPeriod.getMonth();
    return withinAccountingYear || withinCutPeriod;
  }

  /**
    * You shall be able to report during the accounting year
    * If you report during the current year you shall be able to report from the first of January no matter if the first period
    * of the year is within the accounting year or not.
   * @param startOfPeriod The start date of the current accounting year (for either ctax or dj)
   * @param endOfPeriod The end date of the current accounting year (for either ctax or dj)
   * @param date the date to test
   * @returns true if the date is either in the current accounting year or in the cut period
   */
  export function DayWithinAccountingPeriod(startOfPeriod: Date, endOfPeriod: Date, date: Date) {
    const currentDate = new Date();
    const withinAccountingYear = date >= startOfPeriod && date <= endOfPeriod;
    const withinCutPeriod = date.getFullYear() === currentDate.getFullYear() && date < startOfPeriod;
    return withinAccountingYear || withinCutPeriod;
  }

  /**
   * Is this a recent dj entry.
   * Because of NFS to Miles change, it should not be possible to change DJ in historic data.
   * It should however be possible to change last month before the 8th of the current month.
   * @param testDate The month selected to report
   * @returns true if it should be possible to edit in the specified day
   */
  export function IsDJDateInRecentMonths(testDate: Date): boolean {
    const currentDate = new Date();
    const lastDayOfTheSelectedMonth = new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0);
    const lastDayOfTheSelectedMonthPlusNine = new Date(lastDayOfTheSelectedMonth.toISOString());
    lastDayOfTheSelectedMonthPlusNine.setDate(lastDayOfTheSelectedMonthPlusNine.getDate() + 9);
    var result = currentDate < lastDayOfTheSelectedMonthPlusNine;
    return result;
  }

  /**
   * Is this a recent dj entry, within the last three month
   * Because of NFS to Miles change, it should not be possible to change CTAX and infra historic data.
   * It should however be possible to change last three months before the 8th of the current month.
   * @param testDate The month selected to report
   * @returns true if it should be possible to edit in the specified day
   */
  export function IsDJDateInRecentThreeMonths(testDate: Date): boolean {
    const currentDate = new Date();
    const lastDayOfTheSelectedMonth = new Date(testDate.getFullYear(), testDate.getMonth() + 3, 0);
    const lastDayOfTheSelectedMonthPlusNine = new Date(lastDayOfTheSelectedMonth.toISOString());
    lastDayOfTheSelectedMonthPlusNine.setDate(lastDayOfTheSelectedMonthPlusNine.getDate() + 9);
    var result = currentDate < lastDayOfTheSelectedMonthPlusNine;
    return result;
  }
}
