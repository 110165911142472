<div class="element-container element-container-sm" *ngIf="formModel && formModel.form">
  <div class="sub-box">
    <h1>
      <app-translate translationName="ForgotLoginComponent.title_t">
        <span translate-text>{{'ForgotLoginComponent.title_t' | translate }}
        </span>
      </app-translate>
    </h1>
    <div *ngIf="!formSuccess">
      <p>
        <app-translate translationName="ForgotLoginComponent.subtitle_t">
          <span translate-text>{{'ForgotLoginComponent.subtitle_t' | translate }}
          </span>
        </app-translate>
      </p>
      <br>
      <div>
        <app-form [formObject]="getFormObject()" [ruleObject]="getRuleObject()"></app-form>
      </div>
    </div>
    <div *ngIf="formSuccess">
      <h2>
        <app-translate translationName='ForgotLoginComponent.t_forgot_password_confirmation'>
          <span translate-text>{{'ForgotLoginComponent.t_forgot_password_confirmation'| translate}}</span>
        </app-translate>
      </h2>
      <p>
        <app-translate translationName='ForgotLoginComponent.t_forgot_password_confirmation_help'>
          <span translate-text>{{'ForgotLoginComponent.t_forgot_password_confirmation_help'| translate}}</span>
        </app-translate>
      </p>
    </div>
    <button mat-button type="button" (click)="back()">
      <app-translate translationName='FormComponent.Back_t'>
        <span translate-text>{{'FormComponent.Back_t'| translate}}</span>
      </app-translate>
    </button>
  </div>
</div>

<router-outlet></router-outlet>