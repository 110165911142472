var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UserRoleType } from './../../../models/enums/user-role-type.enum';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { ScreenService } from '../../../services/screen.service';
import { AfterViewChecked, ElementRef } from '@angular/core';
import { AbstractFeature } from '../../features/feature.abstract';
import { AppUserStore } from '../../../stores/DataStores';
var MenuFleetUserComponent = /** @class */ (function (_super) {
    __extends(MenuFleetUserComponent, _super);
    function MenuFleetUserComponent(screenService, element, theBen, bigAl, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.screenService = screenService;
        _this.element = element;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.appUserStore = appUserStore;
        _this.isFleetAdmin = false;
        _this.anchorMovedOnInit = false;
        return _this;
    }
    MenuFleetUserComponent.prototype.ngAfterViewChecked = function () {
        if (!this.anchorMovedOnInit &&
            this.element.nativeElement.querySelector(".active a")) {
            this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
            this.anchorMovedOnInit = true;
        }
    };
    MenuFleetUserComponent.prototype.setEventsToWatch = function () {
        this.watchEvent('userLogin');
    };
    MenuFleetUserComponent.prototype.setData = function () {
        this.isFleetAdmin = this.appUserStore.appUser.Roles.some(function (role) { return role === UserRoleType.FleetAdmin || role === UserRoleType.SystemAdminFleetAdmin || role === UserRoleType.DevUser_Admin; });
    };
    MenuFleetUserComponent.prototype.getData = function () {
        this.isFleetAdmin = this.appUserStore.appUser.Roles.some(function (role) { return role === UserRoleType.FleetAdmin || role === UserRoleType.SystemAdminFleetAdmin || role === UserRoleType.DevUser_Admin; });
    };
    MenuFleetUserComponent.prototype.isDataAvailable = function () {
        return true;
    };
    // EventHandler
    MenuFleetUserComponent.prototype.onClick = function ($event) {
        this.moveAnchor($event.target);
    };
    MenuFleetUserComponent.prototype.moveAnchor = function (target) {
        var itemTarget = this.findAncestorItem(target);
        this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
    };
    MenuFleetUserComponent.prototype.findAncestorItem = function (el) {
        while ((el = el.parentNode) && !el.classList.contains("item")) { }
        return el;
    };
    return MenuFleetUserComponent;
}(AbstractFeature));
export { MenuFleetUserComponent };
