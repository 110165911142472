export class WorkshopBooking {
    constructor(
        public ContractId: string = null,
        public WorkshopId: string = null,
        public CurrentMileage: number = 0,
        public Date: Date = null,
        public Time: string = null,
        public WorkToBeDone: string = null,
        public WantsReplacementCar: boolean = false,
        public PickupStatus: PickupStatus = null,
        public Name: string = null,
        public Street: string = null,
        public PostalCode: string = null,
        public City: string = null,
        public Phone: string = null,
        public Email: string = null,
        public Service: ServiceType = null,
        public DeliverCarAtSupplier = null,
        public OrderDate = null,
        public RepairTypeId = null
    ) {

    }
}

export enum PickupStatus {
    DriveYourself = 0,
    CompanyAddress = 1,
    Address = 2
}

export enum ServiceType {
    Undefined = 0,
    OrdinaryService = 1,
    Repair = 2
}
/**Model from 2.0. for serviceLinkBookingWithPickup
 * constructor(
    public shouldSendToCompanyAddress: boolean,
    public contractId: string,
    public workshopId: string,
    public currentMileage: string,
    public date: string,
    public street: string,
    public city: string,
    public zipcode: string,
    public name: string,
    public phone: string,
    public email: string,
    public workToBeDone: string,
    public wantsReplacementCar: boolean
  ) { }
 */
