import { Component, OnInit } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentStructure } from '../../../models/component-config/component-structure.model';
import { ComponentContent } from '../../../models/component-config/component-content.model';
import { ComponentConfiguration } from '../../../models/component-config/component-configuration.model';
import { ComponentAction, ComponentActionType } from '../../../models/component-config/component-action.model';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-component-config',
  templateUrl: './component-config.component.html',
  styleUrls: ['./component-config.component.scss'],
  exportAs: "modal"
})
export class ComponentConfigComponent implements OnInit {

  public componentConfigForm: FormGroup;
  private minIndex: number;
  private componentContentMaxIndex: number[] = [];
  private componentActionMaxIndex: number[] = [];
  public actionTypeStringValues: string[] = [];
  public actionTypeIntValues: number[] = [];

  constructor(
    private bigAl: BigAlModule,
    private navigationService: NavigationService) {
    /*Enums are a bit funky to work with in TypeScript compared to C#,
    so in order to get the keys without their numeric value you have to splice.
    See here for docs: https://www.gurustop.net/blog/2016/05/24/how-to-use-typescript-enum-with-angular2/ */
    let options = (Object.keys(ComponentActionType));
    this.actionTypeStringValues = options.slice(options.length / 2);
    options.slice(0, options.length / 2).forEach(val => this.actionTypeIntValues.push(parseInt(val)));
  }

  ngOnInit() {
    if (this.bigAl.appSettings === null) {
      this.bigAl.whoAmI();
    }

    this.componentConfigForm = new FormGroup({
      componentConfigFormArray: new FormArray([
      ])
    });

    let contentMaxIndexArray: number[] = [];
    let actionMaxIndexArray: number[] = [];
    this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.forEach(element => {
      let index = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.indexOf(element);
      this.addComponent(element, index);
      if (element.Content) {
        element.Content.forEach(content => {
          this.addContent(content, index);
        });
        contentMaxIndexArray[index] = element.Content.length - 1;
      }
      if (element.Actions) {
        element.Actions.forEach(action => {
          this.addAction(action, index);
        });
        actionMaxIndexArray[index] = element.Actions.length - 1;
      }
    });

    this.componentContentMaxIndex = contentMaxIndexArray;
    this.componentActionMaxIndex = actionMaxIndexArray;
    this.minIndex = 0;

  }

  initComponentConfig(element: ComponentStructure) {
    let elementName = "";
    if (element && element.Name) {
      elementName = element.Name;
    }
    this.componentContentMaxIndex.unshift(999);
    this.componentActionMaxIndex.unshift(999);
    this.minIndex += 1;
    return new FormGroup({
      componentTitle: new FormControl(elementName, [Validators.required]),
      componentConfigContentFormArray: new FormArray([]),
      componentConfigActionFormArray: new FormArray([])
    });

  }

  initContent(content: ComponentContent) {
    return new FormGroup({
      componentContentCheckbox: new FormControl(content.ShowContent),
      componentContent: new FormControl(content.Name, [Validators.required]),
    });
  }

  initAction(action: ComponentAction) {

    return new FormGroup({
      componentActionNext: new FormControl(action.Next, [Validators.required]),
      componentActionType: new FormControl(action.Type, [Validators.required]),
      componentAction: new FormControl(action.Name, [Validators.required]),
    });
  }

  addComponent(component: ComponentStructure, index: number) {
    const control = <FormArray>this.componentConfigForm.get('componentConfigFormArray');
    control.insert(index, this.initComponentConfig(component));
  }

  addContent(content: ComponentContent, componentIndex: number) {
    let formGroup = <FormArray>this.componentConfigForm.get('componentConfigFormArray');
    const control = <FormArray>formGroup.controls[componentIndex].get('componentConfigContentFormArray');
    if (!content) {
      content = new ComponentContent();
      content.ShowContent = true;
    }
    control.push(this.initContent(content));
  }

  addAction(action: ComponentAction, componentIndex: number) {
    let formGroup = <FormArray>this.componentConfigForm.get('componentConfigFormArray');
    const control = <FormArray>formGroup.controls[componentIndex].get('componentConfigActionFormArray');
    if (!action) {
      action = new ComponentAction();
    }
    control.push(this.initAction(action));
  }

  getComponentConfigFormArray(form) {
    return form.controls.componentConfigFormArray.controls;
  }

  getComponentConfigContentFormArray(form) {
    return form.controls.componentConfigContentFormArray.controls;
  }

  getComponentConfigActionFormArray(form) {
    return form.controls.componentConfigActionFormArray.controls;
  }

  saveComponents() {
    // Convert form arrays to ComponentConfiguration
    let componentConfigFormArray = this.componentConfigForm.value.componentConfigFormArray;
    let components = new Array<ComponentStructure>();

    for (let index = 0; index < componentConfigFormArray.length; index++) {
      let element = componentConfigFormArray[index];

      let component = new ComponentStructure();
      component.Name = element.componentTitle;
      let contents = new Array<ComponentContent>();
      let componentContents = element.componentConfigContentFormArray;

      for (let index = 0; index < componentContents.length; index++) {
        let content = new ComponentContent();
        content.Name = componentContents[index].componentContent;
        content.ShowContent = componentContents[index].componentContentCheckbox;
        contents.push(content);
      }
      component.Content = contents;
      components.push(component);

      let actions = new Array<ComponentAction>();
      let componentActions = element.componentConfigActionFormArray;

      for (let index = 0; index < componentActions.length; index++) {
        let action = new ComponentAction();
        action.Name = componentActions[index].componentAction;
        action.Next = componentActions[index].componentActionNext;
        action.Type = parseInt(componentActions[index].componentActionType);
        actions.push(action);
      }
      component.Actions = actions;
    }
    let componentConfiguration = new ComponentConfiguration();
    componentConfiguration.Components = components;
    this.bigAl.postComponents(componentConfiguration);
  }

  action() {
    this.navigationService.back();
  }


}
