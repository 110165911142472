import { Router } from '@angular/router';
var PageNotFoundComponent = /** @class */ (function () {
    function PageNotFoundComponent(router) {
        this.router = router;
    }
    PageNotFoundComponent.prototype.goHome = function () {
        this.router.navigate(['/']);
    };
    return PageNotFoundComponent;
}());
export { PageNotFoundComponent };
