/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./translate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./translate.component";
import * as i4 from "../../../translation/customTranslateService";
import * as i5 from "../../../services/navigation.service";
import * as i6 from "../../../services/modals/modal.service";
var styles_TranslateComponent = [i0.styles];
var RenderType_TranslateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TranslateComponent, data: {} });
export { RenderType_TranslateComponent as RenderType_TranslateComponent };
function View_TranslateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TranslateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "translate-span"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadTranslation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TranslateComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TranslateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TranslateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TranslateComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TranslateComponent_5(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TranslateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TranslateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TranslateComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["translateContent", 2]], null, 0, null, View_TranslateComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.updateTranslations; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.updateTranslations; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TranslateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-translate", [], null, null, null, View_TranslateComponent_0, RenderType_TranslateComponent)), i1.ɵdid(1, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TranslateComponentNgFactory = i1.ɵccf("app-translate", i3.TranslateComponent, View_TranslateComponent_Host_0, { translationName: "translationName" }, {}, ["[translate-text]"]);
export { TranslateComponentNgFactory as TranslateComponentNgFactory };
