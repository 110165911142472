var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AbstractFeature, PrefilledContentEntry } from '../../features/feature.abstract';
import { ApiService } from '../../../services/api.service';
var ApiErrorComponent = /** @class */ (function (_super) {
    __extends(ApiErrorComponent, _super);
    function ApiErrorComponent(apiService, bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.apiService = apiService;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.currentError = null;
        // Below two variables are used by .html file.
        // private allowErrorReporting: Boolean = false;
        _this.reportError = false;
        return _this;
    }
    ApiErrorComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.apiService.getError()
            .subscribe(function (errorResponse) {
            if (!errorResponse) {
                _this.reportError = false;
                _this.currentError = null;
            }
            else {
                _this.currentError = errorResponse.error;
            }
        }));
    };
    ApiErrorComponent.prototype.setData = function () {
        return;
    };
    ApiErrorComponent.prototype.getData = function () {
        return;
    };
    ApiErrorComponent.prototype.isDataAvailable = function () {
        return true;
    };
    ApiErrorComponent.prototype.closeModal = function (closing) {
        if (closing) {
            this.apiService.removeError();
        }
    };
    ApiErrorComponent.prototype.getFormObject = function () {
        var prefilled = new Map();
        prefilled.set("MessageId", [new PrefilledContentEntry("Value", this.currentError.MessageId)]);
        prefilled.set("StatusCode", [new PrefilledContentEntry("Value", this.currentError.StatusCode)]);
        var formObject = _super.prototype.getFormObject.call(this, "ReportApiError", prefilled);
        return formObject;
    };
    ApiErrorComponent.prototype.getRuleObject = function () {
        return _super.prototype.getRuleObject.call(this, "ReportApiError");
    };
    ApiErrorComponent.prototype.reportErrorForm = function () {
        this.reportError = true;
    };
    // Based on the current error's errorCode, we use a different tag for translation in order to provide more meaningful information to the user.
    ApiErrorComponent.prototype.getErrorTypeTranslationTagTitle = function () {
        var baseTag = "ApiError.";
        var defaultTag = baseTag + this.currentError.MessageId + "_title_t";
        switch (this.currentError.StatusCode) {
            case 403:
                if (this.currentError.MessageId === "NFO1003") {
                    return baseTag + "refresh_token_invalid_t";
                }
                break;
            case 400:
                return defaultTag;
        }
        return defaultTag;
    };
    ApiErrorComponent.prototype.getErrorTypeTranslationTagBody = function () {
        var baseTag = "ApiError.";
        var defaultTag = baseTag + this.currentError.MessageId + "_body_t";
        switch (this.currentError.StatusCode) {
            case 403:
                if (this.currentError.MessageId === "NFO1003") {
                    return baseTag + "refresh_token_invalid_t";
                }
                break;
            case 400:
                return defaultTag;
        }
        return defaultTag;
    };
    return ApiErrorComponent;
}(AbstractFeature));
export { ApiErrorComponent };
