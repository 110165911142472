import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppUserStore } from '../../../stores/DataStores';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { UserSettings } from '../../../models/user-settings.model';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit {
  type: string;
  content: string;

  constructor(
    protected userStore: AppUserStore,
    protected theBen: ComponentConfigService,
    private dialogRef: MatDialogRef<ConsentComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.type = data.consentType;
  }

  ngOnInit() {
  }

  isContent(name: string): boolean {
    return this.theBen.isContent(this, name);
  }

  action(name: string): void {
    this.theBen.action(this, name);
    this.dialogRef.close(false);
  }

  yes() {
    // the current settings
    const settings: UserSettings = this.userStore.appUser.UserSettings;

    // Update the userSettings with the user selected option
    settings.ContentSettings.find(setting => setting.Settings[this.type] !== undefined).Settings[this.type] = "true";

    // Save to the backend
    this.userStore.saveSettings(settings, this.userStore.appUser.AuthenticationUserId)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        this.dialogRef.close(false);
      });
  }

  no() {
    this.dialogRef.close(false);
  }

}
