import { BehaviorSubject } from 'rxjs';
/**
 * Its use is to keep the DriverjournalMonthlyUpdateComponent and DriverjournalDailyUpdateComponent
 * in sync
 */
var BusinessTripService = /** @class */ (function () {
    function BusinessTripService() {
        this.businessTripKmCount = new Map();
        this.oldValue = 0;
        this.businessTripKmCount[0] = new Map();
        this.businessTripKmCount[1] = new Map();
        for (var monthCount = 0; monthCount < 12; monthCount++) {
            this.businessTripKmCount[0][monthCount] = new BehaviorSubject(false);
            this.businessTripKmCount[1][monthCount] = new BehaviorSubject(false);
        }
        this.oldValue = 0;
    }
    BusinessTripService.prototype.resetBusinessTripKmCountForMonth = function (monthlyLog) {
        var month = this.getMonthCountForMonthlyLog(monthlyLog);
        this.businessTripKmCount[0][month].next(false);
        this.businessTripKmCount[1][month].next(false);
        this.oldValue = 0;
    };
    BusinessTripService.prototype.resetCurrentBusinessTrip = function () {
        this.oldValue = 0;
    };
    BusinessTripService.prototype.currentBusinessTrip = function (currentBusinessTrip) {
        if (!currentBusinessTrip) {
            return;
        }
        else {
            this.oldValue = currentBusinessTrip.Distance;
        }
    };
    BusinessTripService.prototype.businessTripUpdate = function (currentBusinessTrip) {
        var monthCount = this.getMonthCountForDailyLog(currentBusinessTrip);
        var isExtraCar = this.getIsExtraCar(currentBusinessTrip.IsExtraCarSelected);
        var newValue = currentBusinessTrip.Distance;
        var oldValue = this.oldValue;
        if (oldValue !== newValue) {
            this.businessTripKmCount[isExtraCar][monthCount].next(true);
        }
        else {
            this.businessTripKmCount[isExtraCar][monthCount].next(false);
        }
    };
    BusinessTripService.prototype.businessTripDelete = function (currentBusinessTrip) {
        var monthCount = this.getMonthCountForDailyLog(currentBusinessTrip);
        var isExtraCar = this.getIsExtraCar(currentBusinessTrip.IsExtraCar);
        this.businessTripKmCount[isExtraCar][monthCount].next(true);
    };
    BusinessTripService.prototype.getObservableforPrimaryCar = function (monthlyLog) {
        return this.businessTripKmCount[0][this.getMonthCountForMonthlyLog(monthlyLog)].asObservable();
    };
    BusinessTripService.prototype.getObservableForExtraCar = function (monthlyLog) {
        return this.businessTripKmCount[1][this.getMonthCountForMonthlyLog(monthlyLog)].asObservable();
    };
    BusinessTripService.prototype.getMonthCountForDailyLog = function (dailyLog) {
        return new Date(dailyLog.Date).getMonth();
    };
    BusinessTripService.prototype.getMonthCountForMonthlyLog = function (monthlyLog) {
        return monthlyLog.Month;
    };
    BusinessTripService.prototype.getIsExtraCar = function (isExtraCarSelected) {
        var isExtraCar = (isExtraCarSelected === "true" || isExtraCarSelected === true) ? 1 : 0;
        return isExtraCar;
    };
    return BusinessTripService;
}());
export { BusinessTripService };
