import { OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MaskComponent } from '../mask/mask.component';
import { NavigationService } from '../../../services/navigation.service';
// app-modal.component.ts
var ModalComponent = /** @class */ (function () {
    function ModalComponent(navigationService) {
        this.navigationService = navigationService;
        this.modalClosing = new EventEmitter();
        this.allowClose = true;
        this.mainStyles = "modal";
    }
    ModalComponent.prototype.ngOnInit = function () {
        if (this.size === "xxs") {
            this.mainStyles = "modal modal-xxs  on-top";
        }
        if (this.size === "small") {
            this.mainStyles = "modal modal-small on-top";
        }
        if (this.size === "medium") {
            this.mainStyles = "modal modal-medium on-top";
        }
        if (this.closeButton === false) {
            this.allowClose = false;
        }
    };
    ModalComponent.prototype.closeModal = function (childNavigates) {
        if (childNavigates === void 0) { childNavigates = false; }
        if (this.allowClose) {
            this.modalClosing.emit(true);
            if (!childNavigates) {
                this.navigationService.closeModal();
            }
        }
    };
    return ModalComponent;
}());
export { ModalComponent };
