import { BehaviorSubject } from 'rxjs';
import { AppUserStore } from '../../stores/DataStores';
import { DriverJournalDailyLog } from '../../models/driverjournal/driverJournalDailyLog.model';
import { TripTypeEnum } from '../../models/enums/TripType.enum';
import { BigAlModule } from '../../modules/bigal.module';
import { ComponentConfigService } from '../componentConfig.service';
var DailyUpdateService = /** @class */ (function () {
    function DailyUpdateService(appUserStore, bigAl, theBen) {
        this.appUserStore = appUserStore;
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.dailyLog = new BehaviorSubject(null);
        this.resetDailyLog();
    }
    DailyUpdateService.prototype.getDailyLog = function () {
        return this.dailyLog.asObservable();
    };
    DailyUpdateService.prototype.setDailyLog = function (log) {
        this.dailyLog.next(log);
    };
    DailyUpdateService.prototype.resetDailyLog = function () {
        var djSettings = this.appUserStore.appUser.UserSettings.ContentSettings.find(function (p) { return p.Content === 'driverjournal'; });
        // add the default settings from user settings
        var purpose = djSettings.Settings["TravelPurpose"];
        var type = (JSON.parse(djSettings.Settings['DefaultTripType'])) ? TripTypeEnum.BusinessTrip : TripTypeEnum.PrivateTrip;
        var regNo = (this.bigAl.currentVehicle && this.bigAl.currentVehicle.RegistrationNumber) ? this.bigAl.currentVehicle.RegistrationNumber : '';
        var id = (this.bigAl.currentVehicleContract && this.bigAl.currentVehicleContract.Id) ? this.bigAl.currentVehicleContract.Id : '';
        var descr = (this.bigAl.currentVehicle && this.bigAl.currentVehicle.ModelDescription) ? this.bigAl.currentVehicle.ModelDescription : '';
        var log = new DriverJournalDailyLog();
        log.ContractId = id;
        log.RegistrationNumber = regNo;
        log.Date = new Date().toLocaleDateString('sv');
        log.Purpose = purpose;
        log.CarDescription = descr;
        log.TripType = type;
        this.dailyLog.next(log);
    };
    return DailyUpdateService;
}());
export { DailyUpdateService };
