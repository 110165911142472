import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var DriverStore = /** @class */ (function () {
    function DriverStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.driver$ = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetDriver$ = this.driver$.asObservable();
    }
    // #region Requests
    DriverStore.prototype.getDriver = function (forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // this is commented out unitl the store, not big al updates the form to ensure changes shows
        // // Entry found
        // if (this.driver && !forceReload) {
        //   this.driver$.next({ model: this.driver, isSuccess: true, isCached: true });
        //   return;
        // }
        // Get entry from database
        this.apiService.getAsync(ApiUrl.Driver, this.settingStore.appSettings)
            .then(function (data) {
            _this.driver = data;
            _this.driver$.next({ model: data, isSuccess: true });
        })
            .catch(function (err) {
            _this.driver = null;
            _this.driver$.next({ model: _this.driver, isSuccess: false, error: err });
        });
    };
    DriverStore.prototype.reset = function () {
        this.driver$.next({ model: null, isInitial: true });
        this.driver = null;
    };
    DriverStore.prototype.getDriverProfile = function (driverId) {
        var _this = this;
        this.apiService.getAsync(ApiUrl.Drivers + "/" + driverId, this.settingStore.appSettings)
            .then(function (data) {
            _this.driver = data;
            _this.driver$.next({ model: data, isSuccess: true });
        })
            .catch(function (err) {
            _this.driver = null;
            _this.driver$.next({ model: _this.driver, isSuccess: false, error: err });
        });
    };
    DriverStore.ngInjectableDef = i0.defineInjectable({ factory: function DriverStore_Factory() { return new DriverStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: DriverStore, providedIn: "root" });
    return DriverStore;
}());
export { DriverStore };
