import { OnInit, EventEmitter } from "@angular/core";
var MaskComponent = /** @class */ (function () {
    function MaskComponent() {
        this.onMaskClose = new EventEmitter();
    }
    MaskComponent.prototype.ngOnInit = function () {
        this.showMask = true;
    };
    MaskComponent.prototype.toggleMask = function () {
        this.showMask = !this.showMask;
    };
    MaskComponent.prototype.onClose = function () {
        this.onMaskClose.emit(true);
    };
    return MaskComponent;
}());
export { MaskComponent };
