import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractFeature } from '../../../feature.abstract';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../../services/componentConfig.service';
import { Driver } from '../../../../../models/driver.model';
import { AppUserStore } from '../../../../../stores/DataStores';
import { Subscription } from 'rxjs';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../../../../helpers/constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-fleet-take-over-popup',
  templateUrl: './fleet-take-over-popup.component.html',
  styleUrls: ['./fleet-take-over-popup.component.scss']
})
export class FleetTakeOverPopupComponent extends AbstractFeature implements OnInit {
  private subscriptions: Subscription = new Subscription();
  didOneOrMoreEventFail: boolean = false;

  // data variables
  @Output() done: EventEmitter<any> = new EventEmitter();

  public driver: Driver;
  public contractId = null;

  // content flags

  // feature flags

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private route: ActivatedRoute,
    private appUserStore: AppUserStore,

  ) {
    super(bigAl, theBen);
    this.subscriptions.add(this.bigAl.dataStreamFailed.subscribe((response: { event: string, error: HttpErrorResponse }) => {
      if (response.event === Constants.Event_CurrentDriver) {
        this.didOneOrMoreEventFail = true;
      }
    }));
  }


  protected setEventsToWatch(): void {
    this.watchEvent(Constants.Event_CurrentContracts);
    this.watchEvent(Constants.Event_CurrentDriver);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getData();
  }

  protected setData(): void {
    if (this.bigAl.currentVehicleContract) {
      this.contractId = this.bigAl.currentVehicleContract.Id;
    }
    if (this.bigAl.currentDriver) {
      this.driver = this.bigAl.currentDriver;
    }
  }

  protected getData(): void {
    if (!this.driver) {

      const takeOverInfo = this.bigAl.currentFleetUserTakeOverInfo;
      if (takeOverInfo) {
        this.contractId = takeOverInfo.ContractNumber;
        if (!this.bigAl.currentVehicleContract) {
          this.bigAl.getContracts();
        }
        this.bigAl.getDriverProfile(takeOverInfo.Driver.DriverId);
      } else {
        if (this.appUserStore.appUser.Roles.some(r => r === UserRoleType.Driver || r === UserRoleType.SystemAdminDriver || r === UserRoleType.DevUser_Driver)) {
          this.bigAl.getDriver();
        }
        else if (this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser || r === UserRoleType.DevUser_Business)) {

          this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['id']) {
              this.bigAl.getDriverProfile(params['id']);
            }
          }));
        }
        else if (this.appUserStore.appUser.Roles.some(r => r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.DevUser_Admin)) {
          this.bigAl.getDriverProfile(this.route.snapshot.params["id"]);
        }
      }
    }
  }

  public isDataAvailable(): boolean {
    if (this.driver) {
      return true;
    }
    return false;
  }



}
