export class EditLogin {
  constructor(
    public Username: string,
    public CurrentPassword: string,
    public NewPassword: string,
  ) { }
}

export class EditLoginForm {
  constructor(
    public Username: string,
    public CurrentPassword: string,
    public Password: string,
    public ConfirmPassword: string
  ) { }
}
