import { OnInit, OnDestroy } from '@angular/core';
import { BigAlModule } from '../../../../modules/bigal.module';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../../../services/modals/modal.service';
import { Translation } from '../../../../models/translation.model';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { NavigationService } from '../../../../services/navigation.service';
var UpdateTranslationComponent = /** @class */ (function () {
    function UpdateTranslationComponent(bigAl, modalService, theBen, translateService, formBuilder, navigationService) {
        this.bigAl = bigAl;
        this.modalService = modalService;
        this.theBen = theBen;
        this.translateService = translateService;
        this.formBuilder = formBuilder;
        this.navigationService = navigationService;
        this.hasDifferentNffTranslations = false;
        this.translationsToSave = [];
        this.aldCompanyName = "ALD";
        this.nffCompanyName = "NF Fleet";
    }
    UpdateTranslationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translationForm = this.formBuilder.group({
            aldTranslations: this.formBuilder.array([]),
            nffTranslations: this.formBuilder.array([])
        });
        // For getting the translations to display
        this.modalSubscription = this.modalService.getData()
            .subscribe(function (languageTranslations) {
            _this.translationName = languageTranslations[0].TranslationName;
            _this.addTranslations(languageTranslations);
        });
        // To control the NFF translations checkbox
        this.translateServiceSubscription = this.translateService.getHasNffTranslations()
            .subscribe(function (hasDifferentNffTranslations) {
            _this.hasDifferentNffTranslations = hasDifferentNffTranslations;
        });
    };
    UpdateTranslationComponent.prototype.addTranslations = function (translationList) {
        var _this = this;
        translationList.forEach(function (translation) {
            if (translation.CompanyId === _this.translateService.aldCompanyId) {
                var language = _this.getLanguageText(_this.aldCompanyName + " (generic)", translation.LanguageCode);
                _this.addAldTranslation(translation.TranslationValue, translation.LanguageCode, language, translation.CompanyId);
            }
            else {
                var language = _this.getLanguageText(_this.nffCompanyName, translation.LanguageCode);
                _this.addNffTranslation(translation.TranslationValue, translation.LanguageCode, language, translation.CompanyId);
            }
        });
    };
    UpdateTranslationComponent.prototype.addAldTranslation = function (translationValue, languageCode, language, companyId) {
        this.aldTranslations.push(this.formBuilder.group({ translation: [translationValue, Validators.required], languageCode: languageCode, language: language, companyId: companyId }));
    };
    UpdateTranslationComponent.prototype.addNffTranslation = function (translationValue, languageCode, language, companyId) {
        this.nffTranslations.push(this.formBuilder.group({ translation: [translationValue, Validators.required], languageCode: languageCode, language: language, companyId: companyId }));
    };
    Object.defineProperty(UpdateTranslationComponent.prototype, "aldTranslations", {
        get: function () {
            return this.translationForm.get('aldTranslations');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateTranslationComponent.prototype, "nffTranslations", {
        get: function () {
            return this.translationForm.get('nffTranslations');
        },
        enumerable: true,
        configurable: true
    });
    UpdateTranslationComponent.prototype.saveTranslation = function () {
        var aldCompanyId = this.translateService.aldCompanyId;
        var nffCompanyId = this.translateService.nffCompanyId;
        // Add ALD translations
        this.addTranslationsToSave(this.translationForm.value.aldTranslations, aldCompanyId);
        // If 'different nff translations' is checked save NFF translations
        if (this.hasDifferentNffTranslations) {
            this.addTranslationsToSave(this.translationForm.value.nffTranslations, nffCompanyId);
            // Else overwrite NFF translation with the ALD translation as it should be used for both companies
        }
        else {
            this.addTranslationsToSave(this.translationForm.value.aldTranslations, nffCompanyId);
        }
        this.bigAl.postTranslations(this.translationsToSave);
    };
    UpdateTranslationComponent.prototype.addTranslationsToSave = function (translations, companyId) {
        var _this = this;
        translations.forEach(function (element) {
            var updatedTranslation = new Translation(_this.translationName, element.translation, element.languageCode, companyId);
            _this.translationsToSave.push(updatedTranslation);
        });
    };
    UpdateTranslationComponent.prototype.toggleNffTranslations = function () {
        var _this = this;
        // If we have different NFF translations, remove them
        if (this.hasDifferentNffTranslations) {
            this.nffTranslations.controls.splice(0);
            this.nffTranslations.controls.forEach(function (c) {
                c.clearValidators();
            });
            this.nffTranslations.updateValueAndValidity({ onlySelf: true });
            this.translationForm.updateValueAndValidity({ onlySelf: true });
            this.translateService.setHasNffTranslations(false);
            // else add empty inputs for the user to enter the NFF translations
        }
        else {
            var languages = this.translateService.getLanguageCodes();
            languages.forEach(function (lang) {
                var languageText = _this.getLanguageText(_this.nffCompanyName, lang);
                _this.addNffTranslation("", lang, languageText, _this.translateService.nffCompanyId);
            });
            this.translateService.setHasNffTranslations(true);
        }
    };
    UpdateTranslationComponent.prototype.getLanguageText = function (companyDisplayName, language) {
        return companyDisplayName + " - " + this.translateService.getLanguageText(language) + ":";
    };
    UpdateTranslationComponent.prototype.action = function (location, id) {
        (id) ? this.theBen.action(this, location, id) : this.theBen.action(this, location);
    };
    UpdateTranslationComponent.prototype.ngOnDestroy = function () {
        if (this.modalSubscription) {
            this.modalSubscription.unsubscribe();
        }
        this.translateService.setHasNffTranslations(false);
        if (this.translateServiceSubscription) {
            this.translateServiceSubscription.unsubscribe();
        }
    };
    // We have disabled actions in translations mode so use router
    UpdateTranslationComponent.prototype.closeModal = function () {
        this.navigationService.back();
    };
    return UpdateTranslationComponent;
}());
export { UpdateTranslationComponent };
