import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var RouteStateService = /** @class */ (function () {
    function RouteStateService(router) {
        this.router = router;
        this.history = [];
    }
    RouteStateService.prototype.loadRouting = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (_a) {
            var urlAfterRedirects = _a.urlAfterRedirects;
            if (_this.history.length < 2 || _this.history[_this.history.length - 2] !== urlAfterRedirects) {
                _this.history = _this.history.concat([urlAfterRedirects]);
            }
            else {
                _this.history.pop();
            }
        });
    };
    RouteStateService.prototype.getHistory = function () {
        return this.history;
    };
    RouteStateService.prototype.getPreviousUrl = function () {
        return this.history[this.history.length - 2] || '/cars';
    };
    RouteStateService.ngInjectableDef = i0.defineInjectable({ factory: function RouteStateService_Factory() { return new RouteStateService(i0.inject(i1.Router)); }, token: RouteStateService, providedIn: "root" });
    return RouteStateService;
}());
export { RouteStateService };
