import { OnInit, Component, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { AppUserStore } from "../../stores/DataStores";

// Services
import { NavigationService } from '../../services/navigation.service';

// Models
import { UserRoleType } from './../../models/enums/user-role-type.enum';
import { BigAlModule } from './../../modules/bigal.module';

// Helpers
import { Constants } from "./../../helpers/constants";

@Component({
  selector: "app-base-route",
  templateUrl: "./base.component.html"
  // styleUrls: ["./init.component.css"]
})
export class BaseComponent implements OnInit, OnDestroy {

  subscription: Subscription = null;

  constructor(
    private bigAl: BigAlModule,
    private navigationService: NavigationService,
    private appUserStore: AppUserStore,
  ) { }

  ngOnInit(): void {

    const roles: Array<UserRoleType> = this.bigAl.getPreviousUserRoles();
    if (roles.some(r => r === UserRoleType.Driver || r === UserRoleType.SystemAdminDriver)) {
      this.navigationService.forward('cars', null);
    } else if (roles.some(r => r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.SystemAdminFleetAdmin)) {
      // In order to avoid race conditions, if bigAl has yet to load the currentUser, we subscribe to that event
      // and revert the driver takeover once possible. If the currentUser has loaded, we revert it immidiately.
      let isInTakeOver = this.bigAl.getStorageValueForIsInTakeOverMode();
      if (isInTakeOver && !this.appUserStore.appUser) {
        this.subscription = this.bigAl.getDataStream().subscribe(lastEvent => {
          if (lastEvent === Constants.Event_currentUser) {
            this.bigAl.revertTakeOverDriver();
          }
        });
      } else if (isInTakeOver && this.appUserStore.appUser) {
        this.bigAl.revertTakeOverDriver();
      }

      this.navigationService.forward('fleet/overview', null);
    } else {
      this.navigationService.forward('cars', null);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
