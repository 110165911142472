import { getLocaleCurrencySymbol } from "@angular/common";

export class LocaleUtil {

    static localeNames = {
        'en': {
            'long': [
                'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
                'October', 'November', 'December'
            ],
            'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
        },
        'da': {
            'long': [
                'Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September',
                'Oktober', 'November', 'December'
            ],
            'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
            'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
        },
        'sv': {
            'long': [
                'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September',
                'Oktober', 'November', 'December'
            ],
            'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
            'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
        },
        'nb': {
            'long': [
                'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
                'Oktober', 'November', 'Desember'
            ],
            'short': ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
            'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
        }
    };

    static getLocaleByLang(lang: string) {
        switch (lang) {
            case 'da': {
                return 'da-DK';
            }
            case 'sv': {
                return 'sv-SE';
            }
            case 'nb': {
                return 'nb-NO';
            }
            default:
                return 'en-US';
        }
    }

    static getLocaleShortDateNoYear(date: any, currentLang: string) {
        return new Date(date).toLocaleDateString(this.getLocaleByLang(currentLang), {
            month: 'short', day: 'numeric'
        });
    }

    static getLocaleShortDate(date: any, currentLang: string) {
        return new Date(date).toLocaleDateString(this.getLocaleByLang(currentLang), {
            year: 'numeric', month: 'short', day: 'numeric'
        });
    }

    static getLocaleLongDate(date: any, currentLang: string) {
        return new Date(date).toLocaleDateString(this.getLocaleByLang(currentLang), {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
        });
    }

    /**
     * Could be: da-DK,
     * @param currentLang
     */
    static getLocaleValuta(currentLang: string) {
        return getLocaleCurrencySymbol(currentLang);
    }

    static convertToDateEnglish(dateString: string, currentLang: string) {
        const compare = this.localeNames[currentLang];
        const english = this.localeNames['en'];

        if (!dateString) { return null; }
        if (!compare) { return null; }

        if (currentLang === 'en') {
            return new Date(Date.parse(dateString));
        }

        // check that there at least 3 consecutive letters in stirng - necessary for this to work
        const pattern = /[a-z]{3}/i;
        const test = pattern.test(dateString);
        if (!test) {
            return new Date(Date.parse(dateString));
        }

        // we do not know the mapping type so we will try all;
        const mappings = ['long', 'short', 'narrow'];

        for (let i = 0; i < mappings.length; i++) {
            const index = compare[mappings[i]].findIndex(c => dateString.toLowerCase().includes(c.toLowerCase()));
            if (index > -1) {
                dateString = dateString.toLowerCase().replace(compare[mappings[i]][index].toLowerCase(), english[mappings[i]][index]);
                return new Date(Date.parse(dateString));
            }
        }
    }
}
