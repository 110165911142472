import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpinnerService } from '../spinner.service';
import { ApiService } from '../api.service';
import { AppSettings } from '../../models/app-settings.model';
import { HttpErrorResponse } from '@angular/common/http';

export interface IFileModel {
  File?: string;
  FileToken?: string;
  Id: string;
  FileName: string;
  chunkNumber: number;
  totalChunks: number;
  FileSize: number;
  ChunkSize?: number;
}

export interface IGloveboxFileModel extends IFileModel {
  DriverId?: string;
}

export interface IUploadedFile {
  FileName: string;
  FileToken: string;
}

@Injectable({
  providedIn: "root"
})
export class FileUploadService {

  private filePathStream = new BehaviorSubject<IUploadedFile>(null);
  private uploadError = new BehaviorSubject<boolean>(false);
  private errorType = new BehaviorSubject<string>('');

  errorCount = 0;
  chunkNumber = 0;
  totalChunks = 1;
  chunkSize = 0;
  fileId = '';
  file: File;
  sessionId = '';
  uploading = false;
  allowedFileExtensions = new Set<string>(["jpg", "jpeg", "png", "bmp", "pdf", "docx", "xlsx"]);
  uploadedFileIds: string[] = [];

  constructor(
    private spinnerService: SpinnerService,
    private apiService: ApiService
  ) { }

  public getFilePathObservable(): Observable<IUploadedFile> {
    return this.filePathStream.asObservable();
  }

  public setFilePathStreamObservable(data: IUploadedFile) {
    this.filePathStream.next(data);
  }

  public setuploadErrorObservable(data: boolean) {
    this.uploadError.next(data);
  }

  public getuploadErrorObservable(): Observable<boolean> {
    return this.uploadError.asObservable();
  }

  public getErrorTypeObservable(): Observable<string> {
    return this.errorType.asObservable();
  }

  public setErrorTypeObservable(data: string) {
    this.errorType.next(data);
  }

  public defaultFileUpload(file: File, input: HTMLInputElement, appSettings: AppSettings) {
    if (input) {
      let chunked = false;
      if (this.shouldSendInChunks(file)) {
        chunked = true;
      }
      this.send(chunked, input, () => this.sendChunk("File/Upload", "File/Upload", appSettings, false));
    }
  }

  public gloveboxFileUpload(file: File, input: HTMLInputElement, appSettings: AppSettings) {
    if (input) {
      let chunked = false;
      if (this.shouldSendInChunks(file)) {
        chunked = true;
      }
      this.send(chunked, input, () => this.sendChunk("file/Glovebox/Upload/Create", "file/Glovebox/Upload/Append/", appSettings, true));
    }
  }

  private shouldSendInChunks(file: File): boolean {
    if (file.size < 2 * 1000000) {
      return true;
    }
    return false;
  }

  private send(chunked: boolean, input: HTMLInputElement, sendFunction: () => void) {
    // Check that we're not already uploading
    if (this.uploading) {
      this.setuploadErrorObservable(true);
      this.setErrorTypeObservable('stillUploading');
      this.uploading = false;
      return;
    }
    // Begin input validation
    if (!input) {
      this.setuploadErrorObservable(true);
      this.setErrorTypeObservable('nofile');
      this.uploading = false;
      return;
    }
    else if (!input.files) {
      this.setuploadErrorObservable(true);
      this.setErrorTypeObservable('noBrowserSupport');
      this.uploading = false;
      return;
    }
    else if (!input.files[0]) {
      this.setuploadErrorObservable(true);
      this.setErrorTypeObservable('selectFile');
      this.uploading = false;
      return;
    }
    // End input validation
    this.uploading = true;
    this.fileId = '001';    // Set the file Id to whatever you need it to be for further processing. Do not reuse Ids within a session under any circumstances. This _will_ break, and you will get errors from the server.
    this.file = input.files[0];

    if (!this.isFileExtensionAllowed(this.file.name)) {
      this.setuploadErrorObservable(true);
      this.setErrorTypeObservable('illegalFileFormat');
      this.uploading = false;
      return;
    }
    // this.sessionId = UUID.UUID();
    // If you don't want to send it in chunks, use this method (or you could just not call slice and create a custom function for that)
    if (!chunked) {
      this.chunkNumber = 1;
      this.totalChunks = 1;
      this.chunkSize = this.file.size;
      sendFunction();
      return;
    }
    // otherwise, decide on a chunk size and calculate number of chunks
    this.chunkSize = 1024 * 1024;
    let totalSize = this.file.size;
    let finalchunksize = totalSize % this.chunkSize;
    let chunkscounter = (totalSize - finalchunksize) / this.chunkSize;
    this.chunkNumber = 1;
    this.totalChunks = chunkscounter + 1;
    sendFunction();
  }

  private isFinalChunk(): boolean {
    return this.chunkNumber > this.totalChunks;
  }

  private nextChunk(endpoint: string, appSettings: AppSettings) {
    if (this.chunkNumber > this.totalChunks) {
      // We're done
      this.uploading = false;
      return;
    }
    // Send the next chunk
    this.sendChunk(endpoint, endpoint, appSettings, false);
  }

  private sendChunk(endpoint: string, appendEndpoint: string, appSettings: AppSettings, saveFileId: boolean) {

    let currentUser = null; // todo: get user from BigAL, we got this from the glovebox service before but that has been removed
    let model: IGloveboxFileModel;
    if (currentUser) {
      model = {
        Id: "",
        FileName: this.file.name,
        chunkNumber: this.chunkNumber,
        totalChunks: this.totalChunks,
        FileSize: this.file.size,
        ChunkSize: this.chunkSize,
        DriverId: currentUser.UserId
      }

    }
    else {
      model = {
        Id: "",
        FileName: this.file.name,
        chunkNumber: this.chunkNumber,
        totalChunks: this.totalChunks,
        FileSize: this.file.size,
        ChunkSize: this.chunkSize
      }
    }

    let reader = new FileReader();
    let service = this;
    let blob = this.getBlob(this.chunkNumber, this.totalChunks, this.chunkSize, this.file);
    reader.onload = () => {
      this.sendFileModel(model, reader.result, endpoint, appSettings).then((data: IFileModel) => {

        let uploadedFile: IUploadedFile = {
          FileName: data.FileName,
          FileToken: data.FileToken
        };

        service.chunkNumber++;
        if (service.isFinalChunk()) {
          // We're done
          service.filePathStream.next(uploadedFile);
          service.uploading = false;
          service.setuploadErrorObservable(false);
          service.setErrorTypeObservable('');
          if (saveFileId) {
            service.uploadedFileIds.push(data.Id);
          }
          service.spinnerService.hide();
          return;
        }
        // Send the next chunk
        this.sendChunk(appendEndpoint + data.Id, appendEndpoint, appSettings, saveFileId);
      }).catch((err: HttpErrorResponse) => {
        service.uploading = false;
        service.setuploadErrorObservable(true);
        let value = err.status;
        if (err.error.MessageId) {
          value = err.error.MessageId;
        }
        service.setErrorTypeObservable(value.toString());
        service.spinnerService.hide();
      });
    };
    reader.readAsDataURL(blob);
  }

  private sendFileModel(model: IFileModel, loadedblob: any, endpoint: string, appSettings: AppSettings): Promise<any> {
    model.File = loadedblob;
    this.spinnerService.show();
    return this.apiService.postAsync(endpoint, model, appSettings);
  }

  getBlob(chunkNumber, totalChunks, chunkSize, file) {
    if (chunkNumber === 1 && totalChunks === 1) {
      // unchunked
      return file.slice(0, file.size);
    }
    let initial = (chunkNumber - 1) * chunkSize;
    let final = initial + chunkSize;
    if (final > file.size) {
      final = file.size;
    }
    let blob = file.slice(initial, final);
    return blob;
  }

  private isFileExtensionAllowed(filename: string): boolean {
    let nameSplit = filename.split('.');
    if (nameSplit.length < 2) {
      return false;
    }
    return this.allowedFileExtensions.has(nameSplit[(nameSplit.length - 1)].trim().toLowerCase());
  }

}
