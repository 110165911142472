var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractFeature } from '../../../features/feature.abstract';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';
var FieldPlaceholderTranslateComponent = /** @class */ (function (_super) {
    __extends(FieldPlaceholderTranslateComponent, _super);
    function FieldPlaceholderTranslateComponent(translateService, bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.translateService = translateService;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.isEditing = false;
        _this.translationTagType = TranslationTagType.Placeholder;
        return _this;
    }
    FieldPlaceholderTranslateComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.translateService.isEditing.subscribe(function (editing) {
            _this.isEditing = editing;
        }));
    };
    FieldPlaceholderTranslateComponent.prototype.setData = function () {
        return;
    };
    FieldPlaceholderTranslateComponent.prototype.getData = function () {
        return;
    };
    FieldPlaceholderTranslateComponent.prototype.isDataAvailable = function () {
        return true;
    };
    return FieldPlaceholderTranslateComponent;
}(AbstractFeature));
export { FieldPlaceholderTranslateComponent };
