import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { Contract } from "../../models/contract.model";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";

export interface IAllContracts {
  vehicleContracts: Contract[];
  otherContracts: Contract[];
  vehicleContract: Contract;
  otherContract: Contract;
}

@Injectable({
  providedIn: "root"
})
export class ContractStore {

  private vehicleContracts: Contract[] = null;
  private otherContracts: Contract[] = null;
  private vehicleContract: Contract = null;
  private otherContract: Contract = null;

  private readonly contracts$: BehaviorSubject<IStoreResult<IAllContracts>> = new BehaviorSubject<IStoreResult<IAllContracts>>({ model: null, isInitial: true });
  onGetContracts$ = this.contracts$.asObservable();

  // private readonly contract$: BehaviorSubject<IStoreResult<Contract>> = new BehaviorSubject<IStoreResult<Contract>>({ model: null, isInitial: true });
  // onGetContract$ = this.contract$.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  // #region Requests

  getVehicleContract() {
    return this.vehicleContract;
  }

  getContracts(forceReload: boolean = false): void {

    // Entry found
    if (this.vehicleContracts && !forceReload) {
      this.contracts$.next({ model: this.responseObject(), isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<Contract[]>(`${ApiUrl.Contracts}`, this.settingStore.appSettings)
      .then((data: Contract[]) => {

        // make distinct - issue on miles because sends dupicate contracts
        const distinctContracts: Contract[] = [];
        data.forEach(d => {
          if (!distinctContracts.some(c => c.Id === d.Id)) {
            distinctContracts.push(d);
          }
        });

        this.vehicleContracts = distinctContracts.filter(c => isNaN(Number(c.VehicleId)) || c.VehicleId !== "0");
        this.otherContracts = distinctContracts.filter(c => c.VehicleId === "0");

        this.vehicleContract = this.vehicleContracts !== null ? { ...this.vehicleContracts[0] } : null;
        this.otherContract = this.otherContracts !== null ? { ...this.otherContracts[0] } : null;

        // this.contract$.next({ model: this.responseObject().vehicleContract, isSuccess: true });
        this.contracts$.next({ model: this.responseObject(), isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.vehicleContracts = null;
        this.otherContracts = null;
        this.vehicleContract = null;
        this.otherContract = null;
        this.contracts$.next({ model: this.responseObject(), isSuccess: false, error: err });
        // this.contract$.next({ model: this.vehicleContract, isSuccess: false, error: err });
      });
  }

  reset() {
    this.contracts$.next({ model: null, isInitial: true });
    this.vehicleContract = null;
    this.vehicleContracts = null;
    this.otherContract = null;
    this.otherContracts = null;
  }

  setCurrentContract(contract: Contract) {
    const exists = this.vehicleContracts.some((cntr) => cntr === contract);
    if (exists) {
      this.vehicleContract = contract;
      // this.contract$.next({ model: contract, isSuccess: true });
    }
  }
  // #endregion

  // #region Helpers

  private responseObject(): IAllContracts {
    return {
      vehicleContract: this.vehicleContract,
      vehicleContracts: this.vehicleContracts,
      otherContract: this.otherContract,
      otherContracts: this.otherContracts
    };
  }

  // #endregion

}
