import { Component, OnInit } from '@angular/core';

import { BigAlModule } from '../../../modules/bigal.module';
import { NavigationService } from '../../../services/navigation.service';
import { ApiService } from '../../../services/api.service';
import { Translation } from '../../../models/translation.model';

@Component({
  selector: 'app-missing-translations',
  templateUrl: './missing-translations.component.html',
  exportAs: "modal"
})
export class MissingTranslationComponent implements OnInit {

  translations: Translation[] = [];

  constructor(
    private apiService: ApiService,
    private bigAl: BigAlModule,
    private navigationService: NavigationService) {
  }

  ngOnInit() {

    this.apiService.getAsync(`GetMissingTranslations?countryCode=${this.bigAl.appSettings.CountryCode}`, this.bigAl.appSettings)
      .then((data: Translation[]) => {
        this.translations = data;
      })
      .catch((err: any) => {
        this.translations = [];
      });

  }

  back() {
    this.navigationService.back();
  }

}
