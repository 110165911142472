import { BehaviorSubject } from 'rxjs';
import { FleetUserReportOrder } from '../models/fleetUserReportOrder.model';
var FleetReportService = /** @class */ (function () {
    function FleetReportService() {
        this.reportOrder = new BehaviorSubject(new FleetUserReportOrder("0", [], []));
    }
    FleetReportService.prototype.setOrder = function (order) {
        this.reportOrder.next(order);
    };
    FleetReportService.prototype.getOrder = function () {
        return this.reportOrder.asObservable();
    };
    FleetReportService.prototype.getOrderValue = function () {
        return this.reportOrder.getValue();
    };
    return FleetReportService;
}());
export { FleetReportService };
