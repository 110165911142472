<div class="element-container">
    <div class="sub-box">
        <div class="primary-border-box center-box-content">
            <h2 class="element-title primary-lighter-text">
                <app-translate translationName="PageNotFoundComponent.under_construction_t">
                    <span translate-text>{{'PageNotFoundComponent.under_construction_t' | translate }}
                    </span>
                </app-translate>
                <p>
                    <button mat-raised-button color="primary">
                        <app-translate translationName="PageNotFoundComponent.under_construction_home_t" (click)="goHome()">
                            <span translate-text>{{'PageNotFoundComponent.under_construction_home_t' | translate }}
                            </span>
                        </app-translate>
                    </button>
                </p>

            </h2>
            <img src="assets/ald/images/Temp/papillon.gif" style="width: 200px; height: 200px;">
        </div>
    </div>