<div *ngIf="showMenu" (click)="recordTripService.hideMenu()" class="record-trip-menu-background">
  <div class="record-trip-menu" #menuHandle>
    <!-- <a class="item" routerLink="/recordtrip">
      <mat-icon class="icon-xs" svgIcon="compass"></mat-icon>
      <p class="title">
        <app-translate translationName="MenuRecordTrip.record_trip">
          <span translate-text>{{'MenuRecordTrip.record_trip' | translate}}</span>
        </app-translate>
      </p>
      <p>
        <app-translate translationName="MenuRecordTrip.start_tracking">
          <span translate-text>{{'MenuRecordTrip.start_tracking' | translate}}</span>
        </app-translate>
      </p>
    </a> -->
    <a class="item" routerLink="/journal/driver-journal-daily">
      <mat-icon class="icon-xs" svgIcon="calendar-xxl"></mat-icon>
      <p class="title">
        <app-translate translationName="MenuRecordTrip.driver_journal">
          <span translate-text>{{'MenuRecordTrip.driver_journal' | translate}}</span>
        </app-translate>
      </p>
      <p>
        <app-translate translationName="MenuRecordTrip.lastest_trips">
          <span translate-text>{{'MenuRecordTrip.lastest_trips' | translate}}</span>
        </app-translate>
      </p>
    </a>
  </div>
</div>