import { Component } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { ResponseObject } from '../../../models/responseobject.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractFeature, PrefilledContentEntry } from '../../features/feature.abstract';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss'],
})
export class ApiErrorComponent extends AbstractFeature {

  private currentError: ResponseObject = null;
  // Below two variables are used by .html file.
  // private allowErrorReporting: Boolean = false;
  public reportError: Boolean = false;

  constructor(
    private apiService: ApiService,
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.subscribe(this.apiService.getError()
      .subscribe((errorResponse: HttpErrorResponse) => {
        if (!errorResponse) {
          this.reportError = false;
          this.currentError = null;
        }
        else {
          this.currentError = errorResponse.error;
        }
      }));
  }
  protected setData(): void {
    return;
  }
  protected getData(): void {
    return;
  }

  public isDataAvailable(): boolean {
    return true;
  }

  closeModal(closing: boolean) {
    if (closing) {
      this.apiService.removeError();
    }
  }

  public getFormObject() {

    let prefilled = new Map<string, PrefilledContentEntry[]>();
    prefilled.set("MessageId", [new PrefilledContentEntry("Value", this.currentError.MessageId)]);
    prefilled.set("StatusCode", [new PrefilledContentEntry("Value", this.currentError.StatusCode)]);
    let formObject = super.getFormObject("ReportApiError", prefilled);
    return formObject;
  }

  public getRuleObject() {
    return super.getRuleObject("ReportApiError");
  }

  public reportErrorForm() {
    this.reportError = true;
  }

  // Based on the current error's errorCode, we use a different tag for translation in order to provide more meaningful information to the user.
  public getErrorTypeTranslationTagTitle(): string {
    let baseTag = "ApiError.";
    let defaultTag = baseTag + this.currentError.MessageId + "_title_t";
    switch (this.currentError.StatusCode) {
      case 403:
        if (this.currentError.MessageId === "NFO1003") {
          return baseTag + "refresh_token_invalid_t";
        }
        break;
      case 400:
        return defaultTag;
    }
    return defaultTag;
  }

  public getErrorTypeTranslationTagBody(): string {
    let baseTag = "ApiError.";
    let defaultTag = baseTag + this.currentError.MessageId + "_body_t";
    switch (this.currentError.StatusCode) {
      case 403:
        if (this.currentError.MessageId === "NFO1003") {
          return baseTag + "refresh_token_invalid_t";
        }
        break;
      case 400:
        return defaultTag;
    }
    return defaultTag;
  }
}
