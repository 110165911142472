var TrackingHelper = /** @class */ (function () {
    function TrackingHelper(appUserStore) {
        this.appUserStore = appUserStore;
    }
    TrackingHelper.prototype.trackPage = function (route, name) {
        try {
            if (!this.isUserSet()) {
                return;
            }
            var simplePath = name.substring(name.lastIndexOf('/') + 1);
            dataLayer.push({
                'sessionID': this.userId,
                'screenPath': route,
                'screenName': simplePath // Last part of route
            });
            dataLayer.push({ 'event': 'appScreenView' });
        }
        catch (err) {
        }
    };
    TrackingHelper.prototype.trackClick = function (action, label) {
        try {
            if (!this.isUserSet()) {
                return;
            }
            dataLayer.push({
                'sessionID': this.userId,
                'appEventCategory': 'LinkClick',
                'appEventAction': action,
                'appEventLabel': label // If possible log url we are going to
            });
            dataLayer.push({ 'event': 'appEvent' });
        }
        catch (err) {
        }
    };
    TrackingHelper.prototype.isUserSet = function () {
        // Try to set userId
        if (!this.userId) {
            this.userId = this.appUserStore.appUser.AuthenticationUserId;
        }
        // If AuthenticationUserId is still not set skip tracking
        if (!this.userId) {
            return false;
        }
        return true;
    };
    return TrackingHelper;
}());
export { TrackingHelper };
