var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DriverJournalMonthlyLog } from '../models/driverjournal/driverJournalMonthlyLog.model';
import { DriverJournalDailyRules } from '../models/driverjournal/driverJournalDailyRules.model';
import { ApiService } from './../services/api.service';
import { BehaviorSubject, Subject } from "rxjs";
import { CustomTranslateService } from '../translation/customTranslateService';
import { Feed } from '../models/feed.model';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopSearch } from '../models/workshop-search.model';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { FuelCardOrder } from '../models/fuelcard-order.model';
import { InsuranceClaimFinish } from '../models/insurance-claim-finish.model';
import { CardColour } from '../models/cardorder.model';
import { AppUser } from '../models/appuser.model';
import { SpinnerService } from '../services/spinner.service';
import { EditLogin } from '../models/editlogin.model';
import { Appointment } from '../models/appointment.model';
import { AppointmentPlace } from '../models/appointment-place.model';
import { DatePipe } from '@angular/common';
import { FleetUserService } from '../services/fleet-user.service';
import { WorkshopBooking } from '../models/workshop-booking.model';
import { ChangeDriver } from '../models/change-driver.model';
import { UserActionSuccess } from '../models/userActionSuccess.model';
import { FileUploadService } from '../services/files/file-upload.service';
import { DriverJournalMonthlyRules } from '../models/driverjournal/driverJournalMonthlyRules.model';
import { FleetReportService } from '../services/fleet-report.service';
import { BusinessTripService } from '../services/driverjournal/business-trip.service';
import { NavigationService } from '../services/navigation.service';
import { TableDataUpdatesService } from '../services/tables/table-data-updates.service';
import { AldPosition } from '../models/geo-tracking-device-motion/ald-position.model';
import { ITireBranch, ITireBranchTimeslot, ITireBooking, IBookingServiceType, IBooking, IBusinessPartnerIdAndReference, IValidateToken, IDefaultSettings, IScript, ITireBranchServiceType } from '../sharedtypes/interfaces/sharedtypes.interface';
import { Constants } from '../helpers/constants';
import { ResourceInjectorService } from '../services/resourceInjector.service';
import { CongestionTaxStore, ContractStore, DriverStore, AppUserStore, DriverJournalStore, DocumentStore, InsuranceStore, ParkingStore } from '../stores/DataStores';
import { FirebaseService } from '../services/firebase.service';
import { MonitoringService } from '../services/logging.service';
import { JournalUserQueryTypes } from '../models/enums/journal-user-query-types.enum';
import { ApiUrl } from '../helpers/apiUrls';
import { ChangePasswordForm } from '../models/changepassword.model';
import { TireBranchServiceTypeOption } from '../sharedtypes/enums/sharedtypes.enum';
var BigAlModule = /** @class */ (function () {
    function BigAlModule(firebaseService, appUserStore, contractStore, congestionTaxStore, driverJournalStore, documentStore, insuranceStore, parkingStore, driverStore, DatePipe, apiService, translateService, route, navigationService, spinnerService, fleetUserService, fileUploadService, reportService, businessTripService, tableDataService, resourceInjector, monitoringService) {
        this.firebaseService = firebaseService;
        this.appUserStore = appUserStore;
        this.contractStore = contractStore;
        this.congestionTaxStore = congestionTaxStore;
        this.driverJournalStore = driverJournalStore;
        this.documentStore = documentStore;
        this.insuranceStore = insuranceStore;
        this.parkingStore = parkingStore;
        this.driverStore = driverStore;
        this.DatePipe = DatePipe;
        this.apiService = apiService;
        this.translateService = translateService;
        this.route = route;
        this.navigationService = navigationService;
        this.spinnerService = spinnerService;
        this.fleetUserService = fleetUserService;
        this.fileUploadService = fileUploadService;
        this.reportService = reportService;
        this.businessTripService = businessTripService;
        this.tableDataService = tableDataService;
        this.resourceInjector = resourceInjector;
        this.monitoringService = monitoringService;
        this.appDidInit = new BehaviorSubject(null);
        this.appDidInit$ = this.appDidInit.asObservable();
        this.dataStream = new BehaviorSubject(null);
        this.nextCallStream = new BehaviorSubject(null);
        this.dataStreamFailed = new Subject();
        this.uploadedFiles = [];
        this.testTokenRefresh = false;
        this.callsQueue = [];
        this.defaultSettings = null;
        this.listeningOnFirebaseNotification = false;
        // Used for getting and setting the user's refresh token in the local/session storage.
        this.RefreshTokenKey = "refreshKey";
        this.UserRoleKey = "userRoleKey";
        this.isUnderMaintenance = false;
        this.isInDesktopScreen = true;
        this.isUserLoggedIn = false;
        this.appSettings = null;
        this.currentRuleBook = null;
        this.currentMarketingConsent = null;
        this.currentBusinessPartnerIdAndReference = [];
        this.currentQueriedBusinessPartnerId = "";
        this.currentVehicleContracts = null;
        this.currentOtherContracts = null;
        this.currentVehicleContract = null;
        this.currentOtherContract = null;
        this.currentDriver = null;
        this.currentBusinessPartner = null;
        this.currentVehicle = null;
        this.currentVehicles = null;
        this.currentFuelCards = null;
        this.currentDriverCards = null;
        this.currentTollTags = null;
        this.currentChargingTags = null;
        this.currentFuelCompanies = null;
        this.currentFuelingSummary = null;
        this.currentFeed = null;
        this.currentTireCenter = null;
        this.currentTireBranch = null;
        this.currentWorkshops = null;
        this.currentWorkshopSearch = new WorkshopSearch();
        this.currentAvailableTimes = null;
        this.currentTireBranchTimeslots = null;
        this.currentTireBookings = null;
        this.currentDrivingSettings = null;
        this.currentDriverJournalMonthly = null;
        this.currentDriverJournalDaily = null;
        this.currentFuelRules = null;
        this.currentContacts = null;
        this.currentInsurance = null;
        this.currentContactUsSubjects = null;
        this.currentPasswordResetCode = null;
        this.currentPasswordSetCode = null;
        this.currentBusinessDocuments = null;
        this.currentAvailableReports = null;
        this.currentFleetOldReports = null;
        this.currentChangeDriverFuelCompanies = null;
        this.currentAccessibleFleetUsers = null;
        this.currentBusinessUsers = null;
        this.currentFleetUserContracts = null;
        this.currentFleetUserFleetOverview = null;
        this.currentFleetUserTakeOverInfo = null;
        this.currentSearchedUsers = null;
        this.currentSearchedFleetUsers = null;
        this.currentJournalUsers = null;
        this.currentFleetUserBusinessPartners = null;
        this.currentJournalUser = null;
        this.currentJournalUserQueryAndType = null;
        this.currentJournalUserContracts = null;
        this.currentJournalUserContract = null;
        this.currentJournalUserContractsTimeSpan = null;
        this.showAdminButtons = false;
        // helper vars
        this.resetPasswordPath = "/new-password";
        this.currentBusinessPartnerSearchQuery = null;
        this.currentBookingFormSelectedServiceType = null;
        this.currentDriverJournalMonthlyRules = null;
        this.currentDriverJournalMonthlyEditLog = null;
        this.currentDriverJournalDailyRules = null;
        this.calls();
    }
    BigAlModule.prototype.getDataStream = function () {
        return this.dataStream.asObservable();
    };
    BigAlModule.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var path;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "";
                        if (window.location.href.includes("index.html")) {
                            path = window.location.href.replace("index.html", "assets/default-settings.json");
                        }
                        else {
                            path = "assets/default-settings.json";
                        }
                        return [4 /*yield*/, this.apiService.getFileAsync(path)
                                .then(function (data) {
                                _this.defaultSettings = data;
                            })
                                .catch(function (err) {
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.whoAmI()];
                    case 2:
                        _a.sent();
                        if (!this.appSettings) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getTranslations()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.getRules()];
                    case 4:
                        _a.sent();
                        this.postRefreshToken();
                        this.dataStream.next("init");
                        _a.label = 5;
                    case 5:
                        this.injectGTMTag();
                        this.initMatomo();
                        this.initFleetUserData();
                        this.subscribeToFileService();
                        // Initialize ApplicationInsights
                        this.monitoringService.init(this.defaultSettings.InstrumentationKey, this.defaultSettings.WhoAmI[location.host]);
                        this.appDidInit.next(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.injectGTMTag = function () {
        try {
            var whoAmILocation = this.defaultSettings.WhoAmI[location.host];
            if (whoAmILocation) {
                // We are on the web
                var key = this.getGTMWebKey(whoAmILocation);
                if (key) {
                    this.defaultSettings.GTMTag = key;
                }
            }
            if (this.defaultSettings.GTMTag) {
                // Add GTM key to window
                window["GTMIdentity"] = this.defaultSettings.GTMTag;
                // Create script to inject
                var script = {
                    src: "assets/scripts/gtm.js",
                    identifier: "gtm"
                };
                // Inject into DOM
                this.resourceInjector.injectScript(script);
            }
            else {
                console.warn("Failed to find GTM key: ", whoAmILocation, this.defaultSettings.GTMTag);
            }
        }
        catch (err) {
        }
    };
    BigAlModule.prototype.initMatomo = function () {
        //Based on CompanyId and Country code the tracking Id is assigned for tracking
        //Based on CompanyId and Country code the tracking Id is assigned for tracking from defaultSettings
        if (this.appSettings.CountryCode.toLowerCase() === "dk" && this.appSettings.CompanyId === 1) {
            window["trackId"] = this.defaultSettings.MyAld2DkTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "dk" && this.appSettings.CompanyId === 2) {
            window["trackId"] = this.defaultSettings.MyNf2DkTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "se" && this.appSettings.CompanyId === 1) {
            window["trackId"] = this.defaultSettings.MyAld2SeTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        else if (this.appSettings.CountryCode.toLowerCase() === "se" && this.appSettings.CompanyId === 2) {
            window["trackId"] = this.defaultSettings.MyNf2SeTrackId;
            window["trackUrl"] = this.defaultSettings.TrackUrl;
        }
        var script = {
            src: "assets/scripts/matomo.js",
            identifier: "matomo"
        };
        this.resourceInjector.injectScript(script);
    };
    BigAlModule.prototype.getGTMWebKey = function (whoAmILocation) {
        var key = "";
        try {
            var application = "";
            var domainPrefix = "";
            var environment = "";
            if (whoAmILocation) {
                // What application is running
                application = whoAmILocation.includes("myald2") ? "ald" : "nffleet";
                domainPrefix = application === "ald" ? "my" : "my-nf";
                // Resolve Environment
                if (location.host.includes("localhost")) {
                    if (whoAmILocation.includes("myald2dk")) { // myald2dk | myald2dklocal
                        whoAmILocation = "myald2dk";
                    }
                    else if (whoAmILocation.includes("myald2se")) { // myald2se | myald2selocal
                        whoAmILocation = "myald2se";
                    }
                    else if (whoAmILocation.includes("myald2no")) { // myald2no | myald2nolocal
                        whoAmILocation = "myald2no";
                    }
                    else {
                        console.warn("Failed to find GTM key for unknown whoAmI: ", whoAmILocation);
                    }
                    key = this.defaultSettings.GTMWeb[application]["uat"][whoAmILocation];
                }
                else {
                    if (location.host.includes(domainPrefix + ".uat.ald") ||
                        location.host.includes(domainPrefix + ".test.ald")) {
                        environment = "uat";
                    }
                    else if (!location.host.includes(domainPrefix + ".dev.ald") &&
                        !location.host.includes(domainPrefix + ".test.ald") &&
                        !location.host.includes(domainPrefix + ".uat.ald")) {
                        environment = "prod";
                    }
                    key = this.defaultSettings.GTMWeb[application][environment][whoAmILocation];
                }
            }
        }
        catch (err) {
            console.warn('Failed to find GTM key: ', err);
        }
        return key;
    };
    BigAlModule.prototype.initFleetUserData = function () {
        var isInTakeOverMode = this.getStorageValueForIsInTakeOverMode();
        this.fleetUserService.setIsInTakeOverMode(isInTakeOverMode);
        this.fleetUserService.isInTakeOverMode.subscribe(function (isInTakeOverMode) {
            localStorage.setItem(Constants.LocalStorage_IsInTakeOverModeKey, isInTakeOverMode.toString());
        });
    };
    BigAlModule.prototype.getStorageValueForIsInTakeOverMode = function () {
        return localStorage.getItem(Constants.LocalStorage_IsInTakeOverModeKey) === "true";
    };
    BigAlModule.prototype.setTraceId = function (appSettings) {
        if (!this.currentTraceId) {
            this.currentTraceId = this.newGuid();
        }
        appSettings.TraceId = this.currentTraceId;
        return appSettings;
    };
    BigAlModule.prototype.newGuid = function () {
        var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        guid = guid.toLowerCase().replace(/-/g, "");
        return guid;
    };
    BigAlModule.prototype.whoAmI = function () {
        return __awaiter(this, void 0, void 0, function () {
            var whoAmILocation;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.appSettings) {
                            return [2 /*return*/];
                        }
                        whoAmILocation = "";
                        try {
                            whoAmILocation = this.defaultSettings.WhoAmI[location.host];
                            if (whoAmILocation === undefined) {
                                whoAmILocation = this.defaultSettings.WhoAmI["app"];
                            }
                        }
                        catch (err) {
                            whoAmILocation = this.defaultSettings.WhoAmI["app"];
                        }
                        return [4 /*yield*/, this.apiService.getAsync("whoami2/" + whoAmILocation, this.defaultSettings)
                                .then(function (data) {
                                localStorage.setItem('countryCode', data.CountryCode);
                                _this.appSettings = _this.setTraceId(data);
                                _this.appSettings.FeatureComponentConfiguration = data.FeatureComponentConfiguration;
                                _this.appSettings.LanguageCode = (localStorage.getItem('language')) ? localStorage.getItem('language') : _this.translateService.getCountryLanguage(_this.appSettings.CountryCode);
                                _this.localLanguageCountryCode = _this.appSettings.CountryCode;
                                _this.appSettings.IsApp = _this.defaultSettings.IsApp;
                                if (localStorage.getItem("language")) {
                                    _this.changeLanguage(localStorage.getItem("language"));
                                }
                                _this.setDefaultLocation(data.CountryCode);
                            })
                                .catch(function (err) {
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.setDefaultLocation = function (country) {
        this.defaultPosition = new AldPosition();
        switch (country) {
            case 'dk':
                this.defaultPosition.Latitude = 55.661265;
                this.defaultPosition.Longitude = 12.280537;
                break;
            case 'se':
                this.defaultPosition.Latitude = 59.3289;
                this.defaultPosition.Longitude = 18.0649;
                break;
            case 'no':
                this.defaultPosition.Latitude = 59.911491;
                this.defaultPosition.Longitude = 10.757933;
                break;
            default:
                this.defaultPosition.Latitude = null;
                this.defaultPosition.Longitude = null;
                break;
        }
    };
    BigAlModule.prototype.getFeatureComponentConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getAsync("featurecomponentconfigurations", this.defaultSettings)
                            .then(function (data) {
                            _this.appSettings.FeatureComponentConfiguration = data;
                        })
                            .catch(function (err) {
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.getTranslations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getAsync("languages", this.defaultSettings)
                            .then(function (data) {
                            _this.translateService.loadCompanyTranslations(data, _this.appSettings.CompanyId);
                            _this.translateService.setLanguage(_this.appSettings.CountryCode);
                            if (localStorage.getItem("language")) {
                                _this.translateService.changeLanguageByLanguageCode(localStorage.getItem("language"));
                            }
                        })
                            .catch(function (err) {
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.getRules = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // get rules
                    return [4 /*yield*/, this.apiService.getAsync("rules", this.defaultSettings)
                            .then(function (data) {
                            _this.currentRuleBook = data;
                        })
                            .catch(function (err) {
                        })];
                    case 1:
                        // get rules
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.getBusinessDocuments = function () {
        if (!this.currentBusinessDocuments) {
            this.getBusinessPartner();
            this.addCall("currentBusinessDocuments");
        }
    };
    BigAlModule.prototype.subscribeToFileService = function () {
        var _this = this;
        this.fileUploadService.getFilePathObservable().subscribe(function (file) {
            if (file) {
                _this.uploadedFiles.push(file.FileToken);
            }
        });
    };
    BigAlModule.prototype.getAppointmentPlace = function (modelName) {
        var appointmentPlace;
        if (modelName === "TireCenter") {
            this.getTireCenter();
            var bookings = [];
            if (this.currentTireCenter) {
                for (var index = 0; index < this.currentTireCenter.Bookings.length; index++) {
                    var appointment = this.currentTireCenter.Bookings[index];
                    bookings.push(new Appointment(appointment.AppointmentId, appointment.Appointment, appointment.MobilePhoneNumber, appointment.Description));
                }
                appointmentPlace = new AppointmentPlace("TireCenter", this.currentTireCenter.Name, this.currentTireCenter.Street, this.currentTireCenter.PostalCodeAndCity, this.currentTireCenter.Telephone, bookings);
            }
            else {
                appointmentPlace = new AppointmentPlace("TireCenter", '', '', '', '', []);
            }
            return appointmentPlace;
        }
        else {
            return new AppointmentPlace(null, null, null, null, null);
        }
    };
    BigAlModule.prototype.getAvailableTimes = function (modelName) {
        if (modelName === "TireCenter") {
            this.getTireCenter();
            this.addCall("currentTireCenterAvailableTimes");
        }
    };
    BigAlModule.prototype.getTireBookings = function () {
        this.getTireCenterBranch();
        this.addCall(Constants.Event_CurrentTireBookings);
        this.spinnerService.hide();
    };
    BigAlModule.prototype.getTireCenterAvailableTimeslots = function () {
        this.getTireCenterBranch();
        this.addCall(Constants.Event_CurrentTireBranchTimes);
        this.spinnerService.hide();
    };
    BigAlModule.prototype.postBooking = function (booking) {
        var _this = this;
        this.spinnerService.show();
        return this.apiService.postAsync("tire/booking/" + this.currentTireBranch.CenterType, booking, this.appSettings)
            .then(function () {
            _this.currentTireBookings = null;
            _this.getTireBookings();
            _this.spinnerService.hide();
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
            throw err;
        });
    };
    BigAlModule.prototype.putBooking = function (id, booking) {
        var _this = this;
        this.spinnerService.show();
        return this.apiService.putAsync("tire/booking/" + this.currentTireBranch.CenterType + "/" + id, booking, this.appSettings)
            .then(function () {
            _this.spinnerService.hide();
            _this.currentTireBookings = null;
            _this.getTireBookings();
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
            throw err;
        });
    };
    BigAlModule.prototype.deleteBooking = function (id) {
        var _this = this;
        this.spinnerService.show();
        return this.apiService.deleteAsync("tire/booking/" + this.currentTireBranch.CenterType + "/" + this.currentVehicleContract.Id + "/" + id, null, this.appSettings)
            .then(function () {
            _this.currentTireBookings = null;
            _this.spinnerService.hide();
            _this.getTireBookings();
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
            throw err;
        });
    };
    BigAlModule.prototype.postAppointment = function (modelName, appointment) {
        var _this = this;
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.getTireCenter();
            this.apiService.postAsync("TireCenter/appointments/" + this.currentVehicleContract.Id, appointment, this.appSettings)
                .then(function () {
                _this.currentTireCenter = null;
                _this.getTireCenter();
                return true;
            });
        }
    };
    BigAlModule.prototype.updateAppointment = function (modelName, appointment) {
        var _this = this;
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.getTireCenter();
            this.apiService.putAsync("TireCenter/appointments/" + this.currentVehicleContract.Id + "/" + appointment.AppointmentId, appointment, this.appSettings)
                .then(function () {
                _this.currentTireCenter = null;
                _this.getTireCenter();
                return true;
            })
                .catch(function (err) { });
        }
    };
    BigAlModule.prototype.deleteAppointment = function (modelName, appointmentId) {
        var _this = this;
        if (modelName === "TireCenter") {
            this.spinnerService.show();
            this.getContracts();
            this.apiService.deleteAsync("TireCenter/appointments/" + this.currentVehicleContract.Id + "/" + appointmentId, {}, this.appSettings)
                .then(function () {
                _this.currentTireCenter = null;
                _this.getTireCenter();
                return true;
            })
                .catch(function (err) { });
        }
    };
    BigAlModule.prototype.setUserLocation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.userPosition = new AldPosition();
                this.userPosition.Latitude = this.defaultPosition.Latitude;
                this.userPosition.Longitude = this.defaultPosition.Longitude;
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        _this.userPosition.Latitude = position.coords.latitude;
                        _this.userPosition.Longitude = position.coords.longitude;
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    BigAlModule.prototype.getAvailableLanguageCodes = function () {
        return this.translateService.getLanguageCodes();
    };
    BigAlModule.prototype.changeLanguage = function (languageCode) {
        localStorage.setItem("language", languageCode);
        this.appSettings.LanguageCode = languageCode;
        this.translateService.changeLanguageByLanguageCode(this.appSettings.LanguageCode);
        this.postChangeLanguage();
    };
    BigAlModule.prototype.takeOverDriver = function (contractId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinnerService.show();
                        return [4 /*yield*/, this.apiService.getAsync("FleetUser/TakeOverDriver/" + contractId, this.appSettings)
                                .then(function (data) {
                                _this.currentFleetUserTakeOverInfo = data;
                                _this.currentDriver = _this.currentFleetUserTakeOverInfo.Driver;
                                _this.fleetUserService.setIsInTakeOverMode(true);
                                _this.navigationService.forward("cars", null);
                                _this.spinnerService.hide();
                            })
                                .catch(function (err) {
                                _this.spinnerService.hide();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.revertTakeOverDriver = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinnerService.show();
                        return [4 /*yield*/, this.apiService.getAsync("FleetUser/RevertTakeOverDriver", this.appSettings)
                                .then(function (data) {
                                _this.clearData(false);
                                _this.resetStores();
                                _this.fleetUserService.setIsInTakeOverMode(false);
                                _this.navigationService.forward("fleet/overview", null, { queryParams: { reload: true } });
                                _this.spinnerService.hide();
                            })
                                .catch(function (err) {
                                _this.spinnerService.hide();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.resetStores = function () {
        this.contractStore.reset();
        this.driverStore.reset();
        this.congestionTaxStore.reset();
        this.driverJournalStore.reset();
        this.parkingStore.reset();
        this.insuranceStore.reset();
        this.documentStore.reset();
    };
    BigAlModule.prototype.getContracts = function () {
        if (this.currentVehicleContracts === null) {
            this.addCall(Constants.Event_CurrentContracts);
        }
    };
    BigAlModule.prototype.clearContractDependent = function () {
        this.currentFuelCards = null;
        this.currentDriverJournalMonthly = null;
        this.currentDriverJournalDaily = null;
        this.currentFuelingSummary = null;
        this.currentInsurance = null;
        this.congestionTaxStore.reset();
    };
    BigAlModule.prototype.changeContractAndVehicle = function () {
        var _this = this;
        var contractIndex = this.currentVehicleContracts.findIndex(function (c) { return c.Id === _this.currentVehicleContract.Id; });
        if (contractIndex < this.currentVehicleContracts.length - 1) {
            this.currentVehicleContract = this.currentVehicleContracts[contractIndex + 1];
        }
        else {
            this.currentVehicleContract = this.currentVehicleContracts[0];
        }
        this.clearContractDependent();
        this.addCall(Constants.Event_CurrentVehicle);
    };
    BigAlModule.prototype.getVehicle = function () {
        if (this.currentVehicle === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentVehicle);
        }
    };
    BigAlModule.prototype.getVehicles = function () {
        if (this.currentVehicles === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentVehicles);
        }
    };
    BigAlModule.prototype.getBusinessPartner = function () {
        if (this.currentBusinessPartner === null) {
            this.addCall(Constants.Event_CurrentBusinessPartner);
        }
    };
    BigAlModule.prototype.getDriver = function () {
        if (this.currentDriver === null) {
            this.addCall(Constants.Event_CurrentDriver);
        }
    };
    BigAlModule.prototype.getDriverProfile = function (driverId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getAsync("Drivers/" + driverId, this.appSettings)
                            .then(function (data) {
                            _this.currentDriver = data;
                            _this.dataStream.next(Constants.Event_CurrentDriver);
                        })
                            .catch(function (err) {
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BigAlModule.prototype.getMarketConsent = function () {
        if (this.currentMarketingConsent === null) {
            this.addCall("currentMarketingConsent");
        }
    };
    BigAlModule.prototype.getTireCenter = function () {
        if (this.currentTireCenter === null) {
            this.getContracts();
            this.getDriver();
            this.addCall(Constants.Event_CurrentTireCenter);
        }
    };
    BigAlModule.prototype.getTireCenterBranch = function () {
        if (this.currentTireBranch === null) {
            this.getContracts();
            this.getDriver();
            this.addCall(Constants.Event_CurrentTireBranch);
            this.spinnerService.hide();
        }
    };
    BigAlModule.prototype.getWorkshops = function () {
        this.getContracts();
        this.addCall(Constants.Event_CurrentWorkshops);
    };
    BigAlModule.prototype.getFuelCards = function () {
        if (this.currentFuelCards === null) {
            this.getContracts();
            this.addCall("currentFuelCards");
        }
    };
    BigAlModule.prototype.getDriverCards = function () {
        if (this.currentDriverCards === null) {
            this.getContracts();
            this.addCall("currentDriverCards");
        }
    };
    BigAlModule.prototype.getChargingTags = function () {
        if (this.currentChargingTags === null) {
            this.getContracts();
            this.addCall("currentChargingTags");
        }
    };
    BigAlModule.prototype.getTollTags = function () {
        if (this.currentTollTags === null) {
            this.getContracts();
            this.addCall("currentTollTags");
        }
    };
    BigAlModule.prototype.getFuelCompanies = function () {
        if (this.currentFuelCompanies === null) {
            this.getContracts();
            this.addCall("currentFuelCompanies");
        }
    };
    BigAlModule.prototype.getFuelingSummary = function () {
        if (!this.currentFuelingSummary) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentFuelSummary);
        }
    };
    BigAlModule.prototype.getFleetUser = function () {
        if (!this.currentFleetUser) {
            this.addCall(Constants.Event_CurrentFleetUser);
        }
    };
    BigAlModule.prototype.getFleetUserById = function (id) {
        var _this = this;
        this.currentFleetUser = null;
        this.apiService.getAsync("FleetUser/" + id, this.appSettings)
            .then(function (data) {
            _this.currentFleetUser = data;
        })
            .then(function () {
            _this.next();
            _this.dataStream.next(Constants.Event_CurrentFleetUser);
        })
            .catch(function (err) {
            _this.next();
        });
    };
    BigAlModule.prototype.getFleetUserByUsername = function (username) {
        var _this = this;
        this.currentFleetUser = null;
        this.apiService.getAsync("FleetUser/name/" + username, this.appSettings)
            .then(function (data) {
            _this.currentFleetUser = data;
        })
            .then(function () {
            _this.next();
            _this.dataStream.next(Constants.Event_CurrentFleetUser);
        })
            .catch(function (err) {
            _this.next();
        });
    };
    BigAlModule.prototype.getAccessibleFleetUsers = function () {
        if (!this.currentAccessibleFleetUsers) {
            this.addCall("currentAccessibleFleetUsers");
        }
    };
    BigAlModule.prototype.getBusinessUsers = function () {
        if (!this.currentBusinessUsers) {
            this.addCall(Constants.Event_BusinessUsers);
        }
    };
    BigAlModule.prototype.getUsersByQuery = function (queryString, endpoint) {
        var _this = this;
        this.spinnerService.show();
        var apiPromise = this.apiService.getAsync(endpoint + queryString, this.appSettings)
            .then(function (data) {
            _this.currentSearchedUsers = data;
            _this.dataStream.next("currentUsers");
            _this.spinnerService.hide();
            return true;
        }).catch(function () {
            _this.currentSearchedUsers = [];
            _this.dataStream.next("currentUsers");
            _this.spinnerService.hide();
            return false;
        });
        return apiPromise;
    };
    BigAlModule.prototype.getFleetUsersForBusinessPartner = function (businessPartnerId) {
        var _this = this;
        this.spinnerService.show();
        this.currentBusinessPartnerSearchQuery = businessPartnerId;
        this.currentSearchedFleetUsers = null;
        var apiPromise = this.apiService.getAsync("users/businessPartner/fleetmanagers/" + businessPartnerId, this.appSettings)
            .then(function (data) {
            _this.currentSearchedFleetUsers = data;
            _this.dataStream.next(Constants.Event_CurrentFleetUsers);
            _this.spinnerService.hide();
            return true;
        }).catch(function (err) {
            _this.spinnerService.hide();
            _this.currentSearchedFleetUsers = [];
            _this.dataStream.next(Constants.Event_CurrentFleetUsers);
            return false;
        });
        return apiPromise;
    };
    BigAlModule.prototype.getBusinessPartnerIdAndReference = function (businessPartnerId) {
        var _this = this;
        this.spinnerService.show();
        var apiPromise = this.apiService.getAsync("businesspartners/" + businessPartnerId + "/getIdAndReference", this.appSettings)
            .then(function (data) {
            _this.currentBusinessPartnerIdAndReference = data;
            _this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);
            return true;
        })
            .catch(function () {
            _this.currentBusinessPartnerIdAndReference = [];
            _this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);
            return false;
        });
        return apiPromise;
    };
    BigAlModule.prototype.getBusinessPartnerConcern = function (businessPartnerId) {
        var _this = this;
        this.spinnerService.show();
        return this.apiService.getAsync("businesspartners/" + businessPartnerId + "/concern", this.appSettings)
            .then(function (value) {
            _this.spinnerService.hide();
            return value;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
            throw err;
        });
    };
    BigAlModule.prototype.getBusinessPartnersById = function (businessPartnerId) {
        var _this = this;
        this.currentFleetUserBusinessPartners = null;
        this.currentQueriedBusinessPartnerId = businessPartnerId;
        this.spinnerService.show();
        var apiPromise = this.apiService.getAsync("businesspartners/" + businessPartnerId + "/status/all", this.appSettings)
            .then(function (data) {
            _this.currentFleetUserBusinessPartners = data;
            _this.currentAccessibleFleetUsers = [];
            _this.currentSearchedFleetUsers = [];
            _this.currentBusinessPartnerIdAndReference = [];
            if (_this.currentFleetUserBusinessPartners) {
                _this.getFleetUsersForBusinessPartner(businessPartnerId);
                _this.getBusinessPartnerIdAndReference(businessPartnerId);
            }
            // If 'data' is null they queried ID dose not exist and we treat it as an error
            if (!data) {
                _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: null });
            }
            else {
                _this.dataStream.next(Constants.Event_CurrentFleetUserBusinessPartners);
            }
            _this.spinnerService.hide();
            return true;
        })
            .catch(function () {
            _this.currentFleetUserBusinessPartners = null;
            _this.currentAccessibleFleetUsers = [];
            _this.currentSearchedFleetUsers = [];
            _this.currentBusinessPartnerIdAndReference = [];
            _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: null });
            _this.spinnerService.hide();
            return false;
        });
        return apiPromise;
    };
    BigAlModule.prototype.getFleetUserFleetOverview = function (force) {
        if (force === void 0) { force = false; }
        if (!this.currentFleetUserFleetOverview || force) {
            this.getFleetUser();
            this.addCall(Constants.Event_CurrentFleetOverview);
        }
    };
    BigAlModule.prototype.getFleetUserBusinessPartners = function () {
        if (!this.currentFleetUserBusinessPartners) {
            this.addCall(Constants.Event_CurrentFleetUserBusinessPartners);
        }
    };
    BigAlModule.prototype.getFleetUserBusinessPartnersIdAndReference = function () {
        if (!this.currentBusinessPartnerIdAndReference) {
            this.addCall(Constants.Event_CurrentBusinessPartnerIdAndReference);
        }
    };
    BigAlModule.prototype.getContactUsSubjects = function () {
        if (this.currentContactUsSubjects === null) {
            this.getContracts();
            this.addCall("currentContactUsSubjects");
        }
    };
    BigAlModule.prototype.setDriverJournalMonthlyRulesDefault = function () {
        var currentDate = new Date().getDate();
        var year = new Date().getFullYear();
        var currentMonth = new Date().getMonth();
        if (currentMonth === 0 && currentDate <= 17) { // if beginning of Jan show prev year
            year = year - 1;
        }
        this.currentDriverJournalMonthlyRules = new DriverJournalMonthlyRules(year);
    };
    BigAlModule.prototype.getDrivingSettings = function () {
        this.addCall("currentDrivingSettings");
    };
    BigAlModule.prototype.getFuelRules = function () {
        this.getContracts();
        this.addCall(Constants.Event_currentFuelRules);
    };
    /**
     * This shall be used by drivers asking for their DJ entries
     */
    BigAlModule.prototype.getDriverJournalMonthly = function () {
        this.getContracts();
        this.addCall(Constants.Event_CurrentDriverJournalMonthly);
    };
    /**
     * This shall be used by business users asking for a drivers DJ entries
     */
    BigAlModule.prototype.getDriverJournalMonthlyForDriver = function () {
        if (!this.currentJournalUserContract) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
        this.addCall(Constants.Event_CurrentDriverJournalMonthlyForDriver);
    };
    BigAlModule.prototype.getDriverJournalMonthlyEditDefaultLog = function () {
        return this.currentDriverJournalMonthlyEditLog;
    };
    BigAlModule.prototype.setDriverJournalMonthlyLogDefault = function (month, year, contractId) {
        this.currentDriverJournalMonthlyEditLog = new DriverJournalMonthlyLog(contractId, null, year, month, null, null, null, null, null, null, null);
    };
    BigAlModule.prototype.resetDriverJournalMonthlyLogDefault = function () {
        this.currentDriverJournalMonthlyEditLog = null;
    };
    BigAlModule.prototype.setDriverJournalDailyRulesDefault = function () {
        this.currentDriverJournalDailyRules = new DriverJournalDailyRules(new Date().getMonth(), new Date().getFullYear());
    };
    BigAlModule.prototype.getDriverJournalDaily = function () {
        this.getContracts();
        this.getVehicle();
        this.addCall(Constants.Event_CurrentDriverJournalDaily);
    };
    BigAlModule.prototype.getDriverJournalDailyForDriver = function () {
        this.getJournalUserContracts();
        this.addCall(Constants.Event_CurrentDriverJournalDailyForDriver);
    };
    BigAlModule.prototype.getJournalUsersByQuery = function () {
        if (this.currentJournalUsers === null) {
            this.addCall(Constants.Event_CurrentJournalUsers);
        }
    };
    BigAlModule.prototype.getJournalUserContracts = function () {
        if (this.currentJournalUserContracts === null) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
    };
    BigAlModule.prototype.getAvailableReports = function () {
        if (this.currentAvailableReports === null) {
            this.addCall(Constants.Event_CurrentFleetAvailableReports);
        }
    };
    BigAlModule.prototype.getOldReports = function () {
        if (this.currentFleetOldReports === null) {
            this.addCall(Constants.Event_CurrentFleetOldReports);
        }
    };
    BigAlModule.prototype.getChangeDriverFuelCompanies = function () {
        if (this.currentChangeDriverFuelCompanies === null) {
            this.addCall("currentChangeDriverFuelCompanies");
        }
    };
    BigAlModule.prototype.setCurrentUser = function (user) {
        this.isUserLoggedIn = true;
        this.appUserStore.appUser = user;
        // Let ApplicationInsights attach traces to the authenticated user
        this.monitoringService.setAuthenticatedUser(user.Username);
        // Check if PUSH token is set
        /*
        const pushToken = localStorage.getItem("pushToken");
        if (!pushToken) {
          // Get a new token
          this.wireupFirebasePush();
        }*/
        // this.firebaseService.setupFirebasePush(this.appSettings);
        this.setStorageData(this.appUserStore.appUser, true);
        this.dataStream.next(Constants.Event_currentUser);
    };
    BigAlModule.prototype.postUserSettings = function (model) {
        this.appUserStore.saveUserSettings(model);
    };
    BigAlModule.prototype.postSettingsForm = function (model) {
        this.appUserStore.saveSettings(model, this.appUserStore.appUser.AuthenticationUserId);
    };
    BigAlModule.prototype.postUserLogin = function (userLogin) {
        var _this = this;
        userLogin.Username = userLogin.Username.trim();
        this.spinnerService.show();
        this.apiService.postAsync("users/login", userLogin, this.appSettings)
            .then(function (data) {
            //Enabling the password policy       
            if (data.IsPasswordExpiry) {
                _this.spinnerService.hide();
                return _this.navigationService.forward("change-password", null);
            }
            if (_this.isUnderMaintenance && !data.Roles.includes(UserRoleType.MaintenanceUser)) {
                _this.navigationService.openModal('app-under-maintenance');
                _this.spinnerService.hide();
            }
            else if (data instanceof HttpErrorResponse) {
                _this.appUserStore.appUser = AppUser.GetInitialUser();
                _this.spinnerService.hide();
            }
            else {
                _this.setCurrentUser(data);
                var returnUrl = _this.route.snapshot.queryParams['returnUrl'] || '';
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; }) && _this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver; })) {
                    _this.navigationService.forward(returnUrl, null);
                }
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver; }) && !_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; }) ||
                    _this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.SystemAdminDriver; })) {
                    _this.navigationService.forward("cars", null);
                }
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin; }) && !_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver; }) ||
                    _this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.SystemAdminFleetAdmin; })) {
                    _this.navigationService.forward("fleet/overview", null);
                }
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin; })) {
                    _this.navigationService.forward("dashboard", null);
                }
                _this.dataStream.next('userLogin');
                _this.spinnerService.hide();
            }
        }).catch(function () {
            _this.appUserStore.appUser = AppUser.GetInitialUser();
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postForgotLoginComponent = function (resetPasswordRequest) {
        resetPasswordRequest.Query = resetPasswordRequest.Query.trim();
        return this.resetUserPassword(resetPasswordRequest, "Password/Reset2", true);
    };
    BigAlModule.prototype.postResetPassword = function (resetPasswordRequest) {
        var _this = this;
        return this.resetUserPassword(resetPasswordRequest, "Password/Reset/" + resetPasswordRequest.Id, false).then(function (data) {
            if (data) {
                _this.apiService.addSuccess(new UserActionSuccess("ResetPasswordBusinessUser"));
                return true;
            }
            return false;
        });
    };
    BigAlModule.prototype.postTrackingTestData = function (name, trackingData) {
        return this.apiService.postAsync("testdata/dj", { name: name, trackingInformation: trackingData }, this.appSettings);
    };
    BigAlModule.prototype.getTrackingTestData = function (id) {
        return this.apiService.getAsync("testdata/dj/" + id, this.appSettings);
    };
    BigAlModule.prototype.resetUserPassword = function (resetPasswordModel, url, redirect) {
        var _this = this;
        this.spinnerService.show();
        resetPasswordModel.SetPasswordPath = this.resetPasswordPath;
        var apiPromise = this.apiService.postAsync(url, resetPasswordModel, this.appSettings)
            .then(function () {
            _this.spinnerService.hide();
            if (redirect === true) {
                _this.navigationService.forward("forgot-login/confirmation", null);
            }
            return true;
        }).catch(function () {
            _this.spinnerService.hide();
            return false;
        });
        return apiPromise;
    };
    BigAlModule.prototype.postFleetUser = function (fleetUser) {
        var _this = this;
        var possibleBusinessPartners = this.currentFleetUserBusinessPartners;
        var businessPartnerIds = [];
        var concernId = this.currentBusinessPartnerIdAndReference.find(function (x) { return x.Id === _this.currentQueriedBusinessPartnerId || x.Reference === _this.currentQueriedBusinessPartnerId; });
        fleetUser.ConcernId = concernId.Id;
        // If the new user is a fleet admin, add all business partners and do not bother processing what was selected.
        if (fleetUser.Role === UserRoleType.FleetAdmin) {
            businessPartnerIds.push(concernId.Id);
        }
        else {
            Object.keys(fleetUser).forEach(function (key) {
                // If the business partner was not selected, and the new user is not a fleet manager, skip this one.
                if (fleetUser[key] !== true) {
                    return;
                }
                var curBp = possibleBusinessPartners.find(function (bp) { return bp.Id === key; });
                if (curBp && fleetUser[key] === true && fleetUser.Role === UserRoleType.FleetManager) {
                    businessPartnerIds.push(key);
                }
            });
        }
        fleetUser.BusinessPartnerIds = businessPartnerIds;
        if (!fleetUser.Username) {
            fleetUser.Username = fleetUser.Email;
        }
        fleetUser.SetPasswordPath = this.resetPasswordPath;
        this.spinnerService.show();
        // if you have an id it is an update
        if (fleetUser.AuthenticationUserId && fleetUser.AuthenticationUserId.length > 1) {
            this.apiService.putAsync("users/update/", fleetUser, this.appSettings)
                .then(function (data) {
                if (_this.currentBusinessPartnerSearchQuery &&
                    _this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser ||
                        r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver; })) {
                    _this.currentSearchedFleetUsers = null;
                    _this.spinnerService.hide();
                    _this.getFleetUsersForBusinessPartner(_this.currentBusinessPartnerSearchQuery);
                }
                else {
                    _this.currentAccessibleFleetUsers = null;
                    _this.spinnerService.hide();
                    _this.getAccessibleFleetUsers();
                }
                _this.apiService.addSuccess(new UserActionSuccess("UpdateFleetUser"));
                return true;
            })
                .catch(function (err) {
                _this.spinnerService.hide();
                _this.apiService.addError(err);
            });
        }
        // if you have no id it is a create
        else {
            this.apiService.postAsync("users/create", fleetUser, this.appSettings)
                .then(function (data) {
                if (_this.currentBusinessPartnerSearchQuery &&
                    _this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser ||
                        r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver; })) {
                    _this.currentSearchedFleetUsers = null;
                    _this.getFleetUsersForBusinessPartner(_this.currentBusinessPartnerSearchQuery);
                    _this.spinnerService.hide();
                }
                else {
                    _this.currentAccessibleFleetUsers = null;
                    _this.spinnerService.hide();
                    _this.getAccessibleFleetUsers();
                }
                _this.apiService.addSuccess(new UserActionSuccess("CreateFleetUser"));
                return true;
            })
                .catch(function (err) {
                _this.spinnerService.hide();
                _this.apiService.addError(err);
            });
        }
    };
    BigAlModule.prototype.postBusinessUser = function (businessUser) {
        var _this = this;
        businessUser.SetPasswordPath = this.resetPasswordPath;
        this.spinnerService.show();
        //update business user
        if (businessUser.AuthenticationUserId && businessUser.AuthenticationUserId.length > 1) {
            this.apiService.putAsync("users/updatebusinessuser", businessUser, this.appSettings)
                .then(function (data) {
                _this.currentBusinessUsers = null;
                _this.spinnerService.hide();
                _this.getBusinessUsers();
                _this.apiService.addSuccess(new UserActionSuccess("UpdateBusinessUser"));
                return true;
            })
                .catch(function (err) {
                _this.spinnerService.hide();
                _this.apiService.addError(err);
            });
        }
        else { //create business user
            this.apiService.postAsync("users/createbusinessuser", businessUser, this.appSettings)
                .then(function (data) {
                _this.currentBusinessUsers = null;
                _this.spinnerService.hide();
                _this.getBusinessUsers();
                _this.apiService.addSuccess(new UserActionSuccess("CreateBusinessUser"));
                return true;
            })
                .catch(function (err) {
                _this.spinnerService.hide();
                _this.apiService.addError(err);
            });
        }
    };
    BigAlModule.prototype.postDeleteFleetUser = function (fleetUserId) {
        var _this = this;
        this.apiService.deleteAsync("users/delete/" + fleetUserId, "", this.appSettings)
            .then(function (data) {
            if (_this.currentBusinessPartnerSearchQuery &&
                _this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser ||
                    r === UserRoleType.SystemAdminFleetAdmin || r === UserRoleType.SystemAdminDriver; })) {
                _this.currentSearchedFleetUsers = null;
                _this.getFleetUsersForBusinessPartner(_this.currentBusinessPartnerSearchQuery);
            }
            else {
                _this.currentAccessibleFleetUsers = null;
                _this.getAccessibleFleetUsers();
            }
            _this.apiService.addSuccess(new UserActionSuccess("DeleteFleetUser"));
            return true;
        }, function () { return false; })
            .catch(function (err) {
            _this.apiService.addError(err);
        });
    };
    BigAlModule.prototype.deleteBusinessUser = function (businessUserId) {
        var _this = this;
        this.apiService.deleteAsync("users/delete/" + businessUserId, "", this.appSettings)
            .then(function (data) {
            _this.currentBusinessUsers = null;
            _this.getBusinessUsers();
            _this.apiService.addSuccess(new UserActionSuccess("DeleteBusinessUser"));
            return true;
        }, function () { return false; })
            .catch(function (err) {
            _this.apiService.addError(err);
        });
    };
    BigAlModule.prototype.postFleetDriverNumberChange = function (_a) {
        var _this = this;
        var CurrentDriverNumber = _a.CurrentDriverNumber, NewDriverNumber = _a.NewDriverNumber;
        if (!this.currentDriver) {
            console.warn("No driver.");
            return;
        }
        var driver = this.currentDriver;
        driver.EmployeeNo = NewDriverNumber;
        this.apiService.putAsync("drivers/profile/" + driver.DriverId, driver, this.appSettings)
            .then(function () {
            _this.getDriver();
            _this.doThenWithSuccessAndForward('FleetDriverNumberChange', "cars");
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postNewPasswordComponent = function (setPasswordRequest) {
        var _this = this;
        setPasswordRequest.SetCode = this.currentPasswordSetCode;
        setPasswordRequest.Password = btoa(setPasswordRequest.Password);
        setPasswordRequest.ConfirmPassword = setPasswordRequest.Password;
        this.apiService.putAsync("Password", setPasswordRequest, this.appSettings)
            .then(function (data) {
            _this.executeLogout(false);
            _this.dataStream.next("passwordSet");
        }, function () { return false; })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.getPasswordSetCode = function (resetCode) {
        if (!resetCode) {
            return;
        }
        if (this.currentPasswordSetCode === null || (resetCode !== this.currentPasswordResetCode)) {
            this.currentPasswordSetCode = null;
            this.currentPasswordResetCode = resetCode;
            this.addCall("currentPasswordSetCode");
        }
    };
    BigAlModule.prototype.mergePrefixValues = function (model) {
        Object.keys(model).forEach(function (key) {
            if (model.hasOwnProperty(key + "_prefix")) {
                model[key] = model[key + "_prefix"] + model[key];
            }
        });
        return model;
    };
    BigAlModule.prototype.postMileage = function (mileage) {
        var _this = this;
        var newdate = new Date(mileage.NewDate);
        var timezoneOffset = (newdate.getTimezoneOffset()) * (-1);
        newdate.setMinutes(timezoneOffset);
        mileage.ContractId = this.currentVehicleContract.Id;
        mileage.NewDate = newdate;
        console.log(newdate);
        this.apiService.postAsync(ApiUrl.MileageUpdate, mileage, this.appSettings)
            .then(function () {
            _this.currentVehicle = null;
            _this.getVehicle();
            _this.navigationService.back();
        })
            .catch(function (err) {
            _this.currentVehicle = null;
            _this.getVehicle();
        });
    };
    BigAlModule.prototype.postCustomerErrorReporting = function (data) {
        var _this = this;
        this.spinnerService.show();
        this.apiService.postAsync("feedback", data, this.appSettings)
            .then(function () {
            _this.spinnerService.hide();
            _this.navigationService.forward("more", null);
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postDriverJournalMonthlyLog = function (log) {
        var _this = this;
        log.Month = log.Month + 1;
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = this.currentJournalUser.PersonId;
            }
        }
        if (!log.IsExtraCarSelected) {
            log.BusinessKmTotalExtraCar = 0;
            log.TotalKmExtraCar = 0;
        }
        this.spinnerService.show();
        this.apiService.postAsync("DriverJournal/LogMonth", log, this.appSettings)
            .then(function () {
            _this.spinnerService.hide();
            _this.currentDriverJournalMonthly = null;
            var isBusinessUser = _this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser; });
            log.Month = log.Month - 1;
            _this.businessTripService.resetBusinessTripKmCountForMonth(log);
            if (isBusinessUser) {
                _this.getDriverJournalMonthlyForDriver();
                _this.navigationService.forward("dashboard/driver-journal", null).then(function () { return _this.navigationService.openModal('update-dj-success'); });
            }
            else {
                _this.getDriverJournalMonthly();
                _this.navigationService.forward("journal/driver-journal-monthly", null).then(function () { return _this.navigationService.openModal('update-dj-success'); });
            }
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.putDriverJournalDailyLog = function (log) {
        var _this = this;
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        var promise = new Promise(function (resolve, reject) {
            // log.Date = log.Date + "T00:00:00Z";
            log.IsCreate = false;
            _this.apiService.putAsync("DriverJournal/LogDaily/" + log.Trip.LogDailyId, log, _this.appSettings)
                .then(function (response) {
                _this.spinnerService.hide();
                resolve(response);
            })
                .catch(function (err) {
                _this.spinnerService.hide();
                _this.businessTripService.resetCurrentBusinessTrip();
                console.log("Failed to save - probarly no connection");
                reject(err);
            });
        });
        return promise;
    };
    BigAlModule.prototype.postDriverJournalDailyLog = function (log, isFinalSave) {
        var _this = this;
        if (isFinalSave === void 0) { isFinalSave = true; }
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        var promise = new Promise(function (resolve, reject) {
            if (log.IsCreate) {
                // log.Date = log.Date + "T00:00:00Z";
                // The date has been formatted
                if (log.Date && log.Date.hasOwnProperty("includes")) {
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }
                else {
                    log.Date = new Date(log.Date).toLocaleDateString('sv');
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }
                _this.apiService.postAsync("DriverJournal/LogDaily", log, _this.appSettings)
                    .then(function (response) {
                    // Web manual trip
                    if (!_this.appSettings.IsApp) {
                        _this.spinnerService.hide();
                        _this.currentDriverJournalDaily = null;
                        _this.getDriverJournalDaily();
                        _this.businessTripService.businessTripUpdate(log);
                        _this.businessTripService.resetCurrentBusinessTrip();
                        _this.navigationService.forward("journal/driver-journal-daily", null);
                    }
                    else {
                        // App manual
                        if (isFinalSave) {
                            _this.spinnerService.hide();
                            _this.currentDriverJournalDaily = null;
                            _this.getDriverJournalDaily();
                            // Work around for iOS
                            if (_this.appSettings.IsApp) {
                                _this.navigationService.forward("cars", null);
                                setTimeout(function () {
                                    _this.navigationService.forward("journal/driver-journal-daily", null);
                                }, 100);
                            }
                            else {
                                _this.navigationService.forward("journal/driver-journal-daily", null);
                            }
                        }
                        // else App auto
                    }
                    resolve(response);
                })
                    .catch(function (err) {
                    _this.spinnerService.hide();
                    _this.businessTripService.resetCurrentBusinessTrip();
                    reject(err);
                });
            }
            else {
                // The date has been formatted
                if (log.Date && log.Date.hasOwnProperty("includes")) {
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }
                else {
                    log.Date = new Date(log.Date).toLocaleDateString('sv');
                    if (!log.Date.includes("T00:00:00Z")) {
                        log.Date = log.Date + "T00:00:00Z";
                    }
                }
                _this.apiService.putAsync("DriverJournal/EditDailyBusinessTrip/" + log.TripId + "/" + log.DataSource, log, _this.appSettings)
                    .then(function () {
                    _this.spinnerService.hide();
                    _this.currentDriverJournalDaily = null;
                    _this.getDriverJournalDaily();
                    _this.businessTripService.businessTripUpdate(log);
                    _this.businessTripService.resetCurrentBusinessTrip();
                    // Work around for iOS
                    if (_this.appSettings.IsApp) {
                        _this.navigationService.forward("journal/driver-journal-monthly", null);
                        setTimeout(function () {
                            _this.navigationService.forward("journal/driver-journal-daily", null);
                        }, 100);
                    }
                    else {
                        _this.navigationService.forward("journal/driver-journal-daily", null);
                    }
                    resolve(true);
                })
                    .catch(function (err) {
                    _this.businessTripService.resetCurrentBusinessTrip();
                    _this.spinnerService.hide();
                    // this.navigationService.forward("journal/driver-journal-daily", null);
                    reject(err);
                });
            }
        });
        return promise;
    };
    BigAlModule.prototype.postDriverJournalDailyLogPrivate = function (log) {
        return this.postDriverJournalDailyLog(log);
    };
    BigAlModule.prototype.postDriverJournalDailyLogDelete = function (logDelete) {
        var _this = this;
        this.spinnerService.show();
        this.apiService.postAsync("DriverJournal/DeleteDailyBusinessTrip", logDelete, this.appSettings)
            .then(function () {
            _this.currentDriverJournalDaily = null;
            _this.getDriverJournalDaily();
            _this.businessTripService.businessTripDelete(logDelete);
            _this.businessTripService.resetCurrentBusinessTrip();
            _this.spinnerService.hide();
            // Work around for iOS
            if (_this.appSettings.IsApp) {
                _this.navigationService.forward("journal/driver-journal-monthly", null);
                setTimeout(function () {
                    _this.navigationService.forward("journal/driver-journal-daily", null);
                }, 100);
            }
        })
            .catch(function (err) {
            _this.businessTripService.resetCurrentBusinessTrip();
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postRecordTrip = function () {
        this.doThenWithSuccessAndForward("RecordTripComponent", 'journal/driver-journal-daily');
        this.apiService.addSuccess(new UserActionSuccess("RecordTripComponent"));
        this.navigationService.forward('journal/driver-journal-daily', null);
    };
    BigAlModule.prototype.postCongestionTaxDailyLog = function (log) {
        var _this = this;
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        // if it is a business user that is updating journals you will not get vehicle id or contract id 
        // from currentContract or currentVehicle so you only update them if it is not a business user
        var isBusinessUser = this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser; });
        if (isBusinessUser && !this.currentJournalUserContract) {
            this.addCall(Constants.Event_CurrentJournalUserContracts);
        }
        this.spinnerService.show();
        if (log.IsCreate) {
            this.apiService.postAsync("CTax/LogDaily", log, this.appSettings)
                .then(function () {
                _this.congestionTaxStore.reset();
                if (!isBusinessUser) {
                    _this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                    _this.navigationService.forward("journal/congestion-tax-daily", null);
                }
                else {
                    _this.congestionTaxStore.getMonthlyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                }
                _this.spinnerService.hide();
            })
                .catch(function (err) {
                _this.congestionTaxStore.reset();
                if (!isBusinessUser) {
                    _this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                }
                else {
                    _this.congestionTaxStore.getMonthlyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                }
                _this.spinnerService.hide();
            });
        }
        else {
            this.apiService.postAsync("CTax/EditDaily", log, this.appSettings)
                .then(function () {
                _this.congestionTaxStore.reset();
                if (!isBusinessUser) {
                    _this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                }
                else {
                    _this.congestionTaxStore.getMonthlyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                }
                _this.spinnerService.hide();
                if (!isBusinessUser) {
                    _this.navigationService.forward("journal/congestion-tax-daily", null);
                }
            })
                .catch(function (err) {
                _this.congestionTaxStore.reset();
                if (!isBusinessUser) {
                    _this.congestionTaxStore.getMonthlyCTax(log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTax(log.ContractId, true);
                }
                else {
                    _this.congestionTaxStore.getMonthlyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                    _this.congestionTaxStore.getDailyCTaxForDriver(_this.currentJournalUser.PersonId, log.ContractId, true);
                }
                _this.spinnerService.hide();
            });
        }
    };
    BigAlModule.prototype.postCongestionTaxMonthlyLog = function (log) {
        if (!this.appUserStore.appUser.Roles.includes(UserRoleType.Driver)) {
            if (this.getStorageValueForIsInTakeOverMode()) {
                log.PersonId = +this.currentFleetUserTakeOverInfo.Driver.DriverId;
            }
            else {
                log.PersonId = +this.currentJournalUser.PersonId;
            }
        }
        // either Drivers or BusinessUsers can get Daily History. A BusinessUser get it for a driver
        // so you shall use the contract id in the currentJournalUser in those cases
        var isBusinessUser = this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser; });
        var contract = null;
        if (isBusinessUser && this.currentJournalUserContract != null) {
            contract = this.currentJournalUserContract;
        }
        if (isBusinessUser && contract === null) {
            return;
        }
        else {
            var contractId = isBusinessUser ? contract.ContractId.toString() : this.contractStore.getVehicleContract().Id;
            if (log.IsEdit) {
                this.congestionTaxStore.putMonthlyCTax(contractId, log);
            }
            else {
                this.congestionTaxStore.postMonthlyCTax(contractId, log);
            }
        }
    };
    BigAlModule.prototype.postBlockCard = function (card, cardsAndTagsService) {
        var _this = this;
        this.spinnerService.show();
        this.apiService.postAsync("FuelCard2/Block", card, this.appSettings)
            .then(function () {
            cardsAndTagsService.reloadCardOrTagData();
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postCardOrder = function (companyId, cardsAndTagsService) {
        var _this = this;
        this.spinnerService.show();
        var contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        var fuelCardOrder = new FuelCardOrder([companyId], contractId);
        this.apiService.postAsync("FuelCard/Order", fuelCardOrder, this.appSettings)
            .then(function () {
            cardsAndTagsService.reloadCardOrTagData();
            // this.spinnerService.hide();
            _this.apiService.addSuccess(new UserActionSuccess("FuelCardOrderComponent"));
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postCardPinCode = function (card, cardsAndTagsService) {
        var _this = this;
        this.spinnerService.show();
        this.apiService.postAsync("FuelCard2/PincodeOrder", card, this.appSettings)
            .then(function () {
            // this.spinnerService.hide();
            _this.apiService.addSuccess(new UserActionSuccess("FuelCardPincodeComponent"));
            cardsAndTagsService.reloadCardOrTagData();
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postContactUsEmail = function (contactUs) {
        this.getContracts();
        this.apiService.postAsync("contactus/SendEmail/" + this.currentVehicleContract.Id, contactUs, this.appSettings)
            .then(function (data) {
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postInsuranceClaimFinish = function (values) {
        var _this = this;
        this.getContracts();
        var array = values["formArray"];
        var accidentDate = (array[1].AccidentDate === "") ? null : array[1].AccidentDate;
        accidentDate = this.ChangeTimezoneToUTC(accidentDate);
        var cpr = (array[0].CprNumber === undefined) ? null : array[0].CprNumber;
        var claim = new InsuranceClaimFinish(array[0].RegistrationNumber, array[0].Driver, array[0].CompanyName, array[0].CompanyAddress, array[0].Email, array[0].Phone, array[1].AccidentLocation, array[1].AccidentTime, array[1].PoliceStationJounalNumber, array[1].VehicleDamageDescription, array[1].DescriptionOfAccident, array[1].GiveAway, array[1].LaneShift, array[1].Reversing, array[1].LeftTurn, array[1].RigthTurn, array[1].WhoIsToBlame, array[2].CounterPartName, array[2].CounterPartAddress, array[2].CounterPartPhone, array[2].CounterPartRegistrationNumber, array[2].CounterPartInsuranceCompany, array[2].CounterPartCarDamageDescription, array[2].CounterPartMultiple, array[3].WitnessName, array[3].WitnessAddress, array[3].WitnessPhone, array[3].WitnessLocation, array[4].InjuredPersonsNameAndAddress, array[4].InjuredPersonsCprNumbers, array[4].InjuredPersonsPhoneNumbers, array[4].InjuredPersonsInjuryDescription, array[5].MiscDamagesDescription, this.uploadedFiles, cpr, accidentDate, array[1].AccidentDuringWorkHours, array[1].DayLight, array[1].DrivesLicence, array[1].PoliceReport, array[1].DrugTest, array[4].DriverInjury, array[4].PassengerInjury, array[4].CounterPartInjury);
        this.apiService.postAsync("InsuranceClaimFinish/" + this.currentVehicleContract.Id, claim, this.appSettings)
            .then(function () {
            _this.uploadedFiles = [];
            _this.doThenWithSuccessAndNavBack('InsuranceClaimFinish');
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postProfileForm = function (driverProfile) {
        var _this = this;
        driverProfile.IssuerId = this.appUserStore.appUser.AuthenticationUserId;
        driverProfile.DriverId = this.currentDriver.DriverId;
        driverProfile = this.mergePrefixValues(driverProfile);
        // this.apiService.putAsync("drivers/profile/" + driverProfile.DriverId, driverProfile, this.appSettings)
        this.apiService.putAsync("drivers/profile/" + driverProfile.DriverId, driverProfile, this.appSettings)
            .then(function (reponse) {
            if (!reponse) {
                _this.apiService.addError(new HttpErrorResponse({ error: "Failed to update user" }));
            }
            else {
                _this.currentDriver = null;
                _this.driverStore.reset();
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver; }) || _this.getStorageValueForIsInTakeOverMode()) {
                    _this.driverStore.getDriver();
                    _this.getDriver();
                }
                if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.FleetAdmin; })) {
                    _this.tableDataService.setData(driverProfile.Email);
                }
                _this.doThenWithSuccessAndNavBack("EditDriverProfile");
            }
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postFleetUserProfileForm = function (fleetUser) {
        var _this = this;
        fleetUser.FleetManagerId = this.currentFleetUser.FleetManagerId;
        this.apiService.putAsync("fleetUser", fleetUser, this.appSettings)
            .then(function () {
            _this.currentFleetUser = null;
            if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; })) {
                _this.getFleetUser();
            }
            if (_this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.FleetAdmin; })) {
                _this.tableDataService.setData(fleetUser.Email);
            }
            _this.doThenWithSuccessAndNavBack("EditFleetUserProfile");
            return true;
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postFleetDepartmentChange = function (fleetDepartmentChange) {
        var _this = this;
        if (!this.currentDriver) {
            console.warn("No driver.");
            return;
        }
        var driver = this.currentDriver;
        driver.IssuerId = this.appUserStore.appUser.AuthenticationUserId;
        driver.Department = fleetDepartmentChange.NewDepartment;
        this.apiService.putAsync("drivers/profile/" + driver.DriverId, driver, this.appSettings)
            .then(function () {
            _this.doThenWithSuccessAndForward('FleetDepartmentChange', "cars");
            _this.currentDriver = null;
            _this.getDriver();
        })
            .catch(function (err) {
            _this.currentDriver = null;
            _this.getDriver();
            _this.navigationService.forward("cars", null);
        });
    };
    BigAlModule.prototype.postOrderRedcard = function (cardOrder) {
        cardOrder.Colour = CardColour.Red;
        return this.postOrderCard(cardOrder);
    };
    BigAlModule.prototype.postOrderGreencard = function (cardOrder) {
        cardOrder.Colour = CardColour.Green;
        return this.postOrderCard(cardOrder);
    };
    BigAlModule.prototype.postOrderCard = function (cardOrder) {
        var _this = this;
        var contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderCard/" + contractId, cardOrder, this.appSettings)
            .then(function () {
            if (cardOrder.Colour === CardColour.Green) {
                _this.doThenWithSuccessAndNavBack("OrderGreencardComponent");
            }
            else if (cardOrder.Colour === CardColour.Red) {
                _this.doThenWithSuccessAndNavBack("OrderRedcardComponent");
            }
            return true;
        }).catch(function (err) {
        });
    };
    BigAlModule.prototype.postCertificateOrder = function (certificateOrder) {
        var _this = this;
        var contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderCertificate/" + contractId, certificateOrder, this.appSettings)
            .then(function () {
            _this.doThenWithSuccessAndNavBack("OrderCertificateComponent");
            return true;
        }).catch(function (err) {
        });
    };
    BigAlModule.prototype.postOrderDocuments = function (orderDocuments) {
        var _this = this;
        var contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("GoingAbroad/PostOrderDocuments/" + contractId, orderDocuments, this.appSettings)
            .then(function () {
            _this.doThenWithSuccessAndNavBack("OrderDocumentsComponent");
            return true;
        }).catch(function (err) {
        });
    };
    BigAlModule.prototype.postWorkshopBooking = function (workshopBooking) {
        var _this = this;
        var path = "";
        if (!(workshopBooking instanceof WorkshopBooking)) {
            workshopBooking = this.mapStepperForm(workshopBooking, WorkshopBooking);
        }
        if (this.currentWorkshopSearch.ServiceLinkOnly) {
            path = "ServiceLinkBooking";
        }
        else {
            path = "WorkshopBooking";
        }
        workshopBooking.ContractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        workshopBooking.Date = this.ChangeTimezoneToUTC(workshopBooking.Date);
        this.apiService.postAsync(path, workshopBooking, this.appSettings)
            .then(function () {
            _this.doThenWithSuccessAndForward('WorkshopBookComponent', "services");
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postContactUsForm = function (contactUsEmail) {
        var contractId = (this.currentVehicleContract === null) ? this.currentOtherContract.Id : this.currentVehicleContract.Id;
        this.apiService.postAsync("ContactUs/SendEmail/" + contractId, contactUsEmail, this.appSettings)
            .then(function () { return true; })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postMarketingConsent = function (consent) {
        var _this = this;
        this.apiService.postAsync("MarketingConsent", consent, this.appSettings)
            .then(function () {
            _this.currentMarketingConsent = null;
            _this.getMarketConsent();
            return true;
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postTranslations = function (translations) {
        var _this = this;
        var translationsToSave = translations;
        this.apiService.postAsync("languages/translations/", translationsToSave, this.appSettings)
            .then(function (data) {
            _this.translateService.updateCompanyTranslations(translationsToSave, _this.appSettings.CompanyId);
            if (_this.previousModal !== null) {
                _this.navigationService.openModal(_this.previousModal);
            }
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postChangeLanguage = function () {
        var _this = this;
        this.apiService.postAsync("languages/change/" + this.appSettings.LanguageCode, null, this.appSettings)
            .then(function (data) {
            _this.currentFeed = null;
            _this.getFeed();
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postEditLoginForm = function (editLoginForm) {
        var _this = this;
        var editLogin = new EditLogin(editLoginForm.Username, editLoginForm.CurrentPassword, editLoginForm.Password);
        this.apiService.patchAsync("users/login/edit", editLogin, this.appSettings)
            .then(function (data) {
            _this.appUserStore.appUser.Username = editLogin.Username;
            _this.doThenWithSuccessAndNavBack("EditLoginForm");
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.redirectBasedOnRole = function () {
        var returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        if (this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; }) && this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver; })) {
            this.navigationService.forward(returnUrl, null);
        }
        if (this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.DevUser_Driver; }) && !this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; }) ||
            this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.SystemAdminDriver; })) {
            this.navigationService.forward("cars", null);
        }
        if (this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin; }) && !this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.Driver; }) ||
            this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.SystemAdminFleetAdmin; })) {
            this.navigationService.forward("fleet/overview", null);
        }
        if (this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin; })) {
            this.navigationService.forward("dashboard", null);
        }
        this.dataStream.next('userLogin');
    };
    BigAlModule.prototype.postChangePasswordForm = function (changePwdForm) {
        var _this = this;
        var changePwd = new ChangePasswordForm('', changePwdForm.Password, '');
        this.apiService.patchAsync("users/login/changepassword", changePwd, this.appSettings)
            .then(function (data) {
            _this.doThenWithSuccessAndNavBack("ChangePasswordForm");
            _this.setCurrentUser(data);
            _this.redirectBasedOnRole();
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postFleetIfrsReportComponent = function (ifrsReportOrder) {
        var _this = this;
        ifrsReportOrder.BusinessPartnerIds = [ifrsReportOrder.BusinessPartnerId];
        ifrsReportOrder.CutOffDate = this.ChangeTimezoneToUTC(ifrsReportOrder.CutOffDate);
        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ifrsReport", ifrsReportOrder, this.appSettings)
            .then(function (data) {
            _this.doThenWithSuccessAndForward("FleetIfrsReportComponent", "fleet/reports");
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postFleetIfrsEventReportComponent = function (ifrsReportOrder) {
        var _this = this;
        ifrsReportOrder.BusinessPartnerIds = [ifrsReportOrder.BusinessPartnerId];
        ifrsReportOrder.StartDate = this.ChangeTimezoneToUTC(ifrsReportOrder.StartDate);
        ifrsReportOrder.EndDate = this.ChangeTimezoneToUTC(ifrsReportOrder.EndDate);
        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ifrsReport", ifrsReportOrder, this.appSettings)
            .then(function (data) {
            _this.doThenWithSuccessAndForward("FleetIfrsEventReportComponent", "fleet/reports");
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postFleetReportOrderComponent = function (data) {
        var _this = this;
        var formArray = data.formArray;
        var firstStep = formArray[0];
        var secondStep = formArray[1];
        var businessPartners = this.currentFleetUserBusinessPartners;
        var selectedBusinessPartners = [];
        if (secondStep["ReportFor"] === true && this.appUserStore.appUser.Roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.SystemAdminFleetAdmin; })) {
            selectedBusinessPartners.push(businessPartners.find(function (bp) { return bp.ConcernId === "0"; }).Id);
        }
        else {
            businessPartners.forEach(function (bp) {
                if (secondStep.hasOwnProperty(bp.Id) && secondStep[bp.Id] === true) {
                    selectedBusinessPartners.push(bp.Id);
                }
            });
        }
        var reportOrder = this.reportService.getOrderValue();
        reportOrder.BusinessPartnerIds = selectedBusinessPartners;
        reportOrder.OrderAll = secondStep['OrderAll'];
        reportOrder.FleetAdminReportWithVariableTypes.forEach(function (report) {
            report.ReportVariableTypes.forEach(function (variable) {
                var reportVarId = report.Id + "_" + variable.Id;
                if (firstStep.hasOwnProperty(reportVarId)) {
                    if (variable.IsDate) {
                        variable.ContentTypedFromUser = _this.ChangeTimezoneToUTC(firstStep[reportVarId]).toDateString();
                    }
                    else {
                        variable.ContentTypedFromUser = firstStep[reportVarId];
                    }
                }
            });
            if (firstStep.hasOwnProperty(report.Id + "_reportFormat")) {
                report.SelectedFileFormat = firstStep[report.Id + "_reportFormat"];
            }
        });
        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/SubmitReportOrder", reportOrder, this.appSettings)
            .then(function (data) {
            _this.doThenWithSuccessAndForward("FleetReportOrderComponent", "fleet/reports");
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postComponents = function (components) {
        var _this = this;
        this.apiService.postAsync("components/" + this.appSettings.Id, components, this.appSettings)
            .then(function (data) {
            _this.getFeatureComponentConfiguration();
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.postChangeDriver = function (values) {
        var _this = this;
        var changeDriver = this.mapStepperForm(values, ChangeDriver);
        var lastStepForm = values["formArray"][2];
        if (changeDriver.NotesOnFormerUsersFuelCards === "") {
            changeDriver.NotesOnFormerUsersFuelCards = "-";
        }
        Object.keys(lastStepForm).forEach(function (element) {
            var fuelCompany = _this.currentChangeDriverFuelCompanies.find(function (f) {
                return element.indexOf(f.Name) !== -1
                    && lastStepForm[element] === true
                    && element.indexOf("_fuelProvider") !== -1;
            });
            if (fuelCompany) {
                changeDriver.ListOfFuelCardsToOrder.push(fuelCompany);
            }
        });
        changeDriver.KmPerYear = this.currentVehicleContract.KilometersPerYear.toString();
        changeDriver.ContractId = this.currentVehicleContract.Id;
        this.spinnerService.show();
        this.apiService.postAsync("FleetUser/ChangeDriver", changeDriver, this.appSettings)
            .then(function (data) {
            _this.revertTakeOverDriver().then(function () {
                _this.doThenWithSuccessAndForward('FleetChangeDriver', "fleet/overview");
            });
            return true;
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.postRefreshToken = function () {
        this.addCall("refreshToken");
    };
    // public postReportApiError(reportError: ReportError) {
    // }
    // public postGlassBooking(value) {
    // }
    BigAlModule.prototype.downloadGloveboxDocument = function (id) {
        var _this = this;
        this.spinnerService.show();
        this.apiService.getFile("file/Glovebox/Download/" + id, this.appSettings)
            .then(function (data) {
            _this.spinnerService.hide();
        })
            .catch(function (err) {
            _this.spinnerService.hide();
        });
    };
    BigAlModule.prototype.getContacts = function () {
        if (this.currentContacts === null) {
            this.addCall(Constants.Event_CurrentContacts);
        }
    };
    BigAlModule.prototype.getFeed = function () {
        if (this.currentFeed === null) {
            this.addCall("currentFeed");
        }
    };
    BigAlModule.prototype.getInsurance = function () {
        if (this.currentInsurance === null) {
            this.getContracts();
            this.addCall(Constants.Event_CurrentInsurance);
        }
    };
    BigAlModule.prototype.executeLogout = function (redirect) {
        var _this = this;
        if (redirect === void 0) { redirect = true; }
        this.nextCallSubscription.unsubscribe();
        return this.apiService.postAsync("Users/Logout", null, this.appSettings)
            .then(function () {
            _this.isUserLoggedIn = false;
            _this.fleetUserService.setIsInTakeOverMode(false);
            _this.showAdminButtons = false;
            _this.clearData(true);
            _this.resetStores();
            _this.setStorageData(AppUser.GetInitialUser(), false);
            _this.appUserStore.appUser = AppUser.GetInitialUser();
            _this.calls();
            localStorage.removeItem(_this.RefreshTokenKey);
            localStorage.setItem(_this.UserRoleKey, '0');
            /*
            const pushToken = localStorage.getItem("pushToken");
            if (pushToken) {
              this.apiService.postAsync(`push/unsubscribe`, { Token: pushToken }, this.appSettings);
              localStorage.removeItem("pushToken");
            }*/
            _this.firebaseService.removeFirebasePush(_this.appSettings);
            _this.dataStream.next("logout");
        })
            .then(function () {
            if (_this.appSettings.IsApp) {
                if (redirect === true) {
                    _this.navigationService.forward("login", null);
                }
                _this.init();
            }
            else {
                if (redirect === true) {
                    _this.navigationService.reload();
                }
            }
        })
            .catch(function (err) {
        });
    };
    BigAlModule.prototype.logout = function () {
        this.executeLogout(true);
    };
    BigAlModule.prototype.logoutAndClearSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.executeLogout().then(function () {
                    _this.appSettings = null;
                    _this.whoAmI();
                });
                return [2 /*return*/];
            });
        });
    };
    BigAlModule.prototype.validateToken = function () {
        var _this = this;
        var settings = this.appSettings;
        this.spinnerService.show();
        return this.apiService.getAsync("token/validateToken", settings).then(function (data) {
            if (data.ValidToken) {
                _this.spinnerService.hide();
                return _this.getAuthorizationHeader();
            }
            else {
                var refreshToken = _this.getRefreshKey();
                return _this.refreshToken(refreshToken).then(function () {
                    _this.spinnerService.hide();
                    return _this.getAuthorizationHeader();
                });
            }
        }, function (err) {
            _this.spinnerService.hide();
            throw err;
        });
    };
    BigAlModule.prototype.refreshToken = function (refreshToken) {
        var _this = this;
        return this.apiService.getAsync("tokens/refresh/" + refreshToken, this.appSettings)
            .then(function (data) {
            _this.setCurrentUser(data);
            _this.next();
            _this.dataStream.next("refreshToken");
        })
            .catch(function (err) {
            _this.logout();
            _this.next();
        });
    };
    BigAlModule.prototype.calls = function () {
        var _this = this;
        this.nextCallSubscription = this.nextCallStream.asObservable().subscribe(function (next) {
            switch (next) {
                case "refreshToken":
                    var token = _this.getRefreshKey();
                    if (token) {
                        var settings = _this.appSettings;
                        if (!settings) {
                            _this.next();
                        }
                        else {
                            _this.refreshToken(token);
                        }
                    }
                    else {
                        _this.next();
                    }
                    break;
                case Constants.Event_CurrentContracts:
                    _this.apiService.getAsync("contract", _this.appSettings)
                        .then(function (data) {
                        // make distinct - issue on miles because sends dupicate contracts
                        var distinctContracts = [];
                        data.forEach(function (d) {
                            if (!distinctContracts.some(function (c) { return c.Id === d.Id; })) {
                                distinctContracts.push(d);
                            }
                        });
                        _this.currentVehicleContracts = distinctContracts.filter(function (c) { return isNaN(Number(c.VehicleId)) || c.VehicleId !== "0"; });
                        _this.currentOtherContracts = distinctContracts.filter(function (c) { return c.VehicleId === "0"; });
                        _this.currentVehicleContract = _this.currentVehicleContracts !== null ? _this.currentVehicleContracts[0] : null;
                        _this.currentOtherContract = _this.currentOtherContracts !== null ? _this.currentOtherContracts[0] : null;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentContracts);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentContracts, error: err });
                    });
                    break;
                case Constants.Event_CurrentVehicles:
                    _this.apiService.getAsync(ApiUrl.Vehicle, _this.appSettings)
                        .then(function (data) {
                        _this.currentVehicles = data;
                    })
                        .catch(function (err) {
                        _this.currentVehicles = null;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentVehicles);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicles, error: err });
                    });
                    break;
                case Constants.Event_CurrentVehicle:
                    if (_this.currentVehicleContract.VehicleId) {
                        _this.apiService.getAsync("vehicle/" + _this.currentVehicleContract.Id + "/" + _this.currentVehicleContract.VehicleId, _this.appSettings)
                            .then(function (data) {
                            _this.currentVehicle = data;
                        })
                            .then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentVehicle);
                        })
                            .catch(function (err) {
                            _this.next();
                            _this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicle, error: err });
                        });
                    }
                    else if (_this.currentVehicleContract.ExternalVehicleId != '0') {
                        _this.apiService.getAsync("externalvehicle/" + _this.currentVehicleContract.ExternalVehicleId, _this.appSettings)
                            .then(function (data) {
                            _this.currentVehicle = data;
                        })
                            .then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentVehicle);
                        })
                            .catch(function (err) {
                            _this.next();
                            _this.dataStreamFailed.next({ event: Constants.Event_CurrentVehicle, error: err });
                        });
                    }
                    break;
                case Constants.Event_CurrentBusinessPartner:
                    _this.apiService.getAsync("businesspartner/current", _this.appSettings)
                        .then(function (data) {
                        _this.currentBusinessPartner = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentBusinessPartner);
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentFleetUserBusinessPartners:
                    _this.apiService.getAsync("businesspartners/status/active", _this.appSettings)
                        .then(function (data) {
                        _this.currentFleetUserBusinessPartners = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFleetUserBusinessPartners);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUserBusinessPartners, error: err });
                    });
                    break;
                case Constants.Event_CurrentBusinessPartnerIdAndReference:
                    _this.apiService.getAsync("businesspartners/" + _this.currentBusinessPartner.Id + "/getIdAndReference", _this.appSettings)
                        .then(function (data) {
                        _this.currentBusinessPartnerIdAndReference = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentBusinessPartnerIdAndReference);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentBusinessPartnerIdAndReference, error: err });
                    });
                    break;
                case Constants.Event_CurrentDriver:
                    _this.apiService.getAsync("driver", _this.appSettings)
                        .then(function (data) {
                        _this.currentDriver = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentDriver);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentDriver, error: err });
                    });
                    break;
                case Constants.Event_CurrentContacts:
                    _this.apiService.getAsync("ContactInformation", _this.appSettings)
                        .then(function (data) {
                        _this.currentContacts = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentContacts);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentContacts, error: err });
                    });
                    break;
                case "currentFeed":
                    _this.apiService.getAsync("Feed", _this.appSettings)
                        .then(function (data) {
                        var feed = new Feed();
                        feed.Items = data;
                        _this.currentFeed = feed;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentFeed");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentTireCenter:
                    _this.apiService.getAsync("TireCenter/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        debugger;
                        _this.currentTireCenter = data;
                    }).then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentTireCenter);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentTireCenter, error: err });
                    });
                    break;
                case Constants.Event_CurrentTireBranch:
                    _this.apiService.getAsync("tire/branch/contract/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentTireBranch = data[0];
                    }).then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentTireBranch);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBranch, error: err });
                    });
                    break;
                case Constants.Event_CurrentTireBookings:
                    if (_this.currentTireBranch) {
                        _this.apiService.getAsync("tire/booking/" + _this.currentTireBranch.CenterType + "/" + _this.currentVehicleContract.Id, _this.appSettings)
                            .then(function (data) {
                            _this.currentTireBookings = data;
                        }).then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentTireBookings);
                        })
                            .catch(function (err) {
                            _this.next();
                            _this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBookings, error: err });
                        });
                    }
                    break;
                case "currentTireCenterAvailableTimes":
                    _this.apiService.getAsync("TireCenter/appointments/availabletime/" + _this.currentVehicleContract.Id + "/" + _this.DatePipe.transform(_this.currentBookingFormSelectedDate, "yyyy-MM-dd"), _this.appSettings)
                        .then(function (data) {
                        if (!(data instanceof HttpErrorResponse))
                            _this.currentAvailableTimes = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentAvailableTimes");
                    });
                    break;
                case Constants.Event_CurrentTireBranchTimes:
                    var serviceTypeOriginId = _this.getCurrentBookingFormSelectedServiceType().Id;
                    var fromDate = new Date(_this.getCurrentBookingFormSelectedDate());
                    var addedDays = 1;
                    if (fromDate.getDay() === 5) {
                        addedDays = 3;
                    }
                    if (fromDate.getDay() === 6) {
                        addedDays = 3;
                    }
                    if (fromDate.getDay() === 0) {
                        addedDays = 2;
                    }
                    var toDate = new Date(fromDate).setDate(fromDate.getDate() + addedDays);
                    var fromFormatted = _this.DatePipe.transform(fromDate, "yyyy-MM-dd");
                    var toFormatted = _this.DatePipe.transform(toDate, "yyyy-MM-dd");
                    _this.apiService.getAsync("tire/timeslot/" + _this.currentTireBranch.CenterType + "/" + _this.currentVehicleContract.Id + "/" + _this.currentTireBranch.OriginId + "?serviceTypeOriginId=" + serviceTypeOriginId + "&from=" + fromFormatted + "&to=" + toFormatted, _this.appSettings)
                        .then(function (data) {
                        if (!(data instanceof HttpErrorResponse))
                            _this.currentTireBranchTimeslots = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentTireBranchTimes);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentTireBranchTimes, error: err });
                    });
                    break;
                case Constants.Event_CurrentWorkshops:
                    // this needs to be here to map the object in the right way for the backend
                    var workshopSearch = _this.currentWorkshopSearch;
                    var parameters = _this.makeQueryParameters(workshopSearch);
                    if (workshopSearch.PostalCode !== null) {
                        workshopSearch.Latitude = 0;
                        workshopSearch.Longitude = 0;
                    }
                    if (!_this.currentVehicleContract) {
                        _this.next();
                        break;
                    }
                    if (workshopSearch.Category !== null) {
                        _this.apiService.getAsync("FindNearest/Partners/" + _this.currentVehicleContract.Id + "?" + parameters, _this.appSettings)
                            .then(function (data) {
                            _this.currentWorkshops = data;
                        })
                            .then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentWorkshops);
                        })
                            .catch(function (err) {
                            _this.currentWorkshops = [];
                            _this.dataStream.next(Constants.Event_CurrentWorkshops);
                            _this.apiService.addError(err);
                            _this.next();
                        });
                        break;
                    }
                    else {
                        _this.apiService.getAsync("Workshop/" + _this.currentVehicleContract.Id + "?" + parameters, _this.appSettings)
                            .then(function (data) {
                            _this.currentWorkshops = data;
                        })
                            .then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentWorkshops);
                        })
                            .catch(function (err) {
                            _this.dataStream.next(Constants.Event_CurrentWorkshops);
                            _this.currentWorkshops = [];
                            _this.apiService.addError(err);
                            _this.next();
                        });
                        break;
                    }
                case Constants.Event_CurrentFleetUser:
                    _this.apiService.getAsync("FleetUser/current", _this.appSettings)
                        .then(function (data) {
                        _this.currentFleetUser = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFleetUser);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetUser, error: err });
                    });
                    break;
                case "currentAccessibleFleetUsers":
                    _this.apiService.getAsync("users/businessPartner/fleetmanagers", _this.appSettings)
                        .then(function (data) {
                        _this.currentAccessibleFleetUsers = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentAccessibleFleetUsers");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_BusinessUsers:
                    _this.apiService.getAsync("users/businessusers", _this.appSettings)
                        .then(function (data) {
                        _this.currentBusinessUsers = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_BusinessUsers);
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentFleetOverview:
                    _this.apiService.getAsync("/FleetUser/FleetOverview", _this.appSettings)
                        .then(function (data) {
                        _this.currentFleetUserFleetOverview = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFleetOverview);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetOverview, error: err });
                    });
                    break;
                case "currentMarketingConsent":
                    _this.apiService.getAsync("MarketingConsent/State", _this.appSettings)
                        .then(function (data) {
                        _this.currentMarketingConsent = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentMarketingConsent");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentInsurance:
                    if (!_this.currentVehicleContract) {
                        _this.next();
                        break;
                    }
                    _this.apiService.getAsync("Insurance/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentInsurance = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentInsurance);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentInsurance, error: err });
                    });
                    break;
                case Constants.Event_CurrentFuelCards:
                    if (!_this.currentVehicleContract) {
                        _this.next();
                        break;
                    }
                    _this.apiService.getAsync("FuelCard2/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentFuelCards = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFuelCards);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelCards, error: err });
                    });
                    break;
                case Constants.Event_CurrentDriverCards:
                    _this.apiService.getAsync("DriverCard/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentDriverCards = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentDriverCards);
                    })
                        .catch(function (err) {
                        console.warn("Faildet to get DriverCards: ", err);
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentDriverCards, error: err });
                    });
                    break;
                case "currentTollTags":
                    _this.apiService.getAsync("TollTag/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentTollTags = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentTollTags");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case "currentChargingTags":
                    _this.apiService.getAsync("ChargingTag/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentChargingTags = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentChargingTags");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentFuelCompanies:
                    _this.apiService.getAsync("Fueling/FuelProvider/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentFuelCompanies = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFuelCompanies);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelCompanies, error: err });
                    });
                    break;
                case Constants.Event_CurrentFuelSummary:
                    _this.apiService.getAsync("Fueling/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentFuelingSummary = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFuelSummary);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFuelSummary, error: err });
                    });
                    break;
                case "currentContactUsSubjects":
                    _this.apiService.getAsync("ContactUs/GetSubjectsAndCategories/", _this.appSettings)
                        .then(function (data) {
                        _this.currentContactUsSubjects = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentContactUsSubjects");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case Constants.Event_CurrentFleetAvailableReports:
                    _this.apiService.getAsync("FleetUser/ReportsAllData", _this.appSettings)
                        .then(function (data) {
                        _this.currentAvailableReports = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentFleetAvailableReports);
                    })
                        .catch(function (err) {
                        _this.next();
                        _this.dataStreamFailed.next({ event: Constants.Event_CurrentFleetAvailableReports, error: err });
                    });
                    break;
                case "currentPasswordSetCode":
                    _this.apiService.getAsync("password/validateLink/" + _this.currentPasswordResetCode, _this.appSettings)
                        .then(function (data) {
                        _this.currentPasswordSetCode = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentPasswordSetCode");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case "currentChangeDriverFuelCompanies":
                    _this.apiService.getAsync("FleetUser/ChangeDriverFuelCompanies/" + _this.currentVehicleContract.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentChangeDriverFuelCompanies = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentChangeDriverFuelCompanies");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case "currentBusinessDocuments":
                    _this.apiService.getAsync("BusinessDocuments/" + _this.currentBusinessPartner.Id, _this.appSettings)
                        .then(function (data) {
                        _this.currentBusinessDocuments = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentBusinessDocuments");
                    })
                        .catch(function (err) {
                        _this.next();
                    });
                    break;
                case "currentDrivingSettings":
                    _this.apiService.getAsync("DriverJournal/DrivingSettings/", _this.appSettings)
                        .then(function (data) {
                        _this.currentDrivingSettings = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next("currentDrivingSettings");
                    });
                    break;
                case Constants.Event_CurrentJournalUsers:
                    _this.spinnerService.show();
                    _this.apiService.getAsync("users/SearchAll?query=" + _this.currentJournalUserQueryAndType.query + "&parameteridx=" + JournalUserQueryTypes[_this.currentJournalUserQueryAndType.type], _this.appSettings)
                        .then(function (data) {
                        _this.currentJournalUsers = data;
                    })
                        .then(function () {
                        _this.doThen(Constants.Event_CurrentJournalUsers);
                    })
                        .catch(function (err) {
                        _this.doCatch(err);
                    });
                    break;
                case Constants.Event_CurrentJournalUserContracts:
                    var from = _this.DatePipe.transform(_this.ChangeTimezoneToUTC(_this.currentJournalUserContractsTimeSpan.from).toUTCString(), "yyyy-MM-dd");
                    var to = _this.DatePipe.transform(_this.ChangeTimezoneToUTC(_this.currentJournalUserContractsTimeSpan.to).toUTCString(), "yyyy-MM-dd");
                    if (_this.currentJournalUserContracts === null) {
                        _this.apiService.getAsync("contracts/" + _this.currentJournalUser.PersonId + "?from=" + from + "&to=" + to, _this.appSettings)
                            .then(function (data) {
                            data.sort(function (c1, c2) { return new Date(c2.StartDate).getTime() - new Date(c1.StartDate).getTime(); });
                            _this.currentJournalUserContracts = data;
                            if (data.length > 0) {
                                _this.currentJournalUserContract = data[0];
                            }
                        })
                            .then(function () {
                            _this.doThen(Constants.Event_CurrentJournalUserContracts);
                        })
                            .catch(function (err) {
                            _this.doCatch(err);
                        });
                    }
                    break;
                case Constants.Event_currentFuelRules:
                    if (_this.currentFuelRules === null) {
                        _this.apiService.getAsync("FuelProfiles/fuelrules/" + _this.currentVehicleContract.Id, _this.appSettings)
                            .then(function (data) {
                            _this.currentFuelRules = data;
                        })
                            .then(function () {
                            _this.doThen(Constants.Event_currentFuelRules);
                        })
                            .catch(function (err) {
                            _this.doCatch(err);
                        });
                    }
                    break;
                case Constants.Event_CurrentDriverJournalMonthly:
                    _this.spinnerService.show();
                    _this.apiService.getAsync("DriverJournal/MonthHistory/" + _this.currentVehicleContract.Id + "/" + _this.currentDriverJournalMonthlyRules.Year, _this.appSettings)
                        .then(function (data) {
                        if (data.PreviousMonthLoggingAlert.Month) {
                            data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
                        }
                        data.Months.forEach(function (entry) {
                            entry.Month = entry.Month - 1;
                        });
                        _this.currentDriverJournalMonthly = data;
                    })
                        .then(function () {
                        _this.doThen(Constants.Event_CurrentDriverJournalMonthly);
                    })
                        .catch(function (err) {
                        _this.doCatch(err);
                    });
                    break;
                // this call is for business users to get the DJ for a specific driver
                case Constants.Event_CurrentDriverJournalMonthlyForDriver:
                    _this.spinnerService.show();
                    var contractVersion = _this.currentJournalUserContract;
                    if (contractVersion && _this.currentJournalUserContracts && _this.currentJournalUserContracts.length > 0) {
                        contractVersion = _this.currentJournalUserContract;
                    }
                    if (contractVersion === null || _this.currentJournalUser === null) {
                        break;
                    }
                    else {
                        var contractId = contractVersion ? contractVersion.ContractId : _this.currentVehicleContract.Id;
                        _this.apiService.getAsync("DriverJournal/MonthHistory/" + _this.currentJournalUser.PersonId + "/" + contractId + "/" + _this.currentDriverJournalMonthlyRules.Year, _this.appSettings)
                            .then(function (data) {
                            if (data.PreviousMonthLoggingAlert.Month) {
                                data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
                            }
                            data.Months.forEach(function (entry) {
                                entry.Month = entry.Month - 1;
                            });
                            _this.currentDriverJournalMonthly = data;
                        })
                            .then(function () {
                            _this.doThen(Constants.Event_CurrentDriverJournalMonthlyForDriver);
                        })
                            .catch(function (err) {
                            _this.doCatch(err);
                        });
                    }
                    break;
                case Constants.Event_CurrentDriverJournalDaily:
                    // this.spinnerService.show();
                    if (!_this.currentDriverJournalDailyRules) {
                        break;
                    }
                    _this.apiService.getAsync("DriverJournal/DailyHistory/" + _this.currentVehicleContract.Id + "/" + (_this.currentDriverJournalDailyRules.Month + 1) + "/" + _this.currentDriverJournalDailyRules.Year, _this.appSettings)
                        .then(function (data) {
                        _this.currentDriverJournalDaily = data;
                    })
                        .then(function () {
                        _this.next();
                        _this.dataStream.next(Constants.Event_CurrentDriverJournalDaily);
                        // this.spinnerService.hide();
                    })
                        .catch(function (err) {
                        _this.next();
                        // this.spinnerService.hide();
                    });
                    break;
                case Constants.Event_CurrentDriverJournalDailyForDriver:
                    // this.spinnerService.show();
                    if (!_this.currentDriverJournalDailyRules) {
                        break;
                    }
                    var contract = null;
                    if (_this.currentJournalUserContracts != null && _this.currentJournalUserContracts.length > 0) {
                        contract = _this.currentJournalUserContracts[0];
                    }
                    if (contract === null) {
                        _this.currentDriverJournalMonthly = null;
                    }
                    else {
                        var contractId = contract['ContractId'];
                        _this.apiService.getAsync("DriverJournal/DailyHistory/" + _this.currentJournalUser.PersonId + "/" + contractId + "/" + (_this.currentDriverJournalDailyRules.Month + 1) + "/" + _this.currentDriverJournalDailyRules.Year, _this.appSettings)
                            .then(function (data) {
                            _this.currentDriverJournalDaily = data;
                        })
                            .then(function () {
                            _this.next();
                            _this.dataStream.next(Constants.Event_CurrentDriverJournalDailyForDriver);
                            // this.spinnerService.hide();
                        })
                            .catch(function (err) {
                            _this.next();
                            // this.spinnerService.hide();
                        });
                    }
                    break;
                case null:
                    // If the command is null, then we have reached the end of the call queue.
                    _this.spinnerService.hide();
                    break;
                default:
                    _this.spinnerService.hide();
                    console.warn("No api call found for event: ", next);
                    break;
            }
        });
    };
    BigAlModule.prototype.doCatch = function (err) {
        console.warn(err);
        this.apiService.addError(err);
        this.spinnerService.hide();
        this.next();
    };
    BigAlModule.prototype.doThen = function (event) {
        this.dataStream.next(event);
        this.next();
        this.spinnerService.hide();
    };
    BigAlModule.prototype.doThenWithSuccessAndForward = function (successName, path) {
        this.apiService.addSuccess(new UserActionSuccess(successName));
        this.spinnerService.hide();
        this.navigationService.forward(path, null);
    };
    BigAlModule.prototype.doThenWithSuccessAndNavBack = function (successName) {
        this.apiService.addSuccess(new UserActionSuccess(successName));
        this.spinnerService.hide();
        this.navigationService.back();
    };
    BigAlModule.prototype.addCall = function (name) {
        if (!this.callsQueue.some(function (c) { return c === name; })) {
            this.callsQueue.push(name);
            if (this.nextCallStream.value == null) {
                this.nextCallStream.next(name);
            }
        }
    };
    BigAlModule.prototype.isEventEnqueued = function (typeOfEvent) {
        return this.callsQueue.some(function (c) { return c === typeOfEvent; });
    };
    BigAlModule.prototype.next = function () {
        this.callsQueue.shift();
        if (this.callsQueue.length > 0) {
            this.nextCallStream.next(this.callsQueue[0]);
        }
        else {
            this.nextCallStream.next(null);
        }
    };
    BigAlModule.prototype.makeQueryParameters = function (obj) {
        var objectPairs = [];
        Object.keys(obj).forEach(function (key) {
            objectPairs.push([key, obj[key]].join("="));
        });
        return objectPairs.join("&");
    };
    BigAlModule.prototype.clearData = function (clearUser) {
        var _this = this;
        Object.keys(this).forEach(function (key) {
            if (key.startsWith("current")) {
                if ((key === Constants.Event_currentUser && clearUser || key !== Constants.Event_currentUser) && key !== "currentRuleBook" && key !== "currentFleetUserFleetOverview" && key !== "currentFleetUserBusinessPartners") {
                    _this[key] = null;
                }
            }
        });
    };
    BigAlModule.prototype.mapStepperForm = function (values, type) {
        var typeObject = new type();
        return this.mapStepperFormObjectProvided(values, typeObject);
    };
    BigAlModule.prototype.mapStepperFormObjectProvided = function (form, typeObject) {
        var array = form["formArray"];
        array.forEach(function (element) {
            Object.keys(element).forEach(function (key) {
                if (typeObject.hasOwnProperty(key)) {
                    typeObject[key] = element[key];
                }
            });
        });
        return typeObject;
    };
    /* All dates passed to the server should be in UTC to avoid timezone problems.
     However, the user is able to select a date/timeslot in the user's local time,
     and therefore dates need to be handled before passing them on to the backend.
     Particularly date pickers, where you do not need the time of day,
     it can be problematic to pass it to the server because in that process
     it is converted to UTC, and thus the date can potentially change.
  
     This function changes the timezone of the date to UTC but it does not change
     the actual time, so 2019-02-27 4:00:00 will still be 2019-02-27 4:00:00 after
     the change.
    */
    BigAlModule.prototype.ChangeTimezoneToUTC = function (date) {
        var hour = date.getHours();
        // Convert the timezone offset from minutes to hours and multiply with -1 so we make up the difference.
        var timezoneOffset = (date.getTimezoneOffset() / 60) * (-1);
        date.setHours((hour + timezoneOffset));
        return date;
    };
    BigAlModule.prototype.setCurrentBookingFormSelectedDate = function (date) {
        this.currentBookingFormSelectedDate = this.ChangeTimezoneToUTC(date);
    };
    BigAlModule.prototype.getCurrentBookingFormSelectedDate = function () {
        return this.currentBookingFormSelectedDate;
    };
    BigAlModule.prototype.setCurrentBookingFormSelectedServiceType = function (type) {
        this.currentBookingFormSelectedServiceType = type;
    };
    BigAlModule.prototype.getCurrentBookingFormSelectedServiceType = function () {
        return this.currentBookingFormSelectedServiceType;
    };
    BigAlModule.prototype.setStorageData = function (appUser, overrideRoles) {
        localStorage.setItem(this.RefreshTokenKey, appUser.RefreshKey);
        if (appUser.Roles && overrideRoles) {
            var roles = appUser.Roles.join();
            localStorage.setItem(this.UserRoleKey, roles);
        }
    };
    BigAlModule.prototype.getRefreshKey = function () {
        var refreshToken = localStorage.getItem(this.RefreshTokenKey);
        return refreshToken;
    };
    BigAlModule.prototype.getPreviousUserRoles = function () {
        var rolesString = localStorage.getItem(this.UserRoleKey);
        var rolesArray = [];
        if (rolesString) {
            rolesArray = rolesString.split(",");
        }
        var userRoles = [];
        rolesArray.forEach(function (r) {
            userRoles.push(Number.parseInt(r));
        });
        return userRoles;
    };
    BigAlModule.prototype.setDataForRequestHeaders = function (token) {
        localStorage.setItem('api_token', token);
    };
    BigAlModule.prototype.isInternalRequest = function (url) {
        var resp = false;
        if (this.defaultSettings) {
            resp = url.includes(this.defaultSettings.ApiBaseUrl);
        }
        if (this.appSettings && !resp) {
            resp = url.includes(this.appSettings.ApiBaseUrl);
        }
        return resp;
    };
    BigAlModule.prototype.getAuthorizationHeader = function () {
        if (this.defaultSettings) {
            return localStorage.getItem("api_token");
        }
        return null;
    };
    BigAlModule.prototype.getTraceHeader = function () {
        if (this.appSettings) {
            return this.appSettings.TraceId;
        }
        return null;
    };
    BigAlModule.prototype.getCompany = function () {
        if (this.appSettings.CompanyId === 1) {
            return 'ald';
        }
        if (this.appSettings.CompanyId === 2) {
            return 'nf';
        }
    };
    //SD-821:We need to send different service type based on the vehicletype fo Euromaster booking.
    //We are getting insurance group id from miles and below vehicle type and insurnace group mapping is given by Euromaster.
    BigAlModule.prototype.getTireCenterServiceTypeByInsuranceGroup = function (tireBranch, insuranceGroupId) {
        var insuranceGroupVehicleTypeMapping = [
            { insuranceGroup: "203153", vehicleType: "PV" },
            { insuranceGroup: "203155", vehicleType: "LLV" },
            { insuranceGroup: "203156", vehicleType: "PV" },
            { insuranceGroup: "203158", vehicleType: "PV" },
            { insuranceGroup: "203159", vehicleType: "LLV" },
            { insuranceGroup: "203160", vehicleType: "PV" },
            { insuranceGroup: "203161", vehicleType: "PV" },
            { insuranceGroup: "203162", vehicleType: "PV" },
            { insuranceGroup: "203163", vehicleType: "PV" },
            { insuranceGroup: "203164", vehicleType: "PV" },
            { insuranceGroup: "203165", vehicleType: "PV" },
            { insuranceGroup: "203166", vehicleType: "LLV" },
            { insuranceGroup: "203167", vehicleType: "LLV" },
            { insuranceGroup: "203168", vehicleType: "LLV" },
            { insuranceGroup: "203169", vehicleType: "LLV" }
        ];
        var group = insuranceGroupVehicleTypeMapping.find(function (i) { return i.insuranceGroup === insuranceGroupId.toString(); });
        var serviceType = null;
        if (group != null) {
            //for LLV vehicle the service type will be Säsongsskifte - Lätt lastbil
            if (group.vehicleType.toLowerCase() === "llv") {
                serviceType = tireBranch.ServiceTypes.find(function (st) { return st.Option === TireBranchServiceTypeOption.SeasonChange && st.Name.match(/Säsongsskifte\s*-\s*Lätt\s*lastbil\s*\(\d+\s*min\)/) != null; });
            }
            //for PV vehicle the service type will be Säsongsskifte
            else if (group.vehicleType.toLowerCase() === "pv") {
                serviceType = tireBranch.ServiceTypes.find(function (st) { return st.Option === TireBranchServiceTypeOption.SeasonChange && st.Name.match(/Säsongsskifte\s*\(\d+\s*min\)/) != null; });
            }
        }
        return serviceType;
    };
    return BigAlModule;
}());
export { BigAlModule };
