var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractModalFeature } from '../../../../components/features/modalfeature.abstract';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ActivatedRoute } from '@angular/router';
import { Appointment } from '../../../../models/appointment.model';
import { ValidatorFactory } from '../../../../rulebook/validator-factory';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { Constants } from '../../../../helpers/constants';
var UpdateBookingComponent = /** @class */ (function (_super) {
    __extends(UpdateBookingComponent, _super);
    // set content variables
    // set features variables
    function UpdateBookingComponent(translateService, translate, bigAl, theBen, route, validatorFactory, formBuilder) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.translateService = translateService;
        _this.translate = translate;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.route = route;
        _this.validatorFactory = validatorFactory;
        _this.formBuilder = formBuilder;
        _this.today = new Date();
        _this.isEditing = false;
        _this.createForm();
        return _this;
    }
    UpdateBookingComponent.prototype.onDateChange = function (newDate) {
        this.bigAl.setCurrentBookingFormSelectedDate(newDate);
        this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
    };
    UpdateBookingComponent.prototype.onLaterClick = function () {
        var laterDate = new Date();
        var lastDate = this.availableTimes[this.availableTimes.length - 1]
            .AvailableBookingDate;
        // add duration of one day to last date
        laterDate.setTime((new Date(lastDate)).getTime() + 24 * 60 * 60 * 1000);
        this.domainForm.controls["date"].setValue(laterDate);
    };
    UpdateBookingComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field); // {3}
            if (control instanceof FormControl) { // {4}
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) { // {5}
                _this.validateAllFormFields(control); // {6}
            }
        });
    };
    UpdateBookingComponent.prototype.createForm = function () {
        var mobile = (this.bigAl.currentDriver !== null) ? this.bigAl.currentDriver.MobileNumber : "";
        var date = (this.appointment) ? this.appointment.Appointment : new Date();
        this.domainForm = this.formBuilder.group({
            date: [date],
            selectedDateTime: ['', this.validatorFactory.getRequiredValidator()],
            mobilePhoneNumber: [mobile, [this.validatorFactory.getRequiredValidator(), this.validatorFactory.getPhoneValidator(this.validatorFactory.hasValue)]]
        });
    };
    UpdateBookingComponent.prototype.onFormSubmit = function () {
        this.validateAllFormFields(this.domainForm);
        if (!this.domainForm.valid) {
        }
        else {
            var appointment = new Appointment(this.appointment.AppointmentId, this.domainForm.value["selectedDateTime"], this.domainForm.value["mobilePhoneNumber"], null);
            this.bigAl.updateAppointment(this.appointmentPlace.ModelName, appointment);
            this.close(true);
        }
    };
    UpdateBookingComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.route.params.subscribe(function (params) {
            _this.modelName = params['modelName'];
            _this.appointmentPlace = _this.bigAl.getAppointmentPlace(params['modelName']);
            _this.appointment = _this.bigAl.currentTireCenter.Bookings.find(function (booking) { return booking.AppointmentId.toString() === params['appointmentId']; });
            _this.domainForm.controls["date"].setValue(_this.appointment.Appointment);
        }));
        this.subscribe(this.domainForm.controls["date"].valueChanges.subscribe(function (newDate) {
            _this.onDateChange(newDate);
        }));
        this.subscribe(this.domainForm.controls["selectedDateTime"].valueChanges.subscribe(function (timeslot) {
            _this.currentSelectedTimeslot = timeslot;
        }));
        this.subscribe(this.translateService.isEditing.subscribe(function (editing) {
            _this.isEditing = editing;
        }));
        this.watchEvent(Constants.Event_CurrentDriver);
        this.watchEvent("currentAvailableTimes");
        return;
    };
    UpdateBookingComponent.prototype.setData = function () {
        this.currentLanguage = this.translate.currentLang;
        if (this.bigAl.currentDriver) {
            this.driver = this.bigAl.currentDriver;
        }
        if (this.bigAl["current" + this.modelName]) {
            this.appointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
        }
        if (this.bigAl.currentAvailableTimes) {
            this.availableTimes = this.bigAl.currentAvailableTimes;
        }
    };
    UpdateBookingComponent.prototype.getData = function () {
        if (!this.bigAl.currentDriver) {
            this.bigAl.getDriver();
        }
        else {
            this.driver = this.bigAl.currentDriver;
        }
        if (!this.bigAl["current" + this.modelName]) {
            this.bigAl["get" + this.modelName]();
            this.appointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
        }
        if (!this.bigAl.currentAvailableTimes) {
            if (this.appointment) {
                this.bigAl.setCurrentBookingFormSelectedDate(new Date(this.appointment.Appointment));
                this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
            }
            else {
                this.bigAl.setCurrentBookingFormSelectedDate(new Date());
                this.bigAl.getAvailableTimes(this.appointmentPlace.ModelName);
            }
        }
        this.setData();
    };
    UpdateBookingComponent.prototype.isDataAvailable = function () {
        return true;
    };
    return UpdateBookingComponent;
}(AbstractModalFeature));
export { UpdateBookingComponent };
