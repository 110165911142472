import { OnDestroy } from '@angular/core';
import { BigAlModule } from '../modules/bigal.module';
// Types
import { ComponentStructure } from '../models/component-config/component-structure.model';
import { FeatureShowState } from '../models/component-config/feature-structure.model';
import { ComponentConfiguration } from '../models/component-config/component-configuration.model';
import { ComponentContent } from '../models/component-config/component-content.model';
import { ComponentActionType, ComponentAction } from '../models/component-config/component-action.model';
// Other
import { CustomTranslateService } from '../translation/customTranslateService';
import * as externalLinks from 'assets/init/external-links.json';
import { NavigationService } from './navigation.service';
import { TrackingHelper } from '../helpers/trackingHelper';
import { AppSettingsStore, AppUserStore } from '../stores/DataStores';
var ComponentConfigService = /** @class */ (function () {
    // #region Life Cycle
    function ComponentConfigService(bigAl, navigationService, translateService, settingsStore, appUserStore) {
        this.bigAl = bigAl;
        this.navigationService = navigationService;
        this.translateService = translateService;
        this.settingsStore = settingsStore;
        this.appUserStore = appUserStore;
        this.isInTranslationMode = false;
        this.createdComponentContent = new Map();
        this.trackingService = new TrackingHelper(appUserStore);
        this.init();
    }
    ComponentConfigService.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    // #endregion
    ComponentConfigService.prototype.init = function () {
        var _this = this;
        this.subscription = this.translateService.isEditing
            .subscribe(function (isInTranslationMode) {
            _this.isInTranslationMode = isInTranslationMode;
        });
    };
    ComponentConfigService.prototype.isContent = function (component, name) {
        var componentName = (typeof component === 'string') ? component : component.constructor.name;
        this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(function (comp) { return comp.Name === componentName; });
        if (!this.config) {
            this.createContent(componentName, name);
            return false;
        }
        var content = this.config.Content.find(function (c) { return c.Name === name; });
        if (!content) {
            this.createContent(componentName, name);
            return false;
        }
        return content.ShowContent;
    };
    ComponentConfigService.prototype.action = function (component, name, id) {
        if (this.isInTranslationMode) {
            return;
        }
        var componentName = (typeof component === "string") ? component : component.constructor.name;
        this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(function (comp) { return comp.Name === componentName; });
        var action;
        if (this.config) {
            action = this.config.Actions.find(function (a) { return a.Name === name; });
        }
        if (!action) {
            action = this.createAction(componentName, name);
        }
        var companyName = this.getCompanyName(this.settingsStore.appSettings.CompanyId);
        var countryCode = this.settingsStore.appSettings.CountryCode;
        var languageCode = this.settingsStore.appSettings.LanguageCode;
        var routeId;
        if (Array.isArray(id)) {
            routeId = id[0];
        }
        else {
            routeId = id;
        }
        var trackingAction = componentName + " - " + action.Name;
        this.trackingService.trackClick(trackingAction, action.Next);
        switch (+action.Type) {
            case ComponentActionType.RouteModal:
                if (action.Next === "close") {
                    this.navigationService.closeModal();
                }
                else {
                    if (id && Array.isArray(id) && id.length > 1) {
                        var array = [];
                        array.push(action.Next);
                        for (var i = 0; i < id.length; i++) {
                            array.push(id[i]);
                        }
                        this.navigationService.openModal(array);
                    }
                    else {
                        (routeId) ? this.navigationService.openModal([action.Next, routeId]) : this.navigationService.openModal(action.Next);
                    }
                }
                break;
            case ComponentActionType.Link:
                var companies = externalLinks['Companies'];
                // As we run through the .json file, we verify we find anything for each step,
                // and if we do not, we fallback to using the specified Next value on the action.
                var company = companies.find(function (obj) { return obj.Name === companyName; });
                if (!company) {
                    this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
                    break;
                }
                var country = company.Countries.find(function (obj) { return obj.Name === countryCode; });
                if (!country) {
                    this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
                    break;
                }
                var language = country.Languages.find(function (obj) { return obj.Name === languageCode; });
                if (!language) {
                    this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
                    break;
                }
                var externalLink = language.ExternalLinks.find(function (obj) { return obj.Key.trim().toLowerCase() === (componentName + "." + action.Name).trim().toLowerCase(); });
                if (!externalLink) {
                    this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
                    break;
                }
                this.navigationService.openNewTab(externalLink.Value, this.bigAl.appSettings.IsApp);
                break;
            case ComponentActionType.File:
                var path = this.getAssetsFilePath(companyName, countryCode, languageCode);
                this.navigationService.openNewTab(path + action.Next, this.bigAl.appSettings.IsApp);
                break;
            case ComponentActionType.Phone:
                window.open('tel:' + action.Next, '_self');
                break;
            case ComponentActionType.Route:
                this.navigationService.forward(action.Next, routeId);
                break;
            default:
                if (action.Next === "close") {
                    this.navigationService.back();
                }
                else {
                    this.navigationService.forward(action.Next, routeId);
                }
                break;
        }
    };
    ComponentConfigService.prototype.actionDocument = function (component, name) {
        this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(function (comp) { return comp.Name === component.constructor.name; });
        if (this.config !== undefined) {
            var action = this.config.Actions.find(function (a) { return a.Name === name; });
            if (action) {
                window.open('/assets/' + action.Next);
            }
        }
    };
    ComponentConfigService.prototype.isFeature = function (name) {
        var _this = this;
        name = name.toLowerCase();
        this.featuresConfig = this.bigAl.appSettings.FeatureComponentConfiguration.FeatureConfiguration.Features;
        if (this.featuresConfig !== undefined) {
            var feature = this.featuresConfig.find(function (k) {
                if (k.Name === name) {
                    if (_this.bigAl.isUserLoggedIn && (k.ShowState & FeatureShowState.LoggedIn) === FeatureShowState.LoggedIn) {
                        return true;
                    }
                    else if ((k.ShowState & FeatureShowState.StartScreen) === FeatureShowState.StartScreen) {
                        return true;
                    }
                }
                return false;
            });
            if (feature) {
                return true;
            }
        }
        return false;
    };
    // #region Helpers
    ComponentConfigService.prototype.createContent = function (configName, contentName) {
        // Check if we've already attempted to create the compoent + content in this run.
        if (this.createdComponentContent.has(configName)) {
            var componentContent = this.createdComponentContent.get(configName);
            for (var i = 0; i < componentContent.length; i++) {
                if (componentContent[i] === contentName) {
                    return;
                }
            }
            componentContent.push(contentName);
            this.createdComponentContent.set(configName, componentContent);
        }
        else {
            this.createdComponentContent.set(configName, [contentName]);
        }
        var compContent = new ComponentContent();
        compContent.Name = contentName;
        compContent.ShowContent = false;
        var compStructure = new ComponentStructure();
        compStructure.Name = configName;
        compStructure.Content = [compContent];
        var compConfig = new ComponentConfiguration();
        compConfig.Components = [compStructure];
        this.bigAl.postComponents(compConfig);
    };
    ComponentConfigService.prototype.createAction = function (configName, actionName) {
        var compAction = new ComponentAction();
        compAction.Name = actionName;
        // Any unknown path will go to the under construction page. 'Under-construction' does not actually match a path, but this will show in the browser url
        compAction.Next = 'under-construction';
        var compStructure = new ComponentStructure();
        compStructure.Name = configName;
        compStructure.Actions = [compAction];
        var compConfig = new ComponentConfiguration();
        compConfig.Components = [compStructure];
        this.bigAl.postComponents(compConfig);
        return compAction;
    };
    ComponentConfigService.prototype.getCompanyName = function (companyId) {
        switch (companyId) {
            case 1:
                return "ald";
            case 2:
                return "nf-fleet";
        }
        return "ald";
    };
    ComponentConfigService.prototype.getAssetsFilePath = function (companyName, country, language) {
        if (this.bigAl.defaultSettings.IsApp) {
            return this.bigAl.appSettings.DefaultFrontendUrl + "/assets/files/" + companyName + "/" + country + "/" + language + "/";
        }
        return "assets/files/" + companyName + "/" + country + "/" + language + "/";
    };
    return ComponentConfigService;
}());
export { ComponentConfigService };
