import { Component, OnInit, Input } from '@angular/core';
import { Form } from '../../../../models/form.model';
import { FormProperty, FormObject } from '../../../../models/form-object.model';
import { Rule } from '../../../../models/rule-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss']
})
export class FieldErrorComponent implements OnInit {

  public translationTagType: TranslationTagType = TranslationTagType.Error;
  @Input() formObject: FormObject;
  @Input() formModel: Form;
  @Input() prop: FormProperty;
  @Input() rule: Rule;
  @Input() formArrayIndex: number;
  constructor() { }

  ngOnInit() {
  }

}
