import { Component, OnInit, Input } from '@angular/core';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { Rule } from '../../../../models/rule-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';

@Component({
  selector: 'app-field-translation',
  templateUrl: './field-translation.component.html',
  styleUrls: ['./field-translation.component.scss']
})
export class FieldTranslationComponent implements OnInit {

  @Input() translationTagType: TranslationTagType;
  @Input() formObject: FormObject;
  @Input() prop: FormProperty;
  @Input() rule: Rule;
  constructor() { }

  ngOnInit() {
  }

  public getTranslationTag() {
    if (this.prop.Translateable === false) {
      return this.prop.Label;
    }

    // Because description tags were implemented in a different way, and was already being used in multiple places once discovered, it has it's own special case.
    if (this.translationTagType === TranslationTagType.Description) {
      return this.formObject.Name + ".description_" + this.prop.Name + "_t";
    }

    let baseTag = this.formObject.Name + "." + this.prop.Name;
    switch (this.translationTagType) {
      case TranslationTagType.Error:
        return baseTag + "_" + this.rule.Type + "_e";
      case TranslationTagType.Hint:
        return baseTag + "_h";
      case TranslationTagType.Label:
        return baseTag + "_l";
      case TranslationTagType.Placeholder:
        return baseTag + "_p";
    }
  }

}
