var ChangeDriver = /** @class */ (function () {
    function ChangeDriver(ContractId, KmInput, ShouldFormerUsersFuelCardsBeClosed, NotesOnFormerUsersFuelCards, FormerDriversName, VehicleRegistrationNumber, ChangeDriverOn, NewDriverName, NewDriverPhoneNumber, NewDriverEmail, NewDriverDepartmentNumber, DoesNewDriverAlreadyExists, ShouldTiresBeReplaced, TiresNewPlacementCityAndZip, ListOfFuelCardsToOrder, KmPerYear, // Not used by DK
    TotalKmByEndOfContract // Not used by DK
    ) {
        if (ContractId === void 0) { ContractId = ""; }
        if (KmInput === void 0) { KmInput = ""; }
        if (ShouldFormerUsersFuelCardsBeClosed === void 0) { ShouldFormerUsersFuelCardsBeClosed = false; }
        if (NotesOnFormerUsersFuelCards === void 0) { NotesOnFormerUsersFuelCards = ""; }
        if (FormerDriversName === void 0) { FormerDriversName = ""; }
        if (VehicleRegistrationNumber === void 0) { VehicleRegistrationNumber = ""; }
        if (ChangeDriverOn === void 0) { ChangeDriverOn = null; }
        if (NewDriverName === void 0) { NewDriverName = ""; }
        if (NewDriverPhoneNumber === void 0) { NewDriverPhoneNumber = ""; }
        if (NewDriverEmail === void 0) { NewDriverEmail = ""; }
        if (NewDriverDepartmentNumber === void 0) { NewDriverDepartmentNumber = ""; }
        if (DoesNewDriverAlreadyExists === void 0) { DoesNewDriverAlreadyExists = false; }
        if (ShouldTiresBeReplaced === void 0) { ShouldTiresBeReplaced = false; }
        if (TiresNewPlacementCityAndZip === void 0) { TiresNewPlacementCityAndZip = ""; }
        if (ListOfFuelCardsToOrder === void 0) { ListOfFuelCardsToOrder = []; }
        if (KmPerYear === void 0) { KmPerYear = ""; }
        if (TotalKmByEndOfContract === void 0) { TotalKmByEndOfContract = ""; }
        this.ContractId = ContractId;
        this.KmInput = KmInput;
        this.ShouldFormerUsersFuelCardsBeClosed = ShouldFormerUsersFuelCardsBeClosed;
        this.NotesOnFormerUsersFuelCards = NotesOnFormerUsersFuelCards;
        this.FormerDriversName = FormerDriversName;
        this.VehicleRegistrationNumber = VehicleRegistrationNumber;
        this.ChangeDriverOn = ChangeDriverOn;
        this.NewDriverName = NewDriverName;
        this.NewDriverPhoneNumber = NewDriverPhoneNumber;
        this.NewDriverEmail = NewDriverEmail;
        this.NewDriverDepartmentNumber = NewDriverDepartmentNumber;
        this.DoesNewDriverAlreadyExists = DoesNewDriverAlreadyExists;
        this.ShouldTiresBeReplaced = ShouldTiresBeReplaced;
        this.TiresNewPlacementCityAndZip = TiresNewPlacementCityAndZip;
        this.ListOfFuelCardsToOrder = ListOfFuelCardsToOrder;
        this.KmPerYear = KmPerYear;
        this.TotalKmByEndOfContract = TotalKmByEndOfContract;
    }
    return ChangeDriver;
}());
export { ChangeDriver };
