<ul class="user-menu-items">
  <div #decorativeAnchor class="decorative-anchor"></div>
  <li class="item" routerLinkActive="active" *ngIf="isFleetAdmin && isContent('dashboard-fleet-user')">
    <a (click)="onClick($event)" routerLink="dashboard">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-feed"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_dashboard">
          <span translate-text>
            {{'MenuComponent.menu_dashboard'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active">
    <a (click)="onClick($event)" routerLink="/fleet/overview">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-car"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_fleet">
          <span translate-text>{{'MenuComponent.menu_fleet'| translate}}</span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('reports')">
    <a (click)="onClick($event)" routerLink="/fleet/reports">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-glove-box"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_reports">
          <span translate-text>{{'MenuComponent.menu_reports'| translate}}</span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('glovebox')">
    <a (click)="onClick($event)" routerLink="/glovebox">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-feed"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu-glove-box">
          <span translate-text>
            {{'MenuComponent.menu-glove-box'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active">
    <a (click)="onClick($event)" routerLink="/fleet/more">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-more"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_more">
          <span translate-text>{{'MenuComponent.menu_more'| translate}}</span>
        </app-translate>
      </span>
    </a>
  </li>
</ul>