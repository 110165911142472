import { ComponentConfigComponent } from './../../components/features/component-config/component-config.component';
import { FeedComponent } from './../../components/features/feed/feed.component';
import { CustomMaterialModule } from './../custom-material.module';
import { PageNotFoundComponent } from './../../components/shared/page-not-found/page-not-found.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from "@angular/core";
import { TranslateModule } from '@ngx-translate/core';
import { LoginGuard } from '../../guards/login.guard';
import { DriverGuard } from '../../guards/driver.guard';
import { TakeOverGuard } from '../../guards/takeover.guard';
import { FleetUserGuard } from '../../guards/fleetuser.guard';
import { SystemAdminGuard } from '../../guards/systemadmin.guard';
import { TabPaneComponent } from '../../components/shared/tab-pane/tab-pane.component';
import { FirstPageComponent } from '../../components/pages/first-page/first-page.component';
import { ContactItemComponent } from '../../components/shared/contact-item/contact-item.component';
import { ContactsComponent } from '../../components/shared/contacts/contacts.component';
import { AccidentsAbroadContactComponent } from '../../components/features/contact-information/accidents-abroad-contact/accidents-abroad-contact.component';
import { CarSpinnerComponent } from '../../components/init/car-spinner.component';
import { UrgentHelpComponent } from '../../components/features/contact-information/urgent-help/urgent-help.component';
import { SharedModule } from './shared.module';
import { RestitutionContactComponent } from '../../components/features/contact-information/restitution-contact/restitution-contact.component';
import { UrgentHelpContactComponent } from '../../components/features/contact-information/urgent-help-contact/urgent-help-contact.component';
import { MissingTranslationComponent } from '../../components/features/missing-translations/missing-translations.component';
import { TranslateListComponent } from '../../components/features/translation-list/translate-list';
import { ApiErrorComponent } from '../../components/shared/api-error/api-error.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CustomMaterialModule,
    SharedModule
  ],
  declarations:
    [
      ApiErrorComponent,
      FeedComponent,
      PageNotFoundComponent,
      TabPaneComponent,
      FirstPageComponent,
      ContactItemComponent,
      ContactsComponent,
      RestitutionContactComponent,
      AccidentsAbroadContactComponent,
      CarSpinnerComponent,
      UrgentHelpComponent,
      UrgentHelpContactComponent,
      ComponentConfigComponent,
      MissingTranslationComponent,
      TranslateListComponent
    ],
  exports: [
    ApiErrorComponent,
    FeedComponent,
    PageNotFoundComponent,
    TabPaneComponent,
    FirstPageComponent,
    ContactItemComponent,
    ContactsComponent,
    RestitutionContactComponent,
    AccidentsAbroadContactComponent,
    CarSpinnerComponent,
    UrgentHelpComponent,
    UrgentHelpContactComponent,
  ],
  providers: [
    LoginGuard,
    DriverGuard,
    TakeOverGuard,
    FleetUserGuard,
    SystemAdminGuard,
  ]
})
export class GenericModule { }
