<div class="element-container element-container-sm">
  <div class="sub-box">
    <h1>
      <app-translate translationName="LoginComponent.title_t">
        <span translate-text>
          {{ 'LoginComponent.title_t' | translate }}
        </span>
      </app-translate>
    </h1>
    <app-form [formObject]="getFormObject()" [ruleObject]="getRuleObject()"></app-form>
    <a (click)="forgotLogin()" class="link-primary">
      <app-translate translationName="LoginComponent.forgotLogin_t">
        <span translate-text>{{ 'LoginComponent.forgotLogin_t' | translate }}</span>
      </app-translate>
    </a>
  </div>
  <!-- language selector !only in apps -->
  <div class="list-item" *ngIf="!bigAl.isInDesktopScreen">
    <h3 class="primary-text">
      <app-translate translationName="LoginComponent.language_t">
        <span translate-text>
          {{ 'LoginComponent.language_t' | translate }}
        </span>
      </app-translate>
    </h3>
    <mat-radio-group>
      <span *ngFor="let languageCode of bigAl.getAvailableLanguageCodes();let i = index">
        <label>
          <mat-radio-button [value]="languageCode" [id]="i" color="primary"
                            [checked]="bigAl.appSettings.LanguageCode === languageCode"
                            (change)="this.bigAl.changeLanguage(languageCode)"></mat-radio-button>
          {{(languageCode.toUpperCase())}}
          <br />
        </label>
      </span>
    </mat-radio-group>
  </div>
  <app-api-error></app-api-error>
</div>
