var Translation = /** @class */ (function () {
    function Translation(TranslationName, TranslationValue, LanguageCode, CompanyId, Language) {
        if (Language === void 0) { Language = ""; }
        this.TranslationName = TranslationName;
        this.TranslationValue = TranslationValue;
        this.LanguageCode = LanguageCode;
        this.CompanyId = CompanyId;
        this.Language = Language;
    }
    return Translation;
}());
export { Translation };
