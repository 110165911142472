import { BigAlModule } from '../modules/bigal.module';
import { OnDestroy } from '@angular/core';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
var SystemAdminGuard = /** @class */ (function () {
    function SystemAdminGuard(navigationService, bigAl) {
        this.navigationService = navigationService;
        this.bigAl = bigAl;
    }
    SystemAdminGuard.prototype.canActivate = function (route, state) {
        // If the user is logged in, and that user is not a system admin, we redirect to the user's "landing page"
        if (!this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.SystemAdminFleetAdmin || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin; })) {
            this.navigationService.forward("dashboard", null);
            return false;
        }
        return true;
    };
    SystemAdminGuard.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return SystemAdminGuard;
}());
export { SystemAdminGuard };
