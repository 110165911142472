import { OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { Translation } from '../../../models/translation.model';
import { CustomTranslateService } from '../../../../app/translation/customTranslateService';
var TranslateListComponent = /** @class */ (function () {
    function TranslateListComponent(navigationService, customTranslateService) {
        this.navigationService = navigationService;
        this.customTranslateService = customTranslateService;
        this.aldCompanyId = 1;
        this.translationGroups = [];
    }
    TranslateListComponent.prototype.ngOnInit = function () {
        var languageCode = this.customTranslateService.getCurrentLang();
        var result = this.customTranslateService.getAllCompanyTranslations(this.aldCompanyId, languageCode);
        for (var propertyName in result) {
            var currentGroup = {
                'GroupName': propertyName,
                'Translations': []
            };
            var currentTranslations = result[propertyName];
            for (var currentTranslationProp in currentTranslations) {
                currentGroup.Translations.push(new Translation(propertyName + '.' + currentTranslationProp, currentTranslations[currentTranslationProp], languageCode, this.aldCompanyId));
            }
            this.translationGroups.push(currentGroup);
        }
    };
    TranslateListComponent.prototype.back = function () {
        this.navigationService.back();
    };
    return TranslateListComponent;
}());
export { TranslateListComponent };
