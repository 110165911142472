var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractModalFeature } from '../../../../components/features/modalfeature.abstract';
import { ActivatedRoute } from '@angular/router';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
var DeleteBookingComponent = /** @class */ (function (_super) {
    __extends(DeleteBookingComponent, _super);
    function DeleteBookingComponent(route, bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.route = route;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        return _this;
    }
    DeleteBookingComponent.prototype.deleteAppointment = function () {
        this.bigAl.deleteAppointment(this.currentAppointmentPlace.ModelName, this.appointment.AppointmentId);
        this.action('modal');
    };
    DeleteBookingComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.route.params.subscribe(function (params) {
            _this.currentAppointmentPlace = _this.bigAl.getAppointmentPlace(params['modelName']);
            _this.appointment = _this.currentAppointmentPlace.Bookings.find(function (appointment) { return appointment.AppointmentId === params['appointmentId']; });
        }));
        return;
    };
    DeleteBookingComponent.prototype.setData = function () {
    };
    DeleteBookingComponent.prototype.getData = function () {
        this.setData();
    };
    DeleteBookingComponent.prototype.isDataAvailable = function () {
        return true;
    };
    return DeleteBookingComponent;
}(AbstractModalFeature));
export { DeleteBookingComponent };
