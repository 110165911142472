var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewChecked, ElementRef } from '@angular/core';
// Common
import { BigAlModule } from './../../../modules/bigal.module';
import { AbstractFeature } from '../../../components/features/feature.abstract';
// Services
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { ScreenService } from './../../../services/screen.service';
import { FleetUserService } from '../../../services/fleet-user.service';
import { RecordTripService } from '../../../services/driverjournal/recordtrip.service';
import { DjTabsService } from '../../../services/driverjournal/dj-tabs.service';
import { NavigationService } from '../../../services/navigation.service';
import { AppUserStore } from '../../../stores/DataStores';
var MenuDriverComponent = /** @class */ (function (_super) {
    __extends(MenuDriverComponent, _super);
    function MenuDriverComponent(screenService, element, theBen, bigAl, fleetUserService, recordTripService, djTabService, navigationService, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.screenService = screenService;
        _this.element = element;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.fleetUserService = fleetUserService;
        _this.recordTripService = recordTripService;
        _this.djTabService = djTabService;
        _this.navigationService = navigationService;
        _this.appUserStore = appUserStore;
        _this.isInTakeOverMode = false;
        _this.isApp = false;
        _this.djTab = '/journal';
        _this.anchorMovedOnInit = false;
        _this.settings = _this.appUserStore.appUser.Settings;
        _this.isApp = _this.bigAl.appSettings.IsApp;
        return _this;
    }
    MenuDriverComponent.prototype.ngAfterViewChecked = function () {
        if (!this.anchorMovedOnInit &&
            this.element.nativeElement.querySelector(".active a")) {
            this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
            this.anchorMovedOnInit = true;
        }
    };
    MenuDriverComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.isInTakeOverMode = this.bigAl.getStorageValueForIsInTakeOverMode();
        this.subscribe(this.recordTripService.showRecordingTripMenue().subscribe(function (state) {
            _this.isShowingRecordingMenue = state;
        }));
        this.subscribe(this.djTabService.getData().subscribe(function (data) { return _this.djTab = data; }));
    };
    MenuDriverComponent.prototype.setData = function () { };
    MenuDriverComponent.prototype.getData = function () { };
    MenuDriverComponent.prototype.isDataAvailable = function () {
        return true;
    };
    MenuDriverComponent.prototype.moveAnchor = function (target) {
        var itemTarget = this.findAncestorItem(target);
        this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
    };
    MenuDriverComponent.prototype.findAncestorItem = function (el) {
        while ((el = el.parentNode) && !el.classList.contains("item")) { }
        return el;
    };
    // EventHandler
    MenuDriverComponent.prototype.onClick = function ($event) {
        this.moveAnchor($event.target);
    };
    MenuDriverComponent.prototype.onDjClick = function ($event) {
        this.onClick($event);
        this.navigationService.forward(this.djTab, null);
    };
    MenuDriverComponent.prototype.triggerRecordTripMenu = function () {
        if (this.isShowingRecordingMenue === true) {
            this.recordTripService.hideMenu();
        }
        else {
            this.recordTripService.showMenu();
        }
    };
    MenuDriverComponent.prototype.hasParkingEnabled = function () {
        var parkingEnabled = this.settings['ParkingApp'];
        return parkingEnabled;
    };
    return MenuDriverComponent;
}(AbstractFeature));
export { MenuDriverComponent };
