<div class="element-container element-container-sm">
  <div>
    <h2>
      <app-translate translationName='ForgotLoginComponent.t_forgot_password_confirmation'>
        <span translate-text>{{'ForgotLoginComponent.t_forgot_password_confirmation'| translate}}</span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName='ForgotLoginComponent.t_forgot_password_confirmation_help'>
        <span translate-text>{{'ForgotLoginComponent.t_forgot_password_confirmation_help'| translate}}</span>
      </app-translate>
    </p>
  </div>
  <button mat-button type="button" (click)="back()">
    <app-translate translationName='FormComponent.Back_t'>
      <span translate-text>{{'FormComponent.Back_t'| translate}}</span>
    </app-translate>
  </button>
</div>