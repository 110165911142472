import { BigAlModule } from '../modules/bigal.module';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class DriverGuard implements CanActivate, OnDestroy {
  private subscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private bigAl: BigAlModule
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // if type directly url - this.bigAl.isUserLoggedIn  returns False
    // this.bigAl.currentUser.Roles.some - returns nothig - changed to this.bigAl.getPreviousUserRoles()

    // if not driver
    if (!this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.Driver || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.DevUser_Driver)) {
      // If fleet user
      if (this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.SystemAdminFleetAdmin)) {

        // console.log('Driver guard - admin role found');
        // continue to take over guard
        return true;
      }
      // If business user redirect to dashboard
      else if (this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.BusinessUser)) {
        this.navigationService.forward("dashboard", null);
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
