import { DomainForm } from "../interfaces/domain-form.interface";

export class DriverJournalMonthlyLog implements DomainForm {

    constructor(
        public ContractId: string,
        public PersonId: string,
        public Year: number,
        public Month: number,
        public StartKm: number,
        public EndKm: number,
        public BusinessKm: number,
        public RegistrationNumber: string,
        public IsExtraCarSelected: boolean,
        public BusinessKmTotalExtraCar: number,
        public TotalKmExtraCar: number
    ) {
    }

    getDomainName() {
        return "DriverJournalMonthlyLog";
    }

}
