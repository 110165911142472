/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fleet-take-over-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/translate/translate.component.ngfactory";
import * as i3 from "../../../../shared/translate/translate.component";
import * as i4 from "../../../../../translation/customTranslateService";
import * as i5 from "../../../../../services/navigation.service";
import * as i6 from "../../../../../services/modals/modal.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./fleet-take-over-popup.component";
import * as i10 from "../../../../../modules/bigal.module";
import * as i11 from "../../../../../services/componentConfig.service";
import * as i12 from "@angular/router";
import * as i13 from "../../../../../stores/store-sections/app-user.store";
var styles_FleetTakeOverPopupComponent = [i0.styles];
var RenderType_FleetTakeOverPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FleetTakeOverPopupComponent, data: {} });
export { RenderType_FleetTakeOverPopupComponent as RenderType_FleetTakeOverPopupComponent };
function View_FleetTakeOverPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-translate", [["translationName", "FleetTakeOverPopupComponent.admin_contract_nr_t"]], null, null, null, i2.View_TranslateComponent_0, i2.RenderType_TranslateComponent)), i1.ɵdid(3, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "FleetTakeOverPopupComponent.admin_contract_nr_t"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("FleetTakeOverPopupComponent.admin_contract_nr_t")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.contractId; _ck(_v, 7, 0, currVal_2); }); }
function View_FleetTakeOverPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-translate", [["translationName", "FleetTakeOverPopupComponent.placeholder_mobile_t"]], null, null, null, i2.View_TranslateComponent_0, i2.RenderType_TranslateComponent)), i1.ɵdid(3, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "FleetTakeOverPopupComponent.placeholder_mobile_t"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("FleetTakeOverPopupComponent.placeholder_mobile_t")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.driver.MobileNumber; _ck(_v, 7, 0, currVal_2); }); }
function View_FleetTakeOverPopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-translate", [["translationName", "FleetTakeOverPopupComponent.placeholder_email_t"]], null, null, null, i2.View_TranslateComponent_0, i2.RenderType_TranslateComponent)), i1.ɵdid(3, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "FleetTakeOverPopupComponent.placeholder_email_t"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("FleetTakeOverPopupComponent.placeholder_email_t")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.driver.Email; _ck(_v, 7, 0, currVal_2); }); }
function View_FleetTakeOverPopupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.driver.Address; _ck(_v, 1, 0, currVal_0); }); }
function View_FleetTakeOverPopupComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.driver.PostalCode; var currVal_1 = _co.driver.City; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FleetTakeOverPopupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-translate", [["translationName", "FleetTakeOverPopupComponent.more_address_l"]], null, null, null, i2.View_TranslateComponent_0, i2.RenderType_TranslateComponent)), i1.ɵdid(2, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_6)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_7)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "FleetTakeOverPopupComponent.more_address_l"; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.driver.Address; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.driver.City; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("FleetTakeOverPopupComponent.more_address_l")); _ck(_v, 4, 0, currVal_1); }); }
function View_FleetTakeOverPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "tab-content-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_3)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_4)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_5)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.driver; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.driver.MobileNumber; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.driver.Email; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.driver.Address || _co.driver.City); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_FleetTakeOverPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetTakeOverPopupComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataAvailable(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FleetTakeOverPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fleet-take-over-popup", [], null, null, null, View_FleetTakeOverPopupComponent_0, RenderType_FleetTakeOverPopupComponent)), i1.ɵdid(1, 245760, null, 0, i9.FleetTakeOverPopupComponent, [i10.BigAlModule, i11.ComponentConfigService, i12.ActivatedRoute, i13.AppUserStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FleetTakeOverPopupComponentNgFactory = i1.ɵccf("app-fleet-take-over-popup", i9.FleetTakeOverPopupComponent, View_FleetTakeOverPopupComponent_Host_0, {}, { done: "done" }, []);
export { FleetTakeOverPopupComponentNgFactory as FleetTakeOverPopupComponentNgFactory };
