var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
// Common
import { BigAlModule } from './../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { AbstractFeature } from '../../feature.abstract';
// Services
import { ApiService } from '../../../../services/api.service';
// Stores
import { AppSettingsStore, ContractStore, BusinessPartnerStore, DriverStore } from '../../../../stores/DataStores';
// SVG library
import "svgjs";
//
var DigitalServiceCardComponent = /** @class */ (function (_super) {
    __extends(DigitalServiceCardComponent, _super);
    function DigitalServiceCardComponent(bigAl, theBen, settingStore, contractStore, businessPartner, driverStore, datePipe, apiService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.settingStore = settingStore;
        _this.contractStore = contractStore;
        _this.businessPartner = businessPartner;
        _this.driverStore = driverStore;
        _this.datePipe = datePipe;
        _this.apiService = apiService;
        _this.hasRenderedCard = false;
        _this.viewboxWidth = 100;
        _this.viewboxHeight = 60;
        _this.aldLogoSvgCode = '<g transform="scale(0.8)" fill-rule="evenodd" fill="none" stroke-width="1" stroke="none" id="000-login"><g id="000da_loader" transform="translate(90, 14)"><g id="ALD104C" transform="translate(0, 0)"><polygon id="Fill-1" fill="#FFFFFF" points="0.173 30.244 29.937 30.244 29.937 0.48 0.173 0.48"></polygon><polygon id="Fill-2" fill="#D8213B" points="0.881 15.361 29.228 15.361 29.228 1.188 0.881 1.188"></polygon><polygon id="Fill-3" fill="#1A1918" points="0.881 29.535 29.228 29.535 29.228 15.361 0.881 15.361"></polygon><polygon id="Fill-4" fill="#FFFFFF" points="5.834 16.224 24.275 16.224 24.275 14.5 5.834 14.5"></polygon></g></g></g>';
        _this.nfLogoSvgCode = '<g transform="scale(0.3) translate(210,60)" fill="#0265a6"><polygon class="st0" points="14.8,20.6 2.7,7.6 0.3,7.6 0.3,23.4 2.5,23.4 2.4,10.3 14.7,23.4 16.8,23.4 16.8,7.6 14.7,7.6   "/><polygon class="st0" points="21.7,23.4 24,23.4 24,16.4 35.7,16.4 35.7,14.6 24,14.6 24,9.5 36.1,9.5 36.1,7.6 21.7,7.6   "/><polygon class="st0" points="46.4,23.4 48.8,23.4 48.8,16.4 60.4,16.4 60.4,14.6 48.8,14.6 48.8,9.5 60.9,9.5 60.9,7.6 46.4,7.6      "/><rect x="63.6" y="7.6" class="st0" width="2.1" height="15.8"/><path class="st0" d="M82,17c-0.2-1.4-0.9-2.3-1.2-2.8c-1.9-2.3-4.6-2.4-5.6-2.4c-0.6,0-1.1,0.1-1.4,0.1c-0.9,0.1-3.9,0.7-5,3.6   c-0.3,0.9-0.4,1.7-0.4,2c0,3.9,3.2,5.8,6.7,5.8c1.9,0,3.2-0.5,3.9-0.8c0.3-0.1,1.4-0.8,2.2-1.7c0.2-0.3,0.4-0.6,0.6-1l-2.1-0.6   c-0.2,0.4-0.3,0.7-0.7,1c-0.9,1-2.2,1.4-3.5,1.4c-1.9,0-3.2-0.8-3.7-1.3c-0.8-0.8-1-1.4-1.2-2.5H82C82.1,17.5,82.1,17.3,82,17z    M70.8,16.3c0.2-0.5,0.3-0.6,0.4-0.8c1-1.4,2.5-2.1,4.1-2.1c0.8,0,1.2,0.1,1.4,0.2c0.6,0.2,1,0.4,1.2,0.6c0.6,0.3,1.2,0.9,1.7,2.1   C79.6,16.3,70.8,16.3,70.8,16.3z"/><path class="st0" d="M98,17c-0.2-1.4-0.9-2.3-1.2-2.8c-1.9-2.3-4.6-2.4-5.6-2.4c-0.6,0-1.1,0.1-1.4,0.1c-0.9,0.1-3.9,0.7-5,3.6   c-0.3,0.9-0.4,1.7-0.4,2c0,3.9,3.2,5.8,6.7,5.8c1.9,0,3.2-0.5,3.9-0.8c0.3-0.1,1.4-0.8,2.2-1.7c0.2-0.3,0.4-0.6,0.6-1l-2.1-0.6   c-0.2,0.4-0.3,0.7-0.7,1c-0.9,1-2.2,1.4-3.5,1.4c-1.9,0-3.2-0.8-3.7-1.3c-0.8-0.8-1-1.4-1.2-2.5H98C98,17.5,98,17.3,98,17z    M86.7,16.3c0.2-0.5,0.3-0.6,0.4-0.8c1-1.4,2.5-2.1,4.1-2.1c0.8,0,1.2,0.1,1.4,0.2c0.6,0.2,1,0.4,1.2,0.6c0.6,0.3,1.2,0.9,1.7,2.1   C95.5,16.3,86.7,16.3,86.7,16.3z"/><path class="st0" d="M106.7,13.6v-1.4h-3.1V8.5h-2.1v3.7h-1.9v1.4h1.9V20c0,1.4,0.1,1.9,0.6,2.5c0.6,0.6,1.6,0.7,2.4,0.7   c0.8,0,1.4-0.1,2.1-0.1v-1.9c-0.2,0-0.9,0.1-1.6,0.1c-1.2,0-1.4-0.5-1.5-1c-0.1-0.3-0.1-0.7-0.1-0.8v-5.8L106.7,13.6L106.7,13.6z"/></g>';
        _this.activeContract = null;
        _this.activeVehicle = null;
        _this.activeDriver = null;
        _this.activeBusinessPartner = null;
        return _this;
    }
    DigitalServiceCardComponent.prototype.onResize = function (event) {
        this.setBoardSize();
    };
    DigitalServiceCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        _super.prototype.subscribe.call(this, this.contractStore.onGetContracts$.subscribe(function (response) {
            if (response.isSuccess) {
                _this.activeContract = response.model.vehicleContract;
                _this.isStoreDataReady();
            }
        }));
        _super.prototype.subscribe.call(this, this.businessPartner.onGetBusinessPartner$.subscribe(function (response) {
            if (response.isSuccess) {
                _this.activeBusinessPartner = response.model;
                _this.isStoreDataReady();
            }
        }));
        _super.prototype.subscribe.call(this, this.driverStore.onGetDriver$.subscribe(function (response) {
            if (response.isSuccess) {
                _this.activeDriver = response.model;
                _this.isStoreDataReady();
            }
        }));
        _super.prototype.subscribe.call(this, this.settingStore.onLanguageChanged$.subscribe(function (response) {
            if (response.isSuccess) {
                if (_this.$serviceCardFront) {
                    _this.$serviceCardFront.innerHTML = "";
                    _this.renderCardFront();
                }
            }
        }));
        this.contractStore.getContracts();
        this.businessPartner.getBusinessPartner();
        this.driverStore.getDriver();
    };
    DigitalServiceCardComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.svgCard = null;
    };
    DigitalServiceCardComponent.prototype.setBoardSize = function () {
        // Re-calculate height
        var $cardElements = document.querySelectorAll("._card-element-height");
        // Get CSS margin value
        var style = getComputedStyle(this.$serviceCardFront);
        var margin = +(style.marginTop.replace('px', ''));
        for (var i = 0; i < $cardElements.length; i++) {
            var elm = $cardElements.item(i);
            elm.style.height = this.$serviceCardFront.offsetHeight + (margin * 2) + "px";
        }
    };
    DigitalServiceCardComponent.prototype.isStoreDataReady = function () {
        var isDataReady = this.activeBusinessPartner !== null && this.activeContract !== null && this.activeDriver !== null;
        if (isDataReady && !this.hasRenderedCard) {
            this.hasRenderedCard = true;
            if (this.activeContract && this.activeContract.VehicleId) {
                this.getVehicle(this.activeContract.VehicleId);
            }
        }
    };
    // todo: Move to Store and refactor BigAl
    DigitalServiceCardComponent.prototype.getVehicle = function (vehicleId) {
        var _this = this;
        if (vehicleId) {
            this.apiService.getAsync("vehicle/" + vehicleId, this.settingStore.appSettings)
                .then(function (data) {
                _this.activeVehicle = data;
                _this.renderCardFront();
            })
                .catch(function (err) {
            });
        }
    };
    DigitalServiceCardComponent.prototype.renderCardFront = function () {
        this.$serviceCardFront = document.querySelector("#service-card-front");
        this.svgCard = SVG('service-card-front').size('100%', '100%');
        this.svgCard.viewbox(0, 0, this.viewboxWidth, this.viewboxHeight);
        var logo = "";
        var header = "";
        var email1 = "Aldkostkontroll.no@aldautomotive.com";
        var email2 = "finance.no@aldautomotive.com";
        var txt_car = "Car";
        var txt_plate = "Plate";
        var txt_expire = "Expires";
        var txt_servicecard = "SERVICE CARD";
        var txt_forward = "All services according to the factory service program can be done without requisition\nor pre-approval from us.";
        var txt_invoices = "Maintenance and repairs below NOK 3,000,- incl. VAT can be done without pre-approval.\nAll maintenance and repairs over NOK 3,000,- incl. VAT must be pre-approved by our technical department:";
        var txt_invoices2 = "Without approval from ALD Automotive in advance, invoices may not be paid or significantly reduced.";
        var txt_postal = "Electronic invoices may be sent to:";
        var fontsizeSmall = 2;
        var fontsizeMedium1 = 2.8;
        var fontsizeMedium = 3.5;
        var fontsizeLarge = 7;
        var font = 'Arial';
        if (this.settingStore.appSettings.CompanyId === 1) {
            // ALD
            logo = this.aldLogoSvgCode;
            header = "ALD";
        }
        else {
            // NF
            logo = this.nfLogoSvgCode;
            header = "NF";
            email1 = "kostkontroll.no@nffleet.com";
            email2 = "finance.no@nffleet.com";
            txt_invoices2 = "Without approval from NF Fleet in advance, invoices may not be paid or significantly reduced.";
        }
        if (this.settingStore.appSettings.LanguageCode === 'nb') {
            txt_car = "Bilmerke";
            txt_plate = "Reg.nr";
            txt_expire = "Gyldig t.o.m";
            txt_servicecard = "SERVICEKORT";
            txt_forward = "Alle vanlige servicer i henhold til fabrikkens serviceprogram kan utføres uten rekvisisjon\neller forhåndsgodkjenning fra oss.";
            txt_invoices = "Vedlikehold og reparasjon under kr 3 000, inkl mva kan utføres uten forhåndsgodkjenning.\nAlt av vedlikehold og reparasjon over kr 3 000, skal forhåndgodkjennes av vår tekniske avdeling:";
            txt_invoices2 = "Uten forhåndsgodkjenning kan det medføre at faktura ikke blir betalt, eller betydelig avkortet.";
            txt_postal = "Elektronisk faktura kan sendes til:";
        }
        /*
         * Header
         */
        this.svgCard.text(header + " " + txt_servicecard).move(4, 5)
            .font({
            family: font, size: fontsizeLarge, leading: '1.5em'
        });
        /*
         * Body
         */
        // NAME Company / Driver
        this.svgCard.text(this.activeBusinessPartner.BusinessName + "\n" + this.activeDriver.FullName).move(4, 20)
            .font({
            family: font, size: fontsizeMedium, leading: '1.2em'
        });
        this.svgCard.text(txt_car + ": " + this.activeVehicle.Make).move(4, 31.3)
            .font({
            family: font, size: fontsizeMedium1, leading: '1.1em'
        });
        this.svgCard.text(txt_plate + ": " + this.activeVehicle.RegistrationNumber).move(4, 35.3)
            .font({
            family: font, size: fontsizeMedium1, leading: '1.1em'
        });
        var expireDate = this.datePipe.transform(this.activeContract.ExpectedEndDate, 'yyyy-MM-dd');
        this.svgCard.text(txt_expire + ": " + expireDate).move(4, 39.3)
            .font({
            family: font, size: fontsizeMedium1, leading: '1.1em'
        });
        /*
         * Footer
         */
        //
        var text = this.svgCard.text("" + txt_forward);
        text.move(4, 44)
            .font({
            family: font, size: fontsizeSmall, leading: '1.3em'
        });
        //
        this.svgCard.text(txt_invoices + " ").move(4, 50.5)
            .font({
            family: font, size: fontsizeSmall, leading: '1.3em'
        });
        var text2 = this.svgCard.text(".").fill("#ffffff");
        text2.build(true);
        var tspan2 = text2.tspan(email1).fill('#0000ee');
        text2.build(false);
        text2.move(3.5, 55.6)
            .font({
            family: font, size: fontsizeSmall, leading: '1.3em'
        });
        this.svgCard.link("mailto:" + email1)
            .target("_blank")
            .rect(35, 2)
            .move(4, 50.4)
            .opacity(0);
        this.svgCard.text("" + txt_invoices2).move(4, 58.2)
            .font({
            family: font, size: fontsizeSmall, leading: '1.3em'
        });
        //
        var text1 = this.svgCard.text("" + txt_postal);
        text1.build(true);
        var tspan = text1.tspan(" " + email2).fill('#0000ee');
        text1.build(false);
        text1.move(4, 62).font({
            family: font, size: fontsizeSmall, leading: '1.3em'
        });
        this.svgCard.link("mailto:" + email2).target("_blank")
            .rect(30, 2)
            .move(35, 56.8)
            .opacity(0);
        this.svgCard.svg(logo);
        this.setBoardSize();
    };
    DigitalServiceCardComponent.prototype.setEventsToWatch = function () { };
    DigitalServiceCardComponent.prototype.getData = function () { };
    DigitalServiceCardComponent.prototype.setData = function () { };
    DigitalServiceCardComponent.prototype.isDataAvailable = function () {
        return this.activeContract !== null;
    };
    return DigitalServiceCardComponent;
}(AbstractFeature));
export { DigitalServiceCardComponent };
