var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../modules/bigal.module';
import { OnDestroy, OnInit } from '@angular/core';
import { AbstractFeature } from '../features/feature.abstract';
import { ComponentConfigService } from '../../services/componentConfig.service';
import { NavigationService } from '../../services/navigation.service';
import { AppSettingsStore } from '../../stores/DataStores';
var LoginComponent = /** @class */ (function (_super) {
    __extends(LoginComponent, _super);
    function LoginComponent(navigationService, bigAl, theBen, settingsStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.navigationService = navigationService;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.settingsStore = settingsStore;
        _this.hide = true;
        _this.appSettings = _this.settingsStore.appSettings;
        return _this;
    }
    LoginComponent.prototype.setEventsToWatch = function () {
        return;
    };
    LoginComponent.prototype.setData = function () {
        return;
    };
    LoginComponent.prototype.getData = function () {
        return;
    };
    LoginComponent.prototype.isDataAvailable = function () {
        return true;
    };
    LoginComponent.prototype.getFormObject = function () {
        return _super.prototype.getFormObject.call(this, "UserLogin", new Map());
    };
    LoginComponent.prototype.getRuleObject = function () {
        return _super.prototype.getRuleObject.call(this, "UserLogin");
    };
    LoginComponent.prototype.forgotLogin = function () {
        this.navigationService.forward("forgot-login", null);
    };
    LoginComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.isContent('under-maintenance-modal')) {
            if (this.appSettings.CountryCode.toLowerCase() === "no" && this.appSettings.CompanyId === 1) {
                this.bigAl.isUnderMaintenance = false;
            }
            else {
                this.bigAl.isUnderMaintenance = true;
                this.navigationService.openModal('app-under-maintenance');
            }
        }
        else {
            this.bigAl.isUnderMaintenance = false;
        }
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return LoginComponent;
}(AbstractFeature));
export { LoginComponent };
