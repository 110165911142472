import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdminInfo } from '../sharedtypes/interfaces/generic/admininfo.interface';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class AdminInfoService {
  constructor(private apiService: ApiService) { }

  public getApplicationVersion(): Promise<IAdminInfo> {
    const path = "assets/admininfo.json";
    return this.apiService.getFileAsync<IAdminInfo>(path);
  }
}