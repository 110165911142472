import { ApplicationInsights } from '@microsoft/applicationinsights-web';
var MonitoringService = /** @class */ (function () {
    function MonitoringService() {
    }
    // We need to provide an initialiation function here, since we will be loading 
    // the instrumentation key from the default-settings.json and we need to be 
    // able to ensure that the default-settings have been loaded before we actually
    // initialize Application Insights
    MonitoringService.prototype.init = function (instrumentationKey, whoami) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                // Enabling 'enableDebug' will result in dropped telemetry whenever an internal error occurs.
                enableDebug: false,
                // Needed to be able to do correlation (connect services) in Application Insightes 
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                correlationHeaderExcludedDomains: [
                    '*.googleapis.com',
                    '*.google-analytics.com',
                    '*.giosg.com',
                    'login.ald.dk',
                    'login.ald.se',
                    'login.ald.no',
                    'login.nffleet.dk',
                    'login.nffleet.se',
                    'login.nffleet.no'
                ]
            }
        });
        this.appInsights.loadAppInsights();
        // It appears we need to initialize ApplicationInsights after loadAppInsights is called
        var telemetryInitializer = function (envelope) {
            envelope.tags['ai.cloud.role'] = "MyALD." + whoami;
        };
        var filteringFunction = function (envelope) {
            if (envelope.name === "Microsoft.ApplicationInsights.{0}.RemoteDependency") {
                var input = envelope.baseData.target;
                var excludeUrls = [
                    // 3rd party services
                    "https://.*\\.google-analytics\\.com.*",
                    "https://.*\\.giosg.com\\.*",
                    // No asset dependencies
                    "https?://.*/assets/.*",
                ];
                for (var i = 0; i < excludeUrls.length; i++) {
                    if (input.match(RegExp(excludeUrls[i]))) {
                        return false;
                    }
                }
            }
            return true;
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
        this.appInsights.addTelemetryInitializer(filteringFunction);
        this.appInsights.setAuthenticatedUserContext(null);
    };
    MonitoringService.prototype.setAuthenticatedUser = function (user) {
        this.appInsights.setAuthenticatedUserContext(user);
    };
    MonitoringService.prototype.logPageView = function (name, url) {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    };
    MonitoringService.prototype.logEvent = function (name, properties) {
        this.appInsights.trackEvent({ name: name }, properties);
    };
    MonitoringService.prototype.logMetric = function (name, average, properties) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    };
    MonitoringService.prototype.logException = function (exception, severityLevel) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    };
    MonitoringService.prototype.logTrace = function (message, properties) {
        this.appInsights.trackTrace({ message: message }, properties);
    };
    return MonitoringService;
}());
export { MonitoringService };
