<div class="element-container element-container-sm">
  <div class="sub-box" *ngIf="badLink && !passwordSetSuccessfully">
    <app-translate translationName='NewPasswordComponent.nolink_t'>
      <p translate-text>{{'NewPasswordComponent.nolink_t'| translate}}</p>
    </app-translate>
    <button mat-button type="button" (click)="back()">
      <app-translate translationName='FormComponent.Back_t'>
        <span translate-text>{{'FormComponent.Back_t'| translate}}</span>
      </app-translate>
    </button>
  </div>
  <div class="sub-box" *ngIf="!currentPasswordSetCode && !badLink">
    <app-translate translationName='NewPasswordComponent.verifying_t'>
      <p translate-text>{{'NewPasswordComponent.verifying_t'| translate}}</p>
    </app-translate>
    <button mat-button type="button" (click)="back()">
      <app-translate translationName='FormComponent.Back_t'>
        <span translate-text>{{'FormComponent.Back_t'| translate}}</span>
      </app-translate>
    </button>
  </div>
  <div class="sub-box" *ngIf="!passwordSetSuccessfully && formModel && formModel.form && currentPasswordSetCode">
    <app-translate translationName='NewPasswordComponent.title_t'>
      <h1 translate-text>{{'NewPasswordComponent.title_t'| translate}}</h1>
    </app-translate>
    <div class="primary-text">
      <app-translate translationName="NewPasswordComponent.RulesHeader_t">
        <span translate-text>{{ 'NewPasswordComponent.RulesHeader_t' | translate }}</span>
      </app-translate>
    </div>
    <div>
      <ul class="list numbered-list" fxLayout="column">
        <div>
          <h3 fxFlex="40px" class="primary-text">1.</h3>
          <div class="item-text">
            <p>
              <app-translate translationName="NewPasswordComponent.Rules1_t">
                <span translate-text>{{ 'NewPasswordComponent.Rules1_t' | translate}}</span>
              </app-translate>
            </p>
          </div>
        </div>
        <div>
          <h3 fxFlex="40px" class="primary-text">2.</h3>
          <div class="item-text">
            <p>
              <app-translate translationName="NewPasswordComponent.Rules2_t">
                <span translate-text>{{ 'NewPasswordComponent.Rules2_t' | translate}}</span>
              </app-translate>
            </p>
          </div>
        </div>
        <div>
          <h3 fxFlex="40px" class="primary-text">3.</h3>
          <div>
            <p>
              <app-translate translationName="NewPasswordComponent.Rules3_t">
                <span translate-text>{{ 'NewPasswordComponent.Rules3_t' | translate}}</span>
              </app-translate>
            </p>
          </div>
        </div>
        <div>
          <h3 fxFlex="40px" class="primary-text">4.</h3>
          <div class="item-text">
            <p>
              <app-translate translationName="NewPasswordComponent.Rules4_t">
                <span translate-text>{{ 'NewPasswordComponent.Rules4_t' | translate}}</span>
              </app-translate>
            </p>
          </div>
        </div>
        <div>
          <h3 fxFlex="40px" class="primary-text">5.</h3>
          <div>
            <p>
              <app-translate translationName="NewPasswordComponent.Rules5_t">
                <span translate-text>{{ 'NewPasswordComponent.Rules5_t' | translate}}</span>
              </app-translate>
            </p>
          </div>
        </div>
      </ul>
    </div>
    <br>
    <app-form [formObject]="getFormObject()" [ruleObject]="getRuleObject()"></app-form>
  </div>
  <div class="sub-box" *ngIf="passwordSetSuccessfully">
    <button mat-button type="button" (click)="back()">
      <app-translate translationName='NewPasswordComponent.confirmation_t'>
        <p translate-text>{{'NewPasswordComponent.confirmation_t'| translate}}</p>
      </app-translate>
      <app-translate translationName='FormComponent.Back_t'>
        <span translate-text>{{'FormComponent.Back_t'| translate}}</span>
      </app-translate>
    </button>
  </div>
</div>