import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from '../spinner.service';
import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "../spinner.service";
import * as i2 from "../api.service";
var FileUploadService = /** @class */ (function () {
    function FileUploadService(spinnerService, apiService) {
        this.spinnerService = spinnerService;
        this.apiService = apiService;
        this.filePathStream = new BehaviorSubject(null);
        this.uploadError = new BehaviorSubject(false);
        this.errorType = new BehaviorSubject('');
        this.errorCount = 0;
        this.chunkNumber = 0;
        this.totalChunks = 1;
        this.chunkSize = 0;
        this.fileId = '';
        this.sessionId = '';
        this.uploading = false;
        this.allowedFileExtensions = new Set(["jpg", "jpeg", "png", "bmp", "pdf", "docx", "xlsx"]);
        this.uploadedFileIds = [];
    }
    FileUploadService.prototype.getFilePathObservable = function () {
        return this.filePathStream.asObservable();
    };
    FileUploadService.prototype.setFilePathStreamObservable = function (data) {
        this.filePathStream.next(data);
    };
    FileUploadService.prototype.setuploadErrorObservable = function (data) {
        this.uploadError.next(data);
    };
    FileUploadService.prototype.getuploadErrorObservable = function () {
        return this.uploadError.asObservable();
    };
    FileUploadService.prototype.getErrorTypeObservable = function () {
        return this.errorType.asObservable();
    };
    FileUploadService.prototype.setErrorTypeObservable = function (data) {
        this.errorType.next(data);
    };
    FileUploadService.prototype.defaultFileUpload = function (file, input, appSettings) {
        var _this = this;
        if (input) {
            var chunked = false;
            if (this.shouldSendInChunks(file)) {
                chunked = true;
            }
            this.send(chunked, input, function () { return _this.sendChunk("File/Upload", "File/Upload", appSettings, false); });
        }
    };
    FileUploadService.prototype.gloveboxFileUpload = function (file, input, appSettings) {
        var _this = this;
        if (input) {
            var chunked = false;
            if (this.shouldSendInChunks(file)) {
                chunked = true;
            }
            this.send(chunked, input, function () { return _this.sendChunk("file/Glovebox/Upload/Create", "file/Glovebox/Upload/Append/", appSettings, true); });
        }
    };
    FileUploadService.prototype.shouldSendInChunks = function (file) {
        if (file.size < 2 * 1000000) {
            return true;
        }
        return false;
    };
    FileUploadService.prototype.send = function (chunked, input, sendFunction) {
        // Check that we're not already uploading
        if (this.uploading) {
            this.setuploadErrorObservable(true);
            this.setErrorTypeObservable('stillUploading');
            this.uploading = false;
            return;
        }
        // Begin input validation
        if (!input) {
            this.setuploadErrorObservable(true);
            this.setErrorTypeObservable('nofile');
            this.uploading = false;
            return;
        }
        else if (!input.files) {
            this.setuploadErrorObservable(true);
            this.setErrorTypeObservable('noBrowserSupport');
            this.uploading = false;
            return;
        }
        else if (!input.files[0]) {
            this.setuploadErrorObservable(true);
            this.setErrorTypeObservable('selectFile');
            this.uploading = false;
            return;
        }
        // End input validation
        this.uploading = true;
        this.fileId = '001'; // Set the file Id to whatever you need it to be for further processing. Do not reuse Ids within a session under any circumstances. This _will_ break, and you will get errors from the server.
        this.file = input.files[0];
        if (!this.isFileExtensionAllowed(this.file.name)) {
            this.setuploadErrorObservable(true);
            this.setErrorTypeObservable('illegalFileFormat');
            this.uploading = false;
            return;
        }
        // this.sessionId = UUID.UUID();
        // If you don't want to send it in chunks, use this method (or you could just not call slice and create a custom function for that)
        if (!chunked) {
            this.chunkNumber = 1;
            this.totalChunks = 1;
            this.chunkSize = this.file.size;
            sendFunction();
            return;
        }
        // otherwise, decide on a chunk size and calculate number of chunks
        this.chunkSize = 1024 * 1024;
        var totalSize = this.file.size;
        var finalchunksize = totalSize % this.chunkSize;
        var chunkscounter = (totalSize - finalchunksize) / this.chunkSize;
        this.chunkNumber = 1;
        this.totalChunks = chunkscounter + 1;
        sendFunction();
    };
    FileUploadService.prototype.isFinalChunk = function () {
        return this.chunkNumber > this.totalChunks;
    };
    FileUploadService.prototype.nextChunk = function (endpoint, appSettings) {
        if (this.chunkNumber > this.totalChunks) {
            // We're done
            this.uploading = false;
            return;
        }
        // Send the next chunk
        this.sendChunk(endpoint, endpoint, appSettings, false);
    };
    FileUploadService.prototype.sendChunk = function (endpoint, appendEndpoint, appSettings, saveFileId) {
        var _this = this;
        var currentUser = null; // todo: get user from BigAL, we got this from the glovebox service before but that has been removed
        var model;
        if (currentUser) {
            model = {
                Id: "",
                FileName: this.file.name,
                chunkNumber: this.chunkNumber,
                totalChunks: this.totalChunks,
                FileSize: this.file.size,
                ChunkSize: this.chunkSize,
                DriverId: currentUser.UserId
            };
        }
        else {
            model = {
                Id: "",
                FileName: this.file.name,
                chunkNumber: this.chunkNumber,
                totalChunks: this.totalChunks,
                FileSize: this.file.size,
                ChunkSize: this.chunkSize
            };
        }
        var reader = new FileReader();
        var service = this;
        var blob = this.getBlob(this.chunkNumber, this.totalChunks, this.chunkSize, this.file);
        reader.onload = function () {
            _this.sendFileModel(model, reader.result, endpoint, appSettings).then(function (data) {
                var uploadedFile = {
                    FileName: data.FileName,
                    FileToken: data.FileToken
                };
                service.chunkNumber++;
                if (service.isFinalChunk()) {
                    // We're done
                    service.filePathStream.next(uploadedFile);
                    service.uploading = false;
                    service.setuploadErrorObservable(false);
                    service.setErrorTypeObservable('');
                    if (saveFileId) {
                        service.uploadedFileIds.push(data.Id);
                    }
                    service.spinnerService.hide();
                    return;
                }
                // Send the next chunk
                _this.sendChunk(appendEndpoint + data.Id, appendEndpoint, appSettings, saveFileId);
            }).catch(function (err) {
                service.uploading = false;
                service.setuploadErrorObservable(true);
                var value = err.status;
                if (err.error.MessageId) {
                    value = err.error.MessageId;
                }
                service.setErrorTypeObservable(value.toString());
                service.spinnerService.hide();
            });
        };
        reader.readAsDataURL(blob);
    };
    FileUploadService.prototype.sendFileModel = function (model, loadedblob, endpoint, appSettings) {
        model.File = loadedblob;
        this.spinnerService.show();
        return this.apiService.postAsync(endpoint, model, appSettings);
    };
    FileUploadService.prototype.getBlob = function (chunkNumber, totalChunks, chunkSize, file) {
        if (chunkNumber === 1 && totalChunks === 1) {
            // unchunked
            return file.slice(0, file.size);
        }
        var initial = (chunkNumber - 1) * chunkSize;
        var final = initial + chunkSize;
        if (final > file.size) {
            final = file.size;
        }
        var blob = file.slice(initial, final);
        return blob;
    };
    FileUploadService.prototype.isFileExtensionAllowed = function (filename) {
        var nameSplit = filename.split('.');
        if (nameSplit.length < 2) {
            return false;
        }
        return this.allowedFileExtensions.has(nameSplit[(nameSplit.length - 1)].trim().toLowerCase());
    };
    FileUploadService.ngInjectableDef = i0.defineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.inject(i1.SpinnerService), i0.inject(i2.ApiService)); }, token: FileUploadService, providedIn: "root" });
    return FileUploadService;
}());
export { FileUploadService };
