import { FleetReportViewerComponent } from './../../components/features/fleet-admin/fleet-report-viewer/fleet-report-viewer.component';
import { LoadingSpinnerComponent } from './../../components/shared/loading-spinner/loading-spinner.component';
import { DeleteBookingNewComponent } from './../../components/shared/booking-form-new/delete-booking-new/delete-booking-new.component';
import { BookingFormNewComponent } from './../../components/shared/booking-form-new/booking-form-new.component';
import { FleetUserProfileFormComponent } from './../../components/features/fleet-admin/fleet-user-profile-form/fleet-user-profile-form.component';
import { AgmDirectionModule } from 'agm-direction';
import { CarheaderComponent } from './../../components/features/cars/car-header/carheader.component';
import { MapComponent } from './../../components/shared/map/map.component';
import { FieldTranslationComponent } from './../../components/shared/form/field-translation/field-translation.component';
import { FieldPlaceholderTranslateComponent } from './../../components/shared/form/field-placeholder-translate/field-placeholder-translate.component';
import { FieldHintComponent } from './../../components/shared/form/field-hint/field-hint.component';
import { FieldErrorComponent } from './../../components/shared/form/field-error/field-error.component';
import { InputFieldComponent } from './../../components/shared/form/input-field/input-field.component';
import { TileComponent } from './../../components/shared/tile/tile.component';
import { FleetTakeOverPopupComponent } from './../../components/features/fleet-admin/fleet-take-over/fleet-take-over-popup/fleet-take-over-popup.component';
import { FleetTakeOverBarComponent } from './../../components/features/fleet-admin/fleet-take-over/fleet-take-over-bar/fleet-take-over-bar.component';
import { SliderComponent } from './../../components/shared/slider/slider.component';
import { FormComponent } from './../../components/shared/form/form.component';
import { CustomMaterialModule } from './../custom-material.module';
import { TranslateModule } from "@ngx-translate/core";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from '../../components/shared/table/table.component';
import { MatTableModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { PipesModule } from '../pipes/pipes.module';
import { FeatureWarningComponent } from '../../components/shared/feature-warning/feature-warning.component';
import { ModalComponent } from '../../components/shared/modal/modal.component';
import { MaskComponent } from '../../components/shared/mask/mask.component';
import { TranslateComponent } from '../../components/shared/translate/translate.component';
import { ApiDataUnavailableComponent } from '../../components/shared/api-data-unavailable/api-data-unavailable.component';
import { ProfileFormComponent } from '../../components/features/driver/profile-form/profile-form.component';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import { GloveboxPageComponent } from '../../../app/components/pages/glovebox-page/glovebox-page.component';
import { SpinnerComponent } from '../../components/shared/spinner/spinner.component';
import { ConsentComponent } from '../../components/shared/consent/consent.component';
import { LeavePageWarningComponent } from '../../components/shared/leave-page-warning/leave-page-warning.component';
import { CtaxManagementReportMonthComponent } from '../../components/features/business-admin/journal-management/ctax-journal-management/ctax-management-report-month/ctax-management-report-month.component';
import { CtaxManagementReportDayComponent } from '../../components/features/business-admin/journal-management/ctax-journal-management/ctax-management-report-day/ctax-management-report-day.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CustomMaterialModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyASH6yTiLsA-7DBZIRx2BbV68TwpZEEAuw",
      libraries: ["places"]
    }),
    AgmDirectionModule,
    CdkTableModule,
    PipesModule,
    MatTableModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    MapComponent,
    TileComponent,
    SliderComponent,
    TableComponent,
    MaskComponent,
    ModalComponent,
    FormComponent,
    FeatureWarningComponent,
    TranslateComponent,
    ApiDataUnavailableComponent,
    FleetTakeOverBarComponent,
    FleetTakeOverPopupComponent,
    InputFieldComponent,
    FieldErrorComponent,
    FieldHintComponent,
    FieldPlaceholderTranslateComponent,
    FieldTranslationComponent,
    CarheaderComponent,
    ProfileFormComponent,
    GloveboxPageComponent,
    FleetUserProfileFormComponent,
    LoadingSpinnerComponent,
    SpinnerComponent,
    BookingFormNewComponent,
    DeleteBookingNewComponent,
    FleetReportViewerComponent,
    ConsentComponent,
    LeavePageWarningComponent,
    CtaxManagementReportMonthComponent,
    CtaxManagementReportDayComponent
  ],
  exports: [
    MapComponent,
    TileComponent,
    SliderComponent,
    TableComponent,
    MaskComponent,
    FormComponent,
    ModalComponent,
    FeatureWarningComponent,
    TranslateComponent,
    ApiDataUnavailableComponent,
    FleetTakeOverBarComponent,
    FleetTakeOverPopupComponent,
    InputFieldComponent,
    FieldErrorComponent,
    FieldHintComponent,
    FieldPlaceholderTranslateComponent,
    FieldTranslationComponent,
    CarheaderComponent,
    ProfileFormComponent,
    GloveboxPageComponent,
    LoadingSpinnerComponent,
    SpinnerComponent,
    BookingFormNewComponent,
    DeleteBookingNewComponent,
    FleetReportViewerComponent,
    ConsentComponent,
    LeavePageWarningComponent,
    CtaxManagementReportMonthComponent,
    CtaxManagementReportDayComponent
  ],
  providers: [
    SearchFilterPipe,
    FleetUserProfileFormComponent,
    ConsentComponent,
    LeavePageWarningComponent,
  ],
  entryComponents: [
    ConsentComponent,
    LeavePageWarningComponent,
  ]
})
export class SharedModule { }
