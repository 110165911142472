<app-modal size="small">
  <div modal-header>
    <app-translate translationName="UnderMaintenanceComponent.title_t">
      <span translate-text>
        {{ 'UnderMaintenanceComponent.title_t'| translate }}
      </span>
    </app-translate>
  </div>
  <div modal-body>
    <h2 class="primary-text">
      <app-translate translationName="UnderMaintenanceComponent.heading">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.heading'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="UnderMaintenanceComponent.content">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.content'| translate }}
        </span>
      </app-translate>
    </p>
    <br />
    <h2 class="primary-text">
      <app-translate translationName="UnderMaintenanceComponent.heading2">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.heading2'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="UnderMaintenanceComponent.content2">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.content2'| translate }}
        </span>
      </app-translate>
    </p>
    <br />
    <h2 class="primary-text">
      <app-translate translationName="UnderMaintenanceComponent.heading3">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.heading3'| translate }}
        </span>
      </app-translate>
    </h2>
    <p>
      <app-translate translationName="UnderMaintenanceComponent.content3">
        <span translate-text>
          {{ 'UnderMaintenanceComponent.content3'| translate }}
        </span>
      </app-translate>
    </p>
  </div>
</app-modal>