
export namespace Constants {

  export const Event_CurrentInsurance: string = "currentInsurance";
  export const Event_CurrentContacts: string = "currentContacts";
  export const Event_CurrentTireBranch: string = "currentTireBranch";
  export const Event_CurrentTireBookings: string = "currentTireBookings";
  export const Event_CurrentTireBranchTimes: string = "currentTireBranchTimeslots";
  export const Event_CurrentContract: string = "currentContract";
  export const Event_CurrentContracts: string = "currentContracts";
  export const Event_CurrentVehicle: string = "currentVehicle";
  export const Event_CurrentVehicles: string = "currentVehicles";
  export const Event_CurrentFuelCompanies: string = "currentFuelCompanies";
  export const Event_CurrentFuelCards: string = "currentFuelCards";
  export const Event_CurrentDriverCards: string = "currentDriverCards";
  export const Event_CurrentTollTags: string = "currentTollTags";
  export const Event_CurrentChargingTags: string = "currentChargingTags";
  export const Event_CurrentFuelSummary: string = "currentFuelingSummary";

  export const Event_CurrentDriverJournalDaily: string = "currentDriverJournalDaily";
  export const Event_CurrentDriverJournalDailyForDriver: string = "currentDriverJournalDailyForDriver";
  export const Event_CurrentDriverJournalMonthly: string = "currentDriverJournalMonthly";
  export const Event_CurrentDriverJournalMonthlyForDriver: string = "currentDriverJournalMonthlyForDriver";

  export const Event_CurrentDriver: string = "currentDriver";
  export const Event_CurrentFleetUser: string = "currentFleetUser";
  export const Event_CurrentFleetUserBusinessPartners: string = "currentFleetUserBusinessPartners";
  export const Event_CurrentFleetOverview: string = "currentFleetOverview";
  export const Event_CurrentFleetAvailableReports: string = "currentFleetAvailableReports";
  export const Event_CurrentFleetOldReports: string = "currentFleetOldReports";
  export const Event_CurrentBusinessPartnerIdAndReference: string = "currentBusinessPartnerIdAndReference";
  export const Event_CurrentFleetUsers: string = "currentFleetUsers";
  export const Event_CurrentBusinessPartner: string = "currentBusinessPartner";
  export const Event_CurrentJournalDrivers: string = "currentJournalDrivers";
  export const Event_BusinessUsers: string = "currentBusinessUsers";
  export const Event_CurrentJournalUsers: string = "currentJournalUsers";
  export const Event_CurrentJournalUser: string = "currentJournalUser";
  export const Event_CurrentJournalUserContracts: string = "currentJournalUserContracts";
  export const Event_currentFuelRules: string = "currentFuelRules";
  export const Event_currentUser: string = "currentUser"

  export const Event_CurrentWorkshops: string = "currentWorkshops";

  // old tires
  export const Event_CurrentTireCenter: string = "currentTireCenter";

  // Local storage
  export const LocalStorage_Language: string = "language";
  export const LocalStorage_CountryCode: string = "countryCode";
  export const LocalStorage_IsInTakeOverModeKey: string = "isInTakeOverModeKey";

  // the Ctax Accounting year starts at the 16th of feb
  export const CurrentCtaxAccountingYearStartDate: Date = (new Date() < new Date(new Date().getFullYear(), 1, 16)) ? new Date(new Date().getFullYear() - 1, 1, 16) : new Date(new Date().getFullYear(), 1, 16);
  // the DJ Accounting year starts at the 16th of jan
  export const CurrentDJAccountingYearStartDate: Date = (new Date() < new Date(new Date().getFullYear(), 0, 16)) ? new Date(new Date().getFullYear() - 1, 0, 16) : new Date(new Date().getFullYear(), 0, 16);

 
}
