/**
 * MyALD are divided into different Contents that can be active or passive depending if the deployed app shall
 * use the Content or not. We therefore need to divide the settings after Contents so when a user shall updates
 * his/hers settings he/she can only see the ones for the active Contents.
 */
export class UserSettings {
    public ContentSettings: Array<ContentSettings> = new Array<ContentSettings>();
    constructor(
    ) { }
}

/**
 * The Content the settings belongs to
 */
export class ContentSettings {
    public Content: string;
    public Settings: Map<string, string> = new Map<string, string>();
}


