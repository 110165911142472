import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class MonitoringService {
    appInsights: ApplicationInsights;

    // We need to provide an initialiation function here, since we will be loading 
    // the instrumentation key from the default-settings.json and we need to be 
    // able to ensure that the default-settings have been loaded before we actually
    // initialize Application Insights
    init(instrumentationKey: string, whoami: string) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                // Enabling 'enableDebug' will result in dropped telemetry whenever an internal error occurs.
                enableDebug: false,
                // Needed to be able to do correlation (connect services) in Application Insightes 
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                correlationHeaderExcludedDomains: [
                    '*.googleapis.com',
                    '*.google-analytics.com',
                    '*.giosg.com',
                    'login.ald.dk',
                    'login.ald.se',
                    'login.ald.no',
                    'login.nffleet.dk',
                    'login.nffleet.se',
                    'login.nffleet.no'
                ]
            }
        });

        this.appInsights.loadAppInsights();

        // It appears we need to initialize ApplicationInsights after loadAppInsights is called
        const telemetryInitializer = (envelope) => {
            envelope.tags['ai.cloud.role'] = `MyALD.${whoami}`
        }

        const filteringFunction = (envelope) => {
            if (envelope.name === "Microsoft.ApplicationInsights.{0}.RemoteDependency") {
                let input = envelope.baseData.target;
                let excludeUrls = [
                    // 3rd party services
                    "https://.*\\.google-analytics\\.com.*",
                    "https://.*\\.giosg.com\\.*",
                    // No asset dependencies
                    "https?://.*/assets/.*",
                ];
                for (let i = 0; i < excludeUrls.length; i++) {
                    if (input.match(RegExp(excludeUrls[i]))) {
                        return false;
                    }
                }
            }
            return true;
        }

        this.appInsights.addTelemetryInitializer(telemetryInitializer);
        this.appInsights.addTelemetryInitializer(filteringFunction);
        this.appInsights.setAuthenticatedUserContext(null)
    }

    setAuthenticatedUser(user: string) {
        this.appInsights.setAuthenticatedUserContext(user)
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}