import { LocaleUtil } from './../../helpers/locale.util';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { AppDateAdapter } from '../../directives/app-date-adapter';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsStore } from '../../stores/DataStores';


@Injectable()
export class TableService<T> {
    data: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    protected filter;
    filteredData: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    private monthNames: Array<string> = null;
    private dateAdapter: AppDateAdapter;

    constructor(
        protected translateService: TranslateService,
        protected settingsStore: AppSettingsStore
    ) {
        this.dateAdapter = new AppDateAdapter(this.settingsStore);
        this.monthNames = this.dateAdapter.getMonthNames('long', this.translateService.currentLang);
        this.translateService.onLangChange.subscribe((newLang) => {
            this.monthNames = this.dateAdapter.getMonthNames('long', newLang['Lang']);
        });
    }

    getData() {
        return this.filteredData.asObservable();
    }

    setData(data: T[]) {
        this.data.next(data);
        this.filteredData.next(data);
    }


    getValue() {
        return this.data.getValue();
    }

    getFilterValue() {
        return this.filteredData.getValue();
    }


    reset() {
        this.filteredData.next(this.data.value);
        this.filter = null;
    }

    sort(property: string, desc: boolean) {
        let array: T[] = this.filteredData.getValue();
        if (array.length > 1 && array[0][property]) {
            if (desc) {
                array.sort((a, b) => {
                    if (a[property] && b[property]) {
                        return b[property].localeCompare(a[property]);
                    }
                    return;
                });
            }
            else {
                array.sort((a, b) => {
                    if (a[property] && b[property]) {
                        return a[property].localeCompare(b[property]);
                    }
                    return;
                });
            }
            this.filteredData.next(array);
        }
    }

    sortByDate(property: string, desc: boolean) {
        let array: T[] = this.filteredData.getValue();

        let dates = [];

        array.forEach((b, i) => {
            if (b[property] === null || b[property] === '' || b[property] === undefined) {
                array[i]['DateForSort'] = 0;
            }
            else {
                array[i]['DateForSort'] = LocaleUtil.convertToDateEnglish(b[property], this.translateService.currentLang);
            }
            dates.push(b[property] + " " + Date.parse(b['DateForSort']));
        });

        property = 'DateForSort';
        if (array.length > 1 && array[0][property] !== null) {
            if (desc) {
                array.sort(function (a, b) { return b[property] - a[property]; });
            }
            else {
                array.sort(function (a, b) { return a[property] - b[property]; });
            }
            this.filteredData.next(array);
        }
    }
    sortByMonth(property: string, desc: boolean) {
        let array: T[] = this.filteredData.getValue();
        if (array.length > 1 && array[0][property]) {
            array.forEach(b => {
                b['MonthForSort'] = this.monthNames.indexOf(b[property]);
            });
            property = 'MonthForSort';
            if (desc) {
                array.sort((a, b) => b[property] - a[property]);
            }
            else {
                array.sort((a, b) => a[property] - b[property]);
            }
            this.filteredData.next(array);
        }
    }
}


export class TableDataSource<T> extends DataSource<T> {
    constructor(private tableService: TableService<T>) {
        super();
    }
    connect(): Observable<T[]> {
        return this.tableService.getData();
    }
    disconnect() { }
}
