import { Component } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AbstractFeature } from '../../features/feature.abstract';
import { ApiService } from '../../../services/api.service';
import { UserActionSuccess } from '../../../models/userActionSuccess.model';

@Component({
  selector: 'app-api-success',
  templateUrl: './api-success.component.html',
  styleUrls: ['./api-success.component.scss'],
})
export class ApiSuccessComponent extends AbstractFeature {

  public currentSuccess: UserActionSuccess = null;

  constructor(
    private apiService: ApiService,
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.subscribe(this.apiService.getSuccess()
      .subscribe((success: UserActionSuccess) => {
        if (!success) {
          this.currentSuccess = null;
        }
        else {
          this.currentSuccess = success;
        }
      }));
  }

  protected setData(): void {
    return;
  }

  protected getData(): void {
    return;
  }

  public isDataAvailable(): boolean {
    return true;
  }

  closeModal(closing: boolean) {
    if (closing) {
      this.apiService.removeSuccess();
    }
  }
}
