import { Injectable } from "@angular/core";
import { stringify } from "querystring";
import { ApiService } from "./api.service";
import { NavigationService } from "./navigation.service";

@Injectable()
export class FirebaseService {

    private isListening: boolean = false;
    constructor(private apiService: ApiService, private navigationService: NavigationService) {

    }

    private subscribePush(token, appSettings): void {
        this.apiService.postAsync(`push/subscribe`, { Token: token }, appSettings)
            .then(() => {
                localStorage.setItem("pushToken", token);
            });
    }

    private unsubscribePush(pushToken, appSettings): void {
        this.apiService.postAsync(`push/unsubscribe`, { Token: pushToken }, appSettings)
            .then(() => {
                localStorage.removeItem("pushToken");
            });
    }

    private wireupFirebasePush(appSettings): void {
        window['FCM'].getToken().then(token => {
            this.subscribePush(token, appSettings);
        });
    }

    async wireupFirebaseNotification() {
        if (!window["FCMPlugin"] && !window["FCM"]) {
            // Not a phone
            return;
        }

        await window['FCM'].requestPushPermission();

        window['FCM'].onNotification((data: any) => {

            // If its Digital Parking enter here
            if (data && data.gotoparking) {
                this.navigationService.forward("/parking/parking-list/refresh", null);

            }
        }, (msg) => {

        }, (err) => {

        });
    }

    setupFirebasePush(appSettings): void {

        if (appSettings.IsApp) {
            //const pushToken = localStorage.getItem("pushToken");
            //if (!pushToken) {
            // Get a new token
            this.wireupFirebasePush(appSettings);
            //}
        }
    }

    removeFirebasePush(appSettings): void {
        const pushToken = localStorage.getItem("pushToken");
        if (pushToken) {
            // Remove current token
            this.unsubscribePush(pushToken, appSettings);
        }
    }
}
