import { MatIconRegistry } from "@angular/material";
import "hammerjs";
import { DomSanitizer } from "@angular/platform-browser";
var CustomMaterialModule = /** @class */ (function () {
    function CustomMaterialModule(iconRegistry, sanitizer) {
        iconRegistry.addSvgIcon("gps", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/geolocation.svg"));
        iconRegistry.addSvgIcon("parking", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/local_parking-24px.svg"));
        iconRegistry.addSvgIcon("time", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/access_time.svg"));
        iconRegistry.addSvgIcon("bubble", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/bubble.svg"));
        iconRegistry.addSvgIcon("arrow-right", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/arrow-right.svg"));
        iconRegistry.addSvgIcon("compass", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/compass.svg"));
        iconRegistry.addSvgIcon("arrow-left", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/arrow-left.svg"));
        iconRegistry.addSvgIcon("pdf", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/pdf.svg"));
        iconRegistry.addSvgIcon("photo", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/photo.svg"));
        iconRegistry.addSvgIcon("check", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/check.svg"));
        iconRegistry.addSvgIcon("cross", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/cross.svg"));
        iconRegistry.addSvgIcon("block", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/block-md.svg"));
        iconRegistry.addSvgIcon("update", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/update-md-w.svg"));
        iconRegistry.addSvgIcon("calendar-xxl", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/calendar.svg"));
        iconRegistry.addSvgIcon("credit-card", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-credit-card.svg"));
        iconRegistry.addSvgIcon("calendar", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/calendar-md.svg"));
        iconRegistry.addSvgIcon("download", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/download-md.svg"));
        iconRegistry.addSvgIcon("phone", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/call-phone.svg"));
        iconRegistry.addSvgIcon("email", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/envelope.svg"));
        iconRegistry.addSvgIcon("external-link", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/external-link.svg"));
        iconRegistry.addSvgIcon("avatar-circle", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/avatar-circle-md.svg"));
        iconRegistry.addSvgIcon("avatar", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/avatar-md.svg"));
        iconRegistry.addSvgIcon("time", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/history.svg"));
        iconRegistry.addSvgIcon("location", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/location.svg"));
        iconRegistry.addSvgIcon("search", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/loupe.svg"));
        // services icons
        iconRegistry.addSvgIcon("service-carbodyrepair", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-carbodyrepair.svg"));
        iconRegistry.addSvgIcon("service-carreturn", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-carreturn.svg"));
        iconRegistry.addSvgIcon("service-carwindowrepair", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-carwindowrepair.svg"));
        iconRegistry.addSvgIcon("service-electricrecharge", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-electricrecharge.svg"));
        iconRegistry.addSvgIcon("service-fuel", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-fuel.svg"));
        iconRegistry.addSvgIcon("service-insurance", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-insurance.svg"));
        iconRegistry.addSvgIcon("service-maintenance", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-maintenance.svg"));
        iconRegistry.addSvgIcon("service-newcartrial", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-newcartrial.svg"));
        iconRegistry.addSvgIcon("ecofleet", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/ecofleet.svg"));
        iconRegistry.addSvgIcon("bomring", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/bomring.svg"));
        iconRegistry.addSvgIcon("map-directions", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/map-directions.svg"));
        iconRegistry.addSvgIcon("el", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/el.svg"));
        iconRegistry.addSvgIcon("service-parking", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-parking.svg"));
        iconRegistry.addSvgIcon("service-pickup", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-pickup.svg"));
        iconRegistry.addSvgIcon("service-technicalcontrol", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-technicalcontrol.svg"));
        iconRegistry.addSvgIcon("service-tires", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-tires.svg"));
        // left MENU icons
        iconRegistry.addSvgIcon("menu-feed", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-feed.svg"));
        iconRegistry.addSvgIcon("menu-car", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-car.svg"));
        iconRegistry.addSvgIcon("menu-glove-box", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-glove-box.svg"));
        iconRegistry.addSvgIcon("ic_playlist_play", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/ic_playlist_play.svg"));
        iconRegistry.addSvgIcon("services_car", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/services_car.svg"));
        iconRegistry.addSvgIcon("ic_euro_symbol", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/ic_euro_symbol.svg"));
        iconRegistry.addSvgIcon("menu-more", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-more.svg"));
        iconRegistry.addSvgIcon("menu-services", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-services.svg"));
        iconRegistry.addSvgIcon("menu-van", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-van.svg"));
        iconRegistry.addSvgIcon("menu-bike", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/menu-bike.svg"));
        iconRegistry.addSvgIcon("edit", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/edit.svg"));
        iconRegistry.addSvgIcon("logout", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/logout.svg"));
        iconRegistry.addSvgIcon("spinner", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/spinner.svg"));
        iconRegistry.addSvgIcon("upload", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/upload.svg"));
        iconRegistry.addSvgIcon("document", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/document-md.svg"));
        iconRegistry.addSvgIcon("traffic-info", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/notif-traffic-info.svg"));
        iconRegistry.addSvgIcon("arrow-drop-down", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/arrow-drop-down.svg"));
        iconRegistry.addSvgIcon("play-circle", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/play-circle-md.svg"));
        iconRegistry.addSvgIcon("content-copy", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/content-copy.svg"));
        iconRegistry.addSvgIcon("return-trip", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/arrow-up-arrow-down.svg"));
        iconRegistry.addSvgIcon("arrow-down", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/angle-down.svg"));
        iconRegistry.addSvgIcon("admin-list", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/ic-list-admin.svg"));
        iconRegistry.addSvgIcon("admin-directions", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/directions-admin.svg"));
        iconRegistry.addSvgIcon("adminstrator", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/ic-supervisor.svg"));
        iconRegistry.addSvgIcon("delete", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/trash.svg"));
        iconRegistry.addSvgIcon("visibility", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/visibility_md.svg"));
        iconRegistry.addSvgIcon("visibility_off", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/visibility_off_md.svg"));
        iconRegistry.addSvgIcon("chevron_left", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/chevron-left.svg"));
        iconRegistry.addSvgIcon("chevron_right", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/chevron-right.svg"));
        iconRegistry.addSvgIcon("globe", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/globe-md.svg"));
        iconRegistry.addSvgIcon("service-partner", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/service-partner.svg"));
        iconRegistry.addSvgIcon("rating-stars", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/notif-rating.svg"));
        iconRegistry.addSvgIcon("car-ald", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/illustrations/car-ald.svg"));
        iconRegistry.addSvgIcon("logo-ald", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/illustrations/logo-ald.svg"));
        iconRegistry.addSvgIcon("car-nf-fleet", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/illustrations/car-nf-fleet.svg"));
        iconRegistry.addSvgIcon("logo-nf-fleet", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/illustrations/logo-nf-fleet.svg"));
        iconRegistry.addSvgIcon("lock", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/lock-md.svg"));
        iconRegistry.addSvgIcon("circle", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/circle.svg"));
        iconRegistry.addSvgIcon("circle-empty", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/circle-empty.svg"));
        iconRegistry.addSvgIcon("square", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/square.svg"));
        iconRegistry.addSvgIcon("play-triangle", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/play-triangle.svg"));
        iconRegistry.addSvgIcon("work", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/work.svg")),
            iconRegistry.addSvgIcon("perm-identity", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/perm-identity.svg"));
        iconRegistry.addSvgIcon("folder", sanitizer.bypassSecurityTrustResourceUrl("./assets/ald/images/sprites-svg/folder.svg"));
    }
    return CustomMaterialModule;
}());
export { CustomMaterialModule };
