var QueryTypeOnlyDigitsValidator = /** @class */ (function () {
    function QueryTypeOnlyDigitsValidator() {
    }
    QueryTypeOnlyDigitsValidator.prototype.onlyDigits = function () {
        return function (formGroup) {
            var queryControl = formGroup.get('searchFilter');
            var selectedQueryTypControl = formGroup.get('selectedQueryType');
            if (!queryControl || !selectedQueryTypControl) {
                return null;
            }
            if (!selectedQueryTypControl.value) {
                return null;
            }
            if (selectedQueryTypControl.value != 'CustomerId' && selectedQueryTypControl.value != 'CustomerReference' && selectedQueryTypControl.value != 'UserId') {
                return null;
            }
            if (!queryControl.value) {
                return null;
            }
            var pattern = /^\+?(0|[1-9]\d*)$/;
            return pattern.test(queryControl.value) ? null : { 'onlyDigits': true };
        };
    };
    return QueryTypeOnlyDigitsValidator;
}());
export { QueryTypeOnlyDigitsValidator };
