import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class RouteStateService {

    private history: string[] = [];

    constructor(
        private router: Router
    ) { }

    loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                if (this.history.length < 2 || this.history[this.history.length - 2] !== urlAfterRedirects) {
                    this.history = [...this.history, urlAfterRedirects];
                } else {
                    this.history.pop();
                }
            });

    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrl(): string {
      return this.history[this.history.length - 2] || '/cars';
    }
}
