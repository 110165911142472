export class FeatureStructure {
    public Name: string;
    public ShowState: FeatureShowState;
}

export enum FeatureShowState {
    None,
    StartScreen,
    LoggedIn
}
