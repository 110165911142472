import { Component, OnInit, Input } from '@angular/core';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';

@Component({
  selector: 'app-field-hint',
  templateUrl: './field-hint.component.html',
  styleUrls: ['./field-hint.component.scss']
})
export class FieldHintComponent implements OnInit {

  @Input() formObject: FormObject;
  @Input() formModel: any;
  @Input() prop: FormProperty;

  public translationTagType: TranslationTagType = TranslationTagType.Hint;
  constructor(
  ) { }

  ngOnInit() {
  }

}
