import { MenuBusinessUserComponent } from './../../components/menu/menu-business-user/menu-business-user.component';
import { CustomMaterialModule } from './../custom-material.module';
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MenuComponent } from '../../components/menu/menu.component';
import { MenuDriverComponent } from '../../components/menu/menu-driver/menu-driver.component';
import { MenuFleetUserComponent } from '../../components/menu/menu-fleet-user/menu-fleet-user.component';
import { SharedModule } from './shared.module';
import { MenuRecordTripComponent } from '../../components/menu/menu-driver/menu-record-trip/menu-record-trip.component';
import { GenericModule } from './generic.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        CustomMaterialModule,
        SharedModule,
        GenericModule
    ],
    declarations: [
        MenuComponent,
        MenuDriverComponent,
        MenuFleetUserComponent,
        MenuBusinessUserComponent,
        MenuRecordTripComponent
    ],
    exports: [
        MenuComponent,
        MenuDriverComponent,
        MenuFleetUserComponent,
        MenuBusinessUserComponent,
        MenuRecordTripComponent
    ]
})
export class MenuModule { }
