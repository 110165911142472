import { BigAlModule } from "../modules/bigal.module";
import { BehaviorSubject } from "rxjs";
var DatePatternService = /** @class */ (function () {
    function DatePatternService(bigAl) {
        this.bigAl = bigAl;
        this.data = new BehaviorSubject("");
        switch (this.bigAl.appSettings.CountryCode) {
            case 'dk': {
                this.data.next("dd/MM/yyyy");
                break;
            }
            case 'se': {
                this.data.next("yyyy/MM/dd");
                break;
            }
            case 'no': {
                this.data.next("dd/MM/yyyy");
                break;
            }
            default: {
                this.data.next("dd/MM/yyyy");
            }
        }
    }
    DatePatternService.prototype.getData = function () {
        return this.data.asObservable();
    };
    return DatePatternService;
}());
export { DatePatternService };
