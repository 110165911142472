/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/translate/translate.component.ngfactory";
import * as i2 from "../../shared/translate/translate.component";
import * as i3 from "../../../translation/customTranslateService";
import * as i4 from "../../../services/navigation.service";
import * as i5 from "../../../services/modals/modal.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./forgot-login-confirmation.component";
import * as i13 from "../../../modules/bigal.module";
import * as i14 from "../../../services/componentConfig.service";
var styles_ForgotLoginConfirmationComponent = [];
var RenderType_ForgotLoginConfirmationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotLoginConfirmationComponent, data: {} });
export { RenderType_ForgotLoginConfirmationComponent as RenderType_ForgotLoginConfirmationComponent };
export function View_ForgotLoginConfirmationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "element-container element-container-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "app-translate", [["translationName", "ForgotLoginComponent.t_forgot_password_confirmation"]], null, null, null, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(4, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "app-translate", [["translationName", "ForgotLoginComponent.t_forgot_password_confirmation_help"]], null, null, null, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(10, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 6, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(16, 0, null, 0, 4, "app-translate", [["translationName", "FormComponent.Back_t"]], null, null, null, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(17, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(18, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "ForgotLoginComponent.t_forgot_password_confirmation"; _ck(_v, 4, 0, currVal_0); var currVal_2 = "ForgotLoginComponent.t_forgot_password_confirmation_help"; _ck(_v, 10, 0, currVal_2); var currVal_6 = "FormComponent.Back_t"; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("ForgotLoginComponent.t_forgot_password_confirmation")); _ck(_v, 6, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("ForgotLoginComponent.t_forgot_password_confirmation_help")); _ck(_v, 12, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 15).disabled || null); var currVal_5 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_7 = i0.ɵunv(_v, 19, 0, i0.ɵnov(_v, 20).transform("FormComponent.Back_t")); _ck(_v, 19, 0, currVal_7); }); }
export function View_ForgotLoginConfirmationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-forgot-login-confirmation", [], null, null, null, View_ForgotLoginConfirmationComponent_0, RenderType_ForgotLoginConfirmationComponent)), i0.ɵdid(1, 245760, null, 0, i12.ForgotLoginConfirmationComponent, [i13.BigAlModule, i14.ComponentConfigService, i4.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotLoginConfirmationComponentNgFactory = i0.ɵccf("app-forgot-login-confirmation", i12.ForgotLoginConfirmationComponent, View_ForgotLoginConfirmationComponent_Host_0, {}, {}, []);
export { ForgotLoginConfirmationComponentNgFactory as ForgotLoginConfirmationComponentNgFactory };
