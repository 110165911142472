import { OnChanges, SimpleChanges, OnInit } from '@angular/core';
var SpinnerComponent = /** @class */ (function () {
    function SpinnerComponent() {
        this.showLodingIndicator = true;
        this.showLoadingMessage = false;
        this.message = "Data is still being received"; // todo: get message from json file?
        this.threshold = 4000;
    }
    SpinnerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isDoneLoading) {
            this.showLodingIndicator = false;
        }
        else {
            this.timer = setTimeout(function () {
                _this.showLoadingMessage = true;
            }, this.threshold);
        }
    };
    SpinnerComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.isDoneLoading &&
            changes.isDoneLoading.currentValue) {
            this.showLodingIndicator = false;
            this.showLoadingMessage = false;
            clearTimeout(this.timer);
        }
        if (changes.didRequestFail &&
            changes.didRequestFail.currentValue) {
            this.showLodingIndicator = false;
            this.showLoadingMessage = false;
            clearTimeout(this.timer);
        }
        if (changes.startAgain &&
            changes.startAgain.currentValue) {
            this.showLodingIndicator = true;
            this.showLoadingMessage = false;
            this.timer = setTimeout(function () {
                _this.showLoadingMessage = true;
            }, this.threshold);
        }
    };
    return SpinnerComponent;
}());
export { SpinnerComponent };
