import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var InsuranceStore = /** @class */ (function () {
    function InsuranceStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.insuranceForContract$ = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetInsuranceForContract$ = this.insuranceForContract$.asObservable();
    }
    InsuranceStore.prototype.reset = function () {
        this.insuranceForContract$.next({ model: null, isInitial: true });
        this.insurance = null;
    };
    // #region Requests
    InsuranceStore.prototype.getInsuranceForContract = function (vehicleContractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        if (vehicleContractId) {
            // Entry found
            if (this.insurance && !forceReload) {
                this.insuranceForContract$.next({ model: this.insurance, isSuccess: true, isCached: true });
                return;
            }
            // Get entry from database
            this.apiService.getAsync(ApiUrl.InsuranceForContract + "/" + vehicleContractId, this.settingStore.appSettings)
                .then(function (data) {
                _this.insurance = data;
                _this.insuranceForContract$.next({ model: _this.insurance, isSuccess: true });
            })
                .catch(function (err) {
                _this.insurance = null;
                _this.insuranceForContract$.next({ model: _this.insurance, isSuccess: false, error: err });
            });
        }
        else {
            this.insurance = null;
            this.insuranceForContract$.next({ model: this.insurance, isSuccess: false, error: "vehicleContractId is empty" });
        }
    };
    InsuranceStore.ngInjectableDef = i0.defineInjectable({ factory: function InsuranceStore_Factory() { return new InsuranceStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: InsuranceStore, providedIn: "root" });
    return InsuranceStore;
}());
export { InsuranceStore };
