<div class="tab-content-text" *ngIf='isDataAvailable()'>
  <p *ngIf="driver">
    <span>
      <app-translate translationName="FleetTakeOverPopupComponent.admin_contract_nr_t">
        <span translate-text>{{ 'FleetTakeOverPopupComponent.admin_contract_nr_t' | translate }}</span>
      </app-translate>
    </span>
    {{contractId}}
  </p>
  <p *ngIf="driver.MobileNumber">
    <span>
      <app-translate translationName="FleetTakeOverPopupComponent.placeholder_mobile_t">
        <span translate-text>
          {{ 'FleetTakeOverPopupComponent.placeholder_mobile_t' | translate }}
        </span>
      </app-translate>
    </span>
    {{driver.MobileNumber}}
  </p>
  <p *ngIf="driver.Email">
    <span>
      <app-translate translationName="FleetTakeOverPopupComponent.placeholder_email_t">
        <span translate-text>
          {{ 'FleetTakeOverPopupComponent.placeholder_email_t' | translate }}
        </span>
      </app-translate>
    </span>
    {{driver.Email}}
  </p>
  <div *ngIf="driver.Address || driver.City">
    <app-translate translationName="FleetTakeOverPopupComponent.more_address_l">
      <span translate-text>
        {{ 'FleetTakeOverPopupComponent.more_address_l' | translate }}
      </span>
    </app-translate>
    <p *ngIf="driver.Address">
      {{driver.Address}}
    </p>
    <p *ngIf="driver.City">
      {{driver.PostalCode}} {{driver.City}}
    </p>
  </div>
</div>