import { BigAlModule } from '../modules/bigal.module';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class SystemAdminGuard implements CanActivate, OnDestroy {
    private subscription: Subscription;

    constructor(
        private navigationService: NavigationService,
        private bigAl: BigAlModule
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // If the user is logged in, and that user is not a system admin, we redirect to the user's "landing page"
        if (!this.bigAl.getPreviousUserRoles().some(ur => ur === UserRoleType.SystemAdminFleetAdmin || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.BusinessUser || ur === UserRoleType.DevUser_Business || ur === UserRoleType.SuperAdmin)) {
            this.navigationService.forward("dashboard", null);
            return false;
        }

        return true;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
