import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable()
export class PhoneCountryCodeService {

    private countryCodes: Set<string> = new Set<string>(["+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40", "+41", "+43", "+44", "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54", "+55", "+56", "+57", "+58", "+60", "+61", "+62", "+63", "+64", "+65", "+66", "+81", "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95", "+98", "+298", "+299", "+354", "+358"]);

    public getCountryCodes(): Set<string> {
        return this.countryCodes;
    }

    public getDefaultCountryCode(countryCode: string): string {
        switch (countryCode.trim().toLowerCase()) {
            case "dk":
                return "+45";
            case "se":
                return "+46";
            case "no":
                return "+47";
            case "fi":
                return "+358";
            case "en":
                return "+44";
        }
        return "";
    }
}
