import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ModalService<T> {
    data: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    bool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(

    ) {
    }

    getData() {
        return this.data.asObservable();
    }

    setData(data: T[]) {
        this.data.next(data);
    }


    getBool() {
        return this.bool.asObservable();
    }

    setBool(bool: boolean) {
        this.bool.next(bool);
    }

}
