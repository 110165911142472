import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../../services/navigation.service';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { AppUserStore } from '../../../../../stores/DataStores';
import { DriverJournalMonthly } from '../../../../../models/driverjournal/driverJournalMonthly.model';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { FuelRules } from '../../../../../models/enums/fuelrules.enum';

@Component({
  selector: 'app-driverjournal-monthly-update-success',
  templateUrl: './driverjournal-monthly-update-success.component.html',
  styleUrls: ['./driverjournal-monthly-update-success.component.scss'],
  exportAs: "modal"
})
export class DriverjournalMonthlyUpdateSuccessComponent implements OnInit {

  public fuelRule: string = null;
  public driverJournalMonthly: DriverJournalMonthly = null;
  isBusinessUser = false;
  constructor(
    public bigAl: BigAlModule,
    private navigationService: NavigationService,
    private appUserStore: AppUserStore,) { }



  ngOnInit() {
    this.driverJournalMonthly = this.bigAl.currentDriverJournalMonthly;
    this.fuelRule = FuelRules[this.bigAl.currentFuelRules];
    this.isBusinessUser = this.appUserStore.appUser.Roles.some(r => r === UserRoleType.BusinessUser);
  }


}
