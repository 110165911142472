import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { Driver } from "../../models/driver.model";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";


@Injectable({
  providedIn: "root"
})
export class DriverStore {
  private driver: Driver;

  private readonly driver$: BehaviorSubject<IStoreResult<Driver>> = new BehaviorSubject<IStoreResult<Driver>>({ model: null, isInitial: true });
  onGetDriver$ = this.driver$.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  // #region Requests

  getDriver(forceReload: boolean = false): void {
    // this is commented out unitl the store, not big al updates the form to ensure changes shows
    // // Entry found
    // if (this.driver && !forceReload) {
    //   this.driver$.next({ model: this.driver, isSuccess: true, isCached: true });
    //   return;
    // }
    // Get entry from database
    this.apiService.getAsync<Driver>(ApiUrl.Driver, this.settingStore.appSettings)
      .then((data: Driver) => {
        this.driver = data;
        this.driver$.next({ model: data, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.driver = null;
        this.driver$.next({ model: this.driver, isSuccess: false, error: err });
      });
  }

  reset() {
    this.driver$.next({ model: null, isInitial: true });
    this.driver = null;
  }

  getDriverProfile(driverId: string): void {
    this.apiService.getAsync<any>(ApiUrl.Drivers + "/" + driverId, this.settingStore.appSettings)
      .then((data: Driver) => {
        this.driver = data;
        this.driver$.next({ model: data, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.driver = null;
        this.driver$.next({ model: this.driver, isSuccess: false, error: err });
      });
  }

  // #endregion
}
