import { Component, Input } from '@angular/core';
import { AbstractFeature } from '../../../features/feature.abstract';
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from '../../../../translation/customTranslateService';
import { BigAlModule } from '../../../../modules/bigal.module';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { FormObject, FormProperty } from '../../../../models/form-object.model';
import { TranslationTagType } from '../../../../models/enums/translation-tag-type.enum';

@Component({
  selector: 'app-field-placeholder-translate',
  templateUrl: './field-placeholder-translate.component.html',
  styleUrls: ['./field-placeholder-translate.component.scss'],
  providers: [
    TranslatePipe
  ]
})
export class FieldPlaceholderTranslateComponent extends AbstractFeature {

  public isEditing = false;
  public translationTagType: TranslationTagType = TranslationTagType.Placeholder;
  @Input() formObject: FormObject;
  @Input() prop: FormProperty;
  constructor(
    private translateService: CustomTranslateService,
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    this.subscribe(this.translateService.isEditing.subscribe(editing => {
      this.isEditing = editing;
    }));
  }
  protected setData(): void {
    return;
  }
  protected getData(): void {
    return;
  }

  public isDataAvailable(): boolean {
    return true;
  }
}
