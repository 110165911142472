import { BehaviorSubject } from 'rxjs';
var FleetUserService = /** @class */ (function () {
    function FleetUserService() {
        this.isInTakeOverMode = new BehaviorSubject(false);
    }
    FleetUserService.prototype.getIsInTakeOverMode = function () {
        return this.isInTakeOverMode.asObservable();
    };
    FleetUserService.prototype.setIsInTakeOverMode = function (data) {
        this.isInTakeOverMode.next(data);
    };
    return FleetUserService;
}());
export { FleetUserService };
