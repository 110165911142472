import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var DocumentStore = /** @class */ (function () {
    function DocumentStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.documents = null;
        this.documentStore = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetDocuments$ = this.documentStore.asObservable();
    }
    DocumentStore.prototype.reset = function () {
        this.documentStore.next({ model: null, isInitial: true });
        this.documents = null;
    };
    // #region Requests
    DocumentStore.prototype.getDocuments = function (userId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this.documents && !forceReload) {
            this.documentStore.next({ model: this.documents, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync(ApiUrl.GetDocuments + "/" + userId, this.settingStore.appSettings)
            .then(function (data) {
            _this.documents = data;
            _this.documentStore.next({ model: _this.documents, isSuccess: true });
        })
            .catch(function (err) {
            _this.documents = null;
            _this.documentStore.next({ model: _this.documents, isSuccess: false, error: err });
        });
    };
    DocumentStore.ngInjectableDef = i0.defineInjectable({ factory: function DocumentStore_Factory() { return new DocumentStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: DocumentStore, providedIn: "root" });
    return DocumentStore;
}());
export { DocumentStore };
