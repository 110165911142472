var QueryTypeEmailValidator = /** @class */ (function () {
    function QueryTypeEmailValidator() {
    }
    QueryTypeEmailValidator.prototype.properEmailFormat = function () {
        return function (formGroup) {
            var queryControl = formGroup.get('searchFilter');
            var selectedQueryTypControl = formGroup.get('selectedQueryType');
            if (!queryControl || !selectedQueryTypControl) {
                return null;
            }
            if (!selectedQueryTypControl.value) {
                return null;
            }
            if (selectedQueryTypControl.value != 'Email') {
                return null;
            }
            if (!queryControl.value) {
                return null;
            }
            var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(queryControl.value) ? null : { properEmailFormat: true };
        };
    };
    return QueryTypeEmailValidator;
}());
export { QueryTypeEmailValidator };
