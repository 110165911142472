var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
// Common
import { BigAlModule } from '../../../../modules/bigal.module';
import { AbstractFeature } from '../../../../components/features/feature.abstract';
// Services
import { RecordTripService } from '../../../../services/driverjournal/recordtrip.service';
import { ComponentConfigService } from '../../../../services/componentConfig.service';
import { DjTabsService } from '../../../../services/driverjournal/dj-tabs.service';
import { NavigationService } from '../../../../services/navigation.service';
import { BrowserService } from '../../../../services/browser.service';
import { ScreenService } from '../../../../services/screen.service';
var MenuRecordTripComponent = /** @class */ (function (_super) {
    __extends(MenuRecordTripComponent, _super);
    function MenuRecordTripComponent(bigAl, theBen, djTabService, navigationService, browserService, screenService, recordTripService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.djTabService = djTabService;
        _this.navigationService = navigationService;
        _this.browserService = browserService;
        _this.screenService = screenService;
        _this.recordTripService = recordTripService;
        _this.showMenu = false;
        _this.djTab = '/journal';
        _this.isApp = false;
        _this.isApp = _this.bigAl.appSettings.IsApp;
        return _this;
    }
    MenuRecordTripComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    MenuRecordTripComponent.prototype.setData = function () {
    };
    MenuRecordTripComponent.prototype.getData = function () {
    };
    MenuRecordTripComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.subscribe(this.recordTripService.showRecordingTripMenue().subscribe(function (state) {
            _this.showMenu = state;
            if (state) {
                if (_this.screenService.isIOS) {
                    if (_this.$menuHandle && !_this.$menuHandle.classList.contains("_ios")) {
                        _this.$menuHandle.classList.add("_ios");
                    }
                }
            }
        }));
        this.subscribe(this.djTabService.getData().subscribe(function (data) { return _this.djTab = data; }));
    };
    MenuRecordTripComponent.prototype.onDjClick = function () {
        this.navigationService.forward(this.djTab, null);
    };
    MenuRecordTripComponent.prototype.isDataAvailable = function () {
        return true;
    };
    return MenuRecordTripComponent;
}(AbstractFeature));
export { MenuRecordTripComponent };
