import { AbstractFeature } from "./feature.abstract";

export abstract class AbstractModalFeature extends AbstractFeature {

    protected close(value: boolean) {
        if (value) {
            this.action("modal");
        }
    }
}
