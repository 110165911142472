<div *ngIf="currentSuccess">
  <app-modal size="small" (modalClosing)="closeModal($event)">

    <div modal-header>
      <app-translate translationName="{{currentSuccess.GetTitleTranslationTag()}}">
        <span translate-text>{{currentSuccess.GetTitleTranslationTag()|translate}}</span>
      </app-translate>
    </div>
    <div modal-body>
      <app-translate translationName="{{currentSuccess.GetDescriptionTranslationTag()}}">
        <span translate-text>{{currentSuccess.GetDescriptionTranslationTag() |translate}}</span>
      </app-translate>
    </div>
    <!-- <div modal-footer>
      <button mat-raised-button mat-button (click)="closeModal(true)">
        <app-translate translationName="ApiSuccess.Close_t">
          <span translate-text>{{'ApiSuccess.Close_t'|translate}}</span>
        </app-translate>
      </button>
    </div> -->
  </app-modal>
</div>
