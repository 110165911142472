import { BehaviorSubject } from "rxjs";
// Stores
import { AppSettingsStore } from "./app-settings.store";
import { ApiService } from "../../services/api.service";
import { CongestionTaxMonthlyTotal } from "../../models/congestionTaxMonthlyTotal.model";
import { SpinnerService } from "../../services/spinner.service";
import { ApiUrl } from "../../helpers/apiUrls";
import { NavigationService } from "../../services/navigation.service";
import { AppUserStore } from "./app-user.store";
import { UserRoleType } from "../../models/enums/user-role-type.enum";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "./app-user.store";
import * as i3 from "../../services/api.service";
import * as i4 from "../../services/spinner.service";
import * as i5 from "../../services/navigation.service";
var CongestionTaxStore = /** @class */ (function () {
    function CongestionTaxStore(appSettingStore, userSettingsStore, apiService, spinnerService, navigationService) {
        this.appSettingStore = appSettingStore;
        this.userSettingsStore = userSettingsStore;
        this.apiService = apiService;
        this.spinnerService = spinnerService;
        this.navigationService = navigationService;
        this.selectedDayEntry = null;
        this.selectedMonthEntry = null;
        this.congestionTaxDaily = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetcongestionTaxDaily$ = this.congestionTaxDaily.asObservable();
        this.congestionTaxMonthly = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetcongestionTaxMonthly$ = this.congestionTaxMonthly.asObservable();
        this.resetSelectedDates();
    }
    CongestionTaxStore.prototype.reset = function () {
        this._congestionTaxDaily = null;
        this._congestionTaxMonthly = null;
        this.congestionTaxMonthlyEntriesTotal = null;
    };
    CongestionTaxStore.prototype.resetSelectedDates = function () {
        this.dailySelectedMonth = new Date().getMonth();
        this.dailySelectedYear = new Date().getFullYear();
        this.dailySelectedDay = 1;
        this.monthlySelectedYear = new Date().getFullYear();
    };
    /**
     * Fetches all daily passages for the given year and selected month and driver. If it is the current year and the previous selected month
     * is after the current month then the month for the dailies will be the current month
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    CongestionTaxStore.prototype.setDailySelectedYear = function (contractId, year, driverId) {
        if (driverId === void 0) { driverId = null; }
        var currentYear = new Date().getFullYear();
        if (year === currentYear) {
            var currentMonth = new Date().getMonth();
            if (this.dailySelectedMonth > currentMonth) {
                this.dailySelectedMonth = currentMonth;
            }
        }
        this.dailySelectedYear = year;
        this._congestionTaxDaily = null;
        if (driverId == null) {
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
    };
    /**
     * Changed the selected month to the given month and fetches the passages for that month
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    CongestionTaxStore.prototype.setDailySelectedMonth = function (contractId, month, driverId) {
        if (driverId === void 0) { driverId = null; }
        this.dailySelectedMonth = month;
        this._congestionTaxDaily = null;
        if (driverId == null) {
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
    };
    /**
     * Changes the selected year to the given year.
     * Fetches all monthly passages for the given year and driver
     * @param contractId the id of the contract
     * @param year the given year
     * @param driverId the id of the driver, only needed if it is another user than the driver that makes the call
     */
    CongestionTaxStore.prototype.setMonthlySelectedYear = function (contractId, year, driverId) {
        if (driverId === void 0) { driverId = null; }
        this.monthlySelectedYear = year;
        this._congestionTaxMonthly = null;
        if (driverId == null) {
            this.getMonthlyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
        }
    };
    CongestionTaxStore.prototype.getDailyEntry = function () {
        var _this = this;
        var entry = this._congestionTaxDaily.CTaxDailyEntrys.find(function (dayEntry) { return (dayEntry.Day === _this.dailySelectedDay && dayEntry.Month === _this.dailySelectedMonth && dayEntry.Year === _this.dailySelectedYear); });
        return entry;
    };
    CongestionTaxStore.prototype.getDailyCTaxForDriver = function (driverId, contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        if (this._congestionTaxDaily && !forceReload) {
            this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true, isCached: true });
            return;
        }
        this.apiService.getAsync(ApiUrl.CTaxDailyHistory + driverId + "/" + contractId + "/" + (this.dailySelectedMonth + 1) + "/" + this.dailySelectedYear, this.appSettingStore.appSettings)
            .then(function (data) {
            _this.processCtaxDaily(data);
        })
            .catch(function (err) {
            console.warn("Error: ", err);
            _this._congestionTaxDaily = null;
            _this.congestionTaxDailyEntriesTotal = null;
            _this.congestionTaxDaily.next({ model: _this._congestionTaxDaily, isSuccess: false, error: err });
        });
    };
    CongestionTaxStore.prototype.getDailyCTax = function (contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._congestionTaxDaily && !forceReload) {
            this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true, isCached: true });
            return;
        }
        this.apiService.getAsync(ApiUrl.CTaxDailyHistory + contractId + "/" + (this.dailySelectedMonth + 1) + "/" + this.dailySelectedYear, this.appSettingStore.appSettings)
            .then(function (data) {
            _this.processCtaxDaily(data);
        })
            .catch(function (err) {
            console.warn("Error: ", err);
            _this._congestionTaxDaily = null;
            _this.congestionTaxDailyEntriesTotal = null;
            _this.congestionTaxDaily.next({ model: _this._congestionTaxDaily, isSuccess: false, error: err });
        });
    };
    CongestionTaxStore.prototype.processCtaxDaily = function (data) {
        var total = new CongestionTaxMonthlyTotal();
        total.NumberOfPasssages = 0;
        total.PrivateAmount = 0;
        total.TotalAmount = 0;
        total.PrivatePart = 0;
        // show only years after 2018
        var years = [];
        data.SelectableYears.forEach(function (year) {
            if (year >= 2018) {
                years.push(year);
            }
        });
        data.SelectableYears = years;
        data.CTaxDailyEntrys.forEach(function (entry, i) {
            if (entry.Month !== 0 && entry.Year !== 0) {
                entry.Month = entry.Month - 1;
                entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
                entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
                entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
                entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
            }
            else {
                data.MonthlyBrigdeFees.splice(i, 1);
            }
        });
        data.MonthlyBrigdeFees.forEach(function (entry, i) {
            if (entry.Month !== 0 && entry.Year !== 0) {
                entry.Month = entry.Month - 1;
                entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
                entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
                entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
                entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
            }
            else {
                data.MonthlyBrigdeFees.splice(i, 1);
            }
        });
        // showPreviewMode if there are none dailyEntries that have noOfPassages and totalAmount
        data.ShowPreviewMode = false;
        var entryWithInvoiceLines = data.CTaxDailyEntrys.find(function (entry) {
            return entry.NumberOfPasssages !== null && entry.TotalAmount !== null;
        });
        if (data.CTaxDailyEntrys && data.CTaxDailyEntrys.length > 0 && !entryWithInvoiceLines) {
            data.ShowPreviewMode = true;
        }
        this._congestionTaxDaily = data;
        this.congestionTaxDailyEntriesTotal = total;
        this.congestionTaxDaily.next({ model: this._congestionTaxDaily, isSuccess: true });
    };
    CongestionTaxStore.prototype.postDailyCTax = function (contractId, log) {
        var _this = this;
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }
        this.spinnerService.show();
        if (log.IsCreate) {
            this.apiService.postAsync(ApiUrl.CTaxLogDaily, log, this.appSettingStore.appSettings)
                .then(function () {
                _this.doThen(contractId, log.PersonId);
            })
                .catch(function (err) {
                _this.doCatch(err, contractId, log.PersonId);
            });
        }
        else {
            this.apiService.postAsync(ApiUrl.CTaxEditDaily, log, this.appSettingStore.appSettings)
                .then(function () {
                _this.doThen(contractId, log.PersonId);
            })
                .catch(function (err) {
                _this.doCatch(err, contractId, log.PersonId);
            });
        }
    };
    CongestionTaxStore.prototype.getMonthlyCTaxForDriver = function (driverId, contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._congestionTaxMonthly && !forceReload) {
            this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true, isCached: true });
            return;
        }
        this.spinnerService.show();
        this.apiService.getAsync(ApiUrl.CTaxMonth + driverId + "/" + contractId + "/" + this.monthlySelectedYear, this.appSettingStore.appSettings)
            .then(function (data) {
            _this.processCongestionTaxMonthly(data);
            _this.spinnerService.hide();
        })
            .catch(function (err) {
            console.warn("Error: ", err);
            _this._congestionTaxMonthly = null;
            _this.congestionTaxMonthlyEntriesTotal = null;
            _this.congestionTaxMonthly.next({ model: _this._congestionTaxMonthly, isSuccess: false, error: err });
            _this.spinnerService.hide();
        });
    };
    CongestionTaxStore.prototype.getMonthlyCTax = function (contractId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._congestionTaxMonthly && !forceReload) {
            this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true, isCached: true });
            return;
        }
        this.apiService.getAsync(ApiUrl.CTaxMonth + contractId + "/" + this.monthlySelectedYear, this.appSettingStore.appSettings)
            .then(function (data) {
            _this.processCongestionTaxMonthly(data);
        })
            .catch(function (err) {
            console.warn("Error: ", err);
            _this._congestionTaxMonthly = null;
            _this.congestionTaxMonthlyEntriesTotal = null;
            _this.congestionTaxMonthly.next({ model: _this._congestionTaxMonthly, isSuccess: false, error: err });
        });
    };
    CongestionTaxStore.prototype.processCongestionTaxMonthly = function (data) {
        var years = [];
        data.SelectableYears.forEach(function (year) {
            if (year >= 2018) {
                years.push(year);
            }
        });
        data.SelectableYears = years;
        var total = new CongestionTaxMonthlyTotal();
        total.NumberOfPasssages = 0;
        total.PrivateAmount = 0;
        total.TotalAmount = 0;
        total.PrivatePart = 0;
        data.CTaxMonthEntrys.forEach(function (entry) {
            entry.Month = entry.Month - 1;
            entry.NumberOfPasssages !== null ? total.NumberOfPasssages += entry.NumberOfPasssages : total.NumberOfPasssages += 0;
            entry.PrivateAmount !== null ? total.PrivateAmount += entry.PrivateAmount : total.PrivateAmount += 0;
            entry.TotalAmount !== null ? total.TotalAmount += entry.TotalAmount : total.TotalAmount += 0;
            entry.PrivatePart !== null ? total.PrivatePart += entry.PrivatePart : total.PrivatePart += 0;
        });
        this._congestionTaxMonthly = data;
        this.congestionTaxMonthlyEntriesTotal = total;
        this.congestionTaxMonthly.next({ model: this._congestionTaxMonthly, isSuccess: true });
    };
    CongestionTaxStore.prototype.postMonthlyCTax = function (contractId, log) {
        var _this = this;
        console.warn(log);
        log.Month = log.Month + 1;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }
        this.spinnerService.show();
        this.apiService.postAsync(ApiUrl.CTaxMonth, log, this.appSettingStore.appSettings)
            .then(function () {
            _this.reset();
            _this.doThen(contractId, log.PersonId);
        })
            .catch(function (err) {
            _this.doCatch(err, contractId, log.PersonId);
        });
    };
    // create a month log
    CongestionTaxStore.prototype.putMonthlyCTax = function (contractId, log) {
        var _this = this;
        var id = log.ContractId;
        log.Month = log.Month + 1;
        log.ContractId = id;
        if (log.ReportAmount) {
            log.BusinessPassages = 0;
            log.PrivatePassages = 0;
        }
        else {
            log.PrivateAmount = 0;
        }
        this.spinnerService.show();
        this.apiService.putAsync(ApiUrl.CTaxMonth, log, this.appSettingStore.appSettings)
            .then(function () {
            _this.reset();
            _this.doThen(contractId, log.PersonId);
        })
            .catch(function (err) {
            _this.doCatch(err, contractId, log.PersonId);
        });
    };
    CongestionTaxStore.prototype.doThen = function (contractId, driverId) {
        var isDriver = this.userSettingsStore.appUser.Roles.some(function (x) { return x == UserRoleType.Driver; });
        this._congestionTaxMonthly = null;
        this._congestionTaxDaily = null;
        if (isDriver) {
            this.getMonthlyCTax(contractId, true);
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
        this.spinnerService.hide();
    };
    CongestionTaxStore.prototype.doCatch = function (err, contractId, driverId) {
        var isDriver = this.userSettingsStore.appUser.Roles.some(function (x) { return x == UserRoleType.Driver; });
        console.warn("Error: ", err);
        this._congestionTaxMonthly = null;
        this._congestionTaxDaily = null;
        if (isDriver) {
            this.getMonthlyCTax(contractId, true);
            this.getDailyCTax(contractId, true);
        }
        else {
            this.getMonthlyCTaxForDriver(driverId, contractId, true);
            this.getDailyCTaxForDriver(driverId, contractId, true);
        }
        this.spinnerService.hide();
    };
    CongestionTaxStore.ngInjectableDef = i0.defineInjectable({ factory: function CongestionTaxStore_Factory() { return new CongestionTaxStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.AppUserStore), i0.inject(i3.ApiService), i0.inject(i4.SpinnerService), i0.inject(i5.NavigationService)); }, token: CongestionTaxStore, providedIn: "root" });
    return CongestionTaxStore;
}());
export { CongestionTaxStore };
