import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { AppUser } from "../../models/appuser.model";
import { ApiService } from "../../services/api.service";
import { AppSettingsStore } from "./app-settings.store";
import { NavigationService } from "../../services/navigation.service";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/navigation.service";
import * as i3 from "./app-settings.store";
import * as i4 from "../../services/api.service";
var AppUserStore = /** @class */ (function () {
    function AppUserStore(router, navigationService, appSettingStore, apiService) {
        this.router = router;
        this.navigationService = navigationService;
        this.appSettingStore = appSettingStore;
        this.apiService = apiService;
        this.appUser$ = new Subject();
        this.onGetAppUser$ = this.appUser$.asObservable();
        this.appUser = AppUser.GetInitialUser();
        this.appUser$.next({ model: this.appUser, isInitial: true });
    }
    // TODO
    // getAppUser(forceReload: boolean = false): void {
    // }
    /**
     * Updates the settings for an AppUser
     * @param model the IDriverSettings object to update
     * @param authenticationUserId the user id
     */
    AppUserStore.prototype.saveSettings = function (model, authenticationUserId) {
        var _this = this;
        return this.apiService.putAsync("users/settings/" + authenticationUserId, model, this.appSettingStore.appSettings)
            .then(function (settings) {
            if (settings instanceof HttpErrorResponse) {
                _this.appUser.UserSettings = null;
                _this.appUser$.next({ model: _this.appUser, isSuccess: false, error: "Something went wrong updating settings: " + settings.error });
            }
            else {
                _this.appUser.UserSettings = settings;
                _this.appUser$.next({ model: _this.appUser, isSuccess: true });
                if (_this.router.url === '/more/settings') {
                    _this.navigationService.back();
                }
            }
        }).catch(function () {
            _this.appUser.UserSettings = null;
            _this.appUser$.next({ model: _this.appUser, isSuccess: false, error: "Something went wrong updating settings" });
        });
    };
    /**
     * Saves the settings from the UserSettings form
     * @param formModel the keys and values from the form components
     */
    AppUserStore.prototype.saveUserSettings = function (formModel) {
        var userSettings = this.appUser.UserSettings;
        var _loop_1 = function (key) {
            if (userSettings.ContentSettings.find(function (setting) { return setting.Settings[key] !== undefined; })) {
                userSettings.ContentSettings.find(function (setting) { return setting.Settings[key] !== undefined; }).Settings[key] = formModel[key];
            }
        };
        // find the keys in the formModel that exists in the user settings and change the value in the user settings
        // to the form value
        for (var _i = 0, _a = Object.keys(formModel); _i < _a.length; _i++) {
            var key = _a[_i];
            _loop_1(key);
        }
        // save the new user settings
        this.saveSettings(userSettings, this.appUser.AuthenticationUserId);
    };
    AppUserStore.ngInjectableDef = i0.defineInjectable({ factory: function AppUserStore_Factory() { return new AppUserStore(i0.inject(i1.Router), i0.inject(i2.NavigationService), i0.inject(i3.AppSettingsStore), i0.inject(i4.ApiService)); }, token: AppUserStore, providedIn: "root" });
    return AppUserStore;
}());
export { AppUserStore };
