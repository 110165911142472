<app-mask (onMaskClose)="closeModal()"></app-mask>
<div [class]="mainStyles">
  <div class="modal-content">
    <div class="modal-header">
      <div *ngIf="allowClose" class="modal-action-bar">
        <mat-icon class="grey-text icon-xs icon-close-btn" svgIcon="cross" (click)="closeModal()"></mat-icon>
      </div>
      <h1>
        <ng-content select="[modal-header]"></ng-content>
      </h1>
      <ng-content select="[modal-subheader]"></ng-content>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-body]"></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select="[modal-footer]"></ng-content>
    </div>
  </div>
</div>