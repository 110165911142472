import { Component } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { AbstractFeature } from '../../features/feature.abstract';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-forgot-login-confirmation',
  templateUrl: './forgot-login-confirmation.component.html'
})
export class ForgotLoginConfirmationComponent extends AbstractFeature {

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService,
    private navigationService: NavigationService
  ) {
    super(bigAl, theBen);
  }

  protected setEventsToWatch(): void {
    return;
  }
  protected setData(): void {
    return;
  }
  protected getData(): void {
    return;
  }
  public isDataAvailable(): boolean {
    return true;
  }

  back() {
    this.navigationService.forward("login", null);
  }
}
