var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
// Third-party
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.cachedRequests = [];
        this.errors = new Subject();
        this.successes = new Subject();
    }
    // Takes the failed request and puts it in the request cache such that it can be retried at a later time if relevant.
    ApiService.prototype.collectFailedRequest = function (request) {
        this.cachedRequests.push(request);
    };
    // #region CRUD operations
    ApiService.prototype.getAsync = function (path, settings) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpRequestAsync("get", path, null, settings)];
            });
        });
    };
    ApiService.prototype.postAsync = function (path, model, settings) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpRequestAsync("post", path, model, settings)];
            });
        });
    };
    ApiService.prototype.putAsync = function (path, model, settings) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpRequestAsync("put", path, model, settings)];
            });
        });
    };
    ApiService.prototype.patchAsync = function (path, model, settings) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpRequestAsync("patch", path, model, settings)];
            });
        });
    };
    ApiService.prototype.deleteAsync = function (path, model, settings) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.httpRequestAsync("delete", path, model, settings)];
            });
        });
    };
    ApiService.prototype.getFile = function (path, settings) {
        return __awaiter(this, void 0, void 0, function () {
            var apiUrl, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apiUrl = settings.ApiBaseUrl + path;
                        return [4 /*yield*/, this.http.get(apiUrl, { responseType: 'blob', observe: 'response' }).subscribe(function (response) {
                                var blob = new Blob([response.body], { type: 'application/octet-stream' });
                                var contentDisposition = response.headers.get('content-disposition');
                                var filename = "";
                                // From the Content-Disposition reponse header, we retrieve the filename
                                if (contentDisposition) {
                                    var split = contentDisposition.split(";");
                                    if (split.length >= 2) {
                                        // We need to sanitize the value, so first we split the value from the key, and then we remove all quotes inside the string to avoid issues when saving the file.
                                        filename = split[1].split("=")[1].replace(new RegExp('\"', 'g'), '');
                                    }
                                }
                                saveAs(blob, filename);
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ApiService.prototype.getFileAsync = function (location) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(location)
                            .toPromise()
                            .then(function (data) {
                            return data;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // #endregion
    // #region Error handling
    ApiService.prototype.addError = function (error) {
        this.errors.next(error);
    };
    ApiService.prototype.removeError = function () {
        this.errors.next(null);
    };
    ApiService.prototype.getError = function () {
        return this.errors.asObservable();
    };
    ApiService.prototype.addSuccess = function (success) {
        this.successes.next(success);
    };
    ApiService.prototype.removeSuccess = function () {
        this.successes.next(null);
    };
    ApiService.prototype.getSuccess = function () {
        return this.successes.asObservable();
    };
    // #endregion
    // #region Helpers
    ApiService.prototype.httpRequestAsync = function (method, path, model, settings) {
        return __awaiter(this, void 0, void 0, function () {
            var apiUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apiUrl = settings.ApiBaseUrl + path;
                        return [4 /*yield*/, this.getHttpMethod(method, apiUrl, model).pipe(map(function (response) {
                                if (response.Token) {
                                    localStorage.setItem('api_token', response.Token);
                                }
                                return response.Data;
                            }))
                                .toPromise()
                                .then(function (data) {
                                return data;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApiService.prototype.getHttpMethod = function (method, apiUrl, model) {
        switch (method) {
            case "delete":
                return this.http.delete(apiUrl);
            case "post":
                return this.http.post(apiUrl, model);
            case "put":
                return this.http.put(apiUrl, model);
            case "patch":
                return this.http.patch(apiUrl, model);
            case "get":
                return this.http.get(apiUrl);
        }
    };
    return ApiService;
}());
export { ApiService };
