<app-modal>
  <div modal-header>
    Update component configurations
  </div>
  <div modal-body>
    <div class="component-configs">
      <form [formGroup]="componentConfigForm">
        <div formArrayName="componentConfigFormArray">
          <br>
          <br>
          <div class="list-item" *ngFor="let component of getComponentConfigFormArray(componentConfigForm); let componentConfigIndex=index"
            [formGroupName]="componentConfigIndex">
            <span>
              <input *ngIf="componentConfigIndex >= minIndex" readonly type="text" class="component-config-title" formControlName="componentTitle">
              <input *ngIf="minIndex > componentConfigIndex" type="text" class="component-config-title" formControlName="componentTitle">
              <br>
            </span>
            <div>
              <div fxFlex>
                <h4>Content:</h4>
                <div formArrayName="componentConfigContentFormArray">
                  <div [formGroupName]="contentIndex" class="list-item" *ngFor="let content of getComponentConfigContentFormArray(component); let contentIndex=index">
                    <div class="component-content">
                      <input type="checkbox" formControlName="componentContentCheckbox">
                      <input *ngIf="componentContentMaxIndex[componentConfigIndex] >= contentIndex" readonly type="text" formControlName="componentContent">
                      <input *ngIf="contentIndex > componentContentMaxIndex[componentConfigIndex]" type="text" formControlName="componentContent">
                      <br>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex>
                <h4>Actions:</h4>
                <div formArrayName="componentConfigActionFormArray">
                  <div [formGroupName]="actionIndex" class="list-item" *ngFor="let action of getComponentConfigActionFormArray(component); let actionIndex=index">
                    <div class="component-content">
                      <input *ngIf="componentActionMaxIndex[componentConfigIndex] >= actionIndex" readonly type="text" formControlName="componentAction">
                      <input *ngIf="actionIndex > componentActionMaxIndex[componentConfigIndex]" type="text" formControlName="componentAction">
                      <div>
                        <input type="text" formControlName="componentActionNext">


                        <select formControlName="componentActionType">
                          <option *ngFor="let option of actionTypeStringValues; let optionIndex=index" value="{{actionTypeIntValues[optionIndex]}}">
                            {{option}}
                          </option>
                        </select>


                      </div>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr>

          </div>

          <br>
        </div>

        <br>
        <div>
          <button mat-raised-button (click)="action()" type="button">
            Cancel
          </button>
          <button mat-raised-button color="primary" [disabled]="componentConfigForm.invalid" (click)="saveComponents(); action()">
            Ok
          </button>
        </div>
      </form>
    </div>
  </div>
  <div modal-footer>

  </div>
</app-modal>