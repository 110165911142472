var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PhoneCountryCodeService } from './../../../services/phone-country-code.service';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FormBuilder } from '@angular/forms';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { Form } from '../../../models/form.model';
import { TranslatePipe } from '@ngx-translate/core';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { AbstractFeature } from '../../features/feature.abstract';
import { NavigationService } from '../../../services/navigation.service';
var ɵ0 = APP_DATE_FORMATS;
var ForgotLoginComponent = /** @class */ (function (_super) {
    __extends(ForgotLoginComponent, _super);
    function ForgotLoginComponent(translatePipe, bigAl, theBen, fb, validatorFactory, fileUploadService, navigationService, translateService, phoneCountryCodeService) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.translatePipe = translatePipe;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.fb = fb;
        _this.validatorFactory = validatorFactory;
        _this.fileUploadService = fileUploadService;
        _this.navigationService = navigationService;
        _this.translateService = translateService;
        _this.phoneCountryCodeService = phoneCountryCodeService;
        _this.hideExtraForgotPasswordFields = true;
        _this.formSuccess = false;
        _this.formModel = new Form(_this.getFormObject(), _this.getRuleObject(), _this.translatePipe, _this.bigAl, _this.theBen, _this.fb, _this.validatorFactory, _this.fileUploadService, _this.translateService, _this.phoneCountryCodeService);
        return _this;
    }
    ForgotLoginComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("resetPasswordResult");
        return;
    };
    ForgotLoginComponent.prototype.setData = function () {
        // if (this.bigAl.currentResetPasswordResult === true) {
        //   this.formSuccess = true;
        // }
        // else if (this.bigAl.currentResetPasswordResult === false) {
        //   this.formSuccess = false;
        // }
        return;
    };
    ForgotLoginComponent.prototype.getData = function () {
        return;
    };
    ForgotLoginComponent.prototype.isDataAvailable = function () {
        return true;
    };
    ForgotLoginComponent.prototype.getFormObject = function () {
        return _super.prototype.getFormObject.call(this, "ForgotLoginComponent", new Map());
    };
    ForgotLoginComponent.prototype.getRuleObject = function () {
        return _super.prototype.getRuleObject.call(this, "ForgotLoginComponent");
    };
    ForgotLoginComponent.prototype.back = function () {
        // this.bigAl.currentResetPasswordResult = undefined;
        this.navigationService.forward("login", null);
    };
    return ForgotLoginComponent;
}(AbstractFeature));
export { ForgotLoginComponent };
export { ɵ0 };
