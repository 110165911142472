import { FormGroup, ValidatorFn } from "@angular/forms";

export class QueryTypeEmailValidator {
    public properEmailFormat(): ValidatorFn {
        return (formGroup: FormGroup) => {
            const queryControl = formGroup.get('searchFilter');
            const selectedQueryTypControl = formGroup.get('selectedQueryType');
            if (!queryControl || !selectedQueryTypControl) {
                return null;
            }

            if (!selectedQueryTypControl.value) {
                return null;
            }


            if (selectedQueryTypControl.value != 'Email') {
                return null;
            }

            if (!queryControl.value) {
                return null;
            }


            let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return pattern.test(queryControl.value) ? null : { properEmailFormat: true };


        };
    }
}