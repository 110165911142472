import { BigAlModule } from '../../modules/bigal.module';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractFeature, PrefilledContentEntry } from '../features/feature.abstract';
import { ComponentConfigService } from '../../services/componentConfig.service';
import { NavigationService } from '../../services/navigation.service';
import { AppSettings } from '../../models/app-settings.model';
import { AppSettingsStore } from '../../stores/DataStores';

@Component({
    selector: "login-component",
    templateUrl: "./login.component.html",
})
export class LoginComponent extends AbstractFeature implements OnInit, OnDestroy {
    public hide = true;
    public appSettings: AppSettings;

    constructor(
        private navigationService: NavigationService,
        public bigAl: BigAlModule,
        protected theBen: ComponentConfigService,
        private settingsStore: AppSettingsStore
    ) {
        super(bigAl, theBen);
        this.appSettings = this.settingsStore.appSettings;
    }

    protected setEventsToWatch(): void {
        return;
    }
    protected setData(): void {
        return;
    }
    protected getData(): void {
        return;
    }
    public isDataAvailable(): boolean {
        return true;
    }

    public getFormObject() {
        return super.getFormObject("UserLogin", new Map<string, PrefilledContentEntry[]>());
    }

    public getRuleObject() {
        return super.getRuleObject("UserLogin");
    }

    public forgotLogin() {
        this.navigationService.forward("forgot-login", null);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.isContent('under-maintenance-modal')) {
            if (this.appSettings.CountryCode.toLowerCase() === "no" && this.appSettings.CompanyId === 1) {
                this.bigAl.isUnderMaintenance = false;
            } else {
                this.bigAl.isUnderMaintenance = true;
                this.navigationService.openModal('app-under-maintenance');
            }
        } else {
            this.bigAl.isUnderMaintenance = false;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
