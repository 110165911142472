/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./field-translation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../translate/translate.component.ngfactory";
import * as i3 from "../../translate/translate.component";
import * as i4 from "../../../../translation/customTranslateService";
import * as i5 from "../../../../services/navigation.service";
import * as i6 from "../../../../services/modals/modal.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./field-translation.component";
var styles_FieldTranslationComponent = [i0.styles];
var RenderType_FieldTranslationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FieldTranslationComponent, data: {} });
export { RenderType_FieldTranslationComponent as RenderType_FieldTranslationComponent };
export function View_FieldTranslationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-translate", [], null, null, null, i2.View_TranslateComponent_0, i2.RenderType_TranslateComponent)), i1.ɵdid(1, 245760, null, 0, i3.TranslateComponent, [i4.CustomTranslateService, i5.NavigationService, i6.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getTranslationTag(), ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.getTranslationTag())); _ck(_v, 3, 0, currVal_1); }); }
export function View_FieldTranslationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-translation", [], null, null, null, View_FieldTranslationComponent_0, RenderType_FieldTranslationComponent)), i1.ɵdid(1, 114688, null, 0, i8.FieldTranslationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldTranslationComponentNgFactory = i1.ɵccf("app-field-translation", i8.FieldTranslationComponent, View_FieldTranslationComponent_Host_0, { translationTagType: "translationTagType", formObject: "formObject", prop: "prop", rule: "rule" }, {}, []);
export { FieldTranslationComponentNgFactory as FieldTranslationComponentNgFactory };
