var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from '../../modules/bigal.module';
import { AbstractFeature } from '../features/feature.abstract';
import { ComponentConfigService } from '../../services/componentConfig.service';
var CarSpinnerComponent = /** @class */ (function (_super) {
    __extends(CarSpinnerComponent, _super);
    function CarSpinnerComponent(bigAl, theBen) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.bigAl = bigAl;
        _this.theBen = theBen;
        _this.showSpinner = false;
        _this.companyName = "";
        _this.carLogo = "";
        _this.logo = "";
        return _this;
    }
    CarSpinnerComponent.prototype.setEventsToWatch = function () {
        this.watchEvent("init");
    };
    CarSpinnerComponent.prototype.setData = function () {
        if (this.bigAl.appSettings) {
            this.showSpinner = true;
            switch (this.bigAl.appSettings.CompanyId) {
                case 1:
                    this.companyName = "My ALD";
                    this.carLogo = "car-ald";
                    this.logo = "logo-ald";
                    break;
                case 2:
                    this.companyName = "My NF Fleet";
                    this.carLogo = "car-nf-fleet";
                    this.logo = "logo-nf-fleet";
                    break;
                default:
                    this.companyName = "";
                    this.carLogo = "";
                    this.logo = "";
            }
        }
    };
    CarSpinnerComponent.prototype.getData = function () {
    };
    CarSpinnerComponent.prototype.isDataAvailable = function () {
        return true;
    };
    return CarSpinnerComponent;
}(AbstractFeature));
export { CarSpinnerComponent };
