import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import { TranslateService } from '@ngx-translate/core';
import { GloveboxDocument } from '../../models/glovebox/glovebox-document.model';
import { GloveboxEntry } from '../../models/glovebox/glovebox-entry.model';
import { AppSettingsStore } from '../../stores/DataStores';

@Injectable()
export class GloveboxTableService extends TableService<GloveboxEntry> {


    constructor(
        private searchPipe: SearchFilterPipe,
        protected translateService: TranslateService,
        protected settingsStore: AppSettingsStore
    ) {
        super(translateService, settingsStore);
    }

    public setSubset(filter: string) {
        let subset: (GloveboxEntry)[] = [];
        if (filter) {
            filter = filter.toLowerCase().trim();
            for (let i = 0; i < this.data.value.length; i++) {
                let element = this.data.value[i];
                // if (this.matchesFilter(user, "Username", filter) || this.matchesFilter(user, "Email", filter) || this.matchesFilter(user, "RegistrationNumber", filter) || this.matchesFilter(user, "Name", filter)) {
                subset.push(element);
                // }
            }
        }
        else {
            subset = this.data.value;
        }
        this.filteredData.next(this.searchPipe.transform(subset, filter));
    }
    private matchesFilter(document: GloveboxDocument, propertyName: string, filter: string): boolean {
        return document[propertyName] !== null && document[propertyName].toLowerCase().trim().includes(filter);
    }
}
