import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DriverJournalDailyLog } from '../../models/driverjournal/driverJournalDailyLog.model';
import { DriverJournalMonthlyLog } from '../../models/driverjournal/driverJournalMonthlyLog.model';
import { DriverJournalDailyLogDelete } from '../../models/driverjournal/driverJournalDailyLogDelete.model';

/**
 * Its use is to keep the DriverjournalMonthlyUpdateComponent and DriverjournalDailyUpdateComponent
 * in sync
 */
@Injectable()
export class BusinessTripService {
    private businessTripKmCount = new Map<number, Map<number, BehaviorSubject<boolean>>>();
    private oldValue = 0;
    constructor() {
        this.businessTripKmCount[0] = new Map<number, BehaviorSubject<boolean>>();
        this.businessTripKmCount[1] = new Map<number, BehaviorSubject<boolean>>();
        for (let monthCount = 0; monthCount < 12; monthCount++) {
            this.businessTripKmCount[0][monthCount] = new BehaviorSubject<boolean>(false);
            this.businessTripKmCount[1][monthCount] = new BehaviorSubject<boolean>(false);
        }
        this.oldValue = 0;
    }

    public resetBusinessTripKmCountForMonth(monthlyLog: DriverJournalMonthlyLog) {
        let month = this.getMonthCountForMonthlyLog(monthlyLog);
        this.businessTripKmCount[0][month].next(false);
        this.businessTripKmCount[1][month].next(false);
        this.oldValue = 0;
    }

    public resetCurrentBusinessTrip() {
        this.oldValue = 0;
    }
    public currentBusinessTrip(currentBusinessTrip: DriverJournalDailyLog) {
        if (!currentBusinessTrip) {
            return;
        }
        else {
            this.oldValue = currentBusinessTrip.Distance;
        }
    }

    public businessTripUpdate(currentBusinessTrip: DriverJournalDailyLog) {
        let monthCount = this.getMonthCountForDailyLog(currentBusinessTrip);
        let isExtraCar = this.getIsExtraCar(currentBusinessTrip.IsExtraCarSelected);
        let newValue = currentBusinessTrip.Distance;
        let oldValue = this.oldValue;
        if (oldValue !== newValue) {
            this.businessTripKmCount[isExtraCar][monthCount].next(true);
        }
        else {
            this.businessTripKmCount[isExtraCar][monthCount].next(false);
        }
    }

    public businessTripDelete(currentBusinessTrip: DriverJournalDailyLogDelete) {
        let monthCount = this.getMonthCountForDailyLog(currentBusinessTrip);
        let isExtraCar = this.getIsExtraCar(currentBusinessTrip.IsExtraCar);
        this.businessTripKmCount[isExtraCar][monthCount].next(true);
    }

    public getObservableforPrimaryCar(monthlyLog: DriverJournalMonthlyLog): Observable<boolean> {
        return this.businessTripKmCount[0][this.getMonthCountForMonthlyLog(monthlyLog)].asObservable();
    }

    public getObservableForExtraCar(monthlyLog: DriverJournalMonthlyLog): Observable<boolean> {
        return this.businessTripKmCount[1][this.getMonthCountForMonthlyLog(monthlyLog)].asObservable();
    }

    private getMonthCountForDailyLog(dailyLog: DriverJournalDailyLog | DriverJournalDailyLogDelete) {
        return new Date(dailyLog.Date).getMonth();
    }
    private getMonthCountForMonthlyLog(monthlyLog: DriverJournalMonthlyLog) {
        return monthlyLog.Month;
    }

    private getIsExtraCar(isExtraCarSelected: any): number {
        let isExtraCar = (isExtraCarSelected === "true" || isExtraCarSelected === true) ? 1 : 0;
        return isExtraCar;
    }
}
