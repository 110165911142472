<app-modal>
  <div modal-header>
    <h1 class="mat-headline" *ngIf="currentAppointmentPlace">
      <app-translate translationName="{{currentAppointmentPlace.ModelName + '.header_t'}}">
        <span translate-text>
          {{ currentAppointmentPlace.ModelName + '.header_t' | translate }}
        </span>
      </app-translate>
    </h1>
  </div>
  <div modal-body>
    <div class="sub-box" *ngIf="currentAppointmentPlace && driver">
      <h2>
        <app-translate translationName="{{currentAppointmentPlace.ModelName + '.sub_header_t'}}">
          <span translate-text>
            {{ currentAppointmentPlace.ModelName + '.sub_header_t' | translate }}
          </span>
        </app-translate>
      </h2>
      <p>
        {{ currentAppointmentPlace.Name }}
        <br> {{ currentAppointmentPlace.Street }}
        <br> {{ currentAppointmentPlace.PostalCodeAndCity }}
        <br>
        <a *ngIf="currentAppointmentPlace.Telephone" [href]="'tel:'+currentAppointmentPlace.Telephone">
          <button class="font-numbers" mat-raised-button color="primary" type="button">
            <mat-icon class="icon-btn icon-md" svgIcon="phone"></mat-icon>
            {{ currentAppointmentPlace.Telephone }}
          </button>
        </a>
      </p>
    </div>
    <form [formGroup]="domainForm">
      <div class="border-full-blue">
        <h2>
          <app-translate translationName="BookingForm.dates_from_t">
            <span translate-text>
              {{ 'BookingForm.dates_from_t' | translate }}
            </span>
          </app-translate>
        </h2>
        <div class="form-group">
          <mat-form-field>
            <input matInput readonly formControlName="date" [matDatepicker]="picker"
              min="{{ today |  date:'yyyy-MM-dd'}}" placeholder="{{ 'BookingForm.pick_date_p' | translate}}">
            <div *ngIf="isEditing">
              <app-translate translationName="BookingForm.pick_date_p">
                <span translate-text>{{ 'BookingForm.pick_date_p' | translate}}</span>
              </app-translate>
            </div>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-hint align="end"></mat-hint>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group">
          <div *ngFor="let timeslot of availableTimes; let j = index">
            <p class="form-group-title grey-text">
              <app-translate translationName="BookingForm.times_for_t">
                <span translate-text>
                  {{ 'BookingForm.times_for_t' | translate }}
                </span>
              </app-translate>
              {{timeslot.AvailableBookingDate | date:'fullDate' : '' : currentLanguage}}
            </p>
            <div class="chip-radio-buttons">
              <span class="input-group" *ngFor="let time of timeslot.AvailableBookingTimes; let i = index">
                <input type='radio' [value]='time' [id]='j*100+i' formControlName="selectedDateTime" />
                <label class="font-numbers" [for]='j*100+i'> {{ time | date:'HH:mm' }}</label>
              </span>
            </div>
          </div>
          <mat-error>
            <div
              *ngIf="this.domainForm.controls['selectedDateTime'].touched && this.domainForm.controls['selectedDateTime'].hasError('required')">
              <app-translate translationName='BookingForm.selectedDateTime_required_e'>
                <span translate-text>
                  {{'BookingForm.selectedDateTime_required_e' | translate}}
                </span>
              </app-translate>
            </div>
          </mat-error>
        </div>
        <button mat-raised-button color="primary" type="button" (click)="onLaterClick()">
          <app-translate translationName="BookingForm.later_times_t">
            <span translate-text>
              {{ 'BookingForm.later_times_t' | translate }}
            </span>
          </app-translate>
        </button>
        <br>
      </div>
      <br>
      <h2 class="no-margin no-padding">
        <app-translate translationName="BookingForm.summary_t">
          <span translate-text>
            {{ 'BookingForm.summary_t' | translate }}
          </span>
        </app-translate>
      </h2>
      <p *ngIf="currentSelectedTimeslot">
        <app-translate translationName="BookingForm.have_choosen_t">
          <span translate-text>
            {{ 'BookingForm.have_choosen_t' | translate }}
          </span>
        </app-translate>
        {{ currentSelectedTimeslot | localizedDate: "EEEE, d MMMM, y ' - ' HH:mm" }}
      </p>
      <p *ngIf="!currentSelectedTimeslot">
        <app-translate translationName="BookingForm.no_time_t">
          <span translate-text>
            {{ 'BookingForm.no_time_t' | translate }}
          </span>
        </app-translate>
      </p>
      <br>
      <br>
      <div *ngIf="currentAppointmentPlace">
        {{currentAppointmentPlace.Name}}
        <app-translate translationName="BookingForm.phone_for_reminder_t">
          <span translate-text>
            {{ 'BookingForm.phone_for_reminder_t' | translate }}
          </span>
        </app-translate>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input matInput formControlName="mobilePhoneNumber"
            placeholder="{{ 'BookingForm.phone_for_reminder_p'| translate}}">
          <div *ngIf="isEditing">
            <app-translate translationName="BookingForm.phone_for_reminder_p">
              <span translate-text>{{ 'BookingForm.phone_for_reminder_p'| translate}}</span>
            </app-translate>
          </div>
          <mat-hint align="end">
            <app-translate translationName='BookingForm.phone_for_reminder_h'>
              <span translate-text>{{'BookingForm.phone_for_reminder_h' | translate}}</span>
            </app-translate>
          </mat-hint>
          <mat-error>
            <div *ngIf="this.domainForm.controls['mobilePhoneNumber'].hasError('required')">
              <app-translate translationName='BookingForm.mobilePhoneNumber_required_e'>
                <span translate-text>
                  {{'BookingForm.mobilePhoneNumber_required_e' | translate}}
                </span>
              </app-translate>
            </div>
          </mat-error>
          <mat-error>
            <div *ngIf="this.domainForm.controls['mobilePhoneNumber'].hasError('phone')">
              <app-translate translationName='BookingForm.mobilePhoneNumber_phone_e'>
                <span translate-text>
                  {{'BookingForm.mobilePhoneNumber_phone_e' | translate}}
                </span>
              </app-translate>
            </div>
          </mat-error>
        </mat-form-field>

      </div>
      <div fxLayoutGap="40px">
        <button mat-raised-button color="warn" type="button" (click)="action('modal')">
          <app-translate translationName="BookingForm.cancel_t">
            <span translate-text>
              {{ 'BookingForm.cancel_t' | translate }}
            </span>
          </app-translate>
        </button>
        <button mat-raised-button color="primary" type="submit" (click)="onFormSubmit()">
          <app-translate translationName="BookingForm.book_t">
            <span translate-text>
              {{ 'BookingForm.book_t' | translate }}
            </span>
          </app-translate>
        </button>
      </div>
    </form>
  </div>
  <div modal-footer>


  </div>
</app-modal>