export class Translation {
    constructor(
        public TranslationName: string,
        public TranslationValue: string,
        public LanguageCode: string,
        public CompanyId: number,
        public Language: string = ""
    ) { }
}

