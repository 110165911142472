import { TripTypeEnum } from "../enums/TripType.enum";
var DriverJournalDailyLog = /** @class */ (function () {
    function DriverJournalDailyLog(PersonId, TripId, DataSource, ContractId, RegistrationNumber, Date, IsExtraCarSelected, Start, End, Purpose, Distance, IsCreate, CarDescription, TripType, Trip, VinliGUID, BusinessKM, Route, PrivatePassages, BusinessPassages) {
        if (PersonId === void 0) { PersonId = 0; }
        if (TripId === void 0) { TripId = null; }
        if (DataSource === void 0) { DataSource = -1; }
        if (ContractId === void 0) { ContractId = null; }
        if (RegistrationNumber === void 0) { RegistrationNumber = null; }
        if (Date === void 0) { Date = null; }
        if (IsExtraCarSelected === void 0) { IsExtraCarSelected = false; }
        if (Start === void 0) { Start = null; }
        if (End === void 0) { End = null; }
        if (Purpose === void 0) { Purpose = null; }
        if (Distance === void 0) { Distance = 0; }
        if (IsCreate === void 0) { IsCreate = true; }
        if (CarDescription === void 0) { CarDescription = null; }
        if (TripType === void 0) { TripType = TripTypeEnum.BusinessTrip; }
        if (Trip === void 0) { Trip = null; }
        if (VinliGUID === void 0) { VinliGUID = null; }
        if (BusinessKM === void 0) { BusinessKM = "0"; }
        if (Route === void 0) { Route = null; }
        if (PrivatePassages === void 0) { PrivatePassages = 0; }
        if (BusinessPassages === void 0) { BusinessPassages = 0; }
        this.PersonId = PersonId;
        this.TripId = TripId;
        this.DataSource = DataSource;
        this.ContractId = ContractId;
        this.RegistrationNumber = RegistrationNumber;
        this.Date = Date;
        this.IsExtraCarSelected = IsExtraCarSelected;
        this.Start = Start;
        this.End = End;
        this.Purpose = Purpose;
        this.Distance = Distance;
        this.IsCreate = IsCreate;
        this.CarDescription = CarDescription;
        this.TripType = TripType;
        this.Trip = Trip;
        this.VinliGUID = VinliGUID;
        this.BusinessKM = BusinessKM;
        this.Route = Route;
        this.PrivatePassages = PrivatePassages;
        this.BusinessPassages = BusinessPassages;
    }
    return DriverJournalDailyLog;
}());
export { DriverJournalDailyLog };
