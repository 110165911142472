import { FuelCompany } from "./fuelCompany.model";

export class ChangeDriver {
    constructor(
        public ContractId: string = "",
        public KmInput: string = "",
        public ShouldFormerUsersFuelCardsBeClosed: boolean = false,
        public NotesOnFormerUsersFuelCards: string = "",
        public FormerDriversName: string = "",
        public VehicleRegistrationNumber: string = "",
        public ChangeDriverOn = null,
        public NewDriverName: string = "",
        public NewDriverPhoneNumber: string = "",
        public NewDriverEmail: string = "",
        public NewDriverDepartmentNumber: string = "",
        public DoesNewDriverAlreadyExists: boolean = false,
        public ShouldTiresBeReplaced: boolean = false,
        public TiresNewPlacementCityAndZip: string = "",
        public ListOfFuelCardsToOrder: Array<FuelCompany> = [],
        public KmPerYear: string = "", // Not used by DK
        public TotalKmByEndOfContract: string = "" // Not used by DK
    ) { }
}
