import { NewPasswordComponent } from './new-password/new-password.component';
import { ForgotLoginConfirmationComponent } from './forgot-login-confirmation/forgot-login-confirmation.component';
import { ForgotLoginComponent } from './forgot-login/forgot-login.component';
import { LoginComponent } from './login.component';
import { Routes } from '@angular/router';
import { ChangePasswordFormComponent } from './change-password/change-password.component';
var routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-login',
        component: ForgotLoginComponent
    },
    {
        path: 'forgot-login/confirmation',
        component: ForgotLoginConfirmationComponent
    },
    {
        path: 'new-password',
        component: NewPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordFormComponent,
    },
];
var LoginRoutingModule = /** @class */ (function () {
    function LoginRoutingModule() {
    }
    return LoginRoutingModule;
}());
export { LoginRoutingModule };
