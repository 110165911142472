import { InsuranceContactsComponent } from './../../components/features/insurance/insurance-contacts/insurance-contacts.component';
import { DeleteFleetUserComponent } from './../../components/features/business-admin/fleet-users-management/delete-fleet-user/delete-fleet-user.component';
import { PageNotFoundComponent } from './../../components/shared/page-not-found/page-not-found.component';
import { UpdateTranslationComponent } from '../../components/shared/translate/update-translation/update-translation.component';
import { BookingFormComponent } from '../../components/shared/booking-form/booking-form.component';
import { DeleteBookingComponent } from '../../components/shared/booking-form/delete-booking/delete-booking.component';
import { UpdateBookingComponent } from '../../components/shared/booking-form/update-booking/update-booking.component';
import { ComponentConfigComponent } from '../../components/features/component-config/component-config.component';
import { MissingTranslationComponent } from '../../components/features/missing-translations/missing-translations.component';
import { TranslateListComponent } from '../../components/features/translation-list/translate-list';
import { ConsentComponent } from '../../components/shared/consent/consent.component';
import { AppMaintenanceNoticeComponent } from '../../components/shared/app-maintenance-notice/app-maintenance-notice.component';
import { DriverjournalMonthlyUpdateSuccessComponent } from '../../components/features/driverjournal/driverjournal-monthly/driverjournal-monthly-update-success/driverjournal-monthly-update-success.component';
import { DeleteBusinessUserComponent } from '../../components/features/business-admin/business-user-management/delete-business-user/delete-business-user.component';
import { FeatureWarningComponent } from '../../components/shared/feature-warning/feature-warning.component';
import { UnderMaintenanceComponent } from '../../components/shared/under-maintenance/under-maintenance.component';
var ROUTES = [
    {
        path: 'update-translation',
        component: UpdateTranslationComponent,
        outlet: 'modal'
    },
    {
        path: 'booking/:modelName',
        component: BookingFormComponent,
        outlet: 'modal'
    },
    {
        path: 'update-booking/:modelName/:appointmentId',
        component: UpdateBookingComponent,
        outlet: 'modal'
    },
    {
        path: 'delete-booking/:modelName/:appointmentId',
        component: DeleteBookingComponent,
        outlet: 'modal'
    },
    {
        path: 'insurance-contacts',
        component: InsuranceContactsComponent,
        outlet: 'modal'
    },
    {
        path: 'component-config',
        component: ComponentConfigComponent,
        outlet: 'modal'
    },
    {
        path: 'missing-translations',
        component: MissingTranslationComponent,
        outlet: 'modal'
    },
    {
        path: 'translations-list',
        component: TranslateListComponent,
        outlet: 'modal'
    },
    {
        path: 'delete-profile/:id',
        component: DeleteFleetUserComponent,
        outlet: 'modal'
    },
    {
        path: 'delete-business-user/:id',
        component: DeleteBusinessUserComponent,
        outlet: 'modal'
    },
    {
        path: 'consent-component/:type',
        component: ConsentComponent,
        outlet: 'modal'
    },
    {
        path: 'app-maintenance-notice',
        component: AppMaintenanceNoticeComponent,
        outlet: 'modal'
    },
    {
        path: 'update-dj-success',
        component: DriverjournalMonthlyUpdateSuccessComponent,
        outlet: 'modal'
    },
    {
        path: 'app-feature-warning',
        component: FeatureWarningComponent,
        outlet: 'modal'
    },
    {
        path: 'app-under-maintenance',
        component: UnderMaintenanceComponent,
        outlet: 'modal'
    },
    // needs to be the last one always
    {
        path: '**',
        component: PageNotFoundComponent,
        outlet: 'modal'
    }
];
var ModalSliderModule = /** @class */ (function () {
    function ModalSliderModule() {
    }
    return ModalSliderModule;
}());
export { ModalSliderModule };
