import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var AdminInfoService = /** @class */ (function () {
    function AdminInfoService(apiService) {
        this.apiService = apiService;
    }
    AdminInfoService.prototype.getApplicationVersion = function () {
        var path = "assets/admininfo.json";
        return this.apiService.getFileAsync(path);
    };
    AdminInfoService.ngInjectableDef = i0.defineInjectable({ factory: function AdminInfoService_Factory() { return new AdminInfoService(i0.inject(i1.ApiService)); }, token: AdminInfoService, providedIn: "root" });
    return AdminInfoService;
}());
export { AdminInfoService };
