import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FleetUserService {

  isInTakeOverMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getIsInTakeOverMode() {
    return this.isInTakeOverMode.asObservable();
  }

  setIsInTakeOverMode(data: boolean) {
    this.isInTakeOverMode.next(data);
  }
}
