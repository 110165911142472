/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./field-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../field-translation/field-translation.component.ngfactory";
import * as i3 from "../field-translation/field-translation.component";
import * as i4 from "@angular/common";
import * as i5 from "./field-error.component";
var styles_FieldErrorComponent = [i0.styles];
var RenderType_FieldErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FieldErrorComponent, data: {} });
export { RenderType_FieldErrorComponent as RenderType_FieldErrorComponent };
function View_FieldErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-field-translation", [], null, null, null, i2.View_FieldTranslationComponent_0, i2.RenderType_FieldTranslationComponent)), i1.ɵdid(2, 114688, null, 0, i3.FieldTranslationComponent, [], { translationTagType: [0, "translationTagType"], formObject: [1, "formObject"], prop: [2, "prop"], rule: [3, "rule"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translationTagType; var currVal_1 = _co.formObject; var currVal_2 = _co.prop; var currVal_3 = _co.rule; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FieldErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FieldErrorComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formModel.formArray.get(_ck(_v, 2, 0, _co.formArrayIndex)).get(_co.prop.Name).hasError(_co.rule.Type); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FieldErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-error", [], null, null, null, View_FieldErrorComponent_0, RenderType_FieldErrorComponent)), i1.ɵdid(1, 114688, null, 0, i5.FieldErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldErrorComponentNgFactory = i1.ɵccf("app-field-error", i5.FieldErrorComponent, View_FieldErrorComponent_Host_0, { formObject: "formObject", formModel: "formModel", prop: "prop", rule: "rule", formArrayIndex: "formArrayIndex" }, {}, []);
export { FieldErrorComponentNgFactory as FieldErrorComponentNgFactory };
