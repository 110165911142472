import { GloveboxComponent } from './glovebox.component';
import { Routes } from '@angular/router';
import { SystemAdminGuard } from '../../../guards/systemadmin.guard';
var routes = [
    {
        path: '',
        component: GloveboxComponent,
        canActivate: [SystemAdminGuard]
    }
];
var GloveboxRoutingModule = /** @class */ (function () {
    function GloveboxRoutingModule() {
    }
    return GloveboxRoutingModule;
}());
export { GloveboxRoutingModule };
