import { AfterViewChecked, Component, ViewChild, ElementRef } from '@angular/core';

// Common
import { BigAlModule } from './../../../modules/bigal.module';
import { AbstractFeature } from '../../../components/features/feature.abstract';

// Services
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { ScreenService } from './../../../services/screen.service';
import { FleetUserService } from '../../../services/fleet-user.service';
import { RecordTripService } from '../../../services/driverjournal/recordtrip.service';
import { DjTabsService } from '../../../services/driverjournal/dj-tabs.service';
import { NavigationService } from '../../../services/navigation.service';
import { IDriverSettings } from '../../../sharedtypes/interfaces/sharedtypes.interface';
import { AppUserStore } from '../../../stores/DataStores';

@Component({
  selector: "menu-driver-component",
  templateUrl: "./menu-driver.component.html"
})
export class MenuDriverComponent extends AbstractFeature implements AfterViewChecked {

  @ViewChild("decorativeAnchor") private decorativeAnchor: ElementRef;
  public isInTakeOverMode = false;
  public isApp = false;
  public djTab = '/journal';
  private isShowingRecordingMenue;
  settings: IDriverSettings;

  anchorMovedOnInit = false;

  constructor(
    public screenService: ScreenService,
    private element: ElementRef,
    public theBen: ComponentConfigService,
    public bigAl: BigAlModule,
    public fleetUserService: FleetUserService,
    private recordTripService: RecordTripService,
    private djTabService: DjTabsService,
    private navigationService: NavigationService,
    private appUserStore: AppUserStore,
  ) {
    super(bigAl, theBen);

    this.settings = this.appUserStore.appUser.Settings;
    this.isApp = this.bigAl.appSettings.IsApp;
  }

  ngAfterViewChecked() {
    if (
      !this.anchorMovedOnInit &&
      this.element.nativeElement.querySelector(".active a")
    ) {
      this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
      this.anchorMovedOnInit = true;
    }
  }

  protected setEventsToWatch(): void {
    this.isInTakeOverMode = this.bigAl.getStorageValueForIsInTakeOverMode();
    this.subscribe(this.recordTripService.showRecordingTripMenue().subscribe((state: boolean) => {
      this.isShowingRecordingMenue = state;
    }));
    this.subscribe(this.djTabService.getData().subscribe(data => this.djTab = data));
  }

  protected setData(): void { }

  protected getData(): void { }

  isDataAvailable(): boolean {
    return true;
  }

  private moveAnchor(target) {
    let itemTarget = this.findAncestorItem(target);
    this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
  }

  private findAncestorItem(el) {
    while ((el = el.parentNode) && !el.classList.contains("item")) { }
    return el;
  }

  // EventHandler

  onClick($event) {
    this.moveAnchor($event.target);
  }

  onDjClick($event) {
    this.onClick($event);
    this.navigationService.forward(this.djTab, null);
  }

  triggerRecordTripMenu() {
    if (this.isShowingRecordingMenue === true) {
      this.recordTripService.hideMenu();
    }
    else {
      this.recordTripService.showMenu();
    }
  }

  public hasParkingEnabled() {
    const parkingEnabled = this.settings['ParkingApp'];
    return parkingEnabled;
  }
}
