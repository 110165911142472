/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/common";
import * as i4 from "./car-spinner.component";
import * as i5 from "../../modules/bigal.module";
import * as i6 from "../../services/componentConfig.service";
var styles_CarSpinnerComponent = [];
var RenderType_CarSpinnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarSpinnerComponent, data: {} });
export { RenderType_CarSpinnerComponent as RenderType_CarSpinnerComponent };
function View_CarSpinnerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "car-spinner-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "centered-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "car"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(5, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "company-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your personal car assistant"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(13, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.carLogo; _ck(_v, 5, 0, currVal_1); var currVal_4 = _co.logo; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).inline; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.companyName; _ck(_v, 8, 0, currVal_2); var currVal_3 = i0.ɵnov(_v, 13).inline; _ck(_v, 12, 0, currVal_3); }); }
export function View_CarSpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarSpinnerComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSpinner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CarSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "car-spinner", [], null, null, null, View_CarSpinnerComponent_0, RenderType_CarSpinnerComponent)), i0.ɵdid(1, 245760, null, 0, i4.CarSpinnerComponent, [i5.BigAlModule, i6.ComponentConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarSpinnerComponentNgFactory = i0.ɵccf("car-spinner", i4.CarSpinnerComponent, View_CarSpinnerComponent_Host_0, {}, {}, []);
export { CarSpinnerComponentNgFactory as CarSpinnerComponentNgFactory };
