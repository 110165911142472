var ComponentAction = /** @class */ (function () {
    function ComponentAction() {
        this.Type = ComponentActionType.Route;
    }
    return ComponentAction;
}());
export { ComponentAction };
export var ComponentActionType;
(function (ComponentActionType) {
    ComponentActionType[ComponentActionType["Route"] = 0] = "Route";
    ComponentActionType[ComponentActionType["RouteModal"] = 1] = "RouteModal";
    ComponentActionType[ComponentActionType["Link"] = 2] = "Link";
    ComponentActionType[ComponentActionType["File"] = 3] = "File";
    ComponentActionType[ComponentActionType["Phone"] = 4] = "Phone";
})(ComponentActionType || (ComponentActionType = {}));
