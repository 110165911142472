<ul class="user-menu-items">
  <div #decorativeAnchor class="decorative-anchor"></div>
  <li class="item" routerLinkActive="active" *ngIf="isContent('car')" (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/cars">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-car"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_car">
          <span translate-text>
            {{'MenuComponent.menu_car'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('servicecard')" (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/service-card">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="credit-card"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_servicecard">
          <span translate-text>
            {{'MenuComponent.menu_servicecard'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" [class.active]="recordTripService.isShowingMenu"
    *ngIf="isContent('journal') && isFeature('driverjournal')">
    <a *ngIf="!screenService.isDesktop && isContent('record-trip')" (click)="triggerRecordTripMenu()">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="calendar-xxl"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu-journal">
          <span translate-text>
            {{'MenuComponent.menu-journal'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
    <a *ngIf="screenService.isDesktop || !isContent('record-trip')" (click)="onDjClick($event)" routerLink='/journal'>
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="calendar-xxl"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu-journal">
          <span translate-text>
            {{'MenuComponent.menu-journal'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('parking_app') && isApp && hasParkingEnabled()"
    (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/parking/parking-list">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="parking"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu-parking">
          <span translate-text>
            {{'MenuComponent.menu-parking'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('glovebox')" (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/glovebox">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-glove-box"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu-glove-box">
          <span translate-text>
            {{'MenuComponent.menu-glove-box'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('services')" (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/services">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-services"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_services">
          <span translate-text>
            {{'MenuComponent.menu_services'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
  <li class="item" routerLinkActive="active" *ngIf="isContent('more')" (click)="recordTripService.hideMenu()">
    <a (click)="onClick($event)" routerLink="/more">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-more"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_more">
          <span translate-text>
            {{'MenuComponent.menu_more'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
</ul>