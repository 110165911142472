var FleetUserReportOrder = /** @class */ (function () {
    function FleetUserReportOrder(AdminUserId, FleetAdminReportWithVariableTypes, BusinessPartnerIds, OrderAll) {
        if (OrderAll === void 0) { OrderAll = false; }
        this.AdminUserId = AdminUserId;
        this.FleetAdminReportWithVariableTypes = FleetAdminReportWithVariableTypes;
        this.BusinessPartnerIds = BusinessPartnerIds;
        this.OrderAll = OrderAll;
    }
    return FleetUserReportOrder;
}());
export { FleetUserReportOrder };
