import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var BusinessPartnerStore = /** @class */ (function () {
    function BusinessPartnerStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.businessPartner$ = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetBusinessPartner$ = this.businessPartner$.asObservable();
    }
    // #region Requests
    BusinessPartnerStore.prototype.getBusinessPartner = function (forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this.businessPartner && !forceReload) {
            this.businessPartner$.next({ model: this.businessPartner, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync(ApiUrl.BusinessPartner_Current, this.settingStore.appSettings)
            .then(function (data) {
            _this.businessPartner = data;
            _this.businessPartner$.next({ model: _this.businessPartner, isSuccess: true });
        })
            .catch(function (err) {
            _this.businessPartner = null;
            _this.businessPartner$.next({ model: _this.businessPartner, isSuccess: false, error: err });
        });
    };
    BusinessPartnerStore.ngInjectableDef = i0.defineInjectable({ factory: function BusinessPartnerStore_Factory() { return new BusinessPartnerStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: BusinessPartnerStore, providedIn: "root" });
    return BusinessPartnerStore;
}());
export { BusinessPartnerStore };
