<div *ngIf="isTranslationLoaded()">
  <app-modal size="small">

    <div modal-header>
      <app-translate translationName="{{'AppMaintenanceNoticeComponent.header_t'}}">
        <span translate-text>{{'AppMaintenanceNoticeComponent.header_t'|translate}}</span>
      </app-translate>
    </div>
    <div modal-body>
      <app-translate translationName="{{'AppMaintenanceNoticeComponent.body_t'}}">
        <span translate-text>{{getMaintenanceMessage() | async}}</span>
      </app-translate>
    </div>
  </app-modal>
</div>