import { FleetUserReportWithVariableType } from "./fleetUserReportWithVariableType.model";

export class FleetUserReportOrder {
    constructor(
        public AdminUserId: string,
        public FleetAdminReportWithVariableTypes: FleetUserReportWithVariableType[],
        public BusinessPartnerIds: string[],
        public OrderAll: boolean = false,
    ) { }
}
