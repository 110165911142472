import { Injectable } from "@angular/core";
import { BigAlModule } from "../modules/bigal.module";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DatePatternService {
    data: BehaviorSubject<string> = new BehaviorSubject<string>("");
    constructor(private bigAl: BigAlModule) {

        switch (this.bigAl.appSettings.CountryCode) {
            case 'dk': {
                this.data.next("dd/MM/yyyy");
                break;
            }
            case 'se': {
                this.data.next("yyyy/MM/dd");
                break;
            }
            case 'no': {
                this.data.next("dd/MM/yyyy");
                break;
            }
            default: {
                this.data.next("dd/MM/yyyy");
            }
        }
    }

    getData() {
        return this.data.asObservable();
    }
}
