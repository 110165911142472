import { BehaviorSubject } from "rxjs";
import { MapsAPILoader } from '@agm/core';
import BackgroundGeolocation from "cordova-background-geolocation-lt";
import * as i0 from "@angular/core";
import * as i1 from "@agm/core/services/maps-api-loader/maps-api-loader";
var GeoLocationService = /** @class */ (function () {
    function GeoLocationService(mapLoader) {
        this.mapLoader = mapLoader;
        this.onNewPostion = new BehaviorSubject(null);
        this.onNewPostion$ = this.onNewPostion.asObservable();
        this.geoCoder = null;
        this.recordedPositions = [];
        // Used for ensureing iOS start to track ASAP and not after 1000meters which is the minimum fence distance it sets
        this.geoFenceIdentifer = "start-position";
        this.isCordovaEnabled = typeof window["cordova"] !== "undefined";
        if (this.isCordovaEnabled) {
            this.locationConfig = {
                reset: true,
                debug: false,
                logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
                logMaxDays: 3,
                desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION,
                autoSync: false,
                stopOnTerminate: false,
                startOnBoot: true,
                preventSuspend: true,
                distanceFilter: 5,
                heartbeatInterval: 60,
                geofenceProximityRadius: 1000,
                // iOS only
                stationaryRadius: 25,
                activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
            };
        }
        this.loadMap();
    }
    GeoLocationService.prototype.loadMap = function () {
        var _this = this;
        console.warn('loading geocoder');
        this.mapLoader.load().then(function () { return _this.geoCoder = new google.maps.Geocoder(); });
        this.isTracking = null;
        this.isRecording = false;
        this.isGeofenceSet = false;
        this.isWaitingForAutomaticStop = false;
    };
    GeoLocationService.prototype.initGEOService = function () {
        var _this = this;
        if (!this.isCordovaEnabled) {
            console.warn("Did not start GeoLocationService since we are not in an cordova environment");
            return;
        }
        this.clearPositions();
        try {
            BackgroundGeolocation.onLocation(function (location) {
                if (location && location.coords) {
                    _this.lastKnownLocation = location;
                }
                // if (!this.isWaitingForAutomaticStop) {
                _this.onNewPostion.next(location);
                if (_this.isRecording) {
                    var mappedPosition = _this.locationToAldPosition(location);
                    if (mappedPosition && _this.noCoordinateErrors(mappedPosition)) {
                        _this.recordedPositions.push(mappedPosition);
                    }
                }
                // }
            });
            BackgroundGeolocation.ready(this.locationConfig, function (state) {
                if (!state.enabled) {
                    BackgroundGeolocation.start();
                }
            }, function (error) {
                _this.isTracking = false;
            });
        }
        catch (err) {
        }
    };
    GeoLocationService.prototype.isServiceTracking = function () {
        return this.isTracking;
    };
    GeoLocationService.prototype.isServiceRecording = function () {
        return this.isRecording;
    };
    GeoLocationService.prototype.startTracking = function () {
        try {
            if (!this.isTracking) {
                this.startGeolocationTracking();
            }
            this.isRecording = true;
        }
        catch (err) {
        }
    };
    GeoLocationService.prototype.startAutomaticStop = function () {
        this.isWaitingForAutomaticStop = true;
    };
    GeoLocationService.prototype.WaitingForAutomaticStop = function () {
        return this.isWaitingForAutomaticStop;
    };
    GeoLocationService.prototype.stopTracking = function () {
        try {
            this.clearPositions();
            this.stopGeolocationTracking();
            this.isTracking = false;
            this.isRecording = false;
            this.isGeofenceSet = false;
            this.isWaitingForAutomaticStop = false;
        }
        catch (err) {
        }
    };
    GeoLocationService.prototype.getDistance = function () {
        var distance = 0;
        var coordinates = Array.from(this.recordedPositions);
        for (var i = 0; i < coordinates.length - 1; i++) {
            if (i === (coordinates.length - 1)) {
                continue;
            }
            distance += this.distanceCalculation(coordinates[i].Latitude, coordinates[i].Longitude, coordinates[i + 1].Latitude, coordinates[i + 1].Longitude);
        }
        return distance;
    };
    GeoLocationService.prototype.getAddressFromLatLong = function (latitude, longitude) {
        var _this = this;
        if (!this.geoCoder) {
            this.geoCoder = new google.maps.geoCoder();
        }
        var promise = new Promise(function (resolve, reject) {
            _this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        resolve(results[0].formatted_address);
                    }
                }
                resolve("No address");
            });
        });
        return promise;
    };
    GeoLocationService.prototype.getPositions = function () {
        return Array.from(this.recordedPositions);
    };
    GeoLocationService.prototype.mockPosition = function () {
        this.recordedPositions.push({ Longitude: 55.660790, Latitude: 12.273067 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660721, Latitude: 12.276400 });
        this.recordedPositions.push({ Longitude: 55.660480, Latitude: 12.284773 });
    };
    GeoLocationService.prototype.getLocationOneTime = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            BackgroundGeolocation.onLocation(function (location) {
                var mappedPosition = _this.locationToAldPosition(location);
                if (mappedPosition) {
                    BackgroundGeolocation.stop();
                    BackgroundGeolocation.removeAllListeners();
                    resolve(mappedPosition);
                }
            });
            BackgroundGeolocation.ready(_this.locationConfig, function (state) { }, function (error) {
                reject(null);
            });
        });
        return promise;
    };
    GeoLocationService.prototype.locationToAldPosition = function (position) {
        if (!position || !position.coords) {
            return null;
        }
        var dateToTicks = new Date(position.timestamp);
        return {
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude,
            Timestamp: dateToTicks.getTime(),
            Accuracy: position.coords.accuracy,
            Speed: position.coords.speed,
            Heading: position.coords.heading
        };
    };
    // #region BackgroundGeolocation Management
    GeoLocationService.prototype.setGeofence = function () {
        this.isGeofenceSet = true;
        if (!this.lastKnownLocation) {
            return;
        }
        BackgroundGeolocation.addGeofence({
            identifier: this.geoFenceIdentifer,
            longitude: this.lastKnownLocation.coords.longitude,
            latitude: this.lastKnownLocation.coords.latitude,
            notifyOnDwell: true,
            notifyOnEntry: true,
            notifyOnExit: true,
            radius: 50
        });
    };
    GeoLocationService.prototype.startGeolocationTracking = function () {
        if (!this.isGeofenceSet) {
            this.setGeofence();
        }
        this.isTracking = true;
    };
    GeoLocationService.prototype.stopGeolocationTracking = function () {
        BackgroundGeolocation.stop();
        BackgroundGeolocation.removeListeners();
        BackgroundGeolocation.removeGeofence(this.geoFenceIdentifer);
    };
    // #endregion
    // #region Helpers
    GeoLocationService.prototype.clearPositions = function () {
        this.recordedPositions = [];
    };
    GeoLocationService.prototype.distanceCalculation = function (lat1, lon1, lat2, lon2) {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            var R = 6378.137; // Radius of earth in KM
            var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
            var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d; // km
        }
    };
    GeoLocationService.prototype.noCoordinateErrors = function (position) {
        // Note: Ensure we don't get messy coordinates like this one: 1.85320519890898e-314
        var regX = /[a-z]|[-]/ig;
        var matchesLatitude = position.Latitude.toString().search(regX);
        var matchesLongitude = position.Longitude.toString().search(regX);
        return matchesLatitude === -1 && matchesLongitude === -1; // No match found - success!
    };
    GeoLocationService.ngInjectableDef = i0.defineInjectable({ factory: function GeoLocationService_Factory() { return new GeoLocationService(i0.inject(i1.MapsAPILoader)); }, token: GeoLocationService, providedIn: "root" });
    return GeoLocationService;
}());
export { GeoLocationService };
