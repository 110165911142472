import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ToastService {

  show(message: string): void {

    if (window['plugins']) {
      const plugin = window['plugins'].toast;
      if (plugin) {
        plugin.showLongCenter(message);
      }
    }
  }

}
