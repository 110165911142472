var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { AbstractFeature } from '../../components/features/feature.abstract';
import { BigAlModule } from '../../modules/bigal.module';
import { ScreenService } from '../../services/screen.service';
import { ComponentConfigService } from '../../services/componentConfig.service';
import { UserRoleType } from '../../models/enums/user-role-type.enum';
import { FleetUserService } from '../../services/fleet-user.service';
import { NavigationService } from '../../services/navigation.service';
import { AppSettingsStore, AppUserStore } from '../../stores/DataStores';
import { Constants } from '../../helpers/constants';
var MenuComponent = /** @class */ (function (_super) {
    __extends(MenuComponent, _super);
    function MenuComponent(screenService, theBen, bigAl, fleetUserService, navigationService, settingsStore, appUserStore) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.screenService = screenService;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.fleetUserService = fleetUserService;
        _this.navigationService = navigationService;
        _this.settingsStore = settingsStore;
        _this.appUserStore = appUserStore;
        _this.user = null;
        _this.showRoleDriver = false;
        _this.showRoleFleetUser = false;
        _this.showRoleBusinessUser = false;
        _this.isInTakeOverMode = false;
        _this.menuRole = UserRoleType.Driver;
        _this.showRolesPicker = false;
        _this.currentLang = null;
        return _this;
    }
    MenuComponent.prototype.setEventsToWatch = function () {
        var _this = this;
        this.watchEvent(Constants.Event_currentUser);
        this.watchEvent('logout');
        this.fleetUserService.isInTakeOverMode.subscribe(function (isInTakeOverMode) {
            _this.isInTakeOverMode = isInTakeOverMode;
            _this.clearMenuRoles();
            _this.pickMenu();
        });
        return;
    };
    MenuComponent.prototype.setData = function () {
        this.registerSubscription();
        this.setLanguage();
        this.user = this.appUserStore.appUser;
        this.showRolesPicker = !this.bigAl.appSettings.IsApp;
        this.pickMenu();
    };
    MenuComponent.prototype.getData = function () {
        this.setData();
    };
    MenuComponent.prototype.isDataAvailable = function () {
        return true;
    };
    MenuComponent.prototype.pickMenu = function () {
        var roles = [];
        this.bigAl.showAdminButtons = false;
        if (!this.user || this.user.Roles.length < 1) {
            roles = this.bigAl.getPreviousUserRoles();
        }
        else {
            roles = this.user.Roles;
        }
        if (roles.some(function (ur) { return ur === UserRoleType.Anonymous; })) {
            this.clearMenuRoles();
        }
        if (roles.some(function (ur) { return ur === UserRoleType.Driver; }) || this.isInTakeOverMode) {
            this.menuRole = UserRoleType.Driver;
            this.showRoleDriver = true;
            if (roles.some(function (ur) { return ur === UserRoleType.SystemAdminFleetAdmin; })) {
                this.bigAl.showAdminButtons = true;
            }
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager; })) {
            this.menuRole = UserRoleType.FleetAdmin;
            this.showRoleFleetUser = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.SystemAdminDriver; })) {
            this.menuRole = UserRoleType.Driver;
            this.bigAl.showAdminButtons = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.SystemAdminFleetAdmin; })) {
            this.menuRole = UserRoleType.FleetAdmin;
            this.bigAl.showAdminButtons = true;
            this.showRoleFleetUser = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.BusinessUser; })) {
            this.menuRole = UserRoleType.BusinessUser;
            this.showRoleBusinessUser = true;
            this.bigAl.showAdminButtons = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.SuperAdmin; })) {
            this.menuRole = UserRoleType.SuperAdmin;
            this.showRoleBusinessUser = true;
            this.bigAl.showAdminButtons = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.DevUser_Driver; })) {
            this.menuRole = UserRoleType.Driver;
            this.showRoleDriver = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.DevUser_Admin; })) {
            this.menuRole = UserRoleType.FleetAdmin;
            this.bigAl.showAdminButtons = true;
            this.showRoleBusinessUser = true;
        }
        else if (roles.some(function (ur) { return ur === UserRoleType.DevUser_Business; })) {
            this.menuRole = UserRoleType.BusinessUser;
            this.bigAl.showAdminButtons = true;
            this.showRoleFleetUser = true;
        }
    };
    MenuComponent.prototype.clearMenuRoles = function () {
        this.showRoleBusinessUser = false;
        this.showRoleFleetUser = false;
        this.showRoleDriver = false;
    };
    MenuComponent.prototype.toggleDriverMenu = function () {
        if (this.showRoleDriver && this.showRoleFleetUser && this.menuRole !== UserRoleType.Driver) {
            this.menuRole = UserRoleType.Driver;
            this.navigationService.forward("cars", null);
        }
    };
    MenuComponent.prototype.toggleFleetUserMenu = function () {
        if (this.showRoleDriver && this.showRoleFleetUser && this.menuRole !== UserRoleType.FleetAdmin) {
            this.menuRole = UserRoleType.FleetAdmin;
            this.navigationService.forward("fleet/overview", null);
        }
    };
    MenuComponent.prototype.showDriverMenu = function () {
        return this.menuRole === UserRoleType.Driver || this.menuRole === UserRoleType.DevUser_Driver;
    };
    MenuComponent.prototype.showFleetUserMenu = function () {
        return this.menuRole === UserRoleType.FleetAdmin || this.menuRole === UserRoleType.DevUser_Admin;
    };
    MenuComponent.prototype.showBusinessUserMenu = function () {
        return this.menuRole === UserRoleType.BusinessUser || this.menuRole === UserRoleType.DevUser_Business || this.menuRole === UserRoleType.SuperAdmin;
    };
    MenuComponent.prototype.registerSubscription = function () {
        var _this = this;
        _super.prototype.subscribe.call(this, this.settingsStore.onLanguageChanged$.subscribe(function (response) {
            if (response && !response.isInitial && response.isSuccess) {
                _this.currentLang = response.model;
            }
            else {
                if (response.error) {
                    _this.currentLang = null;
                }
            }
        }));
    };
    MenuComponent.prototype.changeLanguage = function (languageCode) {
        this.currentLang = languageCode;
        this.settingsStore.changeLanguage(languageCode);
    };
    MenuComponent.prototype.setLanguage = function () {
        if (localStorage.getItem(Constants.LocalStorage_Language)) {
            this.currentLang = localStorage.getItem(Constants.LocalStorage_Language);
        }
        else {
            this.currentLang = this.settingsStore.appSettings.LanguageCode;
        }
    };
    MenuComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    MenuComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return MenuComponent;
}(AbstractFeature));
export { MenuComponent };
