<mat-dialog-content>
  <div class="modal-header">
    <h1 mat-dialog-title>
      <app-translate translationName="ConsentComponent.{{type}}_Header">
        <span translate-text>{{'ConsentComponent.' + type + '_Header'|translate}}</span>
      </app-translate>
    </h1>

    <div class="modal-body">
      <app-translate translationName="ConsentComponent.{{type}}_Body">
        <span translate-text>{{'ConsentComponent.' + type + '_Body'|translate}}</span>
      </app-translate>

      <p class="mt-2">
        <app-translate translationName="ConsentComponent.{{type}}_Body2">
          <span translate-text>{{'ConsentComponent.' + type + '_Body2'|translate}}</span>
        </app-translate>
      </p>

      <p class="mt-2">
        <app-translate translationName="ConsentComponent.{{type}}_Body3">
          <span translate-text>{{'ConsentComponent.' + type + '_Body3'|translate}}</span>
        </app-translate>
      </p>

      <p *ngIf="isContent('privacyLink')" class="mt-2">

        <app-translate translationName="ConsentComponent.{{type}}_PrePrivacyLink">
          <span translate-text>{{'ConsentComponent.' + type + '_PrePrivacyLink'|translate}}</span>
        </app-translate>

        <a class="link-inline" (click)="action('privacyLink')">
          <app-translate translationName="ConsentComponent.{{type}}_LinkTxt_1">
            <span translate-text>
              {{ 'ConsentComponent.' + type + '_LinkTxt_1' | translate }}
            </span>
          </app-translate>
        </a>
      </p>
    </div>

    <mat-dialog-actions class="modal-footer">
      <button mat-raised-button color="primary" (click)="yes()">
        <app-translate translationName="ConsentComponent.{{type}}_Accept">
          <span translate-text>{{'ConsentComponent.' + type + '_Accept'|translate}}</span>
        </app-translate>
      </button>
      <button mat-raised-button (click)="no()">
        <app-translate translationName="ConsentComponent.{{type}}_Decline">
          <span translate-text>{{'ConsentComponent.' + type + '_Decline'|translate}}</span>
        </app-translate>
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>