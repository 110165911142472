import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnChanges {

  @Input() isDoneLoading: boolean;
  @Input() didRequestFail: boolean;
  @Input() startAgain: boolean;

  showLodingIndicator: boolean = true;
  showLoadingMessage: boolean = false;
  message: string = "Data is still being received"; // todo: get message from json file?

  private timer: any;
  private threshold: number = 4000;

  constructor() { }

  ngOnInit(): void {

    if (this.isDoneLoading) {
      this.showLodingIndicator = false;
    } else {
      this.timer = setTimeout(() => {
        this.showLoadingMessage = true;
      }, this.threshold);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.isDoneLoading &&
      changes.isDoneLoading.currentValue) {
      this.showLodingIndicator = false;
      this.showLoadingMessage = false;
      clearTimeout(this.timer);
    }

    if (changes.didRequestFail &&
      changes.didRequestFail.currentValue) {
      this.showLodingIndicator = false;
      this.showLoadingMessage = false;
      clearTimeout(this.timer);
    }

    if (changes.startAgain &&
      changes.startAgain.currentValue) {
      this.showLodingIndicator = true;
      this.showLoadingMessage = false;

      this.timer = setTimeout(() => {
        this.showLoadingMessage = true;
      }, this.threshold);
    }
  }
}
