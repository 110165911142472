<car-spinner *ngIf="!showFirstComponent.value"></car-spinner>
<app-loading-spinner></app-loading-spinner>
<div *ngIf="showFirstComponent.value && !isContent('under-maintenance')">
  <div class="main">
    <router-outlet #routedComponent></router-outlet>
  </div>
  <menu-component></menu-component>
  <div *ngIf="!isProduction && databaseType !== null">
    <div class="version-details-container" style="max-width: 230px">
      {{ databaseType }} | FE: {{ frontendVersion }}, BE: {{ bigAl.appSettings.Version }}
    </div>
  </div>
  <div class="sys-admin-buttons" *ngIf="!isProduction && bigAl.isInDesktopScreen && canUseAdminFunctions()">
    <button class="admin-button" mat-raised-button color="warn" mat-button (click)="translationUpdates()">
      {{updateTranslationsButtonText}}
    </button>
    <button class="admin-button" mat-raised-button color="warn" mat-button (click)="action('component-config')">
      Update component config
    </button>
    <button class="admin-button" mat-raised-button color="warn" mat-button (click)="action('missing-translations')">
      Missing translations
    </button>
    <button class="admin-button" mat-raised-button color="warn" mat-button (click)="action('translations-list')">
      {{translationsListButtonText}}
    </button>
  </div>
</div>

<div *ngIf="showFirstComponent.value && isContent('under-maintenance')" class="element-container">
  <h2 class="primary-text">
    <app-translate translationName="InitComponent.under_maintenance_title">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_title'| translate }}
      </span>
    </app-translate>
  </h2>
  <p>
    <app-translate translationName="InitComponent.under_maintenance_content">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_content'| translate }}
      </span>
    </app-translate>
  </p>
  <br />
  <h2 class="primary-text">
    <app-translate translationName="InitComponent.under_maintenance_title2">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_title2'| translate }}
      </span>
    </app-translate>
  </h2>
  <p>
    <app-translate translationName="InitComponent.under_maintenance_content2">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_content2'| translate }}
      </span>
    </app-translate>
  </p>
  <br />
  <h2 class="primary-text">
    <app-translate translationName="InitComponent.under_maintenance_title3">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_title3'| translate }}
      </span>
    </app-translate>
  </h2>
  <p>
    <app-translate translationName="InitComponent.under_maintenance_content3">
      <span translate-text>
        {{ 'InitComponent.under_maintenance_content3'| translate }}
      </span>
    </app-translate>
  </p>
</div>
<app-api-error></app-api-error>
<router-outlet name="modal"></router-outlet>

<app-api-success></app-api-success>

<app-modal size="small" [closeButton]="showReleaseAlertCloseButton" *ngIf="isApplicationVersionchanged">
  <div modal-header>
    <app-translate translationName="InitComponent.refresh_alert_title">
      <span translate-text>
        {{ 'InitComponent.refresh_alert_title'| translate }}
      </span>
    </app-translate>
  </div>
  <div modal-body>
    <app-translate translationName="InitComponent.refresh_alert_message">
      <span translate-text>
        {{ 'InitComponent.refresh_alert_message'| translate }}
      </span>
    </app-translate>
  </div>
  <div modal-footer>
    <button mat-raised-button color="primary" (click)="updateVersionAndReload()">
      <app-translate translationName="InitComponent.refresh_alert_ok">
        <span translate-text>
          {{ 'InitComponent.refresh_alert_ok'| translate }}
        </span>
      </app-translate>
    </button>
  </div>
</app-modal>