import { Injectable } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { BigAlModule } from './../modules/bigal.module';

export interface IBreakPoint {
    breakpoints: IBreakPoints;
}

export interface IBreakPoints {
    xss: number;
    xs: number;
    s: number;
    m: number;
}

const DEFAULT_CONFIG: IBreakPoint = {
    breakpoints: {
        xss: 320,
        xs: 480,
        s: 768,
        m: 980
    }
};

@Injectable()
export class ScreenService {

    isDesktop = true;
    isRetina: boolean;
    isMobileTablet: boolean;
    isMobile: boolean;
    isTouch: boolean;
    isIOS: boolean;
    isAndroid: boolean;

    constructor(
        private eventManager: EventManager,
        private bigAl: BigAlModule
    ) {
        this.init();

        this.isDesktop = window.innerWidth > DEFAULT_CONFIG.breakpoints.m;
        this.bigAl.isInDesktopScreen = this.isDesktop;
        this.eventManager.addGlobalEventListener('window', 'resize', () => this.onResize());
    }

    private init(): void {
        this.isRetina = window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches);
        this.isMobileTablet = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) ? true : false;
        this.isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i) ? true : false;
        this.isIOS = navigator.userAgent.match(/(iPhone)|(iPod)/i) ? true : false;
        this.isAndroid = navigator.userAgent.match(/(android)/i) ? true : false;
        this.isTouch = "createTouch" in document;
    }

    private onResize() {
        this.isDesktop = window.innerWidth > DEFAULT_CONFIG.breakpoints.m;
        this.bigAl.isInDesktopScreen = this.isDesktop;
    }
}
