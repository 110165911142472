import { BehaviorSubject } from 'rxjs';
var ModalService = /** @class */ (function () {
    function ModalService() {
        this.data = new BehaviorSubject([]);
        this.bool = new BehaviorSubject(true);
    }
    ModalService.prototype.getData = function () {
        return this.data.asObservable();
    };
    ModalService.prototype.setData = function (data) {
        this.data.next(data);
    };
    ModalService.prototype.getBool = function () {
        return this.bool.asObservable();
    };
    ModalService.prototype.setBool = function (bool) {
        this.bool.next(bool);
    };
    return ModalService;
}());
export { ModalService };
