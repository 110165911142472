var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LocaleUtil } from './../../helpers/locale.util';
import { BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { AppDateAdapter } from '../../directives/app-date-adapter';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsStore } from '../../stores/DataStores';
var TableService = /** @class */ (function () {
    function TableService(translateService, settingsStore) {
        var _this = this;
        this.translateService = translateService;
        this.settingsStore = settingsStore;
        this.data = new BehaviorSubject([]);
        this.filteredData = new BehaviorSubject([]);
        this.monthNames = null;
        this.dateAdapter = new AppDateAdapter(this.settingsStore);
        this.monthNames = this.dateAdapter.getMonthNames('long', this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(function (newLang) {
            _this.monthNames = _this.dateAdapter.getMonthNames('long', newLang['Lang']);
        });
    }
    TableService.prototype.getData = function () {
        return this.filteredData.asObservable();
    };
    TableService.prototype.setData = function (data) {
        this.data.next(data);
        this.filteredData.next(data);
    };
    TableService.prototype.getValue = function () {
        return this.data.getValue();
    };
    TableService.prototype.getFilterValue = function () {
        return this.filteredData.getValue();
    };
    TableService.prototype.reset = function () {
        this.filteredData.next(this.data.value);
        this.filter = null;
    };
    TableService.prototype.sort = function (property, desc) {
        var array = this.filteredData.getValue();
        if (array.length > 1 && array[0][property]) {
            if (desc) {
                array.sort(function (a, b) {
                    if (a[property] && b[property]) {
                        return b[property].localeCompare(a[property]);
                    }
                    return;
                });
            }
            else {
                array.sort(function (a, b) {
                    if (a[property] && b[property]) {
                        return a[property].localeCompare(b[property]);
                    }
                    return;
                });
            }
            this.filteredData.next(array);
        }
    };
    TableService.prototype.sortByDate = function (property, desc) {
        var _this = this;
        var array = this.filteredData.getValue();
        var dates = [];
        array.forEach(function (b, i) {
            if (b[property] === null || b[property] === '' || b[property] === undefined) {
                array[i]['DateForSort'] = 0;
            }
            else {
                array[i]['DateForSort'] = LocaleUtil.convertToDateEnglish(b[property], _this.translateService.currentLang);
            }
            dates.push(b[property] + " " + Date.parse(b['DateForSort']));
        });
        property = 'DateForSort';
        if (array.length > 1 && array[0][property] !== null) {
            if (desc) {
                array.sort(function (a, b) { return b[property] - a[property]; });
            }
            else {
                array.sort(function (a, b) { return a[property] - b[property]; });
            }
            this.filteredData.next(array);
        }
    };
    TableService.prototype.sortByMonth = function (property, desc) {
        var _this = this;
        var array = this.filteredData.getValue();
        if (array.length > 1 && array[0][property]) {
            array.forEach(function (b) {
                b['MonthForSort'] = _this.monthNames.indexOf(b[property]);
            });
            property = 'MonthForSort';
            if (desc) {
                array.sort(function (a, b) { return b[property] - a[property]; });
            }
            else {
                array.sort(function (a, b) { return a[property] - b[property]; });
            }
            this.filteredData.next(array);
        }
    };
    return TableService;
}());
export { TableService };
var TableDataSource = /** @class */ (function (_super) {
    __extends(TableDataSource, _super);
    function TableDataSource(tableService) {
        var _this = _super.call(this) || this;
        _this.tableService = tableService;
        return _this;
    }
    TableDataSource.prototype.connect = function () {
        return this.tableService.getData();
    };
    TableDataSource.prototype.disconnect = function () { };
    return TableDataSource;
}(DataSource));
export { TableDataSource };
