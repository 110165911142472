var AppointmentPlace = /** @class */ (function () {
    function AppointmentPlace(ModelName, Name, Street, PostalCodeAndCity, Telephone, Bookings) {
        if (Bookings === void 0) { Bookings = []; }
        this.ModelName = ModelName;
        this.Name = Name;
        this.Street = Street;
        this.PostalCodeAndCity = PostalCodeAndCity;
        this.Telephone = Telephone;
        this.Bookings = Bookings;
    }
    return AppointmentPlace;
}());
export { AppointmentPlace };
