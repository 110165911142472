import { OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

//
import * as formconfig from 'assets/init/formConfig.json';
import { BigAlModule } from "../../modules/bigal.module";

//
import { FormObject } from "../../models/form-object.model";
import { RuleObject } from "../../models/rule-object.model";

//
import { ComponentConfigService } from "../../services/componentConfig.service";

export abstract class AbstractFeature implements OnInit, OnDestroy {

  private allSubscriptions: Subscription = new Subscription();
  private watchedEvents = new Map<string, any>();
  // Cache of retrieved form objects to achieve immutability for the "original" form config retrieved from the formConfig.json
  private cacheFormObjects = new Map<string, FormObject>();

  constructor(
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService) {
  }

  ngOnInit(): void {
    // data calls
    this.setEventsToWatch();
    this.watchData();
    this.getData();
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
  }

  protected abstract setEventsToWatch(): void;

  protected watchEvent(event: string): void {
    this.watchedEvents.set(event, "");
  }

  protected watchData(): void {
    this.subscribe(this.bigAl.getDataStream().subscribe(lastEvent => {
      if (this.watchedEvents.has(lastEvent)) {
        this.setData(lastEvent);
      }
    }));
  }

  protected abstract setData(typeOfEvent?): void;

  // If necessary data is available, call setData(), otherwise make the relevant call in BigAl
  protected abstract getData(): void;

  protected isEventProcessing(typeOfEvent) {
    return this.bigAl.isEventEnqueued(typeOfEvent);
  }

  isFeature(names: any) {
    let val = true;

    if (typeof names === 'string') {
      val = this.theBen.isFeature(names);
    }
    else {
      names.forEach(name => {
        const isAvailable = this.theBen.isFeature(name);
        if (!isAvailable) {
          val = false;
        }
      });
    }
    return val;
  }

  abstract isDataAvailable(): boolean;

  isContent(name: string) {
    return this.theBen.isContent(this, name);
  }

  action(location: string, id?: string[]) {

    (id) ? this.theBen.action(this, location, id) : this.theBen.action(this, location, null);
  }

  actionDocument(location: string) {
    this.theBen.actionDocument(this, location);
  }

  protected subscribe(subscription: Subscription): void {
    this.allSubscriptions.add(subscription);
  }

  private unsubscribe(): void {
    this.allSubscriptions.unsubscribe();
  }

  protected getFormObject(name: string, prefilledContent: Map<string, PrefilledContentEntry[]>, useFormCache: boolean = false): FormObject {

    // If the form has previously been cached, retrieve it from the cache.
    if (this.cacheFormObjects.has(name)) {
      return this.cacheFormObjects.get(name);
    }

    const objects = formconfig['FormObjects'];
    let jsonFormObject: FormObject = objects.find(obj => obj["Name"] === name);
    if (!jsonFormObject) {
      console.warn("Could not find " + name + " in the formconfig. Forgot to add it or typo?");
    }
    let formObject;
    // If there are no prefilled values, it is a waste of time to iterate over all steps' properties.
    if (!prefilledContent || prefilledContent.size < 1) {
      if (useFormCache === true) {
        formObject = JSON.parse(JSON.stringify(jsonFormObject));
        this.cacheFormObjects.set(name, formObject);
        return formObject;
      }
      return jsonFormObject;
    }

    formObject = JSON.parse(JSON.stringify(jsonFormObject));
    // Iterate over the steps' properties and set their value if a prefilledValue is specified for the property.
    for (let i = 0; i < formObject.Steps.length; i++) {
      let step = formObject.Steps[i];
      for (let j = 0; j < step.Properties.length; j++) {
        let prop = step.Properties[j];
        if (prefilledContent.has(prop.Name)) {
          let propContent = prefilledContent.get(prop.Name);
          propContent.forEach(entry => {

            let value = entry.value;
            // The value 0 is valid enough, but is incorrectly treated as something else than number on Input fields, which is why we append an empty string to the value.
            if (prop.Control === "Input" && value === 0) {
              value = value + "";
            }
            prop[entry.key] = value;
          });
        }
      }
    }

    // We save the formObject to the cache for performance, so retrieving the same formObject multiple times will be faster.
    this.cacheFormObjects.set(name, formObject);

    return formObject;
  }

  protected getRuleObject(name: string): RuleObject {
    let ruleObjects = this.bigAl.currentRuleBook.DomainObjects;
    let ruleObject = ruleObjects.find(obj => obj["Name"] === name);
    if (!ruleObject) {
      console.warn("Could not find " + name + " in the rulebook. Forgot to add it or typo?");
    }
    return ruleObject;
  }

  protected clearFormObjectCache(name: string) {
    if (this.cacheFormObjects.has(name)) {
      this.cacheFormObjects.delete(name);
    }
  }
}

export class PrefilledContentEntry {
  constructor(
    public key: string,
    public value: any
  ) { }
}
