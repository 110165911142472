var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BigAlModule } from './../../../modules/bigal.module';
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { ScreenService } from './../../../services/screen.service';
import { ElementRef, AfterViewChecked } from '@angular/core';
import { AbstractFeature } from '../../../components/features/feature.abstract';
var MenuBusinessUserComponent = /** @class */ (function (_super) {
    __extends(MenuBusinessUserComponent, _super);
    function MenuBusinessUserComponent(screenService, element, theBen, bigAl) {
        var _this = _super.call(this, bigAl, theBen) || this;
        _this.screenService = screenService;
        _this.element = element;
        _this.theBen = theBen;
        _this.bigAl = bigAl;
        _this.anchorMovedOnInit = false;
        return _this;
    }
    MenuBusinessUserComponent.prototype.ngAfterViewChecked = function () {
        if (!this.anchorMovedOnInit &&
            this.element.nativeElement.querySelector(".active a")) {
            this.moveAnchor(this.element.nativeElement.querySelector(".active a"));
            this.anchorMovedOnInit = true;
        }
    };
    MenuBusinessUserComponent.prototype.setEventsToWatch = function () {
    };
    MenuBusinessUserComponent.prototype.setData = function () {
    };
    MenuBusinessUserComponent.prototype.getData = function () {
    };
    MenuBusinessUserComponent.prototype.isDataAvailable = function () {
        return true;
    };
    // EventHandler
    MenuBusinessUserComponent.prototype.onClick = function ($event) {
        this.moveAnchor($event.target);
    };
    MenuBusinessUserComponent.prototype.moveAnchor = function (target) {
        var itemTarget = this.findAncestorItem(target);
        this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + "px";
    };
    MenuBusinessUserComponent.prototype.findAncestorItem = function (el) {
        while ((el = el.parentNode) && !el.classList.contains("item")) { }
        return el;
    };
    MenuBusinessUserComponent.prototype.lnkLogout = function () {
        this.bigAl.logout();
    };
    return MenuBusinessUserComponent;
}(AbstractFeature));
export { MenuBusinessUserComponent };
