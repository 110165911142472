import { BigAlModule } from '../modules/bigal.module';
import { OnDestroy } from '@angular/core';
import { UserRoleType } from '../models/enums/user-role-type.enum';
import { NavigationService } from '../services/navigation.service';
var DriverGuard = /** @class */ (function () {
    function DriverGuard(navigationService, bigAl) {
        this.navigationService = navigationService;
        this.bigAl = bigAl;
    }
    DriverGuard.prototype.canActivate = function (route, state) {
        // if type directly url - this.bigAl.isUserLoggedIn  returns False
        // this.bigAl.currentUser.Roles.some - returns nothig - changed to this.bigAl.getPreviousUserRoles()
        // if not driver
        if (!this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.Driver || ur === UserRoleType.SystemAdminDriver || ur === UserRoleType.DevUser_Driver; })) {
            // If fleet user
            if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.FleetAdmin || ur === UserRoleType.FleetManager || ur === UserRoleType.DevUser_Admin || ur === UserRoleType.SystemAdminFleetAdmin; })) {
                // console.log('Driver guard - admin role found');
                // continue to take over guard
                return true;
            }
            // If business user redirect to dashboard
            else if (this.bigAl.getPreviousUserRoles().some(function (ur) { return ur === UserRoleType.BusinessUser; })) {
                this.navigationService.forward("dashboard", null);
                return false;
            }
        }
        else {
            return true;
        }
    };
    DriverGuard.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return DriverGuard;
}());
export { DriverGuard };
