<app-modal>
  <div modal-header>
    Translation List
  </div>
  <div modal-body>
    <div class="component-configs">
      <div *ngFor="let group of translationGroups">
        <h3 class="mt-3">{{group.GroupName}}</h3>
        <div *ngFor="let entry of group.Translations">
          {{entry.TranslationName}} :
          <app-translate translationName="{{entry.TranslationName}}">
            <span translate-text>{{ entry.TranslationName | translate}}</span>
          </app-translate>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer>
    <button mat-raised-button color="primary" (click)="back()">
      Close
    </button>
  </div>
</app-modal>