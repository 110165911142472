import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BigAlModule } from '../modules/bigal.module';

// Types
import { ComponentStructure } from '../models/component-config/component-structure.model';
import { FeatureStructure, FeatureShowState } from '../models/component-config/feature-structure.model';
import { ComponentConfiguration } from '../models/component-config/component-configuration.model';
import { ComponentContent } from '../models/component-config/component-content.model';
import { ComponentActionType, ComponentAction } from '../models/component-config/component-action.model';
import { Company } from '../models/external-links/company-object.model';

// Other
import { CustomTranslateService } from '../translation/customTranslateService';
import * as externalLinks from 'assets/init/external-links.json';
import { NavigationService } from './navigation.service';
import { TrackingHelper } from '../helpers/trackingHelper';
import { AppSettingsStore, AppUserStore } from '../stores/DataStores';

@Injectable()
export class ComponentConfigService implements OnDestroy {

  private config: ComponentStructure;
  private featuresConfig: Array<FeatureStructure>;
  private subscription: Subscription;
  private isInTranslationMode = false;
  private createdComponentContent = new Map<string, string[]>();
  private trackingService: TrackingHelper;

  // #region Life Cycle

  constructor(
    private bigAl: BigAlModule,
    private navigationService: NavigationService,
    private translateService: CustomTranslateService,
    private settingsStore: AppSettingsStore,
    private appUserStore: AppUserStore,
  ) {
    this.trackingService = new TrackingHelper(appUserStore);
    this.init();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // #endregion

  private init(): void {
    this.subscription = this.translateService.isEditing
      .subscribe((isInTranslationMode: boolean) => {
        this.isInTranslationMode = isInTranslationMode;
      });
  }

  isContent(component: Object, name: string): boolean {

    const componentName = (typeof component === 'string') ? component : component.constructor.name;
    this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(comp => comp.Name === componentName);

    if (!this.config) {
      this.createContent(componentName, name);
      return false;
    }

    let content = this.config.Content.find(c => c.Name === name);

    if (!content) {
      this.createContent(componentName, name);
      return false;
    }

    return content.ShowContent;
  }

  action(component: Object | string, name: string, id?: string[]): void {

    if (this.isInTranslationMode) {
      return;
    }

    const componentName = (typeof component === "string") ? component : component.constructor.name;
    this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(comp => comp.Name === componentName);

    let action: ComponentAction;
    if (this.config) {
      action = this.config.Actions.find(a => a.Name === name);
    }

    if (!action) {
      action = this.createAction(componentName, name);
    }


    const companyName = this.getCompanyName(this.settingsStore.appSettings.CompanyId);
    const countryCode = this.settingsStore.appSettings.CountryCode;
    const languageCode = this.settingsStore.appSettings.LanguageCode;

    let routeId: string;
    if (Array.isArray(id)) {
      routeId = id[0];
    }
    else {
      routeId = id;
    }
    const trackingAction = `${componentName} - ${action.Name}`;
    this.trackingService.trackClick(trackingAction, action.Next);

    switch (+action.Type) {
      case ComponentActionType.RouteModal:
        if (action.Next === "close") {
          this.navigationService.closeModal();
        }
        else {

          if (id && Array.isArray(id) && id.length > 1) {
            let array = [];
            array.push(action.Next);
            for (let i = 0; i < id.length; i++) {
              array.push(id[i]);
            }
            this.navigationService.openModal(array);
          }
          else {
            (routeId) ? this.navigationService.openModal([action.Next, routeId]) : this.navigationService.openModal(action.Next);
          }
        }
        break;
      case ComponentActionType.Link:
        let companies: Company[] = externalLinks['Companies'];
        // As we run through the .json file, we verify we find anything for each step,
        // and if we do not, we fallback to using the specified Next value on the action.
        let company: Company = companies.find(obj => obj.Name === companyName);
        if (!company) {
          this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
          break;
        }
        let country = company.Countries.find(obj => obj.Name === countryCode);
        if (!country) {
          this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
          break;
        }
        let language = country.Languages.find(obj => obj.Name === languageCode);
        if (!language) {
          this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
          break;
        }
        let externalLink = language.ExternalLinks.find(obj => obj.Key.trim().toLowerCase() === (componentName + "." + action.Name).trim().toLowerCase());
        if (!externalLink) {
          this.navigationService.openNewTab(action.Next, this.bigAl.appSettings.IsApp);
          break;
        }
        this.navigationService.openNewTab(externalLink.Value, this.bigAl.appSettings.IsApp);
        break;
      case ComponentActionType.File:

        let path = this.getAssetsFilePath(companyName, countryCode, languageCode);
        this.navigationService.openNewTab(path + action.Next, this.bigAl.appSettings.IsApp);
        break;
      case ComponentActionType.Phone:
        window.open('tel:' + action.Next, '_self');
        break;
      case ComponentActionType.Route:
        this.navigationService.forward(action.Next, routeId);
        break;
      default:
        if (action.Next === "close") {
          this.navigationService.back();
        }
        else {
          this.navigationService.forward(action.Next, routeId);
        }
        break;
    }
  }

  actionDocument(component: Object, name: string): void {
    this.config = this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.find(comp => comp.Name === component.constructor.name);

    if (this.config !== undefined) {
      const action = this.config.Actions.find(a => a.Name === name);
      if (action) {

        window.open('/assets/' + action.Next);
      }
    }
  }

  isFeature(name): boolean {
    name = name.toLowerCase();
    this.featuresConfig = this.bigAl.appSettings.FeatureComponentConfiguration.FeatureConfiguration.Features;

    if (this.featuresConfig !== undefined) {
      let feature = this.featuresConfig.find((k: FeatureStructure) => {
        if (k.Name === name) {
          if (this.bigAl.isUserLoggedIn && (k.ShowState & FeatureShowState.LoggedIn) === FeatureShowState.LoggedIn) {
            return true;
          }
          else if ((k.ShowState & FeatureShowState.StartScreen) === FeatureShowState.StartScreen) {
            return true;
          }
        }

        return false;
      });

      if (feature) {
        return true;
      }
    }
    return false;
  }

  // #region Helpers

  private createContent(configName: string, contentName: string): void {

    // Check if we've already attempted to create the compoent + content in this run.
    if (this.createdComponentContent.has(configName)) {
      let componentContent = this.createdComponentContent.get(configName);
      for (let i = 0; i < componentContent.length; i++) {
        if (componentContent[i] === contentName) {
          return;
        }
      }

      componentContent.push(contentName);
      this.createdComponentContent.set(configName, componentContent);
    }
    else {
      this.createdComponentContent.set(configName, [contentName]);
    }

    const compContent = new ComponentContent();
    compContent.Name = contentName;
    compContent.ShowContent = false;

    const compStructure = new ComponentStructure();
    compStructure.Name = configName;
    compStructure.Content = [compContent];

    const compConfig = new ComponentConfiguration();
    compConfig.Components = [compStructure];
    this.bigAl.postComponents(compConfig);
  }

  private createAction(configName: string, actionName: string): ComponentAction {
    const compAction = new ComponentAction();
    compAction.Name = actionName;

    // Any unknown path will go to the under construction page. 'Under-construction' does not actually match a path, but this will show in the browser url
    compAction.Next = 'under-construction';

    const compStructure = new ComponentStructure();
    compStructure.Name = configName;
    compStructure.Actions = [compAction];

    let compConfig = new ComponentConfiguration();
    compConfig.Components = [compStructure];
    this.bigAl.postComponents(compConfig);
    return compAction;
  }

  private getCompanyName(companyId: number): string {
    switch (companyId) {
      case 1:
        return "ald";
      case 2:
        return "nf-fleet";
    }
    return "ald";
  }

  private getAssetsFilePath(companyName: string, country: string, language: string): string {
    if (this.bigAl.defaultSettings.IsApp) {
      return this.bigAl.appSettings.DefaultFrontendUrl + "/assets/files/" + companyName + "/" + country + "/" + language + "/";
    }

    return "assets/files/" + companyName + "/" + country + "/" + language + "/";
  }

  // #endregion
}
