<ul class="user-menu-items">
  <div #decorativeAnchor class="decorative-anchor"></div>
  <li class="item" routerLinkActive="active">
    <a (click)="onClick($event)" routerLink="dashboard">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-feed"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_dashboard">
          <span translate-text>
            {{'MenuComponent.menu_dashboard'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>  
  <li class="item">
    <a (click)="lnkLogout()">
      <span class="icon-container">
        <mat-icon class="menu-icon" svgIcon="menu-more"></mat-icon>
      </span>
      <span class="item-text">
        <app-translate translationName="MenuComponent.menu_logout">
          <span translate-text>
            {{'MenuComponent.menu_logout'| translate}}
          </span>
        </app-translate>
      </span>
    </a>
  </li>
</ul>
