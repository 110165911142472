import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";

export interface IDocument {
  Id: string;
  FileName: string;
  MediaHeaderType: string;
  SortingVariables: {
    BusinessPartnerId: string,
    BusinessPartnerName: string
  },
  CreateDate: Date;
}

@Injectable({
  providedIn: "root"
})
export class DocumentStore {

  private documents: IDocument[] = null;

  private readonly documentStore: BehaviorSubject<IStoreResult<IDocument[]>> = new BehaviorSubject<IStoreResult<IDocument[]>>({ model: null, isInitial: true });
  onGetDocuments$ = this.documentStore.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  reset() {
    this.documentStore.next({ model: null, isInitial: true });
    this.documents = null;
  }

  // #region Requests

  getDocuments(userId: string, forceReload: boolean = false): void {
    // Entry found
    if (this.documents && !forceReload) {
      this.documentStore.next({ model: this.documents, isSuccess: true, isCached: true });
      return;
    }

    // Get entry from database
    this.apiService.getAsync<IDocument[]>(`${ApiUrl.GetDocuments}/${userId}`, this.settingStore.appSettings)
      .then((data: IDocument[]) => {
        this.documents = data;
        this.documentStore.next({ model: this.documents, isSuccess: true });
      })
      .catch((err: HttpErrorResponse) => {
        this.documents = null;
        this.documentStore.next({ model: this.documents, isSuccess: false, error: err });
      });
  }

  // #endregion
}
