

// Third-party global variables
declare const google: any;
export class GeoLocationHelper {


    /**
     * Returns the distance for the trip google map recomends for a start point and an end point
     * @param startLat the latitude of the start point
     * @param startLong the longitude of the start point
     * @param endLat the latitude of the start point
     * @param endLong the longitude of the start point
     */
    static getTripDistanceFromEndpoints(startLat: number, startLong: number, endLat: number, endLong: number): Promise<number> {
        let distanceService = new google.maps.DistanceMatrixService();

        let promise: Promise<number> = new Promise((resolve, reject) => {
            distanceService.getDistanceMatrix({
                origins: [startLat + ',' + startLong],
                destinations: [endLat + ',' + endLong],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                durationInTraffic: true,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    reject(-1);
                } else {
                    if (response.rows[0].elements[0].distance['value'] !== undefined) {
                        let distance = Math.round(response.rows[0].elements[0].distance['value'] / 1000);
                        resolve(distance);
                    }
                }
            });
        });

        return promise;
    }
}
