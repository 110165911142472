import { EventManager } from "@angular/platform-browser";
import { BigAlModule } from './../modules/bigal.module';
var DEFAULT_CONFIG = {
    breakpoints: {
        xss: 320,
        xs: 480,
        s: 768,
        m: 980
    }
};
var ScreenService = /** @class */ (function () {
    function ScreenService(eventManager, bigAl) {
        var _this = this;
        this.eventManager = eventManager;
        this.bigAl = bigAl;
        this.isDesktop = true;
        this.init();
        this.isDesktop = window.innerWidth > DEFAULT_CONFIG.breakpoints.m;
        this.bigAl.isInDesktopScreen = this.isDesktop;
        this.eventManager.addGlobalEventListener('window', 'resize', function () { return _this.onResize(); });
    }
    ScreenService.prototype.init = function () {
        this.isRetina = window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches);
        this.isMobileTablet = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) ? true : false;
        this.isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i) ? true : false;
        this.isIOS = navigator.userAgent.match(/(iPhone)|(iPod)/i) ? true : false;
        this.isAndroid = navigator.userAgent.match(/(android)/i) ? true : false;
        this.isTouch = "createTouch" in document;
    };
    ScreenService.prototype.onResize = function () {
        this.isDesktop = window.innerWidth > DEFAULT_CONFIG.breakpoints.m;
        this.bigAl.isInDesktopScreen = this.isDesktop;
    };
    return ScreenService;
}());
export { ScreenService };
