import { OnInit } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ComponentStructure } from '../../../models/component-config/component-structure.model';
import { ComponentContent } from '../../../models/component-config/component-content.model';
import { ComponentConfiguration } from '../../../models/component-config/component-configuration.model';
import { ComponentAction, ComponentActionType } from '../../../models/component-config/component-action.model';
import { NavigationService } from '../../../services/navigation.service';
var ComponentConfigComponent = /** @class */ (function () {
    function ComponentConfigComponent(bigAl, navigationService) {
        var _this = this;
        this.bigAl = bigAl;
        this.navigationService = navigationService;
        this.componentContentMaxIndex = [];
        this.componentActionMaxIndex = [];
        this.actionTypeStringValues = [];
        this.actionTypeIntValues = [];
        /*Enums are a bit funky to work with in TypeScript compared to C#,
        so in order to get the keys without their numeric value you have to splice.
        See here for docs: https://www.gurustop.net/blog/2016/05/24/how-to-use-typescript-enum-with-angular2/ */
        var options = (Object.keys(ComponentActionType));
        this.actionTypeStringValues = options.slice(options.length / 2);
        options.slice(0, options.length / 2).forEach(function (val) { return _this.actionTypeIntValues.push(parseInt(val)); });
    }
    ComponentConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.bigAl.appSettings === null) {
            this.bigAl.whoAmI();
        }
        this.componentConfigForm = new FormGroup({
            componentConfigFormArray: new FormArray([])
        });
        var contentMaxIndexArray = [];
        var actionMaxIndexArray = [];
        this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.forEach(function (element) {
            var index = _this.bigAl.appSettings.FeatureComponentConfiguration.ComponentConfiguration.Components.indexOf(element);
            _this.addComponent(element, index);
            if (element.Content) {
                element.Content.forEach(function (content) {
                    _this.addContent(content, index);
                });
                contentMaxIndexArray[index] = element.Content.length - 1;
            }
            if (element.Actions) {
                element.Actions.forEach(function (action) {
                    _this.addAction(action, index);
                });
                actionMaxIndexArray[index] = element.Actions.length - 1;
            }
        });
        this.componentContentMaxIndex = contentMaxIndexArray;
        this.componentActionMaxIndex = actionMaxIndexArray;
        this.minIndex = 0;
    };
    ComponentConfigComponent.prototype.initComponentConfig = function (element) {
        var elementName = "";
        if (element && element.Name) {
            elementName = element.Name;
        }
        this.componentContentMaxIndex.unshift(999);
        this.componentActionMaxIndex.unshift(999);
        this.minIndex += 1;
        return new FormGroup({
            componentTitle: new FormControl(elementName, [Validators.required]),
            componentConfigContentFormArray: new FormArray([]),
            componentConfigActionFormArray: new FormArray([])
        });
    };
    ComponentConfigComponent.prototype.initContent = function (content) {
        return new FormGroup({
            componentContentCheckbox: new FormControl(content.ShowContent),
            componentContent: new FormControl(content.Name, [Validators.required]),
        });
    };
    ComponentConfigComponent.prototype.initAction = function (action) {
        return new FormGroup({
            componentActionNext: new FormControl(action.Next, [Validators.required]),
            componentActionType: new FormControl(action.Type, [Validators.required]),
            componentAction: new FormControl(action.Name, [Validators.required]),
        });
    };
    ComponentConfigComponent.prototype.addComponent = function (component, index) {
        var control = this.componentConfigForm.get('componentConfigFormArray');
        control.insert(index, this.initComponentConfig(component));
    };
    ComponentConfigComponent.prototype.addContent = function (content, componentIndex) {
        var formGroup = this.componentConfigForm.get('componentConfigFormArray');
        var control = formGroup.controls[componentIndex].get('componentConfigContentFormArray');
        if (!content) {
            content = new ComponentContent();
            content.ShowContent = true;
        }
        control.push(this.initContent(content));
    };
    ComponentConfigComponent.prototype.addAction = function (action, componentIndex) {
        var formGroup = this.componentConfigForm.get('componentConfigFormArray');
        var control = formGroup.controls[componentIndex].get('componentConfigActionFormArray');
        if (!action) {
            action = new ComponentAction();
        }
        control.push(this.initAction(action));
    };
    ComponentConfigComponent.prototype.getComponentConfigFormArray = function (form) {
        return form.controls.componentConfigFormArray.controls;
    };
    ComponentConfigComponent.prototype.getComponentConfigContentFormArray = function (form) {
        return form.controls.componentConfigContentFormArray.controls;
    };
    ComponentConfigComponent.prototype.getComponentConfigActionFormArray = function (form) {
        return form.controls.componentConfigActionFormArray.controls;
    };
    ComponentConfigComponent.prototype.saveComponents = function () {
        // Convert form arrays to ComponentConfiguration
        var componentConfigFormArray = this.componentConfigForm.value.componentConfigFormArray;
        var components = new Array();
        for (var index = 0; index < componentConfigFormArray.length; index++) {
            var element = componentConfigFormArray[index];
            var component = new ComponentStructure();
            component.Name = element.componentTitle;
            var contents = new Array();
            var componentContents = element.componentConfigContentFormArray;
            for (var index_1 = 0; index_1 < componentContents.length; index_1++) {
                var content = new ComponentContent();
                content.Name = componentContents[index_1].componentContent;
                content.ShowContent = componentContents[index_1].componentContentCheckbox;
                contents.push(content);
            }
            component.Content = contents;
            components.push(component);
            var actions = new Array();
            var componentActions = element.componentConfigActionFormArray;
            for (var index_2 = 0; index_2 < componentActions.length; index_2++) {
                var action = new ComponentAction();
                action.Name = componentActions[index_2].componentAction;
                action.Next = componentActions[index_2].componentActionNext;
                action.Type = parseInt(componentActions[index_2].componentActionType);
                actions.push(action);
            }
            component.Actions = actions;
        }
        var componentConfiguration = new ComponentConfiguration();
        componentConfiguration.Components = components;
        this.bigAl.postComponents(componentConfiguration);
    };
    ComponentConfigComponent.prototype.action = function () {
        this.navigationService.back();
    };
    return ComponentConfigComponent;
}());
export { ComponentConfigComponent };
