var DriverJournalMonthlyLog = /** @class */ (function () {
    function DriverJournalMonthlyLog(ContractId, PersonId, Year, Month, StartKm, EndKm, BusinessKm, RegistrationNumber, IsExtraCarSelected, BusinessKmTotalExtraCar, TotalKmExtraCar) {
        this.ContractId = ContractId;
        this.PersonId = PersonId;
        this.Year = Year;
        this.Month = Month;
        this.StartKm = StartKm;
        this.EndKm = EndKm;
        this.BusinessKm = BusinessKm;
        this.RegistrationNumber = RegistrationNumber;
        this.IsExtraCarSelected = IsExtraCarSelected;
        this.BusinessKmTotalExtraCar = BusinessKmTotalExtraCar;
        this.TotalKmExtraCar = TotalKmExtraCar;
    }
    DriverJournalMonthlyLog.prototype.getDomainName = function () {
        return "DriverJournalMonthlyLog";
    };
    return DriverJournalMonthlyLog;
}());
export { DriverJournalMonthlyLog };
