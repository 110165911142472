import { AldPosition } from "../geo-tracking-device-motion/ald-position.model";

export class DriverJournalTrip {
  constructor(
    public TripId: string,
    public LogDailyId: string,
    public DataSource: number,
    public IsTestData: boolean,
    public IsCompleted: boolean,
    public TripPositions: Array<TripPart>
  ) { }
}

export class TripPart {
  constructor(
    public Positions: Array<AldPosition>,
    public IsLinking?: boolean,
    public DestinationPosition?: any,
    public OriginPosition?: any
  ) { }
}

