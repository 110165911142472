import { OnInit } from '@angular/core';
import { NavigationService } from '../../../../../services/navigation.service';
import { UserRoleType } from '../../../../../models/enums/user-role-type.enum';
import { AppUserStore } from '../../../../../stores/DataStores';
import { BigAlModule } from '../../../../../modules/bigal.module';
import { FuelRules } from '../../../../../models/enums/fuelrules.enum';
var DriverjournalMonthlyUpdateSuccessComponent = /** @class */ (function () {
    function DriverjournalMonthlyUpdateSuccessComponent(bigAl, navigationService, appUserStore) {
        this.bigAl = bigAl;
        this.navigationService = navigationService;
        this.appUserStore = appUserStore;
        this.fuelRule = null;
        this.driverJournalMonthly = null;
        this.isBusinessUser = false;
    }
    DriverjournalMonthlyUpdateSuccessComponent.prototype.ngOnInit = function () {
        this.driverJournalMonthly = this.bigAl.currentDriverJournalMonthly;
        this.fuelRule = FuelRules[this.bigAl.currentFuelRules];
        this.isBusinessUser = this.appUserStore.appUser.Roles.some(function (r) { return r === UserRoleType.BusinessUser; });
    };
    return DriverjournalMonthlyUpdateSuccessComponent;
}());
export { DriverjournalMonthlyUpdateSuccessComponent };
