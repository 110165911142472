import { OnDestroy, ElementRef, AfterViewInit, NgZone, ViewChildren, QueryList } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { MatStepper, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MapsAPILoader } from '@agm/core/services';

// Common
import { BigAlModule } from '../../../modules/bigal.module';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../directives/app-date-adapter';

// Types
import { MapSettings, MapSettingsDirections } from './../../../models/map/map-settings.model';
import { Form } from '../../../models/form.model';
import { TranslationTagType } from '../../../models/enums/translation-tag-type.enum';

// Services
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FileUploadService } from '../../../services/files/file-upload.service';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { PhoneCountryCodeService } from '../../../services/phone-country-code.service';
import { ITollStation } from '../../../sharedtypes/interfaces/sharedtypes.interface';
import { ToastService } from '../../../services/toast.service';
import { GeoLocationHelper } from '../../../helpers/geolocationHelper';

declare const google: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    TranslatePipe,
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {

  private allSubscriptions: Subscription = new Subscription();
  private isSubmitting: boolean = false;

  // data variables
  @Input() isLinear = true;
  @Input() formObject;
  @Input() ruleObject;
  @Input() hasSteps = false;
  @Input() isReadyForInit?: Observable<boolean>;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  tollStations: ITollStation[] = [];
  tollStationMarkerIcon: string = "./assets/ald/images/gmap/tollstation.png";
  isTollStationsLoaded: boolean;

  formModel: Form;
  isEditing = false;
  isChecked = true;
  fileNames: string[] = [];
  fileUploadError: boolean;
  fileErrorType: string;
  locationHelper: GeoLocationHelper;

  @ViewChildren("searchLocationInput") public searchLocationInputs: QueryList<any>;

  constructor(
    private translatePipe: TranslatePipe,
    public bigAl: BigAlModule,
    public theBen: ComponentConfigService,
    private fb: FormBuilder,
    private validatorFactory: ValidatorFactory,
    private fileUploadService: FileUploadService,
    private translateService: CustomTranslateService,
    public mapsLoader: MapsAPILoader,
    public ngZone: NgZone,
    private toaster: ToastService,
    private phoneCountryCodeService: PhoneCountryCodeService,
  ) {
  }

  ngOnInit(): void {
    // data calls
    this.watchData();
    this.getData();

    //
    if (this.isReadyForInit !== null && this.isReadyForInit !== undefined) {
      this.allSubscriptions.add(
        this.isReadyForInit.subscribe(isReady => {
          if (isReady) {
            this.initForm();
            this.subscribe();
          }
        })
      );
    } else {
      this.initForm();
      this.subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.allSubscriptions) {
      this.allSubscriptions.unsubscribe();
    }
    if (this.formModel) {
      this.formModel.unsubscribe();
    }
    this.close.emit(true);
  }

  ngAfterViewInit() {
    const options = {
      componentRestrictions: { country: this.bigAl.appSettings.CountryCode },
      fields: ["formatted_address", "geometry"]
    };
    if (this.searchLocationInputs) {
      this.searchLocationInputs.forEach(elementRef => this.listenToGooglePlaces(elementRef, options));
    }
  }

  isContent(name: string) {
    return this.theBen.isContent(this, name);
  }

  watchData() { }

  getData() { }

  initForm() {
    this.formModel = new Form(
      this.formObject,
      this.ruleObject,
      this.translatePipe,
      this.bigAl,
      this.theBen, this.fb,
      this.validatorFactory,
      this.fileUploadService,
      this.translateService,
      this.phoneCountryCodeService
    );
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  trimValue(name, index) {
    let control = this.formModel.formArray.controls[index].get(name);
    if (control.value) {
      control.setValue(control.value.trim());
    }
  }

  goForward(stepper: MatStepper, i: number) {
    this.formModel.goForward(i);
    stepper.next();
  }

  submit(stepperForm: FormGroup) {
    this.onSubmit(stepperForm.getRawValue());
  }

  onSubmit(value) {
    this.formModel.submitTouched = true;
    if (this.formModel.validate()) {
      let action = "post" + this.formObject.Name;
      if (this.formModel.formArray.length > 1) {
        this.bigAl[action](value);
      }
      else if (this.formModel.formArray.length === 1) {
        this.bigAl[action](value["formArray"][0]);
      }
      this.close.emit(true);
    }
  }

  private subscribe() {

    this.allSubscriptions.add(this.translateService.isEditing.subscribe(editing => {
      this.isEditing = editing;
    }));

    this.allSubscriptions.add(
      this.fileUploadService.getuploadErrorObservable().subscribe(uploadError => {
        this.fileUploadError = uploadError;
      }));

    this.allSubscriptions.add(
      this.fileUploadService.getErrorTypeObservable().subscribe(errorType => {
        this.fileErrorType = errorType;
      })
    );

    this.allSubscriptions.add(this.fileUploadService.getFilePathObservable().subscribe(file => {
      if (file) {
        this.fileNames.push(file.FileName);
      }
    }));
  }



  public getLabelTranslationTagType(): TranslationTagType {
    return TranslationTagType.Label;
  }

  onCheckboxChange(checked) {
    (checked === true) ? checked = false : checked = true;
  }

  private getGeoLocation(address: string): Observable<any> {
    let geocoder = new google.maps.Geocoder;
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next(results[0]);
          observer.complete();
        } else {
          console.log('Error: ', results, ' & Status: ', status);
          observer.error();
        }
      });
    });
  }

  private listenToGooglePlaces(inputElement: ElementRef, options) {
    let property = this.formObject.Steps[0].Properties.find(prop => prop.Name === inputElement.nativeElement['name']);

    let control = this.formModel.formArray.controls[0].get(property.Name);
    if (control && !control.touched && control.value) {
      this.mapsLoader.load().then(() => {
        this.getGeoLocation(control.value).subscribe((resp) => {
          if (property.GoogleSettings) {
            property.GoogleSettings.Influence
              .forEach(toInfluence => {

                let mapProperty = this.formObject.Steps[0].Properties.find(prop => prop.Name === toInfluence);
                if (mapProperty) {

                  let mapControl = this.formModel.formArray.controls[0].get(mapProperty.Name);
                  let newValue = this.googleInputAction(mapControl.value, property, resp);

                  mapControl.setValue(newValue);
                  mapProperty.Value = newValue;

                  if (newValue.CanShowMap) {
                    mapProperty.ShouldShow = true;
                    mapProperty['Hidden'] = false;
                  }
                }
              });
          }
        });
      });
    }

    // load Places Autocomplete
    this.mapsLoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(inputElement.nativeElement, options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          let place = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          control.setValue(place.formatted_address);

          if (property.GoogleSettings) {
            property.GoogleSettings.Influence.forEach(toInfluence => {

              let mapProperty = this.formObject.Steps[0].Properties.find(prop => prop.Name === toInfluence);
              if (mapProperty) {
                let mapControl = this.formModel.formArray.controls[0].get(mapProperty.Name);
                let newValue = this.googleInputAction(mapControl.value, property, place);

                mapControl.setValue(newValue);
                mapProperty.Value = newValue;

                if (newValue.CanShowMap) {
                  mapProperty.ShouldShow = true;
                  mapProperty['Hidden'] = false;
                  this.setChangesFromMap(mapProperty);
                }
              }
            });
          }

        });

      });
    });
  }

  private googleInputAction(settings, property, googleResults): MapSettings {

    let mapSettings: MapSettings = null;
    if (!settings) {
      mapSettings = new MapSettings(
        true,
        null,
        null,
        new MapSettingsDirections(
          null,
          null,
          null,
          null
        ),
        null
      );
    }
    else {
      mapSettings = settings;
    }

    if (!mapSettings.Lat) {
      mapSettings.Lat = googleResults.geometry.location.lat();
      mapSettings.Lng = googleResults.geometry.location.lng();
    }

    if (mapSettings.RequiresDirections && !mapSettings.Directions) {
      mapSettings.Directions = new MapSettingsDirections(
        null,
        null,
        null,
        null
      );
    }

    if (mapSettings.RequiresDirections && property.GoogleSettings.Directions) {
      switch (property.GoogleSettings.Directions) {
        case 'Origin':
          mapSettings.Directions.OriginAddress = googleResults.formatted_address;
          mapSettings.Directions.OriginPosition = { lat: googleResults.geometry.location.lat(), lng: googleResults.geometry.location.lng() };
          break;
        case 'Destination':
          mapSettings.Directions.DestinationAddress = googleResults.formatted_address;
          mapSettings.Directions.DestinationPosition = { lat: googleResults.geometry.location.lat(), lng: googleResults.geometry.location.lng() };
          break;
        default:
          break;
      }
    }

    if (mapSettings.TollStations) {
      this.tollStations = Array.from(mapSettings.TollStations);
    }

    if (mapSettings.RequiresDirections) {
      if (mapSettings.Directions.OriginPosition && mapSettings.Directions.DestinationPosition) {
        mapSettings.CanShowMap = true;
      }
      else {
        mapSettings.CanShowMap = false;
      }
    }
    else {
      mapSettings.CanShowMap = true;
    }

    return mapSettings;
  }

  private setChangesFromMap(mapField) {
    if (mapField.OnChange && mapField.OnChange.length > 0) {
      mapField.OnChange.forEach(changeAction => {
        changeAction.Actions.forEach(action => {
          switch (action.Type) {
            case 'show':
              action.Influence.forEach(toInfluence => {
                let findField = this.formObject.Steps[0].Properties.find(prop => prop.Name === toInfluence);
                if (findField) {
                  findField['Hidden'] = false;
                }
              });
              break;
            case 'calculateDistance':
              action.Influence.forEach(toInfluence => {
                GeoLocationHelper.getTripDistanceFromEndpoints(mapField.Value.Directions.OriginPosition.lat, mapField.Value.Directions.OriginPosition.lng,
                  mapField.Value.Directions.DestinationPosition.lat, mapField.Value.Directions.DestinationPosition.lng).then(distance => {
                    let distanceControl = this.formModel.formArray.controls[0].get(toInfluence);
                    if (distance >= 0) {
                      distanceControl.setValue(distance);
                    }
                    else {
                      distanceControl.setValue(null);
                    }
                  });
              });
              break;
            default:

              break;
          }
        });
      });
    }
  }
}
