//
import { GloveboxComponent } from './../components/features/glovebox/glovebox.component';
import { BaseComponent } from './../components/init/base.component';
import { PageNotFoundComponent } from './../components/shared/page-not-found/page-not-found.component';
import { UrlSegment } from '@angular/router';
import { DigitalServiceCardComponent } from '../components/features/contact-information/digital-service-card/digital-service-card.component';
import { LoginGuard } from '../guards/login.guard';
export function isDeviceStartupFolder(url) {
    if (url.length === 1 &&
        url[0].path.endsWith("/wwww")) {
        // Do the redirect
        return null;
    }
    // Go to what was requested
    return ({ consumed: url });
}
var appRoutes = [
    {
        path: '',
        pathMatch: 'full',
        component: BaseComponent
    },
    {
        path: 'cars',
        loadChildren: "./../components/features/cars/cars.module#CarsModule"
    },
    {
        path: 'service-card',
        component: DigitalServiceCardComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'glovebox',
        component: GloveboxComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'services',
        loadChildren: "./../components/features/workshops/workshops.module#WorkshopsModule"
    },
    {
        path: 'more',
        loadChildren: "./../components/pages/more-page/more.module#MoreModule"
    },
    {
        path: 'journal',
        loadChildren: "./../components/pages/journal/journal.module#JournalModule"
    },
    {
        path: 'recordtrip',
        loadChildren: "./../components/features/record-trip/record-trip.module#RecordTripModule"
    },
    {
        path: 'fleet',
        loadChildren: "./../components/features/fleet-admin/fleet-user.module#FleetUserModule"
    },
    {
        path: 'dashboard',
        loadChildren: "./../components/features/business-admin/business-admin.module#BusinessAdminModule"
    },
    {
        path: 'parking',
        loadChildren: "./../components/features/parking/parking.module#ParkingModule"
    },
    {
        matcher: isDeviceStartupFolder,
        redirectTo: 'cars'
    },
    // needs to be the last one always
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
