import { BehaviorSubject, Subscription } from "rxjs";
// Common
import { BigAlModule } from "../../modules/bigal.module";
// Services
import { GeoLocationService } from "./geolocation.service.v2";
// Types
import { DriverJournalDailyLog } from "../../models/driverjournal/driverJournalDailyLog.model";
import { TripTypeEnum } from "../../models/enums/TripType.enum";
import { DriverJournalTrip, TripPart } from "../../models/driverjournal/driverjournal-trip.model";
import { ComponentConfigService } from "../componentConfig.service";
import { Constants } from "../../helpers/constants";
var RecordTripService = /** @class */ (function () {
    function RecordTripService(theBen, bigAl, geolocationService) {
        this.theBen = theBen;
        this.bigAl = bigAl;
        this.geolocationService = geolocationService;
        this.subscriptions = new Subscription();
        this.recordingTripMenue = new BehaviorSubject(false);
        this.useTestCoords = false;
        this.dailyLog = null;
        this.registerSubscription();
    }
    RecordTripService.prototype.registerSubscription = function () {
        var _this = this;
        this.subscriptions.add(this.bigAl.getDataStream().subscribe(function (event) {
            if (event === Constants.Event_CurrentVehicle || event === Constants.Event_CurrentContracts) {
                _this.setData();
            }
        }));
    };
    RecordTripService.prototype.setData = function () {
        if (this.bigAl.currentVehicleContract) {
            this.contractId = this.bigAl.currentVehicleContract.Id;
        }
        if (this.bigAl.currentVehicle) {
            this.regNo = this.bigAl.currentVehicle.RegistrationNumber;
        }
    };
    RecordTripService.prototype.showMenu = function () {
        this.toggleRecordingTripMenu(true);
    };
    RecordTripService.prototype.hideMenu = function () {
        this.toggleRecordingTripMenu(false);
    };
    RecordTripService.prototype.showRecordingTripMenue = function () {
        return this.recordingTripMenue.asObservable();
    };
    RecordTripService.prototype.toggleRecordingTripMenu = function (value) {
        this.recordingTripMenue.next(value);
    };
    RecordTripService.prototype.saveTrip = function (tripType, purpose, isExtraCar, isFinalSave) {
        var _this = this;
        var prom = new Promise(function (resolve, reject) {
            if (!_this.dailyLog) {
                _this.dailyLog = _this.getDefaultDailyLog();
            }
            var positions = _this.geolocationService.getPositions(); // Get a copy of the recorded positions
            if (positions.length <= 4) {
                reject({ success: false, message: "no-positions-recorded" });
            }
            else {
                var distance_1 = Math.round(_this.geolocationService.getDistance());
                var dataSource = (_this.dailyLog.TripType === TripTypeEnum.BusinessTrip) ? 1 : 0;
                var tripPart = new TripPart(positions);
                var trip_1 = new DriverJournalTrip(null, null, dataSource, _this.useTestCoords, isFinalSave, [tripPart]);
                try {
                    _this.geolocationService.getAddressFromLatLong(positions[0].Latitude, positions[0].Longitude).then(function (start) {
                        _this.dailyLog.Start = start;
                        _this.geolocationService.getAddressFromLatLong(positions[positions.length - 1].Latitude, positions[positions.length - 1].Longitude).then(function (end) {
                            var logId;
                            var tripId;
                            // Store id if it has been saved previusly
                            if (_this.dailyLog && _this.dailyLog.Trip && _this.dailyLog.Trip.LogDailyId) {
                                logId = _this.dailyLog.Trip.LogDailyId;
                            }
                            if (_this.dailyLog && _this.dailyLog.Trip && _this.dailyLog.Trip.TripId) {
                                tripId = _this.dailyLog.Trip.TripId;
                            }
                            _this.dailyLog.Trip = trip_1;
                            // Update if it has been set
                            if (logId) {
                                _this.dailyLog.Trip.LogDailyId = logId;
                            }
                            if (tripId) {
                                _this.dailyLog.Trip.TripId = tripId;
                            }
                            _this.dailyLog.DataSource = trip_1.DataSource;
                            _this.dailyLog.End = end;
                            _this.dailyLog.Distance = distance_1;
                            _this.dailyLog.TripType = tripType;
                            _this.dailyLog.Purpose = purpose;
                            _this.dailyLog.IsExtraCarSelected = isExtraCar;
                            if (_this.dailyLog && _this.dailyLog.Trip.LogDailyId) {
                                _this.bigAl.putDriverJournalDailyLog(_this.dailyLog)
                                    .then(function (log) {
                                    resolve({ success: true, message: "" });
                                })
                                    .catch(function (err) {
                                    if (isFinalSave) {
                                        reject({ success: false, message: "failed-to-save" });
                                    }
                                });
                            }
                            else {
                                _this.bigAl.postDriverJournalDailyLog(_this.dailyLog, isFinalSave)
                                    .then(function (log) {
                                    _this.dailyLog.Trip.LogDailyId = log.Trip.LogDailyId;
                                    _this.dailyLog.Trip.TripId = log.Trip.TripId;
                                    resolve({ success: true, message: "" });
                                })
                                    .catch(function (err) {
                                    if (isFinalSave) {
                                        reject({ success: false, message: "failed-to-save" });
                                    }
                                });
                            }
                        }).catch(function (err) {
                            if (isFinalSave) {
                                reject({ success: false, message: "critical-error" });
                            }
                        });
                    }).catch(function (err) {
                        if (isFinalSave) {
                            reject({ success: false, message: "critical-error" });
                        }
                    });
                }
                catch (err) {
                    if (isFinalSave) {
                        reject({ success: false, message: "critical-error" });
                    }
                }
            }
        });
        return prom;
    };
    RecordTripService.prototype.initialTripSave = function (tripType, purpose, isExtraCar) {
        var _this = this;
        var prom = new Promise(function (resolve, reject) {
            if (!_this.dailyLog) {
                _this.dailyLog = _this.getDefaultDailyLog();
            }
            var positions = _this.geolocationService.getPositions(); // Get a copy of the recorded positions
            var distance = Math.round(_this.geolocationService.getDistance());
            var dataSource = (_this.dailyLog.TripType === TripTypeEnum.BusinessTrip) ? 1 : 0;
            var tripPart = new TripPart(positions);
            var trip = new DriverJournalTrip(null, null, dataSource, _this.useTestCoords, false, [tripPart]);
            try {
                _this.dailyLog.Trip = trip;
                _this.dailyLog.DataSource = trip.DataSource;
                _this.dailyLog.End = "";
                _this.dailyLog.Start = "";
                _this.dailyLog.Distance = distance;
                _this.dailyLog.TripType = tripType;
                _this.dailyLog.Purpose = purpose;
                _this.dailyLog.IsExtraCarSelected = isExtraCar;
                _this.bigAl.postDriverJournalDailyLog(_this.dailyLog, false)
                    .then(function (log) {
                    _this.dailyLog.Trip.LogDailyId = log.Trip.LogDailyId;
                    _this.dailyLog.Trip.TripId = log.Trip.TripId;
                    resolve({ success: true, message: "" });
                })
                    .catch(function (err) {
                });
            }
            catch (err) {
                reject({ success: false, message: "critical-error" });
            }
        });
        return prom;
    };
    RecordTripService.prototype.getDefaultDailyLog = function () {
        var model = new DriverJournalDailyLog();
        model.ContractId = this.contractId;
        model.RegistrationNumber = this.regNo;
        model.IsCreate = true;
        model.Date = new Date().toLocaleDateString('sv');
        return model;
    };
    RecordTripService.prototype.resetTrip = function () {
        this.dailyLog = this.getDefaultDailyLog();
    };
    return RecordTripService;
}());
export { RecordTripService };
