import { OnInit } from '@angular/core';
import { BigAlModule } from '../../../modules/bigal.module';
import { NavigationService } from '../../../services/navigation.service';
import { ApiService } from '../../../services/api.service';
var MissingTranslationComponent = /** @class */ (function () {
    function MissingTranslationComponent(apiService, bigAl, navigationService) {
        this.apiService = apiService;
        this.bigAl = bigAl;
        this.navigationService = navigationService;
        this.translations = [];
    }
    MissingTranslationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.getAsync("GetMissingTranslations?countryCode=" + this.bigAl.appSettings.CountryCode, this.bigAl.appSettings)
            .then(function (data) {
            _this.translations = data;
        })
            .catch(function (err) {
            _this.translations = [];
        });
    };
    MissingTranslationComponent.prototype.back = function () {
        this.navigationService.back();
    };
    return MissingTranslationComponent;
}());
export { MissingTranslationComponent };
