import { OnInit, OnDestroy } from "@angular/core";
import { AppUserStore } from "../../stores/DataStores";
// Services
import { NavigationService } from '../../services/navigation.service';
// Models
import { UserRoleType } from './../../models/enums/user-role-type.enum';
import { BigAlModule } from './../../modules/bigal.module';
// Helpers
import { Constants } from "./../../helpers/constants";
var BaseComponent = /** @class */ (function () {
    function BaseComponent(bigAl, navigationService, appUserStore) {
        this.bigAl = bigAl;
        this.navigationService = navigationService;
        this.appUserStore = appUserStore;
        this.subscription = null;
    }
    BaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        var roles = this.bigAl.getPreviousUserRoles();
        if (roles.some(function (r) { return r === UserRoleType.Driver || r === UserRoleType.SystemAdminDriver; })) {
            this.navigationService.forward('cars', null);
        }
        else if (roles.some(function (r) { return r === UserRoleType.FleetAdmin || r === UserRoleType.FleetManager || r === UserRoleType.SystemAdminFleetAdmin; })) {
            // In order to avoid race conditions, if bigAl has yet to load the currentUser, we subscribe to that event
            // and revert the driver takeover once possible. If the currentUser has loaded, we revert it immidiately.
            var isInTakeOver = this.bigAl.getStorageValueForIsInTakeOverMode();
            if (isInTakeOver && !this.appUserStore.appUser) {
                this.subscription = this.bigAl.getDataStream().subscribe(function (lastEvent) {
                    if (lastEvent === Constants.Event_currentUser) {
                        _this.bigAl.revertTakeOverDriver();
                    }
                });
            }
            else if (isInTakeOver && this.appUserStore.appUser) {
                this.bigAl.revertTakeOverDriver();
            }
            this.navigationService.forward('fleet/overview', null);
        }
        else {
            this.navigationService.forward('cars', null);
        }
    };
    BaseComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return BaseComponent;
}());
export { BaseComponent };
