import { BehaviorSubject } from 'rxjs';
var TableDataUpdatesService = /** @class */ (function () {
    function TableDataUpdatesService() {
        this.email = new BehaviorSubject("");
    }
    TableDataUpdatesService.prototype.getData = function () {
        return this.email.asObservable();
    };
    TableDataUpdatesService.prototype.setData = function (email) {
        this.email.next(email);
    };
    return TableDataUpdatesService;
}());
export { TableDataUpdatesService };
