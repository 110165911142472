<app-modal size="small" *ngIf="isDataAvailable()">
    <div modal-body>
        <h2 class="element-title">
            <app-translate translationName="DeleteBusinessUserComponent.header_t">
                <span translate-text>{{ "DeleteBusinessUserComponent.header_t" | translate}}</span>
            </app-translate>
        </h2>
        <p>
            <app-translate translationName="DeleteBusinessUserComponent.description_p">
                <span translate-text>{{ "DeleteBusinessUserComponent.description_p" | translate}}</span>
            </app-translate>:&nbsp; {{ businessUser.Username }}
        </p>
    </div>
    <div modal-footer>
        <button mat-button (click)="action('close')">
            <app-translate translationName="DeleteBusinessUserComponent.cancel_b">
                <span translate-text>{{ "DeleteBusinessUserComponent.cancel_b" | translate}}</span>
            </app-translate>
        </button>
        <button mat-raised-button color="warn" (click)="confirmDeleteBusinessUser()">
            <mat-icon class="icon-btn icon-md" svgIcon="delete"></mat-icon>
            <app-translate translationName="DeleteBusinessUserComponent.delete_b">
                <span translate-text>{{ "DeleteBusinessUserComponent.delete_b" | translate}}</span>
            </app-translate>
        </button>
    </div>
</app-modal>
