/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../translate/translate.component.ngfactory";
import * as i2 from "../translate/translate.component";
import * as i3 from "../../../translation/customTranslateService";
import * as i4 from "../../../services/navigation.service";
import * as i5 from "../../../services/modals/modal.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./page-not-found.component";
import * as i13 from "@angular/router";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "element-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "sub-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "div", [["class", "primary-border-box center-box-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "h2", [["class", "element-title primary-lighter-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "app-translate", [["translationName", "PageNotFoundComponent.under_construction_t"]], null, null, null, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(5, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", " "])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 6, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i7.View_MatButton_0, i7.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(12, 0, null, 0, 4, "app-translate", [["translationName", "PageNotFoundComponent.under_construction_home_t"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TranslateComponent_0, i1.RenderType_TranslateComponent)), i0.ɵdid(13, 245760, null, 0, i2.TranslateComponent, [i3.CustomTranslateService, i4.NavigationService, i5.ModalService], { translationName: [0, "translationName"] }, null), (_l()(), i0.ɵeld(14, 0, null, 0, 2, "span", [["translate-text", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", " "])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(17, 0, null, null, 0, "img", [["src", "assets/ald/images/Temp/papillon.gif"], ["style", "width: 200px; height: 200px;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "PageNotFoundComponent.under_construction_t"; _ck(_v, 5, 0, currVal_0); var currVal_4 = "primary"; _ck(_v, 11, 0, currVal_4); var currVal_5 = "PageNotFoundComponent.under_construction_home_t"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("PageNotFoundComponent.under_construction_t")); _ck(_v, 7, 0, currVal_1); var currVal_2 = (i0.ɵnov(_v, 11).disabled || null); var currVal_3 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_6 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("PageNotFoundComponent.under_construction_home_t")); _ck(_v, 15, 0, currVal_6); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-not-found-component", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i12.PageNotFoundComponent, [i13.Router], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("page-not-found-component", i12.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
