import { BehaviorSubject } from "rxjs";
import { BigAlModule } from "../../modules/bigal.module";
import { ComponentConfigService } from "../componentConfig.service";
var DeviceMotionService = /** @class */ (function () {
    function DeviceMotionService(bigAl, theBen) {
        var _this = this;
        this.bigAl = bigAl;
        this.theBen = theBen;
        this.dataSource = new BehaviorSubject("still");
        this.motion = function (e) {
            var acc = e.acceleration;
            if (!acc.hasOwnProperty('x')) {
                acc = e.accelerationIncludingGravity;
            }
            if (!acc.x || !_this.accTreshold) {
                return;
            }
            if (Math.abs(acc.x) >= _this.accTreshold &&
                Math.abs(acc.y) >= _this.accTreshold &&
                Math.abs(acc.z) >= _this.accTreshold) {
                _this.setMoving("moving");
            }
            else {
                if (_this.dataSource && _this.dataSource.value !== "still") {
                    _this.setMoving("still");
                }
            }
        };
        this.bigAl.getDataStream().subscribe(function (event) {
            if (event === "currentDrivingSettings") {
                _this.accTreshold = _this.bigAl.currentDrivingSettings.Acceleration;
            }
        });
        if (this.bigAl.currentDrivingSettings === null) {
            this.bigAl.getDrivingSettings();
        }
        window.addEventListener('devicemotion', this.motion, false);
    }
    DeviceMotionService.prototype.getMoving = function () {
        return this.dataSource.asObservable();
    };
    DeviceMotionService.prototype.setMoving = function (data) {
        this.dataSource.next(data);
    };
    return DeviceMotionService;
}());
export { DeviceMotionService };
