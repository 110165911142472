var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TableService } from './table.service';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsStore } from '../../stores/DataStores';
var GloveboxTableService = /** @class */ (function (_super) {
    __extends(GloveboxTableService, _super);
    function GloveboxTableService(searchPipe, translateService, settingsStore) {
        var _this = _super.call(this, translateService, settingsStore) || this;
        _this.searchPipe = searchPipe;
        _this.translateService = translateService;
        _this.settingsStore = settingsStore;
        return _this;
    }
    GloveboxTableService.prototype.setSubset = function (filter) {
        var subset = [];
        if (filter) {
            filter = filter.toLowerCase().trim();
            for (var i = 0; i < this.data.value.length; i++) {
                var element = this.data.value[i];
                // if (this.matchesFilter(user, "Username", filter) || this.matchesFilter(user, "Email", filter) || this.matchesFilter(user, "RegistrationNumber", filter) || this.matchesFilter(user, "Name", filter)) {
                subset.push(element);
                // }
            }
        }
        else {
            subset = this.data.value;
        }
        this.filteredData.next(this.searchPipe.transform(subset, filter));
    };
    GloveboxTableService.prototype.matchesFilter = function (document, propertyName, filter) {
        return document[propertyName] !== null && document[propertyName].toLowerCase().trim().includes(filter);
    };
    return GloveboxTableService;
}(TableService));
export { GloveboxTableService };
