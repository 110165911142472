import { FormGroup } from '@angular/forms';
import { ComponentConfigService } from './../../../services/componentConfig.service';
import { Component } from '@angular/core';
import { FormObject } from '../../../models/form-object.model';
import { BigAlModule } from '../../../modules/bigal.module';
import { AbstractFeature, PrefilledContentEntry } from '../../features/feature.abstract';
import { AppUserStore } from '../../../stores/DataStores';
import { ChangePasswordForm } from '../../../models/changepassword.model';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordFormComponent extends AbstractFeature {
    // form variable
    public form: FormGroup;
    public formObject: FormObject;

    // data variables
    public currentLogin: ChangePasswordForm;
    closeButton: boolean = false;
    constructor(
        protected bigAl: BigAlModule,
        protected theBen: ComponentConfigService,
        private appUserStore: AppUserStore,
    ) {
        super(bigAl, theBen);
    }

    public getFormObject() {
        let prefilledEntries = new Map<string, PrefilledContentEntry[]>();
        let formObject = super.getFormObject("ChangePasswordForm", null);
        return formObject;
    }

    public getRuleObject() {
        let ruleObject = super.getRuleObject("ChangePasswordForm");
        return ruleObject;
    }

    setEventsToWatch() {
        this.watchEvent("currentUser");
    }

    getData(): void {
        this.setCurrentLogin();
    }

    setData(): void {
        this.setCurrentLogin();
    }

    public isDataAvailable(): boolean {
        return true;
    }

    setCurrentLogin() {
        this.currentLogin = new ChangePasswordForm("", "", "");
    }
}