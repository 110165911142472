import { BehaviorSubject } from "rxjs";
// Services
import { ApiService } from "../../services/api.service";
// Stores
import { AppSettingsStore } from "./app-settings.store";
// Helpers
import { ApiUrl } from "../../helpers/apiUrls";
import * as i0 from "@angular/core";
import * as i1 from "./app-settings.store";
import * as i2 from "../../services/api.service";
var DriverJournalStore = /** @class */ (function () {
    function DriverJournalStore(settingStore, apiService) {
        this.settingStore = settingStore;
        this.apiService = apiService;
        this.journalDaily = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetJournalDaily$ = this.journalDaily.asObservable();
        this.journalMonthy = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetJournalMonthy$ = this.journalMonthy.asObservable();
        this.tollStations = new BehaviorSubject({ model: null, isInitial: true });
        this.onGetTollStations$ = this.tollStations.asObservable();
    }
    DriverJournalStore.prototype.reset = function () {
        this.journalDaily.next({ model: null, isInitial: true });
        this.journalMonthy.next({ model: null, isInitial: true });
        this.tollStations.next({ model: null, isInitial: true });
        this._journalDaily = null;
        this._journalMonthly = null;
        this._tollStations = null;
    };
    // #region Requests
    DriverJournalStore.prototype.getJournalDaily = function (vehicleContract, month, year, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._journalDaily && !forceReload) {
            this.journalDaily.next({ model: this._journalDaily, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync(ApiUrl.DriverJournalDaily + "/" + vehicleContract + "/" + month + "/" + year, this.settingStore.appSettings)
            .then(function (data) {
            _this._journalDaily = data;
            _this.journalDaily.next({ model: _this._journalDaily, isSuccess: true });
        })
            .catch(function (err) {
            _this._journalDaily = null;
            _this.journalDaily.next({ model: _this._journalDaily, isSuccess: false, error: err });
        });
    };
    DriverJournalStore.prototype.getJournalMonthly = function (vehicleContract, year, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._journalMonthly && !forceReload) {
            this.journalMonthy.next({ model: this._journalMonthly, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync(ApiUrl.DriverJournalMonth + "/" + vehicleContract + "/" + year, this.settingStore.appSettings)
            .then(function (data) {
            if (data.PreviousMonthLoggingAlert.Month) {
                data.PreviousMonthLoggingAlert.Month = data.PreviousMonthLoggingAlert.Month - 1;
            }
            data.Months.forEach(function (entry) {
                entry.Month = entry.Month - 1;
            });
            _this._journalMonthly = data;
            _this.journalMonthy.next({ model: _this._journalMonthly, isSuccess: true });
        })
            .catch(function (err) {
            _this._journalMonthly = null;
            _this.journalMonthy.next({ model: _this._journalMonthly, isSuccess: false, error: err });
        });
    };
    DriverJournalStore.prototype.getTollStations = function (forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // Entry found
        if (this._tollStations && !forceReload) {
            this.tollStations.next({ model: this._tollStations, isSuccess: true, isCached: true });
            return;
        }
        // Get entry from database
        this.apiService.getAsync("" + ApiUrl.TollStations, this.settingStore.appSettings)
            .then(function (data) {
            _this._tollStations = data;
            _this.tollStations.next({ model: _this._tollStations, isSuccess: true });
        })
            .catch(function (err) {
            _this._tollStations = null;
            _this.tollStations.next({ model: _this._tollStations, isSuccess: false, error: err });
        });
    };
    DriverJournalStore.prototype.stopActiveTrip = function () {
        // Get entry from database
        return this.apiService.getAsync("" + ApiUrl.HasActiveTrip, this.settingStore.appSettings);
    };
    DriverJournalStore.ngInjectableDef = i0.defineInjectable({ factory: function DriverJournalStore_Factory() { return new DriverJournalStore(i0.inject(i1.AppSettingsStore), i0.inject(i2.ApiService)); }, token: DriverJournalStore, providedIn: "root" });
    return DriverJournalStore;
}());
export { DriverJournalStore };
