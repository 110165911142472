import { FormGroup, ValidatorFn } from "@angular/forms";

export class QueryTypeOnlyDigitsValidator {
    public onlyDigits(): ValidatorFn {
        return (formGroup: FormGroup) => {
            const queryControl = formGroup.get('searchFilter');
            const selectedQueryTypControl = formGroup.get('selectedQueryType');
            if (!queryControl || !selectedQueryTypControl) {
                return null;
            }

            if (!selectedQueryTypControl.value) {
                return null;
            }


            if (selectedQueryTypControl.value != 'CustomerId' && selectedQueryTypControl.value != 'CustomerReference' && selectedQueryTypControl.value != 'UserId') {
                return null;
            }

            if (!queryControl.value) {
                return null;
            }



            let pattern = /^\+?(0|[1-9]\d*)$/;
            return pattern.test(queryControl.value) ? null : { 'onlyDigits': true };


        };
    }
}