import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TableDataUpdatesService {

  email: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() { }

  getData() {
    return this.email.asObservable();
  }

  setData(email: string) {
    this.email.next(email);
  }
}
