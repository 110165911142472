import { Component } from '@angular/core';
import { AbstractModalFeature } from '../../features/modalfeature.abstract';
import { BigAlModule } from '../../../modules/bigal.module';
import { ComponentConfigService } from '../../../services/componentConfig.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Appointment } from '../../../models/appointment.model';
import { AppointmentTimeslots } from '../../../models/appointment-timeslots.model';
import { AppointmentPlace } from '../../../models/appointment-place.model';
import { ValidatorFactory } from '../../../rulebook/validator-factory';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../directives/app-date-adapter';
import { Driver } from '../../../models/driver.model';
import { CustomTranslateService } from '../../../translation/customTranslateService';
import { Constants } from '../../../helpers/constants';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class BookingFormComponent extends AbstractModalFeature {
  public currentAppointment: Appointment;
  public currentAppointmentId: string;
  public currentAppointmentPlace: AppointmentPlace;
  public domainObject: Appointment;
  public driver: Driver;
  public today = new Date();
  public modelName;

  public appointments: Appointment[];
  public domainForm: FormGroup;
  public currentSelectedDate: Date = new Date;
  public currentSelectedTimeslot: Date = null;

  public availableTimes: AppointmentTimeslots[];
  public currentLanguage;
  public isEditing = false;


  constructor(
    private translateService: CustomTranslateService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private validatorFactory: ValidatorFactory,
    private formBuilder: FormBuilder,
    protected bigAl: BigAlModule,
    protected theBen: ComponentConfigService
  ) {
    super(bigAl, theBen);
    this.createForm();
  }

  setCurrentAppointment() {
    this.currentAppointment = this.appointments.find(
      appointment => appointment.AppointmentId === this.currentAppointmentId
    );
  }


  onDateChange(newDate: Date) {
    this.bigAl.setCurrentBookingFormSelectedDate(newDate);
    this.bigAl.getAvailableTimes(this.currentAppointmentPlace.ModelName);
  }

  onLaterClick() {
    let laterDate: Date = new Date();
    let lastDate: Date = this.availableTimes[this.availableTimes.length - 1]
      .AvailableBookingDate;
    // add duration of one day to last date
    laterDate.setTime((new Date(lastDate)).getTime() + 24 * 60 * 60 * 1000);

    this.domainForm.controls["date"].setValue(laterDate);
  }

  validateAllFormFields(formGroup: FormGroup) {         // {1}
    Object.keys(formGroup.controls).forEach(field => {  // {2}
      const control = formGroup.get(field);             // {3}
      if (control instanceof FormControl) {             // {4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        // {5}
        this.validateAllFormFields(control);            // {6}
      }
    });
  }

  private createForm() {
    let mobile = (this.driver != null) ? this.driver.MobileNumber : "";
    this.domainForm = this.formBuilder.group({
      date: [new Date()],
      selectedDateTime: ['', this.validatorFactory.getRequiredValidator()],
      mobilePhoneNumber: [mobile, [this.validatorFactory.getRequiredValidator(), this.validatorFactory.getPhoneValidator(this.validatorFactory.hasValue)]]
    });
  }

  onFormSubmit() {
    this.validateAllFormFields(this.domainForm);
    if (!this.domainForm.valid) {
    }
    else {
      let appointment = new Appointment(null, this.domainForm.value["selectedDateTime"], this.domainForm.value["mobilePhoneNumber"], null);
      this.bigAl.postAppointment(this.currentAppointmentPlace.ModelName, appointment);
      this.close(true);
    }
  }

  protected setEventsToWatch(): void {
    this.subscribe(
      this.route.params.subscribe(params => {
        this.modelName = params['modelName'];
      }
      ));
    this.subscribe(this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang;
    }));
    this.subscribe(
      this.domainForm.controls["date"].valueChanges.subscribe(newDate => {
        this.onDateChange(newDate);
      })
    );
    this.subscribe(
      this.domainForm.controls["selectedDateTime"].valueChanges.subscribe(timeslot => {
        this.currentSelectedTimeslot = timeslot;
      })
    );
    this.subscribe(this.translateService.isEditing.subscribe(editing => {
      this.isEditing = editing;
    }));
    this.watchEvent("current" + this.modelName);
    this.watchEvent(Constants.Event_CurrentDriver);
    this.watchEvent("currentAvailableTimes");
    return;
  }
  protected setData(): void {
    this.currentLanguage = this.translate.currentLang;
    if (this.bigAl.currentDriver) {
      this.driver = this.bigAl.currentDriver;
      this.domainForm.controls["mobilePhoneNumber"].setValue(this.driver.MobileNumber);
    }
    if (this.bigAl["current" + this.modelName]) {
      this.currentAppointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
    }
    if (this.bigAl.currentAvailableTimes) {
      this.availableTimes = this.bigAl.currentAvailableTimes;
    }

  }
  protected getData(): void {
    if (!this.bigAl.currentDriver) {
      this.bigAl.getDriver();
    }
    if (!this.bigAl["current" + this.modelName]) {
      this.bigAl["get" + this.modelName]();
      this.currentAppointmentPlace = this.bigAl.getAppointmentPlace(this.modelName);
    }
    if (!this.bigAl.currentAvailableTimes) {
      this.bigAl.setCurrentBookingFormSelectedDate(new Date());
      this.bigAl.getAvailableTimes(this.modelName);
    }
    this.setData();
  }

  public isDataAvailable(): boolean {
    return true;
  }

}
