// Third-party
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { LanguageTranslation } from "../models/languageTranslationResponse.model";
var CustomTranslateService = /** @class */ (function () {
    function CustomTranslateService(translateService) {
        this.translateService = translateService;
        this.isEditing = new BehaviorSubject(false);
        this.hasNffTranslations = new BehaviorSubject(false);
        this.aldCompanyId = 1;
        this.nffCompanyId = 2;
        this.nffTranslations = [];
        this.aldTranslations = [];
        this.translateService.defaultLang = "en";
    }
    CustomTranslateService.prototype.getIsEditing = function () {
        return this.isEditing.asObservable();
    };
    CustomTranslateService.prototype.setIsEditing = function (data) {
        this.isEditing.next(data);
    };
    CustomTranslateService.prototype.getHasNffTranslations = function () {
        return this.hasNffTranslations.asObservable();
    };
    CustomTranslateService.prototype.setHasNffTranslations = function (data) {
        this.hasNffTranslations.next(data);
    };
    CustomTranslateService.prototype.loadCompanyTranslations = function (data, companyId) {
        var _this = this;
        this.aldTranslations = [];
        this.nffTranslations = [];
        data.forEach(function (translation) {
            if (translation.CompanyId === companyId) {
                _this.translateService.setTranslation(translation.LanguageCode, JSON.parse(translation.TranslationJson));
            }
            var companyTranslation = _this.loadCompanyTranslation(translation);
            if (translation.CompanyId === _this.aldCompanyId) {
                _this.aldTranslations.push(companyTranslation);
            }
            else if (translation.CompanyId === _this.nffCompanyId) {
                _this.nffTranslations.push(companyTranslation);
            }
        });
    };
    CustomTranslateService.prototype.loadCompanyTranslation = function (translation) {
        return new LanguageTranslation(translation.LanguageCode, JSON.parse(translation.TranslationJson), translation.CompanyId);
    };
    CustomTranslateService.prototype.updateCompanyTranslations = function (translationsToSave, currentCompany) {
        var _this = this;
        var currentCompanyTranslations = translationsToSave.filter(function (t) { return t.CompanyId === currentCompany; });
        var translationName = currentCompanyTranslations[0].TranslationName;
        var keyParts = [];
        if (translationName) {
            keyParts = translationName.split(".");
        }
        var propertyName = keyParts.pop();
        var translations = {};
        // Update ALD and NFF translations
        translationsToSave.forEach(function (t) {
            if (t.CompanyId === _this.aldCompanyId) {
                _this.setCompanyTranslation(_this.aldTranslations, t.LanguageCode, keyParts[0], propertyName, t.TranslationValue);
            }
            else if (t.CompanyId === _this.nffCompanyId) {
                _this.setCompanyTranslation(_this.nffTranslations, t.LanguageCode, keyParts[0], propertyName, t.TranslationValue);
            }
        });
        // Update translation values in translateService
        currentCompanyTranslations.forEach(function (t) {
            // Set method doesn't support nested translations so we need to use setTranslation with merge = true
            keyParts.reduce(function (o, k) {
                return o[k] = o[k] || {};
            }, translations)[propertyName] = t.TranslationValue;
            _this.translateService.setTranslation(t.LanguageCode, translations, true);
        });
    };
    CustomTranslateService.prototype.setCompanyTranslation = function (languageTranslation, languageCode, parentName, labelName, translationValue) {
        var translationIndex = languageTranslation.findIndex(function (translation) { return translation.LanguageCode === languageCode; });
        if (translationIndex !== -1) {
            languageTranslation[translationIndex.toString()].TranslationJson[parentName.toString()][labelName.toString()] = translationValue;
        }
    };
    CustomTranslateService.prototype.setLanguage = function (country) {
        this.translateService.currentLang = this.getCountryLanguage(country);
    };
    CustomTranslateService.prototype.getCurrentLang = function () {
        return this.translateService.currentLang;
    };
    CustomTranslateService.prototype.setLanguageByLang = function (lang) {
        this.translateService.currentLang = lang;
    };
    CustomTranslateService.prototype.changeLanguageByLanguageCode = function (lang) {
        this.translateService.use(lang);
    };
    CustomTranslateService.prototype.setTranslations = function (country, translations) {
        this.translateService.setTranslation(this.getCountryLanguage(country), translations, false);
    };
    CustomTranslateService.prototype.setTranslation = function (countryLang, translations, shouldMerge) {
        this.translateService.setTranslation(countryLang, translations, shouldMerge);
    };
    // Gets the translated value of a key(or an array of keys) or the key if the value was not found
    CustomTranslateService.prototype.get = function (key) {
        return this.translateService.get(key);
    };
    CustomTranslateService.prototype.getLanguageCodes = function () {
        return this.translateService.getLangs();
    };
    CustomTranslateService.prototype.getCountryLanguage = function (country) {
        var countryLang;
        switch (country.toLowerCase()) {
            case "dk":
                countryLang = "da";
                break;
            case "se":
                countryLang = "sv";
                break;
            case "no":
                countryLang = "nb";
                break;
            default:
                countryLang = "en";
        }
        return countryLang;
    };
    CustomTranslateService.prototype.getLanguageText = function (languageCode) {
        switch (languageCode) {
            case "da":
                return "Danish";
            case "sv":
                return "Swedish";
            case "nb":
                return "Norwegian";
            case "en":
                return "English";
            case "fr":
                return "French";
            default:
                return "Unknown";
        }
    };
    CustomTranslateService.prototype.getAllCompanyTranslations = function (companyId, languageCode) {
        switch (companyId) {
            case this.aldCompanyId:
                return this.aldTranslations.find(function (x) { return x.LanguageCode == languageCode; }).TranslationJson;
            case this.nffCompanyId:
                return this.nffTranslations.find(function (x) { return x.LanguageCode == languageCode; }).TranslationJson;
            default:
                return this.aldTranslations.find(function (x) { return x.LanguageCode == languageCode; }).TranslationJson;
        }
    };
    return CustomTranslateService;
}());
export { CustomTranslateService };
