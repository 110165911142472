import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
    selector: "page-not-found-component",
    templateUrl: "./page-not-found.component.html"
})
export class PageNotFoundComponent {

    constructor(
        private router: Router
    ) {
    }

    public goHome() {
        this.router.navigate(['/']);
    }
}
