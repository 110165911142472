import { PhoneCountryCodeService } from './../../services/phone-country-code.service';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ForgotLoginConfirmationComponent } from './forgot-login-confirmation/forgot-login-confirmation.component';
import { ForgotLoginComponent } from './forgot-login/forgot-login.component';
import { LoginComponent } from './login.component';
import { SharedModule } from './../../modules/components/shared.module';
import { CustomMaterialModule } from './../../modules/custom-material.module';
import { GenericModule } from './../../modules/components/generic.module';
import { LoginRoutingModule } from './login-routing.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { DigitalServiceCardComponent } from '../features/contact-information/digital-service-card/digital-service-card.component';
import { ChangePasswordFormComponent } from './change-password/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    GenericModule,
    FormsModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    LoginRoutingModule
  ],
  providers: [
    TranslatePipe,
    PhoneCountryCodeService
  ],
  declarations: [
    LoginComponent,
    ForgotLoginComponent,
    ForgotLoginConfirmationComponent,
    NewPasswordComponent,
    DigitalServiceCardComponent,
    ChangePasswordFormComponent
  ],
  exports: [
    LoginComponent,
    ForgotLoginComponent,
    ForgotLoginConfirmationComponent,
    NewPasswordComponent,
    DigitalServiceCardComponent,
    ChangePasswordFormComponent
  ]
})

export class LoginModule { }
