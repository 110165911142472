import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

// Services
import { ApiService } from "../../services/api.service";

// Types
import { Insurance } from "../../models/insurance.model";
import { IStoreResult } from "../../sharedtypes/interfaces/sharedtypes.interface";

// Stores
import { AppSettingsStore } from "./app-settings.store";

// Helpers
import { ApiUrl } from "../../helpers/apiUrls";


@Injectable({
  providedIn: "root"
})
export class InsuranceStore {

  private insurance: Insurance;

  private readonly insuranceForContract$: BehaviorSubject<IStoreResult<Insurance>> = new BehaviorSubject<IStoreResult<Insurance>>({ model: null, isInitial: true });
  onGetInsuranceForContract$ = this.insuranceForContract$.asObservable();

  constructor(
    private settingStore: AppSettingsStore,
    private apiService: ApiService) {
  }

  reset() {
    this.insuranceForContract$.next({ model: null, isInitial: true });
    this.insurance = null;
  }

  // #region Requests

  getInsuranceForContract(vehicleContractId: string, forceReload: boolean = false): void {

    if (vehicleContractId) {
      // Entry found
      if (this.insurance && !forceReload) {
        this.insuranceForContract$.next({ model: this.insurance, isSuccess: true, isCached: true });
        return;
      }

      // Get entry from database
      this.apiService.getAsync<Insurance>(`${ApiUrl.InsuranceForContract}/${vehicleContractId}`, this.settingStore.appSettings)
        .then((data: Insurance) => {
          this.insurance = data;
          this.insuranceForContract$.next({ model: this.insurance, isSuccess: true });
        })
        .catch((err: HttpErrorResponse) => {
          this.insurance = null;
          this.insuranceForContract$.next({ model: this.insurance, isSuccess: false, error: err });
        });
    }
    else {
      this.insurance = null;
      this.insuranceForContract$.next({ model: this.insurance, isSuccess: false, error: "vehicleContractId is empty" });
    }
  }

  // #endregion
}
